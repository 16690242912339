import CircularProgress from '@mui/material/CircularProgress';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@headway/helix/Button';
import { IconCheck } from '@headway/helix/icons/Check';
import { theme } from '@headway/helix/theme';

import { isDetailsConfirmed } from '../../Calendar/events/util/events';
import { useButtonsConfig } from '../hooks/useButtonsConfig';
import { useFormActions } from '../hooks/useFormActions';
import { AppointmentConfirmationContextV2 } from '../stores/AppointmentConfirmationContextV2';

interface AutoSaveListenerLoaderProps {
  isAutoSaving?: boolean;
}

const AutoSaveListenerLoader = ({
  isAutoSaving,
}: AutoSaveListenerLoaderProps) => {
  const { event } = useContext(AppointmentConfirmationContextV2);
  return (
    <div className="mt-4">
      {!isDetailsConfirmed(event) && (
        <div className="flex flex-row">
          {isAutoSaving ? (
            <>
              <CircularProgress
                css={{
                  color: theme.color.system.gray,
                  marginTop: '1px',
                }}
                size={16}
              />
              <p className="ml-2">Saving...</p>
            </>
          ) : (
            <>
              <IconCheck />
              <p className="ml-2">Saved</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export interface AppointmentConfirmationFooterProps {
  isAutoSaving?: boolean;
}

export const AppointmentConfirmationFooterV2 = ({
  isAutoSaving,
}: AppointmentConfirmationFooterProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const buttonsConfig = useButtonsConfig();
  const { onPrimaryClick, onSecondaryClick } = useFormActions();

  return (
    <div className="flex flex-1 items-center justify-between px-4">
      <AutoSaveListenerLoader isAutoSaving={isAutoSaving} />
      <div className="flex gap-4">
        {buttonsConfig.secondaryCTA ? (
          <Button
            variant="secondary"
            disabled={buttonsConfig.secondaryDisabled || isSubmitting}
            data-testid="secondaryModalCta"
            onPress={onSecondaryClick}
          >
            {buttonsConfig.secondaryCTA}
          </Button>
        ) : null}
        {buttonsConfig.primaryCTA ? (
          <Button
            form="appointmentConfirmationModalForm"
            variant="primary"
            disabled={buttonsConfig.primaryDisabled || isSubmitting}
            data-testid="primaryModalCta"
            onPress={onPrimaryClick}
          >
            {buttonsConfig.primaryCTA}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
