import { useContext, useEffect, useMemo } from 'react';
import { GenericTemplate } from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/Renderer/types';
import {
  freeTextTemplateFilter,
  intakeNoteTemplateFilter,
} from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/utils';
import { AppointmentContext } from '~/legacy/views/AppointmentConfirmation/stores/AppointmentContext';
import { ProgressNoteContext } from '~/legacy/views/AppointmentConfirmation/stores/ProgressNotesContext';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { UserRead } from '@headway/api/models/UserRead';
import { PRESCRIBER_PPR_CPT_CODES } from '@headway/shared/constants/cptCodes';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';
import { usePrePaymentReview } from 'hooks/usePrePaymentReview';
import { useProvider } from 'hooks/useProvider';
import { useProviderPaymentPause } from 'hooks/useProviderPaymentPause';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import { useDocumentationRequirement } from 'views/Calendar/utils/documentationRequirement';

import { DocumentationRequirementBanner } from './documentationRequirements';
import { MedicareMedicaidBanner } from './medicareRequirements';
import { PausedPaymentsRequirementBanner } from './pausedPaymentsRequirements';
import { PprBanner } from './pprRequirements';

export interface ProgressNoteRequirement {
  /**
   * If undefined, all ProgressNoteTypes allowed
   */
  allowedProgressNoteTypes?: ProgressNoteType[];

  /**
   * Determines if a listed ProgressNoteType will be hidden
   */
  hiddenProgressNoteTypes?: ProgressNoteType[];

  /**
   * Hide the NoteType BigRadio selection
   */
  preventSwitching?: boolean;

  /**
   * Filters out disallowed templates
   */
  templateFilter?: (
    templates: GenericTemplate<unknown>[]
  ) => GenericTemplate<unknown>[];

  /**
   * If undefined, enabled for all CPT codes
   */
  enabledCptCodes?: string[];

  /**
   * Banner content to show for the requirment
   */
  banner?: React.ReactNode;

  /**
   * Fallback requirement if CPT codes not selected
   */
  fallback?: PROGRESS_NOTE_REQUIREMENTS;
}

export enum PROGRESS_NOTE_REQUIREMENTS {
  NO_RESTRICTIONS = 'NO_RESTRICTIONS',
  NONE_ENABLED = 'NONE_ENABLED',
  DOCUMENTATION_REQUIRED = 'DOCUMENTATION_REQUIRED',
  IMPERSONATING_USER = 'IMPERSONATING_USER',
  MEDICARE_OR_MEDICAID = 'MEDICARE_OR_MEDICAID',
  PAUSED_PAYMENTS = 'PAUSED_PAYMENTS',
  PPR_TALK_THERAPY_INTAKES = 'PPR_TALK_THERAPY_INTAKES',
  PPR_PRESCRIBER = 'PPR_PRESCRIBER',
  PPR_GROUP_ADMIN = 'PPR_GROUP_ADMIN',
  PPR_GROUP_ADMIN_PRESCRIBER = 'PPR_GROUP_ADMIN_PRESCRIBER',
  PPR_GROUP_PRACTICE_PROVIDER = 'PPR_GROUP_PRACTICE_PROVIDER',
}

export const getProgressNoteRequirement = (
  requirementEnum: PROGRESS_NOTE_REQUIREMENTS,
  {
    patient,
    carrierSpecificConditions: { requiringCarrier } = {},
  }: ProgressNoteRequirementConditions = {}
): ProgressNoteRequirement => {
  const requirementsMap = {
    [PROGRESS_NOTE_REQUIREMENTS.NO_RESTRICTIONS]: {},
    [PROGRESS_NOTE_REQUIREMENTS.NONE_ENABLED]: {
      allowedProgressNoteTypes: [],
      preventSwitching: true,
    },
    [PROGRESS_NOTE_REQUIREMENTS.DOCUMENTATION_REQUIRED]: {
      allowedProgressNoteTypes: [
        ProgressNoteType.TEMPLATE,
        ProgressNoteType.UPLOAD,
      ],
      hiddenProgressNoteTypes: [ProgressNoteType.NONE],
      banner: (
        <DocumentationRequirementBanner requiringCarrier={requiringCarrier} />
      ),
    },
    [PROGRESS_NOTE_REQUIREMENTS.PAUSED_PAYMENTS]: {
      allowedProgressNoteTypes: [
        ProgressNoteType.TEMPLATE,
        ProgressNoteType.UPLOAD,
      ],
      hiddenProgressNoteTypes: [ProgressNoteType.NONE],
      banner: <PausedPaymentsRequirementBanner patient={patient} />,
    },
    [PROGRESS_NOTE_REQUIREMENTS.IMPERSONATING_USER]: {
      allowedProgressNoteTypes: [
        ProgressNoteType.UPLOAD,
        ProgressNoteType.NONE,
      ],
    },
    [PROGRESS_NOTE_REQUIREMENTS.MEDICARE_OR_MEDICAID]: {
      allowedProgressNoteTypes: [ProgressNoteType.TEMPLATE],
      preventSwitching: true,
      templateFilter: freeTextTemplateFilter,
      banner: <MedicareMedicaidBanner />,
    },
    // PPR non-group, non-prescribers
    [PROGRESS_NOTE_REQUIREMENTS.PPR_TALK_THERAPY_INTAKES]: {
      allowedProgressNoteTypes: [ProgressNoteType.TEMPLATE],
      templateFilter: intakeNoteTemplateFilter,
      preventSwitching: true,
      enabledCptCodes: ['90791'],
      banner: (
        <PprBanner
          version={PROGRESS_NOTE_REQUIREMENTS.PPR_TALK_THERAPY_INTAKES}
          requiringCarrier={requiringCarrier}
        />
      ),
    },
    // PPR prescribers
    [PROGRESS_NOTE_REQUIREMENTS.PPR_PRESCRIBER]: {
      allowedProgressNoteTypes: [
        ProgressNoteType.TEMPLATE,
        ProgressNoteType.UPLOAD,
      ],
      templateFilter: freeTextTemplateFilter,
      banner: (
        <PprBanner
          version={PROGRESS_NOTE_REQUIREMENTS.PPR_PRESCRIBER}
          requiringCarrier={requiringCarrier}
        />
      ),
    },
    // PPR group admins impersonating talk therapists - only allow Uploads for 90791
    // Fallback to impersonating user (upload & none) if not 90791
    [PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_ADMIN]: {
      allowedProgressNoteTypes: [ProgressNoteType.UPLOAD],
      preventSwitching: true,
      enabledCptCodes: ['90791'],
      fallback: PROGRESS_NOTE_REQUIREMENTS.IMPERSONATING_USER,
      banner: (
        <PprBanner
          version={PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_ADMIN}
          requiringCarrier={requiringCarrier}
        />
      ),
    },
    // PPR group admins impersonating prescribers - only allow Uploads
    [PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_ADMIN_PRESCRIBER]: {
      allowedProgressNoteTypes: [ProgressNoteType.UPLOAD],
      preventSwitching: true,
      banner: (
        <PprBanner
          version={PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_ADMIN_PRESCRIBER}
          requiringCarrier={requiringCarrier}
        />
      ),
    },
    // PPR group practice providers require template or upload for 90791
    [PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_PRACTICE_PROVIDER]: {
      allowedProgressNoteTypes: [
        ProgressNoteType.TEMPLATE,
        ProgressNoteType.UPLOAD,
      ],
      enabledCptCodes: ['90791'],
      templateFilter: intakeNoteTemplateFilter,
      banner: (
        <PprBanner
          version={PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_PRACTICE_PROVIDER}
          requiringCarrier={requiringCarrier}
        />
      ),
    },
  };

  return requirementsMap[requirementEnum];
};

/**
 * Specific requirements for enforcing progress note templates.
 *
 * _Note: Must be used within an appointment and progress notes context._
 *
 * @param patient The patient for this appointment
 * @returns ProgressNoteRequirement
 */
export const useProgressNoteRequirements = ({
  patient,
  event,
}: {
  patient?: UserRead;
  event?: ProviderEventRead;
}): ProgressNoteRequirement => {
  const provider = useProvider();
  const { selectedCptCodes } = useContext(AppointmentContext);
  const { user } = useAuthStore();
  const { progressNote, setAllowedUploadTypes } =
    useContext(ProgressNoteContext);

  //
  // ==========================================================================
  // Conditions: These are the current conditions for template requirements
  //

  // Group Practice
  const isGroupAdmin = isGroupAdminImpersonatingProvider(provider, user);
  const isProviderApartOfGroupPractice = !!provider.groupPractice;
  const isViewingProgressNote = progressNote !== undefined;

  // Prescribers
  const isPrescriber = provider.isPrescriber;

  // Pre-Payment Review (PPR):
  const { isPPR, pprCarrier } = usePrePaymentReview(
    patient,
    event?.providerAppointment
  );
  useEffect(() => {
    if (isPPR) {
      setAllowedUploadTypes('application/pdf');
    }
  }, [isPPR, setAllowedUploadTypes]);

  // Medicare / Medicaid
  const isPatientMedicareOrMedicaid = useMedicareOrMedicaid(patient?.id);
  const isMMTreatmentPlanRequirementEnabled: boolean = useFlag(
    'mmTreatmentPlanRequirement',
    false
  );
  const isMedicareOrMedicaid =
    isMMTreatmentPlanRequirementEnabled && isPatientMedicareOrMedicaid;

  // Required Progress Notes & Documentation Requirement Pilot
  const { isRequired: isDocumentationRequired, carrier } =
    useDocumentationRequirement(provider, patient, event?.providerAppointment);
  const requiringCarrier = pprCarrier ?? carrier;

  // Paused Payments
  const isPausedPaymentsEnabled: boolean = useFlag('pausedPayments', false);
  const { data: activeProviderPaymentPauses } = useProviderPaymentPause({
    providerId: provider.id,
    activeOnly: true,
  });
  const isProviderInPausePayments =
    isPausedPaymentsEnabled &&
    activeProviderPaymentPauses &&
    activeProviderPaymentPauses?.length > 0;

  //
  // ==========================================================================
  // Get requirements given the above conditions
  //
  const requirement = useMemo(() => {
    const conditions: ProgressNoteRequirementConditions = {
      patient,
      isGroupAdmin,
      isProviderApartOfGroupPractice,
      isViewingProgressNote,
      isPrescriber,
      isDocumentationRequired,
      isProviderInPausePayments,
      carrierSpecificConditions: {
        isPPR,
        isMedicareOrMedicaid,
        requiringCarrier,
      },
      selectedCptCodes,
    };

    const computedRequirementEnum = computeProgressNoteRequirements(conditions);
    const requirement = getProgressNoteRequirement(
      computedRequirementEnum,
      conditions
    );

    // Check if the selected CPT codes meet the requirements
    if (isRequirementEnabledForCptCodes(requirement, selectedCptCodes)) {
      return requirement;
    } else {
      // If fallback defined, return the fallback requirement
      if (requirement.fallback) {
        return getProgressNoteRequirement(requirement.fallback, conditions);
      }

      // If no requirements are met, return no restrictions
      return getProgressNoteRequirement(
        PROGRESS_NOTE_REQUIREMENTS.NO_RESTRICTIONS
      );
    }
  }, [
    patient,
    isGroupAdmin,
    isProviderApartOfGroupPractice,
    isViewingProgressNote,
    isPrescriber,
    isDocumentationRequired,
    isProviderInPausePayments,
    isPPR,
    isMedicareOrMedicaid,
    requiringCarrier,
    selectedCptCodes,
  ]);
  return requirement;
};

/**
 * Only enable the requirements for the specified CPT codes from the
 * requirement OR if no enabledCptCodes are specified from the requirement,
 * it will assume that the requirement is for all potential CPT codes
 */
export const isRequirementEnabledForCptCodes = (
  requirement: ProgressNoteRequirement,
  selectedCptCodes: string[]
): boolean =>
  !requirement.enabledCptCodes ||
  requirement.enabledCptCodes.some((code) => selectedCptCodes.includes(code));

export interface ProgressNoteRequirementConditions {
  patient?: UserRead;
  isGroupAdmin?: boolean;
  isProviderApartOfGroupPractice?: boolean;
  isViewingProgressNote?: boolean;
  isPrescriber?: boolean;
  isDocumentationRequired?: boolean;
  isProviderInPausePayments?: boolean;
  carrierSpecificConditions?: {
    isPPR?: boolean;
    isMedicareOrMedicaid?: boolean;
    requiringCarrier?: FrontEndCarrierRead;
  };
  selectedCptCodes?: string[];
}
/**
 * Get the template requirements given the specified conditions
 *
 * @param conditions Specified conditions for template requirements
 * @returns ProgressNoteTemplateRequirement
 */
export const computeProgressNoteRequirements = ({
  isGroupAdmin,
  isProviderApartOfGroupPractice,
  isViewingProgressNote,
  isPrescriber,
  isDocumentationRequired,
  isProviderInPausePayments,
  carrierSpecificConditions: { isPPR, isMedicareOrMedicaid } = {},
  selectedCptCodes,
}: ProgressNoteRequirementConditions): PROGRESS_NOTE_REQUIREMENTS => {
  const hasSelectedPrescriberPPRCptCodes = selectedCptCodes?.some((code) =>
    // ensure that the PRESCRIBER_PPR_CPT_CODES is defined properly (is defined as [] now so we can preserve the
    // logic for PPR prescribers)
    PRESCRIBER_PPR_CPT_CODES.includes(code)
  );
  // Group Practice Admins
  if (isGroupAdmin) {
    // For PPR Group Admins
    if (isPPR) {
      // If group admin is impersonating a prescriber
      if (isPrescriber && hasSelectedPrescriberPPRCptCodes) {
        return PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_ADMIN_PRESCRIBER;
      }
      // If group admin is impersonating a talk therapist
      // return PROGRESS_NOTE_REQUIREMENTS.PPR_GROUP_ADMIN;  disabled because this would enforce 90791 upload-only
    } else if (isViewingProgressNote) {
      // Disable editing existing progress notes for Group Admins
      return PROGRESS_NOTE_REQUIREMENTS.NONE_ENABLED;
    }

    // All other group admins
    return PROGRESS_NOTE_REQUIREMENTS.IMPERSONATING_USER;
  }

  // Medicare / Medicaid requirements
  if (isMedicareOrMedicaid) {
    return PROGRESS_NOTE_REQUIREMENTS.MEDICARE_OR_MEDICAID;
  }

  // PPR requirements
  if (isPPR) {
    if (isPrescriber && hasSelectedPrescriberPPRCptCodes) {
      return PROGRESS_NOTE_REQUIREMENTS.PPR_PRESCRIBER;
    }
  }

  // Documentation Requirement Pilot
  if (isDocumentationRequired) {
    return PROGRESS_NOTE_REQUIREMENTS.DOCUMENTATION_REQUIRED;
  }

  // Paused Payments
  if (isProviderInPausePayments) {
    return PROGRESS_NOTE_REQUIREMENTS.PAUSED_PAYMENTS;
  }

  return PROGRESS_NOTE_REQUIREMENTS.NO_RESTRICTIONS;
};
