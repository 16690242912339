import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { FormControl } from '@headway/helix/FormControl';
import { Item, Select } from '@headway/helix/Select';

import { CADENCE_DISPLAY_NAMES } from '../../helpers/utils';
import { AssessmentScheduleInputSelectProps } from './types';

const ALL_RECURRENCE_CADENCES = Object.values(
  PatientAssessmentRecurrenceCadence
);

export function AssessmentRecurrenceCadenceSelector({
  warn,
  ...props
}: AssessmentScheduleInputSelectProps) {
  return (
    <FormControl
      component={Select}
      selectionMode="single"
      label="Frequency"
      data-warn={warn}
      {...props}
    >
      {ALL_RECURRENCE_CADENCES.map((cadence) => {
        const value = CADENCE_DISPLAY_NAMES[cadence];

        return (
          <Item key={cadence} textValue={value}>
            {value}
          </Item>
        );
      })}
    </FormControl>
  );
}
