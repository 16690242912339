import difference from 'lodash/difference';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import queryString, { ParsedQuery } from 'query-string';

import { BoundedGeolocation } from '@headway/api/models/BoundedGeolocation';
import { DayOfWeek } from '@headway/api/models/DayOfWeek';
import { MarketRead } from '@headway/api/models/MarketRead';
import { PatientAgeGroup } from '@headway/api/models/PatientAgeGroup';
import { ProviderFunction } from '@headway/api/models/ProviderFunction';
import { ProviderGender } from '@headway/api/models/ProviderGender';
import { ProviderSearchTopic } from '@headway/api/models/ProviderSearchTopic';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { TimeOfDayFilter } from '@headway/api/models/TimeOfDayFilter';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserPrescriberPreference } from '@headway/api/models/UserPrescriberPreference';
import { UserRead } from '@headway/api/models/UserRead';
import { UserSessionLocationPreference } from '@headway/api/models/UserSessionLocationPreference';
import { WeekdayFilter } from '@headway/shared/constants/search';
import { logException, logWarning } from '@headway/shared/utils/sentry';
import { PatientVisibleSpecialtyRead } from '@headway/shared/utils/specialties';
import { isValidEnumValue } from '@headway/shared/utils/types';

export type Filters = {
  address?: UserRead['address'];
  availabilities?: string[];
  currentPage: number;
  ethnicities?: string[];
  forChild?: PatientAgeGroup[];
  frontEndCarrierId?: number;
  genders?: ProviderGender[];
  initialFrontEndCarrierId?: number;
  isBlueCard?: boolean;
  issues?: string[];
  languages?: string[];
  lat?: UserRead['lat'];
  lon?: UserRead['lon'];
  lowerLat?: UserRead['lowerLat'];
  lowerLon?: UserRead['lowerLon'];
  medium?: string[];
  patientAge?: number;
  phoneConsults?: boolean;
  isPhypaProvider?: boolean;
  isBtiProvider?: boolean;
  providerAges?: string[];
  providerType?: UserPrescriberPreference;
  selectedModalities?: number[];
  sessionLocation?: UserSessionLocationPreference;
  state: UnitedStates; // TODO require?
  topics?: ProviderSearchTopic[];
  typeOfCare?: ProviderFunction[];
  upperLat?: UserRead['upperLat'];
  upperLon?: UserRead['upperLon'];
  zoom?: UserRead['zoom'];
  freeText?: string;
};

export function parseArrayValueFromQueryParams<T>(
  val: T | T[] | undefined,
  elementType: 'string' | 'number'
): T[] {
  if (!val) return [];

  const values = Array.isArray(val) ? val : [val];

  return values.filter((elem) => {
    if (typeof elem !== elementType) {
      throw new Error(
        `Unexpected type ${typeof elem} for ${elem}. Expected ${elementType}`
      );
    }

    if (typeof elem === 'number') {
      return !isNaN(elem);
    }

    if (typeof elem === 'string') {
      return elem.trim() !== '';
    }

    logException(
      new Error(
        `Unexpected type for value ${elem}. This only handles strings and numbers. Filtering out`
      )
    );

    return false;
  });
}

function getEnumValueFromQueryParams<T extends object>({
  enumClass,
  queryParams,
  key,
}: {
  enumClass: T;
  queryParams: ParsedQuery<string | number | boolean | T>;
  key: keyof Filters;
}): T[keyof T] | undefined {
  const value = queryParams[key];

  if (value === undefined || value === null) return;
  if (Array.isArray(value)) {
    logWarning(`Unhandled array value for query param`, {
      extra: { key, value },
    });

    return;
  }

  if (isValidEnumValue(enumClass, value)) {
    return value;
  }

  logWarning(`Query param has unhandled value`, { extra: { key, value } });
}

const getStateFromQueryParams = (
  markets: MarketRead[],
  queryParams: any
): UnitedStates | undefined => {
  if (!queryParams.state) return;

  // If queryParams.state is a string, i.e. nothing after the state name, use it directly. If it is an array, assume it is [state, extra]
  const queryParamsState =
    typeof queryParams.state === 'string'
      ? queryParams.state
      : queryParams.state[0];

  const market = markets.find(
    (market) =>
      market.slug === queryParamsState || market.state === queryParamsState
  );

  return market?.state;
};

export const queryStringToFilters = (
  rawQueryString: string,
  markets: MarketRead[]
): Filters => {
  const queryParams = queryString.parse(rawQueryString, {
    parseNumbers: true,
    parseBooleans: true,
  });

  return omitBy(
    {
      address:
        typeof queryParams.address === 'string'
          ? queryParams.address
          : undefined,
      currentPage:
        typeof queryParams.currentPage === 'number'
          ? queryParams.currentPage
          : 1,
      // default to 1 if currentPage is not specified
      ethnicities: parseArrayValueFromQueryParams(
        queryParams.ethnicities as string | string[] | undefined,
        'string'
      ),
      forChild: parseArrayValueFromQueryParams(
        queryParams.forChild as string | string[] | undefined,
        'string'
      ),
      frontEndCarrierId:
        typeof queryParams.frontEndCarrierId === 'number'
          ? queryParams.frontEndCarrierId
          : undefined,
      genders: parseArrayValueFromQueryParams(
        queryParams.genders as string | string[] | undefined,
        'string'
      ),
      initialFrontEndCarrierId:
        typeof queryParams.initialFrontEndCarrierId === 'number'
          ? queryParams.initialFrontEndCarrierId
          : undefined,
      isBlueCard:
        typeof queryParams.isBlueCard === 'boolean'
          ? queryParams.isBlueCard
          : undefined,
      issues: parseArrayValueFromQueryParams(
        queryParams.issues as string | string[] | undefined,
        'string'
      ),
      languages: parseArrayValueFromQueryParams(
        queryParams.languages as string | string[] | undefined,
        'string'
      ),
      lat: typeof queryParams.lat === 'number' ? queryParams.lat : undefined,
      lon: typeof queryParams.lon === 'number' ? queryParams.lon : undefined,
      lowerLat:
        typeof queryParams.lowerLat === 'number'
          ? queryParams.lowerLat
          : undefined,
      lowerLon:
        typeof queryParams.lowerLon === 'number'
          ? queryParams.lowerLon
          : undefined,
      medium: parseArrayValueFromQueryParams(
        queryParams.medium as string | string[] | undefined,
        'string'
      ),
      patientAge:
        typeof queryParams.patientAge === 'number'
          ? queryParams.patientAge
          : undefined,
      phoneConsults:
        typeof queryParams.phoneConsults === 'boolean'
          ? queryParams.phoneConsults
          : undefined,
      preferredReferralMembership:
        typeof queryParams.preferredReferralMembership === 'boolean'
          ? queryParams.preferredReferralMembership
          : undefined,
      isPhypaProvider:
        typeof queryParams.isPhypaProvider === 'boolean'
          ? queryParams.isPhypaProvider
          : undefined,
      isBtiProvider:
        typeof queryParams.isBtiProvider === 'boolean'
          ? queryParams.isBtiProvider
          : undefined,
      freeText:
        typeof queryParams.freeText === 'string'
          ? queryParams.freeText
          : undefined,
      providerAges: parseArrayValueFromQueryParams(
        queryParams.providerAges as string | string[] | undefined,
        'string'
      ),
      providerType: getEnumValueFromQueryParams({
        enumClass: UserPrescriberPreference,
        queryParams,
        key: 'providerType',
      }),
      selectedModalities: parseArrayValueFromQueryParams(
        queryParams.selectedModalities as number | number[] | undefined,
        'number'
      ),
      sessionLocation: getEnumValueFromQueryParams({
        enumClass: UserSessionLocationPreference,
        queryParams,
        key: 'sessionLocation',
      }),
      state: getStateFromQueryParams(markets, queryParams),
      topics: parseArrayValueFromQueryParams(queryParams.topics, 'string'),
      typeOfCare: parseArrayValueFromQueryParams(
        queryParams.typeOfCare as string | string[] | undefined,
        'string'
      ),
      upperLat:
        typeof queryParams.upperLat === 'number'
          ? queryParams.upperLat
          : undefined,
      upperLon:
        typeof queryParams.upperLon === 'number'
          ? queryParams.upperLon
          : undefined,
      zoom: typeof queryParams.zoom === 'number' ? queryParams.zoom : undefined,
      availabilities: parseArrayValueFromQueryParams(
        queryParams.availabilities as string | string[] | undefined,
        'string'
      ),
    },
    isNil
  ) as Filters;
};

export const filtersToQueryParams = (
  filters: Partial<Filters>
): [Partial<Filters>, (keyof Filters)[]] => {
  const formattedFilters = {
    address: filters.address,
    currentPage: filters.currentPage,
    ethnicities: filters.ethnicities?.length ? filters.ethnicities : undefined,
    forChild: filters.forChild?.length ? filters.forChild : undefined,
    frontEndCarrierId: filters.frontEndCarrierId,
    genders: filters.genders?.length ? filters.genders : undefined,
    initialFrontEndCarrierId: filters.initialFrontEndCarrierId,
    isBlueCard:
      typeof filters.isBlueCard === 'boolean' ? filters.isBlueCard : undefined,
    issues: filters.issues?.length ? filters.issues : undefined,
    lat: filters.lat,
    lon: filters.lon,
    lowerLat: filters.lowerLat,
    lowerLon: filters.lowerLon,
    languages: filters.languages?.length ? filters.languages : undefined,
    medium: filters.medium?.length ? filters.medium : undefined,
    patientAge: filters.patientAge,
    phoneConsults: filters.phoneConsults ? filters.phoneConsults : undefined,
    isPhypaProvider: filters.isPhypaProvider
      ? filters.isPhypaProvider
      : undefined,
    isBtiProvider: filters.isBtiProvider ? filters.isBtiProvider : undefined,
    freeText: filters.freeText ? filters.freeText : undefined,
    providerAges: filters.providerAges?.length
      ? filters.providerAges
      : undefined,
    providerType: filters.providerType,
    selectedModalities: filters.selectedModalities?.length
      ? filters.selectedModalities
      : undefined,
    sessionLocation: filters.sessionLocation,
    state: filters.state?.length ? filters.state : undefined,
    topics: filters.topics,
    typeOfCare: filters.typeOfCare?.length ? filters.typeOfCare : undefined,
    upperLat: filters.upperLat,
    upperLon: filters.upperLon,
    zoom: filters.zoom,
    availabilities: filters.availabilities?.length
      ? filters.availabilities
      : undefined,
  };
  const strippedFilters = omitBy(formattedFilters, isNil);

  const nilKeys = difference(
    Object.keys(formattedFilters),
    Object.keys(strippedFilters)
  ) as (keyof Filters)[];

  // return the stripped filters and the keys of the values in the filters that were nil
  return [strippedFilters, nilKeys];
};

export const getProviderFunction = (
  typeOfCare?: ProviderFunction[]
): ProviderFunction | undefined => {
  if (!typeOfCare || typeOfCare.length === 0) {
    return undefined;
  }

  if (
    typeOfCare.includes(ProviderFunction.TALK_THERAPY) &&
    typeOfCare.includes(ProviderFunction.MEDICATION_MANAGEMENT)
  ) {
    return ProviderFunction.TALK_THERAPY_AND_MEDICATION_MANAGEMENT;
  } else if (typeOfCare.includes(ProviderFunction.MEDICATION_MANAGEMENT)) {
    return ProviderFunction.MEDICATION_MANAGEMENT;
  } else {
    return ProviderFunction.TALK_THERAPY;
  }
};

export const getDayOfWeekFromAvailabilityFilter = (
  availabilityFilters: string[]
): DayOfWeek[] => {
  const hasWeekendSelected = availabilityFilters.find(
    (f) => f === WeekdayFilter.WEEKEND
  );
  const hasWeekdaySelected = availabilityFilters.find(
    (f) => f === WeekdayFilter.WEEKDAY
  );

  const weekdayAvailabilityFilters = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
  ];
  const weekendAvailabilityFilters = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];

  if (hasWeekendSelected && !hasWeekdaySelected) {
    return weekendAvailabilityFilters;
  } else if (hasWeekdaySelected && !hasWeekendSelected) {
    return weekdayAvailabilityFilters;
  } else {
    return [];
  }
};

export const getTimeOfDayFromAvailabilityFilter = (
  availabilityFilters: string[]
): TimeOfDayFilter[] => {
  const timeOfDayFilterValues = Object.values(TimeOfDayFilter);
  return availabilityFilters.filter((f) =>
    timeOfDayFilterValues.includes(f as TimeOfDayFilter)
  ) as TimeOfDayFilter[];
};

export const getAvailableSpecialtiesForState = (
  specialties: PatientVisibleSpecialtyRead[] | SpecialtyRead[],
  selectedState: UnitedStates | undefined
): PatientVisibleSpecialtyRead[] | SpecialtyRead[] => {
  // Filters out chronic conditions and diabetes from specialties dropdown unless searching in New York
  // This prevents 0 provider searches for those specialties
  return specialties.filter((s) => {
    return (
      !['chronic_conditions', 'diabetes'].includes(s.key) ||
      selectedState === UnitedStates.NEW_YORK
    );
  });
};

export const filterUnavailableSpecialtyKeys = (
  specialties: PatientVisibleSpecialtyRead[] | SpecialtyRead[],
  selectedSpecialties: string[],
  selectedState: UnitedStates | undefined
): string[] => {
  return getAvailableSpecialtiesForState(specialties, selectedState)
    ?.filter((s) => selectedSpecialties.includes(s.key))
    ?.map((s) => s.key);
};

export const getCoordinatesFromCurrentFiltersAndGeolocationLadder = (
  currentFilters: Partial<Filters>,
  geolocationLadder: BoundedGeolocation[]
) => {
  const tightestBoundedGeolocation = geolocationLadder[
    geolocationLadder.length - 1
  ] as BoundedGeolocation | null;
  const lat = currentFilters?.lat ?? tightestBoundedGeolocation?.lat;
  const lng = currentFilters?.lon ?? tightestBoundedGeolocation?.lng;
  const upperLat =
    currentFilters?.upperLat ?? tightestBoundedGeolocation?.upperLat;
  const upperLng =
    currentFilters?.upperLon ?? tightestBoundedGeolocation?.upperLng;
  const lowerLat =
    currentFilters?.lowerLat ?? tightestBoundedGeolocation?.lowerLat;
  const lowerLng =
    currentFilters?.lowerLon ?? tightestBoundedGeolocation?.lowerLng;
  return { lat, lng, upperLat, upperLng, lowerLat, lowerLng };
};
