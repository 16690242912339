import { useContext, useEffect, useMemo, useState } from 'react';

import { ProgressNoteFormV2Values } from '../../components/forms/ProgressNote/ProgressNoteFormV2';
import { getInitialTemplateValues } from '../../components/forms/ProgressNote/Template/utils';
import { getTemplate } from '../../components/forms/ProgressNote/utils';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useProgressNoteInitialValues = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const {
    progressNote,
    progressNoteType,
    isEventLoading,
    isProgressNoteLoading,
    templates,
  } = useContext(AppointmentConfirmationContextV2);

  const isLoading = (isEventLoading || isProgressNoteLoading) && !templates;

  const initialValues: ProgressNoteFormV2Values = useMemo(() => {
    const noteJson = progressNote?.noteJson as {
      templateInfo?: { id: number; version: number };
      response?: Record<string, any>;
    };

    const template = getTemplate(
      templates,
      noteJson?.templateInfo?.id,
      noteJson?.templateInfo?.version
    );

    const initialTemplateValues = template
      ? getInitialTemplateValues(template, noteJson?.response)
      : noteJson?.response;

    return {
      progressNoteType: progressNoteType,
      template: noteJson?.templateInfo
        ? `${noteJson.templateInfo.id}-${noteJson.templateInfo.version}`
        : undefined,
      previousNote: undefined,
      lateEntryReason: progressNote?.lateEntryReason ?? undefined,
      lateEntryOtherReason: progressNote?.lateEntryOtherReason ?? undefined,
      ...initialTemplateValues,
    };
  }, [progressNote, templates, progressNoteType]);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
    }
  }, [isLoading, initialValues, isInitialized]);

  return { isInitialized: isInitialized, initialValues: initialValues };
};
