import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderProgressNotesQueryKeyArgs {
  providerAppointmentId?: number;
  patientId?: number;
  providerId?: number;
  templateId?: number;
  templateVersion?: number;
}

export const getUseProviderProgressNotesQueryKey = ({
  providerAppointmentId,
  patientId,
  providerId,
  templateId,
  templateVersion,
}: UseProviderProgressNotesQueryKeyArgs): [
  string,
  number | undefined,
  number | undefined,
  number | undefined,
  number | undefined,
  number | undefined,
] => [
  'provider-progress-notes',
  providerAppointmentId,
  patientId,
  providerId,
  templateId,
  templateVersion,
];

const {
  useSingleQuery: useProviderProgressNotes,
  useListQuery: useProviderProgressNotesList,
  useCachedQuery: useProviderProgressNotesCache,
} = createBasicApiHooks(
  getUseProviderProgressNotesQueryKey,
  ({
    providerAppointmentId,
    patientId,
    providerId,
    templateId,
    templateVersion,
  }) =>
    ProviderProgressNotesApi.findProviderProgressNotes({
      appointment_id: providerAppointmentId,
      patient_id: patientId,
      provider_id: providerId,
      template_id: templateId,
      template_version: templateVersion,
    }),
  ({ providerAppointmentId, patientId, providerId, templateId }) =>
    !!providerAppointmentId || !!(patientId && providerId && templateId),
  () => 'Failed to fetch provider progress notes'
);

export {
  useProviderProgressNotes,
  useProviderProgressNotesList,
  useProviderProgressNotesCache,
};
