import { useProvider } from 'hooks';
import moment from 'moment';
import { useEffect } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { ContentText } from '@headway/helix/ContentText';
import { IconCreditCardCheck } from '@headway/helix/icons/CreditCardCheck';
import { IconQuestion } from '@headway/helix/icons/Question';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { Warning } from '@headway/icons/dist/Warning';
import {
  INSURANCE_OUTAGE_EXTENDED_WAIT_TIME_DAYS,
  NO_DATA_FLAT_FEE,
} from '@headway/shared/constants/userInsuranceOutage';
import { trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import clockImage from 'assets/img/clock.png';
import clinicalLeadHeadshotImage from 'assets/img/michael_heckendorn.png';

import { useProviderPrice } from '../../hooks/useProviderPrice';
import { getPriceString } from '../../utils/price';

interface InsuranceOutageVerificationInProgressModalProps {
  client: UserRead | undefined;
  open: boolean;
  onClose: () => void;
}

export const InsuranceOutageVerificationInProgressModal = ({
  client,
  open,
  onClose,
}: InsuranceOutageVerificationInProgressModalProps) => {
  const provider = useProvider();
  const accumulatorsPresumedReset =
    client?.activeUserInsurance?.latestOutage?.accumulatorsPresumedReset;

  const { data: providerPrice, isLoading: providerPriceLoading } =
    useProviderPrice({
      provider,
      client,
    });

  useEffect(() => {
    if (open && client)
      trackPageView({
        name: 'Verification In Progress Modal Viewed',
        properties: {
          patientUserId: client.id,
          providerId: provider.id,
          userInsuranceId: `${client.activeUserInsuranceId}`,
          prelimPricingType: `${client.activeUserInsurance?.latestOutageType}`,
          presumptiveAccumulatorsApplied: accumulatorsPresumedReset ?? [],
        },
      });
  }, [open, client, provider.id]);

  if (!client || !client?.activeUserInsurance?.isInOutage) {
    return null;
  }

  const clientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });

  function getClientPaymentText(patient: UserRead) {
    const frontEndCarrierName =
      patient.activeUserInsurance?.frontEndCarrierName || 'their insurer';
    const accumulatorsPresumedReset =
      client?.activeUserInsurance?.latestOutage?.accumulatorsPresumedReset;
    const accumulatorsWereReset = (accumulatorsPresumedReset?.length ?? 0) > 0;
    const isClientInOldDataOutage =
      client?.activeUserInsurance?.isInOldDataOutage;
    const isClientInNoDataOutage =
      client?.activeUserInsurance?.isInNoDataOutage;
    if (isClientInNoDataOutage) {
      return (
        <ContentText>
          <strong>
            Confirm your client is okay with paying ${NO_DATA_FLAT_FEE} per
            session for now.{' '}
          </strong>
          Clients on Headway often pay around this much per session, though
          their final cost may be different. We’ll automatically refund or
          charge their payment method later if {frontEndCarrierName} confirms a
          different final cost.
        </ContentText>
      );
    }

    if (isClientInOldDataOutage && accumulatorsWereReset) {
      let priceString = '';
      if (!providerPriceLoading && providerPrice) {
        priceString = getPriceString(
          providerPrice.otherSessionsMinPrice,
          providerPrice.otherSessionsMaxPrice
        );
      }
      const priceStringHeader = priceString
        ? `Confirm your client is okay paying ${priceString} per session for now.`
        : 'Confirm your client is okay with paying full in-network cost per session for now.';
      return (
        <ContentText>
          <strong>{priceStringHeader}</strong>
          &nbsp; This price reflects our best estimate, and factors in a likely
          deductible or out-of-pocket max reset for their plan. We’ll
          automatically refund or charge their payment method later if{' '}
          {frontEndCarrierName} confirms a different final cost.
        </ContentText>
      );
    }

    if (isClientInOldDataOutage && !accumulatorsWereReset) {
      return (
        <ContentText>
          <strong>
            Confirm your client is okay continuing to pay their most recent cost
          </strong>
          , with a possible auto correction later if {frontEndCarrierName}{' '}
          confirms a different final cost.
        </ContentText>
      );
    }
  }

  const daysInOutage = moment().diff(
    moment(client?.activeUserInsurance?.latestOutageActivatedOn),
    'days'
  );
  const isInExtendedWaitTime =
    daysInOutage >= INSURANCE_OUTAGE_EXTENDED_WAIT_TIME_DAYS;
  const patientInsurerName =
    client?.activeUserInsurance?.billingFrontEndCarrierName ||
    `${clientFirstName}’s insurer`;
  return (
    <Modal
      title={
        <span className="text-black flex items-center gap-2">
          <Warning color={theme.color.primary.yellow} />
          Updates to client benefits temporarily unavailable
        </span>
      }
      isOpen={open}
      onDismiss={onClose}
    >
      <ModalContent>
        <div className="-ml-5 -mr-5 -mt-5 mb-7 bg-hue-lightYellow p-6 text-center">
          <img alt="Clock" src={clockImage} />
        </div>
        <div className="mb-4 mt-7">
          <ContentText>Hi {provider.displayFirstName},</ContentText>
        </div>
        <div className="mb-8">
          <ContentText>
            <>
              {patientInsurerName}
              ’s system is experiencing a high volume of requests, and needs
              more time to get us the information we need to verify your
              client’s benefits. Our team is working on a resolution and has
              shared this same information with your client. In the meantime,
              you can:
            </>
          </ContentText>
        </div>
        <div className="mb-8 rounded-[10px] bg-hue-lightYellow">
          <div className="flex flex-wrap gap-3 px-6 py-4">
            <IconCreditCardCheck />
            <div className="box-border flex flex-1">
              {getClientPaymentText(client)}
            </div>
          </div>
          <div className="flex gap-3 px-6 py-4">
            <div>
              <IconQuestion />
            </div>
            <CollapsibleDisclosure
              label="If your client would prefer to wait for a resolution"
              size="large"
            >
              <ContentText>
                <strong>
                  Remind your client they can cancel their next session free of
                  charge if they’d prefer to wait.
                </strong>{' '}
                If your client cancels within your cancellation window while
                verification is in progress, or up to 3 days after, Headway will
                still pay you the cancellation fee.
              </ContentText>
            </CollapsibleDisclosure>
          </div>
        </div>
        <div className="mb-8">
          <ContentText>
            {isInExtendedWaitTime
              ? `It’s taking longer than we expected to verify this client’s benefits.
            If we don’t hear anything more soon, our team will follow up with you and your client so we can escalate
            with ${patientInsurerName} together.`
              : `As soon as we have more information (typically 2-7 days), we’ll
            email you with an update. Thanks for your understanding!`}
          </ContentText>
        </div>
        <div>
          <div className="mb-3 flex items-center gap-2">
            <div>
              <img
                alt="Clinical Lead Headshot"
                src={clinicalLeadHeadshotImage}
                className="h-12 w-12"
              />
            </div>
            <div className="flex flex-col">
              <div className="block font-bold text-[17px]">
                Michael and team
              </div>
              <ContentText>Clinical Lead at Headway</ContentText>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="text-right">
          <Button onPress={onClose} variant="primary" size="large">
            Got it
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
