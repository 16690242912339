import { createContext, ReactNode, useState } from 'react';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';

export enum ManagementAssessmentStep {
  SELECT_ASSESSMENTS = 'SELECT_ASSESSMENTS',
  ATTESTATION = 'ATTESTATION',
  FILL_OUT_ASSESSMENTS = 'FILL_OUT_ASSESSMENTS',
  COMPLETE_ASSESSMENTS = 'COMPLETE_ASSESSMENTS',
  SCHEDULE_RECURRING = 'SCHEDULE_RECURRING',
}

export type FillOutAssessmentForClientContextType = {
  currentStep?: ManagementAssessmentStep;
  setCurrentStep: (step: ManagementAssessmentStep) => void;
  setSelectedAssessments: (assessments: PatientAssessmentType[]) => void;
  selectedAssessments: PatientAssessmentType[];
};

export const FillOutAssessmentModalContext =
  createContext<FillOutAssessmentForClientContextType>({
    currentStep: undefined,
    setCurrentStep: () => {},
    setSelectedAssessments: () => {},
    selectedAssessments: [],
  });

export const FillOutAssessmentForClientModalContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [currentStep, setCurrentStep] = useState<
    ManagementAssessmentStep | undefined
  >(undefined);
  const [selectedAssessments, setSelectedAssessments] = useState<
    PatientAssessmentType[]
  >([]);

  const contextValue: FillOutAssessmentForClientContextType = {
    currentStep,
    setCurrentStep: (step: ManagementAssessmentStep) => {
      setCurrentStep(step);
    },
    setSelectedAssessments: (assessments: PatientAssessmentType[]) => {
      setSelectedAssessments(assessments);
    },
    selectedAssessments,
  };

  return (
    <FillOutAssessmentModalContext.Provider value={contextValue}>
      {children}
    </FillOutAssessmentModalContext.Provider>
  );
};
