import { Email } from '@mui/icons-material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { ContentText } from '@headway/helix/ContentText';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { IconButton } from '@headway/helix/IconButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextArea } from '@headway/helix/TextArea';
import { TextField } from '@headway/helix/TextField';
import { toasts } from '@headway/helix/Toast';
import { useQueryClient } from '@headway/shared/react-query';

import {
  consentToMarketing,
  ExtoleFriend,
  ExtoleResponse,
  IEmailDetails,
  sendReferralEmail,
  trackCTAEvents,
} from '../hooks/extole';

const formSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Required'),
  personalNote: Yup.string(),
});

const ReferralEmailSideSheet = ({
  advocateCode,
  accessToken,
  campaignId,
  emailDetails,
  setTrackerIsOpen,
}: {
  advocateCode?: string;
  accessToken?: string;
  campaignId?: string;
  emailDetails?: IEmailDetails;
  setTrackerIsOpen: (isOpen: boolean) => void;
}) => {
  if (!advocateCode || !accessToken) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const onSubmit = async ({
    email,
    personalNote,
    marketingConsent,
  }: {
    email: string;
    personalNote: string;
    marketingConsent: boolean;
  }) => {
    try {
      await sendReferralEmail({
        email,
        message: personalNote,
        advocateCode,
        token: accessToken,
        campaignId,
      });
      consentToMarketing({
        token: accessToken,
        consent: marketingConsent,
      });

      setIsOpen(false);
      toasts.add('We’ve received your referral.', {
        variant: 'positive',
        actionLabel: 'See referrals',
        onAction: () => {
          // refresh user referrals
          queryClient.setQueryData(
            ['userReferralData', accessToken],
            (cache: ExtoleResponse['data'] | undefined) => {
              if (!cache) return;

              const newReferral: ExtoleFriend = {
                firstNameOrEmail: email,
                initials: email[0],
                email: email,
                profilePictureUrl: '',
                status: 'Shared',
                statusDate: new Date().toISOString(),
                referralDate: new Date().toISOString(),
              };
              // check the last 10 entries to see if
              // the referral already exists to prevent duplicates
              const referralCount = cache.me.friends.length;
              const maxIndex = Math.min(referralCount - 10, 0);
              for (let i = referralCount - 1; i > maxIndex; i--) {
                if (cache.me.friends[i].email === newReferral.email) {
                  return cache;
                }
              }
              return {
                ...cache,
                me: {
                  ...cache.me,
                  friends: [...cache.me.friends, newReferral],
                },
              };
            }
          );
          setTrackerIsOpen(true);
        },
      });
    } catch (err) {
      toasts.add('There was an issue sending your referral.', {
        variant: 'negative',
      });
    }
  };

  return (
    <>
      <IconButton
        type="button"
        variant="transparent"
        size="medium"
        onPress={() => {
          setIsOpen(true);
          trackCTAEvents({
            eventName: 'email_referral_clicked',
            token: accessToken,
          });
        }}
      >
        <Email />
      </IconButton>
      <Modal
        variant="sidesheet"
        title="Email a provider"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <Formik
          validationSchema={formSchema}
          initialValues={{
            email: '',
            personalNote:
              emailDetails?.message ??
              `I love using Headway to run my best practice — and I think you will too! If you join using my link, you’ll get $100 after your first session. These are definitely rewards worth sharing. \n\nHeadway helped me get credentialed fast. Since then, I’ve been accepting insurance with ease, earning enhanced rates paid bi-weekly, and running my entire practice from a single place without ever paying a membership fee. I highly recommend it. \n\nSimply use my link to get started.`,
            marketingConsent: true,
          }}
          onSubmit={onSubmit}
        >
          {({ values, isSubmitting }) => {
            return (
              <>
                <ModalContent>
                  <Form id="Referral-email-intake">
                    <ContentText>
                      Email your referral and invite them to join Headway.
                    </ContentText>
                    <FormControl
                      autoFocus={true}
                      component={TextField}
                      name="email"
                      label="Send to (Email address)"
                    />
                    <FormControl
                      component={TextArea}
                      optionalityText="Optional"
                      label="Personal note"
                      name="personalNote"
                      value={values.personalNote}
                    />
                    <FormControl component={Checkbox} name="marketingConsent">
                      I agree to receive marketing communications from Headway.
                    </FormControl>
                  </Form>
                </ModalContent>
                <ModalFooter>
                  <Button
                    form="Referral-email-intake"
                    type="submit"
                    variant="primary"
                    size="large"
                    disabled={isSubmitting}
                  >
                    Send email
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ReferralEmailSideSheet;
