import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { AppointmentConfirmationModalFormV2Values } from '~/legacy/views/AppointmentConfirmation/components/modals/AppointmentConfirmationModalV2';

import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';

import { ComponentMap } from '../componentsV2';
import { isComponentRequired } from '../utils';
import { GenericTemplate } from './types';
import {
  Component,
  ProgressNoteComponentMetadata,
  TemplateV3,
} from './v3/types';

type TemplateRenderedComponentV2Props<T> = {
  component: Component<T>;
  disabled?: boolean;
  template?: GenericTemplate<TemplateV3<T>>['template'];
};

const doesEnforcePrefillUniqueness = (
  componentType: keyof typeof ComponentMap
) =>
  componentType === 'shortFreeText' ||
  componentType === 'longFreeText' ||
  componentType === 'richFreeText';

export function TemplateRenderedComponentV2<T>({
  component,
  template,
  disabled,
}: TemplateRenderedComponentV2Props<T>) {
  const { control } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const selectedCptCodes = useWatch<AppointmentConfirmationModalFormV2Values>({
    name: 'sessionDetails.cptCodes',
  });

  const metadata = component.metadata as
    | ProgressNoteComponentMetadata
    | undefined;

  const transformedCptCodes = useMemo(
    () =>
      selectedCptCodes?.map((codeInfo: CPTCodeInfo) => codeInfo.value) || [],
    [selectedCptCodes]
  );

  const isOptional = useMemo(
    () => !isComponentRequired(transformedCptCodes, metadata),
    [transformedCptCodes, metadata]
  );

  const { prefillFromLastSession, requirePrefillUniqueness = true } =
    metadata ?? {};

  const { id, type: componentType } = component;

  // Watch the current value for the given field id
  const value = useWatch({
    name: id as keyof AppointmentConfirmationModalFormV2Values,
  });

  // Watch the value of 'previousNote'
  const previousNote = useWatch({
    name: 'progressNote.previousNote',
  });

  // Access the initial value (if needed) from the form's defaultValues
  const { defaultValues } = control._formState;
  const initialValue =
    defaultValues?.[id as keyof AppointmentConfirmationModalFormV2Values];

  const requiresEdit = useMemo(
    () =>
      !!previousNote &&
      prefillFromLastSession &&
      requirePrefillUniqueness &&
      doesEnforcePrefillUniqueness(componentType) &&
      value === initialValue &&
      !(isOptional && !value) &&
      !disabled,
    [
      previousNote,
      prefillFromLastSession,
      requirePrefillUniqueness,
      componentType,
      value,
      initialValue,
      isOptional,
      disabled,
    ]
  );

  const Component = ComponentMap[componentType];

  const modifiedId = useMemo(() => `progressNote.${id}`, [id]);

  return (
    <Component
      isOptional={isOptional}
      template={template}
      disabled={disabled}
      element={{ ...component, id: modifiedId }}
      requiresEdit={requiresEdit}
    />
  );
}
