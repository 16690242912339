import { useState } from 'react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';

import { GenericTemplate } from '../Renderer/types';
import { getComponentIdToComponentMap } from '../utils';

export const findValueNotInTemplateComponentOptions = (
  id: string,
  template: GenericTemplate<unknown>,
  values: string[] | string | undefined
): string => {
  if (!values) {
    return '';
  }

  const componentToIdMap = getComponentIdToComponentMap(template);

  let valuesArray = [];
  if (Array.isArray(values)) {
    valuesArray = [...values];
  } else {
    valuesArray.push(values);
  }

  const difference = valuesArray.filter(
    (x) => !componentToIdMap[id].options.find((option: string) => option === x)
  );

  return difference[0];
};

const otherPrefix = 'Other-';

const createOtherValue = (value: string) => {
  return `${otherPrefix}${value}`;
};

const splitOtherFromValue = (value: string | undefined) => {
  if (!value) {
    return value;
  }

  if (!value.includes(otherPrefix)) {
    return value;
  }

  if (value.substring(0, 6) !== otherPrefix) {
    return value;
  }

  return value.substring(6, value.length);
};

export interface OtherTextFieldProps {
  id: string;
  optionType: string;
  option: any;
  template: any;
  children?: (value: any) => React.ReactNode;
  disabled: boolean;
}

/**
 * A wrapper for an "Other" option in a radio or checkbox group, which displays a free text field
 * if selected.
 */
export const OtherTextField = ({
  id,
  optionType,
  option,
  template,
  children,
  disabled,
}: OtherTextFieldProps) => {
  const { control, setValue, trigger } = useFormContext();
  const watchedValue = useWatch({
    control,
    name: id,
  });

  // Saved state always reflects "Other-[OtherValue]"
  const [otherText, setOtherText] = useState('');

  // Takes out the ["Other-[Othervalue]"] from field.values
  const removeOtherTextFromValues = (value: string[] | string) => {
    if (Array.isArray(value)) {
      const otherTextIndex = value.findIndex(
        (value: string) => value === otherText
      );
      if (otherTextIndex !== -1) {
        const newValues = [...value];
        newValues.splice(otherTextIndex, 1);
        return newValues;
      } else {
        return [...value];
      }
    } else {
      return '';
    }
  };

  // When our input updates - remove existing other value and set our new one
  const onOtherTextChange = (newOtherText: string) => {
    if (!watchedValue) {
      return;
    }

    const trimmedValues = removeOtherTextFromValues(watchedValue);

    if (Array.isArray(trimmedValues)) {
      trimmedValues.push(newOtherText);
      setValue(id, trimmedValues);
    } else {
      setValue(id, newOtherText);
    }

    setOtherText(newOtherText);
    trigger(id);
  };

  const isOtherSelected =
    watchedValue !== undefined &&
    option.includes('Other') &&
    (optionType === 'multiple'
      ? Array.isArray(watchedValue)
        ? watchedValue.find((selectedOption: string) =>
            selectedOption.includes('Other')
          )
        : false
      : watchedValue.includes('Other'));

  // Check if we need to clean up our data state for
  // components with multiple responses (checklists, dropdowns)
  if (isOtherSelected) {
    if (Array.isArray(watchedValue)) {
      // Hanging "Other-" around
      const otherEmptyPrefixIndex = watchedValue.find(
        (value) => value === otherPrefix
      );

      // A user entered value like "Other-UserAddedOption" exists
      const userOtherValueExistsIndex = watchedValue.find(
        (value) => value !== otherPrefix && value.includes(otherPrefix)
      );

      // The "Other" option that is passed to the component
      const otherOptionIsSelectedIndex = watchedValue.find(
        (value) =>
          value !== otherPrefix &&
          !value.includes(otherPrefix) &&
          value.includes('Other')
      );

      let otherCleanupHappened = false;
      const values = [...watchedValue];

      // 1. We have an "Other-" hanging around
      if (otherEmptyPrefixIndex) {
        values.splice(otherEmptyPrefixIndex);
        otherCleanupHappened = true;
      }

      // 2. We have an "Other-UserAddedOption" hanging around
      // but the "Other" option isn't selected by the user
      if (!otherOptionIsSelectedIndex && userOtherValueExistsIndex) {
        values.splice(userOtherValueExistsIndex);
        otherCleanupHappened = true;
      }

      if (otherCleanupHappened) {
        setValue(id, values);
        return null;
      }
    }
  }

  if (isOtherSelected) {
    // Will be "Other-[OtherValue]"
    const value = findValueNotInTemplateComponentOptions(
      id,
      template,
      watchedValue
    );

    // Initialize otherText if it hasn't been done already
    if (value && otherText === '') {
      setOtherText(value);
    }

    // Remove "Other-" from our value
    const splitValue = value !== '' ? splitOtherFromValue(value) : '';

    return children ? (
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          [theme.__futureMedia.phone]: {
            flexDirection: 'column',
          },
        }}
      >
        {children(optionType === 'multiple' ? option : value)}
        <div
          css={{
            width: '100%',
            marginTop: '-14px',
            marginLeft: '10px',
            [theme.__futureMedia.phone]: {
              marginLeft: 0,
              marginTop: '-8px',
            },
          }}
        >
          <TextField
            name="Other"
            value={splitValue}
            onChange={(value) => {
              onOtherTextChange(createOtherValue(value));
            }}
            disabled={disabled}
          />
        </div>
      </div>
    ) : (
      <TextField
        label={'Other (please specfiy)'}
        name="Other"
        value={splitValue}
        onChange={(value) => {
          onOtherTextChange(createOtherValue(value));
        }}
        disabled={disabled}
      />
    );
  } else {
    // Reset things if other isn't selected anymore
    if (otherText !== '') {
      setValue(id, removeOtherTextFromValues(watchedValue));
      setOtherText('');
    }

    return children ? <>{children(option)}</> : null;
  }
};
