import keyBy from 'lodash/keyBy';
import React from 'react';

import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';

interface ProviderFrontEndCarrierProviderProps {
  providerId: number;
}

interface ProviderFrontEndCarrierProviderState {
  isLoading: boolean;
  error?: Error;
  providerFrontEndCarriers: ProviderFrontEndCarrierRead[];
  providerFrontEndCarriersById: { [key: number]: ProviderFrontEndCarrierRead };
}

export type ProviderFrontEndCarrierContextState =
  ProviderFrontEndCarrierProviderState & {
    reload: () => void;
  };

export const ProviderFrontEndCarrierContext =
  React.createContext<ProviderFrontEndCarrierContextState>({
    isLoading: true,
    providerFrontEndCarriers: [],
    providerFrontEndCarriersById: {},
    reload: () => {},
  });

export class ProviderFrontEndCarrierProvider extends React.Component<
  React.PropsWithChildren<ProviderFrontEndCarrierProviderProps>,
  ProviderFrontEndCarrierProviderState
> {
  state: ProviderFrontEndCarrierProviderState = {
    isLoading: true,
    providerFrontEndCarriers: [],
    providerFrontEndCarriersById: {},
  };

  componentDidMount() {
    this.fetchProviderFrontEndCarriers();
  }

  componentDidUpdate(prevProps: ProviderFrontEndCarrierProviderProps) {
    if (prevProps.providerId !== this.props.providerId) {
      this.fetchProviderFrontEndCarriers();
    }
  }

  fetchProviderFrontEndCarriers = async () => {
    this.setState({ error: undefined, isLoading: true });

    try {
      const providerFrontEndCarriers =
        await ProviderFrontEndCarrierApi.getProviderFrontEndCarriers({
          provider_id: this.props.providerId,
        });

      this.setState({
        providerFrontEndCarriers,
        providerFrontEndCarriersById: keyBy(
          providerFrontEndCarriers,
          'frontEndCarrier.id'
        ),
      });
    } catch (error: unknown) {
      this.setState({ error: error as Error });
    }
    this.setState({ isLoading: false });
  };

  reload = () => {
    this.fetchProviderFrontEndCarriers();
  };

  render() {
    return (
      <ProviderFrontEndCarrierContext.Provider
        value={{
          ...this.state,
          reload: this.reload,
        }}
      >
        {this.props.children}
      </ProviderFrontEndCarrierContext.Provider>
    );
  }
}
