import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseGetIsPromsScheduleRequiredQueryKeyArgs {
  providerPatientId: number;
}

export const getUseIsPromsScheduleRequiredQueryKey = ({
  providerPatientId,
}: UseGetIsPromsScheduleRequiredQueryKeyArgs): [string, number] => [
  'get-use-is-proms-schedule-required',
  providerPatientId,
];

const {
  useSingleQuery: useGetIsPromsScheduleRequired,
  useListQuery: useGetIsPromsScheduleRequiredList,
  useCachedQuery: useGetIsPromsScheduleRequiredCache,
} = createBasicApiHooks(
  getUseIsPromsScheduleRequiredQueryKey,
  ({ providerPatientId }) => {
    return ProviderPatientApi.getIsPromsScheduleRequired(providerPatientId);
  },
  ({ providerPatientId }) => !!providerPatientId,
  () => 'Failed to fetch provider patient proms schedule requirement'
);

export {
  useGetIsPromsScheduleRequired,
  useGetIsPromsScheduleRequiredList,
  useGetIsPromsScheduleRequiredCache,
};
