import * as Yup from 'yup';
import { ObjectSchema } from 'yup';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { theme } from '@headway/helix/theme';
import { ENABLE_SUPERVISING_PHYSICIANS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { statesRequiringCDS } from '../../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { RestrictedPracticeAuthority } from '../../utils/supervisingPhysicianConfig';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import {
  getSupervisingPhysicianRequirement,
  IDCertificationType,
  ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep,
  removeSupervisingPhysicianValuesFromRawData,
  SupervisingPhysicianStatusOption,
} from './helpers';
import { getCertificateValidationSchema } from './IDCertificationForm';
import { NpiVerificationModal } from './NpiVerificationModal';
import { StateCertsForm } from './StateCertsForm';
import { getSupervisingPhysicianValidationSchema } from './StateSupervisingPhysiciansForm';
import { useNpiVerification } from './useNpiVerification';

const PrescriberRequirements = ({
  formikHelpers,
  initialValues,
  showConfirmationModal,
  setShowConfirmationModal,
  onStepComplete,
}: CustomComponentProps) => {
  const enableSupervisingPhysicians = useFlag(
    ENABLE_SUPERVISING_PHYSICIANS,
    false
  );
  const formValues =
    formikHelpers.values as ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
  const { provider, providerQuestionnaire } = useQuestionnaireContext();
  const providerStates = getSupportedStates(provider);
  const inputtedNpis = Object.values(
    formValues.userInputtedSupervisingPhysicians ?? {}
  )
    .filter(
      (sp) =>
        sp.hasSupervisingPhysician === SupervisingPhysicianStatusOption.YES
    )
    .map((sp) => sp.npiOfSupervisingPhysician);

  const { npiLookupResults } = useNpiVerification({
    npis: inputtedNpis,
  });

  // if raw data has dea/cds from caqh but no user inputted yet, then we have pre-populated form with caqh data
  const { rawData } = providerQuestionnaire;
  const isFormPrepoulatedWithCAQHData =
    !!rawData?.deaCertificates?.length &&
    !!rawData?.cdsCertificates?.length &&
    !rawData?.userInputtedDeaCertificates &&
    !rawData?.userInputtedCdsCertificates;

  const statesWithSupervisingPhysicianQuestions = providerStates.filter(
    (state) =>
      getSupervisingPhysicianRequirement(
        state,
        provider,
        enableSupervisingPhysicians
      ) !== RestrictedPracticeAuthority.FULL
  );

  // after the first state with a 'yes' answer for supervising physician, we should show the option to reuse
  // the first supervising physician for subsequent states
  const firstStateWithSupervisingPhysician = providerStates.find(
    (state) =>
      formValues.userInputtedSupervisingPhysicians?.[state]
        ?.hasSupervisingPhysician === SupervisingPhysicianStatusOption.YES
  );
  const shouldIncludeReuseFirstSupervisingPhysicianOption = (
    state: UnitedStates
  ) =>
    firstStateWithSupervisingPhysician !== undefined &&
    statesWithSupervisingPhysicianQuestions.indexOf(state) >
      statesWithSupervisingPhysicianQuestions.indexOf(
        firstStateWithSupervisingPhysician
      );

  return (
    <>
      <BodyText>
        As a prescriber, you are required to have a DEA (Drug Enforcement
        Administration) registration in every state where you practice on
        Headway. Some states also require a CDS (Controlled Dangerous
        Substances) license.
      </BodyText>
      {isFormPrepoulatedWithCAQHData && (
        <GuidanceCard variant="neutral">
          We pre-filled this form with information from your CAQH account.
        </GuidanceCard>
      )}
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: '24px',
        }}
      >
        {providerStates.map((state) => (
          <StateCertsForm
            key={state}
            state={state}
            initialValues={initialValues}
            formValues={formValues}
            supervisingPhysicianRequirement={getSupervisingPhysicianRequirement(
              state,
              provider,
              enableSupervisingPhysicians
            )}
            includeReuseFirstSupervisingPhysicianOption={shouldIncludeReuseFirstSupervisingPhysicianOption(
              state
            )}
          />
        ))}
      </div>
      <NpiVerificationModal
        isOpen={showConfirmationModal}
        onDismiss={() => setShowConfirmationModal(false)}
        npiLookupResults={npiLookupResults}
        formValues={formValues}
        onSubmit={() => {
          const newValues =
            removeSupervisingPhysicianValuesFromRawData(formValues);
          onStepComplete(newValues);
        }}
      />
    </>
  );
};

type PerStateValidation = {
  [state in UnitedStates]: ObjectSchema;
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Prescriber Requirements',
  description:
    'As a prescriber, you are required to have a DEA (Drug Enforcement Administration) registration in every state where you practice on Headway. Some states also require a CDS (Controlled Dangerous Substances) license.',
  recredDescription: 'Please verify the following fields:',
  Component: PrescriberRequirements,
  onBeforeSubmit: async (values, _, showConfirmationModal) => {
    const valuesExtended =
      values as ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
    // show confirmation modal only if there are supervising physicians with NPIs to verify
    const hasSupervisingPhysicians = Object.values(
      valuesExtended.userInputtedSupervisingPhysicians ?? {}
    ).some(
      (sp) =>
        sp.hasSupervisingPhysician === SupervisingPhysicianStatusOption.YES
    );

    if (hasSupervisingPhysicians) {
      showConfirmationModal(true);
      return false; // don't submit form yet
    }
    const newValues =
      removeSupervisingPhysicianValuesFromRawData(valuesExtended);
    return newValues; // submit form without supervising physician info
  },
  getFormMeta: ({ provider, providerQuestionnaire }, flags) => {
    const providerStates = provider.activeProviderLicenseStates.map(
      (pls) => pls.state
    );
    const validationSchema = Yup.object().shape({
      userInputtedDeaCertificates: Yup.object().shape(
        providerStates.reduce((acc, state) => {
          acc[state] = getCertificateValidationSchema(IDCertificationType.DEA);
          return acc;
        }, {} as PerStateValidation)
      ),
      userInputtedCdsCertificates: Yup.object().shape(
        providerStates
          .filter((state) => statesRequiringCDS.has(state))
          .reduce((acc, state) => {
            acc[state] = getCertificateValidationSchema(
              IDCertificationType.CDS
            );
            return acc;
          }, {} as PerStateValidation)
      ),
      userInputtedSupervisingPhysicians: Yup.object().shape(
        providerStates.reduce((acc, state) => {
          acc[state] = getSupervisingPhysicianValidationSchema(
            state,
            provider,
            flags
          );
          return acc;
        }, {} as PerStateValidation)
      ),
    });

    const { rawData } = providerQuestionnaire;
    // user inputted certificate values come from:
    //   1. raw data if it has user inputted data already
    //   2. raw data if it has CAQH deaCertificates/cdsCertificates
    //   3. default empty data otherwise
    const userInputtedCertificateValues = {
      userInputtedDeaCertificates:
        rawData?.userInputtedDeaCertificates ||
        ({} as NonNullable<
          ProviderQuestionnaireRawData['userInputtedDeaCertificates']
        >),
      userInputtedCdsCertificates:
        rawData?.userInputtedCdsCertificates ||
        ({} as NonNullable<
          ProviderQuestionnaireRawData['userInputtedCdsCertificates']
        >),
    };
    providerStates.forEach((state) => {
      if (!userInputtedCertificateValues.userInputtedDeaCertificates[state]) {
        const deaFromRawData = rawData?.deaCertificates?.find(
          (cert) => cert.state === state
        );
        userInputtedCertificateValues.userInputtedDeaCertificates[state] =
          deaFromRawData
            ? { ...deaFromRawData }
            : {
                state,
                expirationDate: '',
                number: '',
              };
      }

      const isStateRequiringCDS = statesRequiringCDS.has(state);
      if (
        isStateRequiringCDS &&
        !userInputtedCertificateValues.userInputtedCdsCertificates[state]
      ) {
        const cdsFromRawData = rawData?.cdsCertificates?.find(
          (cert) => cert.state === state
        );
        userInputtedCertificateValues.userInputtedCdsCertificates[state] =
          cdsFromRawData
            ? { ...cdsFromRawData }
            : {
                state,
                expirationDate: '',
                number: '',
              };
      }
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData,
        userInputtedCertificateValues
      ),
    } as FormMeta;
  },
};

export default stepConfig;
