/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PCL5Submission {
    PCL5_1: number;

    PCL5_10: number;

    PCL5_11: number;

    PCL5_12: number;

    PCL5_13: number;

    PCL5_14: number;

    PCL5_15: number;

    PCL5_16: number;

    PCL5_17: number;

    PCL5_18: number;

    PCL5_19: number;

    PCL5_2: number;

    PCL5_20: number;

    PCL5_3: number;

    PCL5_4: number;

    PCL5_5: number;

    PCL5_6: number;

    PCL5_7: number;

    PCL5_8: number;

    PCL5_9: number;

    assessmentType?: PCL5SubmissionAssessmentType;

}


    export enum PCL5SubmissionAssessmentType {
            PCL5 = 'PCL5'
    }

