import { theme } from '@headway/helix/theme';
import { SESSION_CONFIRMATION_FLOW_REVAMP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { ProgressNoteComponentMetadata } from '../../../types';
import {
  doesComponentExpectAnArrayResponse,
  isElementASection,
} from '../../utils';
import { TemplateRenderedComponent } from '../TemplateRenderedComponent';
import { TemplateRenderedComponentV2 } from '../TemplateRenderedComponentV2';
import { GenericTemplate, TemplateValues } from '../types';
import { Component, Section, TemplateV1 } from './types';

type SchemaRendererProps<T> = {
  template?: GenericTemplate<TemplateV1<T>>['template'];
  disabled?: boolean;
};

export function getInitialTemplateValues<T>(
  template?: TemplateV1<T>,
  values?: TemplateValues
) {
  const setInitialValue = (
    obj: any,
    component: Component<ProgressNoteComponentMetadata>
  ) => {
    let initialValue: string | string[] = '';
    if (values?.[component.id]) {
      initialValue = values[component.id];
    } else if (doesComponentExpectAnArrayResponse(component)) {
      initialValue = [];
    }
    return (obj[component.id] = initialValue);
  };

  const obj: any = {};

  if (template) {
    for (let i = 0; i < template.length; i++) {
      const element: any = template[i];

      if (isElementASection(element)) {
        (
          element.components as Component<ProgressNoteComponentMetadata>[]
        ).forEach((component: Component<ProgressNoteComponentMetadata>) =>
          setInitialValue(obj, component)
        );
      } else setInitialValue(obj, element);
    }
  }

  return obj;
}

export function Renderer<T>({ template, disabled }: SchemaRendererProps<T>) {
  const isSessionConfirmationFlowRevampEnabled = useFlag(
    SESSION_CONFIRMATION_FLOW_REVAMP,
    false
  );

  return template ? (
    <>
      {template.map((element: Section<T> | Component<T>, i: number) => {
        if (isElementASection<T>(element)) {
          const section = element as Section<T>;
          return (
            <div
              key={i}
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x3,
              }}
            >
              <p
                css={[
                  theme.typography.list,
                  {
                    color: disabled
                      ? theme.color.system.disabledGray
                      : theme.color.system.textBlack,
                  },
                ]}
              >
                {section.header}
              </p>
              {(section.components as Component<T>[]).map(
                (component: Component<T>) => {
                  return isSessionConfirmationFlowRevampEnabled ? (
                    <TemplateRenderedComponentV2
                      disabled={disabled}
                      component={component}
                      template={template}
                    />
                  ) : (
                    <TemplateRenderedComponent
                      disabled={disabled}
                      component={component}
                      template={template}
                    />
                  );
                }
              )}
            </div>
          );
        } else {
          const component = element as Component<T>;
          return isSessionConfirmationFlowRevampEnabled ? (
            <TemplateRenderedComponentV2
              disabled={disabled}
              component={component}
              template={template}
            />
          ) : (
            <TemplateRenderedComponent
              disabled={disabled}
              component={component}
              template={template}
            />
          );
        }
      })}
    </>
  ) : null;
}
