import { QueryKey } from '@tanstack/react-query';

import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseGroupPracticeSessionsByProviderQueryKeyArgs {
  groupPracticeId: number;
  query?: Parameters<typeof GroupPracticeApi.getSessionsByProvider>[1];
}

export const getUseGroupPracticeSessionsByProviderQueryKey = ({
  groupPracticeId,
  query,
}: UseGroupPracticeSessionsByProviderQueryKeyArgs): [
  string,
  number,
  Parameters<typeof GroupPracticeApi.getSessionsByProvider>[1] | undefined,
] => ['group-practice-sessions-by-provider', groupPracticeId, query];

export const isGroupPracticeSessionsByProviderQueryKey = (
  queryKey: QueryKey
): queryKey is ReturnType<
  typeof getUseGroupPracticeSessionsByProviderQueryKey
> => queryKey[0] === 'group-practice-sessions-by-provider' && !!queryKey[1];

const {
  useSingleQuery: useGroupPracticeSessionsByProvider,
  useListQuery: useGroupPracticeSessionsByProviderList,
  useCachedQuery: useGroupPracticeSessionsByProviderCache,
} = createBasicApiHooks(
  getUseGroupPracticeSessionsByProviderQueryKey,
  ({ groupPracticeId, query }) => {
    return GroupPracticeApi.getSessionsByProvider(groupPracticeId, query);
  },
  ({ groupPracticeId }) => !!groupPracticeId,
  () => 'Failed to fetch group practice sessions by provider'
);

export {
  useGroupPracticeSessionsByProvider,
  useGroupPracticeSessionsByProviderList,
  useGroupPracticeSessionsByProviderCache,
};
