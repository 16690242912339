import { GenericTemplate } from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/Renderer/types';

export type TemplateFilter = (
  templateList: GenericTemplate<unknown>[]
) => GenericTemplate<unknown>[];

export const DefaultTemplateFilters: { [key: string]: TemplateFilter } = {
  Identity: (templates: GenericTemplate<unknown>[]) => templates,
  RemoveFreeText: (templates: GenericTemplate<unknown>[]) => {
    return templates.filter(
      (filter) => filter.templateInfo.name.trim().toLowerCase() !== 'freetext'
    );
  },
  TherapyIntakeFormOnly: (templates: GenericTemplate<unknown>[]) => {
    return templates.filter((template) => template.templateInfo.id === 29);
  },
};
