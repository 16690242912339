import { useCallback, useContext, useMemo } from 'react';
import * as Yup from 'yup';
import { useMedicareOrMedicaid } from '~/legacy/hooks/useMedicareOrMedicaid';

import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';
import { PROGRESS_NOTES_LATE_ENTRY } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import {
  createYupSchema,
  TemplateErrorConfig,
} from '../../components/forms/ProgressNote/Template/errorsV2';
import {
  createComponentErrorMapV2,
  getTemplates,
} from '../../components/forms/ProgressNote/Template/utils';
import { ProgressNoteComponentMetadata } from '../../components/forms/ProgressNote/types';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';
import { useProviderProgressNoteErrors } from '../utils/useProviderProgressNoteErrors';

export const useProgressNotesValidationSchema = (
  selectedProgressNoteTemplateInfo?: string,
  selectedCptCodes?: CPTCodeInfo[]
) => {
  const [selectedNoteTemplateId, selectedNoteTemplateVersion] =
    selectedProgressNoteTemplateInfo?.split('-').map(Number) ?? [
      undefined,
      undefined,
    ];
  const {
    event,
    patient,
    progressNoteErrorsFromSubmitting,
    previousNoteInitialValues,
  } = useContext(AppointmentConfirmationContextV2);

  const progressNotesLateEntryEnabled = useFlag(
    PROGRESS_NOTES_LATE_ENTRY,
    false
  );
  const isMedicareOrMedicaid = useMedicareOrMedicaid(patient?.id);
  const hoursSinceEndofAppointment = event?.endDate
    ? Math.floor(
        (new Date().valueOf() - new Date(event.endDate).valueOf()) /
          (1000 * 3600)
      )
    : 0;

  const showLateEntrySection = useMemo(
    () =>
      progressNotesLateEntryEnabled &&
      ((isMedicareOrMedicaid && hoursSinceEndofAppointment >= 48) ||
        (!isMedicareOrMedicaid && hoursSinceEndofAppointment >= 72)),
    [
      progressNotesLateEntryEnabled,
      isMedicareOrMedicaid,
      hoursSinceEndofAppointment,
    ]
  );

  const { data: initialProgressNoteErrors } = useProviderProgressNoteErrors(
    {
      providerAppointmentId: event?.providerAppointment?.id!,
      noteJson: JSON.stringify({
        templateInfo: {
          id: selectedNoteTemplateId,
          name: undefined,
          version: selectedNoteTemplateVersion,
          schemaVersion: undefined,
        },
        response: {},
      }),
    },
    {
      enabled: !!selectedNoteTemplateId && !!selectedNoteTemplateVersion,
    }
  );

  const lateEntrySchema = useMemo(() => {
    return showLateEntrySection
      ? {
          lateEntryReason: Yup.string()
            .nullable()
            .required('Please select a reason for late entry'),
          lateEntryOtherReason: Yup.string()
            .nullable()
            .when('lateEntryReason', {
              is: ProviderProgressNoteLateEntryReason.OTHER,
              then: Yup.string().nullable().required('Please provide a reason'),
            }),
        }
      : undefined;
  }, [showLateEntrySection]);

  const templates = useMemo(
    () => getTemplates<ProgressNoteComponentMetadata>(),
    []
  );
  const getTemplate = useCallback(
    (id?: number, version?: number) =>
      id !== undefined && version !== undefined && templates
        ? templates.find(
            (t) =>
              t.templateInfo.id === id && t.templateInfo.version === version
          )
        : undefined,
    [templates]
  );

  const progressNoteSchema = useMemo(() => {
    if (
      initialProgressNoteErrors &&
      initialProgressNoteErrors.length > 0 &&
      selectedNoteTemplateId &&
      selectedNoteTemplateVersion
    ) {
      const template = getTemplate(
        selectedNoteTemplateId,
        selectedNoteTemplateVersion
      );
      const componentErrorMap = createComponentErrorMapV2(
        progressNoteErrorsFromSubmitting /*&&
          (progressNote?.noteJson as NoteJson).templateInfo.id ===
            selectedNoteTemplateId*/
          ? progressNoteErrorsFromSubmitting
          : initialProgressNoteErrors,
        template!
      );

      const componentErrorMapAsArray = Object.entries(componentErrorMap).map(
        ([key, val]: [string, TemplateErrorConfig]) => ({
          id: key,
          type: val?.type,
          validationType: val?.validationType,
          validations: val?.validations,
          isUnconditionallyRequired: val?.isUnconditionallyRequired,
          isUniqueResponseRequired: val?.isUniqueResponseRequired,
        })
      );

      const schema: Record<string, any> = {};

      for (const errorConfig of componentErrorMapAsArray) {
        createYupSchema(
          schema,
          errorConfig,
          template,
          selectedCptCodes,
          previousNoteInitialValues?.[errorConfig.id]
        );
      }

      return schema;
    }
    return undefined;
  }, [
    initialProgressNoteErrors,
    progressNoteErrorsFromSubmitting,
    getTemplate,
    selectedCptCodes,
    previousNoteInitialValues,
    selectedNoteTemplateId,
    selectedNoteTemplateVersion,
  ]);

  const schema = useMemo(() => {
    return Yup.object()
      .shape({
        progressNote: Yup.object().shape({
          ...progressNoteSchema,
          ...lateEntrySchema,
        }),
      })
      .required();
  }, [progressNoteSchema, lateEntrySchema]);
  return schema;
};
