import { useProvider, useProviderPatient } from 'hooks';
import { isEqual } from 'lodash';
import { useContext } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Banner } from '@headway/helix/Banner';
import { GuidanceCard } from '@headway/helix/GuidanceCard';

import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import {
  MedicareMedicaidRequirementWarning,
  MedicareMedicaidRequirementWarningBanner,
} from 'views/Clients/TreatmentPlan/Requirements/MedicareMedicaidRequirements';
import {
  TreatmentPlanRequirement,
  useTreatmentPlanRequirements,
} from 'views/Clients/TreatmentPlan/Requirements/TreatmentPlanRequirements';
import { getActiveTreatmentPlan } from 'views/Clients/TreatmentPlan/TreatmentPlanUtils';

import { AppointmentContext } from '../stores/AppointmentContext';
import {
  ProgressNoteContext,
  ProgressNoteState,
} from '../stores/ProgressNotesContext';

export const useTreatmentPlanRequirementWarning = (patient: UserRead) => {
  const { progressNoteState, progressNote } = useContext(ProgressNoteContext);
  const { selectedCptCodes } = useContext(AppointmentContext);

  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: patient.id,
  });
  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });

  const { isTreatmentPlanRequired } = useTreatmentPlanRequirements(patient?.id);

  const activeTreatmentPlan = getActiveTreatmentPlan(
    treatmentPlans,
    isTreatmentPlanRequired
  );

  const isSigned =
    progressNote &&
    progressNoteState === ProgressNoteState.SIGNED &&
    !isEqual(progressNote.noteJson, {});

  const isNotIntakeSession =
    !!selectedCptCodes?.length && !selectedCptCodes.includes('90791');

  return (
    isNotIntakeSession &&
    isSigned &&
    isTreatmentPlanRequired &&
    !activeTreatmentPlan
  );
};

export interface TreatmentPlanRequirementWarningProps {
  patient: UserRead;
}

// gemini.link(web/apps/sigmund/app/legacy/views/Clients/TreatmentPlan/Requirements/TreatmentPlanRequirements.tsx)
export const TreatmentPlanRequirementWarning = ({
  patient,
}: TreatmentPlanRequirementWarningProps) => {
  const { treatmentPlanRequirementType } = useTreatmentPlanRequirements(
    patient?.id
  );

  const isTreatmentPlanRequired = useTreatmentPlanRequirementWarning(patient);
  if (!isTreatmentPlanRequired) {
    return null;
  }

  return (
    <GuidanceCard variant="error">
      {treatmentPlanRequirementType ===
      TreatmentPlanRequirement.MEDICARE_MEDICAID ? (
        <MedicareMedicaidRequirementWarning patient={patient} />
      ) : null}
    </GuidanceCard>
  );
};

// gemini.link(web/apps/sigmund/app/legacy/views/Clients/TreatmentPlan/Requirements/TreatmentPlanRequirements.tsx)
export const TreatmentPlanRequirementWarningBanner = ({
  patient,
  onOpenTreatmentPlanModal,
}: {
  patient: UserRead;
  onOpenTreatmentPlanModal: () => void;
}) => {
  const { treatmentPlanRequirementType } = useTreatmentPlanRequirements(
    patient?.id
  );

  const isTreatmentPlanRequired = useTreatmentPlanRequirementWarning(patient);
  if (!isTreatmentPlanRequired) {
    return null;
  }

  return (
    <Banner variant="warning">
      {treatmentPlanRequirementType ===
      TreatmentPlanRequirement.MEDICARE_MEDICAID ? (
        <MedicareMedicaidRequirementWarningBanner
          patient={patient}
          onOpenTreatmentPlanModal={onOpenTreatmentPlanModal}
        />
      ) : null}
    </Banner>
  );
};
