/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PHQ9Submission {
    PHQ9_1: number;

    PHQ9_2: number;

    PHQ9_3: number;

    PHQ9_4: number;

    PHQ9_5: number;

    PHQ9_6: number;

    PHQ9_7: number;

    PHQ9_8: number;

    PHQ9_9: number;

    assessmentType?: PHQ9SubmissionAssessmentType;

}


    export enum PHQ9SubmissionAssessmentType {
            PHQ9 = 'PHQ9'
    }

