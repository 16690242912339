import { useCallback, useContext } from 'react';
import { useUpdateProviderProgressNoteMutation } from '~/legacy/mutations/providerProgressNote';

import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useUpdateProgressNoteToEmpty = () => {
  const { progressNote } = useContext(AppointmentConfirmationContextV2);
  const updateProviderProgressNoteMutation =
    useUpdateProviderProgressNoteMutation();

  const updateProgressNoteToEmpty = useCallback(async () => {
    if (!progressNote) return;

    await updateProviderProgressNoteMutation.mutateAsync({
      progressNoteId: progressNote.id,
      update: {
        provider_progress_note_data: {
          noteJson: {},
          attestedOn: new Date().toISOString(),
        },
      },
    });
  }, [updateProviderProgressNoteMutation, progressNote]);

  return { updateProgressNoteToEmpty };
};
