import { ModalProps } from '@mui/material';
import { useProvider } from 'hooks';
import { useState } from 'react';

import { FeedbackSystemQuestionnaireRead } from '@headway/api/models/FeedbackSystemQuestionnaireRead';
import { UserRead } from '@headway/api/models/UserRead';
import { useUser } from '@headway/shared/hooks/useUser';

import { useProviderEvent } from 'hooks/useProviderEvent';
import { useProviderPatient } from 'hooks/useProviderPatient';

import { AppointmentConfirmationModalV2 } from './components/modals/AppointmentConfirmationModalV2';
import { SwoopUpsellModal } from './components/modals/SwoopUpsellModal';
import { AppointmentConfirmationContextProvider } from './stores/AppointmentConfirmationContextV2';

type AppointmentConfirmationV2ImplProps = Omit<ModalProps, 'children'> & {
  open: boolean;
  onClose: () => void;
  eventVirtualId: string | number;
  patient: UserRead;
  onOpenContactFormInsuranceIssues: () => void;
  openTreatmentPlanAdoptionModal?: (isIntakeSession: boolean) => void;
  onProgressNoteUpdate?: () => void;
};

const AppointmentConfirmationV2Impl = ({
  open,
  onClose,
  eventVirtualId,
  patient,
  onOpenContactFormInsuranceIssues,
  openTreatmentPlanAdoptionModal,
  onProgressNoteUpdate,
}: AppointmentConfirmationV2ImplProps) => {
  const provider = useProvider();
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });

  const [isSwoopUpsellModalOpen, setIsSwoopUpsellModalOpen] = useState(false);

  const { data: providerPatient } = useProviderPatient({
    providerId: event?.providerId,
    patientId: event?.patientUserId,
  });

  // If the open value switches to false we return null to make sure that the appointment context and
  // the progress note context fully unmount since its not part of the unmount logic of the Modal
  if (!open || !providerPatient || !event) {
    return null;
  }

  return (
    <AppointmentConfirmationContextProvider
      patient={patient}
      eventVirtualId={eventVirtualId}
      provider={provider}
      onCloseAppointmentConfirmationModal={onClose}
      openTreatmentPlanAdoptionModal={openTreatmentPlanAdoptionModal}
    >
      <AppointmentConfirmationModalV2
        open={open}
        //onClose={onModalClose}
        onOpenContactFormInsuranceIssues={onOpenContactFormInsuranceIssues}
        onProgressNoteUpdate={onProgressNoteUpdate}
      />
      <SwoopUpsellModal
        open={isSwoopUpsellModalOpen}
        onClose={onClose}
        patient={patient}
        eventVirtualId={event.virtualId}
      />
    </AppointmentConfirmationContextProvider>
  );
};

interface AppointmentConfirmationV2Props
  extends Omit<AppointmentConfirmationV2ImplProps, 'patient'> {
  patientId: number;
}

export const AppointmentConfirmationV2 = ({
  patientId,
  ...props
}: AppointmentConfirmationV2Props) => {
  const { data: patient, isLoading: isPatientLoading } = useUser({
    userId: patientId,
  });

  if (isPatientLoading || !patient) {
    return null;
  }

  return <AppointmentConfirmationV2Impl patient={patient} {...props} />;
};
