import React from 'react';

import { FormControl } from '@headway/helix/FormControl';
import { TextArea } from '@headway/helix/TextArea';

import { SchemaComponent } from '.';
import { ProgressNoteComponentMetadata } from '../Renderer/v3/types';
import { getInstructionalText } from '../utils';

const MemoizedTextArea = React.memo(TextArea);

const EXTENDED_HEIGHT_ROWS = 6;
const MAX_ROWS = 25;

export const FormTextArea = ({
  element: { id, title, placeholder, subTitle, description, metadata = {} },
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}: SchemaComponent) => {
  const { extendedTextArea } = metadata as ProgressNoteComponentMetadata;

  const sizing = React.useMemo(
    () => ({
      max: MAX_ROWS,
      min: extendedTextArea ? EXTENDED_HEIGHT_ROWS : undefined,
    }),
    [extendedTextArea]
  );

  return (
    <FormControl
      component={MemoizedTextArea}
      label={title}
      name={id}
      key={id}
      optionalityText={isOptional ? 'Optional' : ''}
      disabled={disabled}
      placeholder={placeholder}
      instructionalText={getInstructionalText(subTitle, { requiresEdit })}
      helpText={description}
      sizing={sizing}
    />
  );
};
