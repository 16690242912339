import { useContext } from 'react';

import { AuthorizationType } from '@headway/api/models/AuthorizationType';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { usePatientInsuranceStatusListQuery } from '@headway/shared/hooks/usePatientInsuranceStatusQuery';
import { useProviderUserFreezes } from '@headway/shared/hooks/useProviderUserFreezes';
import { ProviderFrontEndCarrierContext } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';

import { useInsuranceAuthorizations } from 'hooks/useInsuranceAuthorizations';
import { getInsuranceStatus } from 'views/Patients/utils/patientInsuranceStatus';

import { useMSCGuardrail } from './useMSCGuardrail';
import { useProvider } from './useProvider';

export interface UseInsuranceStatusResult {
  insuranceStatus: PatientInsuranceOrEAPStatus;
  isLoading: boolean;
}

export const useInsuranceStatus = (
  patientUser?: UserRead,
  insurance?: UserInsuranceRead,
  isTelehealthAppointment?: boolean,
  appointmentState?: UnitedStates,
  checkAppointmentState?: boolean
): UseInsuranceStatusResult => {
  const insuranceStatusList = useInsuranceStatusList(
    [
      {
        isTelehealthAppointment,
        appointmentState,
        checkAppointmentState,
      },
    ],
    patientUser,
    insurance
  );
  return {
    insuranceStatus: insuranceStatusList.insuranceStatuses[0],
    isLoading: insuranceStatusList.isLoading,
  };
};

export interface UseInsuranceStatusListResult {
  insuranceStatuses: PatientInsuranceOrEAPStatus[];
  isLoading: boolean;
}

export const useInsuranceStatusList = (
  appointmentInfoList: {
    isTelehealthAppointment?: boolean;
    appointmentState?: UnitedStates;
    checkAppointmentState?: boolean;
  }[],
  patientUser?: UserRead,
  insurance?: UserInsuranceRead
): UseInsuranceStatusListResult => {
  const provider = useProvider();
  const { carriersById, isCarriersLoading } = useFrontEndCarriers();
  const {
    providerFrontEndCarriers,
    isLoading: isProviderFrontEndCarriersLoading,
  } = useContext(ProviderFrontEndCarrierContext);
  const { freezeReasonsByUser, isLoading: isProviderUserFreezesLoading } =
    useProviderUserFreezes(provider.id);
  const freezeReasons =
    (patientUser && freezeReasonsByUser[patientUser.id]) || [];
  const { data: eaps, isLoading: areEAPsLoading } = useInsuranceAuthorizations(
    {
      query: {
        user_id: patientUser?.id,
        authorization_type: AuthorizationType.EAP,
        has_unused_sessions: true,
      },
    },
    {
      enabled: patientUser?.id !== undefined,
    }
  );

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction } =
    useMSCGuardrail();
  const statePatientInsuranceQueries = usePatientInsuranceStatusListQuery(
    appointmentInfoList.map((appointmentInfo) => ({
      queryKeyArgs: {
        patientUserId: patientUser?.id,
        providerId: provider.id,
        isTelehealthAppointment: appointmentInfo.isTelehealthAppointment,
        appointmentState: appointmentInfo.appointmentState,
        includeHiddenCarriers: true,
        checkAppointmentState: appointmentInfo.checkAppointmentState,
      },
    }))
  );

  const sigmundInsuranceStatus = getInsuranceStatus(
    providerFrontEndCarriers,
    carriersById,
    provider,
    patientUser,
    insurance,
    eaps,
    freezeReasons
  );

  const isStatePatientInsuranceStatusLoading =
    statePatientInsuranceQueries.some((query) => query.isLoading);
  if (
    (isMSCEnabled ||
      (isMSCGuardrailWarning &&
        appointmentInfoList.some(
          (info) => info.checkAppointmentState === true
        )) ||
      isMSCGuardrailRestriction) &&
    patientUser &&
    (sigmundInsuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK ||
      sigmundInsuranceStatus ===
        PatientInsuranceOrEAPStatus.IN_NETWORK_PENDING_CREDENTIALING ||
      sigmundInsuranceStatus === PatientInsuranceOrEAPStatus.OUT_OF_NETWORK) &&
    !isStatePatientInsuranceStatusLoading
  ) {
    return {
      insuranceStatuses: statePatientInsuranceQueries.map(
        (query) => query.data!.patientInsuranceStatus
      ),
      isLoading:
        isCarriersLoading ||
        isProviderUserFreezesLoading ||
        isProviderFrontEndCarriersLoading ||
        isStatePatientInsuranceStatusLoading ||
        areEAPsLoading,
    };
  }

  return {
    insuranceStatuses: [sigmundInsuranceStatus],
    isLoading:
      isCarriersLoading ||
      isProviderUserFreezesLoading ||
      isProviderFrontEndCarriersLoading ||
      areEAPsLoading,
  };
};
