import { useQueryClient } from '@tanstack/react-query';
import { isGroupPracticeSessionsByProviderQueryKey } from '~/legacy/hooks/useGroupPracticeSessionsByProvider';
import { useSelectedEvent } from '~/legacy/hooks/useSelectedEvent';
import { isUnconfirmedAppointmentCountsForGroupPracticeQueryKey } from '~/legacy/hooks/useUnconfirmedAppointmentCountsForGroupPractice';
import {
  UpdateProviderEventMutationArgs,
  UpdateRecurringInstanceMutationArgs,
} from '~/legacy/mutations/providerEvent';
import { SideEffectsBuilder } from '~/legacy/mutations/utils';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderEventRecurrenceUpdateResponse } from '@headway/api/models/ProviderEventRecurrenceUpdateResponse';
import { toasts } from '@headway/helix/Toast';

/**
 * TODO(GP-61): Add more precise query invalidation and update caches instantly.
 */

/** Invalidates all session queries when an appointment is canceled */
export const useCancelSingleEventSideEffectsForBilling = () => {
  const queryClient = useQueryClient();

  return new SideEffectsBuilder<
    ConcreteProviderEventRead,
    unknown,
    UpdateProviderEventMutationArgs
  >().add({
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          isGroupPracticeSessionsByProviderQueryKey(query.queryKey) ||
          isUnconfirmedAppointmentCountsForGroupPracticeQueryKey(
            query.queryKey
          ),
      });
      toasts.add('Session canceled');
    },
  });
};

export const useCancelRecurringEventSideEffectsForBilling = () => {
  const queryClient = useQueryClient();

  return new SideEffectsBuilder<
    ProviderEventRecurrenceUpdateResponse,
    unknown,
    UpdateRecurringInstanceMutationArgs
  >().add({
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          isGroupPracticeSessionsByProviderQueryKey(query.queryKey) ||
          isUnconfirmedAppointmentCountsForGroupPracticeQueryKey(
            query.queryKey
          ),
      });
      toasts.add('Recurring session canceled');
    },
  });
};

export const useUpdateProviderEventSideEffectsForBilling = () => {
  const queryClient = useQueryClient();
  const { event: selectedEvent } = useSelectedEvent();

  return new SideEffectsBuilder<
    ConcreteProviderEventRead,
    unknown,
    UpdateProviderEventMutationArgs
  >().add({
    onSuccess: (updatedEvent, update) => {
      // When an appointment gets confirmed or unconfirmed, refetch session info and counts.
      if (
        updatedEvent.providerAppointment?.status !==
        selectedEvent?.providerAppointment?.status
      ) {
        queryClient.invalidateQueries({
          predicate: (query) =>
            isGroupPracticeSessionsByProviderQueryKey(query.queryKey) ||
            isUnconfirmedAppointmentCountsForGroupPracticeQueryKey(
              query.queryKey
            ),
        });
      }
    },
  });
};
