import { useContext } from 'react';
import { useProviderPatient } from '~/legacy/hooks';
import { useProviderTreatmentPlans } from '~/legacy/hooks/useProviderTreatmentPlans';
import {
  getLatestAttestedTreatmentPlan,
  isTreatmentPlanExpired,
} from '~/legacy/views/Clients/TreatmentPlan/TreatmentPlanUtils';

import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useActiveTreatmentPlan = () => {
  const { provider, patient } = useContext(AppointmentConfirmationContextV2);
  const { data: providerPatient } = useProviderPatient({
    providerId: provider?.id,
    patientId: patient?.id,
  });
  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });

  const latestAttestedPlan = getLatestAttestedTreatmentPlan(treatmentPlans);

  return latestAttestedPlan && !isTreatmentPlanExpired(latestAttestedPlan, true)
    ? latestAttestedPlan
    : undefined;
};
