import { Time } from '@internationalized/date';
import { useProvider } from 'hooks';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useMSCGuardrail } from '~/legacy/hooks/useMSCGuardrail';
import { useDocumentationRequirement } from '~/legacy/views/Calendar/utils/documentationRequirement';
import {
  MAX_SELF_PAY_RATE,
  MAX_SELF_PAY_RATE_MESSAGE,
} from '~/legacy/views/Patients/utils/billingType';

import { BillingType } from '@headway/api/models/BillingType';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { UserRead } from '@headway/api/models/UserRead';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';
import {
  CONTROLLED_SUBSTANCE_DATA_COLLECTION,
  MULTI_STATE_CREDENTIALING_BETA,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { SessionDetailsFormV2Values } from '../../components/forms/SessionDetails/SessionDetailsFormV2';
import { isBillingAddOnPsychotherapy } from '../../components/forms/SessionDetails/utils';
import { usePrescriberPsychotherapyTimesEnabled } from '../utils/usePrescriberPsychotherapyTimesEnabled';

const isSessionDetailsFormValueTelehealth = (
  providerAddressId?: number | null
) => providerAddressId === -1;

const isFormValueTelehealthAndBilledWithInsuranceOrEAP = (
  providerAddressId?: number | null,
  billingType?: string
) =>
  isSessionDetailsFormValueTelehealth(providerAddressId) &&
  (billingType === BillingType.INSURANCE || billingType === BillingType.EAP);

const baseSchema = {
  billingType: Yup.mixed().oneOf(Object.values(BillingType)),
  patientResponsibilityAmount: Yup.number().when('billingType', {
    is: (billingType) => billingType === BillingType.SELF_PAY,
    then: Yup.number()
      .min(0, 'Must be $0 or more.')
      .max(MAX_SELF_PAY_RATE, MAX_SELF_PAY_RATE_MESSAGE)
      .required('Session rate is required.'),
  }),
  providerAddressId: Yup.number().required('Location is required'),
};

const appointmentConfirmDetailsPrescriberSchema = {
  ...baseSchema,
  diagnosisCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one diagnosis code is required.'),
  }),
  cptCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one CPT code is required.'),
  }),
};

const appointmentConfirmDetailsPrescriberSchemaWithControlledSubstanceAttestation =
  {
    ...appointmentConfirmDetailsPrescriberSchema,
    didPrescribeControlledSubstance: Yup.boolean().when('billingType', {
      is: (billingType) => billingType !== BillingType.SELF_PAY,
      then: Yup.boolean().required('A response is required'),
    }),
    controlledSubstanceAttestation: Yup.object().when(
      ['didPrescribeControlledSubstance', 'billingType'],
      {
        is: (didPrescribeControlledSubstance, billingType) =>
          billingType !== BillingType.SELF_PAY &&
          didPrescribeControlledSubstance,
        then: Yup.object().shape({
          prescribedSubstances: Yup.array().min(
            1,
            'Select all relevant controlled substances'
          ),
          exceedsRecommendedDosage: Yup.boolean()
            .nullable()
            .required('A response is required'),
          notes: Yup.string().nullable().required('A response is required'),
        }),
      }
    ),
  };

const appointmentConfirmDetailsNonPrescriberSchema = {
  ...baseSchema,
  diagnosisCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one diagnosis code is required.'),
  }),
  cptCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one CPT code is required.'),
  }),
};

export const checkIfExactDurationIsRequiredForSelectedProgressNote = (
  progressNoteType: ProgressNoteType | undefined,
  selectedTemplate: string | undefined
) => {
  if (
    progressNoteType === ProgressNoteType.TEMPLATE &&
    selectedTemplate !== undefined
  ) {
    return true;
  }

  return false;
};

export const useSessionDetailsValidationSchema = (
  event?: ProviderEventRead | undefined,
  patient?: UserRead | undefined,
  selectedProgressNoteType?: ProgressNoteType | undefined,
  selectedTemplateInfo?: string | undefined
) => {
  const provider = useProvider();
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const isControlledSubstanceDataCollectionEnabled = useFlag(
    CONTROLLED_SUBSTANCE_DATA_COLLECTION,
    false
  );
  const { isMSCGuardrailRestriction } = useMSCGuardrail();

  const { isRequired: isDocumentationRequired } = useDocumentationRequirement(
    provider,
    patient,
    event?.providerAppointment
  );

  const isPrescriberPsychotherapyTimesEnabled =
    usePrescriberPsychotherapyTimesEnabled(
      provider,
      patient,
      event?.providerAppointment
    );

  const shouldRequirePrescriberPsychotherapyTimes =
    isPrescriberPsychotherapyTimesEnabled && provider.isPrescriber;

  const schemaDict = provider.isPrescriber
    ? isControlledSubstanceDataCollectionEnabled
      ? appointmentConfirmDetailsPrescriberSchemaWithControlledSubstanceAttestation
      : appointmentConfirmDetailsPrescriberSchema
    : appointmentConfirmDetailsNonPrescriberSchema;

  const prescriberPsychotherapyTimesSchema = useMemo(
    () =>
      shouldRequirePrescriberPsychotherapyTimes
        ? {
            prescriberPsychotherapyStartTime: Yup.object().when(['cptCodes'], {
              is: (cptCodes) =>
                isBillingAddOnPsychotherapy(
                  cptCodes?.map((code: CPTCodeInfo) => code.value) || []
                ),
              then: Yup.object().required(
                'To ensure billing compliance, insurers require psychotherapy start times'
              ),
            }),
            prescriberPsychotherapyEndTime: Yup.object().when(['cptCodes'], {
              is: (cptCodes) =>
                isBillingAddOnPsychotherapy(
                  cptCodes?.map((code: CPTCodeInfo) => code.value) || []
                ),
              then: Yup.object().required(
                'To ensure billing compliance, insurers require psychotherapy end times'
              ),
            }),
          }
        : {},
    [shouldRequirePrescriberPsychotherapyTimes]
  );

  // ensure psychotherapy time window is within appointment window by requiring exact appointment start and end time
  const isExactDurationRequired =
    !!isDocumentationRequired ||
    checkIfExactDurationIsRequiredForSelectedProgressNote(
      selectedProgressNoteType,
      selectedTemplateInfo
    ) ||
    shouldRequirePrescriberPsychotherapyTimes;

  const isMSCOrGuardrailRestrictionEnabled =
    isMSCEnabled || isMSCGuardrailRestriction;

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          sessionDetails: Yup.object()
            .shape(schemaDict)
            .shape({
              exactStartTime: isExactDurationRequired
                ? Yup.object()
                    .nullable()
                    .required(
                      'To ensure billing compliance, insurers require start times'
                    )
                : Yup.object(),
              exactEndTime: isExactDurationRequired
                ? Yup.object()
                    .nullable()
                    .required(
                      'To ensure billing compliance, insurers require stop times'
                    )
                : Yup.object(),
            })
            .test({
              name: 'exactTimeRules',
              test: async function (vals: any) {
                const values = vals as SessionDetailsFormV2Values;
                if (!values.exactStartTime || !values.exactEndTime) {
                  return true;
                }

                if (
                  new Time(
                    values.exactStartTime.hour,
                    values.exactStartTime.minute
                  ).compare(
                    new Time(
                      values.exactEndTime.hour,
                      values.exactEndTime.minute
                    )
                  ) >= 0
                ) {
                  return new Yup.ValidationError(
                    ["End time can't be before start time"],
                    undefined,
                    'sessionDetails.exactEndTime'
                  );
                }

                return true;
              },
            })
            .test({
              name: 'appointmentLocationPatientAddressIdRequired',
              test: async function (vals: any) {
                const values = vals as SessionDetailsFormV2Values;

                if (
                  isFormValueTelehealthAndBilledWithInsuranceOrEAP(
                    values.providerAddressId,
                    values.billingType
                  ) &&
                  !values.appointmentLocationPatientAddressId
                ) {
                  return new Yup.ValidationError(
                    ['Location is required'],
                    undefined,
                    'sessionDetails.appointmentLocationPatientAddressId'
                  );
                }

                return true;
              },
            })
            .test({
              name: 'telehealthPlatformRequired',
              test: async (vals: any) => {
                const values = vals as SessionDetailsFormV2Values;
                if (
                  isFormValueTelehealthAndBilledWithInsuranceOrEAP(
                    values.providerAddressId,
                    values.billingType
                  ) &&
                  !values.telehealthPlatform
                ) {
                  return new Yup.ValidationError(
                    ['Choose your telehealth session type'],
                    undefined,
                    'sessionDetails.telehealthPlatform'
                  );
                }

                return true;
              },
            })
            .test({
              name: 'telehealthProviderStateRequired',
              test: async (vals: any) => {
                const values = vals as SessionDetailsFormV2Values;
                if (
                  isFormValueTelehealthAndBilledWithInsuranceOrEAP(
                    values.providerAddressId,
                    values.billingType
                  ) &&
                  !values.telehealthProviderState
                ) {
                  return new Yup.ValidationError(
                    ['State is required'],
                    undefined,
                    'sessionDetails.telehealthProviderState'
                  );
                }

                return true;
              },
            })
            .test({
              name: 'telehealthAttestationRequired',
              test: async (vals: any) => {
                const values = vals as SessionDetailsFormV2Values;
                if (
                  shouldRequireTelehealthAttestation(values) &&
                  !values.telehealthAttestation
                ) {
                  return new Yup.ValidationError(
                    ['Box must be checked to continue'],
                    undefined,
                    'sessionDetails.telehealthAttestation'
                  );
                }

                return true;
              },
            })
            .shape(prescriberPsychotherapyTimesSchema)
            .test({
              name: 'prescriberPsychotherapyEndTimeRules',
              test: async function (vals: any) {
                const values = vals as SessionDetailsFormV2Values;
                if (
                  !values.prescriberPsychotherapyStartTime ||
                  !values.prescriberPsychotherapyEndTime
                ) {
                  return true;
                }

                if (
                  new Time(
                    values.prescriberPsychotherapyStartTime.hour,
                    values.prescriberPsychotherapyStartTime.minute
                  ).compare(
                    new Time(
                      values.prescriberPsychotherapyEndTime.hour,
                      values.prescriberPsychotherapyEndTime.minute
                    )
                  ) >= 0
                ) {
                  return new Yup.ValidationError(
                    ["End time can't be before start time"],
                    undefined,
                    'sessionDetails.prescriberPsychotherapyEndTime'
                  );
                } else if (
                  values.exactEndTime &&
                  new Time(
                    values.prescriberPsychotherapyEndTime.hour,
                    values.prescriberPsychotherapyEndTime.minute
                  ).compare(
                    new Time(
                      values.exactEndTime.hour,
                      values.exactEndTime.minute
                    )
                  ) > 0
                ) {
                  return new Yup.ValidationError(
                    [
                      "Psychotherapy end time can't be after appointment end time",
                    ],
                    undefined,
                    'sessionDetails.prescriberPsychotherapyEndTime'
                  );
                }

                return true;
              },
            })
            .test({
              name: 'prescriberPsychotherapyStartTimeRule',
              test: async function (vals: any) {
                const values = vals as SessionDetailsFormV2Values;
                if (
                  !values.prescriberPsychotherapyStartTime ||
                  !values.prescriberPsychotherapyEndTime ||
                  !values.exactStartTime
                ) {
                  return true;
                }

                if (
                  new Time(
                    values.prescriberPsychotherapyStartTime.hour,
                    values.prescriberPsychotherapyStartTime.minute
                  ).compare(
                    new Time(
                      values.exactStartTime.hour,
                      values.exactStartTime.minute
                    )
                  ) < 0
                ) {
                  return new Yup.ValidationError(
                    [
                      "Psychotherapy start time can't be before appointment start time",
                    ],
                    undefined,
                    'sessionDetails.prescriberPsychotherapyStartTime'
                  );
                }
                return true;
              },
            })
            .test({
              name: 'prescriberPsychotherapyDurationRule',
              test: async function (vals: any) {
                const values = vals as SessionDetailsFormV2Values;
                if (
                  !values.prescriberPsychotherapyStartTime ||
                  !values.prescriberPsychotherapyEndTime ||
                  !values.exactStartTime ||
                  !values.exactEndTime
                ) {
                  return true;
                }

                const psychotherapyDuration = new Time(
                  values.prescriberPsychotherapyEndTime.hour,
                  values.prescriberPsychotherapyEndTime.minute
                ).compare(
                  new Time(
                    values.prescriberPsychotherapyStartTime.hour,
                    values.prescriberPsychotherapyStartTime.minute
                  )
                );
                const appointmentDuration = new Time(
                  values.exactEndTime.hour,
                  values.exactEndTime.minute
                ).compare(
                  new Time(
                    values.exactStartTime.hour,
                    values.exactStartTime.minute
                  )
                );
                if (psychotherapyDuration >= appointmentDuration) {
                  return new Yup.ValidationError(
                    [
                      'Psychotherapy duration must be strictly less than appointment duration',
                    ],
                    undefined,
                    'sessionDetails.prescriberPsychotherapyEndTime'
                  );
                }
                return true;
              },
            }),
        })
        .required(),
    [
      isExactDurationRequired,
      isMSCOrGuardrailRestrictionEnabled,
      prescriberPsychotherapyTimesSchema,
      schemaDict,
    ]
  );
  return schema;
};

export const shouldRequireTelehealthAttestation = (
  values: SessionDetailsFormV2Values
) => {
  return isFormValueTelehealthAndBilledWithInsuranceOrEAP(
    values.providerAddressId,
    values.billingType
  );
};
