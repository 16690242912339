import { useFormContext, useWatch } from 'react-hook-form';

import { ControlledSubstance } from '@headway/api/models/ControlledSubstance';
import { ComboBox, Item, Section } from '@headway/helix/ComboBox';
import {
  BENZODIAZEPINES,
  BUPRENORPHINE_DERIVATIVES,
  COMMONLY_PRESCRIBED,
  CONTROLLED_SUBSTANCE_DISPLAY_NAMES,
  NON_BENZODIAZEPINE_HYPNOTICS,
  NON_BENZODIAZEPINES,
  OTHER,
  STIMULANTS,
} from '@headway/shared/constants/controlledSubstances';

import { FormControlRHF } from '../../../FormControlRHF';

const createOptionCategory = (
  categoryLabel: string,
  substances: ControlledSubstance[]
) => {
  return {
    categoryLabel,
    items: substances.map((substance) => ({
      label: CONTROLLED_SUBSTANCE_DISPLAY_NAMES[substance],
      value: substance,
    })),
  };
};

const OPTIONS = [
  createOptionCategory('Commonly Prescribed', COMMONLY_PRESCRIBED),
  createOptionCategory('Stimulants', STIMULANTS),
  createOptionCategory('Benzodiazepines', BENZODIAZEPINES),
  createOptionCategory('Non-Benzodiazepines', NON_BENZODIAZEPINES),
  createOptionCategory(
    'Non-Benzodiazepine Hypnotics',
    NON_BENZODIAZEPINE_HYPNOTICS
  ),
  createOptionCategory('Buprenorphine Derivatives', BUPRENORPHINE_DERIVATIVES),
  createOptionCategory('Other', OTHER),
];

type ComboBoxProps = React.ComponentProps<typeof ComboBox>;

export const ControlledSubstanceComboBoxV2 = (
  props: Omit<ComboBoxProps, 'items' | 'selectionMode' | 'children'>
) => {
  const { setValue } = useFormContext();
  const sessionDetailsValues = useWatch({ name: 'sessionDetails' });

  return (
    <FormControlRHF
      component={ComboBox}
      {...props}
      selectedKeys={
        sessionDetailsValues.controlledSubstanceAttestation
          ?.prescribedSubstances
          ? new Set(
              sessionDetailsValues.controlledSubstanceAttestation
                ?.prescribedSubstances
            )
          : new Set()
      }
      onSelectionChange={(keys: Set<string>) => {
        const values = keys.size > 0 ? Array.from(keys) : [];
        setValue(
          'sessionDetails.controlledSubstanceAttestation.prescribedSubstances',
          values
        );
      }}
      selectionMode="multiple"
    >
      {OPTIONS.map((section) => {
        return (
          <Section
            key={section.categoryLabel}
            title={section.categoryLabel}
            items={section.items}
          >
            {(item) => (
              <Item key={item.value} textValue={item.label}>
                {item.label}
              </Item>
            )}
          </Section>
        );
      })}
    </FormControlRHF>
  );
};
