import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import '@headway/api/resources/ProviderApi';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextField } from '@headway/helix/TextField';
import {
  MULTI_STATE_CREDENTIALING_BETA,
  MULTI_STATE_CREDENTIALING_ONBOARDING,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { isProviderInAnyStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { YesNo } from '../../../../utils/providerQuestionnaire';
import { statesThatRequireTimelyAccessToCareAttest } from './util';

export const AdditionalInformationQuestions = ({
  provider,
}: {
  provider: ProviderRead;
}) => {
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const isMSCOnboardingEnabled = useFlag(MULTI_STATE_CREDENTIALING_ONBOARDING);
  return (
    <>
      <SectionHeader>Additional Information</SectionHeader>
      {!(isMSCBetaEnabled || isMSCOnboardingEnabled) && (
        <FormControl
          name="hasPractice"
          component={RadioGroup}
          label="Do you currently have an office where you can see Headway
      patients face-to-face? Please note that this address will
      appear publicly in insurance directories."
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
      )}
      <FormControl
        name="doesHomeVisits"
        component={RadioGroup}
        label="Do you do home visits?"
      >
        <Radio value={YesNo.YES}>Yes</Radio>
        <Radio value={YesNo.NO}>No</Radio>
      </FormControl>
      <FormControl
        name="doesTelemedicine"
        component={RadioGroup}
        label="Do you offer telemedicine services?"
      >
        <Radio value={YesNo.YES}>Yes</Radio>
        <Radio value={YesNo.NO}>No</Radio>
      </FormControl>
      <FormControl component={Checkbox} name="practiceDocumentationAttest">
        I attest that I will document all sessions with patients on Headway
        according to professional license record keeping requirements.
      </FormControl>
      {isProviderInAnyStates(
        provider,
        statesThatRequireTimelyAccessToCareAttest
      ) && (
        <FormControl component={Checkbox} name="timelyAccessToCareAttest">
          I attest that I will meet the following standards for member timely
          access to care while practicing on Headway.
        </FormControl>
      )}
    </>
  );
};
