import React from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';

import { TemplateAnalyticsNames } from '../ProgressNotesForm';
import { GenericTemplate } from './Renderer/types';
import { Renderer as V1 } from './Renderer/v1/Renderer';
import { TemplateV1 } from './Renderer/v1/types';
import { Renderer as V2 } from './Renderer/v2/Renderer';
import { TemplateV2 } from './Renderer/v2/types';
import { Renderer as V3 } from './Renderer/v3/Renderer';
import { TemplateV3 } from './Renderer/v3/types';

export type TemplateProps<T> = {
  template: GenericTemplate<T>;
  disabled?: boolean;
  eventStartDate?: string;
  patient?: UserRead;
  providerPatient: ProviderPatientRead;
  sendTemplateAnalytics: (trackingEventName: TemplateAnalyticsNames) => void;
};

const MemoizedV3 = React.memo(
  <T,>({
    template,
    disabled,
    eventStartDate,
    patient,
    providerPatient,
    sendTemplateAnalytics,
  }: TemplateProps<T>) => {
    return (
      <V3<T>
        template={template?.template as TemplateV3<T>}
        disabled={disabled}
        eventStartDate={eventStartDate}
        patient={patient}
        providerPatient={providerPatient}
        sendTemplateAnalytics={sendTemplateAnalytics}
      />
    );
  }
);

const Template = <T,>({
  template,
  disabled,
  eventStartDate,
  patient,
  providerPatient,
  sendTemplateAnalytics,
}: TemplateProps<T>) => {
  const schemaVersion = template.templateInfo.schemaVersion ?? 1;
  switch (schemaVersion) {
    case 1:
      return (
        <V1<T>
          template={template?.template as TemplateV1<T>}
          disabled={disabled}
        />
      );
    case 2:
      return (
        <V2<T>
          template={template?.template as TemplateV2<T>}
          disabled={disabled}
        />
      );
    case 3:
      return (
        <MemoizedV3
          template={template}
          disabled={disabled}
          eventStartDate={eventStartDate}
          patient={patient}
          providerPatient={providerPatient}
          sendTemplateAnalytics={sendTemplateAnalytics}
        />
      );
    default:
      return <></>;
  }
};

const MemoizedTemplate = React.memo(Template);

export default MemoizedTemplate;
