import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import React from 'react';

import { MarketApi } from '@headway/api/resources/MarketApi';
import { unitedStatesAbbreviations } from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  DEFAULT_LARGE_ZOOM,
  DEFAULT_SMALL_RADIUS_MILES,
  DEFAULT_SMALL_ZOOM,
  MILES_PER_DEGREE,
} from '@headway/shared/utils/geography';
import { theme } from '@headway/ui/theme';

export class LocationFilter extends React.Component {
  state = {
    locationSearchResults: [],
    query: '',
    markets: {},
  };

  getLocationPredictions = debounce(async (searchTerm) => {
    try {
      await this.autocompleteService.getPlacePredictions(
        {
          input: searchTerm,
          types: [this.props.placeType],
          componentRestrictions: { country: 'us' },
          fields: ['address_components', 'geometry'],
        },
        (results) => {
          const resultsToShow = this.props.providerStates
            ? results?.filter((result) => {
                const stateAbbrevs = this.props.providerStates.map((st) => {
                  return unitedStatesAbbreviations[st.state];
                });
                const stateOrZipOne =
                  result.terms[result.terms.length - 2]?.value;
                const stateOrZipTwo =
                  result.terms[result.terms.length - 3]?.value;
                const inProviderState =
                  stateAbbrevs.includes(stateOrZipOne) ||
                  stateAbbrevs.includes(stateOrZipTwo);
                if (this.props.placeType === 'address') {
                  return (
                    inProviderState &&
                    (result.types.includes('street_address') ||
                      result.types.includes('premise') ||
                      result.types.includes('subpremise'))
                  );
                }
                return inProviderState;
              })
            : results;
          this.setState({ locationSearchResults: resultsToShow || [] });
        }
      );
    } catch (err) {
      // do nothing
    }
  }, 200);

  parseAddressComponents = (addressComponents) => {
    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      sublocality_level_1: 'long_name',
      sublocality: 'long_name',
      locality: 'long_name',
      neighborhood: 'long_name',
      administrative_area_level_1: 'short_name',
      postal_code: 'short_name',
      subpremise: 'long_name',
    };

    const addressObj = {};
    const componentKeys = Object.keys(componentForm);
    for (var i = 0; i < addressComponents.length; i++) {
      let typeIndex;
      componentKeys.forEach((key) => {
        const idx = addressComponents[i].types.indexOf(key);
        if (idx > -1) {
          typeIndex = idx;
        }
      });
      if (typeIndex === -1) {
        continue;
      }
      const addressType = addressComponents[i].types[typeIndex];
      if (componentForm[addressType]) {
        addressObj[addressType] =
          addressComponents[i][componentForm[addressType]];
      }
    }
    return addressObj;
  };

  getBoundary = (location) => {
    const minLocalityTypes = location.types;
    const smallLocale = [
      'street_address',
      'premise',
      'establishment',
      'neighborhood',
      'route',
    ];
    const lat = location.geometry.location.lat();
    const lon = location.geometry.location.lng();

    if (minLocalityTypes.some((t) => smallLocale.includes(t))) {
      return {
        lower_lat: lat - DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        lower_lon: lon - DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        upper_lat: lat + DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        upper_lon: lon + DEFAULT_SMALL_RADIUS_MILES / MILES_PER_DEGREE,
        zoom: DEFAULT_SMALL_ZOOM,
      };
    } else {
      const bounds = location.geometry.bounds;
      if (bounds) {
        const northEastBound = bounds.getNorthEast();
        const southWestBound = bounds.getSouthWest();
        return {
          upper_lat: northEastBound.lat(),
          upper_lon: northEastBound.lng(),
          lower_lat: southWestBound.lat(),
          lower_lon: southWestBound.lng(),
          zoom: DEFAULT_LARGE_ZOOM,
        };
      }
    }
  };

  handleLocationSelect = (location) => {
    if (location) {
      try {
        this.geocoder.geocode(
          {
            placeId: location.place_id,
          },
          (result) => {
            const addressComponents = result[0].address_components;
            const lat = result[0].geometry.location.lat();
            const lng = result[0].geometry.location.lng();
            const addressObj = this.parseAddressComponents(addressComponents);
            const street_number = addressObj.street_number || '';
            const street_name = addressObj.route;
            const streetLine2 = addressObj.subpremise;
            const city =
              addressObj.locality ||
              addressObj.sublocality ||
              addressObj.sublocality_level_1 ||
              addressObj.neighborhood;
            const state = addressObj.administrative_area_level_1;
            const zipCode = addressObj.postal_code;
            const formattedAddress = result[0].formatted_address;
            const boundary = this.getBoundary(result[0]);

            this.props.handleLocationSelect({
              streetLine1: `${street_number}${
                street_number ? ' ' : ''
              }${street_name}`,
              streetLine2,
              city,
              state,
              zipCode,
              lat,
              lng,
              formattedAddress,
              ...boundary,
            });
          }
        );
      } catch (err) {
        console.log({ err });
      }
    } else {
      this.props.handleLocationSelect({});
    }
  };

  async componentDidMount() {
    // eslint-disable-next-line no-undef
    this.autocompleteService =
      new this.props.google.maps.places.AutocompleteService();
    // eslint-disable-next-line no-undef
    this.geocoder = new this.props.google.maps.Geocoder();

    try {
      const markets = await MarketApi.getAllMarkets();
      this.setState({ markets });
    } catch (e) {
      // do nothing
    }
  }

  render() {
    const {
      handleLocationSelect,
      providerState,
      placeType,
      autoFocus,
      inputProps = {},
      searchInputLabel = 'Search for an address',
      ...rest
    } = this.props;

    return (
      <>
        <Autocomplete
          id="location-search"
          data-testid="location-search"
          options={this.state.locationSearchResults}
          getOptionLabel={(option) => option?.structured_formatting?.main_text}
          filterOptions={(x) => x}
          autoComplete
          includeInputInList
          disableClearable={true}
          {...rest}
          renderInput={(params) => {
            // The only way to turn off autocomplete in chrome is to
            // give autoComplete a random value
            params.inputProps.autoComplete = 'nope';
            return (
              <TextField
                {...params}
                autoFocus={autoFocus}
                label={searchInputLabel}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  if (!event.target.value) {
                    this.setState({ locationSearchResults: [] });
                  } else {
                    this.getLocationPredictions(event.target.value);
                  }
                }}
                {...inputProps}
              />
            );
          }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <div>
                  <div css={{ fontWeight: theme.fontWeight.bold }}>
                    {option?.structured_formatting?.main_text}
                  </div>
                  <div css={{ fontSize: theme.fontSize.xs }}>
                    {option?.structured_formatting?.secondary_text}
                  </div>
                </div>
              </li>
            );
          }}
          onChange={(e, value) => {
            // e.preventDefault();
            if (this.state.locationSearchResults.indexOf(value) > -1) {
              this.handleLocationSelect(value);
            }
          }}
        />
      </>
    );
  }
}
