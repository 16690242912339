import { getLocalTimeZone, today } from '@internationalized/date';
import { Formik, useFormikContext } from 'formik';
import { useProvider, useProviderPatient } from 'hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useGetIsPromsScheduleRequired } from '~/legacy/hooks/useGetIsPromsScheduleRequired';

import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentRecurrenceScheduleCreateRequest } from '@headway/api/models/PatientAssessmentRecurrenceScheduleCreateRequest';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { ContentText } from '@headway/helix/ContentText';
import { dateValueToMoment, momentToDateValue } from '@headway/helix/date';
import { Divider } from '@headway/helix/Divider';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { toasts } from '@headway/helix/Toast';
import {
  ALL_ASSESSMENT_TYPES,
  ASSESSMENT_CATEGORIES,
  ASSESSMENT_QUESTION_COUNTS,
  FULL_ASSESSMENT_NAMES,
  NQF_EXCLUSIVE_PATIENT_ASSESSMENT_TYPES,
  NQF_PATIENT_ASSESSMENT_TYPES,
  SHORT_ASSESSMENT_NAMES,
} from '@headway/shared/constants/patientAssessments';
import {
  NQF_OPT_IN,
  PROMS_ONLY_RATE_BOOST,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useUser } from '@headway/shared/hooks/useUser';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { LogoLoader } from '@headway/ui/LogoLoader';

import { generateAssessmentSchedulesValidationSchema } from 'components/AssessmentScheduler/utils';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { usePatientAssessmentRecurrenceSchedules } from 'hooks/usePatientAssessmentRecurrenceSchedules';
import { useProviderHasSentAssessments } from 'hooks/useProviderHasSentAssessments';
import { useReplacePatientAssessmentRecurrenceSchedulesMutation } from 'mutations/assessments';
import RateBoostGuidanceCard from 'views/Incentives/RateBoostGuidanceCard';
import { AddPatientModalPage } from 'views/Patients/AddPatient/AddPatientModalContext';
import {
  getModalProgressProps,
  useAddPatientModalSteps,
} from 'views/Patients/utils/addPatientModalUtils';

import {
  AssessmentScheduleConfig,
  ManageAssessmentsModalFormValues,
  ScheduleConfig,
} from '../helpers/types';
import { useAvailableAssessmentTypes } from '../helpers/useAvailableAssessmentTypes';
import { useReplacePatientAssessmentRecurrenceSchedulesSideEffects } from '../helpers/utils';
import { AssessmentScheduleInput } from './AssessmentScheduleInput';
import { ManageAssessmentsModalPreviewPanel } from './ManageAssessmentsModalPreviewPanel';

interface ManageAssessmentsModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  clientId: number;
  isIntakeFlow: boolean;
  assessmentTypesToDisplay?: PatientAssessmentType[];
  preselectedSchedules?: AssessmentScheduleConfig[];
}

const MIN_SUBMISSION_LOADING_TIME_MS = 800;
const DEFAULT_CADENCE = PatientAssessmentRecurrenceCadence.EVERY_30_DAYS;
export const ManageAssessmentsModal = ({
  isOpen,
  onDismiss,
  clientId,
  isIntakeFlow,
  assessmentTypesToDisplay,
  preselectedSchedules = [],
}: ManageAssessmentsModalProps) => {
  const provider = useProvider();
  const steps = useAddPatientModalSteps();
  const { data: client } = useUser({ userId: clientId });
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: client?.id,
  });
  const replacePatientAssessmentRecurrenceSchedulesMutation =
    useReplacePatientAssessmentRecurrenceSchedulesMutation({
      sideEffects: useReplacePatientAssessmentRecurrenceSchedulesSideEffects(),
    });
  const { data: existingSchedules, isLoading: areExistingSchedulesLoading } =
    usePatientAssessmentRecurrenceSchedules(
      {
        providerPatientId: providerPatient?.id,
      },
      { refetchOnWindowFocus: false }
    );
  const { isLoading: isProviderHasSentAssessmentsLoading } =
    useProviderHasSentAssessments(
      { providerId: provider.id },
      { refetchOnWindowFocus: false }
    );

  const existingSchedulesAreHeadwayDefaults = existingSchedules?.every(
    (schedule) => schedule.isHeadwayGenerated
  );
  const remainingScheduledSends = existingSchedules
    ? Math.max(
        ...existingSchedules.map((schedule) => schedule.remainingSendCount || 0)
      )
    : 0;

  const initialValues = useMemo(() => {
    const now = momentToDateValue(moment());

    return {
      enabledAssessmentTypes: [
        ...(existingSchedules || []).map((schedule) => schedule.assessmentType),
        ...preselectedSchedules.map((schedule) => schedule.assessmentType),
      ],
      ...ALL_ASSESSMENT_TYPES.reduce(
        (acc, assessmentType) => {
          const existingSchedule = (existingSchedules || []).find(
            (schedule) => schedule.assessmentType === assessmentType
          );

          if (existingSchedule) {
            acc[assessmentType] = {
              cadence: existingSchedule.cadence,
              startDate: momentToDateValue(
                moment(existingSchedule.nextScheduledDate)
              ),
            };
            return acc;
          }

          const preselectedSchedule = preselectedSchedules.find(
            (schedule) => schedule.assessmentType === assessmentType
          );

          if (preselectedSchedule) {
            acc[assessmentType] = {
              cadence: preselectedSchedule.cadence,
              startDate: preselectedSchedule.startDate,
            };
            return acc;
          }

          let defaultCadenceForType = DEFAULT_CADENCE;
          let defaultStartDate = now;
          if (
            assessmentType === PatientAssessmentType.WHODAS_2 ||
            assessmentType === PatientAssessmentType.PROMIS
          ) {
            defaultCadenceForType =
              PatientAssessmentRecurrenceCadence.EVERY_90_DAYS;
          } else if (assessmentType === PatientAssessmentType.ANCHOR) {
            defaultCadenceForType = PatientAssessmentRecurrenceCadence.ONE_TIME;
            defaultStartDate = momentToDateValue(moment().add(12, 'week'));
          }
          acc[assessmentType] = {
            cadence: defaultCadenceForType,
            startDate: defaultStartDate,
          };
          return acc;
        },
        {} as { [assessmentType in PatientAssessmentType]: ScheduleConfig }
      ),
    };
  }, [existingSchedules, preselectedSchedules]);

  if (
    !client ||
    !providerPatient ||
    areExistingSchedulesLoading ||
    isProviderHasSentAssessmentsLoading
  ) {
    return (
      <Modal
        variant="fullscreen"
        isOpen
        onDismiss={onDismiss}
        title={isIntakeFlow ? 'Add Client' : `Manage assessments`}
        progress={
          isIntakeFlow
            ? getModalProgressProps(AddPatientModalPage.SEND_ASSESSMENTS, steps)
            : undefined
        }
      >
        <ModalContent>
          <div className="flex flex-col items-center gap-8 p-8">
            <ContentText variant="section-title/medium">Loading...</ContentText>
            <LogoLoader />
          </div>
        </ModalContent>
      </Modal>
    );
  }

  const clientName = formatPatientName(client);

  const handleSubmit = async (values: ManageAssessmentsModalFormValues) => {
    const { enabledAssessmentTypes, ...configs } = values;
    const schedules: PatientAssessmentRecurrenceScheduleCreateRequest[] =
      Object.entries(configs)
        .filter(([assessmentType]) =>
          enabledAssessmentTypes.includes(
            assessmentType as PatientAssessmentType
          )
        )
        .map(([assessmentType, config]) => {
          return {
            assessmentType: assessmentType as PatientAssessmentType,
            cadence: config.cadence,
            startDate: config.startDate.toString(),
          };
        });
    trackEvent({
      name: 'Save and Send Client Assessments Button Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
        assessmentTypeList: schedules.map(
          (schedule) => schedule.assessmentType
        ),
      },
    });
    await Promise.all([
      replacePatientAssessmentRecurrenceSchedulesMutation.mutateAsync({
        providerPatientId: providerPatient.id,
        body: {
          currentLocalDate: today(getLocalTimeZone()).toString(),
          schedules,
        },
      }),
      // Force submission to take a minimum amount of time for UX reasons
      new Promise((resolve) => {
        setTimeout(() => resolve(undefined), MIN_SUBMISSION_LOADING_TIME_MS);
      }),
    ]);
    onDismiss();

    if (!isIntakeFlow) toasts.add('Assessments saved', { variant: 'positive' });
  };

  return (
    <Formik<ManageAssessmentsModalFormValues>
      initialValues={initialValues}
      enableReinitialize={
        replacePatientAssessmentRecurrenceSchedulesMutation.isIdle
      }
      onSubmit={handleSubmit}
      validationSchema={generateAssessmentSchedulesValidationSchema()}
    >
      {({ values, isSubmitting, isValid, setValues }) => {
        const willSendAssessments = values.enabledAssessmentTypes.some(
          (assessmentType) =>
            moment(dateValueToMoment(values[assessmentType].startDate)).isSame(
              moment(),
              'day'
            )
        );

        const handleRemoveDefaultSetting = () => {
          const now = momentToDateValue(moment());

          setValues({
            enabledAssessmentTypes: [],
            ...ALL_ASSESSMENT_TYPES.reduce(
              (acc, assessmentType) => {
                acc[assessmentType] = {
                  cadence: DEFAULT_CADENCE,
                  startDate: now,
                };
                return acc;
              },
              {} as {
                [assessmentType in PatientAssessmentType]: ScheduleConfig;
              }
            ),
          });
        };

        return (
          <Modal
            variant="fullscreen"
            isOpen={isOpen}
            onDismiss={onDismiss}
            title={
              isIntakeFlow
                ? 'Add Client'
                : `Manage assessments for ${clientName}`
            }
            progress={
              isIntakeFlow
                ? getModalProgressProps(
                    AddPatientModalPage.SEND_ASSESSMENTS,
                    steps
                  )
                : undefined
            }
          >
            {isSubmitting ? (
              <ModalContent>
                <div className="flex flex-col items-center gap-8 p-8">
                  <SectionHeader>Saving changes...</SectionHeader>
                  <LogoLoader />
                </div>
              </ModalContent>
            ) : (
              <ManageAssessmentsModalContent
                client={client}
                provider={provider}
                providerPatient={providerPatient}
                existingSchedulesAreHeadwayDefaults={
                  existingSchedulesAreHeadwayDefaults
                }
                remainingScheduledSends={remainingScheduledSends}
                handleRemoveDefaultSetting={handleRemoveDefaultSetting}
                isIntakeFlow={isIntakeFlow}
                assessmentsToDisplay={assessmentTypesToDisplay}
              />
            )}

            {!isSubmitting && (
              <ModalFooter>
                <Button variant="secondary" onPress={onDismiss}>
                  {isIntakeFlow ? `Don't send at this time` : 'Cancel'}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  form="manage-assessments-modal-form"
                  disabled={!isValid}
                >
                  {willSendAssessments ? 'Save and send' : 'Save'}
                </Button>
              </ModalFooter>
            )}
          </Modal>
        );
      }}
    </Formik>
  );
};

export const ManageAssessmentsFormWrapper = ({
  providerId,
  clientId,
  children,
  showPreview,
}: {
  providerId: number;
  clientId: number;
  children: React.ReactNode;
  showPreview: boolean;
}) => {
  const {
    values: { enabledAssessmentTypes },
  } = useFormikContext<ManageAssessmentsModalFormValues>();
  const showingPreviewPanel = showPreview && enabledAssessmentTypes.length > 0;
  return (
    <SafeFormikForm
      className={`flex h-full flex-grow ${
        showingPreviewPanel ? 'justify-center' : 'flex-col'
      }`}
      id="manage-assessments-modal-form"
    >
      {showingPreviewPanel ? (
        <div className="flex basis-[1440px] gap-5 px-5 py-8">
          <div className="flex grow basis-[50%] justify-center tablet:basis-auto">
            <div className="max-w-[690px]">{children}</div>
          </div>
          <ManageAssessmentsModalPreviewPanel
            providerId={providerId}
            clientId={clientId}
          />
        </div>
      ) : (
        <PageSection>
          <div>{children}</div>
        </PageSection>
      )}
    </SafeFormikForm>
  );
};

interface ManageAssessmentsModalContentProps {
  client: UserRead;
  provider: ProviderRead;
  providerPatient: ProviderPatientRead;
  existingSchedulesAreHeadwayDefaults?: boolean;
  remainingScheduledSends?: number;
  handleRemoveDefaultSetting?: () => void;
  isIntakeFlow?: boolean;
  isFillOutOnBehalfForClient?: boolean;
  isPostFillOutOnBehalfOfClientScheduling?: boolean;
  hasExistingSchedules?: boolean;
  assessmentsToDisplay?: PatientAssessmentType[];
}

export const ManageAssessmentsModalContent = ({
  client,
  provider,
  providerPatient,
  existingSchedulesAreHeadwayDefaults,
  remainingScheduledSends,
  handleRemoveDefaultSetting,
  isIntakeFlow = false,
  isFillOutOnBehalfForClient = false,
  isPostFillOutOnBehalfOfClientScheduling = false,
  hasExistingSchedules = false,
  assessmentsToDisplay,
}: ManageAssessmentsModalContentProps) => {
  const {
    values: { enabledAssessmentTypes },
    dirty,
  } = useFormikContext<ManageAssessmentsModalFormValues>();

  const isProviderOptedIntoNQF = useFlag(NQF_OPT_IN, false);
  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  const { data: providerEnrollmentSummary } =
    useProviderIncentiveProgramEnrollment({
      providerId: provider.id,
    });

  const { data: isPromsScheduleRequiredDetails } =
    useGetIsPromsScheduleRequired({
      providerPatientId: providerPatient.id,
    });

  const allAvailableAssessmentTypes = useAvailableAssessmentTypes();
  const availableAssessmentTypes =
    assessmentsToDisplay || allAvailableAssessmentTypes;

  const showNQFGuidanceCard =
    enabledAssessmentTypes.some((selected) =>
      NQF_EXCLUSIVE_PATIENT_ASSESSMENT_TYPES.has(selected)
    ) &&
    !isFillOutOnBehalfForClient &&
    !isPostFillOutOnBehalfOfClientScheduling;
  const showRateBoostGuidanceCard =
    providerEnrollmentSummary?.isProviderEnrolled &&
    !showNQFGuidanceCard &&
    !promsOnlyRateBoostEnabled &&
    !isPostFillOutOnBehalfOfClientScheduling;

  const showPHQ9OrGAD7GuidanceCard =
    !dirty &&
    existingSchedulesAreHeadwayDefaults &&
    remainingScheduledSends &&
    remainingScheduledSends > 0 &&
    !isFillOutOnBehalfForClient &&
    !isPostFillOutOnBehalfOfClientScheduling;
  const showPreview = !isPostFillOutOnBehalfOfClientScheduling;

  const clientName = formatPatientName(client);

  const handleLearnMore = () => {
    trackEvent({
      name: 'NQF Assessment Info Button Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
      },
    });
  };

  const headerContent = useMemo(() => {
    return !isFillOutOnBehalfForClient &&
      isPromsScheduleRequiredDetails &&
      isPromsScheduleRequiredDetails.isPromsScheduleRequired &&
      isPromsScheduleRequiredDetails.carrierName
      ? `For ${isPromsScheduleRequiredDetails.carrierName} clients, you must send at least one assessment.`
      : 'You can also manage send dates and frequency.';
  }, [isPromsScheduleRequiredDetails, isFillOutOnBehalfForClient]);

  return (
    <>
      <ModalContent>
        <ManageAssessmentsFormWrapper
          clientId={client.id}
          providerId={provider.id}
          showPreview={showPreview}
        >
          <div className="flex flex-col p-2">
            {isIntakeFlow ? (
              <ContentText variant="section-title/medium">
                <b id="manage-assessments-checkbox-group-label">
                  Selected assessments will be sent to {clientName}.
                </b>
              </ContentText>
            ) : isFillOutOnBehalfForClient ? (
              <ContentText variant="body">
                <b id="manage-assessments-checkbox-group-label">
                  Selected assessment(s) to fill out with {clientName} during
                  their session.
                </b>
              </ContentText>
            ) : isPostFillOutOnBehalfOfClientScheduling ? (
              hasExistingSchedules ? (
                <ContentText variant="body/medium">
                  Since you just filled out assessments, would you like to
                  update the next send date?
                </ContentText>
              ) : (
                <ContentText variant="body/medium">
                  Would you like to send these assessments to {clientName} again
                  in the future?
                </ContentText>
              )
            ) : (
              <ContentText variant="body">
                <b id="manage-assessments-checkbox-group-label">
                  Selected assessments will be sent to {clientName}.
                </b>
              </ContentText>
            )}
            {!isPostFillOutOnBehalfOfClientScheduling && (
              <div>
                <ContentText variant="body">{headerContent}</ContentText>{' '}
                <Link
                  href="https://findheadway.zendesk.com/hc/en-us/articles/22620367351956"
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn more
                </Link>
              </div>
            )}
            {showRateBoostGuidanceCard && (
              <div className="mt-5">
                <RateBoostGuidanceCard />
              </div>
            )}

            {showNQFGuidanceCard && (
              <div className="pt-4">
                <GuidanceCard variant="info">
                  <div className="flex flex-col items-start gap-2">
                    <BodyText>
                      <b>Thank you for participating in the NQF study</b>
                      <li>
                        <Link
                          href="https://headway-university.northpass.com/p/784f28b0ab7af3edab90fced51486de78e06404f"
                          target="_blank"
                          rel="noreferrer"
                          onClick={handleLearnMore}
                        >
                          View these instructions
                        </Link>{' '}
                        to review how to send NQF assessments
                      </li>
                      <li>
                        If you send any NQF assessments, we will ask your client
                        to sign a study consent form before they do those
                        assessments.
                      </li>
                    </BodyText>
                    <LinkButton
                      variant="link"
                      href="https://headway-university.northpass.com/p/784f28b0ab7af3edab90fced51486de78e06404f"
                      rel="noreferrer"
                      target="_blank"
                      onPress={handleLearnMore}
                    >
                      Learn more
                    </LinkButton>
                  </div>
                </GuidanceCard>
              </div>
            )}
          </div>
          {showPHQ9OrGAD7GuidanceCard && (
            <div className="pt-3">
              <GuidanceCard variant="neutral">
                <div className="gap flex flex-col items-start gap-2">
                  <span>
                    Clients from Headway will be sent the PHQ-9 and GAD-7 a
                    total of 4 times, once every 30 days. This client has{' '}
                    <b>
                      {remainingScheduledSends} remaining scheduled send
                      {remainingScheduledSends > 1 ? 's' : ''}
                    </b>
                    . You can remove or override this default setting by making
                    customizations below.
                  </span>
                  <Button variant="link" onPress={handleRemoveDefaultSetting}>
                    Remove default setting
                  </Button>
                </div>
              </GuidanceCard>
            </div>
          )}
          <div className="mb-4">
            <FormControl
              aria-labelledby="manage-assessments-checkbox-group-label"
              name="enabledAssessmentTypes"
              component={CheckboxGroup}
            >
              {availableAssessmentTypes.map((assessmentType) => {
                const showNQFStudyBadge =
                  isProviderOptedIntoNQF &&
                  NQF_PATIENT_ASSESSMENT_TYPES.has(assessmentType);
                const showRateBoostQualifiedBadge =
                  enabledAssessmentTypes.includes(assessmentType) &&
                  providerEnrollmentSummary?.isProviderEnrolled;

                return (
                  <div key={assessmentType}>
                    <div className="my-0 px-2 pb-6 pt-5" key={assessmentType}>
                      <Checkbox
                        value={assessmentType}
                        checked={enabledAssessmentTypes.includes(
                          assessmentType
                        )}
                      >
                        <div>
                          <div
                            className={`flex gap-2 ${
                              // If the assessment is PROMIS or WHODAS_2 and provider is NQF & rate eligible
                              // make badges wrap to new line
                              (assessmentType ===
                                PatientAssessmentType.PROMIS ||
                                assessmentType ===
                                  PatientAssessmentType.WHODAS_2) &&
                              showNQFStudyBadge &&
                              showRateBoostQualifiedBadge
                                ? 'flex-col'
                                : ''
                            }`}
                          >
                            <b>
                              {FULL_ASSESSMENT_NAMES[assessmentType]} |{' '}
                              {SHORT_ASSESSMENT_NAMES[assessmentType]}
                            </b>
                            <div className="flex gap-2">
                              {showNQFStudyBadge && (
                                <Badge variant="info">NQF study</Badge>
                              )}
                              {showRateBoostQualifiedBadge &&
                                providerEnrollmentSummary?.isProviderEnrolled && (
                                  <Badge variant="info">
                                    Rate boost qualified
                                  </Badge>
                                )}
                            </div>
                          </div>
                          {ASSESSMENT_CATEGORIES[assessmentType]} ·{' '}
                          {ASSESSMENT_QUESTION_COUNTS[assessmentType]} questions
                        </div>
                      </Checkbox>
                      {!isFillOutOnBehalfForClient &&
                        enabledAssessmentTypes.includes(assessmentType) && (
                          <div className="mt-2 pl-7">
                            <AssessmentScheduleInput name={assessmentType} />
                          </div>
                        )}
                    </div>
                    <Divider autoSpacing={false} />
                  </div>
                );
              })}
            </FormControl>
          </div>
          <BodyText>
            <span className="text-system-gray">
              Get answers to{' '}
              <Link
                href="https://help.headway.co/hc/en-us/articles/22620367351956-Sending-assessments-to-clients-on-Headway#h_01HTCY4XARS8DEPF3REEJ3578K"
                rel="noreferrer"
                target="_blank"
              >
                <span className="text-system-gray">
                  common questions about assessments.
                </span>
              </Link>
            </span>
          </BodyText>
        </ManageAssessmentsFormWrapper>
      </ModalContent>
    </>
  );
};
