import { BillingType } from '@headway/api/models/BillingType';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderAppointmentRead } from '@headway/api/models/ProviderAppointmentRead';
import { UserRead } from '@headway/api/models/UserRead';
import { PPR_FRONTEND_CARRIERS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';

import { useProvider } from './useProvider';
import { useProviderPatient } from './useProviderPatient';

export interface UsePrePaymentReview {
  isPPR: boolean;
  pprCarrier: FrontEndCarrierRead | undefined;
}

export const usePrePaymentReview = (
  patient?: UserRead,
  appointment?: ProviderAppointmentRead,
  interestedCarriers?: number[]
): UsePrePaymentReview => {
  const provider = useProvider();
  const pprFrontendCarrierIds: number[] = useFlag(PPR_FRONTEND_CARRIERS, []);
  const interestedPprFrontendCarrierIds = interestedCarriers
    ? pprFrontendCarrierIds.filter((id) => interestedCarriers.includes(id))
    : pprFrontendCarrierIds;

  const { carriersById } = useFrontEndCarriers();
  const { data: providerPatient, isLoading: isProviderPatientLoading } =
    useProviderPatient({ providerId: provider.id, patientId: patient?.id });

  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: provider?.id!,
        patientUserId: patient?.id!,
        appointmentId: appointment?.id,
      },
      { enabled: !!provider && !!patient }
    );

  if (
    !isProviderPatientLoading &&
    providerPatient?.billingTypeDefault === BillingType.SELF_PAY
  ) {
    return { isPPR: false, pprCarrier: undefined };
  }

  const blanketCarrierId = !!matchingProviderFrontEndCarrier
    ? interestedPprFrontendCarrierIds?.find(
        (id) => id === matchingProviderFrontEndCarrier?.frontEndCarrierId
      )
    : undefined;
  const pprCarrier = blanketCarrierId
    ? carriersById[blanketCarrierId]
    : undefined;

  const isPPR = !!pprCarrier;

  return { isPPR, pprCarrier };
};
