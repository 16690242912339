import React, { useCallback, useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  useCreateProviderAppointmentAttachmentMutation,
  useDeleteProviderAppointmentAttachmentMutation,
} from '~/legacy/mutations/providerAppointmentAttachment';
import {
  AttachmentsListV2,
  AttachmentV2,
} from '~/legacy/views/Patients/AttachmentsListV2';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { BodyText } from '@headway/helix/BodyText';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile } from '@headway/shared/utils/upload';
import { notifyError } from '@headway/ui/utils/notify';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';

import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../stores/AppointmentConfirmationContextV2';
import { FormControlRHF } from '../FormControlRHF';
import { AppointmentConfirmationModalFormV2Values } from '../modals/AppointmentConfirmationModalV2';
import { ProgressNoteFormV2Values } from './ProgressNote/ProgressNoteFormV2';

const MemoizedRichTextArea = React.memo(RichTextArea);

export enum AddendumType {
  TEXT = 'TEXT',
  UPLOAD = 'UPLOAD',
}

export interface AddendumsEditFormV2Values {
  addendumHtml: string | undefined;
  attachments: AttachmentV2[];
  addendumType: AddendumType;
}

export const getAddendumsInitialValues = (): AddendumsEditFormV2Values => {
  return {
    addendumHtml: undefined,
    attachments: [],
    addendumType: AddendumType.TEXT,
  };
};

export const AddendumsEdit = ({
  addendums,
}: {
  addendums: ProviderAppointmentAddendumRead[];
}) => {
  const { event, provider } = useContext(AppointmentConfirmationContextV2);
  const { user } = useAuthStore();
  const isImpersonatingProvider = isGroupAdminImpersonatingProvider(
    provider,
    user
  );

  const {
    formState: { errors },
    setValue,
    trigger,
    clearErrors,
  } = useFormContext<AppointmentConfirmationModalFormV2Values>();

  const progressNoteValues: ProgressNoteFormV2Values = useWatch({
    name: 'progressNote',
  });
  const addendumsEditValues: AddendumsEditFormV2Values = useWatch({
    name: 'addendumsEdit',
  });

  /**
   * We want to restrict file type to only PDF with certain restrictions
   */
  const allowedFileTypes =
    //allowedUploadTypes ??
    'application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png';

  const createProviderAppointmentAttachmentMutation =
    useCreateProviderAppointmentAttachmentMutation();
  const deleteProviderAppointmentAttachmentMutation =
    useDeleteProviderAppointmentAttachmentMutation();

  const onAddAttachments = useCallback(
    async (files: UploadedFile[]) => {
      const newlyAttachedFiles = files.map((file) => ({
        name: file.name,
        link: file.link,
      }));

      const newAttachment =
        await createProviderAppointmentAttachmentMutation.mutateAsync({
          providerAppointmentId: event?.providerAppointment?.id!,
          providerAppointmentAttachment: newlyAttachedFiles[0],
          isActive: false,
        });

      setValue('addendumsEdit.attachments', [
        ...(addendumsEditValues.attachments || []),
        newAttachment,
      ]);
      trigger('addendumsEdit.attachments');
    },
    [
      createProviderAppointmentAttachmentMutation,
      event?.providerAppointment?.id,
      setValue,
      trigger,
      addendumsEditValues.attachments,
    ]
  );

  const onDeleteAttachment = useCallback(
    (providerAppointmentAttachmentId?: number | undefined) => {
      if (providerAppointmentAttachmentId) {
        deleteProviderAppointmentAttachmentMutation.mutateAsync(
          providerAppointmentAttachmentId
        );

        // Filter out the deleted attachment
        const updatedAttachments = (
          addendumsEditValues.attachments || []
        ).filter(
          (attachment) => attachment.id !== providerAppointmentAttachmentId
        );

        setValue('addendumsEdit.attachments', updatedAttachments);
        trigger('addendumsEdit.attachments');
      }
    },
    [
      deleteProviderAppointmentAttachmentMutation,
      setValue,
      trigger,
      addendumsEditValues.attachments,
    ]
  );

  const onDownloadAttachment = useCallback(async (attachment: AttachmentV2) => {
    if (!attachment.link || !attachment.name) {
      return;
    }

    try {
      await downloadFile({ link: attachment.link, name: attachment.name });
    } catch (err) {
      notifyError('There was an issue downloading your attachment');
      logException(err);
    }
  }, []);

  useEffect(() => {
    clearErrors();
  }, [addendumsEditValues.addendumType, clearErrors]);

  const MemoizedBigRadioGroup = React.memo(BigRadioGroup);
  const MemoizedBigRadio = React.memo(BigRadio);

  return (
    <>
      {progressNoteValues.progressNoteType &&
        {
          [ProgressNoteType.UPLOAD]: (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x4,
              }}
            >
              <SectionHeader>Addendum {addendums.length + 1}</SectionHeader>
              <BodyText>
                Specify any additions or changes. You can write your addendum
                using free text or upload an existing addendum.
              </BodyText>
            </div>
          ),
          [ProgressNoteType.TEMPLATE]: (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: theme.spacing.x4,
              }}
            >
              <SectionHeader>Addendum {addendums.length + 1}</SectionHeader>
              {!isImpersonatingProvider && (
                <SubBodyText>
                  Specify any additions or changes, which will appear at the
                  bottom of your original note.
                </SubBodyText>
              )}
            </div>
          ),
          [ProgressNoteType.NONE]: null,
        }[progressNoteValues.progressNoteType]}
      {progressNoteValues.progressNoteType !== ProgressNoteType.NONE && (
        <div
          css={{
            marginBottom: theme.spacing.x4,
            marginTop: theme.spacing.x2,
          }}
        >
          <FormControlRHF
            component={MemoizedBigRadioGroup}
            name="addendumsEdit.addendumType"
            aria-label="Fill out a template or upload an existing note"
          >
            <div css={{ flex: 1 }}>
              <MemoizedBigRadio key="text" value={AddendumType.TEXT}>
                Free Text
              </MemoizedBigRadio>
            </div>
            <div css={{ flex: 1 }}>
              <MemoizedBigRadio key="upload" value={AddendumType.UPLOAD}>
                Upload Existing Addendum
              </MemoizedBigRadio>
            </div>
          </FormControlRHF>
        </div>
      )}
      <div key={addendumsEditValues.addendumType} css={{ minHeight: 150 }}>
        {addendumsEditValues.addendumType === AddendumType.TEXT ? (
          <>
            <BodyText>
              {isImpersonatingProvider
                ? 'Only the provider present at the session can write an addendum'
                : 'Reminder: To be compliant, an addendum should add to, change or clarify details, not remove them.'}
            </BodyText>
            <FormControlRHF
              key="rich-text-addendum"
              component={MemoizedRichTextArea}
              name="addendumsEdit.addendumHtml"
              disabled={isImpersonatingProvider}
              placeholder="Enter addendum notes..."
              aria-label="enter-addendum-notes"
            />
          </>
        ) : (
          <>
            {progressNoteValues.progressNoteType !== ProgressNoteType.NONE && (
              <div css={{ marginBottom: theme.spacing.x4 }}>
                <BodyText>
                  Reminder: To be compliant, an addendum should add to, change
                  or clarify details, not remove them.
                </BodyText>
              </div>
            )}
            <AttachmentsListV2
              provider={provider}
              attachments={addendumsEditValues.attachments || []} // Default to an empty array
              onAddAttachments={onAddAttachments}
              onDownloadAttachment={onDownloadAttachment}
              onDeleteAttachment={onDeleteAttachment}
              accept={allowedFileTypes}
              variant={'helix'}
              hasErrors={!!errors.addendumsEdit?.attachments}
            />
            {!!errors.addendumsEdit?.attachments && (
              <div
                css={{
                  marginTop: theme.spacing.x1,
                  color: theme.color.foreground['danger-secondary'],
                  ...theme.typography.caption.medium,
                }}
              >
                {errors.addendumsEdit.attachments?.message}
              </div>
            )}
          </>
        )}
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: theme.spacing.x4,
          paddingBottom: theme.spacing.x4,
        }}
      >
        <SectionHeader>Declaration</SectionHeader>
        <BodyText>
          By clicking "Sign and submit addendum" I attest that the content of
          this addendum is true and accurate.
        </BodyText>
      </div>
    </>
  );
};

export const AddendumsFormV2 = () => {
  const { progressNoteState, addendums, provider } = useContext(
    AppointmentConfirmationContextV2
  );

  const onDownloadAttachment = useCallback(async (attachment: AttachmentV2) => {
    if (!attachment.link || !attachment.name) {
      return;
    }

    try {
      await downloadFile({ link: attachment.link, name: attachment.name });
    } catch (err) {
      notifyError('There was an issue downloading your attachment');
      logException(err);
    }
  }, []);

  return (
    <div css={{ marginBottom: theme.spacing.x6 }} id="addendums-form-container">
      {addendums?.map((addendum, idx) => {
        const type = addendum.addendumHtml
          ? AddendumType.TEXT
          : AddendumType.UPLOAD;
        return (
          <div css={{ marginBottom: theme.spacing.x4 }} key={addendum.id}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: theme.spacing.x2,
              }}
            >
              <SectionHeader color="disabledGray">
                Addendum {(idx + 1).toString()}
              </SectionHeader>
            </div>
            {
              {
                [AddendumType.TEXT]: (
                  <FormControlRHF
                    data-testid={`addendumFreetext${idx + 1}`}
                    disabled
                    component={MemoizedRichTextArea}
                    name={`addendumFreetext${idx + 1}`}
                    value={addendum.addendumHtml}
                    placeholder="Enter addendum notes..."
                    aria-label="enter-addendum-notes"
                  />
                ),
                [AddendumType.UPLOAD]: (
                  <AttachmentsListV2
                    provider={provider}
                    attachments={addendum.attachments.map((a) => ({
                      name: a.name!,
                      link: a.link!,
                      s3ObjectKey: a.s3ObjectKey!,
                    }))}
                    disabled
                    hideDropzone
                    isDeleteDisabled
                    onDownloadAttachment={onDownloadAttachment}
                    accept="application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png"
                    variant={'helix'}
                  />
                ),
              }[type]
            }
          </div>
        );
      })}

      {progressNoteState === ProgressNoteState.ADDENDUM_EDITING &&
        addendums && <AddendumsEdit addendums={addendums} />}
    </div>
  );
};
