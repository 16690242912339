/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ADNM8Submission {
    ADNM8_1: number;

    ADNM8_2: number;

    ADNM8_3: number;

    ADNM8_4: number;

    ADNM8_5: number;

    ADNM8_6: number;

    ADNM8_7: number;

    ADNM8_8: number;

    assessmentType?: ADNM8SubmissionAssessmentType;

    mostStrainingEvent?: string;

    otherStressfulEvents?: string;

    stressfulEvents?: Array<string>;

}


    export enum ADNM8SubmissionAssessmentType {
            ADNM8 = 'ADNM8'
    }

