import { css, cx } from '@emotion/css';
import { useCallback, useContext, useMemo } from 'react';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { IconDownloadSimple } from '@headway/helix/icons/DownloadSimple';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { Tooltip, TooltipTrigger } from '@headway/helix/Tooltip';
import { trackEvent } from '@headway/shared/utils/analytics';

import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
} from 'utils/access';

import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../../../stores/AppointmentConfirmationContextV2';
import {
  areAdditionalAddendumsAllowed,
  numbersOfAddendumsAllowed,
} from '../utils';

export const ProgressNoteHeaderV2 = () => {
  const {
    event,
    progressNote,
    progressNoteState,
    progressNoteType,
    documentRemediation,
    addendums,
    switchToEditProgressNote,
    switchToEditAddendum,
    setIsProgressNoteDownloadAgreementModalOpen,
  } = useContext(AppointmentConfirmationContextV2);

  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpoofingAsMedicalRecordAuditor = isSpoofing
    ? isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles)
    : false;

  const isInSubmittedState = useMemo(
    () =>
      (progressNoteState === ProgressNoteState.SIGNED ||
        progressNoteState === ProgressNoteState.ADDENDUM_EDITING) &&
      progressNoteType !== ProgressNoteType.NONE,
    [progressNoteType, progressNoteState]
  );

  const handleEditClick = useCallback(() => {
    if (progressNote?.attestedOn) {
      switchToEditAddendum();
    } else {
      switchToEditProgressNote();
    }
  }, [
    progressNote?.attestedOn,
    switchToEditProgressNote,
    switchToEditAddendum,
  ]);

  const handleAddAddendumClick = useCallback(() => {
    switchToEditAddendum();
    const element = document.getElementById('addendums-form-container');
    if (element) {
      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }, [switchToEditAddendum]);

  const isAddAddendumButtonDisabled = useMemo(() => {
    return (
      addendums &&
      !areAdditionalAddendumsAllowed(addendums, documentRemediation)
    );
  }, [addendums, documentRemediation]);

  return (
    <div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: theme.spacing.x2,
        }}
      >
        <div
          css={{
            display: 'flex',
            gap: theme.spacing.x2,
            paddingBottom: theme.spacing.x2,
            alignItems: 'center',
          }}
        >
          <SectionHeader>Progress Note</SectionHeader>
          {progressNoteType !== ProgressNoteType.NONE && (
            <div
              className={cx(chipCss, {
                [submittedCss]: isInSubmittedState,
              })}
            >
              <CaptionText>
                <strong>
                  {isInSubmittedState ? 'Submitted' : 'In Progress'}
                </strong>
              </CaptionText>
            </div>
          )}
        </div>
        {progressNoteType !== ProgressNoteType.NONE && progressNoteState
          ? {
              [ProgressNoteState.SAVED_FOR_LATER]: (
                <Button variant="secondary" onPress={handleEditClick}>
                  Edit
                </Button>
              ),
              [ProgressNoteState.SIGNED]: (
                <div
                  css={{
                    display: 'flex',
                    gap: theme.spacing.x2,
                    alignItems: 'center',
                  }}
                >
                  {event?.providerAppointment!.status ===
                    ProviderAppointmentStatus.DETAILS_CONFIRMED &&
                    !(
                      event?.providerAppointment.progressNoteType ===
                      ProgressNoteType.NONE
                    ) && (
                      <Button
                        variant="secondary"
                        disabled={
                          isSpoofing && !isSpoofingAsMedicalRecordAuditor
                        }
                        onPress={() => {
                          setIsProgressNoteDownloadAgreementModalOpen(true);
                          trackEvent({
                            name: 'Download Progress Note Button Clicked',
                            properties: {
                              providerId: event.providerId,
                              providerAppointmentId:
                                event.providerAppointment!.id,
                            },
                          });
                        }}
                      >
                        <div
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            gap: theme.spacing.x2,
                          }}
                        >
                          <IconDownloadSimple />
                          Download note
                        </div>
                      </Button>
                    )}
                  <TooltipTrigger>
                    <Button
                      variant="secondary"
                      disabled={!!isAddAddendumButtonDisabled}
                      onPress={handleAddAddendumClick}
                    >
                      Add Addendum
                    </Button>
                    <Tooltip>
                      {addendums &&
                        areAdditionalAddendumsAllowed(
                          addendums,
                          documentRemediation
                        ) &&
                        `${numbersOfAddendumsAllowed(
                          addendums,
                          documentRemediation
                        )} addendums remaining`}
                    </Tooltip>
                  </TooltipTrigger>
                </div>
              ),
              [ProgressNoteState.EDITING]: null,
              [ProgressNoteState.ADDENDUM_EDITING]: null,
            }[progressNoteState]
          : null}
      </div>
    </div>
  );
};

const chipCss = css({
  backgroundColor: theme.color.system.lightGray,
  color: theme.color.system.textBlack,
  borderRadius: '2px',
  padding: `0 4px`,
});

const submittedCss = css({
  backgroundColor: theme.color.hue.lightGreen,
  color: theme.color.system.green,
});
