import { isEqual } from 'lodash';
import moment from 'moment';

import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderDocumentRemediationWithAuditResults } from '@headway/api/models/ProviderDocumentRemediationWithAuditResults';

import { GenericTemplate } from './Template/Renderer/types';
import { ProgressNoteTemplateRollout } from './Template/utils';
import { ProgressNoteComponentMetadata } from './types';

export const getRecommendedTemplateForCPTCode = (
  templateRollout: ProgressNoteTemplateRollout,
  selectedCptCodes?: string[] | undefined
) => {
  if (templateRollout.enabled === undefined || !selectedCptCodes) {
    return;
  }

  for (let x = 0; x < templateRollout.enabled.length; x++) {
    const templateRolloutInfo = templateRollout.enabled[x];

    if (templateRolloutInfo.cptCodes === undefined) {
      continue;
    }

    for (let y = 0; y < templateRolloutInfo.cptCodes.length; y++) {
      if (isEqual(selectedCptCodes, templateRolloutInfo.cptCodes[y])) {
        return {
          id: templateRolloutInfo.id,
          version: templateRolloutInfo.version,
        };
      }
    }
  }

  return undefined;
};

export const getTemplate = (
  templates?: GenericTemplate<ProgressNoteComponentMetadata>[],
  id?: number,
  version?: number
) =>
  id !== undefined && version !== undefined && templates !== undefined
    ? templates?.find((t) => {
        return t.templateInfo.id === id && t.templateInfo.version === version;
      })
    : undefined;

export const NUM_ALLOWED_ADDENDUMS = 2;

export const areAdditionalAddendumsAllowed = (
  addendums: ProviderAppointmentAddendumRead[],
  documentRemediation?: ProviderDocumentRemediationWithAuditResults
): boolean => {
  if (documentRemediation) {
    // If the document was under review and has been either submitted or not
    // submitted, we don't allow any more addendums
    if (documentRemediation.status !== DocumentRemediationStatus.NEEDS_REVIEW) {
      return false;
    }

    // If the document is under review, and we already have 2 addendums,
    // see if they happened before the review and allow another one.
    if (addendums.length === NUM_ALLOWED_ADDENDUMS) {
      const documentRequestDate = moment(documentRemediation.createdOn);

      const addendumHappenedAfterRequest = addendums.some((addendum) => {
        const addendumAttestedOnDate = moment(addendum.attestedOn);
        return addendumAttestedOnDate.isAfter(documentRequestDate);
      });

      if (addendumHappenedAfterRequest) {
        return false;
      } else {
        return true;
      }
    }
  }

  return addendums.length < NUM_ALLOWED_ADDENDUMS;
};

export const numbersOfAddendumsAllowed = (
  addendums: ProviderAppointmentAddendumRead[],
  documentRemediation?: ProviderDocumentRemediationWithAuditResults
): number => {
  if (documentRemediation) {
    // if the document is under review, and we already have 2 addendums,
    // confirm that they happened before the review and allow another one.
    if (addendums.length === NUM_ALLOWED_ADDENDUMS) {
      const documentRequestDate = moment(documentRemediation.createdOn);

      const addendumHappenedAfterRequest = addendums.some((addendum) => {
        const addendumAttestedOnDate = moment(addendum.attestedOn);
        return addendumAttestedOnDate.isAfter(documentRequestDate);
      });

      if (addendumHappenedAfterRequest) {
        return 0;
      } else {
        return 1;
      }
    }
  }

  return NUM_ALLOWED_ADDENDUMS - addendums.length;
};

export const getTemplateIdAndVersionFromFormValue = (formValue?: string) => {
  if (!formValue?.includes('-')) {
    return [undefined, undefined];
  }

  try {
    const [id, version] = formValue.split('-');
    return [parseInt(id), parseInt(version)];
  } catch {
    throw new Error('Failed getting template id and version from form value');
  }
};

export const createNoteJsonObject = (
  template: GenericTemplate<unknown>,
  response: any
) => ({
  templateInfo: template.templateInfo,
  response,
});
