/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ISISubmission {
    ISI_1: number;

    ISI_2: number;

    ISI_3: number;

    ISI_4: number;

    ISI_5: number;

    ISI_6: number;

    ISI_7: number;

    assessmentType?: ISISubmissionAssessmentType;

}


    export enum ISISubmissionAssessmentType {
            ISI = 'ISI'
    }

