import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePayerQuestionnairesKeyArgs {
  provider: ProviderRead;
  liveOnly?: boolean;
  incompleteOnly?: boolean;
  intakeCompleteStatesOnly?: boolean;
}

export const getUsePayerQuestionnairesKey = ({
  provider,
  liveOnly,
  incompleteOnly,
  intakeCompleteStatesOnly,
}: UsePayerQuestionnairesKeyArgs): [
  string,
  number,
  ProviderLicenseStateRead[],
  boolean | undefined,
  boolean | undefined,
  boolean | undefined,
] => [
  'payer-questionnaires',
  provider?.id,
  provider?.activeProviderLicenseStates,
  liveOnly,
  incompleteOnly,
  intakeCompleteStatesOnly,
];

const {
  useSingleQuery: usePayerQuestionnaires,
  useListQuery: usePayerQuestionnairesList,
} = createBasicApiHooks(
  getUsePayerQuestionnairesKey,
  ({
    provider,
    liveOnly = false,
    incompleteOnly = false,
    intakeCompleteStatesOnly = false,
  }) => {
    return ProviderApi.getProviderPayerQuestionnaires(provider.id!, {
      incomplete_only: incompleteOnly,
      live_only: liveOnly,
      intake_complete_states_only: intakeCompleteStatesOnly,
    });
  },
  ({ provider }) => {
    return !!provider;
  },
  () => 'Failed to fetch payer questionnaires'
);

export { usePayerQuestionnaires, usePayerQuestionnairesList };
