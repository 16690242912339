import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { useProvider, useProviderPatient } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParam } from 'react-use';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { useUserAppointmentReadiness } from '@headway/shared/hooks/useUserAppointmentReadiness';
import { trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import {
  wasPatientInNoDataOutage,
  wasPatientInOldDataOutage,
} from 'utils/userInsuranceOutage';

import { BillingMethodInfo } from './BillingMethodInfo';
import { BillingMethodModal } from './BillingMethodModal';
import { EmployeeAssistancePrograms } from './EmployeeAssistancePrograms';
import { InsuranceIneligibilityExplainerModal } from './InsuranceIneligibilityExplainerModal';
import { InsuranceModal } from './InsuranceModal';
import { InsuranceOutageToEligibleAlertModal } from './InsuranceOutageToEligibleAlertModal';
import { InsuranceOutageToIneligibleAlertModal } from './InsuranceOutageToIneligibleAlertModal';
import { InsuranceOutageVerificationInProgressModal } from './InsuranceOutageVerificationInProgressModal';
import { NonInsuranceOutagePriceVerificationModal } from './NonInsuranceOutagePriceVerificationModal';
import { PaymentInfo } from './PaymentInfo';
import { PaymentMethodModal } from './PaymentMethodModal';
import { PricingInfo } from './PricingInfo';

interface BillingDetailProps {
  clientId: number;
}

export const BillingDetail = ({ clientId }: BillingDetailProps) => {
  const shouldShowBillingMethodModalDefault =
    useSearchParam('showBillingModal') !== null;

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [isPlanEligibleAlertModalOpen, setIsPlanEligibleAlertModalOpen] =
    useState<boolean>(false);
  const [isBillingMethodModalOpen, setIsBillingMethodModalOpen] =
    useState<boolean>(shouldShowBillingMethodModalDefault);
  const [
    isIneligibilityExplainerModalOpen,
    setIsIneligibilityExplainerModalOpen,
  ] = useState<boolean>(shouldShowBillingMethodModalDefault);
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] =
    useState<boolean>(false);
  const [isPlanIneligibleAlertModalOpen, setIsPlanIneligibleAlertModalOpen] =
    React.useState<boolean>(false);
  const [
    isNoDataOutageVerificationInProgressModalOpen,
    setIsNoDataOutageVerificationInProgressModalOpen,
  ] = React.useState<boolean>(false);
  const [
    isOldDataOutageVerificationInProgressModalOpen,
    setIsOldDataOutageVerificationInProgressModalOpen,
  ] = React.useState<boolean>(false);
  const [
    isNonInsuranceOutagePriceVerificationModalOpen,
    setIsNonInsuranceOutagePriceVerificationModalOpen,
  ] = React.useState<boolean>(false);

  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: client?.id,
  });
  const appointmentReadinessQuery = useUserAppointmentReadiness({
    userId: clientId,
    providerId: provider.id,
  });
  const { insuranceStatus } = useInsuranceStatus(
    client,
    client?.activeUserInsurance
  );

  const insurance = client?.activeUserInsurance;

  const patientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });
  const wasInNoDataOutage = wasPatientInNoDataOutage(
    insurance,
    client,
    provider.id
  );
  const wasInOldDataOutage = wasPatientInOldDataOutage(
    insurance,
    client,
    provider.id
  );

  const { billingTypeDefault, providerSelfPayAttestationDate } =
    providerPatient || {};
  const isInsuranceBillingMethodSetup =
    (billingTypeDefault === BillingType.INSURANCE &&
      insuranceStatus !== PatientInsuranceOrEAPStatus.NO_INSURANCE) ||
    (billingTypeDefault === BillingType.SELF_PAY &&
      !!providerSelfPayAttestationDate) ||
    // An insurance status of IN_NETWORK for an EAP patient does not necessarily mean that they have an insurance, so
    // we perform the additional check on activeUserInsurance here.
    // TODO: Refactor InsuranceStatus so that it is not overloaded with both in-network-ness and has-valid-insurance-ness.
    (billingTypeDefault === BillingType.EAP &&
      insuranceStatus !== PatientInsuranceOrEAPStatus.NO_INSURANCE &&
      client?.activeUserInsurance);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_ID) {
    throw new Error('REACT_APP_GOOGLE_MAPS_API_ID expected');
  }

  const shouldShowAnthemEAPExperience = useShouldShowAnthemEAPExperience(
    clientId,
    providerPatient?.id,
    undefined
  );

  const shouldDisplayInsuranceOutageToVerifiedGuidanceCard =
    (wasInNoDataOutage || wasInOldDataOutage) &&
    insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK &&
    moment().isBefore(
      moment(insurance?.latestOutageDeactivatedOn).add(7, 'days')
    );

  useEffect(() => {
    if (
      (wasInNoDataOutage || wasInOldDataOutage) &&
      shouldDisplayInsuranceOutageToVerifiedGuidanceCard &&
      client &&
      client.activeUserInsurance
    )
      trackPageView({
        name: 'Verification Completed Banner Viewed',
        properties: {
          providerId: provider.id,
          patientUserId: client.id,
          userInsuranceId: `${client.activeUserInsurance.id}`,
          prelimPricingType: `${client.activeUserInsurance.latestOutageType}`,
          copyVariant: '',
        },
      });
  }, [
    wasInNoDataOutage,
    wasInOldDataOutage,
    shouldDisplayInsuranceOutageToVerifiedGuidanceCard,
    client,
    client?.activeUserInsurance,
    provider.id,
  ]);

  return (
    <div>
      <PageSection css={billingDetailCss.billing}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing.x2,
          }}
        >
          <div css={{ marginBottom: theme.spacing.x3 }}>
            {shouldShowAnthemEAPExperience.data && (
              <GuidanceCard variant="info">
                <BodyText>
                  {patientFirstName} is covered by an active EAP program.
                  Eligible sessions will first use their free EAP sessions. Once
                  those benefits are exhausted, their sessions will be covered
                  by insurance.
                  <div>
                    <LinkButton
                      href={
                        'https://help.headway.co/hc/en-us/articles/7473561009428-Seeing-a-client-with-an-Employee-Assistance-Program-EAP'
                      }
                      target="_blank"
                      variant="link"
                    >
                      Learn more
                    </LinkButton>
                  </div>
                </BodyText>
              </GuidanceCard>
            )}
          </div>
          <h2
            css={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <SectionHeader>Billing method</SectionHeader>
            {providerPatient && (
              <Button
                variant="link"
                onPress={() => setIsBillingMethodModalOpen(true)}
              >
                {isInsuranceBillingMethodSetup ? 'Update' : 'Set up'}
              </Button>
            )}
          </h2>
          <BodyText>
            As a reminder, you must be credentialed with {patientFirstName}
            's health plan in the state where they're located during the
            session.
          </BodyText>
        </div>
        {shouldDisplayInsuranceOutageToVerifiedGuidanceCard && (
          <div className="no-data-outage-benefits-verified">
            <GuidanceCard variant="positive">
              <BodyText>Your client’s benefits are verified</BodyText>
              <Button
                size="large"
                variant="link"
                onPress={() => setIsPlanEligibleAlertModalOpen(true)}
              >
                Learn more
              </Button>
            </GuidanceCard>
          </div>
        )}
        {providerPatient ? (
          isInsuranceBillingMethodSetup ? (
            <BillingMethodInfo
              clientId={clientId}
              onOpenContactFormInsuranceIssues={() => {
                setIsNonInsuranceOutagePriceVerificationModalOpen(true);
              }}
              onClickOpenBillingMethodModal={() =>
                setIsBillingMethodModalOpen(true)
              }
              onClickOpenInsuranceIneligibilityExplainerModal={() =>
                setIsIneligibilityExplainerModalOpen(true)
              }
              onOpenInsuranceModal={() => setIsInsuranceModalOpen(true)}
              onOpenPlanIneligibleAlertModal={() =>
                setIsPlanIneligibleAlertModalOpen(true)
              }
              onOpenNoDataOutageVerificationInProgressModal={() =>
                setIsNoDataOutageVerificationInProgressModalOpen(true)
              }
              onOpenOldDataOutageVerificationInProgressModal={() =>
                setIsOldDataOutageVerificationInProgressModalOpen(true)
              }
              wasInNoDataOutage={wasInNoDataOutage}
              wasInOldDataOutage={wasInOldDataOutage}
            />
          ) : (
            <BodyText>
              {billingTypeDefault === BillingType.EAP
                ? 'No insurance added yet.'
                : 'Missing billing method. Please set up before you see the client.'}
            </BodyText>
          )
        ) : (
          <Skeleton variant="rectangular" height={64} />
        )}
      </PageSection>
      {(billingTypeDefault === BillingType.INSURANCE ||
        billingTypeDefault === BillingType.EAP) && (
        <>
          <EmployeeAssistancePrograms clientId={clientId} />

          <PricingInfo
            clientId={clientId}
            onOpenNoDataOutageVerificationInProgressModal={() =>
              setIsNoDataOutageVerificationInProgressModalOpen(true)
            }
            onOpenOldDataOutageVerificationInProgressModal={() =>
              setIsOldDataOutageVerificationInProgressModalOpen(true)
            }
          />
        </>
      )}
      <PaymentInfo
        clientId={clientId}
        userAppointmentReadiness={appointmentReadinessQuery?.data}
        openPaymentModal={() => setIsPaymentModalOpen(true)}
      />
      <InsuranceIneligibilityExplainerModal
        client={client}
        provider={provider}
        open={isIneligibilityExplainerModalOpen}
        onClose={() => setIsIneligibilityExplainerModalOpen(false)}
        issues={appointmentReadinessQuery?.data?.insurance || []}
      />
      <BillingMethodModal
        clientId={clientId}
        open={isBillingMethodModalOpen}
        onClose={() => setIsBillingMethodModalOpen(false)}
      />
      <PaymentMethodModal
        clientId={clientId}
        open={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
      />
      <InsuranceModal
        clientId={clientId}
        open={isInsuranceModalOpen}
        onClose={() => setIsInsuranceModalOpen(false)}
      />
      {client && (
        <InsuranceOutageToEligibleAlertModal
          client={client}
          open={isPlanEligibleAlertModalOpen}
          onClose={() => setIsPlanEligibleAlertModalOpen(false)}
        />
      )}
      {client && (
        <InsuranceOutageToIneligibleAlertModal
          client={client}
          clientInsurance={client?.activeUserInsurance}
          open={isPlanIneligibleAlertModalOpen}
          onClose={() => setIsPlanIneligibleAlertModalOpen(false)}
          onUpdateInsurance={() => {
            setIsPlanIneligibleAlertModalOpen(false);
            setIsBillingMethodModalOpen(true);
          }}
          issues={appointmentReadinessQuery?.data?.insurance || []}
        />
      )}
      {client && (
        <InsuranceOutageVerificationInProgressModal
          client={client}
          open={isNoDataOutageVerificationInProgressModalOpen}
          onClose={() =>
            setIsNoDataOutageVerificationInProgressModalOpen(false)
          }
        />
      )}
      {client && (
        <InsuranceOutageVerificationInProgressModal
          client={client}
          open={isOldDataOutageVerificationInProgressModalOpen}
          onClose={() =>
            setIsOldDataOutageVerificationInProgressModalOpen(false)
          }
        />
      )}
      {client && (
        <NonInsuranceOutagePriceVerificationModal
          isOpen={isNonInsuranceOutagePriceVerificationModalOpen}
          onDismiss={() =>
            setIsNonInsuranceOutagePriceVerificationModalOpen(false)
          }
          patient={client}
          onUpdateInsuranceInformation={() => {
            setIsNonInsuranceOutagePriceVerificationModalOpen(false);
            setIsInsuranceModalOpen(true);
          }}
        />
      )}
    </div>
  );
};

const billingDetailCss = {
  billing: css({
    h2: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    gap: theme.spacing.x3,
  }),
};
