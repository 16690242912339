import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ComboBox, Item, Section } from '@headway/helix/ComboBox';
import { Select } from '@headway/helix/Select';
import {
  COMMON_DIAGNOSIS_CODES,
  DXCodeInfo,
  LESS_COMMON_DIAGNOSIS_CODES,
  PHYSIOLOGICAL_CODES,
} from '@headway/shared/constants/diagnosisCodes';

import { FormControlRHF } from '../../../FormControlRHF';

const shouldIncludePhysiologicalCodes = (
  chosenCodes: DXCodeInfo[]
): boolean => {
  // https://docs.google.com/document/d/13-u6iW0sVv7c9F_v09LOqO60SQhJnXGK-_CiayPISy4/edit
  const result = chosenCodes.some((code) => {
    const value = code.value.toUpperCase();
    return (
      value.startsWith('F01') ||
      value.startsWith('F02') ||
      value === 'F04' ||
      value === 'F05' ||
      value.startsWith('F06') ||
      value.startsWith('F07') ||
      value === 'F09' ||
      value === 'F48.2' ||
      value === 'F54'
    );
  });
  return result;
};

const physiologicalCodeValues = PHYSIOLOGICAL_CODES.map((c) => c.value);

const orderedDiagnosisCodes = (codes: DXCodeInfo[]): DXCodeInfo[] => {
  // physiological codes go first
  // everything else should stay in order it was entered.
  return codes.sort((a, b) => {
    if (
      physiologicalCodeValues.includes(a.value) &&
      physiologicalCodeValues.includes(b.value)
    ) {
      return 0;
    }
    if (physiologicalCodeValues.includes(a.value)) {
      return -1;
    }
    if (physiologicalCodeValues.includes(b.value)) {
      return 1;
    }
    return 0;
  });
};

type ComboBoxProps = React.ComponentProps<typeof ComboBox>;

interface DiagnosisCodeComboBoxV2Props
  extends Omit<ComboBoxProps, 'children' | 'items'> {
  disabled?: boolean;
  helpText?: React.ReactNode;
  instructionalText?: React.ReactNode;
  optionalityText?: React.ReactNode;
  name: string;
  searchable?: boolean;
}

const allCodesByKey = Object.entries({
  'Common codes': COMMON_DIAGNOSIS_CODES,
  'Less common codes': LESS_COMMON_DIAGNOSIS_CODES,
  'Physiological codes': PHYSIOLOGICAL_CODES,
});

const commonAndLessCommonCodesByKey = Object.entries({
  'Common codes': COMMON_DIAGNOSIS_CODES,
  'Less common codes': LESS_COMMON_DIAGNOSIS_CODES,
});

const allCodes = [
  ...COMMON_DIAGNOSIS_CODES,
  ...LESS_COMMON_DIAGNOSIS_CODES,
  ...PHYSIOLOGICAL_CODES,
];

const codesByValue = allCodes.reduce(
  (acc, code) => {
    acc[code.value] = code;
    return acc;
  },
  {} as Record<string, DXCodeInfo>
);

export const DX_COMBOBOX_DEFAULT_HELP_TEXT = 'Select multiple if applicable.';

const DiagnosisCodeComboBoxV2 = (props: DiagnosisCodeComboBoxV2Props) => {
  const {
    disabled = false,
    helpText,
    instructionalText,
    optionalityText,
    name,
    placeholder,
    selectionMode,
    ...rest
  } = props;
  const { setValue, trigger } = useFormContext();
  const values = useWatch({ name });

  const singleOrArrayValue = values;
  const value = Array.isArray(singleOrArrayValue)
    ? singleOrArrayValue
    : singleOrArrayValue
    ? [singleOrArrayValue]
    : [];
  const includePhysiologicalCodes = shouldIncludePhysiologicalCodes(
    value || []
  );

  const options = React.useMemo(() => {
    const items = includePhysiologicalCodes
      ? allCodesByKey
      : commonAndLessCommonCodesByKey;

    return items.map(([description, codes]) => {
      return { description, items: codes };
    });
  }, [includePhysiologicalCodes]);

  return (
    <div
      css={{
        '& .hlx-chip-root .code-combobox-desc': {
          display: 'none',
        },
      }}
    >
      <FormControlRHF
        name={name}
        disabled={disabled}
        helpText={helpText}
        component={Select}
        instructionalText={instructionalText}
        label="Diagnosis codes"
        menuWidth="stretch"
        onSelectionChange={(keys) => {
          const newValues = Array.from(keys, (key) => codesByValue[key]);
          setValue(
            name,
            selectionMode === 'single'
              ? newValues[0]
              : orderedDiagnosisCodes(newValues)
          );
          trigger(name);
        }}
        selectedKeys={
          new Set(value.map((code: DXCodeInfo) => code.value) ?? [])
        }
        placeholder={placeholder}
        selectionMode={selectionMode}
        searchable={rest.searchable}
      >
        {options.map((section) => (
          <Section
            key={section.description}
            title={section.description}
            items={section.items}
          >
            {(code) => {
              return (
                <Item key={code.value} textValue={code.value}>
                  <span className="code-combobox-value">{code.value}</span>
                  <span className="code-combobox-desc flex-1">
                    {code.display}
                  </span>
                </Item>
              );
            }}
          </Section>
        ))}
      </FormControlRHF>
    </div>
  );
};

export { DiagnosisCodeComboBoxV2 };
