import { DocumentationRemediation } from './DocumentationRemediation/DocumentationRemediation';
import { DocumentationRequest } from './DocumentationRequest/DocumentationRequest';
import { DocumentRequirementsPAPilot } from './DocumentRequirementsPAPilot/DocumentRequirementsPAPilot';
import { MedicareAndMedicaid } from './MedicareAndMedicaid/MedicareAndMedicaid';
import { PausedPayments } from './PausedPayments/PausedPayments';
import { PPRAnthemCANV } from './PPRAnthemCANV/PPRAnthemCANV';

// Order is from highest priority to least priority
export const RulesInPriorityOrder = [
  PPRAnthemCANV,
  DocumentationRequest,
  DocumentationRemediation,
  MedicareAndMedicaid,
  DocumentRequirementsPAPilot,
  PausedPayments,
];
