export const joinWithOxfordComma = (strings: string[]): string => {
  if (strings.length === 0) {
    return '';
  } else if (strings.length === 1) {
    return strings[0];
  } else if (strings.length === 2) {
    return strings.join(' and ');
  } else {
    const lastElement = strings.pop();
    return strings.join(', ') + ', and ' + lastElement;
  }
};

/**
 * Appends an 's' to the given word if `count` is anything other than 1.
 * This doesn't handle words with nonstandard plural forms, e.g. 'entry' -> 'entries'.
 */
export const pluralize = (word: string, count: number): string =>
  count === 1 ? word : word + 's';
