/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ASRSAgeCategory } from './ASRSAgeCategory';
import { ASRSResponse } from './ASRSResponse';
export interface ASRSSubmission {
    AGE: ASRSAgeCategory;

    ASRS_1?: ASRSResponse;

    ASRS_10?: ASRSResponse;

    ASRS_11?: ASRSResponse;

    ASRS_12?: ASRSResponse;

    ASRS_13?: ASRSResponse;

    ASRS_14?: ASRSResponse;

    ASRS_15?: ASRSResponse;

    ASRS_16?: ASRSResponse;

    ASRS_17?: ASRSResponse;

    ASRS_18?: ASRSResponse;

    ASRS_2?: ASRSResponse;

    ASRS_3?: ASRSResponse;

    ASRS_4?: ASRSResponse;

    ASRS_5?: ASRSResponse;

    ASRS_6?: ASRSResponse;

    ASRS_7?: ASRSResponse;

    ASRS_8?: ASRSResponse;

    ASRS_9?: ASRSResponse;

    assessmentType?: ASRSSubmissionAssessmentType;

}


    export enum ASRSSubmissionAssessmentType {
            ASRS = 'ASRS'
    }

