import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link as HelixLink } from '@headway/helix/Link';
import { NO_DATA_FLAT_FEE } from '@headway/shared/constants/userInsuranceOutage';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useProviderPrice } from 'hooks/useProviderPrice';
import { getPriceString } from 'utils/price';

// Guidance card used on the sigmund calendar page when
// scheduling or confirming a patient's appointment
export function InsuranceOutageCalendarGuidanceCard({
  patient,
  provider,
}: {
  patient?: UserRead;
  provider?: ProviderRead;
}) {
  const { data: providerPrice, isLoading: providerPriceLoading } =
    useProviderPrice({ provider: provider, client: patient });

  if (providerPriceLoading) {
    return null;
  }

  if (!patient) {
    return null;
  }
  if (!patient.activeUserInsurance?.isInOutage) {
    return null;
  }
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });
  const isInOldDataOutage = patient.activeUserInsurance?.isInOldDataOutage;
  const isInNoDataFlatFeeOutage =
    patient.activeUserInsurance?.isInNoDataFlatFeeOutage;
  const accumulatorsPresumedReset =
    patient?.activeUserInsurance?.latestOutage?.accumulatorsPresumedReset;
  const accumulatorsWereReset = accumulatorsPresumedReset?.length ?? 0 > 0;
  const isInNoDataOutage = patient.activeUserInsurance?.isInNoDataOutage;
  const billingFrontEndCarrierName =
    patient?.activeUserInsurance?.frontEndCarrierName ||
    `${patientFirstName}’s insurer`;
  const providerPriceString = !!providerPrice
    ? getPriceString(
        providerPrice.otherSessionsMinPrice,
        providerPrice.otherSessionsMaxPrice
      )
    : 'full cost';
  let outageBannerText = '';
  if (isInOldDataOutage && !accumulatorsWereReset) {
    outageBannerText = `Reminder: ${billingFrontEndCarrierName} is working to get us the information we need to verify ${patientFirstName}’s benefits. If you book a session,
      they’ll pay their most recent cost (adjusted by CPT code) with an autocorrection later if ${billingFrontEndCarrierName} confirms a different final cost.`;
  } else if (isInOldDataOutage && accumulatorsWereReset) {
    outageBannerText = `Reminder: ${billingFrontEndCarrierName} is working to get us the information we need to verify ${patientFirstName}’s benefits. If you book a session,
    they’ll pay ${providerPriceString} (due to their deductible or out-of-pocket max likely resetting) with an autocorrection later if ${billingFrontEndCarrierName} confirms a different final cost.`;
  } else if (isInNoDataFlatFeeOutage) {
    outageBannerText = `Reminder: ${billingFrontEndCarrierName} is working to get us the information we need to verify ${patientFirstName}’s benefits. If you book a session,
      they’ll pay a temporary cost of $${NO_DATA_FLAT_FEE} with an autocorrection later if ${billingFrontEndCarrierName} confirms a different final cost.`;
  } else if (isInNoDataOutage) {
    outageBannerText = `Reminder: ${billingFrontEndCarrierName} is working to get us the information we need to verify ${patientFirstName}’s benefits. If you book a session,
      they’ll pay full cost cost (adjusted by CPT code) with an autocorrection later if ${billingFrontEndCarrierName} confirms a different final cost.`;
  }
  return (
    <GuidanceCard variant="warning" layout={'vertical'}>
      <ContentText>
        <div className={'mb-2'}>{outageBannerText}</div>
        <HelixLink
          href={'https://help.headway.co/hc/en-us/articles/21767328647828'}
          target="_blank"
        >
          <ContentText variant="body/medium">Learn more</ContentText>
        </HelixLink>
      </ContentText>
    </GuidanceCard>
  );
}
