interface Options {
  truncate?: boolean;
}

export const formatPrice = (
  dollars?: number | null,
  truncate = true
): string => {
  if (dollars === undefined || dollars === null) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  })
    .format(dollars)
    .replace(truncate ? '.00' : '', '');
};

export const getPriceString = (
  min: number | undefined,
  max: number | undefined,
  options: Options = {}
): string => {
  if (max == null || min == null) {
    return '';
  }

  return min === max
    ? formatPrice(min, options.truncate)
    : `${formatPrice(min, options.truncate)}-${formatPrice(
        max,
        options.truncate
      )}`;
};
