/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum CodingEnforcementErrorType {
    NO_INSURANCE_ON_FILE = 'NO_INSURANCE_ON_FILE',
    NO_EAP_ON_FILE = 'NO_EAP_ON_FILE',
    CONCURRENT_CODE = 'CONCURRENT_CODE',
    PSYCHOTHERAPY_WITH_E_AND_M = 'PSYCHOTHERAPY_WITH_E_AND_M',
    PSYCHOTHERAPY_WITH_CRISIS = 'PSYCHOTHERAPY_WITH_CRISIS',
    DUPLICATE_CONFIRM_SAME_DAY = 'DUPLICATE_CONFIRM_SAME_DAY',
    E_AND_M_NEW_PATIENTS_ONLY = 'E_AND_M_NEW_PATIENTS_ONLY',
    DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_6_MONTHS = 'DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_6_MONTHS',
    DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_6_MONTHS = 'DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_6_MONTHS',
    DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA = 'DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA',
    DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA = 'DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA',
    DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21 = 'DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21',
    DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21 = 'DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21',
    INTERACTIVE_COMPLEXITY_WITH_OTHER_CODES = 'INTERACTIVE_COMPLEXITY_WITH_OTHER_CODES',
    FCODE_FIRST_REQUIRED = 'FCODE_FIRST_REQUIRED',
    ZCODE_WITHOUT_FCODE = 'ZCODE_WITHOUT_FCODE',
    RCODE_WITHOUT_FCODE = 'RCODE_WITHOUT_FCODE',
    MULTIPLE_F32_CODES = 'MULTIPLE_F32_CODES',
    MULTIPLE_F33_CODES = 'MULTIPLE_F33_CODES',
    CODE_NOT_SUPPORTED_BY_ALL_INSURANCE_PLANS = 'CODE_NOT_SUPPORTED_BY_ALL_INSURANCE_PLANS',
    HALLUCINATION_OUTSIDE_INTAKE = 'HALLUCINATION_OUTSIDE_INTAKE',
    OPTUM_EAP_DISALLOWED_CODE = 'OPTUM_EAP_DISALLOWED_CODE',
    CIGNA_EAP_DISALLOWED_CODE = 'CIGNA_EAP_DISALLOWED_CODE',
    ANTHEM_EAP_DISALLOWED_CODE = 'ANTHEM_EAP_DISALLOWED_CODE',
    ANTHEM_CA_DISALLOWED_CODE = 'ANTHEM_CA_DISALLOWED_CODE',
    MULTIPLE_BASE_CODES = 'MULTIPLE_BASE_CODES',
    MULTIPLE_UNIQUE_ADD_ONS = 'MULTIPLE_UNIQUE_ADD_ONS',
    NO_CPT_CODES = 'NO_CPT_CODES',
    NO_DIAGNOSIS_CODES = 'NO_DIAGNOSIS_CODES',
    MANIC_WITH_BIPOLAR = 'MANIC_WITH_BIPOLAR',
    BIPOLAR_WITH_MANIC = 'BIPOLAR_WITH_MANIC',
    MANIC_WITH_DEPRESSIVE = 'MANIC_WITH_DEPRESSIVE',
    DEPRESSIVE_WITH_MANIC = 'DEPRESSIVE_WITH_MANIC',
    MANIC_WITH_RECURRENT = 'MANIC_WITH_RECURRENT',
    RECURRENT_WITH_MANIC = 'RECURRENT_WITH_MANIC',
    BIPOLAR_WITH_DEPRESSIVE = 'BIPOLAR_WITH_DEPRESSIVE',
    DEPRESSIVE_WITH_BIPOLAR = 'DEPRESSIVE_WITH_BIPOLAR',
    BIPOLAR_WITH_RECURRENT = 'BIPOLAR_WITH_RECURRENT',
    RECURRENT_WITH_BIPOLAR = 'RECURRENT_WITH_BIPOLAR',
    DEPRESSIVE_WITH_RECURRENT = 'DEPRESSIVE_WITH_RECURRENT',
    RECURRENT_WITH_DEPRESSIVE = 'RECURRENT_WITH_DEPRESSIVE',
    EATING_DISORDER_WITH_ANOREXIA = 'EATING_DISORDER_WITH_ANOREXIA',
    EATING_DISORDER_WITH_POLYPHAGIA = 'EATING_DISORDER_WITH_POLYPHAGIA',
    EATING_DISORDER_WITH_FEEDING_PROBLEMS_OF_NEWBORN = 'EATING_DISORDER_WITH_FEEDING_PROBLEMS_OF_NEWBORN',
    PSYCHOTHERAPY_WITHOUT_E_AND_M = 'PSYCHOTHERAPY_WITHOUT_E_AND_M',
    PSYCHOTHERAPY_FOR_CRISIS_BY_ITSELF = 'PSYCHOTHERAPY_FOR_CRISIS_BY_ITSELF',
    PANIC_WITH_AGORAPHOBIA = 'PANIC_WITH_AGORAPHOBIA',
    ANOREXIA_BINGE_PURGE_WITH_BULIMIA = 'ANOREXIA_BINGE_PURGE_WITH_BULIMIA',
    ANOREXIA_NERVOSA_WITH_PSYCHOGENIC_LOSS_OF_APPETITE = 'ANOREXIA_NERVOSA_WITH_PSYCHOGENIC_LOSS_OF_APPETITE',
    G47_9_CANNOT_BE_PRIMARY = 'G47_9_CANNOT_BE_PRIMARY',
    G89_4_CODE_ALSO_DX = 'G89_4_CODE_ALSO_DX',
    G89_4_EXCLUDES_DX = 'G89_4_EXCLUDES_DX',
    G93_31_EXCLUDES_DX = 'G93_31_EXCLUDES_DX',
    E66_09_EXCLUDES_DX = 'E66_09_EXCLUDES_DX',
    R46_81_EXCLUDES_F42 = 'R46_81_EXCLUDES_F42',
    BMI_WITHOUT_OBESITY = 'BMI_WITHOUT_OBESITY',
    Z56_9_WITH_PRIMARY_F_CODE = 'Z56_9_WITH_PRIMARY_F_CODE',
    Z60_0_WITH_PRIMARY_F_CODE = 'Z60_0_WITH_PRIMARY_F_CODE',
    Z63_0_WITH_Z69_1 = 'Z63_0_WITH_Z69_1',
    Z63_0_WITH_PRIMARY_F_CODE = 'Z63_0_WITH_PRIMARY_F_CODE',
    Z63_0_WITH_Z70_ = 'Z63_0_WITH_Z70_',
    Z63_4_WITH_PRIMARY_F_CODE = 'Z63_4_WITH_PRIMARY_F_CODE',
    F84_0_WITH_F84_5 = 'F84_0_WITH_F84_5',
    F50_83_WITH_F_98_3 = 'F50_83_WITH_F_98_3',
    F50_84_WITH_F_98_21 = 'F50_84_WITH_F_98_21',
    F53_0_WITH_O90_6 = 'F53_0_WITH_O90_6',
    F64_1_WITH_F64_2 = 'F64_1_WITH_F64_2',
    F64_2_WITH_F64_0 = 'F64_2_WITH_F64_0',
    F33_8_WITH_F30_ = 'F33_8_WITH_F30_',
    F33_8_WITH_F31_ = 'F33_8_WITH_F31_',
    F43_8_IS_UNBILLABLE = 'F43_8_IS_UNBILLABLE',
    F06_70_71_EXCLUDES_DX = 'F06_70_71_EXCLUDES_DX',
    R45_2_DISALLOWED = 'R45_2_DISALLOWED',
    PHYSIOLOGICAL_CODE_REQUIRED = 'PHYSIOLOGICAL_CODE_REQUIRED',
    CPT_90837_DURATION_MISMATCH = 'CPT_90837_DURATION_MISMATCH',
    CPT_90834_DURATION_MISMATCH = 'CPT_90834_DURATION_MISMATCH',
    CPT_90833_DURATION_MISMATCH = 'CPT_90833_DURATION_MISMATCH',
    CPT_90833_PSYCHOTHERAPY_DURATION_MISMATCH = 'CPT_90833_PSYCHOTHERAPY_DURATION_MISMATCH',
    CPT_90836_DURATION_MISMATCH = 'CPT_90836_DURATION_MISMATCH',
    CPT_90836_PSYCHOTHERAPY_DURATION_MISMATCH = 'CPT_90836_PSYCHOTHERAPY_DURATION_MISMATCH',
    CPT_90838_DURATION_MISMATCH = 'CPT_90838_DURATION_MISMATCH',
    CPT_90838_PSYCHOTHERAPY_DURATION_MISMATCH = 'CPT_90838_PSYCHOTHERAPY_DURATION_MISMATCH',
    CPT_90833_90836_90838_MISSING_OR_UNREASONABLE_PSYCHOTHERAPY_TIMES = 'CPT_90833_90836_90838_MISSING_OR_UNREASONABLE_PSYCHOTHERAPY_TIMES',
    CPT_90791_90792_ERROR_BELOW_MIN_DURATION = 'CPT_90791_90792_ERROR_BELOW_MIN_DURATION',
    CPT_90791_90792_WARNING_BELOW_AVG_DURATION = 'CPT_90791_90792_WARNING_BELOW_AVG_DURATION',
    DURATION_INVALID = 'DURATION_INVALID',
    OVERLAPPING_APPOINTMENT = 'OVERLAPPING_APPOINTMENT',
    MULTIPLE_E_AND_M = 'MULTIPLE_E_AND_M',
    CRISIS_CODE_WITH_E_AND_M = 'CRISIS_CODE_WITH_E_AND_M',
    CRISIS_CODE_WITH_E_AND_M_CRISIS = 'CRISIS_CODE_WITH_E_AND_M_CRISIS',
    PSYCH_ADDON_WITH_E_AND_M = 'PSYCH_ADDON_WITH_E_AND_M',
    PRESCRIBER_WARNING_99213_90838_90785 = 'PRESCRIBER_WARNING_99213_90838_90785',
    PRESCRIBER_WARNING_99204_90838 = 'PRESCRIBER_WARNING_99204_90838',
    PRESCRIBER_WARNING_99204_90838_90785 = 'PRESCRIBER_WARNING_99204_90838_90785',
    PRESCRIBER_WARNING_99214_90838 = 'PRESCRIBER_WARNING_99214_90838',
    PRESCRIBER_WARNING_99214_90838_90785 = 'PRESCRIBER_WARNING_99214_90838_90785',
    PRESCRIBER_WARNING_99205 = 'PRESCRIBER_WARNING_99205',
    PRESCRIBER_WARNING_99205_90833 = 'PRESCRIBER_WARNING_99205_90833',
    PRESCRIBER_WARNING_99205_90836 = 'PRESCRIBER_WARNING_99205_90836',
    PRESCRIBER_WARNING_99205_90838 = 'PRESCRIBER_WARNING_99205_90838',
    PRESCRIBER_WARNING_99205_90833_90785 = 'PRESCRIBER_WARNING_99205_90833_90785',
    PRESCRIBER_WARNING_99205_90836_90785 = 'PRESCRIBER_WARNING_99205_90836_90785',
    PRESCRIBER_WARNING_99205_90838_90785 = 'PRESCRIBER_WARNING_99205_90838_90785',
    PRESCRIBER_WARNING_99215 = 'PRESCRIBER_WARNING_99215',
    PRESCRIBER_WARNING_99215_90833 = 'PRESCRIBER_WARNING_99215_90833',
    PRESCRIBER_WARNING_99215_90836 = 'PRESCRIBER_WARNING_99215_90836',
    PRESCRIBER_WARNING_99215_90838 = 'PRESCRIBER_WARNING_99215_90838',
    PRESCRIBER_WARNING_99215_90833_90785 = 'PRESCRIBER_WARNING_99215_90833_90785',
    PRESCRIBER_WARNING_99215_90836_90785 = 'PRESCRIBER_WARNING_99215_90836_90785',
    PRESCRIBER_WARNING_99215_90838_90785 = 'PRESCRIBER_WARNING_99215_90838_90785',
    SESSION_LIMIT = 'SESSION_LIMIT',
    E_AND_M_PSYCH_ADDON_SECOND_ONLY = 'E_AND_M_PSYCH_ADDON_SECOND_ONLY',
    E_AND_M_WITH_INTERACTIVE_COMPLEXITY_THIRD = 'E_AND_M_WITH_INTERACTIVE_COMPLEXITY_THIRD',
    E_AND_M_WITH_THIRD_WARNING = 'E_AND_M_WITH_THIRD_WARNING',
    DIAGNOSTIC_EVALUATION_ADD_ON = 'DIAGNOSTIC_EVALUATION_ADD_ON',
    MULTIPLE_INTAKE_CODES = 'MULTIPLE_INTAKE_CODES',
    PHYSIO_DX_POSITION = 'PHYSIO_DX_POSITION'
}
