import { useMemo } from 'react';
import * as Yup from 'yup';

import { AddendumType } from '../../components/forms/AddendumsFormV2';

export const useAddendumsEditValidationSchema = () => {
  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          addendumsEdit: Yup.object().shape({
            addendumHtml: Yup.string().when('addendumType', {
              is: AddendumType.TEXT,
              then: Yup.string().required('Please add your addendum text here'),
              otherwise: Yup.string().notRequired(),
            }),
            attachments: Yup.array().when('addendumType', {
              is: AddendumType.UPLOAD,
              then: Yup.array()
                .min(1, 'Please add an attachment')
                .required('Please add an attachment'),
              otherwise: Yup.array().notRequired(),
            }),
          }),
        })
        .required(),
    []
  );

  return schema;
};
