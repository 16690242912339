// https://developers.cloudflare.com/images/transform-images/integrate-with-frameworks/#nextjs

const normalizeSrc = (src: string) => {
  const normalizedSrc = src.startsWith('/') ? src.slice(1) : src;
  if (normalizedSrc.includes('http')) {
    return encodeURIComponent(normalizedSrc);
  }
  return normalizedSrc;
};

const isHeic = (src: string) => {
  return src.endsWith('.heic') || src.endsWith('.HEIC');
};

export const transformCloudflareImg = ({
  src,
  width,
  height,
  quality = 100,
  format = 'auto',
  fit = 'contain',
}: {
  src?: string;
  width: number;
  height?: number;
  quality?: number;
  format?: string;
  fit?: string;
}) => {
  if (!src) return '';

  const APP_ENV =
    process.env.REACT_APP_ENVIRONMENT ??
    process.env.NEXT_PUBLIC_ENVIRONMENT ??
    process.env.NEXT_PUBLIC_APP_ENVIRONMENT;
  if (isHeic(src) || (APP_ENV !== 'production' && APP_ENV !== 'experimental')) {
    return src;
  }

  const params = [`width=${width}`];
  if (height) {
    params.push(`height=${height}`);
  }
  if (quality) {
    params.push(`quality=${quality}`);
  }
  if (format) {
    params.push(`format=${format}`);
  }
  if (fit) {
    params.push(`fit=${fit}`);
  }
  const paramsString = params.join(',');
  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};
