/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WHO5Submission {
    WHO5_1: number;

    WHO5_2: number;

    WHO5_3: number;

    WHO5_4: number;

    WHO5_5: number;

    assessmentType?: WHO5SubmissionAssessmentType;

}


    export enum WHO5SubmissionAssessmentType {
            WHO5 = 'WHO5'
    }

