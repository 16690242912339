/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReadinessIssueImpact } from './ReadinessIssueImpact';
export interface AssessmentsUpsellReadinessIssueMoreThanOneSessionInPastTwoMonths {
    impact: ReadinessIssueImpact;

    metadata?: object;

    type: AssessmentsUpsellReadinessIssueMoreThanOneSessionInPastTwoMonthsType;

}


    export enum AssessmentsUpsellReadinessIssueMoreThanOneSessionInPastTwoMonthsType {
            MORE_THAN_ONE_SESSION_IN_PAST_TWO_MONTHS = 'MORE_THAN_ONE_SESSION_IN_PAST_TWO_MONTHS'
    }

