import { WarningOutlined } from '@mui/icons-material';
import { Alert } from '@mui/material';
import React from 'react';

import { Button } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { ValidationWarning } from '../validation/miscoding';

export type ValidationWarningAlertProps = {
  onOpenContactFormInsuranceIssues: () => void;
  validationWarning: ValidationWarning;
  id: number;
};

export const ValidationWarningAlert = ({
  onOpenContactFormInsuranceIssues,
  validationWarning,
  id,
}: ValidationWarningAlertProps) => {
  if (!validationWarning.message) {
    return null;
  }

  return (
    <Alert
      key={id}
      color="warning"
      icon={<WarningOutlined />}
      css={{
        marginTop: theme.space.xl,
        marginBottom: theme.space.xl,
      }}
      action={
        validationWarning.showVerificationEntryPoint ? (
          <Button
            color="inherit"
            type="button"
            css={{
              whiteSpace: 'nowrap',
              alignSelf: 'center',
              textDecoration: 'underline',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => onOpenContactFormInsuranceIssues()}
          >
            Verify benefits
          </Button>
        ) : undefined
      }
    >
      {validationWarning.message}
    </Alert>
  );
};
