import { I18nProvider, useLocale } from '@react-aria/i18n';
import { ModalProvider, OverlayProvider, useModalProvider } from 'react-aria';
import { SSRProvider } from 'react-aria';

function HelixProviderImpl({ children }: { children: React.ReactNode }) {
  let { modalProviderProps } = useModalProvider();

  return <div {...modalProviderProps}>{children}</div>;
}

interface HelixProviderProps {
  children: React.ReactNode;
}

function HelixProvider({ children }: HelixProviderProps) {
  return (
    <I18nProvider locale="en-US">
      <OverlayProvider>
        <HelixProviderImpl>{children}</HelixProviderImpl>
      </OverlayProvider>
    </I18nProvider>
  );
}

export { HelixProvider, SSRProvider as HelixSSRProvider };
