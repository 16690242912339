import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { TextArea } from '@headway/helix/TextArea';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';
import { getOptionalityText } from '../utils';

const MemoizedTextArea = React.memo(TextArea);

export const FormTextAreaWithCheckbox = ({
  element,
  isOptional = false,
  disabled = false,
}: SchemaComponent) => {
  const { id, title, placeholder, subTitle, defaultResponse, optionalityText } =
    element;

  const { control, setValue } = useFormContext();
  const watchedValue = useWatch({
    control,
    name: id,
  });

  const optionality = useMemo(
    () => getOptionalityText(isOptional, optionalityText),
    [isOptional, optionalityText]
  );

  const isCheckboxSelected = defaultResponse === watchedValue;

  const [textAreaValue, setTextAreaValue] = useState(
    isCheckboxSelected ? '' : watchedValue
  );

  const [isOpen, setIsOpen] = useState(!isCheckboxSelected);

  const handleTextAreaChange = useCallback((value: any) => {
    setTextAreaValue(value);
  }, []);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setIsOpen(false);
        setValue(id, defaultResponse);
      } else {
        setIsOpen(true);
        setValue(id, '');
      }
      setTextAreaValue('');
    },
    [setIsOpen, setValue, setTextAreaValue, id, defaultResponse]
  );

  useEffect(() => {
    setTextAreaValue(isCheckboxSelected ? '' : watchedValue);
    setIsOpen(!isCheckboxSelected);
  }, [isCheckboxSelected, watchedValue]);

  const sectionContainerStyles = useMemo(() => {
    return css`
      display: grid;
      grid-template-rows: min-content ${isOpen ? 1 : 0}fr;
      transition: grid-template-rows 0.6s ease-in-out;
    `;
  }, [isOpen]);

  return (
    <div css={sectionContainerStyles}>
      <div css={sectionHeaderContainerCss}>
        <div css={sectionHeaderTitleContainerCss}>
          <div className="hlx-text-area-root" css={textAreaOverrideCss}>
            <div className="hlx-text-area-descriptors">
              {title && (
                <label
                  className="hlx-text-area-label"
                  css={{
                    color: disabled
                      ? `${theme.color.system.disabledGray} !important`
                      : 'inherit',
                  }}
                >
                  {title}
                </label>
              )}
              {subTitle && (
                <div
                  className="hlx-text-area-instructional-text"
                  id={subTitle}
                  css={{
                    color: disabled
                      ? `${theme.color.system.disabledGray} !important`
                      : 'inherit',
                  }}
                >
                  {subTitle}
                </div>
              )}
            </div>
          </div>
        </div>
        {defaultResponse && (
          <div css={checkBoxGroupOverrideCss}>
            <CheckboxGroup
              name={`${id}-checkbox`}
              value={isCheckboxSelected ? [defaultResponse] : []}
              disabled={disabled}
              aria-label="Progress-notes checkbox-group with checkbox"
            >
              <Checkbox
                value={defaultResponse}
                disabled={disabled}
                onChange={handleCheckboxChange}
                checked={isCheckboxSelected}
                aria-label="Progress-notes checkbox-group with checkbox item"
              >
                {defaultResponse}
              </Checkbox>
            </CheckboxGroup>
          </div>
        )}
      </div>
      <div css={sectionContentContainerCss}>
        <div>
          <FormControlRHF
            component={MemoizedTextArea}
            key={id}
            name={id}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleTextAreaChange}
            value={textAreaValue}
            optionalityText={optionality}
            aria-label="Progress-notes text area with checkbox"
          />
        </div>
      </div>
    </div>
  );
};

const checkBoxGroupOverrideCss = css`
  div {
    margin-bottom: 0 !important;
  }
`;
const textAreaOverrideCss = css`
  grid-row-gap: 0 !important;
`;
const sectionHeaderContainerCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const sectionHeaderTitleContainerCss = css`
  display: flex;
  width: fit-content;
  flex-direction: column;
`;
const sectionContentContainerCss = css`
  overflow: hidden;
  /* prevent horizontal-overflowing input outlines from being cut off */
  margin: -4px -4px;
  padding: 4px 4px;
`;
