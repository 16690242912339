import { Skeleton } from '@mui/material';
import { useProvider, useProviderPatient } from 'hooks';
import keyBy from 'lodash/keyBy';
import sum from 'lodash/sum';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconClipboardText } from '@headway/helix/icons/ClipboardText';
import { SectionHeader } from '@headway/helix/SectionHeader';
import {
  SplitButton,
  SplitButtonMenu,
  SplitButtonMenuItem,
} from '@headway/helix/SplitButton';
import { ALL_ASSESSMENT_TYPES } from '@headway/shared/constants/patientAssessments';
import {
  FILL_OUT_PROMS_ON_BEHALF_OF_CLIENT,
  PROMS_DOWNLOADS,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useUser } from '@headway/shared/hooks/useUser';
import { useMutation } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { useQueryParams } from '@headway/shared/utils/queryParams';
import { logException } from '@headway/shared/utils/sentry';
import {
  NqfConsentFormModal,
  NqfConsentFormMode,
} from '@headway/ui/NqfConsentForm';
import { notifyError } from '@headway/ui/utils/notify';

import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { usePatientAssessmentCountByType } from 'hooks/usePatientAssessmentCountByType';
import { usePatientAssessmentRecurrenceSchedules } from 'hooks/usePatientAssessmentRecurrenceSchedules';
import { usePatientAssessments } from 'hooks/usePatientAssessments';
import { usePatientAssessmentUnreadCountCache } from 'hooks/usePatientAssessmentUnreadCount';
import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
} from 'utils/access';
import { useTailwindGlobals } from 'utils/css';

import { ClientContext } from '../stores/ClientContext';
import { AssessmentsIncentiveCard } from './AssessmentsIncentiveCard';
import { AssessmentHistory } from './components/AssessmentHistory';
import { AssessmentsEmptyState } from './components/AssessmentsEmptyState';
import { FillOutAssessmentForClientModal } from './components/FillOutAssessments/FillOutAssessmentForClientModal';
import { ManageAssessmentsModal } from './components/ManageAssessmentsModal';
import { useAllAssessmentsPdf } from './downloads/useAllAssessmentsPdf';

interface AssessmentsProps {
  clientId: number;
}

interface ManageAssessmentsURLParams {
  manageAssessments?: string;
}

/** Tab of the client page which displays patient assessments. */
export const Assessments = ({ clientId }: AssessmentsProps) => {
  useTailwindGlobals();

  const isPromsDownloadsEnabled = useFlag(PROMS_DOWNLOADS, false);
  const isFillPromsOnBehalfOfClientEnabled = useFlag(
    FILL_OUT_PROMS_ON_BEHALF_OF_CLIENT,
    false
  );

  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpooferWithoutPermission =
    isSpoofing &&
    !isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles);

  const clientQuery = useUser({ userId: clientId });
  const client = clientQuery.data;
  const navigate = useNavigate();
  const params = useQueryParams<ManageAssessmentsURLParams>();
  const [isManageAssessmentsModalOpen, setIsManageAssessmentsModalOpen] =
    useState<boolean>(false);
  const [isFillOutAssessmentModalOpen, setIsFillOutAssessmentModalOpen] =
    useState<boolean>(false);
  const [selectedAssessmentToFill, setSelectedAssessmentToFill] =
    useState<PatientAssessmentType>();
  const [isNqfConsentFormModalOpen, setIsNqfConsentFormModalOpen] =
    useState<boolean>(false);
  // used to update providerLastViewedAssessmentsOn
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  // used to fire Assessments page viewed event
  const [isFirstPageView, setIsFirstPageView] = useState<boolean>(true);

  const provider = useProvider();
  /** TODO(sc-194373): Add pagination */
  const { data: providerPatient }: { data: ProviderPatientRead | undefined } =
    useProviderPatient({
      providerId: provider.id,
      patientId: clientId,
    });

  const { hasAlerts } = useContext(ClientContext);

  const {
    data: providerEnrollmentSummary,
    isLoading: isEnrollmentSummaryLoading,
  } = useProviderIncentiveProgramEnrollment({
    providerId: provider.id,
  });

  const { data: patientAssessmentCounts } = usePatientAssessmentCountByType(
    {
      providerPatientId: providerPatient?.id,
    },
    {
      refetchOnWindowFocus: false,
      enabled: !isSpooferWithoutPermission,
    }
  );
  const { data: hasCompletedAssessments } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      limit: 1,
      completed_on_date_range_start: new Date(0).toISOString(),
    },
    {
      refetchOnWindowFocus: false,
      select: ({ totalCount }) => totalCount > 0,
      enabled: !isSpooferWithoutPermission,
    }
  );

  const { data: sentWhodasToPatient } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      assessment_type: PatientAssessmentType.WHODAS_2,
      include_expired: true,
      limit: 1,
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.totalCount > 0;
      },
    }
  );

  const { data: sentPromisToPatient } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      assessment_type: PatientAssessmentType.PROMIS,
      include_expired: true,
      limit: 1,
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.totalCount > 0;
      },
    }
  );

  const sentNqfToPatient = sentWhodasToPatient || sentPromisToPatient;

  const { data: patientAssessmentRecurrenceSchedules } =
    usePatientAssessmentRecurrenceSchedules(
      {
        providerPatientId: providerPatient?.id,
      },
      {
        refetchOnWindowFocus: false,
        enabled: !isSpooferWithoutPermission,
      }
    );
  const patientAssessmentUnreadCountCache =
    usePatientAssessmentUnreadCountCache();

  const recurrenceSchedulesByType = keyBy(
    patientAssessmentRecurrenceSchedules,
    'assessmentType'
  );

  const { mutate: updateProviderPatientAssessmentsLastViewed } = useMutation(
    async (providerPatient: ProviderPatientRead) => {
      const payload = {
        providerLastViewedAssessmentsOn: new Date().toISOString(),
      };
      await ProviderPatientApi.updateProviderPatient(
        providerPatient.id,
        payload
      );
    },
    {
      onSuccess: () => {
        patientAssessmentUnreadCountCache.setAndInvalidate(
          { providerPatientId: providerPatient?.id },
          0
        );
      },
      onError: (e) => {
        logException(e);
        notifyError(
          'There was an error updating provider last viewed assessments.'
        );
      },
    }
  );

  useEffect(() => {
    if (providerPatient && isFirstRender) {
      updateProviderPatientAssessmentsLastViewed(providerPatient);
      setIsFirstRender(false);
    }
  }, [
    providerPatient,
    isFirstRender,
    updateProviderPatientAssessmentsLastViewed,
  ]);

  useEffect(() => {
    if (params.manageAssessments) {
      setIsManageAssessmentsModalOpen(true);
      navigate(`/clients/${clientId}/assessments`, { replace: true });
    }
  }, [navigate, params, clientId, setIsManageAssessmentsModalOpen]);

  const totalAssessmentsCount =
    patientAssessmentCounts && sum(Object.values(patientAssessmentCounts));

  const assessmentTypeToIds = new Map<PatientAssessmentType, Set<number>>();
  const addInitiallyVisibleAssessmentIds = (
    assessmentIdsByType: Set<number>,
    assessmentType: PatientAssessmentType
  ) => {
    assessmentTypeToIds.set(assessmentType, assessmentIdsByType);
  };

  const assessmentHistoriesToShow = ALL_ASSESSMENT_TYPES.filter(
    (assessmentType) => {
      // If there are scheduled or sent assessments, render AssessmentTable
      const existsActivePatientAssessmentRecurrenceSchedule =
        patientAssessmentRecurrenceSchedules?.find(
          (schedule) => schedule.assessmentType === assessmentType
        );
      return (
        (patientAssessmentCounts &&
          patientAssessmentCounts[assessmentType] > 0) ||
        existsActivePatientAssessmentRecurrenceSchedule
      );
    }
  );

  const providerId = provider.id;
  useEffect(() => {
    if (
      isFirstPageView &&
      patientAssessmentCounts &&
      assessmentTypeToIds.size === assessmentHistoriesToShow.length
    ) {
      setIsFirstPageView(false);
      let initiallyVisibleAssessmentIds: Array<number> = [];
      assessmentTypeToIds.forEach((value: Set<number>) => {
        value.forEach((id) => initiallyVisibleAssessmentIds.push(id));
      });
      trackPageView({
        name: 'Client Assessment Page Viewed',
        properties: {
          providerId: providerId,
          patientUserId: clientId,
          assessmentIdList:
            initiallyVisibleAssessmentIds.length > 0
              ? initiallyVisibleAssessmentIds
              : null,
        },
      });
    }
  }, [
    isFirstPageView,
    providerId,
    clientId,
    patientAssessmentCounts,
    assessmentTypeToIds.size,
    assessmentHistoriesToShow.length,
  ]);

  const trackDownloadAllAssessments = useCallback(
    (downloadedAssessments?: PatientAssessmentRead[]) => {
      if (!downloadedAssessments || !providerPatient) {
        return;
      }

      trackEvent({
        name: 'Download Assessments Button Clicked',
        properties: {
          numberOfAssessments: String(downloadedAssessments.length),
          patientUserId: providerPatient.userId,
          providerId: providerPatient.providerId,
          providerPatientId: providerPatient.id,
        },
      });
    },
    [providerPatient]
  );

  const [{ loading: isPdfLoading, error: pdfError }, downloadAllAssessments] =
    useAllAssessmentsPdf({
      client,
      provider,
      providerPatient,
      onTrigger: trackDownloadAllAssessments,
    });

  if (isSpooferWithoutPermission) {
    return (
      <div className="py-6">
        <GuidanceCard variant="error">
          You do not have permission to view assessments.
        </GuidanceCard>
      </div>
    );
  }

  if (
    !providerPatient ||
    !patientAssessmentCounts ||
    hasCompletedAssessments == null
  ) {
    return (
      <div className="py-6">
        <Skeleton height={248} variant="rectangular" />
      </div>
    );
  }

  const handleManageAssessmentsPress = () => {
    trackEvent({
      name: 'Manage Assessments Button Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
        providerPatientId: providerPatient.id,
      },
    });
    setIsManageAssessmentsModalOpen(true);
  };

  const handleFillOutOnBehalfOfClient = () => {
    trackEvent({
      name: 'Fill Out With Client in Session Button Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
        providerPatientId: providerPatient.id,
        assessmentTypeList: [],
      },
    });
    setIsFillOutAssessmentModalOpen(true);
  };

  const handleViewNqfConsent = () => {
    setIsNqfConsentFormModalOpen(true);
    trackEvent({
      name: 'Client NQF Assessment View Consent Form Button Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
      },
    });
  };

  const isProviderEnrolledIncentiveProgram =
    !isEnrollmentSummaryLoading &&
    providerEnrollmentSummary?.isProviderEnrolled;

  const noAssessmentsOrRecurrenceSchedules =
    totalAssessmentsCount === 0 &&
    (!patientAssessmentRecurrenceSchedules ||
      patientAssessmentRecurrenceSchedules?.length === 0);

  return (
    <>
      {noAssessmentsOrRecurrenceSchedules &&
      isProviderEnrolledIncentiveProgram &&
      client &&
      !hasAlerts ? (
        <AssessmentsIncentiveCard
          client={client}
          onOpenManageAssessmentsModal={() =>
            setIsManageAssessmentsModalOpen(true)
          }
        />
      ) : noAssessmentsOrRecurrenceSchedules ? (
        <AssessmentsEmptyState
          onOpenManageAssessmentsModal={() =>
            setIsManageAssessmentsModalOpen(true)
          }
          providerPatient={providerPatient}
        />
      ) : null}
      {!noAssessmentsOrRecurrenceSchedules && (
        <>
          <h2 className="flex justify-between px-4 py-6">
            <SectionHeader>Assessments</SectionHeader>
            {isPromsDownloadsEnabled ? (
              <SplitButton variant="secondary">
                <Button
                  variant="secondary"
                  onPress={handleManageAssessmentsPress}
                >
                  Manage
                </Button>
                <SplitButtonMenu>
                  <SplitButtonMenuItem onAction={handleManageAssessmentsPress}>
                    Manage assessments
                  </SplitButtonMenuItem>
                  {hasCompletedAssessments && (
                    <SplitButtonMenuItem
                      onAction={downloadAllAssessments}
                      isDisabled={isPdfLoading || !!pdfError}
                    >
                      Download all assessments
                    </SplitButtonMenuItem>
                  )}
                  {isFillPromsOnBehalfOfClientEnabled && (
                    <SplitButtonMenuItem
                      onAction={handleFillOutOnBehalfOfClient}
                    >
                      Fill out with client in session
                    </SplitButtonMenuItem>
                  )}
                </SplitButtonMenu>
              </SplitButton>
            ) : (
              <Button
                variant="secondary"
                onPress={handleManageAssessmentsPress}
              >
                Manage assessments{' '}
              </Button>
            )}
          </h2>
          <div className="flex flex-col gap-y-3 px-4 py-2">
            {sentNqfToPatient && client && !!client.consentedToNqfOn && (
              <GuidanceCard variant="neutral" layout="horizontal">
                Your client consented to participate in the Aligned Innovation
                Study with Headway on{' '}
                {moment(client.consentedToNqfOn).format('MM/DD/YYYY')}
                <Button variant="link" onPress={handleViewNqfConsent}>
                  View consent
                </Button>
              </GuidanceCard>
            )}
          </div>
          <div className="space-y-6 pb-[80px]">
            {!hasCompletedAssessments && (
              <div className="mx-4 grid grid-cols-[max-content_1fr] gap-x-2 rounded bg-system-backgroundGray p-4">
                <IconClipboardText />
                <BodyText>
                  <b>Check back for your client's results</b>
                </BodyText>
                <span className="col-start-2">
                  <CaptionText>
                    <span className="text-system-gray">
                      Once your client completes their assessments, their
                      results will be shown here.
                    </span>
                  </CaptionText>
                </span>
              </div>
            )}
            {assessmentHistoriesToShow.sort().map((assessmentType) => (
              <AssessmentHistory
                key={assessmentType}
                providerPatient={providerPatient}
                clientId={clientId}
                assessmentType={assessmentType}
                onOpenManageAssessmentsModal={() =>
                  setIsManageAssessmentsModalOpen(true)
                }
                onOpenFillOutAssessmentModal={(assessment) => {
                  setSelectedAssessmentToFill(assessment);
                  setIsFillOutAssessmentModalOpen(true);
                }}
                recurrence={recurrenceSchedulesByType[assessmentType]}
                addInitiallyVisibleAssessmentIds={
                  addInitiallyVisibleAssessmentIds
                }
              />
            ))}
          </div>
        </>
      )}
      {isManageAssessmentsModalOpen && (
        <ManageAssessmentsModal
          isOpen
          onDismiss={() => setIsManageAssessmentsModalOpen(false)}
          clientId={clientId}
          isIntakeFlow={false}
        />
      )}
      {isFillOutAssessmentModalOpen && (
        <FillOutAssessmentForClientModal
          isOpen
          onDismiss={() => {
            setSelectedAssessmentToFill(undefined);
            setIsFillOutAssessmentModalOpen(false);
          }}
          clientId={clientId}
          providerPatient={providerPatient}
          selectedAssessmentToFill={selectedAssessmentToFill}
        />
      )}
      {client && isNqfConsentFormModalOpen && (
        <NqfConsentFormModal
          clientId={client.id}
          isOpen
          onDismiss={() => setIsNqfConsentFormModalOpen(false)}
          mode={NqfConsentFormMode.SUBMITTED}
          providerPatientId={providerPatient.id}
          isSigmund
        />
      )}
    </>
  );
};
