import { useLocation, useNavigate } from 'react-router-dom';

import { ProviderRead } from '@headway/api/models/ProviderRead';

import { PROVIDER_VIEW_STORAGE_KEY } from 'utils/contstants';

import { useAuthStore } from '../stores/AuthStore';

interface SetSelectedProviderOptions {
  /** If true, skips redirecting the user when changing providers. */
  noRedirect?: boolean;
}

/**
 * Updates the selected provider in AuthStore and local storage.
 */
export const useSetSelectedProvider = (): ((
  provider: ProviderRead,
  options?: SetSelectedProviderOptions
) => void) => {
  const AuthStore = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  return (provider: ProviderRead, options: SetSelectedProviderOptions = {}) => {
    AuthStore.setProvider(provider);

    if (
      !options.noRedirect &&
      (location.pathname.match(/\/clients\/[0-9]+/) ||
        location.pathname.match(/\/credentials/) ||
        location.pathname.match(/\/practice\/*/))
    ) {
      navigate('/');
    }

    window.localStorage.setItem(
      PROVIDER_VIEW_STORAGE_KEY,
      provider.id.toString()
    );
  };
};
