import { css } from '@emotion/react';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentAttachmentBase } from '@headway/api/models/ProviderAppointmentAttachmentBase';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile } from '@headway/shared/utils/upload';
import { Form } from '@headway/ui/form';
import { SubmitListener } from '@headway/ui/form/SubmitListener';
import { usePrevious } from '@headway/ui/hooks/usePrevious';
import { notifyError } from '@headway/ui/utils/notify';

import { useProvider } from 'hooks/useProvider';
import { useProviderEvent } from 'hooks/useProviderEvent';
import { isDetailsConfirmed } from 'views/Calendar/events/util/events';
import { Attachment, AttachmentsList } from 'views/Patients/AttachmentsList';

import { AppointmentContext } from '../../stores/AppointmentContext';
import {
  ProgressNoteContext,
  ProgressNotePermissions,
  ProgressNoteState,
} from '../../stores/ProgressNotesContext';

const AttachmentFormAccordionCss = css`
  padding-bottom: 32px;

  .MuiAccordion-root,
  .MuiAccordion-root.Mui-expanded {
    margin-top: 0;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    height: 48px;
    min-height: 48px;
  }
`;

export const convertAttachmentFormValues = (
  attachmentsList: Attachment<string>[]
) =>
  attachmentsList.map((attachment) => ({
    link: attachment.link,
    name: attachment.name,
  }));

export const AppointmentAttachmentForm = ({
  eventVirtualId,
  innerRef,
  onProgressNoteUpdate,
}: {
  eventVirtualId: string;
  innerRef: React.Ref<any>;
  onProgressNoteUpdate?: () => void;
}) => {
  const provider = useProvider();
  const appointmentContext = useContext(AppointmentContext);
  const progressNoteContext = useContext(ProgressNoteContext);
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const previousEvent = usePrevious(event);

  const attachmentsAsFormValues =
    event?.providerAppointment?.attachments?.map(
      (a: ProviderAppointmentAttachmentBase, idx) => ({
        name: a.name!,
        id: a.name!,
        link: a.link!,
        s3ObjectKey: a.s3ObjectKey!,
      })
    ) || [];

  const [attachmentsList, setAttachmentsList] = useState<Attachment<string>[]>(
    attachmentsAsFormValues
  );

  // On initial load, set attachments list
  if (
    !previousEvent?.providerAppointment?.attachments &&
    !attachmentsList.length &&
    attachmentsAsFormValues.length
  ) {
    setAttachmentsList(attachmentsAsFormValues);
  }

  if (progressNoteContext.progressNoteType === ProgressNoteType.NONE) {
    return null;
  }

  const addAttachments = async (files: UploadedFile[]) => {
    const fileAttachment = files.map((file, idx) => ({
      id: file.name,
      name: file.name,
      link: file.link,
      s3ObjectKey: file.s3ObjectKey,
    }));

    setAttachmentsList([...attachmentsList, ...fileAttachment]);
  };

  const onDeleteAttachment = (id: string) => {
    const filteredAttachmentList = attachmentsList.filter(
      (attachment) => attachment.name !== id
    );
    setAttachmentsList(filteredAttachmentList);
  };

  const onDownloadAttachment = async (attachment: Attachment<string>) => {
    if (!attachment.link || !attachment.name) {
      return;
    }

    try {
      await downloadFile({ link: attachment.link, name: attachment.name });
    } catch (err) {
      notifyError('There was an issue downloading your attachment');
      logException(err);
    }
  };

  /**
   * We want to return no react element in the following situations:
   *
   * 1. Signed note with no attachments
   * 2. Saved for later and no attachments
   * 3. Progress note type is template but they haven't selected anything yet
   */

  if (progressNoteContext.progressNoteType === ProgressNoteType.TEMPLATE) {
    switch (progressNoteContext.progressNoteState) {
      case ProgressNoteState.SIGNED:
        if (attachmentsList.length === 0) {
          return null;
        }
        break;

      case ProgressNoteState.SAVED_FOR_LATER:
        if (attachmentsList.length === 0) {
          return null;
        }
        break;

      case ProgressNoteState.EDITING:
        if (!progressNoteContext.selectedTemplate) {
          return null;
        }
        break;
    }
  }

  /**
   * We want to disable the dropzone and deleting in the following scenarios:
   *
   * 1. Appointment is confirmed with an uploaded Note
   * 2. Appointment is confirmed with a signed template
   * 3. Appointment is confirmed with a draft template
   * 4. Appointment is not confirmed with a signed template
   * 5. Appointment is not confirmed with a draft template
   *
   */

  let shouldDisableDropzoneAndDelete = false;

  if (
    !progressNoteContext.notePermissions.find(
      (permission) => permission === ProgressNotePermissions.WRITE
    ) &&
    progressNoteContext.progressNoteType === ProgressNoteType.TEMPLATE
  ) {
    shouldDisableDropzoneAndDelete = true;
  }

  if (isDetailsConfirmed(event)) {
    if (progressNoteContext.progressNoteType === ProgressNoteType.TEMPLATE) {
      switch (progressNoteContext.progressNoteState) {
        case ProgressNoteState.SIGNED:
        case ProgressNoteState.SAVED_FOR_LATER:
        case ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT:
          shouldDisableDropzoneAndDelete = true;
          break;
      }
    } else if (
      progressNoteContext.progressNoteType === ProgressNoteType.UPLOAD
    ) {
      if (
        event?.providerAppointment?.attachments &&
        event?.providerAppointment?.attachments?.length > 0
      ) {
        shouldDisableDropzoneAndDelete = true;
      }
    }
  } else {
    if (progressNoteContext.progressNoteType === ProgressNoteType.TEMPLATE) {
      switch (progressNoteContext.progressNoteState) {
        case ProgressNoteState.SIGNED:
          shouldDisableDropzoneAndDelete = true;
          break;
        case ProgressNoteState.SAVED_FOR_LATER:
          shouldDisableDropzoneAndDelete = true;
          break;
        case ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT:
          shouldDisableDropzoneAndDelete = true;
          break;
      }
    }
  }

  /**
   * We want to restrict file type to only PDF with certain restrictions
   */
  const allowedFileTypes =
    progressNoteContext.allowedUploadTypes ??
    'application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png';

  return (
    <Formik
      initialValues={{ attachments: attachmentsList }}
      enableReinitialize
      validate={({ attachments }) => {
        if (
          progressNoteContext.progressNoteType === ProgressNoteType.UPLOAD &&
          attachments.length === 0 &&
          appointmentContext.confirmSessionDetailsAttempted
        ) {
          return {
            attachments:
              'Upload an attachment to continue. Accepted attachment file types are: pdf, doc, docx, pages.',
          };
        }

        return {};
      }}
      onSubmit={async () => {
        await appointmentContext.updateAttachments(eventVirtualId);
        if (onProgressNoteUpdate) onProgressNoteUpdate();
      }}
      innerRef={innerRef}
    >
      {({ isSubmitting, values, errors, ...props }) => {
        return (
          <>
            {!isDetailsConfirmed(event) && (
              <SubmitListener considerInitialValues={false} />
            )}
            <Form>
              <div css={AttachmentFormAccordionCss}>
                {progressNoteContext.progressNoteType ===
                  ProgressNoteType.TEMPLATE &&
                progressNoteContext.selectedTemplate !== undefined ? (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: theme.spacing.x6,
                    }}
                  >
                    <SectionHeader
                      color={
                        shouldDisableDropzoneAndDelete
                          ? 'disabledGray'
                          : 'textBlack'
                      }
                    >
                      Additional Information
                    </SectionHeader>
                    <SubBodyText
                      color={
                        shouldDisableDropzoneAndDelete
                          ? 'disabledGray'
                          : 'textBlack'
                      }
                    >
                      Upload anything else that might be useful.
                    </SubBodyText>
                  </div>
                ) : null}
                {shouldDisableDropzoneAndDelete &&
                  progressNoteContext.progressNoteType ===
                    ProgressNoteType.UPLOAD && (
                    <div css={{ marginBottom: theme.spacing.x1 }}>
                      <SubBodyText>
                        <strong>Original Upload</strong>
                      </SubBodyText>
                    </div>
                  )}
                <AttachmentsList
                  provider={provider}
                  attachments={values.attachments}
                  onAddAttachments={addAttachments}
                  onDownloadAttachment={onDownloadAttachment}
                  onDeleteAttachment={onDeleteAttachment}
                  accept={allowedFileTypes}
                  disabled={shouldDisableDropzoneAndDelete}
                  isDeleteDisabled={shouldDisableDropzoneAndDelete}
                  hideDropzone={shouldDisableDropzoneAndDelete}
                  variant={'helix'}
                />
                {errors['attachments'] ? (
                  <div
                    css={{
                      marginTop: '20px',
                      color: theme.color.primary.red,
                      ...theme.typography.caption.medium,
                    }}
                  >
                    {errors['attachments'] as string}
                  </div>
                ) : null}
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
