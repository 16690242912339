import { compact, flattenDeep, get } from 'lodash';
import moment, { Moment } from 'moment';
import { GenericTemplate } from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/Renderer/types';
import { ComponentTypes } from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/Renderer/v1/types';
import {
  Component,
  Section,
} from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/Renderer/v2/types';
import { isElementASection } from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/utils';

import { ProviderProgressNoteRead } from '@headway/api/models/ProviderProgressNoteRead';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';

import { Step, TreatmentPlanMetadata } from './Template/types';

export const isSignedTreatmentPlan = (
  treatmentPlan: ProviderTreatmentPlanRead
) => {
  return (
    treatmentPlan.attestedOn !== undefined && treatmentPlan.attestedOn !== null
  );
};

export const getTreatmentPlanName = (
  treatmentPlanId: number,
  treatmentPlans: ProviderTreatmentPlanRead[]
) => {
  const signedTreatmentPlans = treatmentPlans.filter((plan) =>
    isSignedTreatmentPlan(plan)
  );
  const originalTreatmentPlan = signedTreatmentPlans.length
    ? signedTreatmentPlans.reduce((a, b) => (a < b ? a : b))
    : undefined;
  if (!originalTreatmentPlan || treatmentPlanId === originalTreatmentPlan.id) {
    return 'Original treatment plan';
  }
  return 'Updated treatment plan';
};

export type Path = (string | number)[];

export const pathToString = (path: Path) =>
  path
    .map((key) => (typeof key === 'string' ? `.${key}` : `[${key}]`))
    .join('')
    .slice(1);

export const firstStepWithMissingFields = (values: object, steps?: Step[]) =>
  Math.max(
    (steps || []).findIndex((step) => !stepHasAllValues(values, step)),
    0
  );

const sectionHasAllValues = (
  values: object,
  section: Section<TreatmentPlanMetadata>,
  path: Path
): boolean => {
  return (get(values, pathToString(path)) || []).every(
    (sectionInstance: any, idx: number) =>
      section.components.every(
        (
          element:
            | Section<TreatmentPlanMetadata>
            | Component<TreatmentPlanMetadata>
        ) =>
          isElementASection(element)
            ? sectionHasAllValues(values, element, [...path, idx, element.id!])
            : componentHasAllValues(values, [...path, idx, element.id])
      )
  );
};

const componentHasAllValues = (values: object, path: Path): boolean => {
  const value = get(values, path);
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return !!value;
};

export const stepHasAllValues = (values: object, step: Step) => {
  return step.components.every((element) =>
    isElementASection(element)
      ? sectionHasAllValues(values, element, [element.id!])
      : componentHasAllValues(values, [element.id])
  );
};

const isComponentADate = (component: Component<TreatmentPlanMetadata>) => {
  return component.type === ComponentTypes.date ? component.id : '';
};

const sectionHasDates = (section: Section<TreatmentPlanMetadata>): string[] => {
  return section.components
    .map(
      (
        element:
          | Section<TreatmentPlanMetadata>
          | Component<TreatmentPlanMetadata>
      ) => {
        return isElementASection(element)
          ? sectionHasDates(element)
          : isComponentADate(element);
      }
    )
    .flat()
    .filter((element) => element !== '');
};

export const stepHasDates = (step: Step): string[] => {
  return step.components
    .map((element) =>
      isElementASection(element)
        ? sectionHasDates(element)
        : isComponentADate(element)
    )
    .flat()
    .filter((element) => element !== '');
};

const sectionCharacterCount = (
  values: object,
  section: Section<TreatmentPlanMetadata>,
  path: Path
): number => {
  return (get(values, pathToString(path)) || []).reduce(
    (partialCharCount: number, sectionInstance: any, idx: number) => {
      return (
        partialCharCount +
        section.components.reduce(
          (
            accumulatorCount: number,
            element:
              | Section<TreatmentPlanMetadata>
              | Component<TreatmentPlanMetadata>
          ) => {
            return (
              accumulatorCount +
              (isElementASection(element)
                ? sectionCharacterCount(values, element, [
                    ...path,
                    idx,
                    element.id!,
                  ])
                : componentCharacterCount(values, element, [
                    ...path,
                    idx,
                    element.id,
                  ]))
            );
          },
          0
        )
      );
    },
    0
  );
};

const componentCharacterCount = (
  values: object,
  component: Component<TreatmentPlanMetadata>,
  path: Path
): number => {
  const value = get(values, path);
  if (component.type === 'longFreeText') {
    return value.length;
  }

  return 0;
};

export const stepCharacterCount = (values: object, step: Step) => {
  return step.components.reduce(
    (stepCharCount, element) =>
      stepCharCount +
      (isElementASection(element)
        ? sectionCharacterCount(values, element, [element.id!])
        : componentCharacterCount(values, element, [element.id])),
    0
  );
};

export const parseTextFromHtmlString = (html: string) =>
  new DOMParser()
    .parseFromString(html, 'text/html')
    .documentElement.textContent?.replace(/[\s\u00A0]/g, '');

export const getReviewDate = (treatmentPlan: ProviderTreatmentPlanRead) => {
  if (!treatmentPlan.attestedOn) {
    return undefined;
  }

  let reviewDate = moment(treatmentPlan.attestedOn).add(180, 'days');

  if (treatmentPlan.planType === TreatmentPlanType.TEMPLATE) {
    // Valid for <= id_2_template treatment plans
    const reviewDates = compact(
      flattenDeep<Moment | undefined>(
        (treatmentPlan?.planJson as any)?.template?.goals?.map(
          (goal: any) =>
            goal?.objectives?.map((objective: any) =>
              objective?.targetDate ? moment(objective?.targetDate) : undefined
            )
        )
      )
    );
    if (reviewDates.length > 0) {
      reviewDate = moment.min(reviewDates);
    }
  }

  return reviewDate;
};

export const isTreatmentPlanExpired = (
  treatmentPlan: ProviderTreatmentPlanRead,
  isTreatmentPlanRequirementEnabled?: boolean
) => {
  if (!treatmentPlan.attestedOn) {
    return false;
  }

  if (isTreatmentPlanRequirementEnabled) {
    const reviewDate = getReviewDate(treatmentPlan);
    return reviewDate ? moment() > reviewDate : false;
  } else {
    return moment().diff(treatmentPlan.attestedOn, 'days') >= 180;
  }
};

export const isTreatmentPlanExiringSoon = (
  treatmentPlan: ProviderTreatmentPlanRead
) => {
  const reviewDate = getReviewDate(treatmentPlan);
  return reviewDate ? reviewDate.diff(moment(), 'days') <= 7 : false;
};

export const getLatestAttestedTreatmentPlan = (
  treatmentPlans?: ProviderTreatmentPlanRead[]
) => {
  return treatmentPlans?.find((treatmentPlan) =>
    isSignedTreatmentPlan(treatmentPlan)
  );
};

export const getActiveTreatmentPlan = (
  treatmentPlans?: ProviderTreatmentPlanRead[],
  isTreatmentPlanRequirementEnabled?: boolean
) => {
  if (!isTreatmentPlanRequirementEnabled) {
    return undefined;
  }

  const latestAttestedPlan = getLatestAttestedTreatmentPlan(treatmentPlans);

  return latestAttestedPlan &&
    !isTreatmentPlanExpired(
      latestAttestedPlan,
      isTreatmentPlanRequirementEnabled
    )
    ? latestAttestedPlan
    : undefined;
};

export const getCorrespondingValueInTemplate = (
  value: string,
  intakeTherapyNote?: ProviderProgressNoteRead,
  intakeTherapySchema?: GenericTemplate<unknown>
) => {
  // @ts-ignore
  for (let section of intakeTherapySchema?.template) {
    if (section.components) {
      for (let component of section.components) {
        if (component.title === value) {
          // @ts-ignore
          return intakeTherapyNote?.noteJson?.response[component.id];
        }
      }
    }
  }
  return '';
};

export const getPrefillTreatmentPlanFromIntake = (
  providerPatientId?: number,
  dxCodes?: string[],
  intakeTherapyNote?: ProviderProgressNoteRead,
  intakeTherapySchema?: GenericTemplate<unknown>
) => {
  if (
    !providerPatientId ||
    !dxCodes ||
    !intakeTherapyNote ||
    !intakeTherapySchema
  ) {
    return undefined;
  }

  return {
    id: 1,
    providerPatientId: providerPatientId,
    planType: TreatmentPlanType.TEMPLATE,
    planJson: {
      template: {
        diagnosis: dxCodes.map((dxCode: string, index) => {
          return {
            code: {
              value: dxCode,
            },
            symptoms:
              index === 0
                ? getCorrespondingValueInTemplate(
                    'Clinical formulation and differential diagnostic support',
                    intakeTherapyNote,
                    intakeTherapySchema
                  )
                : '',
          };
        }),
        summaryOfPresentingConcern: getCorrespondingValueInTemplate(
          'Presenting problem',
          intakeTherapyNote,
          intakeTherapySchema
        ),
        goals: [
          {
            description: '',
            objectives: [
              {
                description: '',
                targetDate: '',
              },
            ],
          },
        ],
        interventions: [],
      },
    },
  };
};
