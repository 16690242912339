import { useContext } from 'react';
import { useProviderDocumentRequests } from '~/legacy/hooks/useProviderDocumentRequests';

import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useDocumentationRequest = () => {
  const { event } = useContext(AppointmentConfirmationContextV2);
  const { data: documentRequest } = useProviderDocumentRequests(
    {
      providerAppointmentId: event?.providerAppointment?.id,
    },
    { select: (results) => (results.length ? results[0] : undefined) }
  );
  return documentRequest;
};
