/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IESRSubmission {
    IESR_1: number;

    IESR_10: number;

    IESR_11: number;

    IESR_12: number;

    IESR_13: number;

    IESR_14: number;

    IESR_15: number;

    IESR_16: number;

    IESR_17: number;

    IESR_18: number;

    IESR_19: number;

    IESR_2: number;

    IESR_20: number;

    IESR_21: number;

    IESR_22: number;

    IESR_3: number;

    IESR_4: number;

    IESR_5: number;

    IESR_6: number;

    IESR_7: number;

    IESR_8: number;

    IESR_9: number;

    assessmentType?: IESRSubmissionAssessmentType;

}


    export enum IESRSubmissionAssessmentType {
            IESR = 'IESR'
    }

