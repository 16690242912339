import { useFormContext, useWatch } from 'react-hook-form';

import { TelehealthPlatform } from '@headway/api/models/TelehealthPlatform';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Item, Select } from '@headway/helix/Select';
import { telehealthPlatformDisplayNames } from '@headway/shared/constants/telehealthPlatformDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { FormControlRHF } from '../../../FormControlRHF';
import { SessionDetailsFormV2Values } from '../SessionDetailsFormV2';

export const TelehealthLocationsInputsV2 = ({
  canUpdateSessionDetails,
}: {
  canUpdateSessionDetails?: boolean;
}) => {
  let telehealthPlatformOptions = [
    TelehealthPlatform.VIDEO,
    TelehealthPlatform.AUDIO_ONLY_NO_ACCESS,
    TelehealthPlatform.AUDIO_ONLY_REFUSAL,
  ];

  const { setValue } = useFormContext();
  const sessionDetailsValues: SessionDetailsFormV2Values = useWatch({
    name: 'sessionDetails',
  });

  // Account for deprecated telehealthPlatform values
  if (
    !canUpdateSessionDetails &&
    sessionDetailsValues.telehealthPlatform &&
    Object.values(TelehealthPlatform).includes(
      sessionDetailsValues.telehealthPlatform
    )
  ) {
    telehealthPlatformOptions = [
      sessionDetailsValues.telehealthPlatform as TelehealthPlatform,
    ];
  }

  return (
    <>
      <FormControlRHF
        component={RadioGroup}
        name="sessionDetails.telehealthPlatform"
        disabled={!canUpdateSessionDetails}
        label="Type of telehealth session"
      >
        {telehealthPlatformOptions.map((option) => (
          <Radio key={option} value={option}>
            {telehealthPlatformDisplayNames[option as TelehealthPlatform]}
          </Radio>
        ))}
      </FormControlRHF>
      <FormControlRHF
        component={Select}
        name="sessionDetails.telehealthProviderState"
        disabled={!canUpdateSessionDetails}
        label="Where did you join the telehealth session from?"
        placeholder="Select a state"
        selectionMode="single"
        menuWidth="stretch"
        selectedKeys={
          sessionDetailsValues.telehealthProviderState
            ? new Set([
                UnitedStates[
                  sessionDetailsValues.telehealthProviderState as UnitedStates
                ],
              ])
            : new Set()
        }
        onSelectionChange={(items: Set<string>) => {
          const selectedValue =
            items.size > 0
              ? UnitedStates[Array.from(items)[0] as UnitedStates]
              : undefined;
          setValue('sessionDetails.telehealthProviderState', selectedValue);
        }}
      >
        {Object.keys(statesToDisplayNames).map((state) => (
          <Item key={state}>{statesToDisplayNames[state as UnitedStates]}</Item>
        ))}
      </FormControlRHF>
    </>
  );
};
