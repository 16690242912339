import { useFormikContext } from 'formik';
import { debounce, isEqual } from 'lodash';
import * as React from 'react';
import { useContext } from 'react';

import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { toaster } from '@headway/ui/ToastManager';

import {
  createNoteJsonObject,
  ProgressNoteContext,
} from '../../../../stores/ProgressNotesContext';
import { getTemplateIdAndVersionFromFormValue } from '../ProgressNotesForm';
import { getTemplates } from '../Template/utils';
import { ProgressNoteComponentMetadata } from '../types';
import { getTemplate } from '../utils';

/**
 * Autosaving for progress notes. Calls the updateNote function passing
 * in the new response.
 */

interface ProgressNotesSubmitListenerProps {
  considerInitialValues?: boolean;
  updateSavingState(changeTo: boolean): void;
  providerId: number;
  patientId: number;
  providerAppointmentId?: number;
}

enum FormAction {
  UPDATE,
  CREATE,
}

const saveForm = async (
  providerId: number,
  patientId: number,
  providerAppointmentId: number,
  response: { [id: string]: string },
  template: string,
  updateSavingState: (changeTo: boolean) => void,
  updateNote: (values: {
    noteJson: object;
    lateEntryReason?: ProviderProgressNoteLateEntryReason;
    lateEntryOtherReason?: string;
  }) => void,
  createNote: (values: any) => void,
  saveType: FormAction,
  lateEntryReason?: string,
  lateEntryOtherReason?: string
) => {
  const templates = getTemplates<ProgressNoteComponentMetadata>();
  const selectedTemplate = getTemplate(
    templates,
    ...getTemplateIdAndVersionFromFormValue(template)
  );

  if (!selectedTemplate) {
    return;
  }

  const noteJson = createNoteJsonObject(selectedTemplate, response);

  try {
    updateSavingState(true);

    if (saveType === FormAction.CREATE) {
      await createNote({
        providerId,
        patientId,
        providerAppointmentId,
        noteJson,
      });
    } else {
      await updateNote({
        noteJson,
        lateEntryReason: lateEntryReason as ProviderProgressNoteLateEntryReason,
        lateEntryOtherReason,
      });
    }

    updateSavingState(false);
  } catch (error) {
    toaster.error(`We were unable to update the progress note: ${error}`);
  }
};

const debouncedSave = debounce(saveForm, 1200, { maxWait: 1600 });

export const ProgressNotesSubmitListener = ({
  considerInitialValues = true,
  updateSavingState,
  providerId,
  patientId,
  providerAppointmentId,
}: ProgressNotesSubmitListenerProps) => {
  const formik = useFormikContext<any>();
  const { updateNote, progressNote, createNote } =
    useContext(ProgressNoteContext);
  const [lastValues, updateState] = React.useState(undefined);

  React.useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (considerInitialValues) {
      const valuesEqualInitialValues = isEqual(
        formik.values,
        formik.initialValues
      );

      if (!valuesEqualLastValues && !valuesEqualInitialValues) {
        if (!providerAppointmentId) {
          return;
        }

        const {
          template,
          progressNoteType,
          previousNote,
          lateEntryReason,
          lateEntryOtherReason,
          ...response
        } = formik.values;

        debouncedSave(
          providerId,
          patientId,
          providerAppointmentId,
          response,
          template,
          updateSavingState,
          updateNote,
          createNote,
          progressNote ? FormAction.UPDATE : FormAction.CREATE,
          lateEntryReason,
          lateEntryOtherReason
        );
      }
    } else {
      if (!valuesEqualLastValues) {
        if (!providerAppointmentId) {
          return;
        }

        const {
          template,
          progressNoteType,
          previousNote,
          lateEntryReason,
          lateEntryOtherReason,
          ...response
        } = formik.values;
        debouncedSave(
          providerId,
          patientId,
          providerAppointmentId,
          response,
          template,
          updateSavingState,
          updateNote,
          createNote,
          progressNote ? FormAction.UPDATE : FormAction.CREATE,
          lateEntryReason,
          lateEntryOtherReason
        );
      }
    }
  }, [
    formik.values,
    progressNote,
    lastValues,
    considerInitialValues,
    formik.initialValues,
    updateNote,
    updateSavingState,
  ]);

  return null;
};
