import React from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderAppointmentUpdate } from '@headway/api/models/ProviderAppointmentUpdate';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { Modal } from '@headway/ui';

import { useProviderEventCache } from 'hooks/useProviderEvent';
import { useSelectedEvent } from 'hooks/useSelectedEvent';
import {
  UpdateProviderEventMutationArgs,
  useUpdateProviderEventMutation,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';
import { NullableKeys } from 'utils/types';

import { useUpdateProviderEventSideEffectsForBilling } from '../../Practice/utils/queries';
import { AppointmentUndoConfirmedSessionForm } from '../form/AppointmentUndoConfirmedSessionForm';
import { useUpdateProviderEventSideEffectsForCalendar } from '../utils/queries';

interface AppointmentUndoConfirmedSessionModalProps {
  open: boolean;
  onClose: () => void;
}

export const AppointmentUndoConfirmedSessionModal: React.FC<
  React.PropsWithChildren<AppointmentUndoConfirmedSessionModalProps>
> = ({ open, onClose }) => {
  const { event } = useSelectedEvent();
  const providerEventCache = useProviderEventCache();

  const updateEventMutation = useUpdateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useUpdateProviderEventSideEffectsForCalendar())
      .merge(useUpdateProviderEventSideEffectsForBilling()),
  });

  const handleUndoConfirmedSession = async () => {
    if (event) {
      const undoSessionValues = {
        providerAppointment: {
          status: ProviderAppointmentStatus.SCHEDULED,
          sessionDetailsConfirmedOn: null,
        } as NullableKeys<ProviderAppointmentUpdate>,
      } as ProviderEventUpdate;

      await updateEventMutation.mutateAsync({
        eventIdOrVirtualId: event.virtualId,
        update: undoSessionValues,
      });
      onClose();
    }
  };
  return (
    <Modal open={open} onClose={onClose} title="Undo Session Confirmation">
      <AppointmentUndoConfirmedSessionForm
        onClose={onClose}
        handleUndoConfirmedSession={handleUndoConfirmedSession}
      />
    </Modal>
  );
};
