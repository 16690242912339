import React, { useMemo } from 'react';

import { RichTextArea } from '@headway/helix/RichTextArea';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';
import { getInstructionalText, getOptionalityText } from '../utils';

const MemoizedTextArea = React.memo(RichTextArea);

export const FormRichTextArea = ({
  element,
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}: SchemaComponent) => {
  const { id, title, subTitle, placeholder, optionalityText } = element;

  const optionality = useMemo(
    () => getOptionalityText(isOptional, optionalityText),
    [isOptional, optionalityText]
  );

  const instructionalText = useMemo(
    () => getInstructionalText(subTitle, { requiresEdit }),
    [subTitle, requiresEdit]
  );

  return (
    <FormControlRHF
      component={MemoizedTextArea}
      label={title}
      disabled={disabled}
      name={id}
      key={id}
      optionalityText={optionality}
      instructionalText={instructionalText}
      placeholder={placeholder}
      aria-label="Progress-notes rich text area"
    />
  );
};
