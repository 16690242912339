import { FormLabel } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { isProviderInAnyStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { FieldControl } from '@headway/ui/form';
import { FieldDropzone } from '@headway/ui/form';
import { FieldErrorText } from '@headway/ui/form';

import { onDropFiles, YesNo } from '../../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { isNotCanadianAddress } from '../../utils/helpers';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { EmploymentHeader } from './EmploymentHeader';

const statesThatRequireResume = [
  UnitedStates.ALABAMA,
  UnitedStates.ARIZONA,
  UnitedStates.CALIFORNIA,
  UnitedStates.FLORIDA,
  UnitedStates.INDIANA,
  UnitedStates.KANSAS,
  UnitedStates.LOUISIANA,
  UnitedStates.MISSOURI,
  UnitedStates.NEW_JERSEY,
  UnitedStates.OREGON,
  UnitedStates.WASHINGTON,
  UnitedStates.UTAH,
  UnitedStates.IDAHO,
];
const statesThatRequireEmployerInfo = [
  UnitedStates.WASHINGTON,
  UnitedStates.OREGON,
];
const statesThatRequireWorkHistoryCoding = [UnitedStates.LOUISIANA];

const physicianDegreeOptions = [
  'DO - Doctor of Osteopathy',
  'MD - Doctor of Medicine',
];

const WorkHistoryCoding: { [key: string]: string } = {
  C: 'Clinic/Group',
  S: 'Solo practice',
  A: 'Academic (Paid Teaching Appointments)',
  H: 'Civilian Hospital Medical Staff Appointment',
  M: 'Military Service (Including Hospital Staff Appointments)',
  O: 'Other',
};

export const EmploymentInformation: React.FC<
  React.PropsWithChildren<CustomComponentProps>
> = ({ initialValues, formikHelpers }) => {
  const { provider } = useQuestionnaireContext();

  return (
    <>
      <EmploymentHeader initialValues={initialValues} />
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: '24px',
        }}
      >
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x1,
            width: '60%',
          }}
        >
          <SectionHeader>Additional Information</SectionHeader>
        </div>
        <FormControl
          name="belongsToProfessionalSocieties"
          component={RadioGroup}
          label="Do/did you belong to any Professional Organizations or
          Societies?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {formikHelpers.values.belongsToProfessionalSocieties === YesNo.YES && (
          <FormControl
            name="professionalSocieties"
            component={TextField}
            label="Organization or society name(s)"
          />
        )}
        <FormControl
          name="voluntaryOrInvoluntaryTermination"
          component={RadioGroup}
          label="Have you ever been voluntarily or involuntarily terminated
          by an employer?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {isProviderInAnyStates(provider, statesThatRequireResume) && (
          <FieldControl name={`resumeUrl`} fullWidth={true}>
            <FormLabel css={{ marginBottom: theme.spacing.x4 }}>
              Please upload a copy of your resume in <strong>PDF format</strong>
              . This is required in order to get you credentialed with payers.
            </FormLabel>
            <FieldDropzone
              accept="application/pdf"
              onDrop={(file: any) => onDropFiles(provider.id, file)}
              inputTestId="resumeUrl"
            />
            <FieldErrorText />
          </FieldControl>
        )}

        {formikHelpers.values.employment?.map((job, idx) => {
          const showWorkHistoryCodingQuestions = isProviderInAnyStates(
            provider,
            statesThatRequireWorkHistoryCoding
          );
          const showEmployerInfo = isProviderInAnyStates(
            provider,
            statesThatRequireEmployerInfo
          );

          if (!showWorkHistoryCodingQuestions && !showEmployerInfo) return null;
          return (
            <div
              css={{
                ...theme.stack.vertical,
                gap: theme.spacing.x6,
                marginTop: theme.spacing.x4,
              }}
            >
              <div
                css={{
                  ...theme.stack.vertical,
                  gap: theme.spacing.x1,
                  width: '60%',
                }}
              >
                <SectionHeader>{job.employer}</SectionHeader>
              </div>
              {showWorkHistoryCodingQuestions && (
                <FormControl
                  component={Select}
                  name={`employment[${idx}].workHistoryCoding`}
                  label="Degree Level"
                  selectionMode="single"
                  menuWidth="stretch"
                >
                  {Object.keys(WorkHistoryCoding).map((key) => (
                    <Item key={key}>{WorkHistoryCoding[key]}</Item>
                  ))}
                </FormControl>
              )}
              {showEmployerInfo && (
                <>
                  <FormControl
                    name={`employment[${idx}].email`}
                    component={TextField}
                    label="Employer Contact Email"
                  />
                  <FormControl
                    name={`employment[${idx}].preferredMethodOfContact`}
                    component={RadioGroup}
                    label="Preferred method of contact"
                  >
                    <Radio value="Email">Email</Radio>
                    <Radio value="Mail">Mail</Radio>
                  </FormControl>
                  <FormControl
                    name={`employment[${idx}].hasCollaborationAgreement`}
                    component={RadioGroup}
                    label="Do/Did you have a collaboration agreement
                  with a licensed physician?"
                  >
                    <Radio value={YesNo.YES}>Yes</Radio>
                    <Radio value={YesNo.NO}>No</Radio>
                  </FormControl>
                  {job.hasCollaborationAgreement === YesNo.YES && (
                    <>
                      <FormControl
                        name={`employment[${idx}.collaborationFirstName`}
                        component={TextField}
                        label="Physician First Name"
                      />
                      <FormControl
                        name={`employment[${idx}.collaborationLastName`}
                        component={TextField}
                        label="Physician Last Name"
                      />
                      <FormControl
                        component={Select}
                        name={`employment[${idx}].collaborationDegree`}
                        label="Physician Degree"
                        selectionMode="single"
                        menuWidth="stretch"
                      >
                        {physicianDegreeOptions.map((degree) => (
                          <Item key={degree}>{degree}</Item>
                        ))}
                      </FormControl>
                    </>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Employment Information',
  description:
    'In this section we ask that you share details about your employment background. Please include all employment for the last 5 years.',
  Component: EmploymentInformation,
  getFormMeta: ({ provider, providerQuestionnaire: { rawData } }) => {
    const validationSchema = Yup.object().shape({
      resumeUrl: isProviderInAnyStates(provider, statesThatRequireResume)
        ? Yup.array()
            .of(
              Yup.object().shape({
                link: Yup.string(),
                name: Yup.string(),
              })
            )
            .required('A copy of your resume is required.')
        : Yup.array(),

      employment: Yup.array().of(
        Yup.object().shape({
          location: Yup.string().required('This question is required.'),
          employer: Yup.string().required('Employer is required.'),
          street1: Yup.string().required('Address is required.'),
          street2: Yup.string(),
          city: Yup.string().required('City is required.'),
          state: Yup.string().when(
            ['location', 'country'],
            (location: string, country: string, schema: Yup.StringSchema) =>
              isNotCanadianAddress(location, country)
                ? schema.required('State is required.')
                : schema
          ),
          zip: Yup.string().when(
            ['location', 'country'],
            (location: string, country: string, schema: Yup.StringSchema) =>
              isNotCanadianAddress(location, country)
                ? schema.required('ZIP code is required.')
                : schema
          ),
          country: Yup.string(),
          startDate: Yup.date().required('Start date is required.'),
          currentlyEmployedHere: Yup.string().required(
            'This question is required.'
          ),
          endDate: Yup.date().when(
            'currentlyEmployedHere',
            (currentlyEmployedHere: string, schema: Yup.StringSchema) =>
              currentlyEmployedHere === YesNo.NO
                ? schema
                    .required('End date is required.')
                    .min(
                      Yup.ref('startDate'),
                      'End date must be later than start date.'
                    )
                : schema
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
            // https://github.com/jquense/yup/issues/764
          ),
          reasonForLeaving: Yup.string().when(
            'currentlyEmployedHere',
            (currentlyEmployedHere: string, schema: Yup.StringSchema) =>
              currentlyEmployedHere === YesNo.NO
                ? schema.required('This question is required.')
                : schema
          ),
          workHistoryCoding: isProviderInAnyStates(
            provider,
            statesThatRequireWorkHistoryCoding
          )
            ? Yup.string().required('This question is required.')
            : Yup.string(),
          email: isProviderInAnyStates(provider, statesThatRequireEmployerInfo)
            ? Yup.string().required('This question is required.')
            : Yup.string(),
          preferredMethodOfContact: isProviderInAnyStates(
            provider,
            statesThatRequireEmployerInfo
          )
            ? Yup.string().required('This question is required.')
            : Yup.string(),
          hasCollaborationAgreement: isProviderInAnyStates(
            provider,
            statesThatRequireEmployerInfo
          )
            ? Yup.string().nullable().required('This question is required.')
            : Yup.string().nullable(),
          collaborationFirstName: Yup.string()
            .nullable()
            .when(
              'hasCollaborationAgreement',
              (hasCollaborationAgreement: string, schema: Yup.StringSchema) =>
                isProviderInAnyStates(
                  provider,
                  statesThatRequireEmployerInfo
                ) && hasCollaborationAgreement === YesNo.YES
                  ? schema.required('This question is required.')
                  : schema
            ),
          collaborationLastName: Yup.string()
            .nullable()
            .when(
              'hasCollaborationAgreement',
              (hasCollaborationAgreement: string, schema: Yup.StringSchema) =>
                isProviderInAnyStates(
                  provider,
                  statesThatRequireEmployerInfo
                ) && hasCollaborationAgreement === YesNo.YES
                  ? schema.required('This question is required.')
                  : schema
            ),
          collaborationDegree: Yup.string()
            .nullable()
            .when(
              'hasCollaborationAgreement',
              (hasCollaborationAgreement: string, schema: Yup.StringSchema) =>
                isProviderInAnyStates(
                  provider,
                  statesThatRequireEmployerInfo
                ) && hasCollaborationAgreement === YesNo.YES
                  ? schema.required('This question is required.')
                  : schema
            ),
        })
      ),
      hasEmploymentGaps: Yup.boolean(),
      employmentGaps: Yup.array().of(
        Yup.object().shape({
          startDate: Yup.string(),
          endDate: Yup.string(),
          explanation: Yup.string().required('This field is required.'),
        })
      ),
      belongsToProfessionalSocieties: Yup.string().required(
        'This field is required'
      ),
      professionalSocieties: Yup.string().when(
        'belongsToProfessionalSocieties',
        (belongsToProfessionalSocieties: string, schema: Yup.StringSchema) =>
          belongsToProfessionalSocieties === YesNo.YES
            ? Yup.string().required('This question is required')
            : schema
      ),
      voluntaryOrInvoluntaryTermination: Yup.string().required(
        'This field is required'
      ),
    });
    return {
      validationSchema: validationSchema,
      initialValue: {
        ...yupSchemaToDefaultValue(validationSchema),
        ...rawData,
      },
    } as FormMeta;
  },
};

export default stepConfig;
