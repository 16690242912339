/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PROMISSubmission {
    PROMIS_1: number;

    PROMIS_2: number;

    PROMIS_3: number;

    PROMIS_4: number;

    assessmentType?: PROMISSubmissionAssessmentType;

}


    export enum PROMISSubmissionAssessmentType {
            PROMIS = 'PROMIS'
    }

