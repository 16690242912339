import { useProviderDocumentRemediations } from '~/legacy/hooks/useProviderDocumentRemediations';

import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProviderDocumentRemediationWithAuditResults } from '@headway/api/models/ProviderDocumentRemediationWithAuditResults';
import { UseQueryResult } from '@headway/shared/react-query';

import { Rule } from '../Rule';
import { DocumentationRemediationSessionDetailsBanner } from './DocumentationRemediationSessionDetailsBanner';

export class DocumentationRemediation extends Rule<
  [UseQueryResult<ProviderDocumentRemediationWithAuditResults>]
> {
  enabled = [true];

  addDataHooks() {
    return [
      () =>
        useProviderDocumentRemediations(
          {
            providerAppointmentId: this.event?.providerAppointment?.id,
          },
          {
            select: ([firstItem]) => firstItem ?? undefined,
          }
        ),
    ];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData || !hookData[0]) {
      return false;
    }

    if (hookData[0].status !== 'success') {
      return false;
    }

    const documentRemediation = hookData[0].data;

    return (
      documentRemediation?.status === DocumentRemediationStatus.NEEDS_REVIEW
    );
  }

  get disabledProgressNoteTypes() {
    return [];
  }

  get banners() {
    return {
      sessionDetails: {
        header: <DocumentationRemediationSessionDetailsBanner />,
      },
    };
  }

  isDocumentationRequired(): boolean {
    return true;
  }

  isTreatmentPlanRequired(): boolean {
    return false;
  }

  isInRemediationFlow(): boolean {
    return true;
  }
}
