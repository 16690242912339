import { Formik } from 'formik';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { MarketRead } from '@headway/api/models/MarketRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { useMarkets } from '@headway/ui/providers/MarketProvider';

import {
  postReferProviderEvent,
  postShareEvent,
  trackCTAEvents,
} from '../hooks/extole';

const licenseType = [
  'Clinical Psychologist',
  'Group Admin',
  'Group Clinical',
  'Group Director',
  'Group practice admin',
  'LCAS',
  'LCAT',
  'LCSW',
  'Limited Permit-MHC',
  'LMFT',
  'LMHC',
  'LMSW',
  'LPC',
  'MD',
  'NP',
  'Psychiatric NP',
  'Psychiatrist',
  'Psychologist',
];

const formSchema = Yup.object({
  referralFirstName: Yup.string().required('Required'),
  referralLastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phoneNumber: Yup.string().required('Required'),
  licenseType: Yup.string().required('Required'),
  licenseState: Yup.string().required('Required'),
});

const ReferralSideSheet = ({
  advocateCode,
  accessToken,
  campaignId,
}: {
  advocateCode: string;
  accessToken: string;
  campaignId: string;
}) => {
  if (!advocateCode || !accessToken) {
    return null;
  }

  const { liveMarkets } = useMarkets();
  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = async ({
    referralFirstName,
    referralLastName,
    email,
    phoneNumber,
    licenseType,
    licenseState,
  }: {
    referralFirstName: string;
    referralLastName: string;
    email: string;
    phoneNumber: string;
    licenseType: string;
    licenseState: string;
  }) => {
    await Promise.all([
      postShareEvent({
        token: accessToken,
        advocateCode,
        email,
      }),
      postReferProviderEvent({
        token: accessToken,
        advocateCode,
        email,
        firstName: referralFirstName,
        lastName: referralLastName,
        phoneNumber,
        licenseType,
        licenseState,
        campaignId,
      }),
    ]);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        variant="primary"
        size="large"
        onPress={() => {
          setIsOpen(true);
          trackCTAEvents({
            eventName: 'lead_form_clicked',
            token: accessToken,
          });
        }}
      >
        Invite a provider directly
      </Button>
      <Modal
        variant="sidesheet"
        title="Invite a provider"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      >
        <Formik
          validationSchema={formSchema}
          initialValues={{
            referralFirstName: '',
            referralLastName: '',
            email: '',
            phoneNumber: '',
            licenseType: '',
            licenseState: '',
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <>
              <ModalContent>
                <Form id="Referral-intake">
                  <BodyText>
                    One of our practice consultants will email your referral to
                    schedule an intro meeting.
                  </BodyText>
                  <div className="flex flex-col gap-4">
                    <FormControl
                      key="referralFirstName"
                      component={TextField}
                      name="referralFirstName"
                      label="Referral first name"
                    />
                    <FormControl
                      key="referralLastName"
                      component={TextField}
                      name="referralLastName"
                      label="Referral last name"
                    />
                    <FormControl
                      key="email"
                      component={TextField}
                      name="email"
                      label="Email address"
                    />
                    <FormControl
                      key="phoneNumber"
                      component={PhoneNumberField}
                      name="phoneNumber"
                      label="Phone number"
                    />
                    <Select
                      key="licenseType"
                      name="licenseType"
                      label="License type"
                      selectionMode="single"
                      placeholder="Select license type"
                      items={licenseType.map((type: string) => ({
                        key: type,
                        label: type,
                      }))}
                      onSelectionChange={(keys: Set<string>) => {
                        const keyArray = Array.from(keys);
                        const licenseType = keyArray[0] ?? '';
                        setFieldValue('licenseType', licenseType);
                      }}
                    >
                      {(item: any) => <Item key={item.key}>{item.label}</Item>}
                    </Select>
                    <Select
                      key="licenseState"
                      name="licenseState"
                      label="State of license"
                      selectionMode="single"
                      placeholder="Select state of license"
                      items={sortBy(liveMarkets, 'displayName').map(
                        (market: MarketRead) => ({
                          key: market.abbreviation,
                          label: market.displayName,
                        })
                      )}
                      onSelectionChange={(keys: Set<string>) => {
                        const keyArray = Array.from(keys);
                        const licenseState = keyArray[0] ?? '';
                        setFieldValue('licenseState', licenseState);
                      }}
                    >
                      {(item: any) => <Item key={item.key}>{item.label}</Item>}
                    </Select>
                  </div>
                </Form>
              </ModalContent>
              <ModalFooter>
                <Button
                  form="Referral-intake"
                  type="submit"
                  variant="primary"
                  size="large"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ReferralSideSheet;
