import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderType } from '@headway/api/models/ProviderType';
import { SupervisingPhysicianStatus } from '@headway/api/models/SupervisingPhysicianStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';

import { NpiError } from '../../utils/npi';
import {
  NP_PRACTICE_AUTHORITY_BY_STATE,
  RestrictedPracticeAuthority,
} from '../../utils/supervisingPhysicianConfig';

export enum IDCertificationType {
  CDS = 'CDS',
  DEA = 'DEA',
}

export type NpiLookupResult = {
  npi: string;
  error?: NpiError;
  firstName?: string;
  lastName?: string;
  credential?: string;
};

export enum SupervisingPhysicianStatusOption {
  YES = SupervisingPhysicianStatus.YES,
  NO = SupervisingPhysicianStatus.NO,
  EXEMPT = SupervisingPhysicianStatus.EXEMPT,
  REUSE_EXISTING = 'REUSE_EXISTING',
}

export type ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep =
  ProviderQuestionnaireRawData & {
    // we don't want to store this in PQ raw data
    userInputtedSupervisingPhysicians?: {
      [key: string]: {
        hasSupervisingPhysician: string;
        npiOfSupervisingPhysician: string;
        nameOfSupervisingPhysician: string;
        emailOfSupervisingPhysician: string;
      };
    };
  };

export const getSupervisingPhysicianRequirement = (
  state: UnitedStates,
  provider: ProviderRead,
  enableSupervisingPhysicians?: boolean // from feature flag
): RestrictedPracticeAuthority => {
  if (
    enableSupervisingPhysicians &&
    provider.providerType === ProviderType.NURSE_PRACTITIONER
  ) {
    return NP_PRACTICE_AUTHORITY_BY_STATE[state];
  }
  // for non-NP providers, we don't need to collect supervising physician info
  return RestrictedPracticeAuthority.FULL;
};

export const removeSupervisingPhysicianValuesFromRawData = (
  formValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep
) => {
  const newFormValues = { ...formValues };
  delete newFormValues.userInputtedSupervisingPhysicians;
  return newFormValues;
};
