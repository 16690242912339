import React from 'react';
import { mergeProps, useSeparator, VisuallyHidden } from 'react-aria';
import {
  Button as RAButton, // @ts-expect-error
  useSSRCollectionNode,
} from 'react-aria-components';
import 'react-stately';

import { IconCaretDown } from '../icons/CaretDown';
import { IconX } from '../icons/X';

interface FilterGroupContextValue {
  variant: FilterGroupProps['variant'];
}

const FilterGroupContext = React.createContext<
  FilterGroupContextValue | undefined
>(undefined);

interface FilterGroupProps {
  /**
   * How the filter group should be displayed.
   * - `exposed`: All filter buttons are displayed.
   * - `hybrid`: Some filter buttons are displayed, and the rest are hidden behind an AllFilterButton button. More
   *  filter buttons are displayed as the screen size increases.
   * - `condensed`: All filter buttons are hidden behind an AllFilterButton.
   */
  variant: 'exposed' | 'hybrid' | 'condensed';
  children: React.ReactNode;
}

// todo: filter items should responsively hide/show based on available space
// when variant is hybrid. The component can accept an optional priority prop
// that determines the order in which filter items are revealed.
export function FilterGroup({ children, variant }: FilterGroupProps) {
  return (
    <FilterGroupContext.Provider value={{ variant }}>
      <div className="hlx-filter-group" data-variant={variant}>
        {children}
      </div>
    </FilterGroupContext.Provider>
  );
}

interface FilterButtonProps {
  size?: 'medium' | 'large';
  children?: React.ReactNode;
  onClear: () => void;
  isSelected: boolean;
  ['aria-label']: string;
}
function _FilterButton(
  {
    children,
    onClear,
    isSelected,
    'aria-label': ariaLabel,
    size = 'medium',
    ...props
  }: FilterButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <div className="hlx-filter-button-root">
      <RAButton
        ref={ref}
        className="hlx-filter-button"
        data-hlx-variant="secondary"
        data-hlx-size={size}
        data-selected={isSelected || undefined}
        {...mergeProps(props, {
          onKeyDown: (e: React.KeyboardEvent) => {
            if (e.key === 'Backspace' || e.key === 'Delete') {
              onClear();
            }
          },
        })}
        aria-label={ariaLabel}
      >
        {children}
        {!isSelected && <IconCaretDown width={22} height={22} />}
      </RAButton>
      {isSelected && (
        <button
          className="hlx-filter-button-clear"
          aria-label="Clear"
          onClick={onClear}
          tabIndex={-1}
        >
          <IconX width={16} height={16} />
        </button>
      )}
    </div>
  );
}

export const FilterButton = React.forwardRef(_FilterButton);

interface FilterButtonCountProps<T> {
  selection: Set<T>;
}

export function FilterButtonCount<T>({ selection }: FilterButtonCountProps<T>) {
  if (selection.size < 2) {
    return null;
  }

  return <span>+{selection.size - 1}</span>;
}

interface AllFilterButtonProps {
  onPress: () => void;
  selections: any[];
  children: React.ReactNode;
  size?: 'medium' | 'large';
}

export function AllFilterButton({
  onPress,
  selections,
  children,
  size = 'medium',
}: AllFilterButtonProps) {
  const count = selections.reduce((acc, selection) => {
    if (!selection) {
      return acc;
    }

    if (Array.isArray(selection) || selection instanceof Set) {
      const size = new Set(selection).size;
      return acc + size;
    }

    return acc + 1;
  }, 0);
  const groupContext = React.useContext(FilterGroupContext);

  return (
    <>
      {groupContext?.variant === 'hybrid' && <Separator />}
      <RAButton
        className="hlx-filter-button hlx-filter-button-all"
        data-hlx-variant="secondary"
        data-hlx-size={size}
        data-selected={count > 0 || undefined}
        onPress={onPress}
      >
        <span className="hlx-filter-button-content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
            style={{
              display: 'inline-block',
            }}
          >
            <path
              fill="#353535"
              stroke="#353535"
              strokeWidth="0.25"
              d="M9.875 18v.125h4.25v-1.75h-4.25V18zm-4-5.25v.125h12.25v-1.75H5.875v1.625zm-3-5.25v.125h18.25v-1.75H2.875V7.5z"
            ></path>
          </svg>

          {children}

          {count > 0 && (
            <span className="hlx-filter-button-count">
              {count}
              <VisuallyHidden> selected</VisuallyHidden>
            </span>
          )}
        </span>
      </RAButton>
    </>
  );
}

function Separator() {
  let { separatorProps } = useSeparator({
    orientation: 'vertical',
  });

  return <div {...separatorProps} className="hlx-filter-separator" />;
}

type forwardRefType = typeof React.forwardRef;

function FilterItem(
  props: any,
  ref: React.ForwardedRef<HTMLLIElement>
): JSX.Element | null {
  return useSSRCollectionNode('item', props, ref, props.children);
}

const _FilterItem = (React.forwardRef as forwardRefType)(FilterItem);
export { _FilterItem as FilterItem };
