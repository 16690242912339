import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { Rule } from '../Rule';

export class DocumentRequirementsPAPilot extends Rule<[boolean]> {
  enabled = [true];

  addDataHooks() {
    return [() => useFlag('documentationRequirementPilot', false)];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData) {
      return false;
    }
    return hookData[0];
  }

  get disabledProgressNoteTypes() {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    return {
      progressNote: undefined,
    };
  }

  isDocumentationRequired(): boolean {
    return true;
  }
  isTreatmentPlanRequired(): boolean {
    return false;
  }
}
