import {
  CalendarDate,
  getLocalTimeZone,
  toZoned,
} from '@internationalized/date';
import { useNavigate } from '@remix-run/react';
import { Formik } from 'formik';
import React from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { Modal } from '@headway/helix/Modal';
import { useQueryClient } from '@headway/shared/react-query';

import { isGroupPracticeSessionsByProviderQueryKey } from 'hooks/useGroupPracticeSessionsByProvider';
import { isUnconfirmedAppointmentCountsForGroupPracticeQueryKey } from 'hooks/useUnconfirmedAppointmentCountsForGroupPractice';
import { useCreateProviderEventMutation } from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';

import {
  BillNewSessionsFormValues,
  BillNewSessionsSidesheetForm,
  BillNewSessionValidationSchema,
} from './BillNewSessionsSidesheetForm';

interface BillNewSessionsSidesheetProps {
  onDismiss: () => void;
  preSelectedProviderId?: number;
  sessionDate: CalendarDate;
}

export const BillNewSessionsSidesheet = ({
  onDismiss,
  preSelectedProviderId,
  sessionDate,
}: BillNewSessionsSidesheetProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createEventMutation = useCreateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      ProviderEventCreate
    >().add({
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            isGroupPracticeSessionsByProviderQueryKey(query.queryKey) ||
            isUnconfirmedAppointmentCountsForGroupPracticeQueryKey(
              query.queryKey
            ),
        });
      },
    }),
  });

  const onSubmit = async (values: BillNewSessionsFormValues) => {
    const zonedDateTime = toZoned(values.sessionDate, getLocalTimeZone());

    const createEventPromises = values.patientIds.map((patientId) => {
      return createEventMutation.mutateAsync({
        type: ProviderEventType.APPOINTMENT,
        providerId: values.providerId,
        patientUserId: patientId,
        startDate: zonedDateTime.toAbsoluteString(),
        endDate: zonedDateTime.add({ minutes: 45 }).toAbsoluteString(),
        providerAppointment: {
          status: ProviderAppointmentStatus.SCHEDULED,
          providerId: values.providerId,
          userId: patientId,
        },
      } as ProviderEventCreate);
    });

    const createdEvents = await Promise.all(createEventPromises);
    const createdEventVirtualIds = createdEvents.map(
      (event) => event.virtualId
    );
    // TODO(justin): dedup this method
    const params = new URLSearchParams(
      createdEventVirtualIds.map((id) => ['event', id])
    );
    navigate(`/practice/billing/confirm?${params.toString()}`);
    onDismiss();
  };
  return (
    <Modal
      variant="sidesheet"
      isOpen
      onDismiss={onDismiss}
      title="Add sessions"
    >
      <Formik
        initialValues={{
          sessionDate: sessionDate,
          providerId: preSelectedProviderId,
          patientIds: [],
        }}
        validationSchema={BillNewSessionValidationSchema}
        onSubmit={onSubmit}
      >
        <BillNewSessionsSidesheetForm
          onDismiss={onDismiss}
          isProviderPreselected={!!preSelectedProviderId}
        />
      </Formik>
    </Modal>
  );
};
