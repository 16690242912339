import { CommunicationType } from '@headway/api/models/CommunicationType';
import { Banner } from '@headway/helix/Banner';
import { Link } from '@headway/helix/Link';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';

import { useDocumentationRequest } from '../../hooks/utils/useDocumentationRequest';

export const DocumentationRequestProgressNoteBanner = () => {
  const { carriersById } = useFrontEndCarriers();
  const documentRequest = useDocumentationRequest();

  const requiringCarrier = documentRequest?.frontEndCarrierId
    ? carriersById[documentRequest.frontEndCarrierId]
    : undefined;

  return documentRequest?.communicationType !==
    CommunicationType.FRIENDLY_MESSAGING ? (
    <Banner variant="warning">
      Your complete progress notes are required{' '}
      {requiringCarrier ? `by ${requiringCarrier.name}` : ''} to receive
      payment. Please upload or submit notes using one of our templates. Learn
      more about{' '}
      <Link
        href="https://findheadway.zendesk.com/hc/en-us/articles/13042026808212-Keeping-Progress-Notes-on-Headway"
        target="_blank"
        rel="noreferrer"
      >
        best practices
      </Link>
      .
    </Banner>
  ) : null;
};
