import {
  BusinessTwoTone,
  CloseTwoTone,
  MessageTwoTone,
  PersonOutlineTwoTone,
  PhoneTwoTone,
  SupervisorAccountTwoTone,
} from '@mui/icons-material';
import { ButtonProps, Chip, IconButton } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { LinkButton } from '@headway/helix/LinkButton';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Button, MessageBubble, VisuallyHidden } from '@headway/ui';
import { theme as legacyTheme } from '@headway/ui/theme';

import {
  isAppointment,
  isAutobooked,
  isHealthcareReferral,
} from '../events/util/events';
import { PatientBookedMessage } from '../utils/Calendar';
import { DetailPopover, DetailPopoverProps } from './DetailPopover';
import { EventDetail } from './EventDetail';

type PatientBookedEventDetailProps = ButtonProps & {
  event: ProviderEventRead;
  patient: UserRead;
  provider: ProviderRead;
  message?: PatientBookedMessage;
  lastSeenDate?: Date;
  handleCancelClick: (event: ProviderEventRead) => void;
  handleIntakeCallClick: (event: ProviderEventRead) => void;
};

const PatientBookedEventDetail: React.FC<
  React.PropsWithChildren<PatientBookedEventDetailProps>
> = ({
  event,
  patient,
  provider,
  message,
  lastSeenDate,
  handleIntakeCallClick,
  handleCancelClick,
}) => {
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  return (
    <div
      css={{
        borderTop: `1px solid ${legacyTheme.color.border}`,
        padding: legacyTheme.space.base,
      }}
    >
      <div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin: `0 0 ${legacyTheme.space.xs2} 0`,
          }}
        >
          <h4
            css={{
              fontFamily: legacyTheme.fontFamily.brandText,
              color: legacyTheme.color.primary,
              fontSize: legacyTheme.fontSize.lg,
              margin: `0 ${legacyTheme.space.xs} 0 0`,
            }}
          >
            {formatPatientName(patient)}
          </h4>
          <div css={{ fontSize: legacyTheme.fontSize.sm }}>
            {lastSeenDate &&
            moment(event.createdOn).isBefore(moment(lastSeenDate)) ? null : (
              <Chip
                label="New"
                size="small"
                css={{
                  color: legacyTheme.color.white,
                  background: legacyTheme.color.error,
                }}
              />
            )}
          </div>
        </div>

        <div>
          <div
            css={{
              fontFamily: legacyTheme.fontFamily.postGrotesk,
            }}
          >
            {moment(event.startDate).format('dddd, MMMM Do [at] h:mma')}
          </div>
          <div
            css={{
              fontFamily: legacyTheme.fontFamily.postGrotesk,

              marginBottom: legacyTheme.space.base,
            }}
          >
            Booked {moment(event.createdOn).from(moment())}
          </div>
          <EventDetail
            title={isAppointment(event) ? 'Appointment' : 'Phone consultation'}
            label={isAppointment(event) ? 'Appointment' : 'Phone consultation'}
            icon={isAppointment(event) ? BusinessTwoTone : PhoneTwoTone}
          />
          {(patient.dob || patient.gender) && (
            <EventDetail
              title="Client info"
              icon={PersonOutlineTwoTone}
              label={
                patient.dob
                  ? `${moment().diff(moment(patient.dob), 'years')} year${
                      !patient.gender ? 's' : ''
                    } old ${patient.gender ? patient.gender : ''}`
                  : patient.gender
                  ? patient.gender
                  : 'Unknown'
              }
            />
          )}
          {message?.content && (
            <EventDetail
              title={`Message from ${patientFirstName}`}
              label={
                <MessageBubble position="left">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(message.content),
                    }}
                  />
                </MessageBubble>
              }
              icon={MessageTwoTone}
            />
          )}
          {(isAutobooked(event) || isHealthcareReferral(event)) && (
            <EventDetail
              title="Referral source"
              icon={SupervisorAccountTwoTone}
              label={`${patientFirstName} was referred by their healthcare provider. Please check the client's profile for physician notes.`}
            />
          )}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: legacyTheme.space.base,
        }}
      >
        <Link to={`/messages?patient=${patient.id}`}>
          <Button
            color="gray"
            variant="outlined"
            css={{
              marginRight: legacyTheme.space.xs,
              marginBottom: legacyTheme.space.xs,
            }}
          >
            Message {patientFirstName} →
          </Button>
        </Link>
        {isAppointment(event) && (
          <Button
            color="gray"
            css={{
              marginRight: legacyTheme.space.xs,
              marginBottom: legacyTheme.space.xs,
            }}
            variant="outlined"
            onClick={() => handleIntakeCallClick(event)}
          >
            Change to phone consultation →
          </Button>
        )}
        <Button
          color="gray"
          onClick={() => handleCancelClick(event)}
          variant="outlined"
        >
          Cancel {isAppointment(event) ? 'session' : 'call'} →
        </Button>
      </div>
    </div>
  );
};

export type BookedEvent = {
  event: ProviderEventRead;
  patient: UserRead;
  message?: PatientBookedMessage;
};

export type PatientBookedEventPopoverProps = DetailPopoverProps & {
  provider: ProviderRead;
  bookedEvents: BookedEvent[];
  lastSeenDate: PatientBookedEventDetailProps['lastSeenDate'];
  handleCancelClick: PatientBookedEventDetailProps['handleCancelClick'];
  handleIntakeCallClick: PatientBookedEventDetailProps['handleIntakeCallClick'];
};

export const PatientBookedEventPopover: React.FC<
  React.PropsWithChildren<PatientBookedEventPopoverProps>
> = ({
  open,
  onClose,
  anchorEl,
  bookedEvents,
  provider,
  lastSeenDate,
  handleIntakeCallClick,
  handleCancelClick,
}) => {
  return (
    <DetailPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      PaperProps={{
        style: {
          minWidth: 382,
          maxWidth: 550,
        },
      }}
    >
      <div
        css={{
          padding: legacyTheme.space.base,
          borderBottom: `1px solid ${legacyTheme.color.border}`,
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: legacyTheme.fontFamily.postGrotesk,
          }}
        >
          <h4 css={{ margin: 0 }}>Referrals</h4>
          <IconButton
            onClick={onClose as any}
            css={{
              margin: `-${legacyTheme.space.xs}`,
            }}
            size="large"
          >
            <CloseTwoTone
              css={{
                width: 24,
                height: 24,
              }}
            />
            <VisuallyHidden>Close</VisuallyHidden>
          </IconButton>
        </div>
      </div>
      <div
        css={{
          marginTop: -1,
          [legacyTheme.media.medium]: { maxHeight: 600, overflowY: 'scroll' },
        }}
      >
        {bookedEvents.length ? (
          bookedEvents.map((bookedEvent) => {
            return (
              <PatientBookedEventDetail
                provider={provider}
                key={bookedEvent.event.id}
                event={bookedEvent.event}
                patient={bookedEvent.patient}
                message={bookedEvent.message}
                lastSeenDate={lastSeenDate}
                handleIntakeCallClick={handleIntakeCallClick}
                handleCancelClick={handleCancelClick}
              />
            );
          })
        ) : (
          <div className="flex flex-col items-center p-6">
            <div className="mb-4 max-w-[300px] text-center">
              <BodyText>
                You don't have any referrals right now. Try adjusting your
                working hours to create more openings.
              </BodyText>
            </div>
            <LinkButton
              variant="primary"
              component={Link}
              elementType="a"
              to="/settings/calendar"
            >
              Configure working hours
            </LinkButton>
          </div>
        )}
      </div>
    </DetailPopover>
  );
};
