// @ts-nocheck
// @ts-ignore
import { AriaListBoxOptions, getItemId, listData } from '@react-aria/listbox';
import { announce } from '@react-aria/live-announcer';
import { useMenuTrigger } from '@react-aria/menu';
import {
  ariaHideOutside,
  OverlayContainer,
  useOverlayPosition,
} from '@react-aria/overlays';
import {
  ListKeyboardDelegate,
  useSelectableCollection,
} from '@react-aria/selection';
import {
  chain,
  isAppleDevice,
  useLabels,
  useLayoutEffect,
  useResizeObserver,
  useSlotId,
} from '@react-aria/utils';
import { getItemCount, Item, Section } from '@react-stately/collections';
import { ListCollection } from '@react-stately/list';
import { useMenuTriggerState } from '@react-stately/menu';
import { useControlledState } from '@react-stately/utils';
import { AriaButtonProps } from '@react-types/button';
import { MenuTriggerAction } from '@react-types/combobox';
import {
  Collection,
  CollectionBase,
  DOMAttributes,
  DOMProps,
  FocusableProps,
  FocusStrategy,
  HelpTextProps,
  InputBase,
  KeyboardDelegate,
  LabelableProps,
  MultipleSelection,
  Node,
  PressEvent,
  TextInputBase,
  Validation,
} from '@react-types/shared';
import isEqual from 'lodash/isEqual';
import React, {
  FocusEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  RefObject,
  TouchEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FocusRing,
  mergeProps,
  useButton,
  useFilter,
  useHover,
  useLocalizedStringFormatter,
  useTextField,
  VisuallyHidden,
} from 'react-aria';
import { MenuTriggerState, OverlayTriggerProps } from 'react-stately';
import { Simplify } from 'type-fest';

import { Chip, ChipGroup } from './Chip';
import { ListBox } from './collections/ListBox';
import { MobilePickerDialog } from './collections/MobilePickerDialog';
import { Popover, POPOVER_MAX_HEIGHT } from './collections/Popover';
import {
  MultiSelectListState,
  useMultiSelectListState,
} from './collections/selection';
import { Tray } from './collections/Tray';
import { DATA } from './consts';
import { IconCaretDown } from './icons/CaretDown';
import { IconXCircle } from './icons/XCircle';
import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { FormInputProps } from './useFormInput';
import { useIsMobileDevice } from './utils';

type ComboBoxProps<T> = Simplify<
  {
    /** Temporary text that occupies the text input when it is empty. */
    placeholder?: string;
    /** Width of the menu. */
    menuWidth?: 'small' | 'medium' | 'stretch';

    optionalityText?: React.ReactNode;
    /**
     * Text displayed beneath the input label to provider instructions on how the input should be
     * filled out. This differs from `helpText` in that it is meant to be read by the user prior to
     * filling out the input and should provide precise and actionable instructions. Conversely,
     * `helpText` is meant to be read after the input has been filled out and should provide
     * hints and tips on how to fill out the input.
     *
     * Additionally, `instructionalText` should only be used on block level inputs (i.e. inputs that
     * span the full width of the form) and should not be used on inline inputs (i.e. inputs that
     * are displayed alongside other inputs).
     */
    instructionalText?: React.ReactNode;

    /** Handler that is called when the selection changes. */
    onSelectionChange?: (keys: Set<string>) => void;

    /** The current value of the combo box input. */
    inputValue?: string;

    /** Handler that is called when the selection changes. */
    onInputChange?: (value: string) => void;

    /** The currently selected keys in the collection (controlled). */
    selectedKeys?: ComboBoxMultiSelectProps<T>['selectedKeys'];

    /** Whether to have the comboBox menu open.*/
    isOpen?: boolean;

    /**
     * The interaction required to display the ComboBox menu.
     * @default 'focus'
     */
    menuTrigger?: ComboBoxMultiSelectProps<T>['menuTrigger'];
    /** The initial selected keys in the collection (uncontrolled). */
    defaultSelectedKeys?: ComboBoxMultiSelectProps<T>['defaultSelectedKeys'];
    disabledKeys?: ComboBoxMultiSelectProps<T>['disabledKeys'];
    items?: ComboBoxMultiSelectProps<T>['items'];
    children?: ComboBoxMultiSelectProps<T>['children'];

    /** The type of selection that is allowed in the collection. */
    selectionMode: 'single' | 'multiple';

    /**
     * Whether to display a search field in the menu on mobile. This is useful when the list of options is
     * very long and the user needs to be able to filter the options. On desktop devices, the list of options
     * is searchable via keyboard input but does not show a search field.
     **/
    searchable?: boolean;
  } & FormInputProps<string>
>;

function ComboBox<T extends object>({
  menuTrigger = 'focus',
  ...props
}: ComboBoxProps<T>) {
  let { contains } = useFilter({ sensitivity: 'base' });

  let isMobileEnabled = true;
  let isMobileDevice = useIsMobileDevice();
  const isMobile = isMobileEnabled && isMobileDevice;

  let state = useComboBoxMultiSelectState({
    ...props,
    menuTrigger: isMobile ? 'input' : menuTrigger,
    isDisabled: props.disabled,
    defaultFilter: contains,
    allowsEmptyCollection: true,
    shouldCloseOnBlur: !isMobile,
  });

  const rootRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);
  const roamingInputPlaceholderRef = useRef<HTMLDivElement>(null);

  let {
    buttonProps,
    inputProps,
    listBoxProps,
    labelProps,
    descriptionProps,
    errorMessageProps,
  } = useComboBoxMultiSelect(
    {
      ...props,
      isDisabled: props.disabled,
      menuTrigger,
      disallowEmptySelection: false,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  useAssertFormParentEffect(inputRef, 'ComboBox', props.name);

  let { hoverProps, isHovered } = useHover({ isDisabled: props.disabled });

  const {
    overlayProps,
    // TODO: Do we want to support consumers controlling the placement of the popover?
    placement,
    updatePosition,
  } = useOverlayPosition({
    targetRef: buttonRef,
    overlayRef: popoverRef,
    scrollRef: listBoxRef,
    // TODO: Support more placements if we run into cases during migration
    placement: 'bottom right',
    offset: 4,
    shouldFlip: true,
    isOpen: state.isOpen && !isMobile,
    onClose: state.close,
    maxHeight: POPOVER_MAX_HEIGHT,
  });

  // Update position once the ListBox has rendered. This ensures that
  // it flips properly when it doesn't fit in the available space.
  useLayoutEffect(() => {
    if (state.isOpen) {
      requestAnimationFrame(() => {
        updatePosition();
      });
    }
  }, [state.isOpen, updatePosition]);

  let [menuWidth, setMenuWidth] = React.useState(0);

  let onResizeRoot = React.useCallback(() => {
    const root = rootRef.current;

    if (root) {
      setMenuWidth(root.offsetWidth);
    }

    if (state.isOpen) {
      requestAnimationFrame(() => {
        updatePosition();
      });
    }
  }, [state.isOpen, updatePosition]);

  useResizeObserver({
    ref: rootRef,
    onResize: onResizeRoot,
  });

  const [inputPosition, setInputPosition] = React.useState<
    [
      top: React.CSSProperties['top'],
      left: React.CSSProperties['left'],
      width: React.CSSProperties['width'],
    ]
  >([0, 0, '100%']);
  const onResizePlaceholder = React.useCallback(() => {
    if (props.selectionMode === 'single') {
      return;
    }

    const input = inputRef.current;
    const placeholder = roamingInputPlaceholderRef.current;

    if (!(input && placeholder)) {
      return;
    }

    // TODO: Replace this with CSS settings for font-display?
    const waitForFontsThenPosition = async () => {
      await document.fonts.ready;

      const top = placeholder.offsetTop + 'px';
      const left = placeholder.offsetLeft + 'px';
      const width = placeholder.getBoundingClientRect().width + 'px';

      setInputPosition([top, left, width]);
    };

    waitForFontsThenPosition();
  }, []);

  React.useLayoutEffect(() => {
    onResizePlaceholder();
  }, [props.selectionMode, state.selectedKeys, onResizePlaceholder]);

  let [actionsWidth, setActionsWidth] = React.useState(0);

  let onResizeActions = React.useCallback(() => {
    const el = actionsRef.current;

    if (el) {
      setActionsWidth(el.offsetWidth);
      onResizePlaceholder();
    }
  }, []);

  useResizeObserver({
    ref: actionsRef,
    onResize: onResizeActions,
  });

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);
  const instructionalTextId = useSlotId([Boolean(props.instructionalText)]);
  const chipGroupLabelId = useSlotId();

  const controlProps = mergeProps(inputProps, hoverProps);

  controlProps['aria-describedby'] =
    [instructionalTextId, instructionalTextId, controlProps['aria-describedby']]
      .filter(Boolean)
      .join(' ') || undefined;

  const listbox = (
    <ListBox listBoxRef={listBoxRef} {...listBoxProps} state={state} />
  );

  let overlay;

  if (isMobile) {
    overlay = (
      <OverlayContainer>
        <Tray state={state}>
          <MobilePickerDialog
            state={state}
            title={props.label ?? undefined}
            searchable={props.searchable}
          >
            {listbox}
          </MobilePickerDialog>
        </Tray>
      </OverlayContainer>
    );
  } else {
    overlay = (
      <OverlayContainer>
        {state.isOpen && (
          <Popover
            __style={{
              ...overlayProps.style,
              width: menuWidth,
            }}
            popoverRef={popoverRef}
            isOpen={state.isOpen}
            onClose={state.close}
          >
            {listbox}
          </Popover>
        )}
      </OverlayContainer>
    );
  }

  const placeholder = !state.inputValue ? props.placeholder : undefined;

  return (
    <div
      className="hlx-combobox hlx-combobox-root"
      style={{
        '--combobox-actions-width': actionsWidth,
      }}
      ref={rootRef}
      {...{
        [DATA.DISABLED]: props.disabled,
        [DATA.READONLY]: props.readonly,
        [DATA.VALIDATION]: props.validation?.validity,
        [DATA.HOVERED]: isHovered,
      }}
      data-disabled-state={props.disabled ? 'disabled' : 'enabled'}
    >
      <div className="hlx-combobox-descriptors">
        <label className="hlx-combobox-label" {...labelProps}>
          {props.label}
        </label>
        {props.optionalityText && (
          <div id={optionalityId} className="hlx-combobox-optionality-text">
            {props.optionalityText}
          </div>
        )}
        {props.instructionalText ? (
          <div
            id={instructionalTextId}
            className="hlx-combobox-instructional-text"
          >
            {props.instructionalText}
          </div>
        ) : null}
      </div>
      <FocusRing
        within
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={true}
        autoFocus={props.autoFocus}
      >
        <div className="hlx-combobox-control">
          <input
            hidden={isMobile}
            className={`hlx-combobox-input`}
            ref={inputRef}
            style={
              props.selectionMode === 'multiple' &&
              state.selectedItems?.length > 0
                ? {
                    position: 'absolute',
                    top: inputPosition[0],
                    left: inputPosition[1],
                    width: inputPosition[2],
                  }
                : {}
            }
            {...controlProps}
          />

          {props.selectionMode === 'multiple' && (
            <React.Fragment>
              <VisuallyHidden id={chipGroupLabelId}>
                Selected items
              </VisuallyHidden>
              {state.selectedItems ? (
                <>
                  <ChipGroup
                    aria-labelledby={[chipGroupLabelId, labelProps.id].join(
                      ' '
                    )}
                    disabledKeys={
                      props.disabled
                        ? Array.from(state.collection.getKeys())
                        : props.disabledKeys
                    }
                    // Don't allow selection of chips while menu is open
                    selectionMode={state.isOpen ? 'none' : 'multiple'}
                    onRemove={(removed) => {
                      if (props.disabled) {
                        return;
                      }

                      let keys = [];

                      if (Set.prototype.difference) {
                        keys = state.selectedKeys.difference(removed);
                      } else {
                        keys = [...state.selectedKeys].filter((k) => {
                          return !removed.has(k);
                        });
                      }
                      state.setSelectedKeys(keys);
                    }}
                    items={state.selectedItems}
                    endSlotPlaceholderRef={roamingInputPlaceholderRef}
                  >
                    {(item) => {
                      return (
                        <Chip
                          key={item.key}
                          aria-label={item['aria-label']}
                          textValue={item.textValue}
                        >
                          <span>{item.rendered}</span>
                        </Chip>
                      );
                    }}
                  </ChipGroup>
                </>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </FocusRing>
      {isMobile && (
        <TriggerButton
          className="hlx-combobox-mobile-trigger"
          {...mergeProps(buttonProps, controlProps, {
            autoFocus: props.autoFocus,
          })}
          data-placeholder={!!placeholder}
          ref={buttonRef}
          disabled={props.disabled}
          onPress={() => !props.readonly && state.open(null, 'manual')}
        >
          {!state.inputValue
            ? placeholder
            : props.selectionMode === 'single'
            ? state.inputValue
            : null}
        </TriggerButton>
      )}
      <div ref={actionsRef} className="hlx-combobox-actions">
        {(state.selectedItems ?? []).length > 0 && (
          <TriggerButton
            className="hlx-combobox-trigger"
            {...{
              'aria-label': 'Clear',
              excludeFromTabOrder: true,
              // @ts-ignore
              preventFocusOnPress: true,
              isDisabled: props.disabled,
              onPress: () => {
                state.setSelectedKeys([]);
              },
            }}
          >
            <IconXCircle />
          </TriggerButton>
        )}
        <TriggerButton ref={buttonRef} {...buttonProps}>
          <IconCaretDown aria-hidden={true} size={16} />
        </TriggerButton>
      </div>

      {props.helpText && (
        <div className="hlx-combobox-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-combobox-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}

      {overlay}
    </div>
  );
}

const TriggerButton = React.forwardRef<
  HTMLButtonElement,
  AriaButtonProps<'button'>
>((props, ref) => {
  let { buttonProps } = useButton(
    props,
    ref as React.RefObject<HTMLButtonElement>
  );
  return (
    <button
      type="button"
      className={props.className ?? undefined}
      {...buttonProps}
      ref={ref}
    >
      {props.children}
    </button>
  );
});

export interface ComboBoxMultiSelectState<T>
  extends MultiSelectListState<T>,
    MenuTriggerState {
  /** The current value of the combo box input. */
  inputValue: string;
  /** Sets the value of the combo box input. */
  setInputValue(value: string): void;
  /** Selects the currently focused item and updates the input value. */
  commit(): void;
  /** Opens the menu. */
  open(focusStrategy?: FocusStrategy | null, trigger?: MenuTriggerAction): void;
  /** Toggles the menu. */
  toggle(
    focusStrategy?: FocusStrategy | null,
    trigger?: MenuTriggerAction
  ): void;
  /** Resets the input value to the previously selected item's text if any and closes the menu.  */
  revert(): void;

  /** Whether the select is currently focused. */
  isFocused: boolean;

  /** Sets whether the select is focused. */
  setFocused(isFocused: boolean): void;
}

type FilterFn = (textValue: string, inputValue: string) => boolean;

export interface ComboBoxMultiSelectProps<T>
  extends CollectionBase<T>,
    MultipleSelection,
    InputBase,
    TextInputBase,
    Validation,
    FocusableProps,
    LabelableProps,
    HelpTextProps,
    OverlayTriggerProps {
  /** The list of ComboBox items (uncontrolled). */
  defaultItems?: Iterable<T>;
  /** The list of ComboBox items (controlled). */
  items?: Iterable<T>;
  /** Method that is called when the open state of the menu changes. Returns the new open state and the action that caused the opening of the menu. */
  onOpenChange?: (isOpen: boolean, menuTrigger?: MenuTriggerAction) => void;
  /** The value of the ComboBox input (controlled). */
  inputValue?: string;
  /** The default value of the ComboBox input (uncontrolled). */
  defaultInputValue?: string;
  /** Handler that is called when the ComboBox input value changes. */
  onInputChange?: (value: string) => void;

  // /**
  //  * Whether the Combobox should only suggest matching options or autocomplete the field with the nearest matching option.
  //  * @default 'suggest'
  //  */
  // completionMode?: 'suggest' | 'complete',
  /**
   * The interaction required to display the ComboBox menu.
   * @default 'input'
   */
  menuTrigger?: MenuTriggerAction;
  /**
   * The name of the input element, used when submitting an HTML form. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname).
   */
  name?: string;
}

export interface AriaComboBoxMultiSelectProps<T>
  extends ComboBoxMultiSelectProps<T>,
    DOMProps {
  /** Whether keyboard navigation is circular. */
  shouldFocusWrap?: boolean;
}

export interface ComboBoxMultiSelectStateOptions<T>
  extends ComboBoxMultiSelectProps<T> {
  /** The filter function used to determine if a option should be included in the combo box list. */
  defaultFilter?: FilterFn;
  /** Whether the combo box list menu should be open or not. */
  isOpen?: boolean;
}

function useComboBoxMultiSelectState<T extends object>(
  props: ComboBoxMultiSelectStateOptions<T>
): ComboBoxMultiSelectState<T> {
  let { defaultFilter } = props;

  let [showAllItems, setShowAllItems] = useState(false);
  let [isFocused, setFocusedState] = useState(false);
  let [inputValue, setInputValue] = useControlledState(
    props.inputValue,
    props.defaultInputValue ?? '',
    // @ts-expect-error
    props.onInputChange
  );

  let listState = useMultiSelectListState({
    ...props,
    onSelectionChange: (keys) => {
      if (props.onSelectionChange) {
        if (keys === 'all') {
          // This may change back to "all" once we will implement async loading of additional
          // items and differentiation between "select all" vs. "select visible".
          props.onSelectionChange(new Set(listState.collection.getKeys()));
        } else {
          props.onSelectionChange(keys);
        }
      }

      if (props.selectionMode === 'single') {
        const [current] = selectedKeys;
        const [selected] = keys;

        // If key is the same, reset the inputValue and close the menu
        // (scenario: user clicks on already selected option)
        if (current && current === selected) {
          resetInputValue();
        }

        triggerState.close();
      } else {
        setInputValue('');
      }
    },

    items: props.items ?? props.defaultItems,
  });
  const {
    collection,
    selectionManager,
    selectedKeys,
    setSelectedKeys,
    selectedItems,
    disabledKeys,
  } = listState;

  // Preserve original collection so we can show all items on demand
  let originalCollection = collection;
  let filteredCollection = useMemo(() => {
    // No default filter if items are controlled.
    return !defaultFilter
      ? collection
      : filterCollection(collection, inputValue, defaultFilter);
  }, [collection, inputValue, defaultFilter, props.items]);

  // Track what action is attempting to open the menu
  let menuOpenTrigger = useRef('focus' as MenuTriggerAction);
  let onOpenChange = (open: boolean) => {
    if (props.onOpenChange) {
      props.onOpenChange(open, open ? menuOpenTrigger.current : undefined);
    }
  };

  let triggerState = useMenuTriggerState({
    ...props,
    onOpenChange,
    isOpen: props.isOpen,
    defaultOpen: undefined,
  });

  let open = (focusStrategy?: FocusStrategy, trigger?: MenuTriggerAction) => {
    let displayAllItems =
      trigger === 'manual' ||
      (trigger === 'focus' && props.menuTrigger === 'focus');
    // Prevent open operations from triggering if there is nothing to display
    // Also prevent open operations from triggering if items are uncontrolled but defaultItems is empty, even if displayAllItems is true.
    // This is to prevent comboboxes with empty defaultItems from opening but allow controlled items comboboxes to open even if the inital list is empty (assumption is user will provide swap the empty list with a base list via onOpenChange returning `menuTrigger` manual)
    if (
      filteredCollection.size > 0 ||
      (displayAllItems && originalCollection.size > 0) ||
      props.items ||
      props.searchable
    ) {
      if (
        displayAllItems &&
        !triggerState.isOpen &&
        props.items === undefined
      ) {
        // Show all items if menu is manually opened. Only care about this if items are undefined
        setShowAllItems(true);
      }

      menuOpenTrigger.current = trigger;
      triggerState.open(focusStrategy);
    }
  };

  let toggle = (focusStrategy?: FocusStrategy, trigger?: MenuTriggerAction) => {
    let displayAllItems =
      trigger === 'manual' ||
      (trigger === 'focus' && props.menuTrigger === 'focus');
    // If the menu is closed and there is nothing to display, early return so toggle isn't called to prevent extraneous onOpenChange
    if (
      !(
        filteredCollection.size > 0 ||
        (displayAllItems && originalCollection.size > 0) ||
        props.items
      ) &&
      !triggerState.isOpen
    ) {
      return;
    }

    if (displayAllItems && !triggerState.isOpen && props.items === undefined) {
      // Show all items if menu is toggled open. Only care about this if items are undefined
      setShowAllItems(true);
    }

    // Only update the menuOpenTrigger if menu is currently closed
    if (!triggerState.isOpen) {
      menuOpenTrigger.current = trigger;
    }

    triggerState.toggle(focusStrategy);
  };

  let [lastValue, setLastValue] = useState(inputValue);
  let resetInputValue = () => {
    let itemText =
      selectedItems
        ?.map((item) => {
          return item.textValue ?? item.rendered;
        })
        .join(', ') ?? '';

    if (props.selectionMode === 'single') {
      setLastValue(itemText);
      setInputValue(itemText);
    } else {
      setLastValue('');
      setInputValue('');
    }
  };

  let isInitialRender = useRef(true);
  let lastSelectedKeys = useRef(
    props.selectedKeys ?? props.defaultSelectedKeys ?? null
  );

  let lastSelectedKeysText = useRef(
    selectedItems?.map((item) => item.textValue ?? item.rendered).join(', ') ??
      ''
  );

  // intentional omit dependency array, want this to happen on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Open and close menu automatically when the input value changes if the input is focused,
    // and there are items in the collection or allowEmptyCollection is true.
    if (
      isFocused &&
      filteredCollection.size > 0 &&
      !triggerState.isOpen &&
      inputValue !== lastValue &&
      props.menuTrigger !== 'manual'
    ) {
      open(undefined, 'input');
    }

    // Close when an item is selected.
    if (
      props.selectionMode === 'single' &&
      selectedKeys.size > 0 &&
      !isEqual(selectedKeys, lastSelectedKeys.current)
    ) {
      triggerState.close();
    }

    // Clear focused key when input value changes and display filtered collection again.
    if (props.selectionMode === 'single' && inputValue !== lastValue) {
      selectionManager.setFocusedKey(null);
      setShowAllItems(false);

      // Set selectedKey to null when the user clears the input.
      // If controlled, this is the application developer's responsibility.
      if (
        inputValue === '' &&
        (props.inputValue === undefined || props.selectedKeys === undefined)
      ) {
        setSelectedKeys([]);
      }
    }

    // If it is the intial render and inputValue isn't controlled nor has an intial value, set input to match current selected key if any
    if (
      props.selectionMode === 'single' &&
      isInitialRender.current &&
      props.inputValue === undefined &&
      props.defaultInputValue === undefined
    ) {
      resetInputValue();
    }

    // If the selectedKeys changed, update the input value.
    // Do nothing if both inputValue and selectedKey are controlled.
    // In this case, it's the user's responsibility to update inputValue in onSelectionChange.
    if (
      !isEqual(selectedKeys, lastSelectedKeys.current) &&
      (props.inputValue === undefined || props.selectedKeys === undefined)
    ) {
      resetInputValue();
    } else if (props.selectionMode === 'single') {
      setLastValue(inputValue);
    }

    // If we clear the last chip we want to restore focus to the input.
    const prev = lastSelectedKeys.current;
    if (selectedKeys.size === 0 && (prev === 'all' || new Set(prev).size > 0)) {
      setFocusedState(true);
    }

    // Update the inputValue if the selected item's text changes from its last tracked value.
    // This is to handle cases where a selectedKey is specified but the items aren't available (async loading) or the selected item's text value updates.
    // Only reset if the user isn't currently within the field so we don't erroneously modify user input.
    // If inputValue is controlled, it is the user's responsibility to update the inputValue when items change.

    const selectedItemText =
      selectedItems
        ?.map((item) => item.textValue ?? item.rendered)
        .join(', ') ?? '';
    if (
      props.selectionMode === 'single' &&
      !isFocused &&
      selectedKeys.size > 0 &&
      props.inputValue === undefined &&
      isEqual(selectedKeys, lastSelectedKeys.current)
    ) {
      if (lastSelectedKeysText.current !== selectedItemText) {
        setLastValue(selectedItemText);
        setInputValue(selectedItemText);
      }
    }

    isInitialRender.current = false;
    lastSelectedKeys.current = selectedKeys;
    lastSelectedKeysText.current = selectedItemText;
  });

  useEffect(() => {
    // Reset focused key when the menu closes
    if (!triggerState.isOpen) {
      selectionManager.setFocusedKey(null);
    }
  }, [triggerState.isOpen, selectionManager.setFocusedKey]);

  // Revert input value and close menu
  let revert = () => {
    commitSelection();
    triggerState.close();
  };

  let commitSelection = () => {
    // If multiple things are controlled, call onSelectionChange
    if (props.selectedKeys !== undefined && props.inputValue !== undefined) {
      props.onSelectionChange && props.onSelectionChange(selectedKeys);

      // Stop menu from reopening from useEffect
      let itemText =
        selectedItems
          ?.map((item) => item.textValue ?? item.rendered)
          .join(', ') ?? '';

      if (props.selectionMode === 'single') {
        setLastValue(itemText);
      }
    } else {
      // If only a single aspect of combobox is controlled, reset input value and close menu for the user
      resetInputValue();
    }
  };

  let commit = () => {
    if (triggerState.isOpen && selectionManager.focusedKey != null) {
      // Reset inputValue and close menu here if the selected key is already the focused key. Otherwise
      // fire onSelectionChange to allow the application to control the closing.
      if (selectedKeys.has(selectionManager.focusedKey)) {
        setSelectedKeys(
          [...selectedKeys].filter((key) => {
            return key !== selectionManager.focusedKey;
          })
        );
        commitSelection();
      } else {
        setSelectedKeys([...selectedKeys, selectionManager.focusedKey]);
      }
    } else {
      // Reset inputValue and close menu if no item is focused but user triggers a commit
      commitSelection();
    }
  };

  let setFocused = (isFocused: boolean) => {
    if (isFocused) {
      if (props.menuTrigger === 'focus') {
        open(undefined, 'focus');
      }
    } else {
      commitSelection();
    }

    setFocusedState(isFocused);
  };

  return {
    ...listState,
    ...triggerState,
    toggle,
    open,
    selectionManager,
    selectedKeys,
    setSelectedKeys,
    disabledKeys,
    isFocused,
    setFocused,
    selectedItems,
    collection: showAllItems ? originalCollection : filteredCollection,
    inputValue,
    setInputValue,
    commit,
    revert,
  };
}

export function filterCollection<T extends object>(
  collection: Collection<Node<T>>,
  inputValue: string,
  filter: FilterFn
): Collection<Node<T>> {
  return new ListCollection(filterNodes(collection, inputValue, filter));
}

export function filterNodes<T>(
  nodes: Iterable<Node<T>>,
  inputValue: string,
  filter: FilterFn
): Iterable<Node<T>> {
  let filteredNode = [];
  for (let node of [...nodes]) {
    if (node.type === 'section' && node.hasChildNodes) {
      let filtered = filterNodes(node.childNodes, inputValue, filter);
      if ([...filtered].length > 0) {
        filteredNode.push({ ...node, childNodes: filtered });
      }
    } else if (node.type !== 'section' && filter(node.textValue, inputValue)) {
      filteredNode.push({ ...node });
    }
  }
  return filteredNode;
}

export interface AriaComboBoxMultiSelectOptions<T>
  extends AriaComboBoxMultiSelectProps<T> {
  /** The ref for the input element. */
  inputRef: RefObject<HTMLInputElement>;
  /** The ref for the list box popover. */
  popoverRef: RefObject<Element>;
  /** The ref for the list box. */
  listBoxRef: RefObject<HTMLElement>;
  /** The ref for the optional list box popup trigger button.  */
  buttonRef?: RefObject<Element>;
  /** An optional keyboard delegate implementation, to override the default. */
  keyboardDelegate?: KeyboardDelegate;
}

export interface ComboBoxAria<T> {
  /** Props for the label element. */
  labelProps: DOMAttributes;
  /** Props for the combo box input element. */
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  /** Props for the list box, to be passed to [useListBox](useListBox.html). */
  listBoxProps: AriaListBoxOptions<T>;
  /** Props for the optional trigger button, to be passed to [useButton](useButton.html). */
  buttonProps: AriaButtonProps;
  /** Props for the combo box description element, if any. */
  descriptionProps: DOMAttributes;
  /** Props for the combo box error message element, if any. */
  errorMessageProps: DOMAttributes;
}

/**
 * Provides the behavior and accessibility implementation for a combo box component.
 * A combo box combines a text input with a listbox, allowing users to filter a list of options to items matching a query.
 * @param props - Props for the combo box.
 * @param state - State for the select, as returned by `useComboBoxState`.
 */
export function useComboBoxMultiSelect<T>(
  props: AriaComboBoxMultiSelectOptions<T>,
  state: ComboBoxMultiSelectState<T>
): ComboBoxAria<T> {
  let {
    buttonRef,
    popoverRef,
    inputRef,
    listBoxRef,
    keyboardDelegate,
    // completionMode = 'suggest',
    shouldFocusWrap,
    isReadOnly,
    isDisabled,
  } = props;

  let { menuTriggerProps, menuProps } = useMenuTrigger(
    {
      type: 'listbox',
      isDisabled: isDisabled || isReadOnly,
    },
    state,
    buttonRef
  );

  let stringFormatter = useLocalizedStringFormatter(
    intlMessages,
    '@react-aria/combobox'
  );

  // Set listbox id so it can be used when calling getItemId later
  listData.set(state, { id: menuProps.id });

  // By default, a KeyboardDelegate is provided which uses the DOM to query layout information (e.g. for page up/page down).
  // When virtualized, the layout object will be passed in as a prop and override this.
  let delegate = useMemo(
    () =>
      keyboardDelegate ||
      new ListKeyboardDelegate(
        state.collection,
        state.disabledKeys,
        listBoxRef
      ),
    [keyboardDelegate, state.collection, state.disabledKeys, listBoxRef]
  );

  // Use useSelectableCollection to get the keyboard handlers to apply to the textfield
  let { collectionProps } = useSelectableCollection({
    selectionManager: state.selectionManager,
    keyboardDelegate: delegate,
    disallowTypeAhead: true,
    // TODO: Fix infinite loop when allowing select all
    disallowSelectAll: true,
    disallowEmptySelection: true,
    shouldFocusWrap,
    ref: inputRef,
    // Prevent item scroll behavior from being applied here, should be handled in the Popover + ListBox component
    isVirtualized: false,
  });

  // For textfield specific keydown operations
  let onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        // Prevent form submission if menu is open since we may be selecting a option
        if (state.isOpen && e.key === 'Enter') {
          e.preventDefault();
        }

        // Prevent tabbing out of the menu in multi-select mode
        // unless menuTrigger === 'focus' and we don't have a focused key
        if (state.isOpen && props.selectionMode === 'multiple') {
          if (
            props.menuTrigger === 'focus' &&
            state.selectionManager.focusedKey == null
          ) {
            state.close();
          } else {
            e.preventDefault();
          }
        }

        state.commit();
        break;
      case 'Escape':
        state.revert();
        break;
      case 'ArrowDown':
        state.open('first', 'manual');
        break;
      case 'ArrowUp':
        state.open('last', 'manual');
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        state.selectionManager.setFocusedKey(null);
        break;
    }
  };

  let onBlur = (e: FocusEvent) => {
    // Ignore blur if focused moved to the button or into the popover.
    if (
      e.relatedTarget === buttonRef?.current ||
      popoverRef.current?.contains(e.relatedTarget)
    ) {
      return;
    }

    if (props.onBlur) {
      props.onBlur(e);
    }

    state.setFocused(false);
  };

  let onFocus = (e: FocusEvent) => {
    if (state.isFocused) {
      return;
    }

    if (props.onFocus) {
      props.onFocus(e);
    }

    state.setFocused(true);
  };

  let { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(
      {
        ...props,
        onChange: (value) => {
          state.setInputValue(value);
        },
        onKeyDown: !isReadOnly
          ? chain(
              state.isOpen && collectionProps.onKeyDown,
              onKeyDown,
              props.onKeyDown
            )
          : undefined,
        onBlur,
        value: state.inputValue,
        onFocus,
        autoComplete: 'off',
      },
      inputRef
    );

  // Press handlers for the ComboBox button
  let onPress = (e: PressEvent) => {
    if (e.pointerType === 'touch') {
      // Focus the input field in case it isn't focused yet
      inputRef.current.focus();
      state.toggle(null, 'manual');
    }
  };

  let onPressStart = (e: PressEvent) => {
    if (e.pointerType !== 'touch') {
      inputRef.current.focus();
      state.toggle(
        e.pointerType === 'keyboard' || e.pointerType === 'virtual'
          ? 'first'
          : null,
        'manual'
      );
    }
  };

  let triggerLabelProps = useLabels({
    id: menuTriggerProps.id,
    'aria-label': 'buttonLabel',
    'aria-label': stringFormatter.format('buttonLabel'),
    'aria-labelledby': props['aria-labelledby'] || labelProps.id,
  });

  let listBoxProps = useLabels({
    id: menuProps.id,
    'aria-label': 'listboxLabel',
    'aria-label': stringFormatter.format('listboxLabel'),
    'aria-labelledby': props['aria-labelledby'] || labelProps.id,
  });

  // If a touch happens on direct center of ComboBox input, might be virtual click from iPad so open ComboBox menu
  let lastEventTime = useRef(0);
  let onTouchEnd = (e: TouchEvent) => {
    if (isDisabled || isReadOnly) {
      return;
    }

    // Sometimes VoiceOver on iOS fires two touchend events in quick succession. Ignore the second one.
    if (e.timeStamp - lastEventTime.current < 500) {
      e.preventDefault();
      inputRef.current.focus();
      return;
    }

    let rect = (e.target as Element).getBoundingClientRect();
    let touch = e.changedTouches[0];

    let centerX = Math.ceil(rect.left + 0.5 * rect.width);
    let centerY = Math.ceil(rect.top + 0.5 * rect.height);

    if (touch.clientX === centerX && touch.clientY === centerY) {
      e.preventDefault();
      inputRef.current.focus();
      state.toggle(null, 'manual');

      lastEventTime.current = e.timeStamp;
    }
  };

  let onMouseDown = (e: TouchEvent) => {
    if (state.isFocused && !state.isOpen) {
      state.open(null, 'manual');
    }
  };

  // VoiceOver has issues with announcing aria-activedescendant properly on change
  // (especially on iOS). We use a live region announcer to announce focus changes
  // manually. In addition, section titles are announced when navigating into a new section.
  let focusedItem =
    state.selectionManager.focusedKey != null && state.isOpen
      ? state.collection.getItem(state.selectionManager.focusedKey)
      : undefined;
  let sectionKey = focusedItem?.parentKey ?? null;
  let itemKey = state.selectionManager.focusedKey ?? null;
  let lastSection = useRef(sectionKey);
  let lastItem = useRef(itemKey);
  useEffect(() => {
    if (
      isAppleDevice() &&
      focusedItem != null &&
      itemKey !== lastItem.current
    ) {
      let isSelected = state.selectionManager.isSelected(itemKey);
      let section =
        sectionKey != null ? state.collection.getItem(sectionKey) : null;
      let sectionTitle =
        section?.['aria-label'] ||
        (typeof section?.rendered === 'string' ? section.rendered : '') ||
        '';

      let announcement = stringFormatter.format('focusAnnouncement', {
        isGroupChange: section && sectionKey !== lastSection.current,
        groupTitle: sectionTitle,
        groupCount: section ? [...section.childNodes].length : 0,
        optionText: focusedItem['aria-label'] || focusedItem.textValue || '',
        isSelected,
      });

      announce(announcement);
    }

    lastSection.current = sectionKey;
    lastItem.current = itemKey;
  });

  // Announce the number of available suggestions when it changes
  let optionCount = getItemCount(state.collection);
  let lastSize = useRef(optionCount);
  let lastOpen = useRef(state.isOpen);
  useEffect(() => {
    // Only announce the number of options available when the menu opens if there is no
    // focused item, otherwise screen readers will typically read e.g. "1 of 6".
    // The exception is VoiceOver since this isn't included in the message above.
    let didOpenWithoutFocusedItem =
      state.isOpen !== lastOpen.current &&
      (state.selectionManager.focusedKey == null || isAppleDevice());

    if (
      state.isOpen &&
      (didOpenWithoutFocusedItem || optionCount !== lastSize.current)
    ) {
      let announcement = stringFormatter.format('countAnnouncement', {
        optionCount,
      });

      announce(announcement);
    }

    lastSize.current = optionCount;
    lastOpen.current = state.isOpen;
  });

  // Announce when a selection occurs for VoiceOver. Other screen readers typically do this automatically.
  let lastSelectedKeys = useRef(state.selectedKeys);
  useEffect(() => {
    if (
      isAppleDevice() &&
      state.isFocused &&
      state.selectedItems &&
      !isEqual(state.selectedKeys, lastSelectedKeys.current)
    ) {
      let selectionText = state.selectedItems
        .map((item) => item['aria-label'] || item.textValue || '')
        .join(', ');

      let announcement = stringFormatter.format('selectedAnnouncement', {
        selectionText,
        selectionCount: state.selectedKeys.size,
      });

      announce(announcement);
    }

    lastSelectedKeys.current = state.selectedKeys;
  });

  useEffect(() => {
    if (state.isOpen) {
      const elementsToHide = [inputRef.current];
      // popoverRef only exists on desktop implementation
      if (popoverRef.current) {
        elementsToHide.push(popoverRef.current);
      }
      return ariaHideOutside(elementsToHide);
    }
  }, [state.isOpen, inputRef, popoverRef]);

  return {
    labelProps,
    buttonProps: {
      ...menuTriggerProps,
      ...triggerLabelProps,
      excludeFromTabOrder: true,
      onPress,
      onPressStart,
      isDisabled: isDisabled || isReadOnly,
    },
    inputProps: mergeProps(inputProps, {
      role: 'combobox',
      'aria-expanded': menuTriggerProps['aria-expanded'],
      'aria-controls': state.isOpen ? menuProps.id : undefined,
      // TODO: readd proper logic for completionMode = complete (aria-autocomplete: both)
      'aria-autocomplete': 'list',
      'aria-activedescendant': focusedItem
        ? getItemId(state, focusedItem.key)
        : undefined,
      onTouchEnd,
      onMouseDown,
      // This disable's iOS's autocorrect suggestions, since the combo box provides its own suggestions.
      autoCorrect: 'off',
      // This disable's the macOS Safari spell check auto corrections.
      spellCheck: 'false',
    }),
    listBoxProps: mergeProps(menuProps, listBoxProps, {
      autoFocus: state.focusStrategy,
      shouldUseVirtualFocus: true,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
    }),
    descriptionProps,
    errorMessageProps,
  };
}

/**
 * Accessibility messages for screen readers.
 *
 * Until we implement a more robust solution for localization, we are manually defining
 * the messages for en-US as functions.  These were compiled using @internationalized/string-compiler.
 * See the commented out object below for the raw strings.
 *
 */
const intlMessages = {
  'en-US': {
    focusAnnouncement: (args, formatter) =>
      `${formatter.select(
        {
          true: () =>
            `Entered group ${args.groupTitle}, with ${formatter.plural(
              args.groupCount,
              {
                one: () => `${formatter.number(args.groupCount)} option`,
                other: () => `${formatter.number(args.groupCount)} options`,
              }
            )}. `,
          other: ``,
        },
        args.isGroupChange
      )}${args.optionText}${formatter.select(
        { true: `, selected`, other: `` },
        args.isSelected
      )}`,
    countAnnouncement: (args, formatter) =>
      `${formatter.plural(args.optionCount, {
        one: () => `${formatter.number(args.optionCount)} option`,
        other: () => `${formatter.number(args.optionCount)} options`,
      })} available.`,
    selectedAnnouncement: (args) =>
      `${args.selectionCount} items selected. ${args.selectionText}`,
    buttonLabel: `Show suggestions`,
    listboxLabel: `Suggestions`,
  },
};

// const intlMessages = {
//   'en-US': {
//     focusAnnouncement:
//       '{isGroupChange, select, true {Entered group {groupTitle}, with {groupCount, plural, one {# option} other {# options}}. } other {}}{optionText}{isSelected, select, true {, selected} other {}}',
//     countAnnouncement:
//       '{optionCount, plural, one {# option} other {# options}} available.',
//     selectedAnnouncement: '{selectionCount} items selected. {selectionText}',
//     buttonLabel: 'Show suggestions',
//     listboxLabel: 'Suggestions',
//   }
// }

export { ComboBox, Item, Section };
