import React from 'react';

import { ContentText } from '@headway/helix/ContentText';

import { theme } from './theme';

interface PropertyListProps {
  maxColumnCount?: number;
}

export const PropertyList: React.FC<
  React.PropsWithChildren<PropertyListProps>
> = ({ maxColumnCount, ...rest }) => (
  <div
    css={{
      columnCount: 1,
      [theme.media.small]: {
        columnCount: maxColumnCount || 2,
      },
    }}
    {...rest}
  />
);

interface PropertyListItemProps {
  label: string;
  value: React.ReactNode;
  labelWidth?: string;
  vertical?: boolean;
}

export const PropertyListItem: React.FC<
  React.PropsWithChildren<PropertyListItemProps>
> = ({ label, value, labelWidth = '50%', vertical, ...props }) => (
  <div
    className={`flex flex-col ${
      vertical ? 'phone:flex-col' : 'phone:flex-row'
    } px-0 py-2`}
    {...props}
  >
    <div className={`w-[${labelWidth}] shrink-0 grow-0`}>
      <ContentText variant="caption/medium" color="foreground/secondary">
        {label}
      </ContentText>
    </div>
    <div className="text-system-black [overflow-wrap:anywhere]">{value}</div>
  </div>
);
