import { useMemo } from 'react';

import {
  ALL_ASSESSMENT_TYPES,
  NQF_EXCLUSIVE_PATIENT_ASSESSMENT_TYPES,
} from '@headway/shared/constants/patientAssessments';
import { NQF_OPT_IN } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';

import { getSortedAssessmentTypes } from './utils';

export function useAvailableAssessmentTypes() {
  const isProviderOptedIntoNQF = useFlag(NQF_OPT_IN, false);

  return useMemo(() => {
    const availableTypes = ALL_ASSESSMENT_TYPES.filter((assessmentType) =>
      NQF_EXCLUSIVE_PATIENT_ASSESSMENT_TYPES.has(assessmentType)
        ? isProviderOptedIntoNQF
        : true
    );

    return getSortedAssessmentTypes(availableTypes, isProviderOptedIntoNQF);
  }, [isProviderOptedIntoNQF]);
}
