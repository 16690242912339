import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import { useQuery } from '@headway/shared/react-query';

import { useExtoleMicrosite } from '../hooks/extole';

interface ReferProviderProps {
  AuthStore: any;
  imgComponent?: React.FC<React.PropsWithChildren<any>> | 'img';
}

const isUserRegistered = (user?: UserRead): boolean => {
  return !!user?.email;
};

export const ReferProvider = ({
  AuthStore,
  imgComponent: ImgComponent = 'img',
}: ReferProviderProps) => {
  const user = AuthStore.user;

  const referralPortalQuery = useQuery(
    ['referralPortal', user?.id],
    () => UserApi.getReferralPortal(user?.id),
    {
      enabled: user?.id && isUserRegistered(user),
    }
  );

  return (
    <div className="bg-system-white isolate min-h-screen w-full pt-[50px]">
      {isUserRegistered(user) && referralPortalQuery.data?.accessToken ? (
        <ExtoleReferralPortal token={referralPortalQuery.data.accessToken} />
      ) : !isUserRegistered(user) ? (
        <ExtoleReferralPortal token={undefined} />
      ) : null}
    </div>
  );
};

interface ExtoleReferralPortalProps {
  token: string | undefined;
}

function ExtoleReferralPortal({ token }: ExtoleReferralPortalProps) {
  const ref = useExtoleMicrosite(token);

  return <div ref={ref} />;
}
