import { FormikProps } from 'formik';
import { MutableRefObject } from 'react';

export const validateAndSubmit = async (
  ref: MutableRefObject<FormikProps<any> | undefined>
) => {
  if (!ref.current) {
    return;
  }

  const form = ref.current;
  const validationErrors = await form.validateForm();
  await form.submitForm();

  return {
    success: Object.keys(validationErrors).length === 0,
    errors: validationErrors,
    values: form.values,
  };
};
