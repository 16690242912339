import { useContext, useEffect, useMemo, useState } from 'react';

import { ProviderAppointmentAttachmentRead } from '@headway/api/models/ProviderAppointmentAttachmentRead';

import { AppointmentAttachmentFormV2Values } from '../../components/forms/AppointmentAttachmentFormV2';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useAttachmentsInitialValues = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { event, isEventLoading, isProgressNoteLoading } = useContext(
    AppointmentConfirmationContextV2
  );

  const isLoading = isEventLoading || isProgressNoteLoading;

  const initialValues: AppointmentAttachmentFormV2Values = useMemo(() => {
    return (
      event?.providerAppointment?.attachments?.map(
        (a: ProviderAppointmentAttachmentRead) => ({
          id: a.id,
          name: a.name,
          link: a.link,
        })
      ) || []
    );
  }, [event?.providerAppointment?.attachments]);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
    }
  }, [isLoading, initialValues, isInitialized]);

  return { isInitialized: isInitialized, initialValues: initialValues };
};
