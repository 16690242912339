import { css } from '@emotion/react';
import { useCallback, useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  useCreateProviderAppointmentAttachmentMutation,
  useDeleteProviderAppointmentAttachmentMutation,
} from '~/legacy/mutations/providerAppointmentAttachment';
import {
  AttachmentsListV2,
  AttachmentV2,
} from '~/legacy/views/Patients/AttachmentsListV2';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile } from '@headway/shared/utils/upload';
import { notifyError } from '@headway/ui/utils/notify';

import { useProvider } from 'hooks/useProvider';
import { isDetailsConfirmed } from 'views/Calendar/events/util/events';

import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../stores/AppointmentConfirmationContextV2';
import { AppointmentConfirmationModalFormV2Values } from '../modals/AppointmentConfirmationModalV2';
import { ProgressNoteFormV2Values } from './ProgressNote/ProgressNoteFormV2';

export interface AppointmentAttachmentItem {
  id?: number | undefined;
  name?: string | undefined;
  link?: string | undefined;
}
export type AppointmentAttachmentFormV2Values = AppointmentAttachmentItem[];

export const AppointmentAttachmentFormV2 = () => {
  const provider = useProvider();
  const { event, progressNoteState } = useContext(
    AppointmentConfirmationContextV2
  );

  const {
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<AppointmentConfirmationModalFormV2Values>();

  const attachments: AppointmentAttachmentFormV2Values = useWatch({
    name: 'attachments',
  });
  const progressNoteValues: ProgressNoteFormV2Values = useWatch({
    name: 'progressNote',
  });

  const createProviderAppointmentAttachmentMutation =
    useCreateProviderAppointmentAttachmentMutation();
  const deleteProviderAppointmentAttachmentMutation =
    useDeleteProviderAppointmentAttachmentMutation();

  const onAddAttachments = useCallback(
    async (files: UploadedFile[]) => {
      const newlyAttachedFiles = files.map((file) => ({
        name: file.name,
        link: file.link,
      }));

      const newAttachment =
        await createProviderAppointmentAttachmentMutation.mutateAsync({
          providerAppointmentId: event?.providerAppointment?.id!,
          providerAppointmentAttachment: newlyAttachedFiles[0],
        });

      setValue('attachments', [...(attachments || []), newAttachment]);
      trigger('attachments');
    },
    [
      createProviderAppointmentAttachmentMutation,
      event?.providerAppointment?.id,
      setValue,
      trigger,
      attachments,
    ]
  );

  const onDeleteAttachment = useCallback(
    (providerAppointmentAttachmentId?: number | undefined) => {
      if (providerAppointmentAttachmentId) {
        deleteProviderAppointmentAttachmentMutation.mutateAsync(
          providerAppointmentAttachmentId
        );

        // Filter out the deleted attachment
        const updatedAttachments = (attachments || []).filter(
          (attachment) => attachment.id !== providerAppointmentAttachmentId
        );

        setValue('attachments', updatedAttachments);
        trigger('attachments');
      }
    },
    [
      deleteProviderAppointmentAttachmentMutation,
      setValue,
      trigger,
      attachments,
    ]
  );

  const onDownloadAttachment = useCallback(async (attachment: AttachmentV2) => {
    if (!attachment.link || !attachment.name) {
      return;
    }

    try {
      await downloadFile({ link: attachment.link, name: attachment.name });
    } catch (err) {
      notifyError('There was an issue downloading your attachment');
      logException(err);
    }
  }, []);

  const [providerClosedConsiderHeadwayTemplateCard] = useLocalStorage(
    'providerClosedConsiderHeadwayTemplateCard'
  );

  const shouldDisableDropzoneAndDelete = useMemo(() => {
    /**
     * We want to disable the dropzone and deleting in the following scenarios:
     *
     * 1. Appointment is confirmed with an uploaded Note
     * 2. Appointment is confirmed with a signed template
     * 3. Appointment is confirmed with a draft template
     * 4. Appointment is not confirmed with a signed template
     * 5. Appointment is not confirmed with a draft template
     *
     */
    if (isDetailsConfirmed(event)) {
      if (progressNoteValues.progressNoteType === ProgressNoteType.TEMPLATE) {
        switch (progressNoteState) {
          case ProgressNoteState.SIGNED:
          case ProgressNoteState.SAVED_FOR_LATER:
            return true;
        }
      } else if (
        progressNoteValues.progressNoteType === ProgressNoteType.UPLOAD
      ) {
        if (!!event?.providerAppointment?.attachments?.length) {
          return true;
        }
      }
    } else {
      if (progressNoteValues.progressNoteType === ProgressNoteType.TEMPLATE) {
        switch (progressNoteState) {
          case ProgressNoteState.SIGNED:
            return true;
          case ProgressNoteState.SAVED_FOR_LATER:
            return true;
          case ProgressNoteState.EDITING:
            return false;
        }
      }
    }
    return false;
  }, [event, progressNoteState, progressNoteValues.progressNoteType]);

  if (
    !providerClosedConsiderHeadwayTemplateCard ||
    progressNoteValues.progressNoteType === ProgressNoteType.NONE ||
    !progressNoteValues.progressNoteType
  ) {
    return null;
  }

  /**
   * We want to return no react element in the following situations:
   *
   * 1. Signed note with no attachments
   * 2. Saved for later and no attachments
   * 3. Editing free text for addendum and no attachments
   * 4. Editing upload for addendum and no attachments
   * 5. Progress note type is template but they haven't selected anything yet
   */
  if (progressNoteValues.progressNoteType === ProgressNoteType.TEMPLATE) {
    switch (progressNoteState) {
      case ProgressNoteState.SIGNED:
      case ProgressNoteState.ADDENDUM_EDITING:
      case ProgressNoteState.SAVED_FOR_LATER:
        if (attachments?.length === 0) {
          return null;
        }
        break;
      case ProgressNoteState.EDITING:
        if (!progressNoteValues.template) {
          return null;
        }
        break;
    }
  }

  /**
   * We want to restrict file type to only PDF with certain restrictions
   */
  const allowedFileTypes =
    //allowedUploadTypes ??
    'application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png';

  return (
    <div css={AttachmentFormAccordionCss}>
      {progressNoteValues.progressNoteType === ProgressNoteType.TEMPLATE &&
      progressNoteValues.template !== undefined ? (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing.x6,
          }}
        >
          <SectionHeader
            color={
              shouldDisableDropzoneAndDelete ? 'disabledGray' : 'textBlack'
            }
          >
            Additional Information
          </SectionHeader>
          <SubBodyText
            color={
              shouldDisableDropzoneAndDelete ? 'disabledGray' : 'textBlack'
            }
          >
            Upload anything else that might be useful.
          </SubBodyText>
        </div>
      ) : null}
      {shouldDisableDropzoneAndDelete &&
        progressNoteValues.progressNoteType === ProgressNoteType.UPLOAD && (
          <div css={{ marginBottom: theme.spacing.x1 }}>
            <SubBodyText>
              <strong>Original Upload</strong>
            </SubBodyText>
          </div>
        )}
      <AttachmentsListV2
        provider={provider}
        attachments={attachments || []}
        onAddAttachments={onAddAttachments}
        onDownloadAttachment={onDownloadAttachment}
        onDeleteAttachment={onDeleteAttachment}
        accept={allowedFileTypes}
        disabled={shouldDisableDropzoneAndDelete}
        isDeleteDisabled={shouldDisableDropzoneAndDelete}
        hideDropzone={shouldDisableDropzoneAndDelete}
        variant={'helix'}
        hasErrors={!!errors.attachments}
      />
      {errors.attachments && (
        <div
          css={{
            marginTop: theme.spacing.x1,
            color: theme.color.foreground['danger-secondary'],
            ...theme.typography.caption.medium,
          }}
        >
          {errors.attachments.message}
        </div>
      )}
    </div>
  );
};

const AttachmentFormAccordionCss = css`
  padding-bottom: 32px;

  .MuiAccordion-root,
  .MuiAccordion-root.Mui-expanded {
    margin-top: 0;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    height: 48px;
    min-height: 48px;
  }
`;
