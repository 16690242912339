import { useProvider } from 'hooks';
import React, { useCallback, useContext, useState } from 'react';
import { useMemo } from 'react';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { Item, Select } from '@headway/helix/Select';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { getUseUserQueryKey } from '@headway/shared/hooks/useUser';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { FormRow } from '@headway/ui/form';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import {
  getUsePatientAddressesQueryKey,
  usePatientAddresses,
} from 'hooks/usePatientAddresses';

import { AddressModal } from '../../../../../Patients/AddressModal';
import { AppointmentContext } from '../../../../stores/AppointmentContext';
import { SessionDetailsFormValues } from '../SessionDetailsForm';

// gemini.link(web/apps/sigmund/app/legacy/views/Calendar/components/AppointmentConfirmation/Forms/FormComponents/PatientAddressDropdown.tsx)
export const PatientAddressDropdownHelix = ({
  patient,
  canUpdateSessionDetails,
  values,
  setFieldValue,
  providerAppointmentId,
}: {
  patient: UserRead;
  canUpdateSessionDetails: boolean;
  values: SessionDetailsFormValues;
  setFieldValue: (a: any, b: any) => void;
  providerAppointmentId?: number;
}) => {
  const provider = useProvider();
  const queryClient = useQueryClient();
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });
  const { updateAppointmentAddressState } = useContext(AppointmentContext);
  const { data: patientAddresses, isLoading } = usePatientAddresses({
    patientId: patient.id,
  });
  const [showAddressModal, setShowAddressModal] = useState(false);
  const { isMSCGuardrailWarning, restrictionDate } = useMSCGuardrail();

  const onAddSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      getUsePatientAddressesQueryKey({ patientId: patient.id })
    );
    queryClient.invalidateQueries(getUseUserQueryKey({ userId: patient.id }));
    queryClient.invalidateQueries(['patient-insurance-status']);
  }, [queryClient, patient.id]);

  const patientAddressesOnFileText = `Your client must be physically located (during your session) in a state where you are credentialed with their insurance plan on Headway. ${
    patientAddresses?.length === 0 && isMSCGuardrailWarning
      ? `Starting after ${restrictionDate}, we'll block telehealth session confirmation if there is a mismatch.`
      : ''
  }`;

  return useMemo(() => {
    if (isLoading) {
      return <></>;
    }

    return (
      <>
        <FormRow>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <FormControl
                component={Select}
                selectionMode="single"
                menuWidth="stretch"
                label={`Where did ${patientFirstName} join the telehealth session from?`}
                disabled={!canUpdateSessionDetails}
                name={'appointmentLocationPatientAddressId'}
                placeholder="Select a location"
                helpText={patientAddressesOnFileText}
                onSelectionChange={(items: Set<string>) => {
                  const value = parseInt(Array.from(items)[0]);
                  setFieldValue('appointmentLocationPatientAddressId', value);
                  updateAppointmentAddressState(
                    values?.providerAddressId,
                    value
                  );
                }}
                selectedKeys={
                  values.appointmentLocationPatientAddressId
                    ? [values.appointmentLocationPatientAddressId.toString()]
                    : []
                }
              >
                {patientAddresses?.map((address: PatientAddressRead) => (
                  <Item key={address.id}>
                    {address.addressType &&
                      `${addressTypeDisplayNames[address.addressType]}: `}
                    {address.streetLine1}
                    {address.streetLine2 && ` ${address.streetLine2}`},{' '}
                    {address.city}, {statesToDisplayNames[address.state]}{' '}
                    {address.zipCode}
                  </Item>
                ))}
              </FormControl>
            </div>
            <div style={{ padding: 10 }} />
            <div
              className={'hlx-combobox-actions button'}
              style={{ paddingTop: 20 }}
            >
              <Button
                size="large"
                variant={'secondary'}
                onPress={() => {
                  trackEvent({
                    name: 'Provider Add New Address Button Clicked',
                    properties: {
                      providerId: provider.id,
                      providerPatientId: patient.id,
                      providerAppointmentId,
                    },
                  });
                  setShowAddressModal(true);
                }}
              >
                + New location
              </Button>
            </div>
          </div>
        </FormRow>

        <AddressModal
          open={showAddressModal}
          patientUser={patient}
          isActiveAddress={false}
          onClose={() => {
            trackEvent({
              name: 'Provider Skip Telehealth Button Clicked',
              properties: {
                providerId: provider.id,
                providerPatientId: patient.id,
                providerAppointmentId,
              },
            });
            setShowAddressModal(false);
          }}
          onSuccess={() => {
            trackEvent({
              name: 'Provider Save and Verify Telehealth Button Clicked',
              properties: {
                providerId: provider.id,
                providerPatientId: patient.id,
                providerAppointmentId,
              },
            });
            onAddSuccess && onAddSuccess();
            setShowAddressModal(false);
          }}
        />
      </>
    );
  }, [
    patient,
    canUpdateSessionDetails,
    values.appointmentLocationPatientAddressId,
    patientAddresses,
    isLoading,
    setFieldValue,
    onAddSuccess,
    showAddressModal,
    patientFirstName,
    provider.id,
    providerAppointmentId,
    updateAppointmentAddressState,
    values?.providerAddressId,
  ]);
};
