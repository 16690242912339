import { useMemo } from 'react';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { FormControl } from '@headway/helix/FormControl';
import { Item, Select } from '@headway/helix/Select';
import {
  ALL_ASSESSMENT_TYPES,
  SHORT_ASSESSMENT_NAMES,
} from '@headway/shared/constants/patientAssessments';

import { useAvailableAssessmentTypes } from '../../helpers/useAvailableAssessmentTypes';
import { AssessmentScheduleInputSelectProps } from './types';

type AssessmentTypeSelectorProps = AssessmentScheduleInputSelectProps & {
  types?: PatientAssessmentType[];
};

export function AssessmentTypeSelector({
  types = ALL_ASSESSMENT_TYPES,
  warn,
  ...props
}: AssessmentTypeSelectorProps) {
  const userAvailableTypes = useAvailableAssessmentTypes();

  const typeOptions = useMemo(() => {
    const availableTypes = new Set(types);
    return userAvailableTypes.filter((type) => availableTypes.has(type));
  }, [types, userAvailableTypes]);

  return (
    <FormControl
      component={Select}
      selectionMode="single"
      label="Assessment"
      searchable
      data-warn={warn}
      {...props}
    >
      {typeOptions.map((assessmentType) => {
        const value = SHORT_ASSESSMENT_NAMES[assessmentType];

        return (
          <Item key={assessmentType} textValue={value}>
            {value}
          </Item>
        );
      })}
    </FormControl>
  );
}
