import { CircularProgress } from '@mui/material';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import { ContentText } from '@headway/helix/ContentText';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { theme } from '@headway/helix/theme';
import { VisuallyHidden } from '@headway/helix/utils';
import { formatPatientName } from '@headway/shared/utils/patient';

import {
  BulkConfirmSubmissionStatus,
  UnconfirmedEventsData,
} from '../../utils/types';

interface SubmissionStepProps {
  unconfirmedEventsData: UnconfirmedEventsData;
  submissionStatusByEventVirtualId: Record<string, BulkConfirmSubmissionStatus>;
}

export const SubmissionStep = ({
  unconfirmedEventsData,
  submissionStatusByEventVirtualId,
}: SubmissionStepProps) => {
  const totalCount = Object.keys(submissionStatusByEventVirtualId).length;
  const completeCount = Object.values(submissionStatusByEventVirtualId).filter(
    (status) =>
      status === BulkConfirmSubmissionStatus.SUCCESS ||
      status === BulkConfirmSubmissionStatus.ERROR
  ).length;

  const sortedEvents = sortBy(
    Object.values(unconfirmedEventsData).map((data) => data.event),
    (event) => event.startDate
  );

  return (
    <div className="px-5 py-10">
      <div className="mb-2">
        <ContentText variant="page-title">
          Confirming sessions ({completeCount}/{totalCount})
        </ContentText>
      </div>
      <ContentText variant="body">
        One moment while we make sure your session details meet insurer
        requirements.
      </ContentText>
      <div className="mt-5 flex flex-col gap-3 rounded border border-solid border-border-primary bg-background-secondary p-5">
        {sortedEvents.map((event) => {
          const status = submissionStatusByEventVirtualId[event.virtualId];
          return (
            <div key={event.virtualId} className="flex items-center">
              {status === BulkConfirmSubmissionStatus.SUCCESS ? (
                <>
                  <IconCheckCircle
                    size={18}
                    fill={theme.color.foreground.compliance}
                  />
                  <VisuallyHidden>Confirmed:</VisuallyHidden>
                </>
              ) : status === BulkConfirmSubmissionStatus.ERROR ? (
                <>
                  <IconWarningCircle
                    variant="outlined"
                    size={18}
                    fill={theme.color.foreground.danger}
                  />
                  <VisuallyHidden>Changes required:</VisuallyHidden>
                </>
              ) : (
                <>
                  <CircularProgress size={18} color="inherit" />
                  <VisuallyHidden>Loading:</VisuallyHidden>
                </>
              )}
              <div className="ml-2">
                <ContentText variant="body/medium">
                  {formatPatientName(event.providerAppointment!.patient)}
                </ContentText>
              </div>
              <div className="ml-1">
                <ContentText variant="caption">
                  {moment(event.startDate).format('h:mma')}
                </ContentText>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
