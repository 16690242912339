import { Skeleton } from '@mui/material';
import { Formik } from 'formik';
import {
  cloneDeep,
  differenceBy,
  isEqual,
  keyBy,
  pullAllBy,
  shuffle,
  sortBy,
} from 'lodash';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import React from 'react';
import * as Yup from 'yup';

import { FilterRead } from '@headway/api/models/FilterRead';
import { GenderStatus } from '@headway/api/models/GenderStatus';
import { ModalityRead } from '@headway/api/models/ModalityRead';
import { ProviderFilterRead } from '@headway/api/models/ProviderFilterRead';
import { ProviderGender } from '@headway/api/models/ProviderGender';
import { ProviderModalityRead } from '@headway/api/models/ProviderModalityRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSpecialtyRead } from '@headway/api/models/ProviderSpecialtyRead';
import { ProviderSpecialtyUpdate } from '@headway/api/models/ProviderSpecialtyUpdate';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { StyleTag } from '@headway/api/models/StyleTag';
import { ModalityApi } from '@headway/api/resources/ModalityApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderFilterApi } from '@headway/api/resources/ProviderFilterApi';
import { ProviderModalityApi } from '@headway/api/resources/ProviderModalityApi';
import { ProviderSpecialtyApi } from '@headway/api/resources/ProviderSpecialtyApi';
import { ProviderStyleTagApi } from '@headway/api/resources/ProviderStyleTagApi';
import { SpecialtyApi } from '@headway/api/resources/SpecialtyApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { ComboBox } from '@headway/helix/ComboBox';
import { Form, FormStickyFooter } from '@headway/helix/Form';
import { FormControl, validity } from '@headway/helix/FormControl';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  GENDER_STATUS_TO_DISPLAY_NAMES,
  GENDERS_TO_DISPLAY_NAMES,
  shouldIncludeProviderGender,
} from '@headway/shared/constants/gender';
import { languages as allLanguages } from '@headway/shared/constants/languages';
import type { LDFlagSet } from '@headway/shared/FeatureFlags/react';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { SORTED_ETHNICITY_OPTIONS } from '@headway/shared/utils/ethnicity';
import {
  getBioTherapyApproachHeader,
  hasQualifiedForNewProfile,
  PROVIDER_BIO_ABOUT_YOU_PROMPT,
  PROVIDER_BIO_TAKE_AWAYS_PROMPT,
  ProviderBioFields,
} from '@headway/shared/utils/providers';
import { getStyleTagProperties } from '@headway/shared/utils/providerStyleTag';
import { logException } from '@headway/shared/utils/sentry';
import { getContentOfHtml } from '@headway/shared/utils/strings';
import { theme as legacyTheme } from '@headway/ui/theme';
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from '@headway/ui/utils/notify';

import { twoColumnGridCss } from '../../utils/billing';
import {
  BIO_SECTION_DESCRIPTION,
  BIO_SECTION_TITLE,
  bioQandAToStatementHtml,
  bioQandAValidator,
  NewBioFeatureAlert,
} from './components/BioInput';
import {
  formatHighlightsForSubmission,
  formatHighlightsFromRead,
} from './components/HighlightsInput';
import { ProfilePhotoInput } from './components/ProfilePhotoInput';
import { ProfileStatusAlert } from './components/ProfileStatusAlert';
import { styleTagsValidator } from './components/StyleTagsInput';

const createProviderProfileLink = (provider: ProviderRead) =>
  `${process.env.REACT_APP_MAIN_URL}/providers/${provider.slug}?utm_source=pem&utm_medium=direct_link&utm_campaign=${provider.id}`;

const inputCss = {
  marginTop: legacyTheme.space.base,
  [legacyTheme.media.small]: { marginTop: 0 },
  '& .MuiInputBase': {
    fontSize: legacyTheme.fontSize.xs,
  },
};

const randomizedStyleTags = shuffle(Object.values(StyleTag));

const getValuesForSubmission = (
  values: FormValues,
  isProviderPrescriber?: boolean
): FormValuesForSubmission => {
  const bioFields: ProviderBioFields = {
    bioAboutYou: values.bioAboutYou,
    bioTherapyApproach: values.bioTherapyApproach,
    bioTakeAways: values.bioTakeAways,
  };

  return {
    degreeType: values.degreeType || '',
    degreeType2: values.degreeType2 || '',
    displayName: values.displayName || '',
    displayFirstName: values.displayFirstName || '',
    displayLastName: values.displayLastName || '',
    ethnicity: values.ethnicity || [],
    gender: values.gender,
    genderStatus: values.genderStatus,
    // If the provider has completed their approach q/a we use it
    // for their highlights otherwise we keep their existing highlights
    highlights: values.bioTherapyApproach
      ? [getContentOfHtml(values.bioTherapyApproach)]
      : formatHighlightsForSubmission(values.highlights || []),
    languages: values.languages || [],
    photoUrl: values.photoUrl || '',
    photoS3ObjectKey: values.photoS3ObjectKey || '',
    postnomials: values.postnomials || [],
    prenomial: values.prenomial || '',
    school: values.school || '',
    school2: values.school2 || '',
    slug: values.slug,
    // If the provider has met the minimum completion criteria for Q/A
    // then we use it to populate their bio.
    statementHtml: hasQualifiedForNewProfile(bioFields)
      ? bioQandAToStatementHtml(bioFields, isProviderPrescriber)
      : values.statementHtml,
    bioAboutYou: values.bioAboutYou || '',
    bioTherapyApproach: values.bioTherapyApproach || '',
    bioTakeAways: values.bioTakeAways || '',
    pronouns: values.pronouns || '',
  };
};

const getFormPropertiesFromProviderRead = (
  provider: ProviderRead
): Omit<FormValues, 'styleTags'> => {
  return {
    degreeType: provider.degreeType || '',
    degreeType2: provider.degreeType2 || '',
    displayName: provider.displayName || '',
    displayFirstName: provider.displayFirstName || '',
    displayLastName: provider.displayLastName || '',
    ethnicity: provider.ethnicity || [],
    gender: provider.gender,
    genderStatus: provider.genderStatus,
    highlights: formatHighlightsFromRead(provider.highlights || []),
    languages: provider.languages || [],
    photoUrl: provider.photoUrl || '',
    photoS3ObjectKey: provider.photoS3ObjectKey || '',
    postnomials: provider.postnomials || [],
    prenomial: provider.prenomial || '',
    school: provider.school || '',
    school2: provider.school2 || '',
    slug: provider.slug,
    statementHtml: provider.statementHtml || '',
    bioAboutYou: provider.bioAboutYou || '',
    bioTherapyApproach: provider.bioTherapyApproach || '',
    bioTakeAways: provider.bioTakeAways || '',
    pronouns: provider.pronouns || '',
  };
};

const getConstructedNamesObj = ({
  displayName,
  displayFirstName,
  displayLastName,
  prenomial,
}: {
  prenomial?: string;
  displayFirstName?: string;
  displayLastName?: string;
  displayName?: string;
}) => {
  const constructedFullNameArr = `${
    prenomial || ''
  } ${displayFirstName} ${displayLastName}`.split(' ');

  // Only map indices if displayName isn't an empty string
  if (displayName?.length) {
    const displayNameArray = displayName.split(' ');
    const displayNameIndices: number[] = [];
    let displayNameIndex = 0;
    let constructedNameIndex = 0;
    while (
      displayNameIndex < displayNameArray.length &&
      constructedNameIndex < constructedFullNameArr.length
    ) {
      if (
        displayNameArray[displayNameIndex] ===
        constructedFullNameArr[constructedNameIndex]
      ) {
        displayNameIndices.push(constructedNameIndex);
        displayNameIndex++;
      }
      constructedNameIndex++;
    }

    return {
      constructedFullNameArr,
      displayNameIndices,
    };
  }

  // Empty displayNameIndices on constructedFullNameArr change
  return {
    constructedFullNameArr,
    displayNameIndices: [],
  };
};

const getDisplayNameString = (
  constructedFullNameArr: string[],
  displayNameIndices: number[]
) => {
  return displayNameIndices
    .sort()
    .map((idx) => constructedFullNameArr[idx])
    .join(' ');
};

const getProfileSchema = () => {
  return Yup.object().shape({
    ethnicity: Yup.array().of(Yup.string()).nullable(),
    gender: Yup.mixed().oneOf(Object.values(ProviderGender)).nullable(),
    genderStatus: Yup.string().nullable(),
    school: Yup.string().nullable(),
    degreeType: Yup.string()
      .nullable()
      .when('school', {
        is: (school) => school,
        then: Yup.string().required(
          'Degree type is required when a school is selected'
        ),
      }),
    school2: Yup.string().nullable(),
    degreeType2: Yup.string().nullable(),
    photoUrl: Yup.string().nullable(),
    prenomial: Yup.string().nullable(),
    displayName: Yup.string().test({
      name: 'Truthy',
      message: 'You must choose at least one name.',
      test: (value) => !!value,
    }),
    displayFirstName: Yup.string()
      .nullable()
      .required('First name is required.'),
    displayLastName: Yup.string().nullable().required('Last name is required.'),
    postnomials: Yup.array().of(Yup.string()),
    bioAboutYou: bioQandAValidator,
    bioTherapyApproach: bioQandAValidator,
    bioTakeAways: bioQandAValidator,
    styleTags: styleTagsValidator,
    pronouns: Yup.string().nullable(),
  });
};

interface BaseFormValues {
  ethnicity: string[];
  prenomial: string;
  displayName: string;
  displayFirstName: string;
  displayLastName: string;
  postnomials: string[];
  gender?: ProviderGender;
  genderStatus?: GenderStatus;
  languages: string[];
  school: string;
  degreeType: string;
  school2: string;
  degreeType2: string;
  providerFilters?: ProviderFilterRead[];
  specialties?: SpecialtyRead[];
  photoUrl: string;
  photoS3ObjectKey: string;
  statementHtml: string;
  highlights: { highlight: string }[];
  slug: string; // updated automatically when the display first or last name is updated
  modalities?: ModalityRead[];
  bioAboutYou: string;
  bioTherapyApproach: string;
  bioTakeAways: string;
  pronouns: string;
}

type FormValuesWithTags = BaseFormValues & { styleTags: StyleTag[] };

type FormValues = BaseFormValues | FormValuesWithTags;

type FormValuesForSubmission = Omit<FormValues, 'highlights' | 'styleTags'> & {
  highlights: string[];
};

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  AuthStore?: any;
  flags: LDFlagSet;
}

interface ProviderAttributes {
  genderOptions: SelectOption[];
  genderStatusOptions: SelectOption[];
  ethnicityOptions: SelectOption[];
  degreeTypeOptions: SelectOption[];
  schoolOptions: SelectOption[];
  postnomialOptions: SelectOption[];
  languagesOptions: SelectOption[];
}

interface State extends ProviderAttributes {
  isLoading: boolean;
  isPreviewModalOpen: boolean;
  filters: FilterRead[];
  providerFilters: ProviderFilterRead[];
  providerId: number;
  constructedFullNameArr: string[];
  displayNameIndices: number[];
  initialConstructedFullNameArr: string[];
  initialDisplayNameIndices: number[];
  specialties: SpecialtyRead[];
  providerSpecialties: ProviderSpecialtyRead[];
  selectedFocusAreas: SpecialtyRead[];
  selectedOtherTreatmentAreas: SpecialtyRead[];
  modalities: ModalityRead[];
  providerModalities: ProviderModalityRead[];
  selectedModalities: ModalityRead[];
  styleTags: StyleTag[];
  providerStyleTags: StyleTag[];
}

const ProfileImpl = inject('AuthStore')(
  observer(
    class ProfileImpl extends React.Component<Props, State> {
      state: State = {
        isLoading: true,
        isPreviewModalOpen: false,
        genderOptions: [],
        genderStatusOptions: [],
        languagesOptions: [],
        ethnicityOptions: [],
        degreeTypeOptions: [],
        schoolOptions: [],
        postnomialOptions: [],
        filters: [],
        specialties: [],
        providerFilters: [],
        providerSpecialties: [],
        providerId: -1,
        constructedFullNameArr: [],
        displayNameIndices: [],
        initialConstructedFullNameArr: [],
        initialDisplayNameIndices: [],
        selectedFocusAreas: [],
        selectedOtherTreatmentAreas: [],
        modalities: [],
        providerModalities: [],
        selectedModalities: [],
        providerStyleTags: [],
        styleTags: [],
      };

      fetchProviderAttributeSet = async (): Promise<ProviderAttributes> => {
        const res = await ProviderApi.getProviderAttributeSet();

        const attributes = {
          degreeTypeOptions: res.degreeTypes.map((o) => ({
            label: o,
            value: o,
          })),
          ethnicityOptions: Object.values(SORTED_ETHNICITY_OPTIONS).map(
            (ethnicity) => ({
              label: ethnicity,
              value: ethnicity,
            })
          ),
          genderOptions: Object.entries(GENDERS_TO_DISPLAY_NAMES)
            .filter(([value, _]) =>
              shouldIncludeProviderGender(
                value,
                this.props.AuthStore.provider?.gender
              )
            )
            .map(([genderKey, displayName]) => ({
              label: displayName,
              value: genderKey,
            })),
          genderStatusOptions: Object.entries(
            GENDER_STATUS_TO_DISPLAY_NAMES
          ).map(([genderStatusKey, displayName]) => ({
            label: displayName,
            value: genderStatusKey,
          })),
          schoolOptions: res.schools.map((o) => ({ label: o, value: o })),
          postnomialOptions: res.postnomials.map((o) => ({
            label: o,
            value: o,
          })),
          languagesOptions: allLanguages.map((o) => ({ label: o, value: o })),
        };

        const sortedAttrOptions = {} as ProviderAttributes;

        let attrKey: keyof ProviderAttributes;
        for (attrKey in attributes) {
          sortedAttrOptions[attrKey] = attributes[attrKey].sort((a, b) => {
            return a.label
              .toLocaleLowerCase()
              .localeCompare(b.label.toLocaleLowerCase());
          });
        }

        return sortedAttrOptions;
      };

      handleFiltersUpdate = async (
        oldValues: ProviderFilterRead[],
        newValues: ProviderFilterRead[]
      ): Promise<ProviderFilterRead[]> => {
        const added = differenceBy(newValues, oldValues, 'filterId');
        const removed = differenceBy(oldValues, newValues, 'filterId');
        if (added.length) {
          added.forEach(async (addedVal) => {
            try {
              const newFilter = await ProviderFilterApi.createProviderFilter({
                providerId: addedVal.providerId,
                filterId: addedVal.filterId,
              });
              // Update value in newValues to have id in case the user wants
              // to delete the filter before refreshing
              newValues.forEach((val, idx) => {
                if (val.filterId === newFilter.filterId) {
                  newValues[idx] = newFilter;
                }
              });
            } catch (err: AnyTS4TryCatchUnknownError) {
              notifyError((err.message || err).toString());
            }
          });
        }

        if (removed) {
          removed.forEach(async (removedVal) => {
            try {
              await ProviderFilterApi.deleteProviderFilter(removedVal.id);
            } catch (err: AnyTS4TryCatchUnknownError) {
              notifyError(err.toString());
            }
          });
        }

        return newValues;
      };

      handleProviderSpecialtiesUpdate = async (
        focusAreasSelected: SpecialtyRead[],
        otherTreatmentAreasSelected: SpecialtyRead[],
        initialProviderSpecialties: ProviderSpecialtyRead[]
      ): Promise<ProviderSpecialtyRead[]> => {
        let results = cloneDeep(initialProviderSpecialties);
        const providerSpecialtiesToRemove: ProviderSpecialtyRead[] = [];
        const existingBySpecialtyId = keyBy(
          initialProviderSpecialties,
          'specialtyId'
        );

        // We want to leverage Promise.all() for this to reduce time spent waiting
        // on network calls.
        const focusAreaCreates = focusAreasSelected
          .filter((specialty) => {
            const exists = existingBySpecialtyId[specialty.id];
            return !exists;
          })
          .map((specialty) => {
            return ProviderSpecialtyApi.createProviderSpecialty({
              providerId: this.props.AuthStore.provider.id,
              specialtyId: specialty.id,
              isFocusArea: true,
            });
          });

        const otherAreaCreates = otherTreatmentAreasSelected
          .filter((specialty) => {
            const exists = existingBySpecialtyId[specialty.id];
            return !exists;
          })
          .map((specialty) => {
            return ProviderSpecialtyApi.createProviderSpecialty({
              providerId: this.props.AuthStore.provider.id,
              specialtyId: specialty.id,
              isFocusArea: false,
            });
          });

        const updates = initialProviderSpecialties
          .filter((providerSpecialty) => {
            const isUpdatedFocusArea = focusAreasSelected.find(
              (s) =>
                s.id === providerSpecialty.specialtyId &&
                !providerSpecialty.isFocusArea
            );
            const isUpdatedOtherArea = otherTreatmentAreasSelected.find(
              (s) =>
                s.id === providerSpecialty.specialtyId &&
                providerSpecialty.isFocusArea
            );
            return (
              (isUpdatedFocusArea && !isUpdatedOtherArea) ||
              (!isUpdatedFocusArea && isUpdatedOtherArea)
            );
          })
          .map((providerSpecialty) => {
            const providerSpecialtyUpdate: ProviderSpecialtyUpdate = {
              providerId: providerSpecialty.providerId,
              specialtyId: providerSpecialty.specialtyId,
              isFocusArea: !providerSpecialty.isFocusArea,
            };
            return ProviderSpecialtyApi.updateProviderSpecialty(
              providerSpecialty.id,
              providerSpecialtyUpdate
            );
          });

        const deletes = initialProviderSpecialties
          .filter((providerSpecialty) => {
            const isSelectedFocusArea = focusAreasSelected.find(
              (s) => s.id === providerSpecialty.specialtyId
            );
            const isSelectedOtherArea = otherTreatmentAreasSelected.find(
              (s) => s.id === providerSpecialty.specialtyId
            );

            return !isSelectedFocusArea && !isSelectedOtherArea;
          })
          .map((providerSpecialty) => {
            providerSpecialtiesToRemove.push(providerSpecialty);
            return ProviderSpecialtyApi.deleteProviderSpecialty(
              providerSpecialty.id
            );
          });

        const [focusAreaResult, otherAreaResult] = await Promise.all([
          Promise.all(focusAreaCreates),
          Promise.all(otherAreaCreates),
          Promise.all(updates),
          Promise.all(deletes),
        ]);

        results = [...results, ...focusAreaResult, ...otherAreaResult];
        pullAllBy(results, providerSpecialtiesToRemove, 'id');

        return results;
      };

      handleProviderModalitiesUpdate = async (
        modalitiesSelected: ModalityRead[],
        initialProviderModalities: ProviderModalityRead[]
      ): Promise<ProviderModalityRead[]> => {
        let results = cloneDeep(initialProviderModalities);
        const providerModalitiesToRemove: ProviderModalityRead[] = [];
        const existingByModalityId = keyBy(
          initialProviderModalities,
          'modalityId'
        );

        // We want to leverage Promise.all() for this to reduce time spent waiting
        // on network calls.
        const modalitiesCreates = modalitiesSelected
          .filter((modality) => {
            const exists = existingByModalityId[modality.id];
            return !exists;
          })
          .map((modality) => {
            return ProviderModalityApi.createProviderModality({
              providerId: this.props.AuthStore.provider.id,
              modalityId: modality.id,
            });
          });

        const deletes = initialProviderModalities
          .filter((providerModality) => {
            const isSelectedModality = modalitiesSelected.find(
              (s) => s.id === providerModality.modalityId
            );

            return !isSelectedModality;
          })
          .map((providerModality) => {
            providerModalitiesToRemove.push(providerModality);
            return ProviderModalityApi.deleteProviderModality(
              providerModality.id
            );
          });

        const [modalityResult] = await Promise.all([
          Promise.all(modalitiesCreates),
          Promise.all(deletes),
        ]);

        results = [...results, ...modalityResult];
        pullAllBy(results, providerModalitiesToRemove, 'id');

        return results;
      };

      getAndUpdateDisplayNames = (position: number) => {
        const { displayNameIndices, constructedFullNameArr } = this.state;
        let newDisplayNameIndices;
        if (!displayNameIndices.includes(position)) {
          newDisplayNameIndices = [...displayNameIndices, position];
        } else {
          newDisplayNameIndices = displayNameIndices.filter(
            (idx) => idx !== position
          );
        }
        this.setState({
          constructedFullNameArr,
          displayNameIndices: newDisplayNameIndices,
        });
        return getDisplayNameString(
          constructedFullNameArr,
          newDisplayNameIndices
        );
      };

      checkAndUpdateConstructedFullNameArr = (
        values: Parameters<typeof getConstructedNamesObj>[0]
      ) => {
        const { constructedFullNameArr, displayNameIndices } =
          getConstructedNamesObj(values);
        if (
          !isEqual(this.state.constructedFullNameArr, constructedFullNameArr)
        ) {
          this.setState({
            constructedFullNameArr,
            displayNameIndices,
          });
        }
      };

      handleUpdateStyleTags = async (
        selectedTags: StyleTag[]
      ): Promise<StyleTag[]> => {
        const providerId = this.props.AuthStore.provider.id;

        const currentProviderStyleTags =
          await ProviderStyleTagApi.getProviderStyleTags({
            provider_id: providerId,
          });

        const providerStyleTagsToDelete = currentProviderStyleTags.filter(
          (providerStyleTag) =>
            !selectedTags.includes(providerStyleTag.styleTag)
        );
        const providerStyleTagIdsToDelete = providerStyleTagsToDelete.map(
          (tag) => tag.id
        );
        await ProviderStyleTagApi.bulkDeleteProviderStyleTags({
          provider_id: providerId,
          provider_style_tag_ids: providerStyleTagIdsToDelete,
        });
        await ProviderStyleTagApi.bulkCreateProviderStyleTags({
          providerId,
          styleTags: selectedTags,
        });

        const updatedProviderStyleTags =
          await ProviderStyleTagApi.getProviderStyleTags({
            provider_id: providerId,
          });

        return updatedProviderStyleTags.map((tag) => tag.styleTag);
      };

      async onSubmit(
        values: FormValues,
        providerId: number,
        isProviderPrescriber?: boolean
      ) {
        try {
          let providerSpecialties: ProviderSpecialtyRead[] = [];
          let filters: ProviderFilterRead[] = [];

          if (values.specialties) {
            providerSpecialties = await this.handleProviderSpecialtiesUpdate(
              this.state.selectedFocusAreas,
              this.state.selectedOtherTreatmentAreas,
              this.state.providerSpecialties
            );
          }

          const providerModalities = await this.handleProviderModalitiesUpdate(
            values.modalities as ModalityRead[],
            this.state.providerModalities
          );

          let providerStyleTags: StyleTag[] = [];

          if ('styleTags' in values) {
            providerStyleTags = await this.handleUpdateStyleTags(
              values.styleTags
            );
          }

          const res = await ProviderApi.updateProvider(
            providerId,
            getValuesForSubmission(values, isProviderPrescriber)
          );

          const newValues = getFormPropertiesFromProviderRead(res);

          // Update AuthStore for toolbar photoUrl
          this.props.AuthStore.setProvider({
            ...this.props.AuthStore.provider,
            ...res,
          });

          const { constructedFullNameArr, displayNameIndices } =
            getConstructedNamesObj(newValues);

          this.setState({
            providerFilters: filters,
            providerSpecialties,
            providerModalities,
            initialConstructedFullNameArr: constructedFullNameArr,
            initialDisplayNameIndices: displayNameIndices,
            providerStyleTags,
          });
          notifySuccess('Your profile has been updated.');
        } catch (err) {
          notifyError('There was a problem updating your profile.');
        }
      }

      async saveProfilePhoto(photoS3ObjectKey: string, providerId: number) {
        try {
          const res = await ProviderApi.updateProvider(providerId, {
            photoS3ObjectKey,
          });
          // Update AuthStore for toolbar photoUrl
          this.props.AuthStore.setProvider({
            ...this.props.AuthStore.provider,
            ...res,
          });
          notifySuccess('Your photo has been updated.');
        } catch (err) {
          notifyError('There was a problem updating your photo.');
        }
      }

      async maybeMigrateEthnicity(provider: ProviderRead) {
        // calls an update with the existing values to trigger ethnicity migration
        const updatedProvider = await ProviderApi.updateProvider(provider.id, {
          ethnicity: provider.ethnicity,
        });
        // Update AuthStore for for latest changes
        this.props.AuthStore.setProvider({
          ...this.props.AuthStore.provider,
          ...updatedProvider,
        });
      }

      async componentDidMount() {
        try {
          const provider = this.props.AuthStore.provider as ProviderRead;

          let specialties: SpecialtyRead[] = [];
          let providerSpecialties: ProviderSpecialtyRead[] = [];
          specialties = await SpecialtyApi.getSpecialties({
            get_available_to_patients_only: true,
          });
          providerSpecialties =
            await ProviderSpecialtyApi.getProviderSpecialties({
              provider_id: provider.id,
            });

          const modalities: ModalityRead[] = await ModalityApi.getModalities();
          const providerModalities: ProviderModalityRead[] =
            await ProviderModalityApi.getProviderModalities({
              provider_id: provider.id,
            });

          const providerStyleTags =
            await ProviderStyleTagApi.getProviderStyleTags({
              provider_id: provider.id,
            });
          const providerAttributes = await this.fetchProviderAttributeSet();

          /*
  
      This is called at the beginning of load for this page to ensure the hispanic/latinx ethnicity migration
      happens when the user comes in via an email link. The link will have the query parameter `profile_feb_2023_updates`
      and if its on the link we will trigger an update to the user ethnicity with the existing values. If the gate is
      turned on the backend will handle the migration correctly stripping out the old value to mark it as seen.
      */

          if (window.location?.search) {
            const query = queryString.parse(window.location.search);
            if (query['source'] === 'profile_feb_2023_updates') {
              await this.maybeMigrateEthnicity(provider);
            }
          }

          const { constructedFullNameArr, displayNameIndices } =
            getConstructedNamesObj(getFormPropertiesFromProviderRead(provider));
          this.setState({
            isLoading: false,
            specialties: sortBy(specialties, [
              (specialty) => specialty.clinicalDisplayName,
            ]),
            providerSpecialties,
            selectedFocusAreas: providerSpecialties
              .filter((item) => item.isFocusArea)
              .map((item) => item.specialty),
            selectedOtherTreatmentAreas: providerSpecialties
              .filter((item) => !item.isFocusArea)
              .map((item) => item.specialty),
            modalities: sortBy(modalities, [
              (modality) => modality.clinicalDisplayName,
            ]),
            providerModalities,
            selectedModalities: providerModalities.map((item) => item.modality),
            providerId: provider.id,
            initialConstructedFullNameArr: constructedFullNameArr,
            initialDisplayNameIndices: displayNameIndices,
            constructedFullNameArr,
            displayNameIndices,
            providerStyleTags: providerStyleTags.map((pst) => pst.styleTag),
            ...providerAttributes,
          });
        } catch (err) {
          notifyError(
            'Could not load all profile information. Please refresh.'
          );
          logException(err);
        }
      }

      render() {
        const {
          providerFilters,
          providerSpecialties,
          providerId,
          providerModalities,
        } = this.state;
        const provider = this.props.AuthStore.provider as ProviderRead;

        // Scroll to demographics element if demographics in URL.
        if (window.location.hash === '#demographics') {
          const demographicsElement = document.getElementById('demographics');
          if (demographicsElement) {
            demographicsElement.scrollIntoView({ behavior: 'smooth' });
          }
        }

        return (
          <Formik
            initialValues={{
              ...getFormPropertiesFromProviderRead(provider),
              styleTags: this.state.providerStyleTags,
              providerFilters,
              specialties: providerSpecialties.map((item) => item.specialty),
              focusAreasSpecialties: providerSpecialties
                .filter((item) => item.isFocusArea)
                .map((item) => item.specialty),
              otherTreatmentAreasSpecialties: providerSpecialties
                .filter((item) => !item.isFocusArea)
                .map((item) => item.specialty),
              modalities: providerModalities.map((item) => item.modality),
            }}
            initialTouched={{
              displayName: true,
              styleTags: true,
            }}
            enableReinitialize={true}
            validationSchema={getProfileSchema()}
            validateOnMount={true}
            onSubmit={async (values) => {
              await this.onSubmit(values, providerId, provider.isPrescriber);
            }}
            render={(formik) => {
              const { values, isSubmitting, resetForm, dirty, setFieldValue } =
                formik;
              const focusAreasSpecialtiesOptions = differenceBy(
                this.state.specialties,
                this.state.selectedOtherTreatmentAreas,
                'key'
              );

              const otherTreatmentAreasSpecialtiesOptions = differenceBy(
                this.state.specialties,
                this.state.selectedFocusAreas,
                'key'
              );

              return (
                <>
                  <div
                    css={{
                      width: '100%',
                      margin: '0 auto',
                      [legacyTheme.media.small]: {
                        maxWidth: 927,
                      },
                    }}
                  >
                    <Form
                      id="settings-profile"
                      css={{
                        '--section-gap': '32px',
                        ...theme.stack.vertical,
                        gap: 'var(--section-gap)',
                        '& > section': {
                          padding: `var(--section-gap) ${theme.spacing.x4} 0`,
                        },
                        '& section + section': {
                          borderTop: `1px solid ${theme.color.system.borderGray}`,
                        },
                      }}
                    >
                      <ProfileStatusAlert />
                      <PageSection
                        css={{
                          ...theme.stack.vertical,
                          gap: 'var(--section-gap)',
                        }}
                      >
                        <div>
                          <h2>
                            <SectionHeader>Profile</SectionHeader>
                          </h2>
                          <div>
                            <BodyText>
                              Your profile tells Headway referrals and other
                              prospective clients about you and your practice.
                              It's also where clients can book an intake session
                              or consultation call with you.
                            </BodyText>
                          </div>
                        </div>
                        <section css={twoColumnGridCss}>
                          <div>
                            <h3>
                              <SectionHeader>Profile URL</SectionHeader>
                            </h3>
                            <PageSectionSubText>
                              <BodyText>
                                Use this unique link to view your profile, or
                                share it with prospective clients so they can
                                book with you.
                              </BodyText>
                            </PageSectionSubText>
                          </div>
                          <div
                            css={{
                              display: 'grid',
                              gridTemplateColumns: '1fr auto',
                              gap: theme.spacing.x2,
                            }}
                          >
                            <TextField
                              name="profile-url"
                              label="Profile URL"
                              value={createProviderProfileLink(
                                this.props.AuthStore.provider
                              )}
                              readonly={true}
                            />
                            <div
                              css={{
                                paddingTop: 20,
                                ...theme.stack.horizontal,
                                gap: theme.spacing.x2,
                              }}
                            >
                              <Button
                                size="large"
                                variant="secondary"
                                onPress={() => {
                                  navigator.clipboard
                                    .writeText(
                                      createProviderProfileLink(
                                        this.props.AuthStore.provider
                                      )
                                    )
                                    .then(() => {
                                      notifySuccess('Copied', {
                                        autoHideDuration: 3000,
                                      });
                                    });
                                }}
                                data-testid="copyGeneratedCalendarUrl"
                              >
                                Copy
                              </Button>
                              <LinkButton
                                size="large"
                                variant="secondary"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={createProviderProfileLink(
                                  this.props.AuthStore.provider
                                )}
                              >
                                View
                              </LinkButton>
                            </div>
                          </div>
                        </section>
                      </PageSection>

                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Photo</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              <p>
                                Your photo is the first thing a prospective
                                client sees.{' '}
                                <strong>
                                  A professional headshot increases the
                                  likelihood of a new client by 80%.
                                </strong>
                              </p>
                              <p css={{ marginBottom: theme.spacing.x4 }}>
                                If your photo doesn't meet Headway's quality
                                requirements, we’ll notify you and remove it
                                from your page. Need help getting a professional
                                headshot?{' '}
                                <Link
                                  href="https://headshots.studio/headway-headshots"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Use this free tool
                                </Link>
                                . This service is offered by{' '}
                                <Link
                                  href="https://snapbar.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Snapbar
                                </Link>{' '}
                                and is subject to the terms and conditions on
                                their site. By using the tool, you allow Snapbar
                                to use your photo. See their website for
                                additional details.
                              </p>
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <ProfilePhotoInput
                          urlFieldName="photoUrl"
                          s3ObjectKeyFieldName="photoS3ObjectKey"
                          provider={this.props.AuthStore.provider}
                          onError={(err, additionalContext) => {
                            notifyError(
                              'An error occurred while uploading your photo.'
                            );
                            logException(err, additionalContext);
                          }}
                          onSave={(photoUrl, photoS3ObjectKey) =>
                            this.saveProfilePhoto(photoS3ObjectKey, providerId)
                          }
                          onWarning={(message) =>
                            notifyWarning(
                              `${message} If you're happy with the uncropped photo, save changes at the bottom of the page.`
                            )
                          }
                        />
                      </PageSection>

                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Name</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              This name will be used on Headway.co, in
                              communications from Headway, and appointment
                              communications to your clients.
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <div
                            css={{
                              display: 'grid',
                              gridTemplateColumns:
                                'minmax(100px, auto) 1fr 1fr',
                              gap: theme.spacing.x2,
                            }}
                          >
                            <Select
                              name="prenomial"
                              label="Title"
                              onSelectionChange={([value]) => {
                                if (values.prenomial !== value) {
                                  const newValues = {
                                    ...values,
                                    prenomial: value,
                                    displayName: '',
                                  };
                                  this.checkAndUpdateConstructedFullNameArr(
                                    newValues
                                  );
                                  setFieldValue('displayName', '');
                                }
                                setFieldValue('prenomial', value);
                              }}
                              selectionMode="single"
                              selectedKeys={
                                values.prenomial ? [values.prenomial] : []
                              }
                              validation={validity('prenomial', formik)}
                            >
                              <Item key="Dr.">Dr.</Item>
                            </Select>

                            <FormControl
                              name="displayFirstName"
                              component={TextField}
                              label="First Name"
                              onChange={(value: string) => {
                                const newValues = {
                                  ...values,
                                  displayFirstName: value,
                                  displayName: '',
                                };
                                this.checkAndUpdateConstructedFullNameArr(
                                  newValues
                                );
                                setFieldValue('displayName', '');
                              }}
                            />

                            <FormControl
                              name="displayLastName"
                              label="Last Name"
                              component={TextField}
                              onChange={(value: string) => {
                                const newValues = {
                                  ...values,
                                  displayLastName: value,
                                  displayName: '',
                                };
                                this.checkAndUpdateConstructedFullNameArr(
                                  newValues
                                );
                                setFieldValue('displayName', '');
                              }}
                            />
                          </div>

                          <ComboBox
                            name="postnomials"
                            label={'Credentials'}
                            items={this.state.postnomialOptions}
                            selectionMode="multiple"
                            onSelectionChange={(selected) => {
                              setFieldValue(
                                'postnomials',
                                Array.from(selected)
                              );
                            }}
                            selectedKeys={values.postnomials}
                            validation={validity('postnomials', formik)}
                          >
                            {(item) => (
                              <Item key={item.value}>{item.label}</Item>
                            )}
                          </ComboBox>

                          <FormControl
                            name="pronouns"
                            label="Pronouns"
                            helpText="Let clients know which pronouns you use."
                            component={TextField}
                          />

                          <div>
                            <CheckboxGroup
                              name="displayName"
                              label="When used in a sentence, include"
                              value={values.displayName.split(' ')}
                              onChange={(checked) => {
                                setFieldValue('displayName', checked.join(' '));
                              }}
                              validation={validity('displayName', formik)}
                            >
                              {this.state.constructedFullNameArr.map(
                                (name, idx) => {
                                  return (
                                    !!name.length && (
                                      <Checkbox
                                        key={idx}
                                        value={name}
                                        onChange={() => {
                                          const displayName =
                                            this.getAndUpdateDisplayNames(idx);
                                          setFieldValue(
                                            'displayName',
                                            displayName
                                          );
                                        }}
                                      >
                                        {name}
                                      </Checkbox>
                                    )
                                  );
                                }
                              )}
                            </CheckboxGroup>

                            {values.displayName && (
                              <output
                                name="display-name-example"
                                htmlFor="displayName"
                                css={{ width: '100%' }}
                              >
                                <BodyText>
                                  <span>
                                    Example: <span>{values.displayName}</span>
                                  </span>
                                </BodyText>
                              </output>
                            )}
                          </div>
                        </div>
                      </PageSection>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Focus treatment areas</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              Select the areas you specialize in (up to 5).
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <ComboBox
                            name="focusAreasSpecialties"
                            label="Focus areas"
                            selectionMode="multiple"
                            items={focusAreasSpecialtiesOptions}
                            selectedKeys={values.focusAreasSpecialties.map(
                              (specialty) => specialty.key
                            )}
                            onSelectionChange={(selected) => {
                              const values =
                                focusAreasSpecialtiesOptions.filter(
                                  (option) => {
                                    return selected.has(option.key);
                                  }
                                );
                              setFieldValue('focusAreasSpecialties', values);
                              this.setState({
                                selectedFocusAreas: values,
                              });
                            }}
                            validation={validity(
                              'focusAreasSpecialties',
                              formik
                            )}
                          >
                            {(specialty) => (
                              <Item key={specialty.key}>
                                {specialty.clinicalDisplayName}
                              </Item>
                            )}
                          </ComboBox>
                        </div>
                      </PageSection>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Treatment areas</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              Select other treatment areas you support in your
                              practice.
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <ComboBox
                            name="otherTreatmentAreasSpecialties"
                            label="Treatment areas"
                            selectionMode="multiple"
                            items={otherTreatmentAreasSpecialtiesOptions}
                            selectedKeys={values.otherTreatmentAreasSpecialties.map(
                              (specialty) => specialty.key
                            )}
                            onSelectionChange={(selected) => {
                              const values =
                                otherTreatmentAreasSpecialtiesOptions.filter(
                                  (option) => {
                                    return selected.has(option.key);
                                  }
                                );
                              setFieldValue(
                                'otherTreatmentAreasSpecialties',
                                values
                              );
                              this.setState({
                                selectedOtherTreatmentAreas: values,
                              });
                            }}
                            validation={validity(
                              'otherTreatmentAreasSpecialties',
                              formik
                            )}
                          >
                            {(specialty) => (
                              <Item>{specialty.clinicalDisplayName}</Item>
                            )}
                          </ComboBox>
                        </div>
                      </PageSection>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Treatment approaches</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              Select those that you support in your practice.
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <ComboBox
                            name="modalities"
                            label="Treatment approaches"
                            selectionMode="multiple"
                            items={this.state.modalities}
                            selectedKeys={values.modalities.map((modality) =>
                              modality.id.toString()
                            )}
                            onSelectionChange={(selected) => {
                              const values = this.state.modalities.filter(
                                (option) => {
                                  // we have to use .toString() because ComboBox uses
                                  // whatever we pass to <Item key={}>, which React converts
                                  // to a string.
                                  return selected.has(option.id.toString());
                                }
                              );
                              setFieldValue('modalities', values);
                              this.setState({
                                selectedModalities: values,
                              });
                            }}
                            validation={validity('modalities', formik)}
                          >
                            {(modality) => (
                              <Item key={modality.id}>
                                {modality.clinicalDisplayName}
                              </Item>
                            )}
                          </ComboBox>
                        </div>
                      </PageSection>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Languages</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              Select those that you support in your practice.
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <ComboBox
                            name="languages"
                            label="Languages"
                            selectionMode="multiple"
                            items={this.state.languagesOptions}
                            selectedKeys={values.languages}
                            onSelectionChange={(selected) => {
                              setFieldValue('languages', Array.from(selected));
                            }}
                            validation={validity('languages', formik)}
                          >
                            {({ value }) => {
                              return <Item key={value}>{value}</Item>;
                            }}
                          </ComboBox>
                        </div>
                      </PageSection>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Style attributes</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              Select those that best describe your approach with
                              clients in sessions.
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <ComboBox
                            name="styleTags"
                            label="Style attributes"
                            selectionMode="multiple"
                            onSelectionChange={(selected) => {
                              setFieldValue('styleTags', Array.from(selected));
                            }}
                            selectedKeys={values.styleTags}
                            items={randomizedStyleTags.map((tag) => ({ tag }))}
                            validation={validity('styleTags', formik)}
                          >
                            {({ tag }) => {
                              const { name } = getStyleTagProperties(tag);

                              return <Item key={tag}>{name}</Item>;
                            }}
                          </ComboBox>
                        </div>
                      </PageSection>
                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>{BIO_SECTION_TITLE}</SectionHeader>
                          </h2>
                          <PageSectionSubText
                            css={{
                              ...theme.stack.vertical,
                              gap: theme.spacing.x6,
                            }}
                          >
                            <BodyText>{BIO_SECTION_DESCRIPTION}</BodyText>

                            {values.statementHtml &&
                              !hasQualifiedForNewProfile({
                                bioAboutYou: provider.bioAboutYou,
                                bioTherapyApproach: provider.bioTherapyApproach,
                                bioTakeAways: provider.bioTakeAways,
                              }) && (
                                <NewBioFeatureAlert
                                  oldBio={values.statementHtml}
                                />
                              )}
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <FormControl
                            name="bioAboutYou"
                            label={PROVIDER_BIO_ABOUT_YOU_PROMPT}
                            component={RichTextArea}
                            helpText="Share personal context to help clients relate to you. Be sure to include your education, professional background, and specific treatment areas and populations served."
                          />

                          <FormControl
                            name="bioTherapyApproach"
                            component={RichTextArea}
                            label={getBioTherapyApproachHeader(
                              provider.isPrescriber
                            )}
                            // helpText="Describe your approach(es) to working with clients."
                          />

                          <FormControl
                            name="bioTakeAways"
                            component={RichTextArea}
                            label={PROVIDER_BIO_TAKE_AWAYS_PROMPT}
                            // helpText="Explain how you can help and what a client should walk away with from their first session with you."
                          />
                        </div>
                      </PageSection>

                      <PageSection layout="grid.two-column">
                        <div>
                          <h2>
                            <SectionHeader>Education</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              Your education details will appear on your Headway
                              profile.
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          <ComboBox
                            name="school"
                            label="School"
                            selectionMode="single"
                            items={this.state.schoolOptions}
                            selectedKeys={values.school ? [values.school] : []}
                            onSelectionChange={([selected]) => {
                              setFieldValue('school', selected);
                            }}
                            validation={validity('school', formik)}
                          >
                            {(item) => (
                              <Item key={item.value}>{item.label}</Item>
                            )}
                          </ComboBox>

                          <ComboBox
                            name="degreeType"
                            label="Degree type"
                            selectionMode="single"
                            items={this.state.degreeTypeOptions}
                            selectedKeys={
                              values.degreeType ? [values.degreeType] : []
                            }
                            onSelectionChange={([selected]) => {
                              setFieldValue('degreeType', selected);
                            }}
                            validation={validity('degreeType', formik)}
                          >
                            {(item) => (
                              <Item key={item.value}>{item.label}</Item>
                            )}
                          </ComboBox>
                          <ComboBox
                            name="school2"
                            label="School 2"
                            optionalityText="Optional"
                            selectionMode="single"
                            items={this.state.schoolOptions}
                            selectedKeys={
                              values.school2 ? [values.school2] : []
                            }
                            onSelectionChange={([selected]) => {
                              setFieldValue('school2', selected);
                            }}
                            validation={validity('school2', formik)}
                          >
                            {(item) => (
                              <Item key={item.value}>{item.label}</Item>
                            )}
                          </ComboBox>
                          <ComboBox
                            name="degreeType2"
                            label="Degree type 2"
                            optionalityText="Optional"
                            selectionMode="single"
                            items={this.state.degreeTypeOptions}
                            selectedKeys={
                              values.degreeType2 ? [values.degreeType2] : []
                            }
                            onSelectionChange={([selected]) => {
                              setFieldValue('degreeType2', selected);
                            }}
                            validation={validity('degreeType2', formik)}
                          >
                            {(item) => (
                              <Item key={item.value}>{item.label}</Item>
                            )}
                          </ComboBox>
                        </div>
                      </PageSection>

                      <PageSection layout="grid.two-column">
                        <div id="demographics">
                          <h2>
                            <SectionHeader>Demographics</SectionHeader>
                          </h2>
                          <PageSectionSubText>
                            <BodyText>
                              This is used to help clients search for providers
                              in their area.
                            </BodyText>
                          </PageSectionSubText>
                        </div>
                        <div
                          css={{
                            ...theme.stack.vertical,
                            gap: theme.spacing.x6,
                          }}
                        >
                          {this.state.isLoading ? (
                            <Skeleton variant="rectangular" height={68} />
                          ) : (
                            <React.Fragment>
                              <Select
                                name="gender"
                                label="Gender"
                                selectionMode="single"
                                items={this.state.genderOptions}
                                onSelectionChange={([value]) => {
                                  setFieldValue('gender', value);
                                }}
                                selectedKeys={
                                  values.gender ? [values.gender] : []
                                }
                                validation={validity('gender', formik)}
                              >
                                {(option) => {
                                  return (
                                    <Item key={option.value}>
                                      {option.label}
                                    </Item>
                                  );
                                }}
                              </Select>
                              {this.props.flags['newProviderGender'] && (
                                <Select
                                  name="genderStatus"
                                  label="Gender Status"
                                  selectionMode="single"
                                  items={this.state.genderStatusOptions}
                                  onSelectionChange={([value]) => {
                                    setFieldValue('genderStatus', value);
                                  }}
                                  selectedKeys={
                                    values.genderStatus
                                      ? [values.genderStatus]
                                      : []
                                  }
                                  validation={validity('genderStatus', formik)}
                                >
                                  {(option) => {
                                    return (
                                      <Item key={option.value}>
                                        {option.label}
                                      </Item>
                                    );
                                  }}
                                </Select>
                              )}
                              <ComboBox
                                css={inputCss}
                                name="ethnicity"
                                label="Ethnicity"
                                optionalityText="Optional"
                                selectionMode="multiple"
                                items={this.state.ethnicityOptions}
                                onSelectionChange={(selected) => {
                                  setFieldValue(
                                    'ethnicity',
                                    Array.from(selected)
                                  );
                                }}
                                selectedKeys={values.ethnicity}
                                validation={validity('ethnicity', formik)}
                              >
                                {(option) => {
                                  return (
                                    <Item key={option.value}>
                                      {option.label}
                                    </Item>
                                  );
                                }}
                              </ComboBox>
                            </React.Fragment>
                          )}
                        </div>
                      </PageSection>
                    </Form>
                  </div>
                  <FormStickyFooter>
                    <Button
                      onPress={() => {
                        this.setState({
                          constructedFullNameArr:
                            this.state.initialConstructedFullNameArr,
                          displayNameIndices:
                            this.state.initialDisplayNameIndices,
                        });
                        resetForm();
                      }}
                      disabled={!dirty || isSubmitting}
                      size="large"
                      variant="secondary"
                    >
                      Cancel
                    </Button>

                    <Button
                      form="settings-profile"
                      type="submit"
                      disabled={!dirty || isSubmitting}
                      variant="primary"
                      size="large"
                    >
                      Save
                    </Button>
                  </FormStickyFooter>
                </>
              );
            }}
          />
        );
      }
    }
  )
);

export function Profile() {
  const newProviderGender = useFlag('newProviderGender');

  const flags = {
    newProviderGender,
  };
  return <ProfileImpl flags={flags} />;
}
