import { CalendarDate, getLocalTimeZone } from '@internationalized/date';
import moment from 'moment';
import * as Yup from 'yup';

import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';

const localTimeZone = getLocalTimeZone();

const getScheduleConfigValidationSchema = (
  schema: Yup.ObjectSchema = Yup.object()
) =>
  schema.shape({
    cadence: Yup.string()
      .required('Assessment frequency is required')
      .oneOf(Object.values(PatientAssessmentRecurrenceCadence)),
    startDate: Yup.date()
      .transform(
        (_value, originalValue: Date | CalendarDate | string | null) => {
          if (originalValue instanceof CalendarDate) {
            return originalValue.toDate(localTimeZone);
          }

          if (typeof originalValue === 'string') {
            return moment(originalValue).toDate();
          }

          return originalValue;
        }
      )
      .required('Select a date')
      .min(moment().startOf('day').toDate(), 'Date must be today or later'),
  });

export const getAssessmentScheduleValidationSchema = (
  schema: Yup.ObjectSchema = Yup.object()
) =>
  getScheduleConfigValidationSchema(schema).shape({
    assessmentType: Yup.string()
      .required('Assessment type is required')
      .oneOf(Object.values(PatientAssessmentType)),
  });

export const generateAssessmentSchedulesValidationSchema = (
  assessments?: PatientAssessmentType[]
): Yup.ObjectSchema => {
  const assessmentTypes = assessments || Object.values(PatientAssessmentType);
  return Yup.object().shape({
    enabledAssessmentTypes: Yup.array(),
    ...assessmentTypes.reduce(
      (acc, current: PatientAssessmentType) => {
        acc[current] = Yup.object().when(
          'enabledAssessmentTypes',
          (enabledTypes: PatientAssessmentType[], schema) => {
            return enabledTypes.includes(current)
              ? getScheduleConfigValidationSchema(schema)
              : schema;
          }
        );
        return acc;
      },
      {} as { [assessmentType in PatientAssessmentType]: Yup.ObjectSchema }
    ),
  });
};
