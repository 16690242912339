import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface useProviderProgressNoteErrorsQueryKeyArgs {
  providerAppointmentId: number;
  noteJson: string;
  noteJsonPrefilledFrom?: number;
}

export const getUseProviderProgressNoteErrorsQueryKey = ({
  providerAppointmentId,
  noteJson,
  noteJsonPrefilledFrom,
}: useProviderProgressNoteErrorsQueryKeyArgs): [
  string,
  number,
  string,
  number | undefined,
] => [
  'get-provider-progress-note-errors',
  providerAppointmentId,
  noteJson,
  noteJsonPrefilledFrom,
];

const {
  useSingleQuery: useProviderProgressNoteErrors,
  useListQuery: useProviderProgressNoteErrorsList,
  useCachedQuery: useProviderProgressNoteErrorsCache,
} = createBasicApiHooks(
  getUseProviderProgressNoteErrorsQueryKey,
  ({ providerAppointmentId, noteJson, noteJsonPrefilledFrom }) =>
    ProviderProgressNotesApi.getProviderProgressNoteErrors({
      provider_appointment_id: providerAppointmentId,
      note_json: noteJson,
      note_json_prefilled_from: noteJsonPrefilledFrom,
    }),
  ({ providerAppointmentId }) => !!providerAppointmentId,
  () => 'Failed to fetch provider progress note errors for this appointment'
);

export {
  useProviderProgressNoteErrors,
  useProviderProgressNoteErrorsList,
  useProviderProgressNoteErrorsCache,
};
