/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ANCHORSubmission {
    ANCHOR_1: number;

    ANCHOR_2: number;

    ANCHOR_3: number;

    assessmentType?: ANCHORSubmissionAssessmentType;

}


    export enum ANCHORSubmissionAssessmentType {
            ANCHOR = 'ANCHOR'
    }

