import { css } from '@emotion/react';
import { getIn, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useContext } from 'react';
import {
  Component,
  Section,
} from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/Renderer/v2/types';
import { isElementASection } from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/utils';

import { BodyText } from '@headway/helix/BodyText';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';

import {
  getErrors,
  TEMPLATE_ERROR_MAP_COMPONENT_LEVEL,
} from '../../Template/errors';
import { Step, TreatmentPlanMetadata } from '../../Template/types';
import { TreatmentPlanContext } from '../../TreatmentPlanContext';
import { Path, pathToString } from '../../TreatmentPlanUtils';

type PreviewVariant = 'small' | 'large';

interface ComponentPreviewProps {
  component: Component<TreatmentPlanMetadata>;
  path: Path;
  variant: PreviewVariant;
  isListItem?: boolean;
  label?: boolean;
  alert?: boolean;
  errors?: object;
}

export interface ErrorValueProps {
  variant: PreviewVariant;
  alert: boolean;
  error: string;
}
const ErrorValue = ({ variant, alert, error }: ErrorValueProps) => {
  const Text = variant === 'large' ? BodyText : SubBodyText;
  return (
    <span>
      {alert && (
        <IconWarningCircle
          color={theme.color.primary.red}
          css={missingValueCss.alert}
        />
      )}
      <Text color="gray">
        <span css={[alert ? missingValueCss.text : null]}>{error}</span>
      </Text>
    </span>
  );
};

const missingValueCss = {
  alert: css({ verticalAlign: 'text-top', marginRight: theme.spacing.x1 }),
  text: css({
    color: theme.color.primary.red,
  }),
};

const ComponentPreview = ({
  component,
  path,
  variant,
  isListItem = false,
  label = true,
  alert = false,
  errors = {},
}: ComponentPreviewProps) => {
  const { values } = useFormikContext<any>();
  let value = getIn(values, pathToString(path));
  const variantCss = componentPreviewCss[variant];
  const Text = variant === 'large' ? BodyText : SubBodyText;

  const componentError = getIn(errors, pathToString(path));
  const isMissingError =
    componentError ===
    TEMPLATE_ERROR_MAP_COMPONENT_LEVEL.MISSING_REQUIRED_COMPONENT[
      component.type
    ];

  // If checklist component has "Other" selected, remove "Other-" from the value and replace with "Other: "
  const hasOtherValue =
    component.type === 'checklist' &&
    value &&
    Array.isArray(value) &&
    value.includes('Other');
  if (hasOtherValue) {
    let valueCopy = [...value];
    const otherIndex = valueCopy.indexOf('Other');
    if (otherIndex !== -1) {
      // Remove Other from the list
      valueCopy.splice(otherIndex, 1);
    }

    const otherValueIndex = valueCopy.findIndex((value: string) =>
      value.startsWith('Other-')
    );
    if (otherValueIndex !== -1) {
      // Replace Other- with Other: in the list
      const splitValues = valueCopy[otherValueIndex].split('-');
      if (splitValues.length > 1) {
        valueCopy[otherValueIndex] = 'Other: ' + splitValues[1];
      }
      value = valueCopy;
    }
  }

  const content = (
    <>
      {label && (
        <Text>
          <span
            css={
              isListItem
                ? {}
                : {
                    fontWeight: 'bold',
                  }
            }
          >
            {component.title}
            {isListItem ? ': ' : ''}
          </span>
        </Text>
      )}
      {componentError ? (
        isMissingError ? (
          <ErrorValue
            variant={variant}
            alert={alert}
            error={
              Array.isArray(value ?? '') ? 'None selected' : 'None described'
            }
          />
        ) : (
          <ErrorValue variant={variant} alert={alert} error={componentError} />
        )
      ) : (
        value &&
        (Array.isArray(value) ? (
          <div css={variantCss.list}>
            {value.map((v, valueIndex) => (
              <Text key={valueIndex}>{v}</Text>
            ))}
          </div>
        ) : (
          <Text>
            {component.type === 'date' ? (
              moment(value).format('M/DD/YYYY')
            ) : (
              <span css={variantCss.value}>
                {component.type === 'dropdownDiagnosis'
                  ? `${value.value} ${value.display}`
                  : value}
              </span>
            )}
          </Text>
        ))
      )}
    </>
  );

  return isListItem ? (
    <li
      css={[
        variantCss.container,
        component.metadata?.indented ? variantCss.indented : null,
        variantCss.horizontal,
      ]}
    >
      <span>{content}</span>
    </li>
  ) : (
    <div
      css={[
        variantCss.container,
        component.metadata?.indented ? variantCss.indented : null,
      ]}
    >
      {content}
    </div>
  );
};

const componentPreviewCss = {
  small: {
    container: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x1,
    }),
    horizontal: css({
      display: 'list-item',
    }),
    list: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x1,
    }),
    indented: css({
      borderLeft: `1px solid ${theme.color.system.borderGray}`,
      paddingLeft: theme.spacing.x5,
    }),
    value: css({
      whiteSpace: 'pre-wrap',
    }),
  },
  large: {
    container: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x1,
    }),
    horizontal: css({
      flexDirection: 'row',
    }),
    list: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x1,
    }),
    indented: css({
      borderLeft: `1px solid ${theme.color.system.borderGray}`,
      paddingLeft: theme.spacing.x5,
    }),
    value: css({
      whiteSpace: 'pre-wrap',
    }),
  },
};

interface SectionPreviewProps {
  section: Section<TreatmentPlanMetadata>;
  path: Path;
  variant: PreviewVariant;
  isSubSection?: boolean;
  alert?: boolean;
  errors?: object;
}

const SectionPreview = ({
  section,
  path,
  variant,
  isSubSection = false,
  alert = false,
  errors = {},
}: SectionPreviewProps) => {
  const { values } = useFormikContext<any>();

  let multipleList = [];

  if (section.allowMultiple) {
    multipleList = getIn(values, pathToString(path)) ?? [];
  }
  const variantCss = sectionPreviewCss[variant];
  const Text = variant === 'large' ? BodyText : SubBodyText;

  return (
    <div
      css={[
        variantCss.list,
        section.metadata?.indented ? variantCss.indented : null,
      ]}
    >
      {multipleList.map((item: any, index: number) => (
        <div
          key={index}
          css={[
            variantCss.section,
            isSubSection ? variantCss.subSection : null,
          ]}
        >
          <Text>
            <strong>
              {section.header}
              {multipleList.length > 1 ? ` ${index + 1}` : ''}
            </strong>
          </Text>
          {(() => {
            const subComponents = section.components.map(
              (component, componentIndex) =>
                isElementASection(component) ? (
                  <SectionPreview
                    key={componentIndex}
                    section={component}
                    path={[...path, index, component.id!]}
                    variant={variant}
                    isSubSection
                    alert={alert}
                    errors={errors}
                  />
                ) : (
                  <ComponentPreview
                    key={componentIndex}
                    component={component as Component<TreatmentPlanMetadata>}
                    path={[...path, index, component.id!]}
                    variant={variant}
                    isListItem={isSubSection}
                    label={componentIndex > 0 || isSubSection}
                    alert={alert}
                    errors={errors}
                  />
                )
            );

            return isSubSection ? (
              <ul css={[variantCss.list, variantCss.subSectionList]}>
                {subComponents}
              </ul>
            ) : (
              <div css={variantCss.list}>{subComponents}</div>
            );
          })()}
        </div>
      ))}
    </div>
  );
};
const sectionPreviewCss = {
  small: {
    section: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x1,
    }),
    list: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x5,
    }),
    subSection: css({
      gap: theme.spacing.x1,
    }),
    subSectionList: css({
      gap: theme.spacing.x1,
      margin: 0,
      padding: `0 0 0 ${theme.spacing.x5}`,
    }),
    indented: css({
      borderLeft: `1px solid ${theme.color.system.borderGray}`,
      paddingLeft: theme.spacing.x5,
    }),
  },
  large: {
    section: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x3,
    }),
    list: css({
      ...theme.stack.vertical,
      gap: theme.spacing.x3,
    }),
    subSection: css({
      gap: theme.spacing.x1,
    }),
    subSectionList: css({
      gap: theme.spacing.x1,
      margin: 0,
      padding: 0,
    }),
    indented: css({
      borderLeft: `1px solid ${theme.color.system.borderGray}`,
      paddingLeft: theme.spacing.x5,
    }),
  },
};

interface StepPreviewProps {
  step: Step;
  variant: PreviewVariant;
  alert?: boolean;
  displayErrors?: boolean;
}

export const StepPreview = ({
  step,
  variant,
  alert = false,
  displayErrors = true,
}: StepPreviewProps) => {
  const { steps } = useContext(TreatmentPlanContext);
  const { values } = useFormikContext<any>();
  const errors = (displayErrors && values && getErrors(values, steps)) || {};

  return (
    <>
      {step.components.map((component, componentIndex) =>
        isElementASection(component) ? (
          <SectionPreview
            key={componentIndex}
            section={component}
            path={[component.id!]}
            variant={variant}
            alert={alert}
            errors={errors}
          />
        ) : (
          <ComponentPreview
            key={componentIndex}
            component={component as Component<TreatmentPlanMetadata>}
            path={[component.id!]}
            variant={variant}
            alert={alert}
            errors={errors}
          />
        )
      )}
    </>
  );
};
