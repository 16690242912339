import { useContext, useEffect } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';

import { InsuranceVerifyMatch } from 'views/Clients/InsuranceVerifyMatch';

import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';

export interface PatientInsuranceVerifyMatchProps {
  client: UserRead;
}

export const PatientInsuranceVerifyMatch = ({
  client,
}: PatientInsuranceVerifyMatchProps) => {
  const { setCurrentStep } = useContext(AddPatientModalContext);

  useEffect(() => {
    /*trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Client Billing Details',
        stepName: 'EXISTING_INSURANCE',
      },
    });
    */
  }, []);

  const onSuccess = () => {
    setCurrentStep(AddPatientModalPage.TELEHEALTH_LOCATIONS);
  };
  const onEdit = () => {
    setCurrentStep(AddPatientModalPage.BILLING_INFORMATION);
  };

  return (
    <>
      <ModalContent>
        <PageSection>
          <div>
            <SectionHeader>Did you mean this?</SectionHeader>
            <InsuranceVerifyMatch patient={client} />
          </div>
        </PageSection>
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={onEdit}>
          Go back and edit
        </Button>
        <Button variant="primary" onPress={onSuccess}>
          Yes, this is right
        </Button>
      </ModalFooter>
    </>
  );
};
