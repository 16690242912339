import { useCallback, useContext, useEffect } from 'react';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Modal } from '@headway/helix/Modal';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';

import { ScheduleRecurring } from 'views/Clients/Assessments/components/FillOutAssessments/ScheduleRecurring';

import { AssessmentCompletion } from './AssessmentCompletion';
import { AttestationFillForClient } from './AttestationFillForClient';
import {
  FillOutAssessmentForClientModalContextProvider,
  FillOutAssessmentModalContext,
  ManagementAssessmentStep,
} from './FillOutAssessmentForClientModalContext';
import { PerformAssessment } from './PerformAssessment';
import { SelectAssessments } from './SelectAssessments';

interface FillOutAssessmentForClientModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  clientId: number;
  providerPatient: ProviderPatientRead;
  selectedAssessmentToFill?: PatientAssessmentType | undefined;
}

interface FillOutAssessmentModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  client: UserRead;
  providerPatient: ProviderPatientRead;
  selectedAssessmentToFill?: PatientAssessmentType;
}

const FillOutAssessmentModal: React.FC<FillOutAssessmentModalProps> = ({
  isOpen,
  onDismiss,
  client,
  providerPatient,
  selectedAssessmentToFill,
}) => {
  const {
    currentStep,
    setCurrentStep,
    setSelectedAssessments,
    selectedAssessments,
  } = useContext(FillOutAssessmentModalContext);

  const clientName = formatPatientName(client);

  useEffect(() => {
    if (!selectedAssessments.length && selectedAssessmentToFill) {
      setCurrentStep(ManagementAssessmentStep.ATTESTATION);
      setSelectedAssessments([selectedAssessmentToFill]);
    } else {
      setCurrentStep(ManagementAssessmentStep.SELECT_ASSESSMENTS);
    }
  }, [selectedAssessmentToFill]);

  const handleDismiss = () => {
    setSelectedAssessments([]);
    onDismiss();
  };

  if (!currentStep || !providerPatient) {
    return null;
  }

  return (
    <Modal
      variant="fullscreen"
      isOpen={isOpen}
      onDismiss={handleDismiss}
      title={`Fill out assessments with ${clientName}`}
    >
      {currentStep === ManagementAssessmentStep.SELECT_ASSESSMENTS && (
        <SelectAssessments
          onDismiss={handleDismiss}
          client={client}
          providerPatient={providerPatient}
        />
      )}
      {currentStep === ManagementAssessmentStep.ATTESTATION && (
        <AttestationFillForClient
          clientName={clientName}
          providerPatient={providerPatient}
        />
      )}
      {currentStep === ManagementAssessmentStep.FILL_OUT_ASSESSMENTS && (
        <PerformAssessment
          assessmentTypesToComplete={selectedAssessments}
          clientName={clientName}
          providerPatientId={providerPatient.id}
          onCancel={onDismiss}
        />
      )}
      {currentStep === ManagementAssessmentStep.COMPLETE_ASSESSMENTS && (
        <AssessmentCompletion
          clientName={clientName}
          providerPatient={providerPatient}
        />
      )}
      {currentStep === ManagementAssessmentStep.SCHEDULE_RECURRING && (
        <ScheduleRecurring
          onDismiss={onDismiss}
          client={client}
          selectedAssessments={selectedAssessments}
        />
      )}
    </Modal>
  );
};

export const FillOutAssessmentForClientModal: React.FC<
  FillOutAssessmentForClientModalProps
> = ({
  isOpen,
  onDismiss,
  clientId,
  providerPatient,
  selectedAssessmentToFill,
}) => {
  const { data: client } = useUser({ userId: clientId });

  if (!client) {
    return null;
  }

  return (
    <FillOutAssessmentForClientModalContextProvider>
      <FillOutAssessmentModal
        isOpen={isOpen}
        onDismiss={onDismiss}
        client={client}
        providerPatient={providerPatient}
        selectedAssessmentToFill={selectedAssessmentToFill}
      />
    </FillOutAssessmentForClientModalContextProvider>
  );
};
