import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderDocumentRequestRead } from '@headway/api/models/ProviderDocumentRequestRead';

import { useDocumentationRequest } from '../../hooks/utils/useDocumentationRequest';
import { Rule } from '../Rule';
import { DocumentationRequestProgressNoteBanner } from './DocumentationRequestProgressNoteBanner';

export class DocumentationRequest extends Rule<
  [ProviderDocumentRequestRead | undefined]
> {
  enabled = [true];

  addDataHooks() {
    return [() => useDocumentationRequest()];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData || !hookData[0]) {
      return false;
    }

    return true;
  }

  get disabledProgressNoteTypes() {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    return {
      progressNote: {
        header: <DocumentationRequestProgressNoteBanner />,
      },
    };
  }

  isDocumentationRequired(): boolean {
    return true;
  }
}
