import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, Resolver, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { useProviderPatient } from '~/legacy/hooks';
import { useSessionDetailsEditability } from '~/legacy/hooks/useSessionDetailsEditability';
import { useAuthStore } from '~/legacy/stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from '~/legacy/utils/access';
import { AddressModalV2 } from '~/legacy/views/AppointmentConfirmation/components/modals/AddressModalV2';
import { ProgressNoteDownloadAgreementModal } from '~/legacy/views/Clients/ProgressNotesDownloads/ProgressNoteDownloadAgreementModal';

import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { Divider } from '@headway/helix/Divider';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { useMediaQuery } from '@headway/helix/utils';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';

import { isPast } from '../../../Calendar/events/util/events';
import { useInitialValues } from '../../hooks/useInitialValues';
import { useValidationSchema } from '../../hooks/useValidationSchema';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';
import { AppointmentConfirmationFooterV2 } from '../AppointmentConfirmationFooterV2';
import { AppointmentHasNotHappenedYetCard } from '../AppointmentHasNotHappenedYetCard';
import AutoSaveListener from '../AutoSaveListener';
import {
  AddendumsEditFormV2Values,
  AddendumsFormV2,
} from '../forms/AddendumsFormV2';
import {
  AppointmentAttachmentFormV2,
  AppointmentAttachmentFormV2Values,
} from '../forms/AppointmentAttachmentFormV2';
import { AppointmentAttestationFormV2 } from '../forms/AppointmentAttestationFormV2';
import { ProgressNoteHeaderV2 } from '../forms/ProgressNote/components/ProgressNoteHeaderV2';
import { ProgressNoteSignedTextV2 } from '../forms/ProgressNote/components/ProgressNoteSignedTextV2';
import {
  ProgressNoteFormV2,
  ProgressNoteFormV2Values,
} from '../forms/ProgressNote/ProgressNoteFormV2';
import { NonEditableSessionDetailsSectionV2 } from '../forms/SessionDetails/components/NonEditableSessionDetailsSectionV2';
import { SessionDetailsHeader } from '../forms/SessionDetails/components/SessionDetailsHeader';
import { SessionDetailsTopBanner } from '../forms/SessionDetails/components/SessionDetailsTopBanner';
import {
  SessionDetailsFormV2,
  SessionDetailsFormV2Values,
} from '../forms/SessionDetails/SessionDetailsFormV2';
import { GroupPracticeAdminBanner } from '../GroupPracticeAdminBanner';
import {
  TreatmentPlanRequirementWarningBanner,
  TreatmentPlanRequirementWarningV2,
} from '../TreatmentPlanRequirementWarningV2';
import { ComplianceGuideModalV2 } from './ComplianceGuideModalV2';
import { RequiredNoteSubmissionModalV2 } from './RequiredNoteSubmissionModalV2';
import { SubmitAddendumWithoutChangesModalV2 } from './SubmitAddendumWithoutChangesModalV2';

export interface AppointmentConfirmationModalV2Props {
  open?: boolean | undefined;
  onOpenContactFormInsuranceIssues: () => void;
  onProgressNoteUpdate?: () => void;
}

export const defaultInitialValues = {
  addendumsEdit: {},
  attachments: [],
  attestation: false,
  progressNote: {},
  sessionDetails: {},
};
export interface AppointmentConfirmationModalFormV2Values {
  sessionDetails: SessionDetailsFormV2Values;
  progressNote: ProgressNoteFormV2Values;
  attachments: AppointmentAttachmentFormV2Values;
  addendumsEdit: AddendumsEditFormV2Values;
  attestation: boolean;
}

export const AppointmentConfirmationModalV2: React.FC<
  React.PropsWithChildren<AppointmentConfirmationModalV2Props>
> = ({ open, onOpenContactFormInsuranceIssues, onProgressNoteUpdate }) => {
  const { user } = useAuthStore();
  const {
    patient,
    provider,
    event,
    isAutoSaving,
    setIsAutoSaving,
    progressNoteErrorsFromSubmitting,
    onCloseAppointmentConfirmationModal,
    isProgressNoteDownloadAgreementModalOpen,
    setIsProgressNoteDownloadAgreementModalOpen,
    isSubmitAddendumWithoutChangesModalOpen,
    setIsSubmitAddendumWithoutChangesModalOpen,
    isComplianceGuideModalOpen,
    setIsComplianceGuideModalOpen,
    isAddressModalOpen,
    setIsAddressModalOpen,
    isRequiredNoteSubmissionModalOpen,
    setIsRequiredNoteSubmissionModalOpen,
  } = useContext(AppointmentConfirmationContextV2);

  const { data: providerPatient } = useProviderPatient({
    providerId: provider?.id,
    patientId: patient?.id,
  });
  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId
  );
  const isSmallScreen = useMediaQuery('(max-width: 850px)');

  /*const trackButtonClicked = (
    name:
      | 'Confirm Session Button Clicked'
      | 'Confirm Session Detail Page Exited'
      | 'Modify Session Button Clicked'
      | 'Progress Note Submit Addendum Button Clicked'
      | 'Save and Close Button Clicked'
      | 'Save and Complete Later Button Clicked'
      | 'Sign Note Button Clicked'
  ) => {
    const properties = {
      providerId: event.providerAppointment!.providerId!,
      patientUserId: event.patientUserId!,
      providerAppointmentId: event.providerAppointment!.id,
      prefillSelected: !!metadataInfo?.noteJsonPrefilledFrom,
      progressNoteId: progressNote?.id,
      progressNoteRadio: progressNoteType,
      selectedTemplate: (progressNote?.noteJson as NoteJson)?.templateInfo
        ?.name,
    };

    if (name === 'Confirm Session Button Clicked') {
      const fullProperties: ConfirmSessionButtonClickedEvent['properties'] = {
        ...properties,
        nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
        selectedTemplate:
          progressNoteType === ProgressNoteType.TEMPLATE
            ? (progressNote?.noteJson as NoteJson)?.templateInfo?.name
            : undefined,
        telehealthAttestation:
          !!event.providerAppointment?.telehealthAttestation,
      };
      trackEvent({
        name,
        properties: fullProperties,
      });
    } else if (name === 'Sign Note Button Clicked') {
      const fullProperties: SignNoteButtonClickedEvent['properties'] = {
        ...properties,
        nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
      };
      trackEvent({
        name,
        properties: fullProperties,
      });
    } else {
      trackEvent({
        name,
        properties,
      });
    }
  };*/

  const [progressNoteTemplateInfo, setProgressNoteTemplateInfo] = useState<
    string | undefined
  >(undefined);
  const [cptCodes, setCptCodes] = useState<CPTCodeInfo[] | undefined>(
    undefined
  );

  const { initialValues, isInitialized } = useInitialValues();
  const validationSchema: Yup.ObjectSchema<AppointmentConfirmationModalFormV2Values> =
    useValidationSchema(progressNoteTemplateInfo, cptCodes);

  const methods = useForm<AppointmentConfirmationModalFormV2Values>({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    mode: 'onSubmit',
    resolver: yupResolver(
      validationSchema
    ) as Resolver<AppointmentConfirmationModalFormV2Values>,
  });

  // We have to watch those values this way so that we can then update the state
  // and re-trigger the call to useValidationSchema when those values change
  //const { reset, trigger, control } = methods;
  const watchedValues = useWatch<AppointmentConfirmationModalFormV2Values>({
    control: methods.control,
  });

  useEffect(() => {
    setProgressNoteTemplateInfo(watchedValues.progressNote?.template);
    setCptCodes(watchedValues.sessionDetails?.cptCodes as CPTCodeInfo[]);
  }, [
    watchedValues.progressNote?.template,
    watchedValues.sessionDetails?.cptCodes,
  ]);

  // Reset when initial values change
  useEffect(() => {
    if (isInitialized) {
      methods.reset(initialValues);
    }
  }, [isInitialized, initialValues, methods]);

  // Trigger when validationSchema changes with errors from submitting
  useEffect(() => {
    if (progressNoteErrorsFromSubmitting) {
      methods.trigger();
    }
  }, [validationSchema, progressNoteErrorsFromSubmitting, methods]);

  /*const handleClose = useCallback(() => {
    if (progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT) {
      setOpenDiscardUnsavedChangesModal(true);
    } else {
      //trackButtonClicked('Confirm Session Detail Page Exited');
      onClose();
    }
  }, [onClose, progressNoteState]);*/

  const modalTitle = useMemo(() => {
    return event &&
      isPast(event) &&
      editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED
      ? 'Confirm session details'
      : 'Session details';
  }, [event, editabilityStatus]);

  const isGroupAdminImpersonating: boolean = useMemo(
    () => isGroupAdminImpersonatingProvider(provider, user),
    [user, provider]
  );

  const canEditSessionDetails = useMemo(
    () => editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED,
    [editabilityStatus]
  );

  return (
    <div>
      <FormProvider {...methods}>
        <form id="appointmentConfirmationModalForm">
          <Modal
            title={modalTitle}
            onDismiss={onCloseAppointmentConfirmationModal}
            isOpen={open}
            variant="fullscreen"
            layout={`${isSmallScreen ? 'contained' : 'full-bleed'}`}
            disableTrapFocus
          >
            <ModalContent css={{ justifyContent: 'center' }}>
              {isGroupAdminImpersonating && <GroupPracticeAdminBanner />}
              <TreatmentPlanRequirementWarningBanner />
              <div
                className={`${
                  isSmallScreen ? 'w-auto' : 'ml-auto mr-auto w-[850px]'
                }`}
              >
                <AutoSaveListener
                  isInitialized={isInitialized}
                  setIsAutoSaving={(value) => setIsAutoSaving(value)}
                />
                <SessionDetailsHeader />
                <SessionDetailsTopBanner />
                {canEditSessionDetails ? (
                  <SessionDetailsFormV2
                    onOpenContactFormInsuranceIssues={
                      onOpenContactFormInsuranceIssues
                    }
                  />
                ) : (
                  <NonEditableSessionDetailsSectionV2 />
                )}
                <Divider />
                <ProgressNoteHeaderV2 />
                <ProgressNoteFormV2 providerPatient={providerPatient} />
                <AppointmentAttachmentFormV2 />
                <AddendumsFormV2 />
                <AppointmentHasNotHappenedYetCard />
                <ProgressNoteSignedTextV2 />
                <AppointmentAttestationFormV2 />
                <TreatmentPlanRequirementWarningV2 />
              </div>
              <ModalFooter>
                <AppointmentConfirmationFooterV2 isAutoSaving={isAutoSaving} />
              </ModalFooter>
            </ModalContent>
          </Modal>
          <AddressModalV2
            open={isAddressModalOpen}
            onClose={() => setIsAddressModalOpen(false)}
          />
          <ComplianceGuideModalV2
            open={isComplianceGuideModalOpen}
            onClose={() => setIsComplianceGuideModalOpen(false)}
          />
          <SubmitAddendumWithoutChangesModalV2
            open={isSubmitAddendumWithoutChangesModalOpen}
            onClose={() => setIsSubmitAddendumWithoutChangesModalOpen(false)}
          />
          <ProgressNoteDownloadAgreementModal
            open={isProgressNoteDownloadAgreementModalOpen}
            onClose={async () =>
              setIsProgressNoteDownloadAgreementModalOpen(false)
            }
            providerId={provider?.id!}
            clientId={patient?.id!}
            providerAppointmentIds={[event?.providerAppointment?.id!]}
          />
          <RequiredNoteSubmissionModalV2
            open={isRequiredNoteSubmissionModalOpen}
            onClose={() => setIsRequiredNoteSubmissionModalOpen(false)}
          />
        </form>
      </FormProvider>
    </div>
  );
};
