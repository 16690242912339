import { useContext, useMemo } from 'react';
import * as Yup from 'yup';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';

import { AppointmentConfirmationModalFormV2Values } from '../components/modals/AppointmentConfirmationModalV2';
import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../stores/AppointmentConfirmationContextV2';
import { useAddendumsEditValidationSchema } from './validationSchemas/useAddendumsEditValidationSchema';
import { useAttachmentsValidationSchema } from './validationSchemas/useAttachmentsValidationSchema';
import { useAttestationValidationSchema } from './validationSchemas/useAttestationValidationSchema';
import { useProgressNotesValidationSchema } from './validationSchemas/useProgressNotesValidationSchema';
import { useSessionDetailsValidationSchema } from './validationSchemas/useSessionDetailsValidationSchema';

export const useValidationSchema = (
  progressNoteTemplateInfo?: string,
  cptCodes?: CPTCodeInfo[]
): Yup.ObjectSchema<AppointmentConfirmationModalFormV2Values> => {
  const { event, patient, progressNoteState, progressNoteType } = useContext(
    AppointmentConfirmationContextV2
  );
  const sessionDetailsValidationSchema = useSessionDetailsValidationSchema(
    event,
    patient,
    progressNoteType,
    progressNoteTemplateInfo
  );
  const progressNotesValidationSchema = useProgressNotesValidationSchema(
    progressNoteTemplateInfo,
    cptCodes
  );
  const attachmentsValidationSchema = useAttachmentsValidationSchema();
  const addendumsEditValidationSchema = useAddendumsEditValidationSchema();
  const attestationValidationSchema = useAttestationValidationSchema();

  return useMemo(() => {
    const isUploadOrNone =
      progressNoteType === ProgressNoteType.UPLOAD ||
      progressNoteType === ProgressNoteType.NONE;
    const isTemplateSavedOrSigned =
      progressNoteType === ProgressNoteType.TEMPLATE &&
      (progressNoteState === ProgressNoteState.SAVED_FOR_LATER ||
        progressNoteState === ProgressNoteState.SIGNED);
    const isEditingTemplate =
      progressNoteType === ProgressNoteType.TEMPLATE &&
      progressNoteState === ProgressNoteState.EDITING;
    const isAddendumEditing =
      progressNoteState === ProgressNoteState.ADDENDUM_EDITING;
    const isAttestationRequired =
      progressNoteState === ProgressNoteState.SIGNED ||
      progressNoteState === ProgressNoteState.SAVED_FOR_LATER ||
      (progressNoteType !== ProgressNoteType.TEMPLATE &&
        !(progressNoteState === ProgressNoteState.ADDENDUM_EDITING));

    return Yup.object({
      sessionDetails:
        isUploadOrNone || isTemplateSavedOrSigned
          ? sessionDetailsValidationSchema.fields.sessionDetails
          : Yup.object().shape({}),
      progressNote: isEditingTemplate
        ? progressNotesValidationSchema.fields.progressNote
        : Yup.object().shape({}),
      attachments:
        progressNoteType === ProgressNoteType.UPLOAD
          ? attachmentsValidationSchema.fields.attachments
          : Yup.array().notRequired(),
      addendumsEdit: isAddendumEditing
        ? addendumsEditValidationSchema.fields.addendumsEdit
        : Yup.object().shape({}),
      attestation: isAttestationRequired
        ? attestationValidationSchema.fields.attestation
        : Yup.boolean().notRequired(),
    }) as Yup.ObjectSchema<AppointmentConfirmationModalFormV2Values>;
  }, [
    progressNoteType,
    progressNoteState,
    progressNotesValidationSchema,
    sessionDetailsValidationSchema,
    attachmentsValidationSchema,
    addendumsEditValidationSchema,
    attestationValidationSchema,
  ]);
};
