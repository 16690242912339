import { useProvider } from 'hooks';
import { useContext } from 'react';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { trackEvent } from '@headway/shared/utils/analytics';

import { NoteJson } from '../../components/forms/ProgressNote/Template/Renderer/types';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const PausedPaymentsRequirementBanner = ({
  patient,
}: {
  patient?: UserRead;
}) => {
  const provider = useProvider();
  const { progressNote } = useContext(AppointmentConfirmationContextV2);

  return (
    <GuidanceCard variant="warning">
      <div className="flex flex-col gap-2">
        <BodyText>
          Reminder: With payments on hold, Headway will review your
          documentation before issuing payment to ensure your notes meet
          insurance compliance standards.
        </BodyText>
        <LinkButton
          variant="link"
          href="https://headway.co/resources/behavioral-health-compliance"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            trackEvent({
              name: 'Progress Notes Learn More Button Clicked',
              properties: {
                providerId: progressNote?.providerId || provider?.id,
                patientUserId: progressNote?.patientId || patient?.id,
                providerAppointmentId:
                  progressNote?.providerAppointmentId || null,
                prefillSelected:
                  /* TODO(Sunny): add to context -> !!metadataInfo?.noteJsonPrefilledFrom */ false,
                progressNoteId: progressNote?.id,
                progressNoteRadio:
                  /* TODO(Sunny) add to context -> progressNoteType*/ ProgressNoteType.TEMPLATE,
                selectedTemplate: (progressNote?.noteJson as NoteJson)
                  ?.templateInfo?.name,
              },
            });
          }}
        >
          Learn about best practices
        </LinkButton>
      </div>
    </GuidanceCard>
  );
};
