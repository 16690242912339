import { useMutation } from '@tanstack/react-query';
import { Message, MessagesApi } from '~/legacy/api/MessagesApi';
import { useProvider } from '~/legacy/hooks';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { toasts } from '@headway/helix/Toast';

/** Sends a message to a patient about an event. */
export const useNotifyForEventMutation = () => {
  const provider = useProvider();

  return useMutation(
    async ({
      event,
      content,
      type,
      additionalFields,
    }: {
      event: ProviderEventRead | undefined;
      content: string;
      type: keyof typeof Message.Type;
      additionalFields: { is_recurring_appointment_cancellation: boolean };
    }) => {
      if (!event) {
        return;
      }

      // don't notify patients of custom billers
      if (provider.customBilling) {
        return;
      }
      await MessagesApi.create({
        patient_user_id: event.patientUserId,
        provider_id: event.providerId,
        message: {
          type,
          to_provider: false,
          content,
          special: false,
          provider_event_id: event.id,
        },
        additional_fields: additionalFields,
      });
    },
    {
      onError: (error: Error) => {
        toasts.add(error.toString(), { variant: 'negative' });
      },
    }
  );
};
