import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { statesRequiringCDS } from '../../../../utils/providerQuestionnaire';
import { RestrictedPracticeAuthority } from '../../utils/supervisingPhysicianConfig';
import {
  IDCertificationType,
  ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep,
} from './helpers';
import { IDCertificationForm } from './IDCertificationForm';
import { StateSupervisingPhysiciansForm } from './StateSupervisingPhysiciansForm';

export const StateCertsForm = ({
  state,
  initialValues,
  formValues,
  supervisingPhysicianRequirement,
  includeReuseFirstSupervisingPhysicianOption,
}: {
  state: UnitedStates;
  initialValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
  formValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
  supervisingPhysicianRequirement: RestrictedPracticeAuthority;
  includeReuseFirstSupervisingPhysicianOption: boolean;
}) => {
  const isStateRequiringCDS = statesRequiringCDS.has(state);

  return (
    <div
      css={{
        ...theme.stack.vertical,
        gap: theme.spacing.x2,
        borderBottom: `1px solid ${theme.color.system.borderGray}`,
        ':last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <SectionHeader>{statesToDisplayNames[state]}</SectionHeader>
      <IDCertificationForm
        state={state}
        idCertType={IDCertificationType.DEA}
        initialValues={initialValues}
      />
      {isStateRequiringCDS && (
        <IDCertificationForm
          state={state}
          idCertType={IDCertificationType.CDS}
          initialValues={initialValues}
        />
      )}
      {supervisingPhysicianRequirement !== RestrictedPracticeAuthority.FULL && (
        <StateSupervisingPhysiciansForm
          state={state}
          formValues={formValues}
          supervisingPhysicianRequirement={supervisingPhysicianRequirement}
          includeReuseFirstSupervisingPhysicianOption={
            includeReuseFirstSupervisingPhysicianOption
          }
        />
      )}
    </div>
  );
};
