import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface useValidProviderProgressNoteQueryKeyArgs {
  providerAppointmentId: number;
}

export const getUseValidProviderProgressNoteQueryKey = ({
  providerAppointmentId,
}: useValidProviderProgressNoteQueryKeyArgs): [string, number] => [
  'get-valid-provider-progress-note',
  providerAppointmentId,
];

const {
  useSingleQuery: useValidProviderProgressNote,
  useListQuery: useValidProviderProgressNoteList,
  useCachedQuery: useValidProviderProgressNoteCache,
} = createBasicApiHooks(
  getUseValidProviderProgressNoteQueryKey,
  ({ providerAppointmentId }) =>
    ProviderProgressNotesApi.getValidProviderProgressNote(
      providerAppointmentId
    ),
  ({ providerAppointmentId }) => !!providerAppointmentId,
  () => 'Failed to fetch the valid provider progress note for this appointment'
);

export {
  useValidProviderProgressNote,
  useValidProviderProgressNoteList,
  useValidProviderProgressNoteCache,
};
