/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { EmailPreviewResponse } from '../models/EmailPreviewResponse';
import { GetPatientConfirmabilityBody } from '../models/GetPatientConfirmabilityBody';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { IsPromsScheduleRequiredResponse } from '../models/IsPromsScheduleRequiredResponse';
import { ProviderPatientAttachmentCreate } from '../models/ProviderPatientAttachmentCreate';
import { ProviderPatientAttachmentRead } from '../models/ProviderPatientAttachmentRead';
import { ProviderPatientAttachmentType } from '../models/ProviderPatientAttachmentType';
import { ProviderPatientCreate } from '../models/ProviderPatientCreate';
import { ProviderPatientRead } from '../models/ProviderPatientRead';
import { ProviderPatientUpdate } from '../models/ProviderPatientUpdate';
import { SendProviderPatientAccountInviteRequestBody } from '../models/SendProviderPatientAccountInviteRequestBody';
import { SessionDetailsConfirmabilityResponse } from '../models/SessionDetailsConfirmabilityResponse';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderPatientApiResource {

    /**
     * Create Provider Patient
     * 
     * @param providerPatientCreate 
     
     */
    public createProviderPatient(body: ProviderPatientCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderPatientRead> {
        const apiRoute = '/provider-patients';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Provider Patient Attachment
     * 
     * @param providerPatientId 
     * @param providerPatientAttachmentCreate 
     
     */
    public createProviderPatientAttachment(providerPatientId: number, body: ProviderPatientAttachmentCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderPatientAttachmentRead> {
        const apiRoute = '/provider-patients/{provider_patient_id}/attachments';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Provider Patient Attachment
     * 
     * @param providerPatientId 
     * @param attachmentId 
     
     */
    public deleteProviderPatientAttachment(providerPatientId: number, attachmentId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-patients/{provider_patient_id}/attachments/{attachment_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId))
                    .replace('{' + 'attachment_id}', String(attachmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Is Proms Schedule Required
     * 
     * @param providerPatientId 
     
     */
    public getIsPromsScheduleRequired(providerPatientId: number, axiosConfig?: AxiosRequestConfig): Promise<IsPromsScheduleRequiredResponse> {
        const apiRoute = '/provider-patients/{provider_patient_id}/is-proms-schedule-required';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Confirmabilities
     * 
     * @param providerId 
     * @param getPatientConfirmabilityBody 
     
     */
    public getPatientConfirmabilities(providerId: number, body: GetPatientConfirmabilityBody, axiosConfig?: AxiosRequestConfig): Promise<{ [key: string]: SessionDetailsConfirmabilityResponse; }> {
        const apiRoute = '/provider/{provider_id}/patients/get-patient-confirmabilities';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Invitation Email Preview
     * 
     * @param providerPatientId 
     
     */
    public getPatientInvitationEmailPreview(providerPatientId: number, axiosConfig?: AxiosRequestConfig): Promise<EmailPreviewResponse> {
        const apiRoute = '/provider-patients/{provider_patient_id}/patient-invitation-email-preview';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Patient
     * 
     * @param providerPatientId 
     
     */
    public getProviderPatient(providerPatientId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderPatientRead> {
        const apiRoute = '/provider-patients/{provider_patient_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Patient Attachments
     * 
     * @param providerPatientId 
     * @param attachmentType 
     
     */
    public getProviderPatientAttachments(providerPatientId: number, query?: { attachment_type?: ProviderPatientAttachmentType }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderPatientAttachmentRead>> {
        const apiRoute = '/provider-patients/{provider_patient_id}/attachments';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Patient By Ids
     * 
     * @param providerId 
     * @param patientUserId 
     
     */
    public getProviderPatientByIds(query?: { provider_id: number, patient_user_id: number }, axiosConfig?: AxiosRequestConfig): Promise<ProviderPatientRead> {
        const apiRoute = '/provider-patients';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Provider Patient Account Invite
     * 
     * @param providerPatientId 
     * @param sendProviderPatientAccountInviteRequestBody 
     
     */
    public sendProviderPatientAccountInvite(providerPatientId: number, body?: SendProviderPatientAccountInviteRequestBody, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-patients/{provider_patient_id}/send-account-invite-email';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Patient
     * 
     * @param providerPatientId 
     * @param providerPatientUpdate 
     
     */
    public updateProviderPatient(providerPatientId: number, body: ProviderPatientUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderPatientRead> {
        const apiRoute = '/provider-patients/{provider_patient_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderPatientApi = new ProviderPatientApiResource();
