// Recursive function to get paths of an object, accounting for nested objects
export const getObjectPaths = (obj: any, prefix = ''): string[] => {
  return Object.keys(obj).reduce((paths, key) => {
    const path = prefix ? `${prefix}.${key}` : key;
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      // Recursively gather paths if the property is a nested object
      return paths.concat(getObjectPaths(obj[key], path));
    } else {
      return paths.concat(path);
    }
  }, [] as string[]);
};
