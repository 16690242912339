import { PrivacyTip } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { useCallback, useContext, useMemo } from 'react';
import { getUsePatientAddressesQueryKey } from '~/legacy/hooks/usePatientAddresses';

import { Modal, ModalContent } from '@headway/helix/Modal';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { getUseUserQueryKey } from '@headway/shared/hooks/useUser';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { theme } from '@headway/ui/theme';

import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';
import { PatientAddressFormV2 } from '../PatientAddressFormV2';

export interface AddressModalV2Props {
  open: boolean;
  onClose: () => void;
  isActiveAddress?: boolean;
}

export const AddressModalV2 = ({
  open,
  onClose,
  isActiveAddress = true,
}: AddressModalV2Props) => {
  const { patient, provider, event } = useContext(
    AppointmentConfirmationContextV2
  );
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const queryClient = useQueryClient();
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const modalTitle = useMemo(
    () =>
      isMSCEnabled ? `Add location for ${patientFirstName}` : 'New Address',
    [isMSCEnabled, patientFirstName]
  );

  const handleOnClose = useCallback(() => {
    if (provider && patient) {
      trackEvent({
        name: 'Provider Skip Telehealth Button Clicked',
        properties: {
          providerId: provider.id,
          providerPatientId: patient.id,
          providerAppointmentId: event?.providerAppointment?.id,
        },
      });
    }
    onClose();
  }, [event?.providerAppointment?.id, onClose, provider, patient]);

  const handleOnSuccess = useCallback(() => {
    if (provider && patient) {
      trackEvent({
        name: 'Provider Save and Verify Telehealth Button Clicked',
        properties: {
          providerId: provider.id,
          providerPatientId: patient.id,
          providerAppointmentId: event?.providerAppointment?.id,
        },
      });
    }
    queryClient.invalidateQueries(
      getUsePatientAddressesQueryKey({ patientId: patient?.id })
    );
    queryClient.invalidateQueries(getUseUserQueryKey({ userId: patient?.id! }));
    queryClient.invalidateQueries(['patient-insurance-status']);
    onClose();
  }, [event?.providerAppointment?.id, onClose, provider, patient, queryClient]);

  return (
    <Modal title={modalTitle} isOpen={open} onDismiss={handleOnClose}>
      <ModalContent>
        <Alert
          color="info"
          icon={<PrivacyTip />}
          css={{ marginBottom: theme.space.base }}
        >
          {isMSCEnabled
            ? `We need to know where ${patientFirstName} is located to submit a claim. You must be licensed and credentialed on Headway in the state where your client is located during each session.`
            : `We ask for a home address because the insurance company requires residence verification for eligibility and claims. We will not use this information otherwise, nor will we share it with anyone besides the insurance and provider.`}
        </Alert>
        <PatientAddressFormV2
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_ID as string}
          onSuccess={handleOnSuccess}
          isActiveAddress={isActiveAddress}
        />
      </ModalContent>
    </Modal>
  );
};
