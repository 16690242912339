/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WHODAS2Submission {
    WHODAS_2_1: number;

    WHODAS_2_10: number;

    WHODAS_2_11: number;

    WHODAS_2_12: number;

    WHODAS_2_2: number;

    WHODAS_2_3: number;

    WHODAS_2_4: number;

    WHODAS_2_5: number;

    WHODAS_2_6: number;

    WHODAS_2_7: number;

    WHODAS_2_8: number;

    WHODAS_2_9: number;

    assessmentType?: WHODAS2SubmissionAssessmentType;

}


    export enum WHODAS2SubmissionAssessmentType {
            WHODAS2 = 'WHODAS2'
    }

