import { CheckCircle } from '@mui/icons-material';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';

import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { trackEvent } from '@headway/shared/utils/analytics';
import { LogoLoader } from '@headway/ui/LogoLoader';
import { PropertyList, PropertyListItem } from '@headway/ui/PropertyList';

import { useUserInsurance } from 'hooks/useUserInsurance';

import { formatFirstName } from '../utils/addPatientModalUtils';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';

export interface PatientInsuranceReviewIntakeFlowProps {
  client: UserRead;
}

export const PatientInsuranceReviewIntakeFlow = ({
  client,
}: PatientInsuranceReviewIntakeFlowProps) => {
  const { setCurrentStep } = useContext(AddPatientModalContext);

  useEffect(() => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Client Billing Details',
        stepName: 'EXISTING_INSURANCE',
      },
    });
  }, []);

  const { data: userInsurance, isLoading: isUserInsuranceLoading } =
    useUserInsurance<UserInsuranceRead>({
      userInsuranceId: client.activeUserInsuranceId,
    });

  const firstName = formatFirstName(client);

  const handleAddingExistingUserSubmit = () => {
    setCurrentStep(AddPatientModalPage.TELEHEALTH_LOCATIONS);
  };

  const handleUpdateExistingUserSubmit = () => {
    setCurrentStep(AddPatientModalPage.BILLING_INFORMATION);
  };

  return isUserInsuranceLoading ? (
    <div className="mt-8 flex flex-col items-center gap-8">
      <SectionHeader>Saving...</SectionHeader>
      <div>
        <LogoLoader />
      </div>
    </div>
  ) : (
    <>
      <ModalContent>
        <PageSection>
          <div>
            <div css={{ marginTop: theme.spacing.x2 }}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: theme.spacing.x6,
                }}
              >
                <CheckCircle
                  css={{
                    color: theme.color.system.green,
                    marginRight: theme.spacing.x1,
                  }}
                />
                <strong>{`${firstName} has been added to your practice! We already have their insurance on file, but free feel to review/make any changes necessary.`}</strong>
              </div>

              <SectionHeader>
                {client ? `${firstName}'s Billing Details` : 'Billing Details'}
              </SectionHeader>
              <div
                css={{
                  borderRadius: 6,
                  border: `1px solid ${theme.color.system.borderGray}`,
                  padding: theme.spacing.x4,
                  marginTop: theme.spacing.x6,
                  marginBottom: theme.spacing.x4,
                }}
              >
                <PropertyList>
                  <PropertyListItem
                    label="Carrier"
                    value={userInsurance?.frontEndCarrierName}
                    data-testid="insuranceCarrierItem"
                  />
                  <PropertyListItem
                    label="Member name"
                    value={`${userInsurance?.firstName} ${userInsurance?.lastName}`}
                    data-testid="insuranceMemberNameItem"
                  />
                  <PropertyListItem
                    label="Member ID"
                    value={userInsurance?.memberId}
                    data-testid="insuranceMemberIdItem"
                  />
                  <PropertyListItem
                    label="Group number"
                    value={userInsurance?.groupNumber || '-'}
                    data-testid="insuranceGroupNumberItem"
                  />
                  <PropertyListItem
                    label="Date of birth"
                    data-testid="insuranceDobItem"
                    value={
                      userInsurance?.dob
                        ? moment(userInsurance?.dob).format('M/DD/YYYY')
                        : ''
                    }
                  />
                  <p
                    css={{
                      columnSpan: 'all',
                      color: theme.color.system.textBlack,
                      margin: `${theme.spacing.x2} 0 0`,
                    }}
                  >
                    Make sure this information matches that of the person being
                    seen, even if they’re a minor.
                  </p>
                </PropertyList>
              </div>
            </div>
          </div>
        </PageSection>
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={handleUpdateExistingUserSubmit}>
          Update
        </Button>
        <Button variant="primary" onPress={handleAddingExistingUserSubmit}>
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};
