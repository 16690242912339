import moment from 'moment';

import { ModalityRead } from '@headway/api/models/ModalityRead';
import { Practice } from '@headway/api/models/Practice';
import { ProviderFunction } from '@headway/api/models/ProviderFunction';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderUpdate } from '@headway/api/models/ProviderUpdate';
import { ReviewStatus } from '@headway/api/models/ReviewStatus';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { AgeGroupApi } from '@headway/api/resources/AgeGroupApi';
import { ProviderAddressApi } from '@headway/api/resources/ProviderAddressApi';
import { ProviderAgeGroupApi } from '@headway/api/resources/ProviderAgeGroupApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderFilterApi } from '@headway/api/resources/ProviderFilterApi';
import { ProviderModalityApi } from '@headway/api/resources/ProviderModalityApi';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { ProviderSpecialtyApi } from '@headway/api/resources/ProviderSpecialtyApi';
import { isProviderGender } from '@headway/shared/constants/gender';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  getAllPostnomials,
  getLowestLevelLicense,
  prescriberLicenseTypes,
} from '@headway/shared/utils/licenseHelper';
import { licenseTypes } from '@headway/shared/utils/marketLicenseTypes';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import {
  doesProviderAddressExist,
  getHeadwayEthnicities,
  issues,
} from '@headway/shared/utils/providerQuestionnaire';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

export const handleConfirmSubmitQuestionnaire = async (
  providerQuestionnaire: ProviderQuestionnaireReadV2,
  provider: ProviderRead,
  hasIncompleteCoiVerifications: boolean,
  specialties: SpecialtyRead[],
  modalities: ModalityRead[]
): Promise<void> => {
  await ProviderQuestionnaireApi.updateProviderQuestionnaire(
    providerQuestionnaire.id,
    {
      reviewStatus: hasIncompleteCoiVerifications
        ? ReviewStatus.NEEDS_REVIEW
        : ReviewStatus.ACCEPTED,
    }
  );

  await updateProviderQuestionnaireDependencies(
    provider,
    providerQuestionnaire,
    specialties,
    modalities
  );

  await ProviderQuestionnaireApi.submitProviderQuestionnaire(
    providerQuestionnaire.id
  );
};

export const updateProviderQuestionnaireDependencies = async (
  provider: ProviderRead,
  providerQuestionnaire: ProviderQuestionnaireReadV2,
  specialties: SpecialtyRead[],
  modalities: ModalityRead[]
) => {
  try {
    const updatedProvider = await addFormDataToProvider(
      provider,
      providerQuestionnaire
    );

    // TODO - We should probably have one backend endpoint that does all of these
    const promises = [
      createProviderSpecialtiesFromFormData(
        provider.id,
        providerQuestionnaire,
        specialties
      ),
      createProviderModalitiesFromFormData(
        provider.id,
        providerQuestionnaire,
        modalities
      ),
      ProviderApi.updateProviderRegionViaProviderAddress(provider.id),
    ];
    if (providerQuestionnaire?.rawData?.practicePopulations) {
      promises.push(
        createProviderAgeGroupsFromFormData(provider.id, providerQuestionnaire)
      );
    }

    await Promise.all(promises);

    return updatedProvider;

    // this.props.AuthStore.setProvider(provider);
  } catch (error: AnyTS4TryCatchUnknownError) {
    notifyError('Failed to process and submit questionnaire data');
    logException(error);
  }
};

export const addFormDataToProvider = async (
  provider: ProviderRead,
  providerQuestionnaire: ProviderQuestionnaireReadV2
) => {
  const { rawData } = providerQuestionnaire;
  const { licenseType } = getLowestLevelLicense(
    rawData?.selectedLicenses,
    getSupportedStates(provider)
  );
  const education = rawData?.education || [];
  // Note that we don't pass in CAQH password or SSN here because those values are cleared by the time
  // we get to the submission step because we redact these PHI values from the UI after the user saves
  // each step of the intake form. If there were changes made to those fields, they would be saved in the
  // ProviderQuestionnaire. We'll update the provider CAQH password and SSN according to those values in
  // the backend.
  const providerUpdatePayload: ProviderUpdate = {
    gender: isProviderGender(rawData?.gender) ? rawData?.gender : undefined,
    dob: moment(rawData?.birthDate).format('YYYY-MM-DD'),
    phone: rawData?.personalPhone,
    afterHoursPhone: rawData?.confidentialVoicemail,
    afterHoursAgreementDate: rawData?.confidentialVoicemailAcknowledgement
      ? new Date().toISOString()
      : undefined,
    smsPhone: rawData?.personalPhone,
    school: education.length > 0 ? education[0].institutionName : undefined,
    degreeType: education.length > 0 ? education[0].degreeType : undefined,
    school2: education.length > 1 ? education[1].institutionName : undefined,
    degreeType2: education.length > 1 ? education[1].degreeType : undefined,
    postnomials: getAllPostnomials(rawData?.selectedLicenses),
    providerFunction: prescriberLicenseTypes.includes(licenseType)
      ? rawData?.prescriberOfferings
      : ProviderFunction.TALK_THERAPY,
    npi: rawData?.npi,
    ethnicity: getHeadwayEthnicities(
      rawData?.race || [],
      rawData?.ethnicity || ''
    ),
    caqhNumber: rawData?.caqhNumberFromNpi,
    caqhUsername: rawData?.caqhUsername,
    taxonomyCode: licenseTypes[licenseType].taxonomyCode,
    taxId: rawData?.ein || undefined,
    languages: rawData?.languages,
    lastAttestedOn: moment().toISOString(),
    licenseType: licenseType,
  };

  const updatedProvider = await ProviderApi.updateProvider(
    provider.id,
    providerUpdatePayload
  );

  await maybeCreateProviderAddresses({ rawData, provider });

  if (rawData?.practiceFocusAreas) {
    const filterIds = [
      ...new Set(
        rawData?.practiceFocusAreas.map(
          (focusArea: string) => issues[focusArea]
        )
      ),
    ].filter((filter) => !!filter);
    const providerFilters = await ProviderFilterApi.getProviderFilters({
      provider_id: provider.id,
    });
    for (let index = 0; index < filterIds.length; index++) {
      const doesProviderFilterExist = providerFilters.find(
        (providerFilter) => providerFilter.filterId === filterIds[index]
      );
      if (!doesProviderFilterExist) {
        await ProviderFilterApi.createProviderFilter({
          providerId: provider.id,
          filterId: filterIds[index] as number,
        });
      }
    }
  }

  return updatedProvider;
};

export async function maybeCreateProviderAddresses({
  rawData,
  provider,
}: {
  rawData?: ProviderQuestionnaireRawData;
  provider: ProviderRead;
}) {
  // For MSC providers, if the provider did not select any practices to use with Headway, return early
  if (!rawData?.practice?.length) {
    return;
  }

  // For non-MSC providers, if the provider selected they do not have a practice, return early
  if (rawData?.hasPractice === 'NO') {
    return;
  }

  const providerAddresses = await ProviderAddressApi.getAllProviderAddresses({
    provider_ids: [provider.id],
  });
  for (let index = 0; index < rawData.practice.length; index++) {
    const address: Practice = rawData.practice[index];
    const addressExists = providerAddresses.find((providerAddress) =>
      doesProviderAddressExist(providerAddress, address)
    );
    if (!addressExists) {
      const addressState = abbreviationToStateEnum[address.state];
      const providerLicenseStateId = provider.activeProviderLicenseStates.find(
        (pls) => pls.state === addressState
      )?.id;
      const telehealthStates = rawData?.telehealthStates ?? {};
      // @ts-ignore
      if (providerLicenseStateId && !telehealthStates[addressState]) {
        await ProviderAddressApi.createProviderAddress({
          providerId: provider.id,
          providerLicenseStateId,
          streetLine1: address.street1,
          streetLine2: address.street2,
          city: address.city,
          state: address.state,
          zipCode: address.zip,
        });
      }
    }
  }
}

const createProviderSpecialtiesFromFormData = async (
  providerId: ProviderRead['id'],
  providerQuestionnaire: ProviderQuestionnaireReadV2,
  specialties: SpecialtyRead[]
) => {
  const { rawData } = providerQuestionnaire;
  const practiceFocusAreas = rawData?.practiceFocusAreas || [];
  const primaryFocusArea = rawData?.primaryFocusArea || [];
  const otherPrimaryFocusAreas = rawData?.otherPrimaryFocusAreas || [];

  // Delete existing provider specialties in case this is a resubmission
  const existingProviderSpecialties =
    await ProviderSpecialtyApi.getProviderSpecialties({
      provider_id: providerId,
    });
  await Promise.all(
    existingProviderSpecialties.map((existingProviderSpecialty) =>
      ProviderSpecialtyApi.deleteProviderSpecialty(existingProviderSpecialty.id)
    )
  );

  const primaryFocuses = [primaryFocusArea, ...otherPrimaryFocusAreas];
  for (const specialtyKey of primaryFocuses) {
    const specialty = specialties.find((s) => s.key === specialtyKey);
    if (specialty) {
      await ProviderSpecialtyApi.createProviderSpecialty({
        providerId,
        specialtyId: specialty.id,
        isFocusArea: true,
      });
    }
  }

  for (const specialtyKey of practiceFocusAreas) {
    if (primaryFocuses.includes(specialtyKey)) {
      // Don't re-include a specialty if it's already a focus area
      continue;
    }

    const specialty = specialties.find((s) => s.key === specialtyKey);
    if (specialty) {
      await ProviderSpecialtyApi.createProviderSpecialty({
        providerId,
        specialtyId: specialty.id,
        isFocusArea: false,
      });
    }
  }
};

const createProviderModalitiesFromFormData = async (
  providerId: ProviderRead['id'],
  providerQuestionnaire: ProviderQuestionnaireReadV2,
  modalities: ModalityRead[]
) => {
  const { rawData } = providerQuestionnaire;
  // Delete existing provider modalities in case this is a resubmission
  const existingProviderModalities =
    await ProviderModalityApi.getProviderModalities({
      provider_id: providerId,
    });
  await Promise.all(
    existingProviderModalities.map((existingProviderModality) =>
      ProviderModalityApi.deleteProviderModality(existingProviderModality.id)
    )
  );

  const createModalitiesPromises: ReturnType<
    typeof ProviderModalityApi.createProviderModality
  >[] = [];
  for (const modalityClinicalDisplayName of rawData?.modalities || []) {
    const modality = modalities.find(
      (m) => m.clinicalDisplayName === modalityClinicalDisplayName
    );
    if (modality) {
      createModalitiesPromises.push(
        ProviderModalityApi.createProviderModality({
          providerId,
          modalityId: modality.id,
        })
      );
    } else {
      logException(
        new Error(
          `Could not find modality record for ${modalityClinicalDisplayName}`
        )
      );
    }
  }

  await Promise.all(createModalitiesPromises);
};

const createProviderAgeGroupsFromFormData = async (
  providerId: ProviderRead['id'],
  providerQuestionnaire: ProviderQuestionnaireReadV2
) => {
  const { rawData } = providerQuestionnaire;
  // Delete existing provider age groups in case this is a resubmission
  const existingProviderAgeGroups =
    await ProviderAgeGroupApi.getProviderAgeGroups({
      provider_id: providerId,
    });
  await Promise.all(
    existingProviderAgeGroups.map((existingProviderAgeGroup) =>
      ProviderAgeGroupApi.deleteProviderAgeGroup(existingProviderAgeGroup.id)
    )
  );

  const ageGroups = await AgeGroupApi.getAgeGroups();
  const createAgeGroupsPromises: ReturnType<
    typeof ProviderAgeGroupApi.createProviderAgeGroup
  >[] = [];
  for (const ageGroupDisplayName of rawData?.practicePopulations || []) {
    const ageGroup = ageGroups.find(
      (group) => group.displayName === ageGroupDisplayName
    );
    if (ageGroup) {
      createAgeGroupsPromises.push(
        ProviderAgeGroupApi.createProviderAgeGroup({
          providerId,
          ageGroupId: ageGroup.id,
        })
      );
    } else {
      logException(
        new Error(`Could not find age group record for ${ageGroupDisplayName}`)
      );
    }
  }

  await Promise.all(createAgeGroupsPromises);
};
