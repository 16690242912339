import { ReactNode } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderProgressNoteRead } from '@headway/api/models/ProviderProgressNoteRead';
import { TemplateError } from '@headway/api/models/TemplateError';
import { TemplateErrorMessages } from '@headway/api/models/TemplateErrorMessages';
import { Badge } from '@headway/helix/Badge';
import { ValidationState } from '@headway/helix/forms';

import { isDetailsConfirmed } from 'views/Calendar/events/util/events';

import * as templates from '../templates/templates';
import {
  id_1_name_FreeText_v1,
  id_13_name_FreeText_v2,
  id_20_name_FreeText_v3,
  id_34_name_FreeTextV2_v1,
  id_34_name_FreeTextV2_v2,
} from '../templates/templates';
import { ProgressNoteComponentMetadata } from '../types';
import { TEMPLATE_ERROR_MAP_COMPONENT_LEVEL } from './errors';
import { TEMPLATE_ERROR_MAP_COMPONENT_LEVEL_V2 } from './errorsV2';
import { GenericTemplate, TemplateValues } from './Renderer/types';
import { getInitialTemplateValues as getInitialTemplateValuesV1 } from './Renderer/v1/Renderer';
import { ComponentTypes, TemplateV1 } from './Renderer/v1/types';
import { getInitialTemplateValues as getInitialTemplateValuesV2 } from './Renderer/v2/Renderer';
import { Component, Section, TemplateV2 } from './Renderer/v2/types';
import { getInitialTemplateValues as getInitialTemplateValuesV3 } from './Renderer/v3/Renderer';
import { TemplateV3 } from './Renderer/v3/types';

export const isGoogleSheetTestingEnabled = false;
export const isLocalTestingEnabled = false;

export function isElementASection<T>(
  element: Section<T> | Component<T>
): element is Section<T> {
  if ('header' in element) {
    return true;
  } else {
    return false;
  }
}

export function getTemplates<T>(): GenericTemplate<T>[] {
  return Object.values(templates as unknown as GenericTemplate<T>[]);
}

type TemplateInfo = {
  id: number;
  version: number;
  cptCodes?: string[][];
};

export type ProgressNoteTemplateRollout = {
  enabled: TemplateInfo[];
};

export function applyTemplateRolloutFilter<T>(
  templates: GenericTemplate<T>[],
  featureFlagValue?: ProgressNoteTemplateRollout,
  forceTemplate?: { id: number; version: number }
): GenericTemplate<T>[] {
  const templatesToRender = templates.filter((template) => {
    return featureFlagValue?.enabled?.some(
      (info) =>
        template.templateInfo.id === info.id &&
        template.templateInfo.version === info.version
    );
  });

  if (forceTemplate) {
    if (
      !templatesToRender.find(
        (template) =>
          template.templateInfo.id === forceTemplate.id &&
          template.templateInfo.version === forceTemplate.version
      )
    ) {
      const forcedTemplate = templates.find(
        (template) =>
          template.templateInfo.id === forceTemplate.id &&
          template.templateInfo.version === forceTemplate.version
      );

      if (forcedTemplate) {
        templatesToRender.push(forcedTemplate);
      }
    }
  }

  return templatesToRender;
}

export function applyTemplateRolloutFilterV2<T>(
  templates?: GenericTemplate<T>[],
  featureFlagValue?: ProgressNoteTemplateRollout,
  forceTemplate?: { id: number; version: number }
): GenericTemplate<T>[] {
  const templatesToRender = templates
    ? templates.filter((template) => {
        return featureFlagValue?.enabled?.some(
          (info) =>
            template.templateInfo.id === info.id &&
            template.templateInfo.version === info.version
        );
      })
    : [];

  if (forceTemplate) {
    if (
      !templatesToRender.find(
        (template) =>
          template.templateInfo.id === forceTemplate.id &&
          template.templateInfo.version === forceTemplate.version
      )
    ) {
      const forcedTemplate = templates?.find(
        (template) =>
          template.templateInfo.id === forceTemplate.id &&
          template.templateInfo.version === forceTemplate.version
      );

      if (forcedTemplate) {
        templatesToRender.push(forcedTemplate);
      }
    }
  }

  return templatesToRender;
}

export const freeTextTemplateFilter = (
  templates: GenericTemplate<unknown>[]
) => {
  const freeTextTemplates = [
    id_1_name_FreeText_v1,
    id_13_name_FreeText_v2,
    id_20_name_FreeText_v3,
    id_34_name_FreeTextV2_v1,
    id_34_name_FreeTextV2_v2,
  ];
  return templates.filter(
    (template) =>
      !freeTextTemplates.find(
        (freeTextTemplate) =>
          template.templateInfo.id === freeTextTemplate.templateInfo.id &&
          template.templateInfo.version ===
            freeTextTemplate.templateInfo.version
      )
  );
};

export const intakeNoteTemplateFilter = (
  templates: GenericTemplate<unknown>[]
) => {
  return templates.filter((template) => template.templateInfo.id === 29);
};

export const alphabeticallySortTemplates = (
  templates: GenericTemplate<unknown>[]
) => {
  return templates.sort((a, b) =>
    a.templateInfo.name.localeCompare(b.templateInfo.name)
  );
};

export const getComponentIdToComponentMap = (
  template: GenericTemplate<any>['template']
): { [key: string]: Component<ProgressNoteComponentMetadata> } =>
  (template as any[]).reduce(
    (
      acc: any,
      sectionOrElement:
        | Section<ProgressNoteComponentMetadata>
        | Component<ProgressNoteComponentMetadata>
    ) => {
      if (isElementASection(sectionOrElement)) {
        (sectionOrElement.components as Component<unknown>[]).forEach(
          (component) => (acc[component.id] = component)
        );
      } else {
        acc[sectionOrElement.id] = sectionOrElement;
      }

      return acc;
    },
    {}
  );

export const getComponentIdToTypeMap = (
  template: GenericTemplate<unknown>
): { [key: string]: keyof typeof ComponentTypes } =>
  (template.template as any[]).reduce(
    (
      acc: any,
      sectionOrElement:
        | Section<ProgressNoteComponentMetadata>
        | Component<ProgressNoteComponentMetadata>
    ) => {
      if (isElementASection(sectionOrElement)) {
        (sectionOrElement.components as Component<unknown>[]).forEach(
          (component) => (acc[component.id] = component.type)
        );
      } else {
        acc[sectionOrElement.id] = sectionOrElement.type;
      }

      return acc;
    },
    {}
  );

export const getComponentIdToTypeMapV2 = (
  template: GenericTemplate<unknown>
): {
  [key: string]: {
    type: keyof typeof ComponentTypes;
    isUnconditionallyRequired?: boolean;
  };
} => {
  const componentIdToTypeMap: {
    [key: string]: {
      type: keyof typeof ComponentTypes;
      isUnconditionallyRequired?: boolean;
    };
  } = {};

  // Iterate through each section or component in the template
  for (const sectionOrElement of template.template as (
    | Section<ProgressNoteComponentMetadata>
    | Component<ProgressNoteComponentMetadata>
  )[]) {
    if (isElementASection(sectionOrElement)) {
      // If it's a section, iterate through its components
      for (const component of sectionOrElement.components as Component<ProgressNoteComponentMetadata>[]) {
        componentIdToTypeMap[component.id] = {
          type: component.type,
          isUnconditionallyRequired: component.metadata?.requiredAlways,
        };
      }
    } else {
      // If it's a component directly, just assign it
      componentIdToTypeMap[sectionOrElement.id] = {
        type: sectionOrElement.type,
        isUnconditionallyRequired: sectionOrElement.metadata?.requiredAlways,
      };
    }
  }

  return componentIdToTypeMap;
};

export function isComponentRequired(
  cptCodes: string[],
  metadata?: ProgressNoteComponentMetadata
) {
  if (metadata?.requiredAlways) {
    return true;
  }

  if (metadata?.requiredForCptCodes) {
    if (cptCodes) {
      const cptCodeCheck = metadata.requiredForCptCodes.filter(
        (cptCode) => cptCodes?.includes(cptCode.toString())
      );
      return cptCodeCheck.length > 0;
    }
  }

  return false;
}

export const getInitialErrors = (
  template: GenericTemplate<ProgressNoteComponentMetadata> | undefined,
  selectedCptCodes: string[]
) => {
  if (!template?.template) return [];

  const errors = [];

  // @ts-ignore
  for (let item of template?.template) {
    if (item.components) {
      for (let component of item.components) {
        if (
          component.metadata?.requiredAlways ||
          component.metadata?.requiredForCptCodes.some((value: number) =>
            selectedCptCodes.includes(value.toString())
          )
        ) {
          errors.push({
            message: 'MISSING_REQUIRED_COMPONENT',
            component_id: component.id,
          });
        }
      }
    } else {
      if (
        item.metadata?.requiredAlways ||
        item.metadata?.requiredForCptCodes.some((value: number) =>
          selectedCptCodes.includes(value.toString())
        )
      ) {
        errors.push({
          message: 'MISSING_REQUIRED_COMPONENT',
          component_id: item.id,
        });
      }
    }
  }

  return errors;
};

export const createComponentErrorMap = (
  errors: TemplateError[],
  template: GenericTemplate<unknown>
) => {
  const componentIdToTypeMap = getComponentIdToTypeMap(template);
  return errors.reduce((acc: any, item: TemplateError) => {
    if (item.component_id) {
      const templateErrorComponentMap =
        TEMPLATE_ERROR_MAP_COMPONENT_LEVEL[
          item.message as keyof typeof TemplateErrorMessages
        ];
      if (templateErrorComponentMap) {
        acc[item.component_id] =
          templateErrorComponentMap[componentIdToTypeMap[item.component_id]];
      }
    }
    return acc;
  }, {});
};

export const createComponentErrorMapV2 = (
  errors: TemplateError[],
  template: GenericTemplate<unknown>
) => {
  const componentIdToTypeMap = getComponentIdToTypeMapV2(template);
  const acc: Record<string, any> = {};

  for (let i = 0; i < errors.length; i++) {
    const item = errors[i];
    if (item.component_id) {
      const templateErrorComponentMap =
        TEMPLATE_ERROR_MAP_COMPONENT_LEVEL_V2[
          item.message as keyof typeof TemplateErrorMessages
        ];
      if (
        templateErrorComponentMap &&
        componentIdToTypeMap[item.component_id]
      ) {
        acc[item.component_id] = {
          ...templateErrorComponentMap[
            componentIdToTypeMap[item.component_id].type
          ],
          isUnconditionallyRequired:
            componentIdToTypeMap[item.component_id].isUnconditionallyRequired,
          isUniqueResponseRequired:
            item.message === TemplateErrorMessages.RESPONSE_NOT_UNIQUE,
        };
      }
    }
  }

  return acc;
};

export const TEMPLATE_ERROR_MAP_FORM_LEVEL: {
  [key in TemplateErrorMessages]?: string;
} = {
  MISSING_REQUIRED_COMPONENT:
    'Session notes have all required fields be completed.',
  RESPONSE_NOT_UNIQUE:
    "Session notes should be different for each session. Update details in highlighted areas so they're unique from previous sessions.",
};

export const getFormLevelErrors = (errors: { [key: string]: string }) => {
  const componentErrorMapReversed = Object.fromEntries(
    Object.entries(TEMPLATE_ERROR_MAP_COMPONENT_LEVEL).map((a) => a.reverse())
  );
  const formLevelErrors = Object.values(errors).map(
    (error) =>
      TEMPLATE_ERROR_MAP_FORM_LEVEL[
        componentErrorMapReversed[error] as keyof typeof TemplateErrorMessages
      ]
  );
  return formLevelErrors.filter(
    (error, idx) => formLevelErrors.indexOf(error) === idx
  );
};

export const getProgressNoteSubmissionState = (
  event: ProviderEventRead,
  progressNotes?: ProviderProgressNoteRead[]
): string | undefined => {
  let submissionState;

  if (event.providerAppointment?.progressNoteType === 'TEMPLATE') {
    if (progressNotes && progressNotes[0].attestedOn === null) {
      submissionState = 'In Progress';
    } else {
      submissionState = 'Submitted';
    }
  } else {
    if (isDetailsConfirmed(event)) {
      if (event.providerAppointment?.attachments) {
        submissionState = 'Submitted';
      }
    } else {
      submissionState = 'In Progress';
    }
  }

  return submissionState;
};

export const doesComponentExpectAnArrayResponse = (
  component: Component<ProgressNoteComponentMetadata>
) =>
  [ComponentTypes.checklist, ComponentTypes.dropdownMulti].includes(
    component.type as ComponentTypes
  );

const RequiresEditIndicator = () => (
  <Badge variant="warning">Requires edit</Badge>
);

type InstructionalWarningFlags = {
  requiresEdit: boolean;
};

// not exactly a React component since it can return string or undefined
export function getInstructionalText(
  instructionalText: string | undefined,
  { requiresEdit }: InstructionalWarningFlags
): ReactNode {
  if (!requiresEdit) {
    return instructionalText;
  }

  if (!instructionalText) {
    return <RequiresEditIndicator />;
  }

  return (
    <>
      {instructionalText}
      <br />
      <RequiresEditIndicator />
    </>
  );
}

export const getOptionalityText = (
  isOptional: boolean,
  optionalityText?: string
) => (isOptional ? optionalityText || 'Optional' : '');

export const getValidationProps = (
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
): ValidationState => {
  const message =
    error && typeof error.message === 'string' ? error.message : '';
  return {
    validity: error ? 'invalid' : 'valid',
    message,
  };
};

export const getFieldRequirementCptCodes = (
  template: GenericTemplate<ProgressNoteComponentMetadata> | undefined,
  id: string | undefined
): string[] => {
  if (!template?.template || !id) return [];

  // @ts-ignore
  for (const item of template.template) {
    const components = item.components || [item];
    for (const component of components) {
      if (
        component.id === id &&
        component.metadata?.requiredForCptCodes?.length > 0
      ) {
        return component.metadata.requiredForCptCodes.map((code: number) =>
          code.toString()
        );
      }
    }
  }
  return [];
};

export function getInitialTemplateValues<T>(
  template: GenericTemplate<unknown>,
  values?: TemplateValues
) {
  const schemaVersion = template.templateInfo.schemaVersion ?? 1;

  switch (schemaVersion) {
    case 1:
      const v1Template = template as GenericTemplate<TemplateV1<T>>;
      return getInitialTemplateValuesV1(v1Template.template, values);
    case 2:
      const v2Template = template as GenericTemplate<TemplateV2<T>>;
      return getInitialTemplateValuesV2(v2Template.template, values);
    case 3:
      const v3Template = template as GenericTemplate<TemplateV3<T>>;
      return getInitialTemplateValuesV3(v3Template.template, values);
  }
}
