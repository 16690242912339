import { useContext } from 'react';
import { useRuleSet } from '~/legacy/views/AppointmentConfirmation/ruleset/useRuleSet';
import { AppointmentConfirmationContextV2 } from '~/legacy/views/AppointmentConfirmation/stores/AppointmentConfirmationContextV2';

export const SessionDetailsTopBanner = () => {
  const { patient, provider, event } = useContext(
    AppointmentConfirmationContextV2
  );
  const rule = useRuleSet({ patient, provider, event });

  return rule?.banners?.sessionDetails?.header || null;
};
