import React, { useMemo } from 'react';

import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';
import { getOptionalityText } from '../utils';
import { OtherTextField } from './OtherTextField';

const MemoizedRadioGroup = React.memo(RadioGroup);
const MemoizedRadio = React.memo(Radio);

export const FormRadio = ({
  element,
  isOptional = false,
  template,
  disabled = false,
}: SchemaComponent) => {
  const { id, title, options, optionalityText } = element;

  const optionality = useMemo(
    () => getOptionalityText(isOptional, optionalityText),
    [isOptional, optionalityText]
  );

  return (
    <FormControlRHF
      name={id}
      disabled={disabled}
      component={MemoizedRadioGroup}
      label={title}
      key={id}
      optionalityText={optionality}
    >
      {options.map((option: string, i: number) => {
        return option.includes('Other') ? (
          <OtherTextField
            id={id}
            optionType="single"
            option={option}
            template={template}
            disabled={disabled!}
            key={i}
          >
            {(value: any) => (
              <MemoizedRadio
                key={`radio-other-${i}`}
                value={option}
                disabled={disabled}
              >
                {option}
              </MemoizedRadio>
            )}
          </OtherTextField>
        ) : (
          <MemoizedRadio key={i} value={option} disabled={disabled}>
            {option}
          </MemoizedRadio>
        );
      })}
    </FormControlRHF>
  );
};
