import { useProviderPaymentPause } from '~/legacy/hooks/useProviderPaymentPause';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderPaymentPauseRead } from '@headway/api/models/ProviderPaymentPauseRead';
import { UseQueryResult } from '@headway/shared/react-query';

import { Rule } from '../Rule';
import { PausedPaymentsRequirementBanner } from './PausedPaymentsProgressNoteBanner';

export class PausedPayments extends Rule<
  [UseQueryResult<ProviderPaymentPauseRead[]>]
> {
  enabled = [true, 'pausedPayments'];

  addDataHooks() {
    return [
      () =>
        useProviderPaymentPause({
          providerId: this.provider.id,
          activeOnly: true,
        }),
    ];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData) {
      return false;
    }

    if (hookData[0].status !== 'success') {
      return false;
    }

    const activeProviderPaymentPauses = hookData[0].data;

    return !!(
      activeProviderPaymentPauses && activeProviderPaymentPauses?.length > 0
    );
  }

  get disabledProgressNoteTypes(): ProgressNoteType[] {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    return {
      progressNote: {
        header: <PausedPaymentsRequirementBanner patient={this.patient} />,
      },
    };
  }

  isDocumentationRequired(): boolean {
    return true;
  }
}
