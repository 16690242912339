import { useCallback, useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useProviderProgressNotes } from '~/legacy/hooks/useProviderProgressNotes';

import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { Item, Select } from '@headway/helix/Select';
import { theme } from '@headway/helix/theme';
import { useQuery } from '@headway/shared/react-query';

import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../../../stores/AppointmentConfirmationContextV2';
import { FormControlRHF } from '../../../FormControlRHF';
import { ProgressNoteFormV2Values } from '../ProgressNoteFormV2';
import { NoteJson } from '../Template/Renderer/types';
import { getInitialTemplateValues } from '../Template/utils';
import { getTemplate } from '../utils';

const NUMBER_OF_PREVIOUS_SESSIONS_TO_SHOW = 5;

export const PrefillFromPreviousSession = () => {
  const {
    provider,
    patient,
    progressNoteState,
    templates,
    setPreviousNoteInitialValues,
  } = useContext(AppointmentConfirmationContextV2);
  const { setValue, trigger } = useFormContext();
  const progressNoteValues: ProgressNoteFormV2Values = useWatch({
    name: 'progressNote',
  });

  const selectedTemplateInfo = useMemo(() => {
    const template = progressNoteValues.template;
    if (template) {
      return {
        id: parseInt(template.split('-')[0], 10),
        version: parseInt(template.split('-')[1], 10),
      };
    }
    return undefined;
  }, [progressNoteValues.template]);

  const { data: previousProgressNotes } = useProviderProgressNotes({
    providerId: provider?.id,
    patientId: patient?.id,
    templateId: selectedTemplateInfo?.id,
    templateVersion: selectedTemplateInfo?.version,
  });

  const { data: previousProgressNotesEvents } = useQuery(
    ['getPreviousAppointmentSession'],
    async () => {
      return Promise.all(
        (
          previousProgressNotes?.slice(
            0,
            NUMBER_OF_PREVIOUS_SESSIONS_TO_SHOW
          ) || []
        ).map((prevNote) => {
          return ProviderEventApi.getEvents({
            provider_id: prevNote.providerId,
            patient_user_id: prevNote.patientId,
            provider_appointment_ids: [prevNote?.providerAppointmentId],
            expand_estimated_prices: false,
          });
        })
      );
    },
    {
      enabled: !!previousProgressNotes?.length,
    }
  );

  const handleOnSelectionChange = useCallback(
    (keys: Set<string>) => {
      setValue(
        'progressNote.previousNote',
        keys.size > 0 ? keys.values().next().value : undefined
      );

      const previousNote = previousProgressNotes?.find(
        (note) => note.id.toString() === keys.values().next().value?.toString()
      );

      const template = getTemplate(
        templates,
        (previousNote?.noteJson as NoteJson)?.templateInfo?.id,
        (previousNote?.noteJson as NoteJson)?.templateInfo?.version
      );

      const noteJson = previousNote?.noteJson as {
        templateInfo?: { id: number; version: number };
        response?: Record<string, any>;
      };

      if (template) {
        const initialTemplateValues = getInitialTemplateValues(
          template,
          noteJson.response
        );
        for (const [key, value] of Object.entries(initialTemplateValues)) {
          setValue(`progressNote.${key}`, value);
          trigger(`progressNote.${key}`);
        }
        setPreviousNoteInitialValues(noteJson.response);
      }
    },
    [
      previousProgressNotes,
      setValue,
      templates,
      trigger,
      setPreviousNoteInitialValues,
    ]
  );

  if (
    !previousProgressNotes?.length ||
    progressNoteState === ProgressNoteState.SIGNED ||
    progressNoteState === ProgressNoteState.ADDENDUM_EDITING
  ) {
    // Do not show if there are no previous progress notes for the
    // given selected template or if the note is signed
    return null;
  }

  return (
    <div
      css={{
        width: 250,
        [theme.__futureMedia.phone]: { width: '100%' },
      }}
    >
      <FormControlRHF
        component={Select}
        name="progressNote.previousNote"
        label="Prefill from previous session"
        placeholder="Select session"
        selectionMode="single"
        menuWidth="stretch"
        disabled={progressNoteState !== ProgressNoteState.EDITING}
        selectedKeys={
          progressNoteValues?.previousNote
            ? new Set([progressNoteValues?.previousNote])
            : new Set()
        }
        onSelectionChange={handleOnSelectionChange}
      >
        {previousProgressNotes
          ?.slice(0, NUMBER_OF_PREVIOUS_SESSIONS_TO_SHOW)
          .map((note, key) => {
            const previousAppointment = previousProgressNotesEvents?.find(
              (prevEvent) => {
                return (
                  prevEvent?.data[0]?.providerAppointment?.id ===
                  note.providerAppointmentId
                );
              }
            );

            const date = new Date(
              previousAppointment?.data[0]?.startDate || note.lastSavedOn
            );
            return (
              <Item key={note.id}>
                {`${date.toLocaleDateString([], {
                  year: '2-digit',
                  month: 'numeric',
                  day: 'numeric',
                })} at ${date.toLocaleTimeString([], {
                  hour: 'numeric',
                  minute: 'numeric',
                })}`}
              </Item>
            );
          })}
      </FormControlRHF>
    </div>
  );
};
