import { CardContent } from '@mui/material';
import { useProvider } from 'hooks';
import moment from 'moment/moment';

import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { DateField } from '@headway/helix/DateField';
import { FormControl } from '@headway/helix/FormControl';
import { NumberField } from '@headway/helix/NumberField';
import { TextField } from '@headway/helix/TextField';

import {
  DataField,
  ExpirationDataField,
} from 'views/InsuranceStatus/CoiComponents/CoiDataFields';
import { CoiPathwayType } from 'views/InsuranceStatus/CoiComponents/CoiUtils';

const CoiDisplayExpirationDate = ({
  expirationDate,
  coiPathway,
}: {
  expirationDate?: string;
  coiPathway: CoiPathwayType;
}) => {
  if (!expirationDate) {
    return (
      <FormControl
        component={DateField}
        label="Expiration date"
        name="expirationDate"
      />
    );
  }
  if (coiPathway == CoiPathwayType.EXPIRING_SOON) {
    return (
      <ExpirationDataField
        fieldName="Expiration date"
        fieldContent={moment(expirationDate).format('MM/DD/YYYY')}
        errorMsg="This policy is expiring within two weeks."
      />
    );
  }
  return (
    <FormControl
      name="expirationDate"
      component={DataField}
      fieldContent={moment(expirationDate).format('MM/DD/YYYY')}
      fieldName="Expiration date"
    />
  );
};

const CoiFieldsDisplayForm = ({
  pqv,
  coiPathway,
}: {
  pqv?: ProviderQuestionnaireVerificationRead;
  coiPathway: CoiPathwayType;
}) => {
  const provider = useProvider();

  return (
    <div>
      <CardContent>
        <div className="grid gap-y-4">
          <div className="text-base">
            <strong>Your coverage details:</strong>
          </div>
          <FormControl
            name="malpracticeProvider"
            component={TextField}
            label="Malpractice insurance provider"
          />
          <FormControl
            name="nameInsured"
            component={DataField}
            fieldContent={provider.name}
            fieldName="Name insured"
          />
          {pqv?.responseJson?.coverageStartDateFound ? (
            <FormControl
              name="effectiveDate"
              component={DataField}
              fieldContent={moment(
                pqv?.responseJson?.coverageStartDateFound
              ).format('MM/DD/YYYY')}
              fieldName="Effective date"
            />
          ) : (
            <FormControl
              name="effectiveDate"
              component={DateField}
              label={'Effective date'}
            />
          )}

          <CoiDisplayExpirationDate
            coiPathway={coiPathway}
            expirationDate={pqv?.responseJson?.coverageEndDateFound}
          />
          {pqv?.responseJson?.perClaimCoverageLimitFound ? (
            <FormControl
              name={'occurrenceLimit'}
              component={DataField}
              fieldContent={pqv?.responseJson?.perClaimCoverageLimitFound}
              fieldName={'Occurrence limit'}
            />
          ) : (
            <FormControl
              name={'occurrenceLimit'}
              component={NumberField}
              label={'Occurrence limit'}
            />
          )}
          {pqv?.responseJson?.aggregateCoverageLimitFound ? (
            <FormControl
              name={'aggregateLimit'}
              component={DataField}
              fieldContent={pqv?.responseJson?.aggregateCoverageLimitFound}
              fieldName={'Aggregate limit'}
            />
          ) : (
            <FormControl
              name={'aggregateLimit'}
              component={NumberField}
              label={'Aggregate limit'}
            />
          )}
        </div>
      </CardContent>
    </div>
  );
};

export default CoiFieldsDisplayForm;
