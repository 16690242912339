import React from 'react';

import { theme } from '@headway/helix/theme';

export interface CustomFormRowProps {
  /** The space between items in the row when aligned horizontally. */
  spacing?: string;
}

export const CustomFormRow: React.FC<
  React.PropsWithChildren<CustomFormRowProps>
> = ({ spacing, children }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      '> *': {
        flexGrow: 1,
        flexBasis: 0,
        ':not(:last-child)': { marginRight: spacing || theme.spacing.x2 },
      },
      [theme.__futureMedia.below('tablet')]: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing.x5,
        flexDirection: 'column',
        '> *': {
          width: '100%',
          ':not(:last-child)': { marginRight: 0 },
        },
      },
    }}
  >
    {children}
  </div>
);
