/**
 * These are the Helix low level primitives. They are used to build the rest of the design system
 * but should not be used directly. Both the [MARKETING] and [WEB] themes are built on top of
 * them.
 */

export const FONT_FAMILY_HEADLINE = `var(--hlx-typography-font-family-headline), serif`;
export const FONT_FAMILY_BODY = '"PostGrotesk", sans-serif';

export const FONT_WEIGHT_HEADLINE_REGULAR = 400;
export const FONT_WEIGHT_BODY_REGULAR = 400;
export const FONT_WEIGHT_BODY_MEDIUM = 500;

export const LINE_HEIGHT_BASE_1 = '12px';
export const LINE_HEIGHT_BASE_2 = '14px';
export const LINE_HEIGHT_BASE_3 = '16px';
export const LINE_HEIGHT_BASE_4 = '18px';
export const LINE_HEIGHT_BASE_5 = '20px';
export const LINE_HEIGHT_BASE_6 = '22px';
export const LINE_HEIGHT_BASE_7 = '24px';
export const LINE_HEIGHT_BASE_8 = '28px';
export const LINE_HEIGHT_BASE_9 = '32px';
export const LINE_HEIGHT_BASE_10 = '36px';
export const LINE_HEIGHT_BASE_11 = '40px';
export const LINE_HEIGHT_BASE_12 = '44px';
export const LINE_HEIGHT_BASE_13 = '48px';
export const LINE_HEIGHT_BASE_14 = '52px';
export const LINE_HEIGHT_BASE_15 = '56px';
export const LINE_HEIGHT_BASE_16 = '60px';
export const LINE_HEIGHT_BASE_17 = '64px';
export const LINE_HEIGHT_BASE_18 = '68px';
export const LINE_HEIGHT_BASE_19 = '88px';
export const LINE_HEIGHT_BASE_20 = '100px';
export const LINE_HEIGHT_BASE_21 = '128px';
export const LINE_HEIGHT_BASE_22 = '156px';
export const LINE_HEIGHT_BASE_23 = '196px';

export const FONT_SIZE_BASE_1 = '8px';
export const FONT_SIZE_BASE_2 = '10px';
export const FONT_SIZE_BASE_3 = '12px';
export const FONT_SIZE_BASE_4 = '13px';
export const FONT_SIZE_BASE_5 = '14px';
export const FONT_SIZE_BASE_6 = '15px';
export const FONT_SIZE_BASE_7 = '16px';
export const FONT_SIZE_BASE_8 = '17px';
export const FONT_SIZE_BASE_9 = '18px';
export const FONT_SIZE_BASE_10 = ' 20px';
export const FONT_SIZE_BASE_11 = ' 21px';
export const FONT_SIZE_BASE_12 = ' 24px';
export const FONT_SIZE_BASE_13 = ' 26px';
export const FONT_SIZE_BASE_14 = ' 28px';
export const FONT_SIZE_BASE_15 = ' 32px';
export const FONT_SIZE_BASE_16 = ' 36px';
export const FONT_SIZE_BASE_17 = ' 40px';
export const FONT_SIZE_BASE_18 = ' 44px';
export const FONT_SIZE_BASE_19 = ' 48px';
export const FONT_SIZE_BASE_20 = ' 56px';
export const FONT_SIZE_BASE_21 = ' 64px';
export const FONT_SIZE_BASE_22 = ' 88px';
export const FONT_SIZE_BASE_23 = ' 104px';
export const FONT_SIZE_BASE_24 = ' 132px';
export const FONT_SIZE_BASE_25 = ' 160px';
export const FONT_SIZE_BASE_26 = ' 200px';
