import { Formik } from 'formik';
import { useCallback, useContext, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useProvider, useProviderPatient } from '~/legacy/hooks';
import { formatFirstName } from '~/legacy/views/Patients/utils/addPatientModalUtils';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { LogoLoader } from '@headway/ui/LogoLoader';

import { ManageAssessmentsModalContent } from '../ManageAssessmentsModal';
import {
  FillOutAssessmentModalContext,
  ManagementAssessmentStep,
} from './FillOutAssessmentForClientModalContext';

interface SelectAssessmentsFormValues {
  enabledAssessmentTypes: PatientAssessmentType[];
}

interface SelectAssessmentsProps {
  onDismiss: () => void;
  client: UserRead;
  providerPatient: ProviderPatientRead;
}

export const SelectAssessments = ({
  onDismiss,
  client,
  providerPatient,
}: SelectAssessmentsProps) => {
  const { setCurrentStep, setSelectedAssessments, selectedAssessments } =
    useContext(FillOutAssessmentModalContext);

  const goToNextStep = useCallback(() => {
    setCurrentStep(ManagementAssessmentStep.ATTESTATION);
  }, [setCurrentStep]);

  const provider = useProvider();

  useEffect(() => {
    trackPageView({
      name: 'Choose Assessments to Fill With Client Page Viewed',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
        providerPatientId: providerPatient.id,
      },
    });
  }, []);

  const validationSchema = Yup.object().shape({
    enabledAssessmentTypes: Yup.array().min(
      1,
      'Please select at least one assessment'
    ),
  });

  const handleFillOutOnBehalfOfClient = (values: any) => {
    const { enabledAssessmentTypes } = values;
    setSelectedAssessments([...enabledAssessmentTypes]);

    trackEvent({
      name: 'Fill Out Assessments With Client Button Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
        providerPatientId: providerPatient.id,
        assessmentTypeList: enabledAssessmentTypes,
      },
    });

    goToNextStep();
  };

  return (
    <Formik<SelectAssessmentsFormValues>
      initialValues={{
        enabledAssessmentTypes: selectedAssessments,
      }}
      enableReinitialize
      onSubmit={handleFillOutOnBehalfOfClient}
      validationSchema={validationSchema}
    >
      {({ values }) => {
        return (
          <>
            <ManageAssessmentsModalContent
              client={client}
              provider={provider}
              providerPatient={providerPatient}
              isFillOutOnBehalfForClient={true}
            />
            <ModalFooter>
              <Button variant="secondary" onPress={onDismiss}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                form="manage-assessments-modal-form"
                disabled={!validationSchema.isValidSync(values)}
              >
                Fill out assessments with {formatFirstName(client)} now
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
