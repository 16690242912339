import { QueryKey } from '@tanstack/react-query';

import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseUnconfirmedAppointmentCountsForGroupPracticeQueryKeyArgs {
  groupPracticeId: number;
  query?: Parameters<
    typeof ProviderEventApi.getUnconfirmedAppointmentCountsForGroupPractice
  >[1];
}

export const getUseUnconfirmedAppointmentCountsForGroupPracticeQueryKey = ({
  groupPracticeId,
  query,
}: UseUnconfirmedAppointmentCountsForGroupPracticeQueryKeyArgs): [
  string,
  number,
  (
    | Parameters<
        typeof ProviderEventApi.getUnconfirmedAppointmentCountsForGroupPractice
      >[1]
    | undefined
  ),
] => [
  'unconfirmed-appointment-counts-for-group-practice',
  groupPracticeId,
  query,
];

export const isUnconfirmedAppointmentCountsForGroupPracticeQueryKey = (
  queryKey: QueryKey
): queryKey is ReturnType<
  typeof getUseUnconfirmedAppointmentCountsForGroupPracticeQueryKey
> =>
  queryKey[0] === 'unconfirmed-appointment-counts-for-group-practice' &&
  !!queryKey[1];

const {
  useSingleQuery: useUnconfirmedAppointmentCountsForGroupPractice,
  useListQuery: useUnconfirmedAppointmentCountsForGroupPracticeList,
  useCachedQuery: useUnconfirmedAppointmentCountsForGroupPracticeCache,
} = createBasicApiHooks(
  getUseUnconfirmedAppointmentCountsForGroupPracticeQueryKey,
  ({ groupPracticeId, query }) => {
    return ProviderEventApi.getUnconfirmedAppointmentCountsForGroupPractice(
      groupPracticeId,
      query
    );
  },
  ({ groupPracticeId }) => !!groupPracticeId,
  () => 'Failed to fetch unconfirmed appointment counts for group practice'
);

export {
  useUnconfirmedAppointmentCountsForGroupPractice,
  useUnconfirmedAppointmentCountsForGroupPracticeList,
  useUnconfirmedAppointmentCountsForGroupPracticeCache,
};
