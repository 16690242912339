import { ProviderAppointmentAttachmentCreate } from '@headway/api/models/ProviderAppointmentAttachmentCreate';
import { ProviderAppointmentAttachmentRead } from '@headway/api/models/ProviderAppointmentAttachmentRead';
import { ProviderAppointmentAttachmentApi } from '@headway/api/resources/ProviderAppointmentAttachmentApi';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export interface CreateProviderAppointmentAttachmentPayload {
  providerAppointmentId: number;
  providerAppointmentAttachment: ProviderAppointmentAttachmentCreate;
  isActive?: boolean;
}

export const useCreateProviderAppointmentAttachmentMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderAppointmentAttachmentRead,
    unknown,
    CreateProviderAppointmentAttachmentPayload
  > = {}
) => {
  return useMutationWithSideEffects(
    ({
      providerAppointmentId,
      providerAppointmentAttachment,
      isActive = true,
    }: CreateProviderAppointmentAttachmentPayload) =>
      ProviderAppointmentAttachmentApi.createProviderAppointmentAttachment(
        providerAppointmentId,
        providerAppointmentAttachment,
        {
          is_active: isActive,
        }
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderAppointmentAttachmentRead,
        unknown,
        CreateProviderAppointmentAttachmentPayload
      >()
        .addErrorLogging(
          () =>
            'There was a problem creating the provider appointment attachment'
        )
        .merge(options.sideEffects),
    }
  );
};

export const useDeleteProviderAppointmentAttachmentMutation = (
  options: UseMutationWithSideEffectsOptions<{}, unknown, number> = {}
) => {
  return useMutationWithSideEffects(
    (providerAppointmentAttachmentId: number) =>
      ProviderAppointmentAttachmentApi.deleteProviderAppointmentAttachment(
        providerAppointmentAttachmentId
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<{}, unknown, number>()
        .addErrorLogging(() => 'There was a problem removing the event')
        .merge(options.sideEffects),
    }
  );
};
