import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey } from '~/legacy/hooks/usePaginatedProviderDocumentRequestsAndRemediations';
import { getUseProviderAppointmentAddendumQueryKey } from '~/legacy/hooks/useProviderAppointmentAddendums';
import { getUseProviderDocumentRemediationQueryKey } from '~/legacy/hooks/useProviderDocumentRemediations';
import { useCreateProviderAppointmentAddendumMutation } from '~/legacy/mutations/providerAppointmentAddendum';
import { PAGE_LIMIT } from '~/legacy/views/Home/ChartReviewCombinedCard';

import { ProviderAppointmentAttachmentCreate } from '@headway/api/models/ProviderAppointmentAttachmentCreate';
import { useQueryClient } from '@headway/shared/react-query';

import {
  AddendumsEditFormV2Values,
  AddendumType,
} from '../../components/forms/AddendumsFormV2';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useSignAddendum = () => {
  const { switchToNoteSigned, event, provider } = useContext(
    AppointmentConfirmationContextV2
  );
  const queryClient = useQueryClient();
  const { setValue } = useFormContext();

  const createProviderAppointmentAddendumMutation =
    useCreateProviderAppointmentAddendumMutation();

  const onSignSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      getUseProviderAppointmentAddendumQueryKey({
        providerAppointmentId: event?.providerAppointment?.id,
      })
    );
    queryClient.invalidateQueries(
      getUseProviderDocumentRemediationQueryKey({
        providerAppointmentId: event?.providerAppointment?.id,
      })
    );
    if (provider) {
      // Invalidate the queries for the home page - chart review card (remediations)
      // Since those remediations status are linked to having an addendum or not
      queryClient.invalidateQueries(
        getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey({
          providerId: provider.id,
          offset: 0,
          limit: PAGE_LIMIT,
        })
      );
    }

    // reset the addendums form
    setValue('addendumsEdit.attachments', []);
    setValue('addendumsEdit.addendumHTML', undefined);
  }, [queryClient, event?.providerAppointment?.id, provider, setValue]);

  const signAddendum = useCallback(
    async (values: any) => {
      if (!event?.providerAppointment) return;

      const addendumsEditValues: AddendumsEditFormV2Values =
        values.addendumsEdit;

      const attachments: ProviderAppointmentAttachmentCreate[] =
        addendumsEditValues.attachments?.map((attachment) => {
          return {
            link: attachment.link ?? '',
            name: attachment.name ?? '',
          };
        });

      await createProviderAppointmentAddendumMutation.mutateAsync({
        providerAppointmentId: event?.providerAppointment?.id,
        providerAppointmentAddendum: {
          attestedOn: new Date().toISOString(),
          providerAppointmentId: event?.providerAppointment?.id,
          addendumHtml:
            addendumsEditValues.addendumType === AddendumType.TEXT
              ? addendumsEditValues.addendumHtml
              : undefined,
          attachments:
            addendumsEditValues.addendumType === AddendumType.UPLOAD
              ? attachments
              : undefined,
        },
      });

      onSignSuccess();
      switchToNoteSigned();
      // TODO: Check if that is still relevant to track at this moment
      // trackButtonClicked('Progress Note Submit Addendum Button Clicked');
    },
    [
      createProviderAppointmentAddendumMutation,
      event?.providerAppointment,
      switchToNoteSigned,
      onSignSuccess,
    ]
  );

  return { signAddendum };
};
