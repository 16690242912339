import { useFormikContext } from 'formik';
import { useEffect } from 'react';

/**
 * Listens for invalid submit attempts and scrolls the page to the invalid form element.
 * NOTE: Assumes that the validation schema does not contain any nested objects and that all input
 * names are unique on the page.
 */
export const ErrorScrollListener = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(
    () => {
      if (isValid) {
        return;
      }
      const errorInputName = Object.keys(errors)[0];
      if (!errorInputName) {
        return;
      }
      const element = document.querySelector(`input[name='${errorInputName}']`);
      if (!element) {
        return;
      }
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    // Formik doesn't have callbacks on failed submissions, so we use submitCount as a proxy.
    [submitCount] //esline-disable-line react-hooks/exhaustive-deps
  );

  return null;
};
