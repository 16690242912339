import { Card } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { Form } from '@headway/helix/Form';
import { GuidanceCard } from '@headway/helix/GuidanceCard';

import { useProvider } from 'hooks/useProvider';

import CoiFieldsDisplayForm from './CoiFieldsDisplayForm';
import { CoiPathwayType, MalpracticeValuesType } from './CoiUtils';

export const CoiSuccess = ({
  coiVerification,
  coiPathway,
}: {
  coiVerification?: ProviderQuestionnaireVerificationRead;
  coiPathway: CoiPathwayType;
}) => {
  const provider = useProvider();
  const { values } = useFormikContext<MalpracticeValuesType>();
  useEffect(() => {
    values.nameInsured = coiVerification?.responseJson?.nameInsuredFound
      ? provider.name
      : '';
    values.aggregateLimit =
      coiVerification?.responseJson?.aggregateCoverageLimitFound;
    values.occurrenceLimit =
      coiVerification?.responseJson?.perClaimCoverageLimitFound;
    values.expirationDate = coiVerification?.responseJson?.coverageEndDateFound;
    values.effectiveDate =
      coiVerification?.responseJson?.coverageStartDateFound;
  }, []);

  return (
    <Form>
      {coiPathway === CoiPathwayType.SUCCESS && (
        <Card variant="outlined" sx={{ marginBottom: 1 }}>
          <CoiFieldsDisplayForm pqv={coiVerification} coiPathway={coiPathway} />
        </Card>
      )}
      {coiPathway === CoiPathwayType.SUBMITTED && (
        <GuidanceCard variant="positive" layout="vertical">
          Your Certificate of Insurance has been successfully submitted!
        </GuidanceCard>
      )}
    </Form>
  );
};
