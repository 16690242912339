import { useCallback } from 'react';

import { Button } from '@headway/helix/Button';
import { AddPlus } from '@headway/icons/dist/AddPlus';

import { MultiAssessmentScheduleValue } from './types';
import { getAssessmentScheduleConfigWithDefaults } from './utils';

type MultiAssessmentScheduleInputAddButtonProps = {
  addToForm: (schedule: MultiAssessmentScheduleValue) => void;
};

const DEFAULT_NEW_SCHEDULE = getAssessmentScheduleConfigWithDefaults({
  assessmentType: undefined,
  editing: true,
});

export const MultiAssessmentScheduleInputAddButton = ({
  addToForm,
}: MultiAssessmentScheduleInputAddButtonProps) => {
  const addSchedule = useCallback(() => {
    addToForm({ ...DEFAULT_NEW_SCHEDULE });
  }, [addToForm]);

  return (
    <Button variant="link" onPress={addSchedule}>
      <div className="flex items-center">
        <AddPlus />
        Add another assessment
      </div>
    </Button>
  );
};
