import * as Yup from 'yup';

import { TemplateErrorMessages } from '@headway/api/models/TemplateErrorMessages';

import { ComponentTypes } from './Renderer/v1/types';

export type TemplateErrorConfig = {
  id?: string;
  type?: string;
  validationType: keyof typeof Yup;
  validations: Array<{ type: string; params: Array<string | number> }>;
};

type TemplateErrorCopyMap = {
  [key in TemplateErrorMessages]?: {
    [key in ComponentTypes]?: TemplateErrorConfig;
  };
};

export const TEMPLATE_ERROR_MAP_COMPONENT_LEVEL: TemplateErrorCopyMap = {
  MISSING_REQUIRED_COMPONENT: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeTextWithCheckbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.shortFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
  },
  MISSING_COMPONENT_RESPONSE: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeTextWithCheckbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.shortFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
  },
  RESPONSE_NOT_UNIQUE: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.longFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.longFreeTextWithCheckbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.shortFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
  },
  MISSING_VALUE_FOR_OTHER: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
      ],
    },
  },
  RESPONSE_LENGTH_TOO_SHORT: {
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Your note must be at least 400 characters long.'],
        },
        {
          type: 'min',
          params: [400, 'Your note must be at least 400 characters long.'],
        },
      ],
    },
  },
};

export function createYupSchema(schema: any, config: TemplateErrorConfig) {
  const { id, validationType, validations = [] } = config;

  if (!Yup[validationType] || !id) {
    return schema;
  }

  const validatorSchema = Yup[validationType] as NewableFunction;
  let validator = validatorSchema();

  validations.forEach(
    (validation: { type: string; params: Array<string | number> }) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    }
  );
  schema[id] = validator;
  return schema;
}
