import * as Yup from 'yup';

import { TemplateErrorMessages } from '@headway/api/models/TemplateErrorMessages';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';

import { ProgressNoteComponentMetadata } from '../types';
import { GenericTemplate } from './Renderer/types';
import { ComponentTypes } from './Renderer/v1/types';
import { getFieldRequirementCptCodes } from './utils';

export type TemplateErrorConfig = {
  id?: string;
  type?: string;
  validationType: keyof typeof Yup;
  validations: Array<{
    type: string;
    params: Array<string | number | boolean>;
  }>;
  isUnconditionallyRequired?: boolean;
  isUniqueResponseRequired?: boolean;
};

type TemplateErrorCopyMap = {
  [key in TemplateErrorMessages]?: {
    [key in ComponentTypes]?: TemplateErrorConfig;
  };
};

export const TEMPLATE_ERROR_MAP_COMPONENT_LEVEL_V2: TemplateErrorCopyMap = {
  MISSING_REQUIRED_COMPONENT: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'match',
          params: [true, 'This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
        {
          type: 'min',
          params: [1, 'Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeTextWithCheckbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.shortFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
  },
  MISSING_COMPONENT_RESPONSE: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'match',
          params: [true, 'This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
        {
          type: 'min',
          params: [1, 'Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'array',
      validations: [
        {
          type: 'required',
          params: ['Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.longFreeTextWithCheckbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.shortFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Select an option to continue.'],
        },
      ],
    },
  },
  RESPONSE_NOT_UNIQUE: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'match',
          params: [true, 'This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
        {
          type: 'min',
          params: [1, 'Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.longFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.longFreeTextWithCheckbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details to continue.'],
        },
      ],
    },
    [ComponentTypes.shortFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Add details specific to this session.'],
        },
      ],
    },
  },
  MISSING_VALUE_FOR_OTHER: {
    [ComponentTypes.checkbox]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'match',
          params: [true, 'This is required.'],
        },
      ],
    },
    [ComponentTypes.checklist]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
        {
          type: 'min',
          params: [1, 'Select at least one option to continue.'],
        },
      ],
    },
    [ComponentTypes.radio]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
      ],
    },
    [ComponentTypes.dropdownMulti]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
      ],
    },
    [ComponentTypes.dropdown]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Please tell why you chose Other.'],
        },
      ],
    },
  },
  RESPONSE_LENGTH_TOO_SHORT: {
    [ComponentTypes.richFreeText]: {
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Your note must be at least 400 characters long.'],
        },
        {
          type: 'min',
          params: [400, 'Your note must be at least 400 characters long.'],
        },
      ],
    },
  },
};

const parseBoolean = (value: string): boolean => {
  return value.toLowerCase() === 'true';
};

const applyValidations = (
  validator: any,
  validations: { type: string; params: Array<string | number | boolean> }[]
) => {
  validations.forEach(({ type, params }) => {
    if (type === 'required') {
      validator = validator.required(params[0] || 'This field is required.');
    } else if (type === 'match') {
      // Explicitly handle "match" validation
      const [value, message] = params;
      if (typeof value === 'boolean') {
        // Handle match validation for booleans
        validator = validator.test(
          'is-boolean-match',
          message || 'Invalid value.',
          (inputValue: any) => {
            if (typeof inputValue === 'string')
              return parseBoolean(inputValue) === value;
            return inputValue === value;
          }
        );
      } else {
        // Handle match validation for non-booleans
        validator = validator.oneOf([value], message || 'Invalid value.');
      }
    } else if (typeof validator[type] === 'function') {
      // Handle standard Yup methods
      validator = validator[type](...params);
    } else {
      console.error(`Validation type "${type}" is not supported.`);
    }
  });
  return validator;
};

const handleConditionalRequirements = (
  validator: any,
  id: string,
  template: GenericTemplate<ProgressNoteComponentMetadata> | undefined,
  selectedCptCodes: CPTCodeInfo[] | undefined,
  validations: { type: string; params: Array<string | number | boolean> }[]
) => {
  const fieldRequirementCptCodes = getFieldRequirementCptCodes(template, id);
  if (fieldRequirementCptCodes?.length > 0) {
    const isCptCodeRequired = selectedCptCodes?.some((item: CPTCodeInfo) =>
      fieldRequirementCptCodes.includes(item.value)
    );
    if (isCptCodeRequired) {
      return applyValidations(validator, validations);
    }
  }
  return validator;
};

export const createYupSchema = (
  schema: Record<string, any>,
  config: TemplateErrorConfig,
  template?: GenericTemplate<ProgressNoteComponentMetadata>,
  selectedCptCodes?: CPTCodeInfo[],
  prefilledValue?: string
) => {
  const {
    id,
    validationType,
    validations = [],
    isUnconditionallyRequired,
    isUniqueResponseRequired,
  } = config;

  if (!Yup[validationType] || !id) {
    return schema;
  }

  // Dynamically retrieve the correct Yup validation schema type based on validationType
  let validator: Yup.Schema<any>;

  switch (validationType) {
    case 'string':
      validator = Yup.string();
      break;
    case 'number':
      validator = Yup.number();
      break;
    case 'array':
      validator = Yup.array();
      break;
    case 'object':
      validator = Yup.object();
      break;
    case 'boolean':
      validator = Yup.boolean();
      break;
    case 'date':
      validator = Yup.date();
      break;
    // Add any other specific schema types you might need
    default:
      // Fall back to AnySchema if it's not one of the known types
      validator = Yup.mixed();
      break;
  }

  if (isUnconditionallyRequired) {
    if (validationType === 'array') {
      validator = Yup.array().of(Yup.string());
    }
    validator = applyValidations(validator, validations);
    if (isUniqueResponseRequired) {
      validator = (validator as Yup.StringSchema).test(
        'is-unique',
        `Add details specific to this session.`,
        (value) => value !== prefilledValue
      );
    }
  } else {
    validator = handleConditionalRequirements(
      validator,
      id,
      template,
      selectedCptCodes,
      validations
    );
  }

  schema[id] = validator;
  return schema;
};
