import { Formik } from 'formik';
import { useProvider } from 'hooks';
import React, { useContext } from 'react';
import * as Yup from 'yup';

import { NoAddendumReason } from '@headway/api/models/NoAddendumReason';
import { ProviderDocumentRemediationApi } from '@headway/api/resources/ProviderDocumentRemediationApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey } from 'hooks/usePaginatedProviderDocumentRequestsAndRemediations';
import { PAGE_LIMIT } from 'views/Home/ChartReviewCombinedCard';

import { ProgressNoteContext } from '../../stores/ProgressNotesContext';

const SUBMIT_WITHOUT_CHANGES_REASONS = {
  [NoAddendumReason.DOCUMENTATION_IS_ACCURATE]:
    'The documentation I have provided is accurate and complete.',
  [NoAddendumReason.UNABLE_TO_UPDATE]:
    "I'm unable to make updates to the flagged items (e.g. I don't recall specifics of the session)",
  [NoAddendumReason.OTHER]: 'Other',
};

interface SubmitAddendumWithoutChangesModalProps {
  open: boolean;
  closeOnCancel?: () => void;
  closeOnSubmit: () => void;
  clientId: number;
}

interface FormValues {
  submitWithoutChangeReason?: NoAddendumReason;
  submitWithoutChangeOtherDescription: string;
}

const submitWithoutChangesSchema = Yup.object().shape({
  submitWithoutChangeReason: Yup.string().required('Select an option'),
  submitWithoutChangeOtherDescription: Yup.string().when(
    'submitWithoutChangeReason',
    {
      is: NoAddendumReason.OTHER,
      then: Yup.string().required('Field is required'),
    }
  ),
});

export const SubmitAddendumWithoutChangesModal = ({
  open,
  closeOnCancel,
  closeOnSubmit,
  clientId,
}: SubmitAddendumWithoutChangesModalProps) => {
  const queryClient = useQueryClient();
  const { documentRemediation } = useContext(ProgressNoteContext);
  const provider = useProvider();

  const handleSubmitWithoutChanges = async (values: FormValues) => {
    try {
      if (documentRemediation) {
        const updateDocumentRemediation = {
          noAddendumReason: values.submitWithoutChangeReason,
          noAddendumOtherReason:
            values.submitWithoutChangeReason === NoAddendumReason.OTHER &&
            values.submitWithoutChangeOtherDescription
              ? values.submitWithoutChangeOtherDescription
              : undefined,
          remediations: documentRemediation.remediations,
        };

        await ProviderDocumentRemediationApi.updateProviderDocumentRemediation(
          documentRemediation?.id,
          updateDocumentRemediation
        );
        queryClient.invalidateQueries(
          getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey({
            providerId: provider.id,
            offset: 0,
            limit: PAGE_LIMIT,
          })
        );
        notifySuccess('Submitted without changes.');
        closeOnSubmit();
      }
    } catch (err: any) {
      notifyError('Something went wrong updating your information.');
      logException(err);
    }
  };

  return (
    <Modal
      title="Submit without changes"
      onDismiss={closeOnCancel}
      isOpen={open}
    >
      <Formik
        initialValues={
          {
            submitWithoutChangeReason: undefined,
            submitWithoutChangeOtherDescription: '',
          } as FormValues
        }
        validationSchema={submitWithoutChangesSchema}
        onSubmit={handleSubmitWithoutChanges}
      >
        {(formik) => {
          return (
            <>
              <ModalContent>
                <Form id="submitWithoutChanges">
                  <BodyText>
                    We may need to follow up if you submit without an addendum.
                  </BodyText>
                  <FormControl
                    name="submitWithoutChangeReason"
                    component={RadioGroup}
                    label="I am submitting without changes because..."
                  >
                    <Radio value={NoAddendumReason.DOCUMENTATION_IS_ACCURATE}>
                      {SUBMIT_WITHOUT_CHANGES_REASONS.DOCUMENTATION_IS_ACCURATE}
                    </Radio>
                    <Radio value={NoAddendumReason.UNABLE_TO_UPDATE}>
                      {SUBMIT_WITHOUT_CHANGES_REASONS.UNABLE_TO_UPDATE}
                    </Radio>
                    <Radio value={NoAddendumReason.OTHER}>
                      {SUBMIT_WITHOUT_CHANGES_REASONS.OTHER}
                    </Radio>
                    <div css={{ marginLeft: theme.spacing.x7 }}>
                      <FormControl
                        name="submitWithoutChangeOtherDescription"
                        component={TextField}
                        label="Please specify"
                        disabled={
                          formik.values.submitWithoutChangeReason !==
                          NoAddendumReason.OTHER
                        }
                      />
                    </div>
                  </FormControl>
                </Form>
              </ModalContent>
              <ModalFooter>
                <Button
                  onPress={closeOnCancel}
                  variant="secondary"
                  size="large"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  form="submitWithoutChanges"
                  variant="primary"
                  size="large"
                  onPress={() => {
                    trackEvent({
                      name: 'Submit Without Changes Addendum Button Clicked',
                      properties: {
                        providerId: provider.id,
                        patientUserId: clientId,
                        providerAppointmentId:
                          documentRemediation!.providerAppointmentId,
                        pageHeader: 'Submit without changes',
                        addendumRadioSelection:
                          formik.values.submitWithoutChangeReason || null,
                        errorEvent: !formik.isValid || !formik.dirty,
                      },
                    });
                  }}
                >
                  Submit without changes
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
