import {
  CalendarDate,
  DateDuration,
  DateValue,
  getLocalTimeZone,
  GregorianCalendar,
  today,
} from '@internationalized/date';
import moment, { Moment } from 'moment';

export const xFromNow = (duration: DateDuration) => {
  const localToday = today(getLocalTimeZone());

  return localToday.add(duration);
};

export const momentToDateValue = (momentDate: Moment): DateValue => {
  // Create a CalendarDate directly from Moment’s year, month, and day
  return new CalendarDate(
    new GregorianCalendar(),
    momentDate.year(),
    momentDate.month() + 1, // Moment months are 0-indexed, CalendarDate months are 1-indexed
    momentDate.date()
  );
};

export const dateValueToMoment = (dateValue: DateValue): Moment => {
  return moment({
    year: dateValue.year,
    month: dateValue.month - 1, // Moment months are 0-indexed
    day: dateValue.day,
  });
};
