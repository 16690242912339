import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { SessionDetailsFormV2Values } from '~/legacy/views/AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';
import {
  commonTimes,
  getAllTimes,
  getFiveMinuteIntervals,
} from '~/legacy/views/AppointmentConfirmation/components/forms/SessionDetails/utils';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserReadByProvider } from '@headway/api/models/UserReadByProvider';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { FormControl } from '@headway/helix/FormControl';
import { Link } from '@headway/helix/Link';
import { Item, Section, Select } from '@headway/helix/Select';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';
import { telehealthPlatformDisplayNames } from '@headway/shared/constants/telehealthPlatformDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { formatPatientName } from '@headway/shared/utils/patient';
import { CPTCodeComboBox } from '@headway/ui/CPTCodeComboBox';

import {
  BulkConfirmFormValues,
  UnconfirmedEventsData,
} from '../../utils/types';

interface SessionTableProps {
  provider: ProviderRead;
  unconfirmedEventsData: UnconfirmedEventsData;
  /** When provided, only the events in the list are displayed in the table. */
  displayedVirtualIds?: string[];
  readonly?: boolean;
}

export const SessionDetailsTable = ({
  provider,
  unconfirmedEventsData,
  displayedVirtualIds,
  readonly = false,
}: SessionTableProps) => {
  const { values } = useFormikContext<BulkConfirmFormValues>();
  const [patientHasCignaEapAuthorization] = useState<boolean>(false);
  const sessionTableColumns = getSessionTableColumns(provider);

  // TODO - REMOVE in GP-54 - Only logging for testing purposes
  console.log(values);

  return (
    <div className="grid">
      <Table aria-label="Table of Unconfirmed Sessions">
        <TableHeader>
          {sessionTableColumns.map((column: SessionTableColumns) => (
            <Column key={`${column.key}`}>{column.label}</Column>
          ))}
        </TableHeader>
        <TableBody>
          {Object.entries(values.sessions)
            .filter(([virtualId]) =>
              displayedVirtualIds
                ? displayedVirtualIds.includes(virtualId)
                : true
            )
            .map(([virtualId, sessionDetails]) => {
              const event = unconfirmedEventsData[virtualId].event;
              const patient = event.providerAppointment
                ?.patient as UserReadByProvider;
              const patientAddress =
                unconfirmedEventsData[virtualId].patientAddress;
              const providerAddress =
                unconfirmedEventsData[virtualId].providerAddress;

              const sessionTableCells = getSessionTableRowCells(
                event,
                provider,
                patient,
                sessionDetails,
                patientHasCignaEapAuthorization,
                readonly,
                patientAddress,
                providerAddress
              );

              return (
                <Row key={event.virtualId}>
                  {sessionTableColumns.map((column: SessionTableColumns) => (
                    <Cell key={`${column.key}-row`}>
                      {sessionTableCells[column.key]}
                    </Cell>
                  ))}
                </Row>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

type SessionTableColumns = {
  key: string;
  label: string;
};

const getSessionTableColumns = (
  provider: ProviderRead
): SessionTableColumns[] => {
  const columns = [
    {
      key: 'client',
      label: 'Client',
    },
    {
      key: 'diagnosisCodes',
      label: 'Diagnosis codes',
    },
    {
      key: 'startTime',
      label: 'Start time',
    },
    {
      key: 'duration',
      label: 'Duration',
    },
    {
      key: 'cptCodes',
      label: 'CPT codes',
    },
    {
      key: 'sessionLocation',
      label: 'Session location',
    },
    {
      key: 'edit',
      label: '',
    },
  ];

  if (provider.isPrescriber) {
    columns.splice(6, 0, {
      key: 'prescriptions',
      label: 'Prescriptions',
    });
  }
  return columns;
};

const getSessionTableRowCells = (
  event: ProviderEventRead,
  provider: ProviderRead,
  patient: UserReadByProvider,
  sessionDetails: SessionDetailsFormV2Values,
  patientHasCignaEapAuthorization: boolean,
  readonly: boolean,
  patientAddress?: PatientAddressRead,
  providerAddress?: ProviderAddressRead
): {
  [key: string]: React.ReactNode;
} => {
  const eventDatePart = moment(sessionDetails.startDate).format('YYYY-MM-DD');
  const fiveMinuteIntervals = getFiveMinuteIntervals(eventDatePart);
  const allTimes = getAllTimes();
  const { controlledSubstanceAttestation } = sessionDetails;

  return {
    client: (
      <b>
        <u>{formatPatientName(patient)}</u>
      </b>
    ),
    diagnosisCodes:
      readonly ||
      (sessionDetails.diagnosisCodes &&
        sessionDetails.diagnosisCodes?.length > 0) ? (
        sessionDetails?.diagnosisCodes?.map((code) => {
          return (
            <Badge key={code} variant="neutral">
              {code.value}
            </Badge>
          );
        })
      ) : (
        // TODO
        <Link
          onClick={() => {
            console.log('Open ');
          }}
        >
          Select code
        </Link>
      ),
    startTime: readonly ? (
      moment(sessionDetails.startDate).format('h:mm A')
    ) : (
      <FormControl
        component={Select}
        name={`sessions.${event.virtualId}.startDate`}
        selectionMode="single"
        menuWidth="stretch"
      >
        {fiveMinuteIntervals.map((interval) => {
          const intervalDate = moment(interval);
          const label = intervalDate.format('h:mma');
          const key = intervalDate.toISOString();

          return (
            <Item key={key} textValue={label}>
              {label}
            </Item>
          );
        })}
      </FormControl>
    ),
    duration: readonly ? (
      `${sessionDetails.duration} minutes`
    ) : (
      <FormControl
        name={`sessions.${event.virtualId}.duration`}
        component={Select}
        selectionMode="single"
        menuWidth="stretch"
      >
        <Section title="Common times">
          {commonTimes.map((time) => (
            <Item key={time.toString()} textValue={`${time} minutes`}>
              {time} minutes
            </Item>
          ))}
        </Section>
        <Section title="All times">
          {allTimes.map((time) => (
            <Item key={time.toString()} textValue={`${time} minutes`}>
              {time} minutes
            </Item>
          ))}
        </Section>
      </FormControl>
    ),
    cptCodes: readonly ? (
      sessionDetails?.cptCodes?.map((code: CPTCodeInfo) => {
        return (
          <Badge key={code.value} variant="neutral">
            {code.value}
          </Badge>
        );
      })
    ) : (
      <CPTCodeComboBox
        name={`sessions.${event.virtualId}.cptCodes`}
        label=""
        patient={patient}
        provider={provider}
        patientHasCignaEapAuthorization={patientHasCignaEapAuthorization}
        searchable
        selectionMode="multiple"
      />
    ),
    sessionLocation:
      sessionDetails.telehealth &&
      sessionDetails.appointmentLocationPatientAddressId &&
      sessionDetails.telehealthPlatform &&
      sessionDetails.telehealthProviderState &&
      patientAddress ? (
        <div className="grid gap-2">
          <ContentText>Virtual Telehealth</ContentText>

          <div className="grid gap-1">
            <ContentText variant="body-small">
              Type:{' '}
              {
                telehealthPlatformDisplayNames[
                  sessionDetails.telehealthPlatform
                ]
              }
            </ContentText>
            <ContentText variant="body-small">
              Client: {patientAddress.streetLine1}, {patientAddress.city},{' '}
              {statesToDisplayNames[patientAddress.state]}{' '}
              {patientAddress.zipCode}
            </ContentText>
            <ContentText variant="body-small">
              Provider:{' '}
              {statesToDisplayNames[sessionDetails.telehealthProviderState]}
            </ContentText>
          </div>
        </div>
      ) : providerAddress ? (
        <div className="grid gap-0">
          <ContentText>{providerAddress.streetLine1}</ContentText>
          <ContentText>
            {providerAddress.city}, {providerAddress.state}{' '}
            {providerAddress.zipCode}
          </ContentText>
        </div>
      ) : (
        // TODO
        <Link
          onClick={() => {
            console.log('Open ');
          }}
        >
          Select location
        </Link>
      ),
    prescriptions:
      provider.isPrescriber &&
      sessionDetails.didPrescribeControlledSubstance &&
      controlledSubstanceAttestation.prescribedSubstances &&
      controlledSubstanceAttestation.exceedsRecommendedDosage &&
      controlledSubstanceAttestation.prescribedSubstances.length > 0 ? (
        <div>
          {controlledSubstanceAttestation.prescribedSubstances.map(
            (substance) => {
              return (
                <Badge key={substance} variant="neutral">
                  {substance}
                </Badge>
              );
            }
          )}

          <ContentText>
            {controlledSubstanceAttestation.exceedsRecommendedDosage
              ? 'No, all prescriptions were within recommended limits'
              : 'Yes, at least one prescription exceeded recommended limits.'}

            <Link
              onClick={() => {
                console.log('Open sidesheet');
              }}
            >
              See more
            </Link>
          </ContentText>
        </div>
      ) : (
        // TODO
        <Link
          onClick={() => {
            console.log('Open sidesheet');
          }}
        >
          Select prescription
        </Link>
      ),
    edit: (
      <Button variant="link" onPress={() => console.log('Open sidesheet')}>
        Edit
      </Button>
    ),
  };
};
