import { css } from '@emotion/react';
import React from 'react';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { theme as legacyTheme } from '@headway/ui/theme';

import { useBillingAccountForBillingSettings } from '../../hooks/useBillingAccount';
import { hasRateAccess } from '../../utils/access';
import { Account } from './Account';
import { Billing } from './Billing';
import { CalendarSettings } from './CalendarSettings/CalendarSettings';
import { Locations } from './Locations';
import { Login } from './Login';
import { PracticePolicies } from './PracticePolicies';
import { Profile } from './Profile';
import { Referrals } from './Referrals';

interface SettingsProps {
  AuthStore: any;
}

export function Settings(props: SettingsProps) {
  const { AuthStore } = props;

  const hasFullSettingsAccess = hasRateAccess(
    AuthStore.provider,
    AuthStore.user
  );

  const logInTabEnabled = useFlag('enableLogInTab', false);

  const { billingAccountInfo } = useBillingAccountForBillingSettings();

  React.useLayoutEffect(() => {
    const body = document.body;

    body.classList.add('helix');

    return () => {
      body.classList.remove('helix');
    };
  }, []);

  return (
    <div
      css={{
        position: 'relative',
        '--hw-header-height': '50px',
        backgroundColor: theme.color.system.white,
        marginTop: 'var(--hw-header-height)',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        padding: '0 16px',
        paddingTop: 20,
        minHeight: 'calc(100vh - var(--hw-header-height))',
        ...theme.reset,
      }}
    >
      <div
        css={{
          width: '100%',
          margin: '0 auto',
          [legacyTheme.media.small]: {
            maxWidth: 927,
          },
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
        }}
      >
        <h1 css={{ fontSize: 0 }}>
          <PageHeader>Settings</PageHeader>
        </h1>
        <div css={{ overflow: 'hidden', width: '100%' }}>
          <div>
            <nav
              css={{
                overflow: 'auto',
                ...theme.stack.horizontal,
                borderBottom: `1px solid ${theme.color.system.borderGray}`,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <NavLink
                css={tabLinkCss}
                to="/settings/profile"
                data-intercom-target="settings-tab-profile"
              >
                Profile
              </NavLink>
              <NavLink
                css={tabLinkCss}
                to="/settings/locations"
                data-intercom-target="settings-tab-locations"
              >
                Locations
              </NavLink>
              {hasFullSettingsAccess && (
                <NavLink
                  css={tabLinkCss}
                  to="/settings/referrals"
                  data-intercom-target="settings-tab-referrals"
                >
                  Referrals
                </NavLink>
              )}
              {hasFullSettingsAccess && (
                <NavLink
                  css={tabLinkCss}
                  to="/settings/billing"
                  data-intercom-target="settings-tab-billing"
                >
                  <span
                    css={{
                      ...theme.stack.horizontal,
                      gap: theme.spacing.x1,
                      alignItems: 'center',
                    }}
                  >
                    <span>Billing</span>
                    {!billingAccountInfo?.isVerified && (
                      <IconWarningCircle
                        css={{ color: theme.color.primary.yellow }}
                      />
                    )}
                  </span>
                </NavLink>
              )}
              <NavLink
                css={tabLinkCss}
                to="/settings/calendar"
                data-intercom-target="settings-tab-calendar"
              >
                Calendar
              </NavLink>
              {hasFullSettingsAccess && (
                <NavLink
                  css={tabLinkCss}
                  to="/settings/account"
                  data-intercom-target="settings-tab-account"
                >
                  Contact Info
                </NavLink>
              )}
              {logInTabEnabled && (
                <NavLink
                  css={tabLinkCss}
                  to="/settings/login"
                  data-intercom-target="settings-tab-login"
                >
                  Login
                </NavLink>
              )}
              <NavLink
                css={tabLinkCss}
                to="/settings/practice-policies"
                data-intercom-target="settings-tab-practice-policies"
              >
                Practice policies
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="profile" element={<Profile />} />
        <Route path="locations" element={<Locations />} />
        {hasFullSettingsAccess && (
          <Route
            path="referrals"
            element={<Referrals AuthStore={AuthStore} />}
          />
        )}
        {hasFullSettingsAccess && (
          <Route path="billing" element={<Billing />} />
        )}
        <Route path="calendar" element={<CalendarSettings />} />
        {hasFullSettingsAccess && (
          <Route path="account" element={<Account />} />
        )}
        {logInTabEnabled && <Route path="login" element={<Login />} />}
        <Route
          path="practice-policies"
          element={<PracticePolicies AuthStore={AuthStore} />}
        />
        <Route path="*" element={<Navigate replace to="/settings/profile" />} />
      </Routes>
    </div>
  );
}

const tabLinkCss = css({
  padding: '11px 16px',
  ...theme.typography.body.regular,
  color: theme.color.system.gray,
  textDecoration: 'none',
  borderBottom: `2px solid transparent`,

  '&:focus': {
    outline: `2px solid ${theme.color.system.green}`,
    outlineOffset: '-2px',
    color: theme.color.system.gray,
  },
  '&:hover': {
    color: theme.color.system.textBlack,
  },
  '&.active': {
    color: theme.color.system.textBlack,
    ...theme.typography.body.medium,

    borderBottom: `2px solid ${theme.color.system.textBlack}`,
  },
});
