import moment from 'moment';
import * as Yup from 'yup';

import { CDSCertificate } from '@headway/api/models/CDSCertificate';
import { DEACertificate } from '@headway/api/models/DEACertificate';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { theme } from '@headway/helix/theme';
import {
  FieldControl,
  FieldDatePicker,
  FieldDropzone,
  FieldErrorText,
} from '@headway/ui/form';
import { FieldTextField } from '@headway/ui/form/FieldTextField';
import { FormRow } from '@headway/ui/form/FormRow';

import { onDropFiles } from '../../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { transformDateToNullOrStartOfDay } from '../../utils/transformDateToNullOrStartOfDay';
import { IDCertificationType } from './helpers';

export const IDCertificationForm = ({
  state,
  idCertType,
  initialValues,
}: {
  state: UnitedStates;
  idCertType: IDCertificationType;
  initialValues: ProviderQuestionnaireRawData;
}) => {
  const { provider } = useQuestionnaireContext();
  let formPrefix: string;
  let fieldDescription: string;
  let fieldDescriptionForUpload: string;
  let cert: DEACertificate | CDSCertificate | undefined;
  if (idCertType === IDCertificationType.DEA) {
    cert = initialValues.userInputtedDeaCertificates?.[state];
    formPrefix = `userInputtedDeaCertificates[${state}]`;
    fieldDescription = 'DEA registration';
    fieldDescriptionForUpload = `${fieldDescription} certificate`;
  } else {
    cert = initialValues.userInputtedCdsCertificates?.[state];
    formPrefix = `userInputtedCdsCertificates[${state}]`;
    fieldDescription = 'CDS license';
    fieldDescriptionForUpload = fieldDescription;
  }

  if (!cert) {
    return null;
  }

  return (
    <div>
      <div className="my-5">
        <b>{fieldDescription}</b>
      </div>
      <FieldControl name={`${formPrefix}number`} fullWidth={true}>
        <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
          <b>{fieldDescription} number</b>
        </span>
        <FieldTextField size="small" />
        <FieldErrorText />
      </FieldControl>
      <FormRow>
        <FieldControl name={`${formPrefix}issueDate`} fullWidth={true}>
          <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
            <b>Date issued</b>
          </span>
          <FieldDatePicker
            inputFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            variant="outlined"
            size="small"
            maxDate={moment()}
          />
          <FieldErrorText />
        </FieldControl>
        <FieldControl name={`${formPrefix}expirationDate`} fullWidth={true}>
          <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
            <b>Expiration date</b>
          </span>
          <FieldDatePicker
            inputFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            variant="outlined"
            size="small"
            minDate={moment()}
          />
          <FieldErrorText />
        </FieldControl>
      </FormRow>
      <FieldControl name={`${formPrefix}uploadUrl`} fullWidth={true}>
        <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
          <b>Upload a copy of your {fieldDescriptionForUpload}</b>
        </span>
        <FieldDropzone
          accept="application/pdf,image/*"
          onDrop={(file: any) => onDropFiles(provider.id, file)}
          css={{ marginBottom: '0', marginTop: '0' }}
        />
        <FieldErrorText />
      </FieldControl>
    </div>
  );
};

export const getCertificateValidationSchema = (type: string) => {
  return Yup.object().shape({
    number: Yup.string().required('This field is required.'),
    state: Yup.string().required('This field is required.'),
    issueDate: Yup.date()
      .nullable()
      .transform(transformDateToNullOrStartOfDay)
      .required('This field is required.')
      .max(new Date(), 'Issue date cannot be in the future.'),
    expirationDate: Yup.date()
      .nullable()
      .transform(transformDateToNullOrStartOfDay)
      .required('This field is required.')
      .min(new Date(), 'Expiration date cannot be in the past.'),
    uploadUrl: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          link: Yup.string(),
          name: Yup.string(),
        })
      )
      .required('This field is required.'),
  });
};
