import { useContext } from 'react';
import { ProgressNoteContext } from '~/legacy/views/AppointmentConfirmation/stores/ProgressNotesContext';

import { CommunicationType } from '@headway/api/models/CommunicationType';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { Banner } from '@headway/helix/Banner';
import { Link } from '@headway/helix/Link';

import { useRequestingDocument } from 'views/Calendar/utils/documentationRequirement';

export const DocumentationRequirementBanner = ({
  requiringCarrier,
}: {
  requiringCarrier: FrontEndCarrierRead | undefined;
}) => {
  const { progressNote } = useContext(ProgressNoteContext);
  const { data: documentRequest } = useRequestingDocument(
    progressNote?.providerAppointmentId
  );

  return documentRequest?.communicationType !==
    CommunicationType.FRIENDLY_MESSAGING ? (
    <Banner variant="warning">
      Your complete progress notes are required{' '}
      {requiringCarrier ? `by ${requiringCarrier.name}` : ''} to receive
      payment. Please upload or submit notes using one of our templates. Learn
      more about{' '}
      <Link
        href="https://findheadway.zendesk.com/hc/en-us/articles/13042026808212-Keeping-Progress-Notes-on-Headway"
        target="_blank"
        rel="noreferrer"
      >
        best practices
      </Link>
      .
    </Banner>
  ) : null;
};
