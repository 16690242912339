import { useCallback, useContext } from 'react';
import { useUpdateProviderProgressNoteMutation } from '~/legacy/mutations/providerProgressNote';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';

import { ProgressNoteFormV2Values } from '../../components/forms/ProgressNote/ProgressNoteFormV2';
import {
  createNoteJsonObject,
  getTemplate,
  getTemplateIdAndVersionFromFormValue,
} from '../../components/forms/ProgressNote/utils';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useSignProgressNote = () => {
  const {
    progressNote,
    switchToNoteSigned,
    setProgressNoteErrorsFromSubmitting,
    templates,
  } = useContext(AppointmentConfirmationContextV2);
  const updateProviderProgressNoteMutation =
    useUpdateProviderProgressNoteMutation();

  const signProgressNote = useCallback(
    async (values: any) => {
      if (!progressNote) return;

      const progressNoteValues: ProgressNoteFormV2Values = values.progressNote;

      const selectedTemplate = getTemplate(
        templates,
        ...getTemplateIdAndVersionFromFormValue(progressNoteValues.template)
      );

      if (!selectedTemplate) return;

      const {
        template,
        progressNoteType,
        previousNote,
        lateEntryReason,
        lateEntryOtherReason,
        ...response
      } = progressNoteValues;

      const noteJson = createNoteJsonObject(selectedTemplate, response);

      const result = await updateProviderProgressNoteMutation.mutateAsync({
        progressNoteId: progressNote.id,
        update: {
          provider_progress_note_data: {
            noteJson:
              progressNoteValues.progressNoteType === ProgressNoteType.NONE
                ? {}
                : noteJson,
            attestedOn: new Date().toISOString(),
            lateEntryReason,
            lateEntryOtherReason,
          },
          note_json_prefilled_from: progressNoteValues.previousNote,
        },
      });

      if (result?.errors?.length) {
        setProgressNoteErrorsFromSubmitting(result.errors);
      } else {
        // TODO: Check if that is still relevant to track at this moment
        // trackButtonClicked('Sign Note Button Clicked');
        setProgressNoteErrorsFromSubmitting(undefined);
        switchToNoteSigned();
      }
    },
    [
      updateProviderProgressNoteMutation,
      progressNote,
      switchToNoteSigned,
      setProgressNoteErrorsFromSubmitting,
      templates,
    ]
  );

  return { signProgressNote };
};
