import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

interface UsePatientSummariesForProviderQueryKeyArgs {
  providerId: number | undefined;
}

const getPatientSummariesForProviderQueryKey = ({
  providerId,
}: UsePatientSummariesForProviderQueryKeyArgs): [string, number] => [
  'patient-summaries-for-provider',
  providerId!,
];

const { useSingleQuery: usePatientsByProviderId } = createBasicApiHooks(
  getPatientSummariesForProviderQueryKey,
  ({ providerId }) => {
    return ProviderApi.getPatientSummariesForProvider(providerId!);
  },
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch patients for provider'
);

export { usePatientsByProviderId };
