import React, { useMemo } from 'react';

import { TextArea } from '@headway/helix/TextArea';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';
import { ProgressNoteComponentMetadata } from '../Renderer/v3/types';
import { getInstructionalText, getOptionalityText } from '../utils';

const MemoizedTextArea = React.memo(TextArea);

const EXTENDED_HEIGHT_ROWS = 6;
const MAX_ROWS = 25;

export const FormTextArea: React.FC<SchemaComponent> = ({
  element: {
    id,
    title,
    placeholder,
    subTitle,
    description,
    metadata = {},
    optionalityText,
  },
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}) => {
  const { extendedTextArea } = metadata as ProgressNoteComponentMetadata;
  const sizing = React.useMemo(
    () => ({
      max: MAX_ROWS,
      min: extendedTextArea ? EXTENDED_HEIGHT_ROWS : undefined,
    }),
    [extendedTextArea]
  );

  const optionality = useMemo(
    () => getOptionalityText(isOptional, optionalityText),
    [isOptional, optionalityText]
  );

  const instructionalText = useMemo(
    () => getInstructionalText(subTitle, { requiresEdit }),
    [subTitle, requiresEdit]
  );

  return (
    <FormControlRHF
      name={id}
      disabled={disabled}
      component={MemoizedTextArea}
      label={title}
      key={id}
      optionalityText={optionality}
      placeholder={placeholder}
      instructionalText={instructionalText}
      helpText={description}
      sizing={sizing}
      aria-label="Progress-notes text area"
    />
  );
};
