/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GAD7Submission {
    GAD7_1: number;

    GAD7_2: number;

    GAD7_3: number;

    GAD7_4: number;

    GAD7_5: number;

    GAD7_6: number;

    GAD7_7: number;

    assessmentType?: GAD7SubmissionAssessmentType;

}


    export enum GAD7SubmissionAssessmentType {
            GAD7 = 'GAD7'
    }

