import { CloseTwoTone } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  Grow,
  GrowProps,
  IconButton,
  Slide,
  SlideProps,
} from '@mui/material';
import React from 'react';

import { SectionHeader } from '@headway/helix/SectionHeader';
import { useMediaQuery } from '@headway/helix/utils';

import { theme } from './theme';
import { VisuallyHidden } from './VisuallyHidden';

const GrowTransition = React.forwardRef(function Transition(
  props: GrowProps,
  ref
) {
  return <Grow ref={ref} timeout={150} {...props} />;
});

const SlideTransition = React.forwardRef(function Transition(
  props: SlideProps,
  ref
) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});

interface ModalBaseProps {
  closeable?: boolean;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  dialogContentStyle?: any;
  onClose?: () => void;
  variant?: 'compass' | 'legacy';
}

export type ModalProps = Omit<DialogProps, 'title'> &
  Pick<DialogContentProps, 'dividers'> &
  ModalBaseProps;

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  closeable = true,
  open,
  onClose,
  title,
  children,
  actions,
  dividers = true,
  keepMounted = false,
  dialogContentStyle,
  variant = 'legacy',
  ...rest
}) => {
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);
  return (
    <Dialog
      open={open}
      TransitionComponent={matches ? SlideTransition : GrowTransition}
      keepMounted={keepMounted}
      fullWidth={true}
      scroll="paper"
      onClose={onClose}
      fullScreen={matches}
      {...rest}
    >
      {variant === 'compass' ? (
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            position: 'relative',
          }}
        >
          {title && (
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: theme.fontFamily.postGrotesk,
                padding: matches ? theme.space.base : theme.space.lg,
                fontSize: matches ? theme.fontSize.lg : theme.fontSize.xl3,
                color: theme.color.black,
              }}
            >
              {title}
            </DialogTitle>
          )}
          {closeable && (
            <IconButton
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 0,
                margin: matches ? theme.space.xs : theme.space.base,
                marginLeft: 'auto',
                zIndex: 1005, // Some elements have z-index = 1000, don't want them to cover the X
              }}
              size="large"
            >
              <CloseTwoTone />
              <VisuallyHidden>Close dialog</VisuallyHidden>
            </IconButton>
          )}
        </div>
      ) : (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            padding: 20,
            gap: 20,
          }}
        >
          {title && (
            <DialogTitle
              sx={(muiTheme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontFamily: theme.fontFamily.brandText,
                color: muiTheme.palette.primary.main,
                flexGrow: 1,
                flexShrink: 1,
                margin: 0,
                padding: 0,
              })}
            >
              <SectionHeader>{title}</SectionHeader>
            </DialogTitle>
          )}
          {closeable && (
            <IconButton
              onClick={onClose}
              sx={{
                padding: 0,
                zIndex: 1005, // Some elements have z-index = 1000, don't want them to cover the X
              }}
              size="large"
            >
              <CloseTwoTone />
              <VisuallyHidden>Close dialog</VisuallyHidden>
            </IconButton>
          )}
        </div>
      )}
      <DialogContent
        dividers={dividers}
        sx={
          matches
            ? dialogContentStyle
              ? dialogContentStyle
              : { padding: theme.space.base }
            : dialogContentStyle
            ? dialogContentStyle
            : {}
        }
      >
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
