import { useFormikContext } from 'formik';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxTree, CheckboxTreeItem } from '@headway/helix/CheckboxTree';
import { FormControl } from '@headway/helix/FormControl';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { formatPatientName } from '@headway/shared/utils/patient';

import { shouldRequireTelehealthAttestation } from 'views/AppointmentConfirmation/hooks/validationSchemas/useSessionDetailsValidationSchema';

import {
  BulkConfirmFormValues,
  UnconfirmedEventsData,
} from '../../utils/types';

interface AttestationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  providerName: string;
  unconfirmedEventsData: UnconfirmedEventsData;
  /**
   * ID of the parent form element, which is necesssary because Modals are rendered in a portal so
   * they are not direct children of the form.
   */
  formId: string;
}

const SAFETY_KEY = 'safety';

/**
 * Displays attestation checkboxes for each client along with a form submission button.
 * Intended for use in the bulk confirm flow, and assumes it is the child of a Formik context.
 */
export const AttestationModal = ({
  isOpen,
  onDismiss,
  providerName,
  unconfirmedEventsData,
  formId,
}: AttestationModalProps) => {
  const { values, setFieldValue } = useFormikContext<BulkConfirmFormValues>();

  const sessionsNeedingTelehealthAttestation = Object.entries(values.sessions)
    .filter(([, session]) => shouldRequireTelehealthAttestation(session))
    .map(([virtualId]) => virtualId);
  const patientsNeedingTelehealthAttestation = uniqBy(
    sessionsNeedingTelehealthAttestation.map(
      (virtualId) =>
        unconfirmedEventsData[virtualId].event.providerAppointment!.patient!
    ),
    (patient) => patient.id
  );

  const sessionCount = Object.keys(values.sessions).length;

  const checkboxItems = [
    {
      id: SAFETY_KEY,
      name:
        patientsNeedingTelehealthAttestation.length === 1
          ? `${providerName} assessed that the client they saw via virtual telehealth is in a safe, private, and known location`
          : `${providerName} assessed that the ${patientsNeedingTelehealthAttestation.length} clients they saw via virtual telehealth are in a safe, private, and known location.`,
      childItems: sortBy(
        patientsNeedingTelehealthAttestation,
        ({ displayLastName, lastName }) => displayLastName || lastName,
        ({ displayFirstName, firstName }) => displayFirstName || firstName
      ).map((patient) => ({
        id: patient.id,
        name: formatPatientName(patient),
      })),
    },
  ];

  const onSafetyAttestationSelectionChange = (
    selected: Set<string | number>
  ) => {
    for (const virtualId of sessionsNeedingTelehealthAttestation) {
      const patientId =
        unconfirmedEventsData[virtualId].event.providerAppointment!.patient!.id;
      setFieldValue(
        `sessions.${virtualId}.telehealthAttestation`,
        selected.has(patientId)
      );
    }
  };

  const selectedKeys: Set<string | number> = new Set(
    patientsNeedingTelehealthAttestation
      .filter((patient) => {
        // Check if all sessions for this patient have been attested
        const relatedSessions = sessionsNeedingTelehealthAttestation.filter(
          (virtualId) =>
            unconfirmedEventsData[virtualId].event.providerAppointment!.patient!
              .id === patient.id
        );
        return relatedSessions.every(
          (virtualId) => values.sessions[virtualId].telehealthAttestation
        );
      })
      .map((patient) => patient.id)
  );
  if (selectedKeys.size === patientsNeedingTelehealthAttestation.length) {
    selectedKeys.add(SAFETY_KEY);
  }

  return (
    <Modal
      isOpen={isOpen}
      title={`Attestations: ${sessionCount} sessions`}
      onDismiss={onDismiss}
    >
      <ModalContent>
        <div className="space-y-5">
          <FormControl component={Checkbox} name="notesAttestation">
            <span>
              <b>Progress notes:</b> I understand that compliant notes are
              required for payment. {providerName}'s notes are true, accurate
              and meet insurer requirements, and we'll provider our notes on
              request within 3 business days.
            </span>
          </FormControl>
          {patientsNeedingTelehealthAttestation.length > 0 && (
            <CheckboxTree
              aria-label="Telehealth safety attestation"
              items={checkboxItems}
              defaultExpandedKeys={[SAFETY_KEY]}
              selectedKeys={selectedKeys}
              onSelectionChange={(selected) =>
                onSafetyAttestationSelectionChange(selected as Set<string>)
              }
            >
              {(item) => {
                return (
                  <CheckboxTreeItem
                    childItems={item.childItems}
                    item={item}
                    textValue={item.name}
                  >
                    {item.id === SAFETY_KEY && (
                      <b>Telehealth client safety: </b>
                    )}
                    {item.name}
                  </CheckboxTreeItem>
                );
              }}
            </CheckboxTree>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onPress={onDismiss}>Cancel</Button>
        <Button form={formId} type="submit">
          Confirm {sessionCount} sessions
        </Button>
      </ModalFooter>
    </Modal>
  );
};
