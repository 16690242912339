import React, { useContext } from 'react';

import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';

import { AppointmentContext } from '../../../../stores/AppointmentContext';
import { SessionDetailsFormValues } from '../SessionDetailsForm';

export const InsuranceStatusBannerForWarning = ({
  patient,
  sessionDetailsFormValues,
}: {
  patient: UserRead;
  sessionDetailsFormValues: SessionDetailsFormValues;
}) => {
  const {
    appointmentAddressState,
    isInsuranceAppointment,
    insuranceStatus,
    insuranceStatusWithCheckState,
  } = useContext(AppointmentContext);
  const { restrictionDate } = useMSCGuardrail();

  const sessionLocationPicked = !!sessionDetailsFormValues.providerAddressId;
  if (!sessionLocationPicked) {
    return null;
  }

  const telehealthWithASelectedPatientLocation =
    sessionDetailsFormValues.providerAddressId === -1 &&
    !!sessionDetailsFormValues.appointmentLocationPatientAddressId;
  const telehealthWithNoPatientLocationCausePatientHasNoAddresses =
    sessionDetailsFormValues.providerAddressId === -1 &&
    !sessionDetailsFormValues.appointmentLocationPatientAddressId &&
    patient.patientStates?.length === 0;

  const isInsuranceStatusInNetwork =
    insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK ||
    (insuranceStatus === PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK &&
      sessionDetailsFormValues.telehealth);
  const isInsuranceStatusWithCheckStateInNetwork =
    insuranceStatusWithCheckState === PatientInsuranceOrEAPStatus.IN_NETWORK ||
    (insuranceStatusWithCheckState ===
      PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK &&
      sessionDetailsFormValues.telehealth);
  const areBothInsuranceStatusesInNetwork =
    isInsuranceStatusInNetwork && isInsuranceStatusWithCheckStateInNetwork;

  /**
   * Dont show anything if they are,
   * - self pay
   * - in network, telehealth with no addresses
   * - in network, in person
   */
  if (
    !isInsuranceAppointment ||
    (isInsuranceStatusInNetwork &&
      sessionDetailsFormValues.providerAddressId &&
      sessionDetailsFormValues.providerAddressId > 0) ||
    telehealthWithNoPatientLocationCausePatientHasNoAddresses
  ) {
    return null;
  }

  if (areBothInsuranceStatusesInNetwork) {
    return null;
  }

  if (
    !telehealthWithASelectedPatientLocation &&
    !telehealthWithNoPatientLocationCausePatientHasNoAddresses &&
    isInsuranceStatusInNetwork
  ) {
    return null;
  }

  const appointmentState = appointmentAddressState
    ? statesToDisplayNames[appointmentAddressState as UnitedStates]
    : undefined;

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const guidanceCardWarningCopy =
    isInsuranceStatusInNetwork &&
    !isInsuranceStatusWithCheckStateInNetwork &&
    sessionDetailsFormValues.appointmentLocationPatientAddressId
      ? `You must be licensed and credentialed in ${appointmentState} to see ${patientName} at this location. Starting after ${restrictionDate}, we'll block session confirmation if there is a mismatch.`
      : `Looks like ${patientName} doesn't have any locations on file where you are credentialed to practice on Headway. Starting after ${restrictionDate}, we'll block telehealth session confirmation if there is a mismatch.`;

  return (
    <GuidanceCard variant="warning" layout="vertical">
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <BodyText>{guidanceCardWarningCopy}</BodyText>
        <div className="mb-2.5 mt-3 text-left">
          <LinkButton
            variant="link"
            href={CREDENTIALING_REQS_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </LinkButton>
        </div>
      </div>
    </GuidanceCard>
  );
};
