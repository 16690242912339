import { theme } from '@headway/helix/theme';
import { SESSION_CONFIRMATION_FLOW_REVAMP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { DoAndDont } from '../../../components/DoAndDont';
import { ProgressNoteComponentMetadata } from '../../../types';
import {
  doesComponentExpectAnArrayResponse,
  isElementASection,
} from '../../utils';
import { TemplateRenderedComponent } from '../TemplateRenderedComponent';
import { TemplateRenderedComponentV2 } from '../TemplateRenderedComponentV2';
import { GenericTemplate, TemplateValues } from '../types';
import {
  Component,
  DoAndDontData,
  InfoComponentTypes,
  Section,
  TemplateV2,
} from './types';

type SchemaRendererProps<T> = {
  template?: GenericTemplate<TemplateV2<T>>['template'];
  disabled?: boolean;
};

export function getInitialTemplateValues<T>(
  template?: TemplateV2<T>,
  values?: TemplateValues
) {
  const setInitialValue = (
    obj: any,
    component: Component<ProgressNoteComponentMetadata>
  ) => {
    let initialValue: string | string[] = '';
    if (values?.[component.id]) {
      initialValue = values[component.id];
    } else if (doesComponentExpectAnArrayResponse(component)) {
      initialValue = [];
    }
    return (obj[component.id] = initialValue);
  };

  const obj: any = {};

  if (template) {
    for (let i = 0; i < template.length; i++) {
      const element: any = template[i];

      if (isElementASection(element)) {
        (
          element.components as Component<ProgressNoteComponentMetadata>[]
        ).forEach((component: Component<ProgressNoteComponentMetadata>) =>
          setInitialValue(obj, component)
        );
      } else setInitialValue(obj, element);
    }
  }

  return obj;
}

export function Renderer<T>({ template, disabled }: SchemaRendererProps<T>) {
  const isSessionConfirmationFlowRevampEnabled = useFlag(
    SESSION_CONFIRMATION_FLOW_REVAMP,
    false
  );
  return template ? (
    <>
      {template.map((element: Section<T> | Component<T>, i: number) => {
        if (isElementASection<T>(element)) {
          const section = element as Section<T>;
          return (
            <div
              key={i}
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x4,
              }}
            >
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  css={[
                    theme.typography.sectionHeader,
                    {
                      color: disabled
                        ? theme.color.system.disabledGray
                        : theme.color.system.textBlack,
                    },
                  ]}
                >
                  {section.header}
                </span>
                {section.subHeader && (
                  <span
                    css={[
                      theme.typography.subbody.regular,
                      {
                        color: disabled
                          ? theme.color.system.disabledGray
                          : theme.color.system.textBlack,
                      },
                    ]}
                  >
                    {section.subHeader}
                  </span>
                )}
              </div>
              {section.infoComponent && section.infoComponent.data ? (
                section.infoComponent.type === InfoComponentTypes.doAndDont ? (
                  <DoAndDont
                    data={section.infoComponent.data as DoAndDontData}
                  />
                ) : section.infoComponent.type ===
                  InfoComponentTypes.infoList ? (
                  <></>
                ) : null
              ) : null}
              {(section.components as Component<T>[]).map(
                (component: Component<T>) => {
                  return isSessionConfirmationFlowRevampEnabled ? (
                    <TemplateRenderedComponentV2
                      disabled={disabled}
                      component={component}
                      template={template}
                    />
                  ) : (
                    <TemplateRenderedComponent
                      disabled={disabled}
                      component={component}
                      template={template}
                    />
                  );
                }
              )}
            </div>
          );
        } else {
          const component = element as Component<T>;

          return isSessionConfirmationFlowRevampEnabled ? (
            <TemplateRenderedComponentV2
              disabled={disabled}
              component={component}
              template={template}
            />
          ) : (
            <TemplateRenderedComponent
              disabled={disabled}
              component={component}
              template={template}
            />
          );
        }
      })}
    </>
  ) : null;
}
