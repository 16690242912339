import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';

// Two weeks after the cutoff date to give time for folks to see it and respond accordingly
export const AETNA_IFP_WARNING_CUTOFF_DATE = new Date('2025-01-14T00:00:00Z');

export const shouldShowAetnaIFPWarning = (
  enableAetnaIFPWarning: boolean,
  clientMemberId?: string,
  clientFrontEndCarrier?: FrontEndCarrierIdentifier
): boolean => {
  if (!clientFrontEndCarrier || !clientMemberId) {
    return false;
  }
  const currentDate = new Date();
  return (
    enableAetnaIFPWarning &&
    currentDate.getTime() <= AETNA_IFP_WARNING_CUTOFF_DATE.getTime() &&
    clientFrontEndCarrier === FrontEndCarrierIdentifier.AETNA &&
    (clientMemberId?.startsWith('100') ||
      clientMemberId?.startsWith('101') ||
      clientMemberId?.startsWith('102'))
  );
};
