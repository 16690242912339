import { ProviderProgressNoteCreateWithMetadataInfo } from '@headway/api/models/ProviderProgressNoteCreateWithMetadataInfo';
import { ProviderProgressNoteUpdateWithMetadataInfo } from '@headway/api/models/ProviderProgressNoteUpdateWithMetadataInfo';
import { ProviderProgressNoteWithErrors } from '@headway/api/models/ProviderProgressNoteWithErrors';
import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export const useCreateProviderProgressNoteMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderProgressNoteWithErrors,
    unknown,
    ProviderProgressNoteCreateWithMetadataInfo
  > = {}
) => {
  return useMutationWithSideEffects(
    (
      providerProgressNoteCreateWithMetadataInfo: ProviderProgressNoteCreateWithMetadataInfo
    ) =>
      ProviderProgressNotesApi.createProviderProgressNote(
        providerProgressNoteCreateWithMetadataInfo
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderProgressNoteWithErrors,
        unknown,
        ProviderProgressNoteCreateWithMetadataInfo
      >()
        .addErrorLogging(
          () => 'There was a problem creating the provider progress note'
        )
        .merge(options.sideEffects),
    }
  );
};

export interface UpdateProviderProgressNoteMutationArgs {
  progressNoteId: number;
  update: ProviderProgressNoteUpdateWithMetadataInfo;
}
export const useUpdateProviderProgressNoteMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderProgressNoteWithErrors,
    unknown,
    UpdateProviderProgressNoteMutationArgs
  > = {}
) => {
  return useMutationWithSideEffects(
    ({ progressNoteId, update }: UpdateProviderProgressNoteMutationArgs) =>
      ProviderProgressNotesApi.updateProviderProgressNote(
        progressNoteId,
        update
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderProgressNoteWithErrors,
        unknown,
        UpdateProviderProgressNoteMutationArgs
      >()
        .addErrorLogging(
          () => 'There was a problem updating the provider progress note'
        )
        .merge(options.sideEffects),
    }
  );
};
