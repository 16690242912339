import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Badge } from '@headway/helix/Badge';
import { ContentText } from '@headway/helix/ContentText';

import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { useGetIsPromsScheduleRequired } from 'hooks/useGetIsPromsScheduleRequired';

import { MultiAssessmentScheduleInput } from '../../Clients/Assessments/components/MultiAssessmentScheduleInput';
import { INCENTIVE_BONUS_AMOUNT_DOLLARS } from '../../Incentives/constants';

type InviteClientPromsUpsellProps = {
  provider: ProviderRead;
  providerPatient: ProviderPatientRead;
};

export function InviteClientPromsUpsell({
  provider: { id: providerId },
  providerPatient,
}: InviteClientPromsUpsellProps) {
  const { data: incentiveEnrollment, isLoading } =
    useProviderIncentiveProgramEnrollment({ providerId });

  const { data: isPromsScheduleRequiredDetails } =
    useGetIsPromsScheduleRequired({
      providerPatientId: providerPatient.id,
    });

  if (isLoading) {
    return null;
  }

  const isPromsScheduleRequiredWithCarrierName =
    isPromsScheduleRequiredDetails &&
    isPromsScheduleRequiredDetails.isPromsScheduleRequired &&
    isPromsScheduleRequiredDetails.carrierName;

  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="flex gap-2">
          <ContentText variant="body-large/medium">
            Send assessments
          </ContentText>
          {isPromsScheduleRequiredWithCarrierName ? (
            <Badge variant="info">Required for this plan</Badge>
          ) : incentiveEnrollment?.isProviderEnrolled ? (
            <Badge variant="info">Rate boost qualified</Badge>
          ) : (
            <Badge variant="positive">Recommended</Badge>
          )}
        </div>
        <ContentText>
          {isPromsScheduleRequiredWithCarrierName ? (
            `New clients on the ${isPromsScheduleRequiredDetails.carrierName} plan are required to complete at least one assessment. `
          ) : incentiveEnrollment?.isProviderEnrolled ? (
            <>
              Earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} extra for{' '}
              <strong>each and every session</strong> when your client completes
              assessments.
            </>
          ) : (
            'Assessments are clinical questionnaires that help both you and your client track progress over time.'
          )}{' '}
          You can change or customize assessments at any time from the
          Assessments tab.
        </ContentText>
      </div>
      <MultiAssessmentScheduleInput name="assessmentSchedules" />
    </div>
  );
}
