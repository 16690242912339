import { useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useInsuranceStatus } from '~/legacy/hooks/useInsuranceStatus';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';

import { AppointmentConfirmationContextV2 } from '../../../../stores/AppointmentConfirmationContextV2';
import { AppointmentConfirmationModalFormV2Values } from '../../../modals/AppointmentConfirmationModalV2';
import { SessionDetailsFormV2Values } from '../SessionDetailsFormV2';

export const InsuranceStatusBannerForWarningV2 = () => {
  const { event, patient, appointmentAddressState } = useContext(
    AppointmentConfirmationContextV2
  );
  const { isMSCGuardrailWarning, restrictionDate } = useMSCGuardrail();
  const sessionDetailsValues: SessionDetailsFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      name: 'sessionDetails',
    });
  const sessionLocationPicked = !!sessionDetailsValues.providerAddressId;

  // Determines if the patient is network or not with the provider
  // using the given appointment details.
  const {
    insuranceStatus: insuranceStatusFetched,
    isLoading: isInsuranceStatusLoading,
  } = useInsuranceStatus(
    patient,
    patient?.activeUserInsurance,
    // This means it's telehealth
    sessionDetailsValues.providerAddressId === -1,
    appointmentAddressState
  );
  const {
    insuranceStatus: insuranceStatusWithCheckStateFetched,
    isLoading: isInsuranceStatusWithCheckStateLoading,
  } = useInsuranceStatus(
    patient,
    patient?.activeUserInsurance,
    // This means it's telehealth
    sessionDetailsValues.providerAddressId === -1,
    appointmentAddressState,
    !!isMSCGuardrailWarning
  );
  const insuranceStatus = isInsuranceStatusLoading
    ? PatientInsuranceOrEAPStatus.IN_NETWORK
    : insuranceStatusFetched;
  const insuranceStatusWithCheckState = isInsuranceStatusWithCheckStateLoading
    ? PatientInsuranceOrEAPStatus.IN_NETWORK
    : insuranceStatusWithCheckStateFetched;

  const isInsuranceAppointment = useMemo(() => {
    return event?.providerAppointment?.billingType === BillingType.INSURANCE;
  }, [event?.providerAppointment?.billingType]);

  if (!sessionLocationPicked) {
    return null;
  }

  const telehealthWithASelectedPatientLocation =
    sessionDetailsValues.providerAddressId === -1 &&
    !!sessionDetailsValues.appointmentLocationPatientAddressId;
  const telehealthWithNoPatientLocationCausePatientHasNoAddresses =
    sessionDetailsValues.providerAddressId === -1 &&
    !sessionDetailsValues.appointmentLocationPatientAddressId &&
    patient?.patientStates?.length === 0;

  const isInsuranceStatusInNetwork =
    insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK ||
    (insuranceStatus === PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK &&
      sessionDetailsValues.telehealth);
  const isInsuranceStatusWithCheckStateInNetwork =
    insuranceStatusWithCheckState === PatientInsuranceOrEAPStatus.IN_NETWORK ||
    (insuranceStatusWithCheckState ===
      PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK &&
      sessionDetailsValues.telehealth);
  const areBothInsuranceStatusesInNetwork =
    isInsuranceStatusInNetwork && isInsuranceStatusWithCheckStateInNetwork;

  /**
   * Dont show anything if they are,
   * - self pay
   * - in network, telehealth with no addresses
   * - in network, in person
   */
  if (
    !isInsuranceAppointment ||
    (isInsuranceStatusInNetwork &&
      sessionDetailsValues.providerAddressId &&
      sessionDetailsValues.providerAddressId > 0) ||
    telehealthWithNoPatientLocationCausePatientHasNoAddresses
  ) {
    return null;
  }

  if (areBothInsuranceStatusesInNetwork) {
    return null;
  }

  if (
    !telehealthWithASelectedPatientLocation &&
    !telehealthWithNoPatientLocationCausePatientHasNoAddresses &&
    isInsuranceStatusInNetwork
  ) {
    return null;
  }

  const appointmentState = appointmentAddressState
    ? statesToDisplayNames[appointmentAddressState as UnitedStates]
    : undefined;

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const guidanceCardWarningCopy =
    isInsuranceStatusInNetwork &&
    !isInsuranceStatusWithCheckStateInNetwork &&
    sessionDetailsValues.appointmentLocationPatientAddressId
      ? `You must be licensed and credentialed in ${appointmentState} to see ${patientName} at this location. Starting after ${restrictionDate}, we'll block session confirmation if there is a mismatch.`
      : `Looks like ${patientName} doesn't have any locations on file where you are credentialed to practice on Headway. Starting after ${restrictionDate}, we'll block telehealth session confirmation if there is a mismatch.`;

  return (
    <GuidanceCard variant="warning" layout="vertical">
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <BodyText>{guidanceCardWarningCopy}</BodyText>
        <div className="mb-2.5 mt-3 text-left">
          <LinkButton
            variant="link"
            href={CREDENTIALING_REQS_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </LinkButton>
        </div>
      </div>
    </GuidanceCard>
  );
};
