import { useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useInsuranceStatus } from '~/legacy/hooks/useInsuranceStatus';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import { formatPatientName } from '@headway/shared/utils/patient';

import {
  doesAppointmentHaveCrisisCode,
  insuranceStatusAllowedWithCrisisCodeException,
} from 'views/Calendar/events/util/events';

import { AppointmentConfirmationContextV2 } from '../../../../stores/AppointmentConfirmationContextV2';
import { AppointmentConfirmationModalFormV2Values } from '../../../modals/AppointmentConfirmationModalV2';
import { SessionDetailsFormV2Values } from '../SessionDetailsFormV2';

export const InsuranceStatusBannerV2 = () => {
  const { event, patient, appointmentAddressState } = useContext(
    AppointmentConfirmationContextV2
  );
  const { control } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const sessionDetailsValues: SessionDetailsFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      control,
      name: 'sessionDetails',
    });
  const cptCodes = sessionDetailsValues.cptCodes;
  const cptCodesValues = cptCodes?.map((code) => code.value) || [];

  // Determines if the patient is network or not with the provider
  // using the given appointment details.
  const {
    insuranceStatus: insuranceStatusFetched,
    isLoading: isInsuranceStatusLoading,
  } = useInsuranceStatus(
    patient,
    patient?.activeUserInsurance,
    // This means it's telehealth
    sessionDetailsValues.providerAddressId === -1,
    appointmentAddressState
  );
  const insuranceStatus = isInsuranceStatusLoading
    ? PatientInsuranceOrEAPStatus.IN_NETWORK
    : insuranceStatusFetched;

  // Don't show error banner if there is a crisis code exception
  const isCrisisCodeExempt =
    insuranceStatusAllowedWithCrisisCodeException.includes(insuranceStatus) &&
    doesAppointmentHaveCrisisCode(cptCodesValues);

  const isInsuranceAppointment = useMemo(() => {
    return event?.providerAppointment?.billingType === BillingType.INSURANCE;
  }, [event?.providerAppointment?.billingType]);

  if (isCrisisCodeExempt) {
    return null;
  }

  /**
   * Dont show anything if they are,
   * - self pay
   * - in network, telehealth with a patient location selected
   * - in network, in person
   */
  if (
    !isInsuranceAppointment ||
    ((insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK ||
      insuranceStatus === PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK) &&
      sessionDetailsValues.providerAddressId === -1 &&
      sessionDetailsValues.appointmentLocationPatientAddressId) ||
    (insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK &&
      sessionDetailsValues.providerAddressId &&
      sessionDetailsValues.providerAddressId > 0)
  ) {
    return null;
  }

  const sessionLocationPicked = !!sessionDetailsValues.providerAddressId;
  const telehealthWithASelectedPatientLocation =
    sessionDetailsValues.providerAddressId === -1 &&
    !!sessionDetailsValues.appointmentLocationPatientAddressId;
  const telehealthWithNoPatientLocationCausePatientHasNoAddresses =
    sessionDetailsValues.providerAddressId === -1 &&
    !sessionDetailsValues.appointmentLocationPatientAddressId &&
    patient?.patientStates?.length === 0;

  if (
    !sessionLocationPicked ||
    (!telehealthWithASelectedPatientLocation &&
      !telehealthWithNoPatientLocationCausePatientHasNoAddresses)
  ) {
    return null;
  }

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const appointmentState = appointmentAddressState
    ? statesToDisplayNames[appointmentAddressState as UnitedStates]
    : undefined;

  const supportedErrorMessages = [
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK,
    PatientInsuranceOrEAPStatus.IN_NETWORK, // only valid if appointment is virtual and patient has 0 virtual session locations
  ];
  if (!supportedErrorMessages.includes(insuranceStatus)) {
    console.error(
      `Unexpected insurance status message ${insuranceStatus} for user: ${patient?.id}`
    );
    return null;
  }

  let guidanceCardCopy =
    telehealthWithNoPatientLocationCausePatientHasNoAddresses
      ? `Looks like ${patientName} doesn't have any locations on file where you are credentialed to practice on Headway. Make sure your client is physically located in a state where you are credentialed during your session.`
      : `You cannot see ${patientName} through their insurance plan in ${appointmentState} because you are not credentialed with ${patientName}’s health plan there. Your client must be physically located in a state where you are credentialed during your session.`;

  return (
    <GuidanceCard variant="error" layout="vertical">
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <BodyText>{guidanceCardCopy}</BodyText>
        <div className="mb-2.5 mt-3 text-left">
          <LinkButton
            variant="link"
            href={CREDENTIALING_REQS_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </LinkButton>
        </div>
      </div>
    </GuidanceCard>
  );
};
