import { ProviderAppointmentAddendumCreate } from '@headway/api/models/ProviderAppointmentAddendumCreate';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderAppointmentApi } from '@headway/api/resources/ProviderAppointmentApi';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export interface CreateProviderAppointmentAddendumPayload {
  providerAppointmentId: number;
  providerAppointmentAddendum: ProviderAppointmentAddendumCreate;
}

export const useCreateProviderAppointmentAddendumMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderAppointmentAddendumRead,
    unknown,
    CreateProviderAppointmentAddendumPayload
  > = {}
) => {
  return useMutationWithSideEffects(
    ({
      providerAppointmentId,
      providerAppointmentAddendum,
    }: CreateProviderAppointmentAddendumPayload) =>
      ProviderAppointmentApi.createProviderAppointmentAddendum(
        providerAppointmentId,
        providerAppointmentAddendum
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderAppointmentAddendumRead,
        unknown,
        CreateProviderAppointmentAddendumPayload
      >()
        .addErrorLogging(
          () => 'There was a problem creating the provider appointment addendum'
        )
        .merge(options.sideEffects),
    }
  );
};
