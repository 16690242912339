import { useFormikContext } from 'formik';
import mapValues from 'lodash/mapValues';
import { useMemo } from 'react';

const WARNING_HELP_TEXTS = {
  assessmentType: 'Select an assessment type',
  cadence: 'Select an assessment frequency',
};

export function useMultiAssessmentScheduleWarnings(
  name: string,
  selected: boolean
) {
  const { submitCount, getFieldMeta } = useFormikContext();

  return useMemo(
    () =>
      mapValues(WARNING_HELP_TEXTS, (helpText, field) => {
        const { touched, error } = getFieldMeta(`${name}.${field}`);
        const hasError = !!error;

        // same condition used by Helix FormControl to show errors
        const isShowingError = !!((touched || submitCount > 0) && hasError);

        const shouldWarn = selected && hasError && !isShowingError;

        return shouldWarn ? { helpText, warn: true } : undefined;
      }),
    [name, selected, submitCount, getFieldMeta]
  );
}
