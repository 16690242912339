import React from 'react';

import { useFlag } from '@headway/feature-flags/react';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Divider } from '@headway/helix/Divider';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { ProgressBar } from '@headway/helix/Progress';
import { EXTOLE_REFERRAL_TRACKER } from '@headway/shared/FeatureFlags/flagNames';

import {
  ExtoleDataResponse,
  ExtoleFriend,
  trackCTAEvents,
} from '../hooks/extole';
import './provider-referral.css';

const ReferralTrackerSideSheet = ({
  userData,
  extoleAccessToken,
  trackerIsOpen,
  setTrackerIsOpen,
}: {
  userData?: ExtoleDataResponse;
  extoleAccessToken: string;
  trackerIsOpen: boolean;
  setTrackerIsOpen: (isOpen: boolean) => void;
}) => {
  const flag = useFlag(EXTOLE_REFERRAL_TRACKER, true);
  if (!userData?.me?.friends || !flag) {
    return null;
  }

  const referralList = sortReferrals(userData.me.friends);
  return (
    <>
      <Button
        size="large"
        variant="secondary"
        onPress={() => setTrackerIsOpen(true)}
      >
        Track your referrals
      </Button>
      <Modal
        id="referral-tracker-modal"
        variant="sidesheet"
        title="Track your referrals"
        isOpen={trackerIsOpen}
        onDismiss={() => {
          setTrackerIsOpen(false);
          trackCTAEvents({
            eventName: 'referral_summary_modal_clicked',
            token: extoleAccessToken,
          });
        }}
      >
        <ModalContent>
          <div className="flex flex-wrap">
            <div className="w-1/2 pl-2" key="referral_header">
              <ContentText variant="body-large/medium">Referral</ContentText>
            </div>
            <div className="w-1/3" key="status_header">
              <ContentText variant="body-large/medium">Status</ContentText>
            </div>
          </div>
          <Divider autoSpacing={false} />

          {referralList.map((referral, index) => (
            <>
              <div className="my-5 flex flex-wrap" key={index}>
                <div className="w-1/2 pl-2">
                  <div className="flex flex-row gap-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#EEF3FB] p-4">
                      {referral.initials}
                    </div>
                    <div>
                      <div className="truncate">
                        <ContentText variant="body/medium">
                          {formatName(
                            referral.firstNameOrEmail,
                            referral.initials
                          )}
                        </ContentText>
                      </div>
                      <ContentText variant="body-small">
                        Referred on{' '}
                        {formatTimestamp(new Date(referral.referralDate))}
                      </ContentText>
                    </div>
                  </div>
                </div>
                <div className="w-1/3">
                  <div className="justify-center2 flex flex-col">
                    <ContentText variant="body-small/medium">
                      {referral.status}
                    </ContentText>
                    <div className="pt-1">
                      {ProgressStatuses[
                        referral.status as keyof typeof ProgressStatuses
                      ] ? (
                        <ProgressBarWrapper
                          progress={
                            referral.status as keyof typeof ProgressStatuses
                          }
                        />
                      ) : (
                        <ContentText variant="body-small">
                          {formatStatusDetail(referral.status)} on{' '}
                          {formatTimestamp(new Date(referral.statusDate), 's')}
                        </ContentText>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Divider autoSpacing={false} />
            </>
          ))}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReferralTrackerSideSheet;

const statusOrder = {
  'Intake Completed': 1,
  Invited: 2,
  'Referral Created': 3,
  Shared: 4,
  Activated: 5,
};
const sortReferrals = (referrals: ExtoleFriend[]) => {
  return referrals.sort((ref1: ExtoleFriend, ref2: ExtoleFriend) => {
    // Compare by statusOrder first
    if (
      statusOrder[ref1.status as keyof typeof statusOrder] !==
      statusOrder[ref2.status as keyof typeof statusOrder]
    ) {
      return (
        statusOrder[ref1.status as keyof typeof statusOrder] -
        statusOrder[ref2.status as keyof typeof statusOrder]
      );
    }

    // If statuses are the same, compare by the most recent statusDate
    return (
      new Date(ref2.statusDate).getTime() - new Date(ref1.statusDate).getTime()
    ); // Most recent date first (descending)
  });
};

const isValidEmail = (email: string) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

const formatName = (firstNameOrEmail: string, initials: string) => {
  if (isValidEmail(firstNameOrEmail)) {
    const [email, domain] = firstNameOrEmail.split('@');
    return `${email.slice(0, 1)}...@${domain}`;
  }
  if (initials.length > 1) {
    const lastInitials = initials[1];
    return `${firstNameOrEmail} ${lastInitials}`;
  }
  return firstNameOrEmail;
};

const formatStatusDetail = (statusDetail: string = 'Shared') => {
  const status = statusDetail.toLowerCase();
  return status.charAt(0).toUpperCase() + status.slice(1);
};

const formatTimestamp = (timestamp: Date, monthFormat: string = 'l') => {
  return timestamp.toLocaleDateString('en-US', {
    month: monthFormat === 'l' ? 'long' : 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

enum ProgressStatuses {
  'Referral Created' = 'Referral Created',
  'Intake Completed' = 'Intake Completed',
}

const ProgressBarWrapper = ({
  progress,
}: {
  progress: keyof typeof ProgressStatuses;
}) => {
  const statusProgressMap: { [key in ProgressStatuses]: number } = {
    'Referral Created': 1,
    'Intake Completed': 2,
  };

  type IProgress = 1 | 2;
  const mapProgress: { [key in IProgress]: number } = {
    1: 50,
    2: 100,
  };
  return (
    <div className="flex items-center gap-2">
      <ProgressBar
        value={mapProgress[statusProgressMap[progress] as IProgress]}
      />
      <ContentText variant="caption" color="foreground/secondary">
        {statusProgressMap[progress] || 0}/2
      </ContentText>
    </div>
  );
};
