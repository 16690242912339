import { getLocalTimeZone, today } from '@internationalized/date';

import { DatePickerField } from '@headway/helix/DatePickerField';
import { FormControl } from '@headway/helix/FormControl';

type AssessmentRecurrenceDatePickerProps = {
  name: string;
  disabled?: boolean;
};

export function AssessmentRecurrenceDatePicker({
  name,
  disabled,
}: AssessmentRecurrenceDatePickerProps) {
  return (
    <FormControl
      name={name}
      component={DatePickerField}
      disabled={disabled}
      minValue={today(getLocalTimeZone())}
      label="Next send date"
    />
  );
}
