import { ThemeChoice } from './constants';

let currentThemeChoice = ThemeChoice.STANDARD;

const fontFamily = {
  brandText: 'var(--hlx-typography-font-family-headline), serif',
  postGrotesk: '"PostGrotesk", "Roboto", "Helvetica", "Arial", sans-serif',
};

const fontWeight = {
  light: 300,
  base: 400,
  regular: 400,
  bold: 700,
  extrabold: 800,
} as const;

//
const fontSize = {
  xs: '0.75rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xl2: '1.5rem',
  xl3: '1.875rem',
  xl4: '2.25rem',
  xl5: '3rem',
  xl6: '4rem',
} as const;

const color = {
  primary: '#13aa65',
  primaryLight: '#9ae7c5',
  primaryMedium: '#0d925b',
  primaryDark: '#03301d',
  primaryBackground: '#F3FCF8',
  success: '#13aa65',
  warning: '#ff9800',
  danger: '#ff9800',
  error: '#f44336',
  info: '#00acc1',
  black: '#212326',
  white: '#ffffff',
  translucentWhite: 'rgba(255, 255, 255, 0.9)',
  gray: '#8a8a8a',
  orange: '#f2994a',
  yellow: '#f2c94c',
  lightGray: '#dadce0',
  darkGray: '#46534d',
  textGray: '#677177',
  border: '#dadce0',
  background: '#f8f8f8',
  backgroundGray: '#eeeeee',
  lightRed: '#f37e7e',
  lightYellow: '#ffe227',
  lightGreen: '#9ae7c5',
  aetna: '#7d3f98',
  bcbs: '#008bcc',
  uhc: '#1f3570',
  cigna: '#0982c6',
  oscar: '#2854a1',
  anthem: '#0067A5',
};

const highContrastColors: typeof color = {
  primary: '#000000',
  primaryLight: '#9e9e9e',
  primaryMedium: '#03301d',
  primaryDark: '#03301d',
  primaryBackground: '#F3FCF8',
  success: '#097B4A',
  warning: '#ff9800',
  danger: '#ff9800',
  error: '#f44336',
  info: '#00acc1',
  black: '#212326',
  white: '#ffffff',
  translucentWhite: 'rgba(255, 255, 255, 0.9)',
  gray: '#8a8a8a',
  orange: '#f2994a',
  yellow: '#f2c94c',
  lightGray: '#dadce0',
  darkGray: '#595959',
  textGray: '#677177',
  border: '#dadce0',
  background: '#f8f8f8',
  backgroundGray: '#f8f8f8',
  lightRed: '#f37e7e',
  lightYellow: '#ffe227',
  lightGreen: '#9ae7c5',
  aetna: '#7d3f98',
  bcbs: '#008bcc',
  uhc: '#1f3570',
  cigna: '#0982c6',
  oscar: '#2854a1',
  anthem: '#0067A5',
};

// We use a lot of MUI so we also use their breakpoints
// https://mui.com/customization/breakpoints/
const breakpoints = {
  muiSmall: 600,
  small: 768, // TODO use MUI's small. 768 should have a diff name for tablet compatibility
  muiMedium: 900,
  medium: 960,
  large: 1280,
  xlarge: 1920,
} as const;

const media = {
  muiSmall: `@media (min-width: ${breakpoints.muiSmall}px)`,
  muiSmallDown: `@media (max-width: ${breakpoints.muiSmall}px)`,
  smallDown: `@media (max-width: ${breakpoints.small}px)`,
  small: `@media (min-width: ${breakpoints.small}px)`,
  muiMedium: `@media (min-width: ${breakpoints.muiMedium}px)`,
  muiMediumDown: `@media (max-width: ${breakpoints.muiMedium}px)`,
  medium: `@media (min-width: ${breakpoints.medium}px)`,
  large: `@media (min-width: ${breakpoints.large}px)`,
} as const;

// should this live in the theme?
const components = {
  paper: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 3,
    background: 'white',
  },
};

const space = {
  xs4: '0.0625rem',
  xs3: '0.125rem',
  xs2: '0.25rem',
  xs: '0.5rem',
  sm: '0.75rem',
  base: '1rem',
  lg: '1.25rem',
  xl: '1.5rem',
  xl2: '2rem',
  xl3: '2.5rem',
  xl4: '3rem',
  xl5: '4rem',
  xl6: '5rem',
  xl7: '6rem',
  xl8: '8rem',
  xl9: '10rem',
  xl10: '12rem',
  xl11: '14rem',
  xl12: '16rem',
} as const;

export const legacyTheme = {
  fontFamily,
  fontWeight,
  fontSize,
  get color(): typeof color {
    return currentThemeChoice === ThemeChoice.HIGH_CONTRAST
      ? highContrastColors
      : color;
  },
  defaultColors: color,
  highContrastColors: highContrastColors,
  media,
  breakpoints,
  space,
  components,
  setTheme: (themeName: ThemeChoice = ThemeChoice.STANDARD) => {
    currentThemeChoice = themeName;
  },
  reset: {
    '& :is(h1, h2, h3, h4, h5, h6)': {
      fontSize: 0,
      margin: 0,
    },
  },
};
