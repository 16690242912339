import { ASRSAgeCategory } from '@headway/api/models/ASRSAgeCategory';
import { ASRSResponse } from '@headway/api/models/ASRSResponse';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';

export const ALL_ASSESSMENT_TYPES = Object.values(PatientAssessmentType);

export const FULL_ASSESSMENT_NAMES: {
  [assessment in PatientAssessmentType]: string;
} = {
  [PatientAssessmentType.ANCHOR]: 'NQF Anchor Questions',
  [PatientAssessmentType.ASRS]: 'Adult ADHD Self-Report Scale v1.1',
  [PatientAssessmentType.PHQ9]: 'Patient Health Questionnaire 9-item',
  [PatientAssessmentType.GAD7]: 'Generalized Anxiety Disorder 7-item',
  [PatientAssessmentType.PCL5]: 'PTSD Checklist for DSM-5',
  [PatientAssessmentType.PROMIS]:
    'PROMIS Scale v1.2 - Global Physical Health 4a',
  [PatientAssessmentType.WHO5]:
    'World Health Organization-Five Well-Being Index',
  [PatientAssessmentType.IESR]: 'Impact of Event Scale — Revised',
  [PatientAssessmentType.ISI]: 'Insomnia Severity Index',
  [PatientAssessmentType.ADNM8]: 'Adjustment Disorder — New Module 8-item',
  [PatientAssessmentType.WHODAS_2]:
    'World Health Organization Disability Assessment Schedule 2.0',
};

export const SHORT_ASSESSMENT_NAMES: {
  [assessment in PatientAssessmentType]: string;
} = {
  [PatientAssessmentType.ANCHOR]: 'ANCHOR',
  [PatientAssessmentType.ASRS]: 'ASRSv1.1',
  [PatientAssessmentType.PHQ9]: 'PHQ-9',
  [PatientAssessmentType.GAD7]: 'GAD-7',
  [PatientAssessmentType.PCL5]: 'PCL-5',
  [PatientAssessmentType.PROMIS]: 'PROMIS-GPH',
  [PatientAssessmentType.WHO5]: 'WHO-5',
  [PatientAssessmentType.IESR]: 'IES-R',
  [PatientAssessmentType.ADNM8]: 'ADNM-8',
  [PatientAssessmentType.ISI]: 'ISI',
  [PatientAssessmentType.WHODAS_2]: 'WHODAS 2.0',
};

export const ASSESSMENT_CATEGORIES: {
  [assessment in PatientAssessmentType]: string;
} = {
  [PatientAssessmentType.ANCHOR]: 'NQF Study Specific Questions',
  [PatientAssessmentType.ASRS]: 'ADHD',
  [PatientAssessmentType.PHQ9]: 'Depression',
  [PatientAssessmentType.GAD7]: 'Anxiety',
  [PatientAssessmentType.PCL5]: 'Post traumatic stress disorder',
  [PatientAssessmentType.PROMIS]: 'Health and disability',
  [PatientAssessmentType.WHO5]: 'Quality of life',
  [PatientAssessmentType.IESR]: 'Trauma',
  [PatientAssessmentType.ADNM8]: 'Adjustment disorder',
  [PatientAssessmentType.ISI]: 'Sleep',
  [PatientAssessmentType.WHODAS_2]: 'Health and disability',
};

export const ASSESSMENT_QUESTION_COUNTS: {
  [assessment in PatientAssessmentType]: number;
} = {
  [PatientAssessmentType.ANCHOR]: 3,
  [PatientAssessmentType.ASRS]: 18,
  [PatientAssessmentType.PHQ9]: 9,
  [PatientAssessmentType.GAD7]: 7,
  [PatientAssessmentType.PCL5]: 20,
  [PatientAssessmentType.PROMIS]: 4,
  [PatientAssessmentType.WHO5]: 5,
  [PatientAssessmentType.IESR]: 22,
  [PatientAssessmentType.ADNM8]: 20,
  [PatientAssessmentType.ISI]: 7,
  [PatientAssessmentType.WHODAS_2]: 12,
};
export const ASRS_PART_A_KEY_TO_QUESTION_TEXT: { [key: string]: string } = {
  ASRS_1:
    'How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?',
  ASRS_2:
    'How often do you have difficulty getting things in order when you have to do a task that requires organization?',
  ASRS_3:
    'How often do you have problems remembering appointments or obligations?',
  ASRS_4:
    'When you have a task that requires a lot of thought, how often do you avoid or delay getting started?',
  ASRS_5:
    'How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?',
  ASRS_6:
    'How often do you feel overly active and compelled to do things, like you were driven by a motor?',
};

export const ASRS_PART_B_KEY_TO_QUESTION_TEXT: { [key: string]: string } = {
  ASRS_7:
    'How often do you make careless mistakes when you have to work on a boring or difficult project?',
  ASRS_8:
    'How often do you have difficulty keeping your attention when you are doing boring or repetitive work?',
  ASRS_9:
    'How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?',
  ASRS_10:
    'How often do you misplace or have difficulty finding things at home or at work?',
  ASRS_11: 'How often are you distracted by activity or noise around you?',
  ASRS_12:
    'How often do you leave your seat in meetings or other situations in which you are expected to remain seated?',
  ASRS_13: 'How often do you feel restless or fidgety?',
  ASRS_14:
    'How often do you have difficulty unwinding and relaxing when you have time to yourself?',
  ASRS_15:
    'How often do you find yourself talking too much when you are in social situations?',
  ASRS_16:
    'When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?',
  ASRS_17:
    'How often do you have difficulty waiting your turn in situations when turn taking is required?',
  ASRS_18: 'How often do you interrupt others when they are busy?',
};

export const ASRS_VALUE_TO_OPTION_TEXT: { [response in ASRSResponse]: string } =
  {
    [ASRSResponse.NEVER]: 'Never',
    [ASRSResponse.RARELY]: 'Rarely',
    [ASRSResponse.SOMETIMES]: 'Sometimes',
    [ASRSResponse.OFTEN]: 'Often',
    [ASRSResponse.VERY_OFTEN]: 'Very often',
  };

export const AGE_ENUM_TO_TEXT: { [age in ASRSAgeCategory]: string } = {
  [ASRSAgeCategory.UNDER_18]: 'Younger than 18 years old',
  [ASRSAgeCategory._18_TO_29]: '18-29 years old',
  [ASRSAgeCategory._30_TO_39]: '30-39 years old',
  [ASRSAgeCategory._40_TO_49]: '40-49 years old',
  [ASRSAgeCategory._50_TO_64]: '50-64 years old',
  [ASRSAgeCategory._65_PLUS]: '65+ years old',
};

export const NQF_EXCLUSIVE_PATIENT_ASSESSMENT_TYPES = new Set([
  PatientAssessmentType.WHODAS_2,
  PatientAssessmentType.PROMIS,
  PatientAssessmentType.ANCHOR,
]);

export const NQF_PATIENT_ASSESSMENT_TYPES = new Set([
  ...Array.from(NQF_EXCLUSIVE_PATIENT_ASSESSMENT_TYPES),
  PatientAssessmentType.PHQ9,
  PatientAssessmentType.GAD7,
]);
