import { useMemo } from 'react';
import * as Yup from 'yup';

export const useAttestationValidationSchema = () => {
  const schema = useMemo(
    () =>
      Yup.object({
        attestation: Yup.boolean()
          .oneOf([true], 'Box must be checked to continue')
          .required(),
      }).required(),
    []
  );

  return schema;
};
