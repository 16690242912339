import { FieldErrors } from 'react-hook-form';

import { CodingEnforcementErrorType } from '@headway/api/models/CodingEnforcementErrorType';

import {
  CodingError,
  CodingErrorLocation,
} from '../../components/forms/SessionDetails/validation/miscoding';
import { AppointmentConfirmationModalFormV2Values } from '../../components/modals/AppointmentConfirmationModalV2';

export const convertMiscodingErrors = (
  errors: CodingError[]
): Record<string, { message: string; type: CodingEnforcementErrorType }> => {
  const errorMap: Record<
    string,
    { message: string; type: CodingEnforcementErrorType }
  > = {};

  errors.forEach((error) => {
    const fieldKey = mapPreferredLocationToField(error.preferredLocation);

    if (fieldKey) {
      errorMap[fieldKey] = {
        message: error.message as string,
        type: error.type,
      };
    }
  });

  return errorMap;
};

// Helper to map CodingErrorLocation to form field keys
export const mapPreferredLocationToField = (
  location: CodingErrorLocation
): string => {
  switch (location) {
    case CodingErrorLocation.CPT_CODES:
      return 'sessionDetails.cptCodes';
    case CodingErrorLocation.DIAGNOSIS_CODES:
      return 'sessionDetails.diagnosisCodes';
    case CodingErrorLocation.SESSION_TIME:
      return 'sessionDetails.sessionTime';
    default:
      return '';
  }
};

export const findFirstError = (errors: any): string | undefined => {
  for (const key in errors) {
    if (errors[key]?.message) {
      return key;
    } else if (errors[key]) {
      const deepError = findFirstError(errors[key]);
      if (deepError) return `${key}.${deepError}`;
    }
  }
  return undefined;
};

export const onValidationFail = (
  errors: FieldErrors<AppointmentConfirmationModalFormV2Values>
) => {
  const firstError = findFirstError(errors);
  if (firstError) {
    const selector = `[id="${firstError}"]`;
    const element = document.querySelector(selector) as HTMLElement | undefined;
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });

      // Delay focus slightly to allow scrolling to finish
      setTimeout(() => {
        element.focus({ preventScroll: true });
      }, 350);
    }
  }
};
