import React, { useMemo } from 'react';

import { TextField } from '@headway/helix/TextField';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';
import { getInstructionalText, getOptionalityText } from '../utils';

const MemoizedTextField = React.memo(TextField);

export const FormTextField = ({
  element,
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}: SchemaComponent) => {
  const { id, title, placeholder, subTitle, optionalityText } = element;

  const optionality = useMemo(
    () => getOptionalityText(isOptional, optionalityText),
    [isOptional, optionalityText]
  );

  const instructionalText = useMemo(
    () => getInstructionalText(subTitle, { requiresEdit }),
    [subTitle, requiresEdit]
  );

  return (
    <FormControlRHF
      component={MemoizedTextField}
      label={title}
      name={id}
      key={id}
      optionalityText={optionality}
      disabled={disabled}
      placeholder={placeholder}
      instructionalText={instructionalText}
      aria-label="Progress-notes text field"
    />
  );
};
