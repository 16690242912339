import React from 'react';

import { Checkbox as HelixCheckbox } from '@headway/helix/Checkbox';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';

const MemoizedCheckbox = React.memo(HelixCheckbox);

export const Checkbox = ({ element, disabled = false }: SchemaComponent) => {
  const { id, title } = element;

  return (
    <FormControlRHF
      name={id}
      disabled={disabled}
      component={MemoizedCheckbox}
      aria-label="RHF checkbox"
    >
      {title}
    </FormControlRHF>
  );
};
