import { Alert, FormHelperText, Skeleton } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { MarketRead } from '@headway/api/models/MarketRead';
import { PatientAddressCreate } from '@headway/api/models/PatientAddressCreate';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { MarketApi } from '@headway/api/resources/MarketApi';
import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button, ButtonProps } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { IconLock } from '@headway/helix/icons/Lock';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { VisuallyHidden } from '@headway/helix/utils';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import {
  abbreviationToStateEnum,
  statesToDisplayNames,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import useScript from '@headway/shared/utils/useScript';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';

import AddressFilter from '../../Patients/AddressFilter';
import { AppointmentConfirmationContextV2 } from '../stores/AppointmentConfirmationContextV2';

const baseValidationSchema = {
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.').nullable(),
  streetLine1: Yup.string().required('Street line 1 is required.'),
  streetLine2: Yup.string().nullable().default(null),
  zipCode: Yup.string().required('Zip code is required.'),
};

export interface LocationFilterAddress {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface PatientAddressFormV2ImplProps {
  onSuccess?: (address: PatientAddressRead, user: UserRead, args?: any) => void;
  apiKey: string;
  isActiveAddress?: boolean;
  isTelehealth?: boolean;
  onCancel?: () => void;
  variant?: ButtonProps['variant'];
}

enum PatientAddressFormFieldNames {
  City = 'city',
  State = 'state',
  StreetLine1 = 'streetLine1',
  StreetLine2 = 'streetLine2',
  ZipCode = 'zipCode',
  AddressType = 'addressType',
  Telehealth = 'telehealth',
}

type PatientAddressFormValues = {
  [PatientAddressFormFieldNames.City]: string;
  [PatientAddressFormFieldNames.State]: UnitedStates | null;
  [PatientAddressFormFieldNames.StreetLine1]: string;
  [PatientAddressFormFieldNames.StreetLine2]: string;
  [PatientAddressFormFieldNames.ZipCode]: string;
  [PatientAddressFormFieldNames.AddressType]: PatientAddressType | null;
  [PatientAddressFormFieldNames.Telehealth]: string | null;
};

export const PatientAddressFormV2 = ({
  onSuccess,
  apiKey,
  onCancel,
  isTelehealth,
  isActiveAddress = true,
  variant = 'primary',
}: PatientAddressFormV2ImplProps) => {
  const { patient } = useContext(AppointmentConfirmationContextV2);
  const validationSchema = Yup.object().shape({
    ...baseValidationSchema,
    ...(isTelehealth
      ? {
          telehealth: Yup.bool().nullable().required('Choose an option.'),
        }
      : {}),
    addressType: Yup.string().nullable().required('Description is required.'),
  });

  const gmapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3&libraries=places`
  );

  const createPatientAddressMutation = useMutation(
    (variables: PatientAddressCreate) => {
      return PatientAddressApi.createPatientAddress(variables);
    }
  );

  const queryClient = useQueryClient();

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  return (
    <React.Fragment>
      <Formik
        validationSchema={validationSchema}
        initialValues={
          {
            [PatientAddressFormFieldNames.City]: '',
            [PatientAddressFormFieldNames.State]: null,
            [PatientAddressFormFieldNames.StreetLine1]: '',
            [PatientAddressFormFieldNames.StreetLine2]: '',
            [PatientAddressFormFieldNames.ZipCode]: '',
            [PatientAddressFormFieldNames.AddressType]: isActiveAddress
              ? PatientAddressType.HOME
              : null,
            [PatientAddressFormFieldNames.Telehealth]: null,
          } as PatientAddressFormValues
        }
        onSubmit={async (values) => {
          try {
            if (patient) {
              const address = await createPatientAddressMutation.mutateAsync({
                patientUserId: patient?.id,
                addressType: values.addressType
                  ? PatientAddressType[values.addressType as PatientAddressType]
                  : undefined,
                city: values.city,
                state: values.state as UnitedStates,
                streetLine1: values.streetLine1,
                streetLine2: values.streetLine2,
                zipCode: values.zipCode,
              });

              const args = isTelehealth
                ? {
                    telehealth: values.telehealth === 'true',
                  }
                : undefined;

              if (isActiveAddress) {
                const updatedUser = await UserApi.updateUser(patient?.id, {
                  activePatientAddressId: address.id,
                });
                onSuccess && onSuccess(address, updatedUser, args);
              } else {
                onSuccess && onSuccess(address, patient, args);
              }

              await queryClient.invalidateQueries(['claim-readiness']);
            }
          } catch (e) {
            logException(e);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <SafeFormikForm className="mb-0 flex flex-col gap-2">
              <PatientAddressFormFields
                gmapsScriptStatus={gmapsScriptStatus}
                isSubmitting={isSubmitting}
                isMSCEnabled={isMSCEnabled}
                patient={patient}
                isTelehealth={isTelehealth}
                createPatientAddressMutationStatus={
                  createPatientAddressMutation.status
                }
              />
              <div className="flex flex-col justify-end gap-8 pt-8">
                <Button disabled={isSubmitting} variant={variant} type="submit">
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
                {onCancel && (
                  <Button variant="secondary" onPress={onCancel}>
                    Cancel
                  </Button>
                )}
              </div>
            </SafeFormikForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

type PatientAddressFormFieldsProps = {
  gmapsScriptStatus: 'idle' | 'loading' | 'ready' | 'error';
  isSubmitting: boolean;
  isMSCEnabled: boolean;
  patient?: UserRead;
  isTelehealth?: boolean;
  createPatientAddressMutationStatus: 'error' | 'idle' | 'loading' | 'success';
};

export const PatientAddressFormFields = ({
  gmapsScriptStatus,
  isMSCEnabled,
  patient,
  isTelehealth,
  createPatientAddressMutationStatus,
}: PatientAddressFormFieldsProps) => {
  const { setFieldValue, values } =
    useFormikContext<PatientAddressFormValues>();
  const [markets, setMarkets] = useState<MarketRead[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await MarketApi.getAllMarkets();
      setMarkets(result);
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      {gmapsScriptStatus === 'ready' ? (
        <div>
          <AddressFilter
            markets={markets}
            placeType={'geocode'}
            handleLocationSelect={(values: any) => {
              const newAddress = {
                ...values,
                state: abbreviationToStateEnum[values.state],
              };

              setFieldValue(PatientAddressFormFieldNames.City, newAddress.city);
              setFieldValue(
                PatientAddressFormFieldNames.State,
                newAddress.state
              );
              setFieldValue(
                PatientAddressFormFieldNames.StreetLine1,
                newAddress.streetLine1
              );
              setFieldValue(
                PatientAddressFormFieldNames.StreetLine2,
                newAddress.streetLine2
              );
              setFieldValue(
                PatientAddressFormFieldNames.ZipCode,
                newAddress.zipCode
              );
            }}
          />
          {isMSCEnabled ? (
            <FormHelperText>
              If {formatPatientName(patient, { firstNameOnly: true })} is a
              Telehealth client, use the address where they join virtual
              sessions from.
            </FormHelperText>
          ) : (
            <></>
          )}
        </div>
      ) : gmapsScriptStatus === 'loading' ? (
        <Skeleton variant="rectangular" height={40} />
      ) : null}
      <legend css={{ margin: 0 }}>
        <VisuallyHidden>Address</VisuallyHidden>
      </legend>
      <FormControl
        name={PatientAddressFormFieldNames.StreetLine1}
        component={TextField}
        label={'Street Line 1'}
      />
      <FormControl
        name={PatientAddressFormFieldNames.StreetLine2}
        component={TextField}
        label={'Street Line 2'}
      />
      <FormControl
        name={PatientAddressFormFieldNames.City}
        component={TextField}
        label={'City'}
      />
      <FormControl
        name={PatientAddressFormFieldNames.State}
        component={Select}
        selectionMode="single"
        label="State"
        menuWidth="stretch"
        selectedKeys={values.state ? [values.state.toString()] : []}
        onSelectionChange={(items: Set<string>) => {
          const value = UnitedStates[Array.from(items)[0] as UnitedStates];
          setFieldValue(PatientAddressFormFieldNames.State, value);
        }}
      >
        {Object.keys(statesToDisplayNames).map((state) => (
          <Item key={state}>{statesToDisplayNames[state as UnitedStates]}</Item>
        ))}
      </FormControl>
      <FormControl
        name={PatientAddressFormFieldNames.ZipCode}
        component={TextField}
        label={'Zip code'}
      />
      <FormControl
        name={PatientAddressFormFieldNames.AddressType}
        component={Select}
        selectionMode="single"
        label="Describe this location"
        menuWidth="stretch"
        selectedKeys={values.addressType ? [values.addressType.toString()] : []}
        onSelectionChange={(items: Set<string>) => {
          const value = Array.from(items)[0];
          setFieldValue(PatientAddressFormFieldNames.AddressType, value);
        }}
      >
        {Object.keys(addressTypeDisplayNames).map((addressType) => (
          <Item key={addressType}>
            {addressTypeDisplayNames[addressType as PatientAddressType]}
          </Item>
        ))}
      </FormControl>
      {isMSCEnabled && (
        <div className="flex flex-row gap-4">
          <IconLock color={theme.color.system.lightGray} />
          <FormHelperText>
            We will not use this information otherwise, nor will we share it
            with anyone besides the insurance and provider.
          </FormHelperText>
        </div>
      )}
      {isTelehealth && (
        <FormControl
          name={PatientAddressFormFieldNames.Telehealth}
          component={RadioGroup}
          label={
            'Is the above address where you plan to join video telehealth sessions?'
          }
        >
          <Radio value="false">No, I plan to join from another location</Radio>
          <Radio value="true">
            Yes, I plan to join video sessions from the address above
          </Radio>
        </FormControl>
      )}
      {createPatientAddressMutationStatus === 'error' && (
        <Alert severity="error">
          We couldn't save this address. Please try again.
        </Alert>
      )}
    </div>
  );
};
