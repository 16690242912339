import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePatientAddresses } from '~/legacy/hooks/usePatientAddresses';
import { useProviderAppointmentAddendums } from '~/legacy/hooks/useProviderAppointmentAddendums';
import { useProviderDocumentRemediations } from '~/legacy/hooks/useProviderDocumentRemediations';
import { useProviderEvent } from '~/legacy/hooks/useProviderEvent';

import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderDocumentRemediationWithAuditResults } from '@headway/api/models/ProviderDocumentRemediationWithAuditResults';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderProgressNoteRead } from '@headway/api/models/ProviderProgressNoteRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { TemplateError } from '@headway/api/models/TemplateError';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { isDetailsConfirmed } from '../../Calendar/events/util/events';
import { GenericTemplate } from '../components/forms/ProgressNote/Template/Renderer/types';
import { getTemplates } from '../components/forms/ProgressNote/Template/utils';
import { ProgressNoteComponentMetadata } from '../components/forms/ProgressNote/types';
import { isSessionDetailsFormValueTelehealth } from '../components/forms/SessionDetails/SessionDetailsFormV2';
import { useValidProviderProgressNote } from '../hooks/utils/useValidProviderProgressNote';

export enum ProgressNoteState {
  EDITING,
  SAVED_FOR_LATER,
  SIGNED,
  ADDENDUM_EDITING,
}

export interface AppointmentConfirmationContextV2Type {
  // event - appointment
  event: ProviderEventRead | undefined;
  eventVirtualId: string | number | undefined;
  isEventLoading: boolean;
  isEventFetching: boolean;
  // progress note
  progressNote: ProviderProgressNoteRead | undefined;
  setProgressNote: (progressNote: ProviderProgressNoteRead | undefined) => void;
  progressNoteState: ProgressNoteState | undefined;
  progressNoteType: ProgressNoteType | undefined;
  setProgressNoteType: (progressNoteType: ProgressNoteType | undefined) => void;
  isProgressNoteLoading: boolean;
  isProgressNoteFetching: boolean;
  progressNoteErrorsFromSubmitting: TemplateError[] | undefined;
  setProgressNoteErrorsFromSubmitting: (
    errors: TemplateError[] | undefined
  ) => void;
  templates: GenericTemplate<ProgressNoteComponentMetadata>[] | undefined;
  previousNoteInitialValues: Record<string, any> | undefined;
  setPreviousNoteInitialValues: (
    previousNoteInitialValues: Record<string, any> | undefined
  ) => void;
  // patient and provider
  patient: UserRead | undefined;
  provider: ProviderRead | undefined;
  // remediation - feedback
  documentRemediation: ProviderDocumentRemediationWithAuditResults | undefined;
  isDocumentRemediationLoading: boolean;
  // addendums
  addendums: ProviderAppointmentAddendumRead[] | undefined;
  areAddendumsLoading: boolean;
  // progress note state logic
  switchToEditProgressNote(): void;
  switchToEditAddendum(): void;
  switchToNoteSigned(): void;
  switchToNoteSavedAsDraft(): void;
  // addresses related state and logic
  appointmentAddressState: UnitedStates | undefined;
  updateAppointmentAddressState: (
    providerAddressId?: number | undefined | null,
    appointmentLocationPatientAddressId?: number | undefined | null
  ) => void;
  // form state
  isAutoSaving: boolean;
  setIsAutoSaving: (isAutoSaving: boolean) => void;
  // modals state
  onCloseAppointmentConfirmationModal: () => void;
  isSubmitAddendumWithoutChangesModalOpen: boolean;
  setIsSubmitAddendumWithoutChangesModalOpen: (isOpen: boolean) => void;
  isProgressNoteDownloadAgreementModalOpen: boolean;
  setIsProgressNoteDownloadAgreementModalOpen: (isOpen: boolean) => void;
  isComplianceGuideModalOpen: boolean;
  setIsComplianceGuideModalOpen: (isOpen: boolean) => void;
  isAddressModalOpen: boolean;
  setIsAddressModalOpen: (isOpen: boolean) => void;
  isRequiredNoteSubmissionModalOpen: boolean;
  setIsRequiredNoteSubmissionModalOpen: (isOpen: boolean) => void;
  openTreatmentPlanAdoptionModal:
    | ((isIntakeSession: boolean) => void)
    | undefined;
}

export const AppointmentConfirmationContextV2 =
  createContext<AppointmentConfirmationContextV2Type>({
    event: undefined,
    eventVirtualId: undefined,
    isEventLoading: false,
    isEventFetching: false,
    progressNote: undefined,
    setProgressNote: () => {},
    progressNoteState: undefined,
    progressNoteType: undefined,
    setProgressNoteType: () => {},
    isProgressNoteLoading: false,
    isProgressNoteFetching: false,
    progressNoteErrorsFromSubmitting: undefined,
    setProgressNoteErrorsFromSubmitting: () => {},
    templates: undefined,
    previousNoteInitialValues: undefined,
    setPreviousNoteInitialValues: () => {},
    patient: undefined,
    provider: undefined,
    documentRemediation: undefined,
    isDocumentRemediationLoading: false,
    addendums: undefined,
    areAddendumsLoading: false,
    switchToEditProgressNote: () => {},
    switchToEditAddendum: () => {},
    switchToNoteSigned: () => {},
    switchToNoteSavedAsDraft: () => {},
    appointmentAddressState: undefined,
    updateAppointmentAddressState: () => {},
    isAutoSaving: false,
    setIsAutoSaving: () => {},
    onCloseAppointmentConfirmationModal: () => {},
    isSubmitAddendumWithoutChangesModalOpen: false,
    setIsSubmitAddendumWithoutChangesModalOpen: () => {},
    isProgressNoteDownloadAgreementModalOpen: false,
    setIsProgressNoteDownloadAgreementModalOpen: () => {},
    isComplianceGuideModalOpen: false,
    setIsComplianceGuideModalOpen: () => {},
    isAddressModalOpen: false,
    setIsAddressModalOpen: () => {},
    isRequiredNoteSubmissionModalOpen: false,
    setIsRequiredNoteSubmissionModalOpen: () => {},
    openTreatmentPlanAdoptionModal: undefined,
  });

export type AppointmentConfirmationContextProps = {
  children: ReactNode;
  eventVirtualId: string | number | undefined;
  patient: UserRead;
  provider: ProviderRead;
  onCloseAppointmentConfirmationModal(): void;
  openTreatmentPlanAdoptionModal:
    | ((isIntakeSession: boolean) => void)
    | undefined;
};

export const AppointmentConfirmationContextProvider = ({
  children,
  eventVirtualId,
  patient,
  provider,
  onCloseAppointmentConfirmationModal,
  openTreatmentPlanAdoptionModal,
}: AppointmentConfirmationContextProps) => {
  const { providerAddresses } = useContext(ProviderAddressContext);
  const [progressNote, setProgressNote] = useState<
    ProviderProgressNoteRead | undefined
  >(undefined);
  const [progressNoteState, setProgressNoteState] = useState<
    ProgressNoteState | undefined
  >(undefined);
  const [progressNoteType, setProgressNoteType] = useState<
    ProgressNoteType | undefined
  >(undefined);
  const [appointmentAddressState, setAppointmentAddressState] = useState<
    UnitedStates | undefined
  >(undefined);
  const [isAutoSaving, setIsAutoSaving] = useState<boolean>(false);
  const [
    progressNoteErrorsFromSubmitting,
    setProgressNoteErrorsFromSubmitting,
  ] = useState<TemplateError[] | undefined>(undefined);
  const [previousNoteInitialValues, setPreviousNoteInitialValues] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [
    isSubmitAddendumWithoutChangesModalOpen,
    setIsSubmitAddendumWithoutChangesModalOpen,
  ] = useState<boolean>(false);
  const [
    isProgressNoteDownloadAgreementModalOpen,
    setIsProgressNoteDownloadAgreementModalOpen,
  ] = useState<boolean>(false);
  const [providerClosedComplianceModal] = useLocalStorage(
    'providerClosedComplianceModal'
  );
  const [isComplianceGuideModalOpen, setIsComplianceGuideModalOpen] =
    useState<boolean>(!providerClosedComplianceModal);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [
    isRequiredNoteSubmissionModalOpen,
    setIsRequiredNoteSubmissionModalOpen,
  ] = useState<boolean>(false);
  const {
    data: event,
    isFetching: isEventFetching,
    isLoading: isEventLoading,
  } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const {
    data: validPogressNote,
    isFetching: isProgressNoteFetching,
    isLoading: isProgressNoteLoading,
  } = useValidProviderProgressNote(
    {
      providerAppointmentId: event?.providerAppointment?.id!,
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: documentRemediation, isLoading: isDocumentRemediationLoading } =
    useProviderDocumentRemediations(
      {
        providerAppointmentId: event?.providerAppointment?.id,
      },
      {
        select: ([firstItem]) => firstItem ?? undefined,
      }
    );
  const { data: addendums, isLoading: areAddendumsLoading } =
    useProviderAppointmentAddendums({
      providerAppointmentId: event?.providerAppointment?.id,
    });
  const { data: patientAddresses } = usePatientAddresses({
    patientId: patient?.id,
  });

  const templates = useMemo(
    () => getTemplates<ProgressNoteComponentMetadata>(),
    []
  );

  const switchToEditProgressNote = useCallback(() => {
    setProgressNoteState(ProgressNoteState.EDITING);
  }, []);
  const switchToEditAddendum = useCallback(() => {
    setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING);
  }, []);
  const switchToNoteSigned = useCallback(() => {
    setProgressNoteState(ProgressNoteState.SIGNED);
  }, []);
  const switchToNoteSavedAsDraft = useCallback(() => {
    setProgressNoteState(ProgressNoteState.SAVED_FOR_LATER);
  }, []);
  const updateAppointmentAddressState = useCallback(
    (
      providerAddressId?: number | undefined | null,
      appointmentLocationPatientAddressId?: number | undefined | null
    ) => {
      if (isSessionDetailsFormValueTelehealth(providerAddressId)) {
        if (appointmentLocationPatientAddressId && patientAddresses) {
          setAppointmentAddressState(
            patientAddresses.find(
              (address) => address.id === appointmentLocationPatientAddressId
            )?.state
          );
        }
      } else {
        if (providerAddressId) {
          const providerAddressState = providerAddresses.find(
            (address) => address.id === providerAddressId
          )?.state;
          if (
            providerAddressState &&
            providerAddressState in abbreviationToStateEnum
          ) {
            setAppointmentAddressState(
              abbreviationToStateEnum[providerAddressState] as UnitedStates
            );
          }
        }
      }
    },
    [patientAddresses, providerAddresses]
  );

  useEffect(() => {
    setProgressNote(validPogressNote);
  }, [validPogressNote]);

  useEffect(() => {
    if (
      documentRemediation?.status === DocumentRemediationStatus.NEEDS_REVIEW
    ) {
      switchToEditAddendum();
    } else if (progressNote) {
      if (progressNote.attestedOn) {
        switchToNoteSigned();
      } else {
        switchToEditProgressNote();
      }
    } else {
      if (!!event?.providerAppointment?.attachments?.length) {
        switchToNoteSigned();
      } else {
        switchToEditProgressNote();
      }
    }
  }, [
    progressNote,
    switchToEditProgressNote,
    switchToNoteSigned,
    switchToEditAddendum,
    event?.providerAppointment?.attachments?.length,
    documentRemediation?.status,
  ]);

  useEffect(() => {
    const noteType = event?.providerAppointment?.progressNoteType;
    if (!validPogressNote && noteType === ProgressNoteType.NONE) {
      if (isDetailsConfirmed(event)) {
        // If there are no existing note and the type is NONE but it's a confirmed session => NONE
        setProgressNoteType(ProgressNoteType.NONE);
      } else {
        // If there are no existing note and the type is NONE => undefined (no big radio button selected)
        setProgressNoteType(undefined);
      }
    } else {
      setProgressNoteType(noteType);
    }
  }, [event?.providerAppointment?.progressNoteType, validPogressNote, event]);

  const value = useMemo(
    () => ({
      event,
      eventVirtualId,
      isEventLoading,
      isEventFetching,
      progressNote,
      setProgressNote,
      progressNoteState,
      progressNoteType,
      setProgressNoteType,
      isProgressNoteLoading,
      isProgressNoteFetching,
      progressNoteErrorsFromSubmitting,
      setProgressNoteErrorsFromSubmitting,
      templates,
      previousNoteInitialValues,
      setPreviousNoteInitialValues,
      patient,
      provider,
      documentRemediation,
      isDocumentRemediationLoading,
      addendums,
      areAddendumsLoading,
      switchToEditProgressNote,
      switchToEditAddendum,
      switchToNoteSigned,
      switchToNoteSavedAsDraft,
      appointmentAddressState,
      updateAppointmentAddressState,
      isAutoSaving,
      setIsAutoSaving,
      onCloseAppointmentConfirmationModal,
      isSubmitAddendumWithoutChangesModalOpen,
      setIsSubmitAddendumWithoutChangesModalOpen,
      isProgressNoteDownloadAgreementModalOpen,
      setIsProgressNoteDownloadAgreementModalOpen,
      isComplianceGuideModalOpen,
      setIsComplianceGuideModalOpen,
      isAddressModalOpen,
      setIsAddressModalOpen,
      isRequiredNoteSubmissionModalOpen,
      setIsRequiredNoteSubmissionModalOpen,
      openTreatmentPlanAdoptionModal,
    }),
    [
      event,
      eventVirtualId,
      isEventLoading,
      isEventFetching,
      progressNote,
      setProgressNote,
      progressNoteState,
      progressNoteType,
      setProgressNoteType,
      isProgressNoteLoading,
      isProgressNoteFetching,
      progressNoteErrorsFromSubmitting,
      setProgressNoteErrorsFromSubmitting,
      templates,
      previousNoteInitialValues,
      setPreviousNoteInitialValues,
      patient,
      provider,
      documentRemediation,
      isDocumentRemediationLoading,
      addendums,
      areAddendumsLoading,
      switchToEditProgressNote,
      switchToEditAddendum,
      switchToNoteSigned,
      switchToNoteSavedAsDraft,
      appointmentAddressState,
      updateAppointmentAddressState,
      isAutoSaving,
      setIsAutoSaving,
      onCloseAppointmentConfirmationModal,
      isSubmitAddendumWithoutChangesModalOpen,
      setIsSubmitAddendumWithoutChangesModalOpen,
      isProgressNoteDownloadAgreementModalOpen,
      setIsProgressNoteDownloadAgreementModalOpen,
      isComplianceGuideModalOpen,
      setIsComplianceGuideModalOpen,
      isAddressModalOpen,
      setIsAddressModalOpen,
      isRequiredNoteSubmissionModalOpen,
      setIsRequiredNoteSubmissionModalOpen,
      openTreatmentPlanAdoptionModal,
    ]
  );

  return (
    <AppointmentConfirmationContextV2.Provider value={value}>
      {children}
    </AppointmentConfirmationContextV2.Provider>
  );
};
