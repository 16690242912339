import { useField } from 'formik';
import { ReactElement, useMemo } from 'react';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';

import { AssessmentRecurrenceCadenceSelector } from './AssessmentRecurrenceCadenceSelector';
import { AssessmentRecurrenceDatePicker } from './AssessmentRecurrenceDatePicker';
import { AssessmentTypeSelector } from './AssessmentTypeSelector';
import { AssessmentScheduleInputCustomSelectProps } from './types';

type BaseAssessmentScheduleInputProps = {
  name: string;
  disabled?: boolean;
  cadenceSelectorProps?: AssessmentScheduleInputCustomSelectProps;
};

type AssessmentScheduleInputProps = BaseAssessmentScheduleInputProps & {
  withTypeSelector?: boolean;
  availableAssessmentTypes?: PatientAssessmentType[];
  typeSelectorProps?: AssessmentScheduleInputCustomSelectProps;
};

const SELECT_WARNING_STYLES =
  '[&_.hlx-select-control[data-warn]]:bg-background-warning';

export function AssessmentScheduleInput(
  props: BaseAssessmentScheduleInputProps & {
    withTypeSelector?: false;
  }
): ReactElement;

export function AssessmentScheduleInput(
  props: BaseAssessmentScheduleInputProps & {
    withTypeSelector: true;
    availableAssessmentTypes: PatientAssessmentType[];
    typeSelectorProps?: AssessmentScheduleInputCustomSelectProps;
  }
): ReactElement;

export function AssessmentScheduleInput({
  name,
  disabled,
  withTypeSelector = false,
  availableAssessmentTypes,
  typeSelectorProps = {},
  cadenceSelectorProps = {},
}: AssessmentScheduleInputProps) {
  const [{ value }] = useField(name);

  const typeOptions = useMemo(
    () =>
      availableAssessmentTypes
        ? [...new Set([...availableAssessmentTypes, value.assessmentType])]
        : availableAssessmentTypes,
    [availableAssessmentTypes, value.assessmentType]
  );

  return (
    <div
      className={`flex gap-2 [&>*]:flex [&>*]:flex-1 [&>*]:flex-col ${SELECT_WARNING_STYLES}`}
    >
      {withTypeSelector && (
        <AssessmentTypeSelector
          name={`${name}.assessmentType`}
          disabled={disabled}
          types={typeOptions}
          {...typeSelectorProps}
        />
      )}
      <AssessmentRecurrenceCadenceSelector
        name={`${name}.cadence`}
        disabled={disabled}
        {...cadenceSelectorProps}
      />
      <AssessmentRecurrenceDatePicker
        name={`${name}.startDate`}
        disabled={disabled}
      />
    </div>
  );
}
