import { useContext, useEffect, useMemo, useState } from 'react';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';

import {
  AddendumsEditFormV2Values,
  AddendumType,
} from '../../components/forms/AddendumsFormV2';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useAddendumsEditInitialValues = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { progressNoteType, isEventLoading, isProgressNoteLoading } =
    useContext(AppointmentConfirmationContextV2);

  const isLoading = isEventLoading || isProgressNoteLoading;

  const initialValues: AddendumsEditFormV2Values = useMemo(() => {
    return {
      addendumHtml: undefined,
      attachments: [],
      addendumType:
        progressNoteType === ProgressNoteType.TEMPLATE
          ? AddendumType.TEXT
          : AddendumType.UPLOAD,
    };
  }, [progressNoteType]);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
    }
  }, [isLoading, initialValues, isInitialized]);

  return { isInitialized: isInitialized, initialValues: initialValues };
};
