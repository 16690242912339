import React from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { theme } from '@headway/helix/theme';
import { SESSION_CONFIRMATION_FLOW_REVAMP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { CollapsibleSection } from '../../../components/CollapsibleSection';
import { CollapsibleSectionV2 } from '../../../components/CollapsibleSectionV2';
import { DoAndDont } from '../../../components/DoAndDont';
import { InlineTreatmentPlan } from '../../../components/InlineTreatmentPlan';
import { TemplateAnalyticsNames } from '../../../ProgressNotesForm';
import {
  doesComponentExpectAnArrayResponse,
  isElementASection,
} from '../../utils';
import { TemplateRenderedComponent } from '../TemplateRenderedComponent';
import { TemplateRenderedComponentV2 } from '../TemplateRenderedComponentV2';
import { GenericTemplate, TemplateValues } from '../types';
import { DoAndDontData, InfoComponentTypes, TemplateV2 } from '../v2/types';
import { callFunctions, FunctionState } from './functions';
import {
  Component,
  ProgressNoteComponentMetadata,
  Section,
  TemplateV3,
} from './types';

type RendererProps<T> = {
  template?: GenericTemplate<TemplateV2<T>>['template'];
  disabled?: boolean;
  eventStartDate?: string;
  providerPatient: ProviderPatientRead;
  sendTemplateAnalytics: (trackingEventName: TemplateAnalyticsNames) => void;
};

export function getInitialTemplateValues<T>(
  template?: TemplateV3<T>,
  values?: TemplateValues
) {
  const setInitialValue = (
    obj: any,
    component: Component<ProgressNoteComponentMetadata>
  ) => {
    let initialValue: string | string[] = '';
    if (values?.[component.id]) {
      initialValue = values[component.id];
    } else if (doesComponentExpectAnArrayResponse(component)) {
      initialValue = [];
    }
    return (obj[component.id] = initialValue);
  };

  const obj: any = {};

  if (template) {
    for (let i = 0; i < template.length; i++) {
      const element: any = template[i];

      if (isElementASection(element)) {
        (
          element.components as Component<ProgressNoteComponentMetadata>[]
        ).forEach((component: Component<ProgressNoteComponentMetadata>) => {
          if (!component.readonly) setInitialValue(obj, component);
        });
      } else setInitialValue(obj, element);
    }
  }

  return obj;
}

export function Renderer<T>({
  template,
  disabled,
  eventStartDate,
  patient,
  providerPatient,
  sendTemplateAnalytics,
}: RendererProps<T> & FunctionState) {
  const isSessionConfirmationFlowRevampEnabled = useFlag(
    SESSION_CONFIRMATION_FLOW_REVAMP,
    false
  );

  return template ? (
    <>
      {template.map((element: Section<T> | Component<T>, i: number) => {
        if (isElementASection<T>(element)) {
          const section = element as Section<T>;

          if (
            section.shouldDisplay &&
            !callFunctions<'isPatientAgeInInclusiveRange'>(
              { patient, eventStartDate },
              section.shouldDisplay
            )
          ) {
            return null;
          }

          const sectionContent = (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x4,
                marginTop: theme.spacing.x4,
              }}
            >
              {section.infoComponent && section.infoComponent.data ? (
                section.infoComponent.type === InfoComponentTypes.doAndDont ? (
                  <DoAndDont
                    data={section.infoComponent.data as DoAndDontData}
                  />
                ) : section.infoComponent.type ===
                  InfoComponentTypes.infoList ? (
                  <></>
                ) : null
              ) : null}
              {(section.components as Component<T>[]).map(
                (component: Component<T>) => {
                  const showInlineTreatmentPlan = (component as any).metadata
                    ?.showInlineTreatmentPlan;

                  if (
                    component.shouldDisplay &&
                    !callFunctions<'isPatientAgeInInclusiveRange'>(
                      { patient },
                      component.shouldDisplay
                    )
                  ) {
                    return null;
                  }

                  return (
                    <React.Fragment key={component.id}>
                      {isSessionConfirmationFlowRevampEnabled ? (
                        <TemplateRenderedComponentV2
                          disabled={disabled}
                          component={component}
                          template={template}
                        />
                      ) : (
                        <TemplateRenderedComponent
                          disabled={disabled}
                          component={component}
                          template={template}
                        />
                      )}
                      {showInlineTreatmentPlan && !disabled && patient && (
                        <InlineTreatmentPlan
                          patient={patient}
                          providerPatient={providerPatient}
                          sendTemplateAnalytics={sendTemplateAnalytics}
                        />
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </div>
          );

          return section.collapsible ? (
            isSessionConfirmationFlowRevampEnabled ? (
              <CollapsibleSectionV2<T>
                key={i}
                disabled={disabled}
                header={section.header}
                subHeader={section.subHeader}
                isCollapsibleOpen={section.collapsible?.defaultOpen}
                defaultOptionBehavior={section.defaultOptionBehavior}
                components={section.components as Component<T>[]}
              >
                {sectionContent}
              </CollapsibleSectionV2>
            ) : (
              <CollapsibleSection<T>
                key={i}
                disabled={disabled}
                header={section.header}
                subHeader={section.subHeader}
                isCollapsibleOpen={section.collapsible?.defaultOpen}
                defaultOptionBehavior={section.defaultOptionBehavior}
                components={section.components as Component<T>[]}
              >
                {sectionContent}
              </CollapsibleSection>
            )
          ) : (
            <div
              key={i}
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  width: 'fit-content',
                  flexDirection: 'column',
                }}
              >
                <span
                  css={[
                    theme.typography.sectionHeader,
                    {
                      color: disabled
                        ? theme.color.system.disabledGray
                        : theme.color.system.textBlack,
                    },
                  ]}
                >
                  {section.header}
                </span>
                {section.subHeader && (
                  <span
                    css={[
                      theme.typography.subbody.regular,
                      {
                        color: disabled
                          ? theme.color.system.disabledGray
                          : theme.color.system.textBlack,
                      },
                    ]}
                  >
                    {section.subHeader}
                  </span>
                )}
              </div>
              {sectionContent}
            </div>
          );
        } else {
          return isSessionConfirmationFlowRevampEnabled ? (
            <TemplateRenderedComponentV2
              key={i}
              disabled={disabled}
              component={element}
              template={template}
            />
          ) : (
            <TemplateRenderedComponent
              key={i}
              disabled={disabled}
              component={element}
              template={template}
            />
          );
        }
      })}
    </>
  ) : null;
}
