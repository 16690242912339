import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Formik } from 'formik';
import { omit } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { MarketRead } from '@headway/api/models/MarketRead';
import { ProviderAddressCreate } from '@headway/api/models/ProviderAddressCreate';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAddressUpdate } from '@headway/api/models/ProviderAddressUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderLicenseStateApi } from '@headway/api/resources/ProviderLicenseStateApi';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  AddressCard,
  AddressCardContent,
  AddressMapContent,
  Button,
} from '@headway/ui';
import {
  FieldAutocomplete,
  FieldControl,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
  FieldSwitch,
  FormRow,
} from '@headway/ui/form';
import { theme } from '@headway/ui/theme';
import { Tooltip } from '@headway/ui/Tooltip';

interface EditFormProps {
  AuthStore: any;
  UiStore: any;
  providerAddresses: ProviderAddressRead[];
  provider: ProviderRead;
  createProviderAddress: (
    address: ProviderAddressCreate
  ) => Promise<ProviderAddressRead>;
  updateProviderAddress: (
    id: number,
    address: ProviderAddressUpdate
  ) => Promise<ProviderAddressRead>;
  markets: MarketRead[];
  toggleEdit: () => void;
  providerLastAttestedDate: string;
  numDaysSinceLastAttestedDate: number;
}

enum ModalActions {
  Add = 'Add',
  Edit = 'Edit',
  Archive = 'Archive',
}

interface AddressAction {
  type: ModalActions;
  payload: ProviderAddressWithOptionalId;
}

type ProviderAddressWithOptionalId = Omit<ProviderAddressRead, 'id'> & {
  id?: number;
};

const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((acc: any, [key, value]) => {
    const hasChanged = initialValues[key] !== value;
    if (hasChanged) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const getSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().required('First name is required.'),
    lastName: Yup.string().required('Last name is required.'),
    middleName: Yup.string().nullable(true),
    providerLicenseState: Yup.object({
      shownInSearch: Yup.boolean(),
    }),
    stateOfResidence: Yup.mixed()
      .oneOf(Object.values(UnitedStates))
      .required('Required'),
    suffix: Yup.string().nullable(true),
  });
};

export const EditForm = (props: EditFormProps) => {
  const {
    UiStore,
    createProviderAddress,
    updateProviderAddress,
    provider,
    toggleEdit,
    AuthStore,
    providerLastAttestedDate,
    numDaysSinceLastAttestedDate,
  } = props;
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [addressActions, setAddressActions] = useState<AddressAction[]>([]);
  const [addingAddress, setAddingAddress] = useState<boolean>(false);
  const [isManualEditing, setIsManualEditing] = useState<boolean>(false);
  const schema = useMemo(() => getSchema(), []);

  const clearSelectedAddress = () => setSelectedAddress({});

  const handleAddAddress = (address: ProviderAddressWithOptionalId) => {
    setAddressActions([
      ...addressActions,
      { type: ModalActions.Add, payload: address },
    ]);
    setSelectedAddress({});
    setAddingAddress(false);
    setIsManualEditing(false);
  };

  const handleSaveAddressForEdit = (address: ProviderAddressRead) => {
    const indexOfAddress = addressActions.findIndex(
      (ad: AddressAction) =>
        ad.type === ModalActions.Edit && ad.payload.id === address.id
    );

    if (indexOfAddress >= 0) {
      addressActions[indexOfAddress].payload = {
        ...addressActions[indexOfAddress].payload,
        ...address,
      };
      setAddressActions([...addressActions]);
    } else {
      setAddressActions([
        ...addressActions,
        {
          type: ModalActions.Edit,
          payload: { ...selectedAddress, ...address },
        },
      ]);
    }

    setSelectedAddress({});
  };

  const handleLocationSelectForEdit = (address: ProviderAddressRead) => {
    setSelectedAddress(address);
  };

  const handleRemoveAddress = useCallback(
    (address: ProviderAddressWithOptionalId) => {
      const filteredActions = addressActions.filter((ad: AddressAction) => {
        const { payload } = ad;
        return !(
          payload?.streetLine1 === address.streetLine1 &&
          payload?.streetLine2 === address.streetLine2 &&
          payload?.city === address.city &&
          payload?.state === address.state
        );
      });

      setAddressActions([...filteredActions]);
    },
    [addressActions]
  );

  const handleLocationSelectForAdd = (
    address: ProviderAddressWithOptionalId
  ) => {
    setSelectedAddress(address);
  };

  const handleEditAddress = (_: string, address: ProviderAddressRead) => {
    setSelectedAddress(address);
  };

  const handleAddressArchived = (address: ProviderAddressRead) => {
    setAddressActions([
      ...addressActions,
      { type: ModalActions.Archive, payload: address },
    ]);
    setSelectedAddress({});
  };

  const processAddressActions = useCallback(
    async (addressActions: AddressAction[]) => {
      const attributesToPick = [
        'city',
        'state',
        'streetLine1',
        'streetLine2',
        'isActive',
        'zipCode',
      ];
      const requests = addressActions.map((action: AddressAction) => {
        switch (action.type) {
          case ModalActions.Add: {
            return createProviderAddress({
              city: action.payload.city,
              state: action.payload.state,
              streetLine1: action.payload.streetLine1,
              streetLine2: action.payload.streetLine2,
              isActive: action.payload.isActive,
              zipCode: action.payload.zipCode,
              providerLicenseStateId: provider.providerLicenseState?.id,
              providerId: provider.id,
            });
          }
          case ModalActions.Edit: {
            return updateProviderAddress(action.payload?.id!, {
              ...pick(action.payload, attributesToPick),
            });
          }
          case ModalActions.Archive: {
            return updateProviderAddress(action.payload.id!, {
              isActive: false,
            });
          }
          default:
            return Promise.resolve();
        }
      });

      await Promise.all(requests);
    },
    [createProviderAddress, updateProviderAddress, provider]
  );

  const renderedAddresses = useMemo(() => {
    const editedAddresses = addressActions.filter(
      (action: AddressAction) => action.type === ModalActions.Edit
    );
    const addedAddresses = addressActions.filter(
      (action: AddressAction) => action.type === ModalActions.Add
    );
    const archivedAddresses = addressActions.filter(
      (action: AddressAction) => action.type === ModalActions.Archive
    );
    const addresses = props.providerAddresses
      .filter((ad) => ad.isActive)
      .filter(
        (ad) =>
          !archivedAddresses.find(
            (archived: AddressAction) => archived.payload.id === ad.id
          )
      ) as ProviderAddressWithOptionalId[];

    for (const idx in addresses) {
      const address = addresses[idx];
      const hasEditedAddress = editedAddresses.find(
        (ad: AddressAction) => ad.payload.id === address.id
      );

      if (hasEditedAddress) {
        addresses[idx] = hasEditedAddress.payload;
      }
    }

    return [
      ...addresses,
      ...addedAddresses.map((ad: AddressAction) => ad.payload),
    ];
  }, [addressActions, props.providerAddresses]);

  const initialValues = useMemo(
    () => ({
      firstName: provider.firstName,
      lastName: provider.lastName,
      middleName: provider.middleName,
      providerLicenseState: {
        shownInSearch: provider.providerLicenseState.shownInSearch,
      },
      stateOfResidence: provider.stateOfResidence,
      suffix: provider.suffix,
    }),
    [provider]
  );

  const SUBMIT_BUTTON_COPY = 'Confirm my changes';

  const handleSubmit = useCallback(
    async (values: any) => {
      UiStore.enableLoading();
      const changedValues = getChangedValues(values, initialValues);
      let unpickedChangedValues = Object.keys(changedValues);
      if (addressActions.length > 0) {
        unpickedChangedValues.push('address');
      }
      try {
        if (!isEmpty(changedValues)) {
          const providerLicenseStateUpdateValues =
            'providerLicenseState' in changedValues
              ? changedValues['providerLicenseState']
              : null;
          const providerUpdateValues = omit(changedValues, [
            'providerLicenseState',
          ]);
          await ProviderApi.updateProvider(provider.id, providerUpdateValues);
          if (providerLicenseStateUpdateValues) {
            await ProviderLicenseStateApi.updateProviderLicenseState(
              provider.providerLicenseState.id,
              providerLicenseStateUpdateValues
            );
          }
        }

        await processAddressActions(addressActions);
        await AuthStore.fetchMe();
        trackEvent({
          name: 'Provider Attestation Step Viewed',
          properties: {
            lastAttestedDate: providerLastAttestedDate,
            numDaysSinceLastAttested: numDaysSinceLastAttestedDate,
            stepName: 'edited',
            ctaButtonCopy: SUBMIT_BUTTON_COPY,
            editedFields: unpickedChangedValues,
          },
        });
      } catch (e) {
        console.error(e);
        UiStore.showWarningSnackbar('Error: Updating your information failed');
      } finally {
        UiStore.disableLoading();
        toggleEdit();
      }
    },
    [
      addressActions,
      UiStore,
      AuthStore,
      provider,
      toggleEdit,
      initialValues,
      processAddressActions,
      providerLastAttestedDate,
      numDaysSinceLastAttestedDate,
    ]
  );

  return (
    <Box>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
      >
        {({ setFieldValue, handleSubmit: formikHandleSubmit }) => {
          return (
            <>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={4}>
                  <Typography>
                    <b>Legal name:</b>
                  </Typography>
                </Grid>
                <Grid item xs>
                  <FormRow>
                    <FieldControl name="firstName" fullWidth>
                      <FieldInputLabel required>First name</FieldInputLabel>
                      <FieldInput autoComplete="off" />
                      <FieldErrorText />
                    </FieldControl>
                    <FieldControl name="middleName" fullWidth>
                      <FieldInputLabel>Middle name</FieldInputLabel>
                      <FieldInput autoComplete="off" />
                    </FieldControl>
                    <FieldControl name="lastName" fullWidth>
                      <FieldInputLabel required>Last name</FieldInputLabel>
                      <FieldInput autoComplete="off" />
                      <FieldErrorText />
                    </FieldControl>
                    <FieldControl name="suffix" fullWidth>
                      <FieldInputLabel>Suffix</FieldInputLabel>
                      <FieldInput autoComplete="off" />
                    </FieldControl>
                  </FormRow>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Typography>
                    <b>Practice address(es):</b>
                  </Typography>
                </Grid>
                {!renderedAddresses.length && (
                  <Grid item xs>
                    <Typography
                      color="textSecondary"
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Virtual visits only{' '}
                      <Tooltip
                        title={
                          "You're only set up for virtual appointments. Add a practice location address to start seeing patients in person."
                        }
                      >
                        <HelpOutlineIcon
                          sx={{
                            stroke: theme.highContrastColors.black,
                            strokeWidth: 0.5,
                          }}
                          css={{
                            fontSize: '1rem',
                            marginLeft: theme.space.xs2,
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {renderedAddresses.map((address) => (
                <>
                  {!isEmpty(selectedAddress) &&
                  !!address.id &&
                  selectedAddress?.id === address.id ? (
                    <Card
                      sx={{
                        marginBottom: theme.space.base,
                        marginTop: theme.space.base,
                      }}
                      variant="outlined"
                      key={`${address?.id} ${address?.streetLine1}`}
                    >
                      <CardContent>
                        <Typography>Edit address</Typography>
                        <AddressMapContent
                          providerState={
                            props.provider.providerLicenseState.state
                          }
                          selectedAddress={selectedAddress}
                          open={selectedAddress?.id === address.id}
                          onClose={() => setSelectedAddress({})}
                          modalAction="Edit"
                          saveAddress={(add: any) =>
                            handleSaveAddressForEdit({
                              id: address?.id,
                              ...add,
                            })
                          }
                          handleLocationSelect={(add: any) =>
                            handleLocationSelectForEdit({
                              id: address?.id,
                              ...add,
                            })
                          }
                          clearSelectedAddress={clearSelectedAddress}
                          addressFormWithMapProps={{
                            layout: 'horizontal',
                            cancelAddress: clearSelectedAddress,
                          }}
                          REACT_APP_GOOGLE_MAPS_API_ID={
                            process.env.REACT_APP_GOOGLE_MAPS_API_ID
                          }
                          isManualEditing={isManualEditing}
                          setIsManualEditing={setIsManualEditing}
                        />
                      </CardContent>
                    </Card>
                  ) : (
                    <AddressCard
                      address={address}
                      key={`${address?.id} ${address?.streetLine1}`}
                      toggleIsModalOpen={handleEditAddress}
                      toggleAddressArchived={handleAddressArchived}
                      hasActions={!!address.id}
                    >
                      {!address.id && (
                        <AddressCardContent>
                          <Button
                            onClick={() => handleRemoveAddress(address)}
                            color="primary"
                            size="small"
                          >
                            Remove
                          </Button>
                        </AddressCardContent>
                      )}
                    </AddressCard>
                  )}
                </>
              ))}
              {addingAddress && (
                <Card
                  sx={{
                    marginBottom: theme.space.base,
                    marginTop: theme.space.base,
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <Grid
                      container
                      justifyContent="space-between"
                      marginBottom={theme.space.sm}
                    >
                      <Typography>Add address</Typography>
                      <Button
                        onClick={() => {
                          setIsManualEditing(false);
                          setAddingAddress(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <AddressMapContent
                      providerState={props.provider.providerLicenseState.state}
                      selectedAddress={selectedAddress}
                      open={addingAddress}
                      onClose={() => setAddingAddress(false)}
                      modalAction="Add"
                      saveAddress={handleAddAddress}
                      handleLocationSelect={handleLocationSelectForAdd}
                      clearSelectedAddress={() => setAddingAddress(false)}
                      addressFormWithMapProps={{
                        layout: 'horizontal',
                        cancelAddress: () => setAddingAddress(false),
                      }}
                      REACT_APP_GOOGLE_MAPS_API_ID={
                        process.env.REACT_APP_GOOGLE_MAPS_API_ID
                      }
                      isManualEditing={isManualEditing}
                      setIsManualEditing={setIsManualEditing}
                    />
                  </CardContent>
                </Card>
              )}
              <Grid container justifyContent="end">
                <Button
                  onClick={() => {
                    setSelectedAddress({});
                    setAddingAddress(true);
                  }}
                  variant="text"
                  color="primary"
                  disabled={addingAddress}
                >
                  {renderedAddresses.length
                    ? 'Add another address'
                    : 'Add address'}
                </Button>
              </Grid>
              <Grid container sx={{ alignItems: 'start' }}>
                <Grid item xs={3}>
                  <Typography>
                    <b>Accepting new patients?:</b>
                  </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs>
                  <FormRow>
                    <FieldControl
                      name="providerLicenseState.shownInSearch"
                      fullWidth
                    >
                      <FieldSwitch />
                    </FieldControl>
                  </FormRow>
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                <Grid item xs={4}>
                  <Typography>
                    <b>State of residence:</b>
                  </Typography>
                </Grid>
                <Grid item xs>
                  <FormRow>
                    <FieldControl name="stateOfResidence" fullWidth>
                      <FieldAutocomplete
                        disableClearable
                        filterOptions={createFilterOptions({
                          stringify: (value: MarketRead['state']) =>
                            props.markets.find((m) => m.state === value)
                              ?.displayName || '',
                        })}
                        getOptionLabel={(value: MarketRead['state']) =>
                          props.markets.find((m) => m.state === value)
                            ?.displayName || ''
                        }
                        label="State of residence *"
                        onChange={(_: any, value: MarketRead['state']) => {
                          setFieldValue('stateOfResidence', value);
                        }}
                        openOnFocus
                        options={sortBy(props.markets, 'displayName').map(
                          (market) => market.state
                        )}
                      />
                    </FieldControl>
                  </FormRow>
                </Grid>
              </Grid>
              <Button
                size="large"
                fullWidth
                onClick={formikHandleSubmit}
                color="primary"
                variant="contained"
              >
                {SUBMIT_BUTTON_COPY}
              </Button>
              <Box sx={{ padding: '2px' }} />
              <Button
                onClick={props.toggleEdit}
                size="large"
                fullWidth
                sx={{ color: theme.color.black }}
              >
                Cancel
              </Button>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EditForm;
