import { css, cx } from '@emotion/css';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { Checkbox } from '@headway/helix/Checkbox';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';

import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';

import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../stores/AppointmentConfirmationContextV2';
import { FormControlRHF } from '../FormControlRHF';
import { AppointmentConfirmationModalFormV2Values } from '../modals/AppointmentConfirmationModalV2';

export const getAppointmentAttestationInitialValues = () => {
  return false;
};

export const AppointmentAttestationFormV2 = () => {
  const { event, progressNoteState, addendums, patient } = useContext(
    AppointmentConfirmationContextV2
  );

  const progressNoteType: ProgressNoteType | undefined =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      name: 'progressNote.progressNoteType',
    });

  const patientPayer =
    patient?.activeUserInsurance?.billingFrontEndCarrierName || 'insurance';

  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId
  );
  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  // We don't need the attestation if we are editing a template,
  // writing an addendum, or if the time to edit session details has passed
  if (
    (progressNoteType === ProgressNoteType.TEMPLATE &&
      (progressNoteState === ProgressNoteState.EDITING ||
        progressNoteState === ProgressNoteState.ADDENDUM_EDITING)) ||
    progressNoteState === ProgressNoteState.ADDENDUM_EDITING ||
    !canUpdateSessionDetails ||
    !progressNoteType
  ) {
    return null;
  }

  return (
    <div>
      {!!addendums?.length ? (
        <div className={cx(attestationContainerCss)}>
          <SectionHeader>Declaration</SectionHeader>
          <div
            id="attestation"
            data-testid="attestationCheckbox"
            className={cx(checkboxContainerFlagCss)}
          >
            <FormControlRHF
              component={Checkbox}
              name="attestation"
              aria-label="RHF checkbox"
            >
              {`I understand that compliant notes are required for payment. My notes are
                  true, accurate and meet ${patientPayer}'s requirements, and I'll provide supplemental information
                  on request within 3 business days. Further, I attest that I have not made modifications to the original note content and have included only new information in the addendum.`}
            </FormControlRHF>
          </div>
        </div>
      ) : (
        <div className={cx(attestationContainerCss)}>
          <SectionHeader>Declaration</SectionHeader>
          <div
            id="attestation"
            data-testid="attestationCheckbox"
            className={cx(checkboxContainerFlagCss)}
          >
            <FormControlRHF
              component={Checkbox}
              name="attestation"
              aria-label="RHF checkbox"
            >
              {`I understand that compliant notes are required for payment. My notes are
                  true, accurate and meet ${patientPayer}'s requirements, and I'll provide ${
                    progressNoteType === ProgressNoteType.NONE
                      ? 'my notes'
                      : 'supplemental information'
                  }
                  on request within 3 business days.`}
            </FormControlRHF>
          </div>
        </div>
      )}
    </div>
  );
};

const attestationContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: theme.spacing.x5,
});

const checkboxContainerFlagCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing.x5,
});
