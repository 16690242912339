import React, { useContext } from 'react';

import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import { formatPatientName } from '@headway/shared/utils/patient';

import {
  doesAppointmentHaveCrisisCode,
  insuranceStatusAllowedWithCrisisCodeException,
} from 'views/Calendar/events/util/events';

import { AppointmentContext } from '../../../../stores/AppointmentContext';
import { SessionDetailsFormValues } from '../SessionDetailsForm';

export const InsuranceStatusBanner = ({
  patient,
  sessionDetailsFormValues,
}: {
  patient: UserRead;
  sessionDetailsFormValues: SessionDetailsFormValues;
}) => {
  const { appointmentAddressState, isInsuranceAppointment, insuranceStatus } =
    useContext(AppointmentContext);
  const cptCodes = sessionDetailsFormValues?.cptCodes;
  const cptCodesValues = cptCodes?.map((code) => code.value) || [];

  // Don't show error banner if there is a crisis code exception
  const isCrisisCodeExempt =
    insuranceStatusAllowedWithCrisisCodeException.includes(insuranceStatus) &&
    doesAppointmentHaveCrisisCode(cptCodesValues);

  if (isCrisisCodeExempt) {
    return null;
  }
  /**
   * Dont show anything if they are,
   * - self pay
   * - in network, telehealth with a patient location selected
   * - in network, in person
   */
  if (
    !isInsuranceAppointment ||
    ((insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK ||
      insuranceStatus === PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK) &&
      sessionDetailsFormValues.providerAddressId === -1 &&
      sessionDetailsFormValues.appointmentLocationPatientAddressId) ||
    (insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK &&
      sessionDetailsFormValues.providerAddressId &&
      sessionDetailsFormValues.providerAddressId > 0)
  ) {
    return null;
  }

  const sessionLocationPicked = !!sessionDetailsFormValues.providerAddressId;
  const telehealthWithASelectedPatientLocation =
    sessionDetailsFormValues.providerAddressId === -1 &&
    !!sessionDetailsFormValues.appointmentLocationPatientAddressId;
  const telehealthWithNoPatientLocationCausePatientHasNoAddresses =
    sessionDetailsFormValues.providerAddressId === -1 &&
    !sessionDetailsFormValues.appointmentLocationPatientAddressId &&
    patient.patientStates?.length === 0;

  if (
    !sessionLocationPicked ||
    (!telehealthWithASelectedPatientLocation &&
      !telehealthWithNoPatientLocationCausePatientHasNoAddresses)
  ) {
    return null;
  }

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const appointmentState = appointmentAddressState
    ? statesToDisplayNames[appointmentAddressState as UnitedStates]
    : undefined;

  const supportedErrorMessages = [
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK,
    PatientInsuranceOrEAPStatus.IN_NETWORK, // only valid if appointment is virtual and patient has 0 virtual session locations
  ];
  if (!supportedErrorMessages.includes(insuranceStatus)) {
    console.error(
      `Unexpected insurance status message ${insuranceStatus} for user: ${patient.id}`
    );
    return null;
  }

  let guidanceCardCopy =
    telehealthWithNoPatientLocationCausePatientHasNoAddresses
      ? `Looks like ${patientName} doesn't have any locations on file where you are credentialed to practice on Headway. Make sure your client is physically located in a state where you are credentialed during your session.`
      : `You cannot see ${patientName} through their insurance plan in ${appointmentState} because you are not credentialed with ${patientName}’s health plan there. Your client must be physically located in a state where you are credentialed during your session.`;

  return (
    <GuidanceCard variant="error" layout="vertical">
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <BodyText>{guidanceCardCopy}</BodyText>
        <div className="mb-2.5 mt-3 text-left">
          <LinkButton
            variant="link"
            href={CREDENTIALING_REQS_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </LinkButton>
        </div>
      </div>
    </GuidanceCard>
  );
};
