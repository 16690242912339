import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { getUsePatientAssessmentCountByTypeQueryKey } from '~/legacy/hooks/usePatientAssessmentCountByType';

import { PatientAssessmentCompletedByProviderRequest } from '@headway/api/models/PatientAssessmentCompletedByProviderRequest';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { ProgressBar } from '@headway/helix/Progress';
import { toasts } from '@headway/helix/Toast';
import {
  ASSESSMENT_QUESTION_COUNTS,
  FULL_ASSESSMENT_NAMES,
} from '@headway/shared/constants/patientAssessments';
import { useMutation } from '@headway/shared/react-query';
import { AssessmentFormRenderer } from '@headway/ui/assessments/renderer';
import { AssessmentFormValues } from '@headway/ui/assessments/types';

import { getUsePatientAssessmentScoresQueryKey } from 'hooks/usePatientAssessmentScores';

import {
  FillOutAssessmentModalContext,
  ManagementAssessmentStep,
} from './FillOutAssessmentForClientModalContext';

interface PerformAssessmentProps {
  assessmentTypesToComplete: PatientAssessmentType[];
  clientName: string;
  providerPatientId: number;
  onCancel: () => void;
}

export const PerformAssessment: React.FC<PerformAssessmentProps> = ({
  assessmentTypesToComplete,
  providerPatientId,
  clientName,
  onCancel,
}) => {
  const [currentAssessmentTypeIndex, setCurrentAssessmentTypeIndex] =
    useState(0);
  const currentAssessmentType =
    assessmentTypesToComplete[currentAssessmentTypeIndex];
  const isLastAssessment =
    currentAssessmentTypeIndex === assessmentTypesToComplete.length - 1;
  const { setCurrentStep } = useContext(FillOutAssessmentModalContext);
  const scrollRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();
  const goToNextStep = useCallback(() => {
    setCurrentStep(ManagementAssessmentStep.COMPLETE_ASSESSMENTS);
  }, [setCurrentStep]);

  const convertFormData = (
    response: AssessmentFormValues
  ): PatientAssessmentCompletedByProviderRequest => ({
    assessmentType: currentAssessmentType,
    providerPatientId: providerPatientId,
    response: response,
  });

  const completeAssessmentMutation = useMutation(
    async (values: PatientAssessmentCompletedByProviderRequest) => {
      await PatientAssessmentApi.createCompletedPatientAssessmentByProvider(
        values
      );
      const invalidateScores = queryClient.invalidateQueries(
        getUsePatientAssessmentScoresQueryKey({
          providerPatientId,
          assessmentType: values.assessmentType,
        })
      );
      const invalidateCountAssessments = queryClient.invalidateQueries(
        getUsePatientAssessmentCountByTypeQueryKey({
          providerPatientId,
        })
      );
      return Promise.all([invalidateScores, invalidateCountAssessments]);
    },
    {
      onError: () => {
        toasts.add('There was a problem submitting the assessment', {
          variant: 'negative',
        });
      },
    }
  );

  const handleSubmit = async (values: AssessmentFormValues) => {
    const formValuesWithMetadata = convertFormData(values);
    const mutationResult = completeAssessmentMutation.mutateAsync(
      formValuesWithMetadata
    );
    // On the last assessment, wait for the submission to finish before proceeding. Otherwise, we
    // optimistically proceed.
    if (isLastAssessment) {
      await mutationResult;
      goToNextStep();
    } else {
      setCurrentAssessmentTypeIndex(currentAssessmentTypeIndex + 1);
      scrollRef.current?.scrollTo({ top: 0 });
    }
  };

  const submissionText = isLastAssessment ? 'Submit' : 'Submit and continue';

  return (
    <div className="flex-grow overflow-y-auto" ref={scrollRef}>
      <ModalContent>
        <div className="mx-auto my-0 flex w-[690px] max-w-full flex-col gap-6 px-4 py-10">
          <ProgressBar
            valueLabel={`${currentAssessmentTypeIndex + 1} of ${
              assessmentTypesToComplete.length
            }`}
            value={currentAssessmentTypeIndex + 1}
            minValue={0}
            maxValue={assessmentTypesToComplete.length}
            aria-label="Assessment progress"
          />
          <div className="flex flex-col gap-2">
            <div className="mx-0 my-auto flex h-[36px] max-h-full flex-row gap-2">
              <ContentText variant="page-title">Assessments</ContentText>
              <div className="my-auto">
                <ContentText variant="body-large">
                  {currentAssessmentTypeIndex + 1} of{' '}
                  {assessmentTypesToComplete.length}
                </ContentText>
              </div>
            </div>
            <GuidanceCard variant="info">
              You may fill out this assessment with {clientName} during their
              session. If you are conducting a telehealth session, you may
              consider screen-sharing with your client.
            </GuidanceCard>
            <ContentText color="foreground/secondary">
              If your client is experiencing suicidal or homicidal thoughts, are
              in crisis, or need immediate help, please ask them to call 911 or
              go to the nearest emergency department.
            </ContentText>
          </div>
          <div className="space-y-2">
            <div className="w-fit rounded bg-system-backgroundGray p-2">
              <ContentText>
                <b>
                  {FULL_ASSESSMENT_NAMES[currentAssessmentType]} |{' '}
                  {ASSESSMENT_QUESTION_COUNTS[currentAssessmentType]} questions
                </b>
              </ContentText>
            </div>
            <AssessmentFormRenderer
              assessmentType={currentAssessmentType}
              id="assessment-form"
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="flex flex-grow justify-between">
          <Button variant="secondary" onPress={onCancel}>
            Cancel
          </Button>
          <Button
            form="assessment-form"
            type="submit"
            disabled={completeAssessmentMutation.isLoading && isLastAssessment}
          >
            {submissionText}
          </Button>
        </div>
      </ModalFooter>
    </div>
  );
};
