import React from 'react';
import {
  AriaDialogProps,
  FocusScope,
  mergeProps,
  OverlayContainer,
  useDialog,
  useLocalizedStringFormatter,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { IconButton } from './IconButton';
import { IconCaretLeft } from './icons/CaretLeft';
import { IconX } from './icons/X';
import { ProgressBar } from './Progress';

type ModalProgressProps = {
  value: number;
  max?: number;
  min?: number;
  valueLabel?: string;
};

type ModalProps = {
  /** The contents of the Dialog. */
  children: React.ReactNode;
  /** Handler that is called when the Dialog is dismissed. */
  onDismiss?: () => void;

  /** The callback to fire when the back arrow in the modal header is pressed */
  onBack?: () => void;
  /** The title of the Modal to be displayed in the header */
  title: React.ReactNode;

  'aria-labelledby'?: AriaDialogProps['aria-labelledby'];

  /** Whether the Modal is open by default (controlled). */
  isOpen?: boolean;
  /** Whether the Modal is open by default (uncontrolled). */
  defaultOpen?: boolean;
  /** Handler that is called when the Modal's open state changes. */
  onOpenChange?: (isOpen: boolean) => void;
  /** Whether the Modal can be dismissed */
  isDismissable?: boolean;

  variant?: 'fullscreen' | 'content' | 'sidesheet';

  /* Which layout to apply to the ModalContent. Only used for fullscreen variant */
  layout?: 'contained' | 'full-bleed';

  /* Props for the progress bar shown below the header */
  progress?: ModalProgressProps;

  /** Whether to disable trapping focus on the modal */
  disableTrapFocus?: boolean;

  /** modal id for modal css targetting */
  id?: string;
};

interface ModalFooterProps {
  children: React.ReactNode;
}
const ModalFooter = (props: ModalFooterProps) => {
  return (
    <div className="hlx-modal-footer">
      <div className="hlx-modal-footer-inner">{props.children}</div>
    </div>
  );
};

export function ModalFooterTertiarySlot(props: { children: React.ReactNode }) {
  return <div className="hlx-modal-footer-tertiary-slot">{props.children}</div>;
}

interface ModalContentProps {
  children: React.ReactNode;
}
const ModalContent = (props: ModalContentProps) => {
  return (
    <div className="hlx-modal-content">
      <div className="hlx-modal-content-inner">{props.children}</div>
    </div>
  );
};

const BACKGROUND_MODAL_SCALE = '0.96';

const messages = {
  'en-US': {
    dismiss: 'Dismiss',
  },
};
const ONE_PASSWORD_TAG_NAME = 'COM-1PASSWORD-BUTTON';

function Modal({
  children,
  onDismiss,
  onBack,
  isDismissable = true,
  disableTrapFocus = false,
  ...props
}: ModalProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const state = useOverlayTriggerState(props);

  const { overlayProps, underlayProps } = useOverlay(
    {
      ...props,
      isDismissable: isDismissable,
      isKeyboardDismissDisabled: !isDismissable,
      shouldCloseOnInteractOutside: (e) => {
        if (e.tagName === ONE_PASSWORD_TAG_NAME) {
          return false;
        }
        return true;
      },
      onClose: () => {
        state.close();
        onDismiss?.();
      },
    },
    ref
  );
  usePreventScroll({
    isDisabled: !state.isOpen,
  });
  const { modalProps } = useModal();

  const stringFormatter = useLocalizedStringFormatter(messages);
  const { dialogProps, titleProps } = useDialog(
    {
      'aria-labelledby': props['aria-labelledby'],
    },
    ref
  );

  React.useEffect(() => {
    // When this modal is opened, find all other modals and scale them down.
    if (state.isOpen) {
      const thisModal = ref.current;
      const modals = document.querySelectorAll<HTMLDivElement>(
        '.hlx-modal[data-hlx-open="true"][data-hlx-variant="content"]'
      );

      for (const modal of modals) {
        if (modal === thisModal) {
          continue;
        }

        modal.style.setProperty('--scale', BACKGROUND_MODAL_SCALE);
      }

      return () => {
        for (const modal of modals) {
          if (modal === thisModal) {
            continue;
          }

          modal.style.removeProperty('--scale');
        }
      };
    }
  }, [state.isOpen]);

  if (!state.isOpen) {
    return null;
  }

  const variant = props.variant || 'content';

  const layout = props.variant === 'fullscreen' ? props.layout : undefined;

  return (
    <OverlayContainer>
      <div
        {...underlayProps}
        className="hlx-modal-underlay"
        data-hlx-variant={variant}
      >
        <FocusScope contain={!disableTrapFocus} restoreFocus autoFocus>
          <section
            id={props.id}
            className="hlx-modal hlx-modal-root"
            data-hlx-open={state.isOpen}
            data-hlx-variant={variant}
            data-hlx-layout={layout}
            ref={ref}
            {...mergeProps(modalProps, dialogProps, overlayProps)}
          >
            <div className="hlx-modal-header">
              {onBack && (
                <IconButton
                  aria-label={stringFormatter.format('dismiss')}
                  onPress={() => {
                    onBack();
                    state.close();
                  }}
                  variant="transparent"
                >
                  <IconCaretLeft size={16} />
                </IconButton>
              )}
              <span className="hlx-modal-heading" {...titleProps}>
                {props.title}
                {props.variant === 'fullscreen' &&
                  props.progress?.valueLabel && (
                    <span className="hlx-modal-heading-progress">
                      {props.progress.valueLabel}
                    </span>
                  )}
              </span>
              {isDismissable && (
                <IconButton
                  aria-label={stringFormatter.format('dismiss')}
                  onPress={() => {
                    // Call click handler after capturing the click event.
                    // Otherwise, the modal will close before the event is
                    // processed, causing any buttons behind the modal at
                    // the same pointer position to be "clicked"
                    setTimeout(() => {
                      onDismiss?.();
                      state.close();
                    }, 0);
                  }}
                  variant="transparent"
                >
                  <IconX />
                </IconButton>
              )}
            </div>
            <div className="hlx-modal-content-outer">
              {props.variant === 'fullscreen' && props.progress && (
                <ProgressBar
                  value={props.progress.value}
                  minValue={props.progress.min}
                  maxValue={props.progress.max}
                  aria-label="Progress"
                />
              )}
              {children}
            </div>
          </section>
        </FocusScope>
      </div>
    </OverlayContainer>
  );
}

export { Modal, ModalContent, ModalFooter };
