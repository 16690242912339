import { getLocalTimeZone } from '@internationalized/date';
import { Skeleton } from '@mui/material';
import { Formik } from 'formik';
import flatten from 'lodash/flatten';
import uniqueId from 'lodash/uniqueId';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { usePatientAddressesList } from '~/legacy/hooks/usePatientAddresses';
import { useProviderById } from '~/legacy/hooks/useProviderById';
import { useProviderEventList } from '~/legacy/hooks/useProviderEvent';
import { useUpdateProviderEventMutation } from '~/legacy/mutations/providerEvent';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import {
  getProviderDisplayFirstAndLast,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';
import { checkExhaustive } from '@headway/shared/utils/types';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { convertFormValuesToProviderEventUpdate } from '../../AppointmentConfirmation/components/forms/SessionDetails/utils';
import { useConfirmSession } from '../../AppointmentConfirmation/hooks/formActions/useConfirmSession';
import { useSessionDetailsInitialValues } from '../../AppointmentConfirmation/hooks/initialValues/useSessionDetailsInitialValues';
import {
  BulkConfirmFormValues,
  BulkConfirmSubmissionStatus,
  SubmissionStatusMap,
  UnconfirmedEventsData,
} from '../utils/types';
import { AttestationModal } from './steps/AttestationModal';
import { DataEntryStep } from './steps/DataEntryStep';
import { ErrorCorrectionStep } from './steps/ErrorCorrectionStep';
import { SubmissionStep } from './steps/SubmissionStep';

interface SessionDetailsDataFetcherProps {
  event: ProviderEventRead;
  setInitialValues: React.Dispatch<React.SetStateAction<BulkConfirmFormValues>>;
}

// Our existing hook to fetch sessionDetailsInitialValues can only fetch for a single event
// This component allow us to map through each event and fetch sessionDetailsInitialValues for each one
const SessionDetailsDataFetcher = ({
  event,
  setInitialValues,
}: SessionDetailsDataFetcherProps) => {
  const providerAppointment = event.providerAppointment;
  const patient = providerAppointment?.patient;

  const { initialValues: sessionDetailsInitialValues } =
    useSessionDetailsInitialValues({
      event,
      isEventLoading: false,
      patient,
    });

  useEffect(() => {
    if (sessionDetailsInitialValues && event.virtualId) {
      setInitialValues((current) => ({
        ...current,
        sessions: {
          ...current.sessions,
          [event.virtualId]: sessionDetailsInitialValues,
        },
      }));
    }
  }, [sessionDetailsInitialValues, event.virtualId, setInitialValues]);

  return null;
};

const createUnconfirmedEventDataMap = (
  unconfirmedEvents: ProviderEventRead[],
  providerAddresses: ProviderAddressRead[],
  patientAddresses: PatientAddressRead[]
): UnconfirmedEventsData => {
  const eventsData: UnconfirmedEventsData = {};

  unconfirmedEvents.forEach((unconfirmedEvent) => {
    const providerAddress = providerAddresses.find(
      (providerAddress) =>
        providerAddress.id === unconfirmedEvent.providerAddressId
    );
    const patientAddress = patientAddresses.find(
      (patientAddress) =>
        patientAddress.id ===
        unconfirmedEvent.providerAppointment
          ?.appointmentLocationPatientAddressId
    );

    eventsData[unconfirmedEvent.virtualId] = {
      event: unconfirmedEvent,
      providerAddress,
      patientAddress,
    };
  });

  return eventsData;
};

export const BulkConfirm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const eventVirtualIds = searchParams.getAll('event');
  const [initialValues, setInitialValues] = useState<BulkConfirmFormValues>({
    sessions: {},
    notesAttestation: false,
  });
  const { providerAddresses } = useContext(ProviderAddressContext);

  const providerEventQueries = useProviderEventList(
    eventVirtualIds.map((id) => ({
      queryKeyArgs: { eventIdOrVirtualId: id },
      // Avoid refetching so that events don't get filtered out once they become confirmed.
      options: { staleTime: Infinity, refetchOnWindowFocus: false },
    }))
  );

  const events = providerEventQueries.map((query) => query.data);

  const unconfirmedEvents = events.filter(
    (event) =>
      event?.providerAppointment?.status === ProviderAppointmentStatus.SCHEDULED
  ) as ProviderEventRead[];

  const providerQuery = useProviderById(
    {
      providerId: unconfirmedEvents[0]?.providerId,
    },
    { refetchOnWindowFocus: false }
  );

  const patientAddressQueries = usePatientAddressesList(
    unconfirmedEvents.map((event) => ({
      queryKeyArgs: { patientId: event?.providerAppointment?.patient?.id },
      refetchOnWindowFocus: false,
    }))
  );

  const isLoading =
    providerQuery.isLoading ||
    providerEventQueries.some((query) => query.isLoading) ||
    patientAddressQueries.some((query) => query.isLoading);

  const provider = providerQuery.data;

  const patientAddresses = flatten(
    patientAddressQueries.map((query) => query.data)
  ) as PatientAddressRead[];

  if (
    !isLoading &&
    (!provider || !unconfirmedEvents.length || !providerAddresses.length)
  )
    return <Navigate to="/practice/billing" replace />;

  const unconfirmedEventsData = !isLoading
    ? createUnconfirmedEventDataMap(
        unconfirmedEvents,
        providerAddresses,
        patientAddresses
      )
    : undefined;

  return (
    <>
      {unconfirmedEvents.map((event) => (
        <SessionDetailsDataFetcher
          key={event.virtualId}
          event={event}
          setInitialValues={setInitialValues}
        />
      ))}
      <BulkConfirmContent
        isLoading={isLoading}
        onDismiss={() => navigate(-1)}
        initialValues={initialValues}
        provider={provider}
        unconfirmedEventsData={unconfirmedEventsData}
      />
    </>
  );
};

interface BulkConfirmContentProps {
  isLoading: boolean;
  onDismiss: () => void;
  initialValues: BulkConfirmFormValues;
  provider?: ProviderRead;
  unconfirmedEventsData?: UnconfirmedEventsData;
}

const dateFormatter = Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  timeZone: getLocalTimeZone(),
});

enum BulkConfirmStep {
  DATA_ENTRY = 'DATA_ENTRY',
  SUBMISSION = 'SUBMISSION',
  ERROR_CORRECTION = 'ERROR_CORRECTION',
  SUCCESS = 'SUCCESS',
}

/** Exported only for testing */
export const BulkConfirmContent = ({
  isLoading,
  onDismiss,
  initialValues,
  provider,
  unconfirmedEventsData,
}: BulkConfirmContentProps) => {
  const [isAttestationModalOpen, setIsAttestationModalOpen] = useState(false);
  const formId = useState(uniqueId('bulk-confirm-form-'))[0];
  const updateProviderEventMutation = useUpdateProviderEventMutation();
  const { confirmSession } = useConfirmSession();
  const date = Object.values(unconfirmedEventsData || {})[0]?.event?.startDate;

  const [
    submissionStatusByEventVirtualId,
    setSubmissionStatusByEventVirtualId,
  ] = useState<SubmissionStatusMap>({});

  // Initialize the submission status map when the data is loaded.
  useEffect(() => {
    if (
      !isLoading &&
      Object.keys(submissionStatusByEventVirtualId).length === 0
    ) {
      setSubmissionStatusByEventVirtualId(
        Object.keys(initialValues.sessions).reduce(
          (acc, eventVirtualId) => ({
            ...acc,
            [eventVirtualId]: BulkConfirmSubmissionStatus.UNSUBMITTED,
          }),
          {}
        )
      );
    }
  }, [isLoading, initialValues, submissionStatusByEventVirtualId]);

  const currentStep = useMemo(() => {
    const values = Object.values(submissionStatusByEventVirtualId);
    if (
      values.every(
        (status) => status === BulkConfirmSubmissionStatus.UNSUBMITTED
      )
    ) {
      return BulkConfirmStep.DATA_ENTRY;
    }
    if (
      values.some((status) => status === BulkConfirmSubmissionStatus.LOADING)
    ) {
      return BulkConfirmStep.SUBMISSION;
    }
    if (values.some((status) => status === BulkConfirmSubmissionStatus.ERROR)) {
      return BulkConfirmStep.ERROR_CORRECTION;
    }
    if (
      values.every((status) => status === BulkConfirmSubmissionStatus.SUCCESS)
    ) {
      return BulkConfirmStep.SUCCESS;
    }
    throw new Error('Unexpected submission status');
  }, [submissionStatusByEventVirtualId]);

  const sessionCount = Object.keys(submissionStatusByEventVirtualId).length;
  const sessionsWithErrors = Object.values(
    submissionStatusByEventVirtualId
  ).filter((status) => status === BulkConfirmSubmissionStatus.ERROR).length;

  const handleSubmit = async (values: BulkConfirmFormValues) => {
    setIsAttestationModalOpen(false);
    const virtualIdsToSubmit = Object.entries(submissionStatusByEventVirtualId)
      .filter(
        ([, status]) =>
          status === BulkConfirmSubmissionStatus.UNSUBMITTED ||
          status === BulkConfirmSubmissionStatus.ERROR
      )
      .map(([virtualId]) => virtualId);

    // Update the submission status to LOADING for the events that are being submitted
    setSubmissionStatusByEventVirtualId((current) =>
      virtualIdsToSubmit.reduce(
        (acc, virtualId) => ({
          ...acc,
          [virtualId]: BulkConfirmSubmissionStatus.LOADING,
        }),
        current
      )
    );

    const submissionPromises = virtualIdsToSubmit.map(async (virtualId) => {
      // Save the draft details first before attempting confirmation.
      await updateProviderEventMutation.mutateAsync({
        eventIdOrVirtualId: virtualId,
        update: convertFormValuesToProviderEventUpdate(
          values.sessions[virtualId]
        ),
      });

      const { errors } = await confirmSession({
        patient:
          unconfirmedEventsData![virtualId].event.providerAppointment!.patient,
        provider: provider!,
        event: unconfirmedEventsData![virtualId].event,
        eventVirtualId: virtualId,
        values: values.sessions[virtualId],
      });

      if (errors.length > 0) {
        setSubmissionStatusByEventVirtualId((current) => ({
          ...current,
          [virtualId]: BulkConfirmSubmissionStatus.ERROR,
        }));
        return;
      }

      setSubmissionStatusByEventVirtualId((current) => ({
        ...current,
        [virtualId]: BulkConfirmSubmissionStatus.SUCCESS,
      }));
    });

    await Promise.all(submissionPromises);
  };

  return (
    <Formik<BulkConfirmFormValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <SafeFormikForm id={formId}>
        <Modal
          isOpen
          variant="fullscreen"
          title={
            provider && date ? (
              <>
                Confirm sessions for{' '}
                {getProviderDisplayFirstAndLastWithPrenomial(provider)}
                <span className="ml-2">
                  <ContentText variant="body">
                    {dateFormatter.format(new Date(date))}
                  </ContentText>
                </span>
              </>
            ) : undefined
          }
          onDismiss={onDismiss}
        >
          <ModalContent>
            {isLoading || !unconfirmedEventsData || sessionCount === 0 ? (
              <div className="p-10">
                <Skeleton variant="rectangular" height={600} />
              </div>
            ) : currentStep === BulkConfirmStep.DATA_ENTRY ? (
              <>
                <DataEntryStep
                  provider={provider!}
                  unconfirmedEventsData={unconfirmedEventsData}
                />
                <AttestationModal
                  formId={formId}
                  isOpen={isAttestationModalOpen}
                  onDismiss={() => setIsAttestationModalOpen(false)}
                  unconfirmedEventsData={unconfirmedEventsData}
                  providerName={getProviderDisplayFirstAndLast(provider!)}
                />
              </>
            ) : currentStep === BulkConfirmStep.SUBMISSION ? (
              <SubmissionStep
                submissionStatusByEventVirtualId={
                  submissionStatusByEventVirtualId
                }
                unconfirmedEventsData={unconfirmedEventsData}
              />
            ) : currentStep === BulkConfirmStep.ERROR_CORRECTION ? (
              <ErrorCorrectionStep
                provider={provider!}
                submissionStatusByEventVirtualId={
                  submissionStatusByEventVirtualId
                }
                unconfirmedEventsData={unconfirmedEventsData}
              />
            ) : currentStep === BulkConfirmStep.SUCCESS ? (
              <div>TODO: Success</div>
            ) : (
              checkExhaustive(currentStep)
            )}
          </ModalContent>
          <ModalFooter>
            {isLoading ? null : currentStep === BulkConfirmStep.DATA_ENTRY ? (
              <>
                {/** TODO(GP-77) */}
                <Button variant="secondary">Close and save as draft</Button>
                <Button onPress={() => setIsAttestationModalOpen(true)}>
                  Confirm {Object.keys(submissionStatusByEventVirtualId).length}{' '}
                  session{sessionCount > 1 ? 's' : ''}
                </Button>
              </>
            ) : currentStep === BulkConfirmStep.ERROR_CORRECTION ? (
              <>
                {/** TODO(GP-77) */}
                <Button variant="secondary">Close and save as draft</Button>
                <Button type="submit" form={formId}>
                  {sessionsWithErrors === 1
                    ? 'Resubmit unconfirmed session'
                    : `Resubmit unconfirmed sessions (${sessionsWithErrors})`}
                </Button>
              </>
            ) : null}
          </ModalFooter>
        </Modal>
      </SafeFormikForm>
    </Formik>
  );
};
