import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_NPI_ERROR_MESSAGE,
  YUP_NPI_MATCH,
} from '@headway/shared/constants/format';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { RestrictedPracticeAuthority } from '../../utils/supervisingPhysicianConfig';
import {
  getSupervisingPhysicianRequirement,
  ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep,
  SupervisingPhysicianStatusOption,
} from './helpers';

export const StateSupervisingPhysiciansForm = ({
  state,
  formValues,
  supervisingPhysicianRequirement,
  includeReuseFirstSupervisingPhysicianOption,
}: {
  state: UnitedStates;
  formValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
  supervisingPhysicianRequirement: RestrictedPracticeAuthority;
  includeReuseFirstSupervisingPhysicianOption: boolean;
}) => {
  const { provider } = useQuestionnaireContext();
  // TODO: fetch initial values from the supervising physicians API
  const hasSupervisingPhysician =
    formValues.userInputtedSupervisingPhysicians?.[state]
      ?.hasSupervisingPhysician;

  const shouldCollectSupervisingPhysicianInfo =
    hasSupervisingPhysician === SupervisingPhysicianStatusOption.YES;
  const formPrefix = `userInputtedSupervisingPhysicians[${state}]`;

  return (
    <div className="mb-7">
      <div className="my-1">
        <b>Collaborative Practice Agreement</b>
      </div>
      <FormControl
        name={`${formPrefix}hasSupervisingPhysician`}
        component={RadioGroup}
        label={`Do you have a collaborating physician who is licensed in ${statesToDisplayNames[state]}?`}
      >
        {includeReuseFirstSupervisingPhysicianOption ? (
          <>
            <Radio value={SupervisingPhysicianStatusOption.REUSE_EXISTING}>
              Yes. I have the same collaborating physician in all my practice
              states.
            </Radio>
            <Radio value={SupervisingPhysicianStatusOption.YES}>
              Yes. I have a different collaborating physician in this state.
            </Radio>
          </>
        ) : (
          <Radio value={SupervisingPhysicianStatusOption.YES}>Yes</Radio>
        )}
        <Radio value={SupervisingPhysicianStatusOption.NO}>
          No. I would like help getting one.
        </Radio>
        {supervisingPhysicianRequirement ===
          RestrictedPracticeAuthority.MAYBE && (
          <Radio value={SupervisingPhysicianStatusOption.EXEMPT}>
            <span>
              No. I am authorized to practice independently.{' '}
              <Link href="TODO: HELP CENTER LINK">
                Learn if I am eligible for this option
              </Link>
            </span>
          </Radio>
        )}
      </FormControl>
      <div className="mt-3">
        {hasSupervisingPhysician === SupervisingPhysicianStatusOption.NO && (
          <GuidanceCard variant="info" layout="vertical">
            <div>
              No problem, we’ll email you at <b>{provider.email}</b> with more
              information after you submit intake. We’ll start the credentialing
              process, but you won’t be able to schedule sessions until you get
              a collaborating physician who will sign our Collaborating
              Physician Agreement.
            </div>
          </GuidanceCard>
        )}
        {hasSupervisingPhysician ===
          SupervisingPhysicianStatusOption.EXEMPT && (
          <GuidanceCard variant="warning" layout="vertical">
            [Before you’re credentialed, we’ll conduct a review to make sure you
            fit the exemption criteria, and reach out if we have any questions.]
          </GuidanceCard>
        )}
      </div>
      {shouldCollectSupervisingPhysicianInfo && (
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x3,
          }}
        >
          <FormControl
            component={TextField}
            name={`${formPrefix}npiOfSupervisingPhysician`}
            label={
              <>
                What is your collaborating physician’s Type 1 NPI?{' '}
                <Link href="TODO: HELP CENTER LINK">How to look up an NPI</Link>
              </>
            }
            type="number"
            css={{ marginBottom: theme.spacing.x3 }}
          />
          <FormControl
            component={TextField}
            name={`${formPrefix}nameOfSupervisingPhysician`}
            label="Collaborating physician’s full name"
            css={{ marginBottom: theme.spacing.x3 }}
          />
          <FormControl
            component={TextField}
            name={`${formPrefix}emailOfSupervisingPhysician`}
            label={
              <>
                <div>Collaborating physician’s email address</div>
                <div className="font-normal">
                  We’ll email you and your collaborating physician our agreement
                  to review and sign.{' '}
                  <Link href="TODO: HELP CENTER LINK">Preview email</Link>
                </div>
              </>
            }
            type="email"
          />
        </div>
      )}
    </div>
  );
};

export const getSupervisingPhysicianValidationSchema = (
  state: UnitedStates,
  provider: ProviderRead,
  flags: { [key: string]: boolean } | undefined
) => {
  return Yup.object().shape({
    hasSupervisingPhysician: Yup.string().when([], {
      is: () =>
        getSupervisingPhysicianRequirement(
          state,
          provider,
          flags?.enableSupervisingPhysicians
        ) !== RestrictedPracticeAuthority.FULL,
      then: Yup.string().required('This field is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    npiOfSupervisingPhysician: Yup.string().when('hasSupervisingPhysician', {
      is: SupervisingPhysicianStatusOption.YES,
      then: Yup.string()
        .matches(YUP_NPI_MATCH, YUP_NPI_ERROR_MESSAGE)
        .required('This field is required.'),
    }),
    nameOfSupervisingPhysician: Yup.string().when('hasSupervisingPhysician', {
      is: SupervisingPhysicianStatusOption.YES,
      then: Yup.string().required('This field is required.'),
    }),
    emailOfSupervisingPhysician: Yup.string().when('hasSupervisingPhysician', {
      is: SupervisingPhysicianStatusOption.YES,
      then: Yup.string()
        .required('This field is required.')
        .email('Invalid email address.'),
    }),
  });
};
