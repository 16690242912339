import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { isExistingProvider } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { isGroupAdmin } from 'utils/access';

import { IntakeLocation } from './intakeQuestionnaireConstants';

export const getNewlySelectedPracticeStates = (
  provider: ProviderRead,
  providerSelectedPracticeStates: UnitedStates[] | undefined
) => {
  const nonLiveStates = provider.activeProviderLicenseStates
    .filter((license) => !license.liveOn)
    .map((pls) => pls.state);

  return (
    providerSelectedPracticeStates?.filter((state) =>
      nonLiveStates.includes(state)
    ) ?? []
  );
};

export const getIsGPNonAdminAddingNewStates = (
  provider: ProviderRead,
  user: UserRead,
  newlySelectedPracticeStates: UnitedStates[]
): boolean => {
  return (
    isExistingProvider(provider) &&
    !!provider.groupPracticeId &&
    !isGroupAdmin(user) &&
    !!newlySelectedPracticeStates.length
  );
};

export const isNotCanadianAddress = (
  location: string,
  country: string
): boolean => {
  return (
    location === IntakeLocation.US_CANADA &&
    country.toUpperCase() !== IntakeLocation.CANADA
  );
};
