import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { Banner } from '@headway/helix/Banner';
import { BodyText } from '@headway/helix/BodyText';

export enum PPRAnthemCANVBannerTypes {
  PPR_TALK_THERAPY_INTAKES = 'PPR_TALK_THERAPY_INTAKES',
  PPR_PRESCRIBER = 'PPR_PRESCRIBER',
  PPR_GROUP_ADMIN = 'PPR_GROUP_ADMIN',
  PPR_GROUP_ADMIN_PRESCRIBER = 'PPR_GROUP_ADMIN_PRESCRIBER',
  PPR_GROUP_PRACTICE_PROVIDER = 'PPR_GROUP_PRACTICE_PROVIDER',
}

const uppercaseCarrierName = (carrierName: string) =>
  carrierName.charAt(0).toUpperCase() + carrierName.slice(1);

const getBannerContent = ({ version, requiringCarrier }: PprBannerProps) => {
  const carrierName = requiringCarrier?.name
    ? requiringCarrier.name
    : `the client's insurer`;
  const bannerContent: { [key: string]: React.ReactNode } = {
    PPR_TALK_THERAPY_INTAKES: (
      <BodyText>
        {uppercaseCarrierName(carrierName)} is currently auditing all intake
        notes (90791). We're temporarily requiring a Headway template because it
        passes these audits at a 2-3x higher rate than others note formats. This
        should help both reduce the length of the audit and cut down on the time
        you spend fielding feedback from us.
      </BodyText>
    ),
    PPR_PRESCRIBER: (
      <BodyText>
        {uppercaseCarrierName(carrierName)} is currently auditing notes for
        certain prescriber sessions. We're temporarily requiring a note upload
        or Headway template so our team can provide feedback before we submit to{' '}
        {carrierName}. This should help ensure more claims pass review and
        reduce the length of the audit as a whole.
      </BodyText>
    ),
    PPR_GROUP_PRACTICE_PROVIDER: (
      <BodyText>
        {uppercaseCarrierName(carrierName)} is currently auditing group practice
        notes related to intake (90791) and all prescriber sessions. We’re
        temporarily requiring a note upload or Headway template for these
        sessions so our team can provide feedback before we submit to{' '}
        {carrierName}. This should help ensure more claims pass review and
        reduce the length of the audit as a whole.
      </BodyText>
    ),
    PPR_GROUP_ADMIN: (
      <BodyText>
        {uppercaseCarrierName(carrierName)} is currently auditing group practice
        notes related to certain prescriber sessions. We're temporarily
        requiring a note upload for these sessions so our team can provide
        feedback before we submit to {carrierName}. This should help ensure more
        claims pass review and reduce the length of the audit as a whole.
      </BodyText>
    ),
    PPR_GROUP_ADMIN_PRESCRIBER: (
      <BodyText>
        {uppercaseCarrierName(carrierName)} is currently auditing group practice
        notes related to certain prescriber sessions. We're temporarily
        requiring a note upload for these sessions so our team can provide
        feedback before we submit to {carrierName}. This should help ensure more
        claims pass review and reduce the length of the audit as a whole.
      </BodyText>
    ),
  };

  return version in bannerContent ? bannerContent[version] : undefined;
};

interface PprBannerProps {
  version: PPRAnthemCANVBannerTypes;
  requiringCarrier?: FrontEndCarrierRead;
}

export const PprBanner = ({ version, requiringCarrier }: PprBannerProps) => {
  const bannerContent = getBannerContent({ version, requiringCarrier });
  return bannerContent ? (
    <Banner variant="warning">{bannerContent}</Banner>
  ) : null;
};
