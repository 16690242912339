import {
  CalendarDate,
  DateValue,
  getLocalTimeZone,
  parseDate,
  today,
} from '@internationalized/date';
import { CircularProgress } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import sortBy from 'lodash/sortBy';
import zipObject from 'lodash/zipObject';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useGroupPracticeSessionsByProvider } from '~/legacy/hooks/useGroupPracticeSessionsByProvider';
import { useUnconfirmedAppointmentCountsForGroupPractice } from '~/legacy/hooks/useUnconfirmedAppointmentCountsForGroupPractice';
import { PanelLayout } from '~/legacy/layouts/PanelLayout';
import { useAuthStore } from '~/legacy/stores/AuthStore';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventIdInfo } from '@headway/api/models/ProviderEventIdInfo';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { Button, ButtonProps } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import {
  CheckboxFilter,
  FilterGroup,
  FilterItem,
  SearchFilter,
} from '@headway/helix/Filter';
import { Pagination } from '@headway/helix/Pagination';
import {
  SelectedEventContext,
  SelectedEventContextProvider,
} from '@headway/shared/events/SelectedEventContext';
import { BULK_SESSION_CONFIRMATION } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import useFuzzy from '@headway/ui/form/useFuzzy';

import { BillNewSessionsSidesheet } from './components/BillNewSessionsSidesheet';
import { HelishDatePickerButton } from './components/HelishDatePicker';
import {
  SessionAction,
  SessionActionModal,
} from './components/SessionActionModal';
import { SessionDisclosure } from './components/SessionDisclosure';

const PROVIDERS_PER_PAGE = 5;
const MAX_DAYS_IN_PAST = 90;

/**
 * To better facilitate an eventual Remix migration, we use a fake "loader" so we can structure
 * code as if we were receiving server-side data.
 */
const useFauxLoaderData = () => {
  const [searchParams] = useSearchParams();
  const timezone = getLocalTimeZone();
  // Memoize the current date so it doesn't change until the component unmounts.
  const currentDate = useMemo(() => today(timezone).toString(), [timezone]);
  const selectedDate = searchParams.get('date') || currentDate;
  const AuthStore = useAuthStore();
  const groupPracticeId = AuthStore.user?.group_practice?.id;

  const { data: sessionsByProvider, isFetching: isFetchingSessions } =
    useGroupPracticeSessionsByProvider(
      {
        groupPracticeId,
        query: { date: selectedDate, timezone },
      },
      {
        select: (response) => response.summariesByProvider,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    );

  const { data: unconfirmedSessionCountsByDate } =
    useUnconfirmedAppointmentCountsForGroupPractice({
      groupPracticeId,
      query: {
        end_date: today(timezone).add({ days: 1 }).toString(),
        start_date: today(timezone)
          .subtract({ days: MAX_DAYS_IN_PAST })
          .toString(),
        timezone_str: timezone,
      },
    });

  // Entries are sorted to ensure consistent ordering for the useQueries call.
  const sessionsByProviderEntries = sortBy(
    Object.entries(sessionsByProvider || {}),
    ([providerId]) => providerId
  );
  const confirmabilityQueries = useQueries({
    queries: sessionsByProviderEntries.map(([, sessions]) => {
      const ids = sessions
        .map((session) => session.providerEventVirtualId)
        .sort();
      const infos: ProviderEventIdInfo[] = sessions.map((session) => ({
        id: session.providerEventId,
        virtualId: session.providerEventVirtualId,
        calendarEventType: session.providerEventCalendarEventType,
      }));
      return {
        queryKey: ['session-details-confirmabilities', ids],
        queryFn: () =>
          ProviderEventApi.getSessionDetailsConfirmabilitiesForEvents(infos),
        refetchOnWindowFocus: false,
      };
    }),
  });

  const confirmabilityByProvider = zipObject(
    sessionsByProviderEntries.map(([providerId]) => providerId),
    confirmabilityQueries.map((query) => query.data)
  );

  if (!sessionsByProvider) {
    return null;
  }

  return {
    selectedDate,
    sessionsByProvider,
    confirmabilityByProvider,
    unconfirmedSessionCountsByDate,
    isFetchingSessions,
  };
};

export const Billing = () => {
  const data = useFauxLoaderData();
  const [, setSearchParams] = useSearchParams();

  const handleDateChange = (newDate: CalendarDate) => {
    setSearchParams({ date: newDate.toString() });
  };

  return (
    <PanelLayout>
      {data ? (
        <SelectedEventContextProvider>
          <BillingContent data={data} onDateChange={handleDateChange} />
        </SelectedEventContextProvider>
      ) : null}
    </PanelLayout>
  );
};

interface BillingContentProps {
  data: NonNullable<ReturnType<typeof useFauxLoaderData>>;
  onDateChange: (newDate: CalendarDate) => void;
}

/** Exported for testing only. */
export const BillingContent = ({ data, onDateChange }: BillingContentProps) => {
  const isBulkConfirmationEnabled = useFlag(BULK_SESSION_CONFIRMATION, false);
  const { selectedEventVirtualId, setSelectedEventVirtualId } =
    useContext(SelectedEventContext);
  const [selectedSessionAction, setSelectedSessionAction] = useState<
    SessionAction | undefined
  >(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);

  const [filters, setFilters] = useState<['UNCONFIRMED'] | []>([]);

  const [isBillNewSessionsSidesheetOpen, setIsBillNewSessionsSidesheetOpen] =
    useState(false);

  const headerRef = useRef<HTMLDivElement>(null);

  const providerSessionData = Object.values(data.sessionsByProvider).map(
    (sessions) => ({
      provider: {
        id: sessions[0].providerId,
        displayFirstName: sessions[0].providerDisplayFirstName,
        displayLastName: sessions[0].providerDisplayLastName,
        prenomial: sessions[0].providerPrenomial,
        displayFullName: `${sessions[0].providerDisplayFirstName} ${sessions[0].providerDisplayLastName}`,
      },
      sessions,
    })
  );

  const sortedProviderSessionData = sortBy(
    providerSessionData,
    ({ sessions }) =>
      // Providers with unconfirmed sessions appear first. Then, sort alphabetically. Providers
      // with no sessions are already filtered from the API.
      !sessions?.find(
        (sessionSummary) =>
          sessionSummary.providerAppointmentStatus ===
          ProviderAppointmentStatus.SCHEDULED
      ),
    ({ provider }) => provider.displayLastName,
    ({ provider }) => provider.displayFirstName
  );

  const { search } = useFuzzy(sortedProviderSessionData, {
    keys: [
      'provider.displayLastName',
      'provider.displayFirstName',
      'provider.displayFullName',
      'provider.prenomial',
    ],
    threshold: 0.3,
  });

  const sortedFilteredProviderSessionData = (
    searchQuery ? search(searchQuery) : sortedProviderSessionData
  ).filter((sessionData) => {
    if (filters.length === 0) {
      return true;
    }
    return sessionData.sessions.some(
      (session) =>
        session.providerAppointmentStatus !==
        ProviderAppointmentStatus.DETAILS_CONFIRMED
    );
  });

  const providerSessionDataPerPage = chunk(
    sortedFilteredProviderSessionData,
    PROVIDERS_PER_PAGE
  );

  useEffect(() => {
    setPage(0);
  }, [data.selectedDate]);

  useEffect(() => {
    // Ensure the page is within bounds. This might be needed if data refreshes.
    if (page >= providerSessionDataPerPage.length) {
      setPage(Math.max(providerSessionDataPerPage.length - 1, 0));
    }
  }, [providerSessionDataPerPage.length, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    headerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSearchQueryChange = (value: string) => {
    setSearchQuery(value);
    handlePageChange(0);
  };

  const handleFilterChange = (selection: Set<React.Key>) => {
    setFilters(Array.from(selection) as ['UNCONFIRMED'] | []);
    handlePageChange(0);
  };

  const isDateUnavailable = (date: DateValue) => {
    return (
      date.compare(today(getLocalTimeZone())) > 0 ||
      date.compare(
        today(getLocalTimeZone()).subtract({ days: MAX_DAYS_IN_PAST })
      ) < 0
    );
  };

  const isDateSignificant = (date: CalendarDate) => {
    const count = (data.unconfirmedSessionCountsByDate || {})[date.toString()];
    return count && !isDateUnavailable(date)
      ? `${count} unconfirmed sessions`
      : false;
  };

  const countsList = Object.values(data.unconfirmedSessionCountsByDate || {});
  const totalUnconfirmedCount = countsList.reduce((sum, cur) => sum + cur, 0);

  const BillNewSessionsButton = ({
    variant,
  }: {
    variant: ButtonProps['variant'];
  }) =>
    isBulkConfirmationEnabled && (
      <Button
        variant={variant}
        onPress={() => {
          setIsBillNewSessionsSidesheetOpen(true);
        }}
      >
        + Bill new sessions
      </Button>
    );

  const NoSessionsEmptyState = () => (
    <EmptyState>
      <div className="text-center">
        <ContentText variant="body/medium">No sessions scheduled</ContentText>
        {isBulkConfirmationEnabled && (
          <div className="mt-2 max-w-[330px]">
            <ContentText color="foreground/secondary">
              Add sessions to bill, and we'll cover submitting the claims and
              charging your clients.
            </ContentText>
          </div>
        )}
      </div>
      {isBulkConfirmationEnabled && (
        <div className="flex flex-row">
          <BillNewSessionsButton variant="secondary" />
        </div>
      )}
    </EmptyState>
  );

  return (
    <>
      <div className="grid flex-grow pb-10">
        <div
          className="flex scroll-mt-10 items-center gap-5 overflow-x-auto py-5"
          ref={headerRef}
        >
          <h1>
            <ContentText variant="page-title">Billing</ContentText>
          </h1>
          <HelishDatePickerButton
            value={parseDate(data.selectedDate)}
            onChange={onDateChange}
            isDateUnavailable={isDateUnavailable}
            isDateSignificant={isDateSignificant}
            aria-label="Date of sessions"
            decoratorText={
              totalUnconfirmedCount > 0
                ? String(totalUnconfirmedCount)
                : undefined
            }
          />
          {data.isFetchingSessions && <CircularProgress size={20} />}
          <div className="ml-auto">
            <FilterGroup variant="hybrid">
              <div className="w-[120px] min-w-min">
                <SearchFilter
                  aria-label="Provider search"
                  placeholder="Search"
                  onChange={handleSearchQueryChange}
                />
              </div>
              <CheckboxFilter
                label="Filter"
                selection={filters}
                onSelectionChange={handleFilterChange}
                items={[
                  {
                    key: 'UNCONFIRMED',
                    label: 'Only providers with unconfirmed sessions',
                  },
                ]}
                size="medium"
              >
                {(item) => <FilterItem key={item.key}>{item.label}</FilterItem>}
              </CheckboxFilter>
            </FilterGroup>
          </div>
          <BillNewSessionsButton variant="brand" />
        </div>
        {providerSessionData.length === 0 ? (
          <NoSessionsEmptyState />
        ) : (
          <div className="space-y-5 overflow-x-auto">
            {sortedFilteredProviderSessionData.length === 0 &&
              (searchQuery !== '' ? (
                <NoSearchResultsEmptyState />
              ) : (
                <NoFilterResultsEmptyState
                  onClearFilter={() => handleFilterChange(new Set())}
                />
              ))}
            {(providerSessionDataPerPage[page] || []).map(
              ({ provider, sessions }) => (
                <SessionDisclosure
                  key={provider.id}
                  provider={provider}
                  sessions={sessions}
                  confirmabilityMap={data.confirmabilityByProvider[provider.id]}
                  onSessionAction={(action, session) => {
                    setSelectedEventVirtualId(session.providerEventVirtualId);
                    setSelectedSessionAction(action);
                  }}
                />
              )
            )}
            {providerSessionDataPerPage.length > 1 && (
              <div className="mt-5 flex justify-center px-4 py-2">
                <Pagination
                  page={page + 1}
                  onPageChange={(newPage) => handlePageChange(newPage - 1)}
                  totalPages={providerSessionDataPerPage.length}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <SessionActionModal
        action={selectedSessionAction}
        isOpen={!!(selectedSessionAction && selectedEventVirtualId)}
        onDismiss={() => {
          setSelectedSessionAction(undefined);
          setSelectedEventVirtualId(undefined);
        }}
      />
      {isBillNewSessionsSidesheetOpen && (
        <BillNewSessionsSidesheet
          onDismiss={() => {
            setIsBillNewSessionsSidesheetOpen(false);
          }}
          sessionDate={parseDate(data.selectedDate)}
        />
      )}

      {/** Outlet for rendering the bulk confirm modal as a nested route */}
      <Outlet />
    </>
  );
};

const EmptyState = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col items-center gap-4 rounded bg-system-backgroundGray py-8">
    {children}
  </div>
);

const NoSearchResultsEmptyState = () => (
  <EmptyState>
    <div className="text-center">
      <ContentText variant="body/medium">
        No providers found. Try adjusting your search.
      </ContentText>
    </div>
  </EmptyState>
);

const NoFilterResultsEmptyState = ({
  onClearFilter,
}: {
  onClearFilter: () => void;
}) => (
  <EmptyState>
    <div className="text-center">
      <ContentText variant="body/medium">
        No providers with unconfirmed scheduled sessions.
      </ContentText>
    </div>
    <Button variant="secondary" onPress={onClearFilter}>
      Clear filter
    </Button>
  </EmptyState>
);
