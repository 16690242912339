import clsx from 'clsx';
import React from 'react';
import type {
  TagGroupProps,
  TagListProps,
  TagProps,
} from 'react-aria-components';
import { Button, Label, Tag, TagGroup, TagList } from 'react-aria-components';

import { IconX } from './icons/X';

interface ChipGroupProps<T>
  extends Omit<TagGroupProps, 'children'>,
    Pick<TagListProps<T>, 'items' | 'children' | 'renderEmptyState'> {
  label?: string;
  endSlotPlaceholderRef?: React.RefObject<HTMLDivElement>;
}

export function ChipGroup<T extends object>({
  label,
  items,
  children,
  renderEmptyState,
  ...props
}: ChipGroupProps<T>) {
  return (
    <TagGroup {...props} className="hlx-chip-group hlx-chip-group-root">
      {label && <Label className="hlx-chip-group-label">{label}</Label>}
      <TagList
        items={items}
        renderEmptyState={renderEmptyState}
        className="hlx-chip-list"
      >
        {children}
      </TagList>
      <div
        className="hlx-chip-group-end-slot-placeholder"
        ref={props.endSlotPlaceholderRef}
      ></div>
    </TagGroup>
  );
}

export function Chip({ children, ...props }: TagProps) {
  let textValue = typeof children === 'string' ? children : undefined;

  return (
    <Tag
      textValue={textValue}
      {...props}
      className={({ isFocused, isFocusVisible, isHovered, isDisabled }) =>
        clsx('hlx-chip-root', {
          focused: isFocused,
          disabled: isDisabled,
          'focus-ring': isFocusVisible,
          hovered: isHovered,
        })
      }
    >
      {({ allowsRemoving, isDisabled }) => (
        <>
          <div className="hlx-chip-label">{children as React.ReactNode}</div>
          {allowsRemoving && (
            <Button
              isDisabled={isDisabled}
              className={(state) => {
                return clsx('hlx-chip-action', {
                  active: state.isPressed,
                  hovered: state.isHovered,
                });
              }}
              slot="remove"
            >
              <IconX width="15" height="15" />
            </Button>
          )}
        </>
      )}
    </Tag>
  );
}
