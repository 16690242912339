import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { UserRead } from '@headway/api/models/UserRead';
import { theme } from '@headway/ui/theme';

import { hasNoRemainingSessions } from './hasNoRemainingSessions';

// Session rate for self pay can be zero (to support pro bono work and
// appointments where payment is handled outside of Headway), but otherwise
// we want to enforce a minumum of $5 to avoid losing money on fees.
export const MIN_NONZERO_SELF_PAY_RATE = 5;
export const MIN_NONZERO_SELF_PAY_RATE_MESSAGE = `Session rate may be $0, but otherwise must be at least $${MIN_NONZERO_SELF_PAY_RATE}.`;
export const MAX_SELF_PAY_RATE = 500;
export const MAX_SELF_PAY_RATE_MESSAGE = `Session rate cannot be greater than $${MAX_SELF_PAY_RATE}.`;

export const mapBillingTypeToDisplayNameDescriptive = (type: BillingType) => {
  switch (type) {
    case BillingType.INSURANCE:
      return (
        <span>
          <div
            css={{
              fontWeight: theme.fontWeight.bold,
              fontSize: theme.fontSize.base,
            }}
          >
            Insurance
          </div>
          <div>Bill the client's insurance plan</div>
        </span>
      );
    case BillingType.SELF_PAY:
      return (
        <span>
          <div
            css={{
              fontWeight: theme.fontWeight.bold,
              fontSize: theme.fontSize.base,
            }}
          >
            Private Pay
          </div>
          <div>Only bill the client's payment method</div>
        </span>
      );
    default:
      return '';
  }
};

/**
 * Returns whether a patient is eligible for self-pay billing.
 * TODO: this should all be moved the the backend, and audited/persisted
 * instead of computed at runtime
 */
export const determineSelfPayEligibility = (
  patient: UserRead,
  insuranceStatus: PatientInsuranceOrEAPStatus,
  isSelfPayEligibleEvenIfInNetwork: boolean,
  providerPatient: ProviderPatientRead
): boolean => {
  const hasNoInsurance =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_INSURANCE;
  const isInvitedViaProviderPortal =
    patient.inviteChannel === UserInviteChannel.PROVIDER_PORTAL;
  const isOutOfNetwork =
    patient.activeUserInsurance?.latestEligibilityLookup?.outOfNetwork ?? false;
  const hasNoTelehealthBenefits =
    patient.activeUserInsurance?.latestEligibilityLookup
      ?.hasNoTelehealthBenefits ?? false;
  const noRemainingSessions = hasNoRemainingSessions(patient);
  const isBillingTypeSelfPay =
    providerPatient.billingTypeDefault === BillingType.SELF_PAY;

  const latestLookup = patient.activeUserInsurance?.latestEligibilityLookup;
  const hasLatestEligibilityLookup = latestLookup != null;
  const isManualVerificationRequired =
    latestLookup?.manualVerificationRequired ?? false;

  if (hasNoInsurance) {
    return isInvitedViaProviderPortal;
  }

  if (!isBillingTypeSelfPay) {
    if (!hasLatestEligibilityLookup || isManualVerificationRequired) {
      return false;
    }
  }

  // A user is self pay eligible based on the following conditions:
  // 1. Patient has no insurance and was invited via provider portal.
  // 2. Patient is self-pay eligible even if in-network.
  // 3. Patient's insurance status is not in-network.
  // 4. Patient is out-of-network.
  // 5. Patient has no telehealth benefits and no telehealth experience is enabled. (true)
  // 6. Patient has no remaining sessions.
  // 7. Billing type default is self-pay.
  // So long as the patient isn't already billing type self-pay:
  // 8. If the user has insurance, but no eligibility lookup is available, return false.
  // 9. If the user has insurance, and a lookup, but manual verification is required, return false.
  // If none of the above conditions are met, return false.

  return (
    isSelfPayEligibleEvenIfInNetwork ||
    insuranceStatus !== PatientInsuranceOrEAPStatus.IN_NETWORK ||
    isOutOfNetwork ||
    hasNoTelehealthBenefits ||
    noRemainingSessions ||
    isBillingTypeSelfPay
  );
};
