import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ComboBox, Item } from '@headway/helix/ComboBox';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';
import { getOptionalityText } from '../utils';
import { hasValue } from './ComboBox';
import { OtherTextField } from './OtherTextField';

const MemoizedComboBox = React.memo(ComboBox);

export const FormSelect = ({
  element,
  isOptional = false,
  disabled = false,
  template,
}: SchemaComponent) => {
  const { id, title, options, placeholder, subTitle, optionalityText } =
    element;
  const { control, setValue, trigger } = useFormContext();
  const watchedValue = useWatch({
    control,
    name: id,
  });

  const selectOptions = Array.from(options as string[]).map((option) => {
    return { key: option };
  });

  const selectedKey = watchedValue;

  const [showOtherFieldInput, setShowOtherFieldInput] = useState(
    selectedKey ? selectedKey.toLowerCase().includes('other') : false
  );

  const onSelectionChange = useCallback(
    (value: Set<string>) => {
      if (!disabled) {
        setShowOtherFieldInput(hasValue(value, 'Other'));
        setValue(id, Array.from(value)[0]);
        trigger(id);
      }
    },
    [setValue, id, disabled, trigger]
  );

  let selectedKeys: Set<string> = new Set();

  if (showOtherFieldInput) {
    selectedKeys = new Set(['Other']);
  } else {
    selectedKeys = selectedKey ? new Set([selectedKey]) : new Set();
  }

  const optionality = useMemo(
    () => getOptionalityText(isOptional, optionalityText),
    [isOptional, optionalityText]
  );

  return (
    <>
      <FormControlRHF
        component={MemoizedComboBox}
        name={id}
        label={title}
        selectedKeys={selectedKeys}
        selectionMode="single"
        menuWidth="stretch"
        onSelectionChange={onSelectionChange}
        optionalityText={optionality}
        disabled={disabled}
        placeholder={placeholder}
        instructionalText={subTitle}
      >
        {selectOptions.map((item: { key: string }) => (
          <Item key={item.key}>{item.key}</Item>
        ))}
      </FormControlRHF>
      {showOtherFieldInput && (
        <OtherTextField
          id={id}
          optionType="single"
          option={'Other'}
          template={template}
          disabled={disabled}
        />
      )}
    </>
  );
};
