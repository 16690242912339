import { useContext } from 'react';

import { Banner } from '@headway/helix/Banner';
import { GuidanceCard } from '@headway/helix/GuidanceCard';

import {
  MedicareMedicaidRequirementWarning,
  MedicareMedicaidRequirementWarningBanner,
} from 'views/Clients/TreatmentPlan/Requirements/MedicareMedicaidRequirements';
import {
  TreatmentPlanRequirement,
  useTreatmentPlanRequirements,
} from 'views/Clients/TreatmentPlan/Requirements/TreatmentPlanRequirements';

import { useRuleSet } from '../ruleset/useRuleSet';
import { AppointmentConfirmationContextV2 } from '../stores/AppointmentConfirmationContextV2';

export const TreatmentPlanRequirementWarningV2 = () => {
  const { patient, provider, event } = useContext(
    AppointmentConfirmationContextV2
  );
  const { treatmentPlanRequirementType } = useTreatmentPlanRequirements(
    patient?.id
  );
  const rule = useRuleSet({ patient, provider, event });

  const isTreatmentPlanRequired = rule?.isTreatmentPlanRequired();
  if (!isTreatmentPlanRequired) {
    return null;
  }

  return (
    <GuidanceCard variant="error">
      {treatmentPlanRequirementType ===
      TreatmentPlanRequirement.MEDICARE_MEDICAID ? (
        <MedicareMedicaidRequirementWarning patient={patient} />
      ) : null}
    </GuidanceCard>
  );
};

export const TreatmentPlanRequirementWarningBanner = () => {
  const { patient, provider, event } = useContext(
    AppointmentConfirmationContextV2
  );
  const { treatmentPlanRequirementType } = useTreatmentPlanRequirements(
    patient?.id
  );
  const rule = useRuleSet({ patient, provider, event });

  const openTreatmentPlanModal = () => {
    window.open(
      `/clients/${patient?.id}/clinical?treatmentPlan=true`,
      '_blank'
    );
  };

  const isTreatmentPlanRequired = rule?.isTreatmentPlanRequired();
  if (!isTreatmentPlanRequired) {
    return null;
  }

  return (
    <Banner variant="warning">
      {treatmentPlanRequirementType ===
      TreatmentPlanRequirement.MEDICARE_MEDICAID ? (
        <MedicareMedicaidRequirementWarningBanner
          patient={patient}
          onOpenTreatmentPlanModal={openTreatmentPlanModal}
        />
      ) : null}
    </Banner>
  );
};
