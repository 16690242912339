import { UnitedStates } from '@headway/api/models/UnitedStates';

export enum RestrictedPracticeAuthority {
  FULL = 'FULL', // an NP in this state can practice independently
  RESTRICTED = 'RESTRICTED', // an NP in this state cannot practice independently
  MAYBE = 'MAYBE', // depends on criteria per-state (e.g. a certain number of hours)
}

export const NP_PRACTICE_AUTHORITY_BY_STATE = {
  [UnitedStates.ALABAMA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.ALASKA]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.ARIZONA]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.ARKANSAS]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.CALIFORNIA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.COLORADO]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.CONNECTICUT]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.DELAWARE]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.DISTRICT_OF_COLUMBIA]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.FLORIDA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.GEORGIA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.HAWAII]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.IDAHO]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.ILLINOIS]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.INDIANA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.IOWA]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.KANSAS]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.KENTUCKY]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.LOUISIANA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.MAINE]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.MARYLAND]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.MASSACHUSETTS]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.MICHIGAN]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.MINNESOTA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.MISSISSIPPI]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.MISSOURI]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.MONTANA]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.NEBRASKA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.NEVADA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.NEW_HAMPSHIRE]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.NEW_JERSEY]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.NEW_MEXICO]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.NEW_YORK]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.NORTH_CAROLINA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.NORTH_DAKOTA]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.OHIO]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.OKLAHOMA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.OREGON]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.PENNSYLVANIA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.PUERTO_RICO]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.RHODE_ISLAND]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.SOUTH_CAROLINA]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.SOUTH_DAKOTA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.TENNESSEE]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.TEXAS]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.UTAH]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.VERMONT]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.VIRGIN_ISLANDS]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.VIRGINIA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.WASHINGTON]: RestrictedPracticeAuthority.FULL,
  [UnitedStates.WEST_VIRGINIA]: RestrictedPracticeAuthority.MAYBE,
  [UnitedStates.WISCONSIN]: RestrictedPracticeAuthority.RESTRICTED,
  [UnitedStates.WYOMING]: RestrictedPracticeAuthority.FULL,
};
