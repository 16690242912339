import merge from 'lodash/merge';
import { useCallback } from 'react';
import { useUpdateProviderEventMutation } from '~/legacy/mutations/providerEvent';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { useQueryClient } from '@headway/shared/react-query';

import { SessionDetailsFormV2Values } from '../../components/forms/SessionDetails/SessionDetailsFormV2';
import { convertFormValuesToProviderEventUpdate } from '../../components/forms/SessionDetails/utils';
import { fetchMiscodingResults } from '../../components/forms/SessionDetails/validation/miscoding';

export type useConfirmSessionProps = {
  patient?: UserRead;
  provider?: ProviderRead;
  event?: ProviderEventRead;
  eventVirtualId?: string | number;
  values: SessionDetailsFormV2Values;
};

export const useConfirmSession = () => {
  const updateEventMutation = useUpdateProviderEventMutation();
  const queryClient = useQueryClient();

  const confirmSession = useCallback(
    async ({
      patient,
      provider,
      event,
      eventVirtualId,
      values,
    }: useConfirmSessionProps) => {
      if (!patient || !provider || !event || !eventVirtualId) {
        return { updatedEvent: undefined, errors: ['Missing required data'] };
      }

      // Will fetch the potential CPT or DX codes errors linked to
      // the session details values
      const { errors: codingErrors } = await fetchMiscodingResults(
        queryClient,
        values.cptCodes || [],
        values.diagnosisCodes || [],
        values.exactStartTime,
        values.exactEndTime,
        patient,
        provider,
        event,
        values.prescriberPsychotherapyStartTime,
        values.prescriberPsychotherapyEndTime
      );

      if (codingErrors.length > 0) {
        return { updatedEvent: undefined, errors: codingErrors };
      }

      const updatedEvent = await updateEventMutation.mutateAsync({
        eventIdOrVirtualId: eventVirtualId,
        update: merge(convertFormValuesToProviderEventUpdate(values), {
          providerAppointment: {
            status: ProviderAppointmentStatus.DETAILS_CONFIRMED,
          },
        }),
      });

      return { updatedEvent, errors: [] };
    },
    [updateEventMutation, queryClient]
  );

  return { confirmSession };
};
