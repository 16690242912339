import { Loader } from '@googlemaps/js-api-loader';
import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useMSCGuardrail } from '~/legacy/hooks/useMSCGuardrail';

import { Practice } from '@headway/api/models/Practice';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderLicenseStateReadByUser } from '@headway/api/models/ProviderLicenseStateReadByUser';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { SectionHeader } from '@headway/helix/SectionHeader';
import {
  abbreviationToStateEnum,
  unitedStatesAbbreviations,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  MULTI_STATE_CREDENTIALING_BETA,
  MULTI_STATE_CREDENTIALING_ONBOARDING,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  getSupportedStates,
  isProviderInAnyStates,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { YesNo } from '../../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { AdditionalInformationQuestions } from './AdditionalInformationQuestions';
import { AdmittingProviderInfoQuestions } from './AdmittingProviderInfoQuestions';
import { PracticeHeader } from './PracticeHeader';
import { PracticeLocationInfo } from './PracticeLocationInfo';
import { PracticeLocationInfoByState } from './PracticeLocationInfoByState';
import { PracticeLocationsSelection } from './PracticeLocationSelections';
import { SectionContainer } from './SectionContainer';
import {
  convertPracticeLocationArrayToPracticeType,
  getCaqhPracticeLocationsToMap,
  getProviderAddedLocationArray,
  getProviderPracticeLocationsToMap,
  isPracticeLocationCaqhPracticeType,
  shouldAskForAdmittingProviderInfo,
  statesThatRequireAttachedToHome,
  statesThatRequireTimelyAccessToCareAttest,
} from './util';

export type PracticeLocationMeta = {
  locationHash?: string;
  isPrimaryPracticeForState?: boolean;
  isCaqhAddress?: boolean;
};
export type PracticeLocation = (ProviderAddressRead | Practice) &
  PracticeLocationMeta;
export type PracticeLocationArray = PracticeLocation[];
export type PracticeLocationMap = { [key: string]: PracticeLocation };
export type SetSelectedPracticeLocations = (
  locations:
    | PracticeLocationArray
    | ((locations: PracticeLocationArray) => PracticeLocationArray)
) => void;
export type TelehealthStates = { [key in UnitedStates]?: boolean };

export const dedupeLocations = async (allLocations: {
  [key: string]: PracticeLocation;
}) => {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_ID as string,
    version: 'weekly',
    libraries: ['places'],
  });
  const google = await loader.load();
  const maps = new google.maps.Geocoder();

  const dedupedLocationsByPlaceId: { [key: string]: PracticeLocation } = {};
  const foundPlaceIds: string[] = [];
  const locationHashes = Object.keys(allLocations);

  for (let i = 0; i < locationHashes.length; i++) {
    // Uses the google maps api to get the geocodeLocation, where we are interested
    // in the placeId which is a textual identifier that uniquely identifies a place
    const locationHash = locationHashes[i];
    const location = allLocations[locationHash];
    const geocodeLocation = await maps.geocode({ address: locationHash });

    // If we have an unexpected result from the API, we skip this processing
    if (geocodeLocation.results.length != 1) {
      continue;
    }

    // Otherwise check if we found the place already, if we did, we prefer to show the location that is in our platform
    const placeId = geocodeLocation.results[0].place_id;
    if (foundPlaceIds.includes(placeId)) {
      if (!location.isCaqhAddress) {
        dedupedLocationsByPlaceId[placeId] = location;
      }
    } else {
      foundPlaceIds.push(placeId);
      dedupedLocationsByPlaceId[placeId] = location;
    }
  }
  // Recreate a locationHash -> PracticeLocation mapping
  return Object.values(dedupedLocationsByPlaceId).reduce(
    (map, location: PracticeLocation) => {
      map[location.locationHash as string] = location;
      return map;
    },
    {} as { [key: string]: PracticeLocation }
  );
};

/**
 *
 * Calculates what locations should be in the LocationMap.
 * The locationMap is a addressHash => address mapping for all
 * locations that are available to the provider based on their
 * selected states.
 *
 * @returns locationMap
 */

export const calculateLocationMap = async (
  providerSelectedStates: UnitedStates[],
  caqhPracticeLocations: Practice[],
  provider: any
) =>
  await dedupeLocations({
    ...getCaqhPracticeLocationsToMap(
      providerSelectedStates,
      caqhPracticeLocations
    ),
    ...getProviderPracticeLocationsToMap(providerSelectedStates, provider),
  });

const PracticeInformationStep = ({
  initialValues,
  formikHelpers,
}: CustomComponentProps) => {
  const { provider } = useQuestionnaireContext();
  const showAdmittingProviderInfoQuestions = shouldAskForAdmittingProviderInfo(
    provider,
    initialValues?.hospitalAffiliations
  );
  const { values, setFieldValue } = formikHelpers;
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const isMSCOnboardingEnabled = useFlag(MULTI_STATE_CREDENTIALING_ONBOARDING);
  const { isMSCGuardrailRestriction } = useMSCGuardrail();

  const isMSCEnabled =
    isMSCBetaEnabled || isMSCOnboardingEnabled || isMSCGuardrailRestriction;

  const [locationMap, setLocationMap] =
    React.useState<PracticeLocationMap | null>(null);

  /**
   * We intercept the "Practice" field update. The "Practice" value contains
   * an array of type Practice.
   *
   * Since we can have a type of ProviderAddress, we need to convert that to type Practice
   * when updating any infor for our selected practices.
   */
  const selectedPracticeLocations = values.practice ?? [];
  const setSelectedPracticeLocations = (
    locationUpdate:
      | PracticeLocationArray
      | ((locations: PracticeLocationArray) => PracticeLocationArray)
  ) => {
    let convertedLocations;
    if (typeof locationUpdate === 'function') {
      const locationUpdateFromFunction = locationUpdate(
        selectedPracticeLocations
      );
      convertedLocations = convertPracticeLocationArrayToPracticeType(
        locationUpdateFromFunction
      );
    } else {
      convertedLocations =
        convertPracticeLocationArrayToPracticeType(locationUpdate);
    }
    setFieldValue('practice', convertedLocations);
  };

  /**
   * The LocationMap is a map of addressHash -> address, that is contains
   * all available addresses for the provider for their selected practice states
   */
  useEffect(() => {
    const runLocationMapCalculation = async () => {
      const calculatedLocationMap = await calculateLocationMap(
        values.providerSelectedPracticeStates ?? [],
        values.caqhPracticeLocations ?? [],
        provider
      );

      setLocationMap(calculatedLocationMap);
    };

    runLocationMapCalculation();
  }, [
    values.providerSelectedPracticeStates,
    values.caqhPracticeLocations,
    provider,
  ]);

  return (
    <>
      <PracticeHeader initialValues={initialValues} />

      {isMSCEnabled ? (
        <SectionContainer>
          <PracticeLocationsSelection
            provider={provider}
            providerSelectedStates={values.providerSelectedPracticeStates ?? []}
            locationMap={locationMap ?? {}}
            selectedPracticeLocations={selectedPracticeLocations}
            setSelectedPracticeLocations={setSelectedPracticeLocations}
          />
          <PracticeLocationInfoByState
            selectedPracticeLocations={selectedPracticeLocations}
            providerSelectedStates={values.providerSelectedPracticeStates ?? []}
            setSelectedPracticeLocations={setSelectedPracticeLocations}
          />
        </SectionContainer>
      ) : (
        <SectionContainer>
          <SectionHeader>Next, add a few more details below</SectionHeader>
          {selectedPracticeLocations?.map((p, idx) => {
            return <PracticeLocationInfo p={p} idx={idx} />;
          })}
        </SectionContainer>
      )}

      {showAdmittingProviderInfoQuestions && (
        <SectionContainer>
          <AdmittingProviderInfoQuestions />
        </SectionContainer>
      )}

      <SectionContainer>
        <AdditionalInformationQuestions provider={provider} />
      </SectionContainer>
    </>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Practice Information',
  description:
    'In this section we ask that you share details about your practice.',
  Component: PracticeInformationStep,
  getFormMeta: ({ provider, providerQuestionnaire }, flags) => {
    const shouldRequireAdmittingProviderInfo =
      shouldAskForAdmittingProviderInfo(
        provider,
        providerQuestionnaire?.rawData?.hospitalAffiliations
      );
    const providerStates: UnitedStates[] =
      provider.activeProviderLicenseStates.map(
        (pls: ProviderLicenseStateReadByUser) => pls.state
      );

    const isMSCEnabled =
      (flags?.multiStateCredentialingBeta ||
        flags?.mscOnboardingAccess ||
        flags?.mscGuardrailRestriction) ??
      false;

    const validationSchema = Yup.object()
      .shape({
        doesHomeVisits: Yup.string().required('This question is required.'),
        doesTelemedicine: Yup.string().required('This question is required.'),
        practiceDocumentationAttest: Yup.boolean()
          .oneOf([true])
          .required('You must read and agree to the statement above.'),
        practice: Yup.array().of(
          Yup.object().shape({
            isPrimaryPracticeForState: Yup.boolean(), // see 'primary-location-selected' test
            street1: Yup.string().required('Address is required.'),
            street2: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            zip: Yup.string().required('ZIP code is required.'),
            attachedToHome: isProviderInAnyStates(
              provider,
              statesThatRequireAttachedToHome
            )
              ? Yup.string().required('This question is required.')
              : Yup.string(),
            genderLimitations: Yup.string(),
            hasAgeLimitations: Yup.string(),
            ageMinimum: Yup.string(),
            ageMaximum: Yup.string(),
            meetsADARequirements: Yup.string(),
            adaAccessibleProperties: Yup.array(Yup.string()),
            hasServicesForDisabled: Yup.string().required(
              'This question is required.'
            ),
            hasPublicTransportation: Yup.string().required(
              'This question is required.'
            ),
            hasInterpreters: isMSCEnabled
              ? Yup.string()
              : Yup.string().required('This question is required.'),
          })
        ),
        telehealthStates: Yup.object(),
        hasHospitalAffiliations: Yup.string().required(
          'This question is required.'
        ),
        hospitalAffiliations: Yup.array().when(
          'hasHospitalAffiliations',
          (hasHospitalAffiliations, schema) =>
            hasHospitalAffiliations === YesNo.YES
              ? schema.of(
                  Yup.object().shape({
                    name: Yup.string().required('Hospital name is required.'),
                    street1: Yup.string().required(
                      'This question is required.'
                    ),
                    street2: Yup.string(),
                    city: Yup.string().required('This question is required.'),
                    state: Yup.string().required('This question is required.'),
                    zip: Yup.string().required('This question is required.'),
                    affiliationType: Yup.string(),
                    isPrimaryHospital: Yup.string(),
                    admittingPrivilegeStatus: Yup.string(),
                    admittingPrivilegeType: Yup.string(),
                    admittingParty: Yup.string(),
                    otherAdmittingPartyDescription: Yup.string(),
                    medicalStaffPhone: Yup.string(),
                    medicalStaffFax: Yup.string(),
                    // TODO: Check if we actually need this
                    // department: Yup.string().when(
                    //   'affiliationType',
                    //   (affiliationType, schema) =>
                    //     affiliationType === 'Admitting Privileges'
                    //       ? Yup.string().required('Department is required')
                    //       : schema
                    // ),
                  })
                )
              : schema
        ),
        admittingProviderName: shouldRequireAdmittingProviderInfo
          ? Yup.string().required('This question is required.')
          : Yup.string(),
        admittingProviderSpecialty: shouldRequireAdmittingProviderInfo
          ? Yup.string().required('This question is required.')
          : Yup.string(),
        admittingProviderHospitalName: shouldRequireAdmittingProviderInfo
          ? Yup.string().required('This question is required.')
          : Yup.string(),
        timelyAccessToCareAttest: isProviderInAnyStates(
          provider,
          statesThatRequireTimelyAccessToCareAttest
        )
          ? Yup.string().required('This question is required.')
          : Yup.string(),
      })
      .test('is-telehealth-test', 'Please fill this out', function (values) {
        if (!isMSCEnabled) {
          return true;
        }
        const statesWithLocation = new Set(
          (values?.practice as Practice[]).map((location) => location.state)
        );
        const statesMissingPracticeLocation = providerStates.filter(
          (state: UnitedStates) =>
            !statesWithLocation.has(unitedStatesAbbreviations[state])
        );
        const telehealthStates = values?.telehealthStates ?? {};
        statesMissingPracticeLocation.forEach((state) => {
          if (!telehealthStates[state]) {
            throw new Yup.ValidationError(
              ['Please confirm or choose a practice location'],
              false,
              `telehealthStates[${state}]`
            );
          }
        });
        return true;
      })
      .test(
        'primary-location-selected',
        'Please selecte a primary location',
        function (values) {
          if (!isMSCEnabled) {
            return true;
          }
          const practiceLocations: Practice[] = values?.practice as Practice[];
          const statesWithMultipleLocations = (
            Object.entries(
              practiceLocations.reduce(
                (counterMap, location) => {
                  if (counterMap[location.state]) {
                    counterMap[location.state].push(location);
                  } else {
                    counterMap[location.state] = [location];
                  }

                  return counterMap;
                },
                {} as { [key: string]: Practice[] }
              )
            ) as Array<[string, Practice[]]>
          )
            .filter(([_, practices]) => practices.length > 1)
            .filter(([state, _]) =>
              providerStates.includes(
                abbreviationToStateEnum[state] as UnitedStates
              )
            ); // There can be previous existing entries belonging to state that are no longer supported
          statesWithMultipleLocations.forEach(([state, practices]) => {
            if (
              !practices.find(
                (practice) => practice.isPrimaryPracticeForState === true
              )
            ) {
              throw new Yup.ValidationError(
                ['Please choose a primary address'],
                false,
                `primarySelection[${abbreviationToStateEnum[state]}]`
              );
            }
          });
          return true;
        }
      );

    /**
     * The following logic is to determine our initial form state with the following scenarios:
     *
     * MSC Flag Enabled
     * 1. existing provider that starts a new intake form after they join msc
     * 2. existing provider that already started a new intake form and they join msc
     * 3. new provider that is currently working on the intake form
     *
     * MSF Flag disabled
     * 1. existing provider recredentialling that has started the intake form
     * 2. existing proivder that is about to start their intake form
     * 3. new provider that is currently working on the intake form
     *
     */
    let caqhPracticeLocations;
    let practice;

    if (isMSCEnabled) {
      const currentPracticeLocations =
        providerQuestionnaire.rawData?.practice ?? [];

      // Either they started the intake form after this is released or refreshed caqh, or
      // if they were already through it, grab the caqhPracticeLocations from
      // their current practice property
      caqhPracticeLocations =
        providerQuestionnaire.rawData?.caqhPracticeLocations ??
        currentPracticeLocations.filter((location) =>
          isPracticeLocationCaqhPracticeType(location)
        );

      practice =
        currentPracticeLocations.length > 0
          ? currentPracticeLocations
          : convertPracticeLocationArrayToPracticeType(
              getProviderAddedLocationArray(provider) ?? []
            );
    } else {
      practice =
        providerQuestionnaire.rawData?.practice ??
        providerQuestionnaire.rawData?.caqhPracticeLocations ??
        [];
      caqhPracticeLocations = practice;
    }

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData,
        {
          practice,
          caqhPracticeLocations,
          providerSelectedPracticeStates: getSupportedStates(provider),
        }
      ),
    } as FormMeta;
  },
};

export default stepConfig;
