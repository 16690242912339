import { useContext } from 'react';
import {
  ProgressNoteContext,
  ProgressNoteState,
} from '~/legacy/views/AppointmentConfirmation/stores/ProgressNotesContext';

import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';

export const MedicareMedicaidBanner = () => {
  const { progressNoteState } = useContext(ProgressNoteContext);

  return progressNoteState === ProgressNoteState.EDITING ? (
    <GuidanceCard variant="compliance">
      <BodyText>
        To meet compliance standards for Medicare and Medicaid plans, you're
        required to complete this documentation on Headway.
      </BodyText>
      <LinkButton
        variant="link"
        href="https://help.headway.co/hc/en-us/articles/23697087897108-Working-with-Original-Medicare-and-Medicare-Advantage-on-Headway#h_01HPT125JP8ZWMEHMH2EMZZ0R4"
        target="_blank"
        rel="noreferrer"
      >
        Learn more about documentation requirements
      </LinkButton>
    </GuidanceCard>
  ) : null;
};
