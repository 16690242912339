import { useMemo } from 'react';
import * as Yup from 'yup';

export const useAttachmentsValidationSchema = () => {
  const schema = useMemo(
    () =>
      Yup.object({
        attachments: Yup.array()
          .min(
            1,
            'Upload an attachment to continue. Accepted attachment file types are: pdf, doc, docx, pages.'
          )
          .required('Attachments are required'),
      }).required(),
    []
  );

  return schema;
};
