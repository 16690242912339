import { Formik, FormikProps } from 'formik';
import { useProvider } from 'hooks';
import React, { MutableRefObject, useContext, useState } from 'react';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { BodyText } from '@headway/helix/BodyText';
import { FormControl } from '@headway/helix/FormControl';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile } from '@headway/shared/utils/upload';
import { notifyError } from '@headway/ui/utils/notify';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import { Attachment, AttachmentsList } from 'views/Patients/AttachmentsList';

import {
  ProgressNoteContext,
  ProgressNoteState,
} from '../../stores/ProgressNotesContext';

enum AddendumType {
  TEXT = 'TEXT',
  UPLOAD = 'UPLOAD',
}

export interface AddendumFormValues {
  addendumHtml: string | undefined;
  attachments: Attachment<string>[];
  addendumType: AddendumType;
}

const onDownloadAttachment = async (attachment: Attachment<string>) => {
  if (!attachment.link || !attachment.name) {
    return;
  }

  try {
    await downloadFile({ link: attachment.link, name: attachment.name });
  } catch (err) {
    notifyError('There was an issue downloading your attachment');
    logException(err);
  }
};

export const AddendumEdit = ({
  addendumFormRef,
  progressNoteType,
  addendums,
  provider,
}: {
  addendumFormRef: React.MutableRefObject<
    FormikProps<AddendumFormValues> | undefined
  >;
  progressNoteType: ProgressNoteType;
  addendums: ProviderAppointmentAddendumRead[];
  provider: ProviderRead;
}) => {
  const { user } = useAuthStore();
  const isImpersonatingProvider = isGroupAdminImpersonatingProvider(
    provider,
    user
  );

  const [attachmentsList, setAttachmentsList] = useState<Attachment<string>[]>(
    []
  );

  /**
   * We want to restrict file type to only PDF with certain restrictions
   */
  const { allowedUploadTypes } = useContext(ProgressNoteContext);
  const allowedFileTypes =
    allowedUploadTypes ??
    'application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png';

  const addAttachments = async (files: UploadedFile[]) => {
    const fileAttachment = files.map((file, idx) => ({
      id: file.name,
      name: file.name,
      link: file.link,
      s3ObjectKey: file.s3ObjectKey,
    }));

    setAttachmentsList([...attachmentsList, ...fileAttachment]);
  };

  const onDeleteAttachment = (id: string) => {
    const filteredAttachmentList = attachmentsList.filter(
      (attachment) => attachment.name !== id
    );
    setAttachmentsList(filteredAttachmentList);
  };

  return (
    <Formik<AddendumFormValues>
      innerRef={
        addendumFormRef as MutableRefObject<FormikProps<AddendumFormValues>>
      }
      enableReinitialize
      initialValues={{
        addendumHtml: undefined,
        attachments: attachmentsList,
        addendumType:
          progressNoteType === ProgressNoteType.TEMPLATE &&
          attachmentsList.length === 0
            ? AddendumType.TEXT
            : AddendumType.UPLOAD,
      }}
      validate={(values) => {
        switch (values.addendumType) {
          case AddendumType.TEXT:
            if (!values.addendumHtml) {
              return { addendumHtml: 'Please add your addendum text here' };
            }
            break;

          case AddendumType.UPLOAD:
            if (values.attachments.length === 0) {
              return { attachments: 'Please add an attachment' };
            }
            break;
        }

        return {};
      }}
      onSubmit={() => {}}
    >
      {({ values, errors }) => {
        return (
          <>
            {
              {
                [ProgressNoteType.UPLOAD]: (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing.x4,
                    }}
                  >
                    <SectionHeader>
                      Addendum {addendums.length + 1}
                    </SectionHeader>
                    <BodyText>
                      Specify any additions or changes. You can write your
                      addendum using free text or upload an existing addendum.
                    </BodyText>
                  </div>
                ),
                [ProgressNoteType.TEMPLATE]: (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: theme.spacing.x4,
                    }}
                  >
                    <SectionHeader>
                      Addendum {addendums.length + 1}
                    </SectionHeader>
                    {!isImpersonatingProvider && (
                      <SubBodyText>
                        Specify any additions or changes, which will appear at
                        the bottom of your original note.
                      </SubBodyText>
                    )}
                  </div>
                ),
                [ProgressNoteType.NONE]: null,
              }[progressNoteType]
            }
            {progressNoteType === ProgressNoteType.UPLOAD ||
            progressNoteType === ProgressNoteType.TEMPLATE ? (
              <div
                css={{
                  marginBottom: theme.spacing.x4,
                  marginTop: theme.spacing.x2,
                }}
              >
                <FormControl
                  name="addendumType"
                  component={BigRadioGroup}
                  onChange={(value: string) => {}}
                  aria-label="Fill out a template or upload an existing note"
                  disabled={
                    values.attachments.length > 0 ||
                    (values.addendumHtml !== undefined &&
                      values.addendumHtml !== '')
                  }
                >
                  <div css={{ flex: 1 }}>
                    <BigRadio key="text" value={AddendumType.TEXT}>
                      Free Text
                    </BigRadio>
                  </div>
                  <div css={{ flex: 1 }}>
                    <BigRadio key="upload" value={AddendumType.UPLOAD}>
                      Upload Existing Addendum
                    </BigRadio>
                  </div>
                </FormControl>
              </div>
            ) : null}
            {
              {
                [AddendumType.TEXT]: (
                  <>
                    {progressNoteType === ProgressNoteType.UPLOAD ? (
                      <div css={{ marginBottom: theme.spacing.x4 }}>
                        {!isImpersonatingProvider ? (
                          <BodyText>
                            Reminder: To be compliant, documentation must be
                            updated and consistent across all locations where
                            you’ve signed it.
                          </BodyText>
                        ) : (
                          <BodyText>
                            Only the provider present at the session can write
                            an addendum
                          </BodyText>
                        )}
                      </div>
                    ) : isImpersonatingProvider ? (
                      <div css={{ marginBottom: theme.spacing.x4 }}>
                        <SubBodyText>
                          Only the provider present at the session can write an
                          addendum
                        </SubBodyText>
                      </div>
                    ) : null}
                    <FormControl
                      name="addendumHtml"
                      component={RichTextArea}
                      placeholder="Enter addendum notes..."
                      disabled={isImpersonatingProvider}
                    />
                  </>
                ),
                [AddendumType.UPLOAD]: (
                  <>
                    {progressNoteType === ProgressNoteType.UPLOAD ||
                    progressNoteType === ProgressNoteType.TEMPLATE ? (
                      <div css={{ marginBottom: theme.spacing.x4 }}>
                        <BodyText>
                          Reminder: To be compliant, an addendum should add to,
                          change or clarify details, not remove them.
                        </BodyText>
                      </div>
                    ) : null}
                    <AttachmentsList
                      provider={provider}
                      attachments={values.attachments}
                      onAddAttachments={addAttachments}
                      onDownloadAttachment={onDownloadAttachment}
                      onDeleteAttachment={onDeleteAttachment}
                      accept={allowedFileTypes}
                      variant={'helix'}
                    />
                  </>
                ),
              }[values.addendumType]
            }
            {errors['attachments'] ? (
              <div
                css={{
                  marginTop: '20px',
                  color: theme.color.primary.red,
                  ...theme.typography.caption.medium,
                }}
              >
                {errors['attachments'] as string}
              </div>
            ) : null}
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: theme.spacing.x4,
                paddingBottom: theme.spacing.x4,
              }}
            >
              <SectionHeader>Declaration</SectionHeader>
              <BodyText>
                By clicking "Sign and submit addendum" I attest that the content
                of this addendum is true and accurate.
              </BodyText>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export const AddendumsForm = () => {
  const {
    progressNoteState,
    addendums,
    addendumFormRef,
    addendumContainerRef,
    progressNoteType,
  } = useContext(ProgressNoteContext);
  const provider = useProvider();

  return (
    <div
      css={{ marginBottom: theme.spacing.x6 }}
      ref={addendumContainerRef as MutableRefObject<HTMLDivElement>}
    >
      {addendums.map((addendum, idx) => {
        const type = addendum.addendumHtml
          ? AddendumType.TEXT
          : AddendumType.UPLOAD;
        return (
          <div css={{ marginBottom: theme.spacing.x4 }} key={addendum.id}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: theme.spacing.x2,
              }}
            >
              <SectionHeader color="disabledGray">
                Addendum {(idx + 1).toString()}
              </SectionHeader>
            </div>
            {
              {
                [AddendumType.TEXT]: (
                  <RichTextArea
                    data-testid={`addendumFreetext${idx + 1}`}
                    disabled={true}
                    name={`addendumFreetext${idx + 1}`}
                    value={addendum.addendumHtml}
                  />
                ),
                [AddendumType.UPLOAD]: (
                  <AttachmentsList
                    provider={provider}
                    attachments={addendum.attachments.map((a) => ({
                      name: a.name!,
                      id: a.name!,
                      link: a.link!,
                      s3ObjectKey: a.s3ObjectKey!,
                    }))}
                    disabled
                    hideDropzone
                    isDeleteDisabled
                    onDownloadAttachment={onDownloadAttachment}
                    accept="application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png"
                    variant={'helix'}
                  />
                ),
              }[type]
            }
          </div>
        );
      })}

      {progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT && (
        <AddendumEdit
          addendumFormRef={addendumFormRef}
          progressNoteType={progressNoteType}
          provider={provider}
          addendums={addendums}
        />
      )}
    </div>
  );
};
