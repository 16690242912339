import { useCallback, useContext, useEffect } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Link } from '@headway/helix/Link';
import { ModalContent } from '@headway/helix/Modal';
import { trackPageView } from '@headway/shared/utils/analytics';

import PlantImage from 'assets/img/plant-scenary.png';

import {
  FillOutAssessmentModalContext,
  ManagementAssessmentStep,
} from './FillOutAssessmentForClientModalContext';

export const AssessmentCompletion = ({
  clientName,
  providerPatient,
}: {
  clientName: string;
  providerPatient: ProviderPatientRead;
}) => {
  const { setCurrentStep, selectedAssessments } = useContext(
    FillOutAssessmentModalContext
  );
  const goToNextStep = useCallback(() => {
    setCurrentStep(ManagementAssessmentStep.SCHEDULE_RECURRING);
  }, [setCurrentStep]);

  useEffect(() => {
    trackPageView({
      name: 'Fill Out on Behalf of Client Assessment Completed Page Viewed',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
        providerPatientId: providerPatient.id,
        assessmentTypeList: selectedAssessments,
      },
    });
  }, []);

  return (
    <>
      <ModalContent>
        <div className="m-12 flex flex-col items-center">
          <div className="flex w-[690px] flex-col items-center gap-3">
            <img src={PlantImage} alt="journal" />
            <ContentText variant="page-title">Assessment complete</ContentText>
            <ContentText variant="body">
              If you are sharing screen with <strong>{clientName}</strong>, you
              may stop sharing now.
            </ContentText>
            <div>
              <Button variant="primary" onPress={goToNextStep}>
                Done
              </Button>
            </div>
            <div className="mt-4 rounded bg-system-backgroundGray p-4 text-system-gray">
              If your client feels at risk of acting on thoughts of harm to self
              or others they should seek immediate crisis intervention or
              suicide prevention services. For immediate assistance, they can
              dial 911 or go to their local emergency room. They can also reach
              out to the following resources:
              <ul className="my-4 list-disc pl-8">
                <li className="mb-2">
                  1-800-SUICIDE - 24-hour suicide prevention line that can be
                  called from anywhere in the U.S.{' '}
                  <Link
                    href="https://988lifeline.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="text-system-gray">
                      https://988lifeline.org/
                    </span>
                  </Link>
                </li>
                <li>
                  If they are uncomfortable making a phone call, they can Text
                  HOME to 741741 to connect with a Crisis Counselor
                </li>
              </ul>
              Please note: If your client is at imminent risk of harm to self or
              others they should contact the resources provided.
            </div>
          </div>
        </div>
      </ModalContent>
    </>
  );
};
