import { LicenseTypes } from '@headway/api/models/LicenseTypes';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserUploadTypes } from '@headway/api/models/UserUploadTypes';
import { logException } from '@headway/shared/utils/sentry';
import { uploadFilesToS3 } from '@headway/shared/utils/upload';
import { notifyError } from '@headway/ui/utils/notify';

export const YesNo = {
  YES: 'YES',
  NO: 'NO',
};

export const IN_PROCESS = 'IN_PROCESS';

export const getDisplayYesNo = (yesNoVal = YesNo.NO) => {
  return yesNoVal === YesNo.YES ? 'Yes' : 'No';
};

export const statesRequiringCDS = new Set([
  UnitedStates.ALABAMA,
  UnitedStates.CONNECTICUT,
  UnitedStates.DELAWARE,
  UnitedStates.DISTRICT_OF_COLUMBIA,
  UnitedStates.HAWAII,
  UnitedStates.IDAHO,
  UnitedStates.ILLINOIS,
  UnitedStates.INDIANA,
  UnitedStates.IOWA,
  UnitedStates.LOUISIANA,
  UnitedStates.MARYLAND,
  UnitedStates.MASSACHUSETTS,
  UnitedStates.MICHIGAN,
  UnitedStates.MISSOURI,
  UnitedStates.NEVADA,
  UnitedStates.NEW_JERSEY,
  UnitedStates.NEW_MEXICO,
  UnitedStates.OKLAHOMA,
  UnitedStates.RHODE_ISLAND,
  UnitedStates.SOUTH_CAROLINA,
  UnitedStates.SOUTH_DAKOTA,
  UnitedStates.UTAH,
  UnitedStates.WYOMING,
]);

export const cdsLicenseExceptionsByState: { [key: string]: LicenseTypes[] } = {
  [UnitedStates.MICHIGAN]: [LicenseTypes.NP],
};

export const needsAlternateCoverage = (
  rawData: ProviderQuestionnaireRawData,
  providerStates: UnitedStates[]
) =>
  rawData.prescribesControlledSubstances === YesNo.YES &&
  getStatesMissingDeaOrCds(providerStates, rawData).length > 0;

export const filterStatesByCdsRequirement = (
  selectedStates: UnitedStates[],
  licenseType: string | undefined
): UnitedStates[] => {
  let filteredStates = Array.from(statesRequiringCDS).filter(
    (state: UnitedStates) => selectedStates.includes(state)
  );
  if (isLicenseType(licenseType)) {
    filteredStates = filteredStates.filter((state: UnitedStates): boolean => {
      if (state in cdsLicenseExceptionsByState) {
        return !cdsLicenseExceptionsByState[state].includes(licenseType);
      }
      return true;
    });
  }
  return filteredStates;
};

export const getStatesMissingDeaOrCds = (
  providerStates: UnitedStates[],
  rawData: ProviderQuestionnaireRawData
) => {
  const { deaCertificates, cdsCertificates } = rawData;
  const coveredDeaStates = (deaCertificates ?? []).map((dea) => dea.state);
  const coveredCdsStates = (cdsCertificates ?? []).map((cds) => cds.state);

  const missingDeaStates = providerStates.filter(
    (state) => !coveredDeaStates.includes(state)
  );

  const missingCdsStates = providerStates
    .filter((providerState) => statesRequiringCDS.has(providerState))
    .filter((state) => !coveredCdsStates.includes(state));

  const allMissingStates = missingDeaStates.concat(missingCdsStates);
  return [...new Set(allMissingStates)];
};

export const formatPhoneNumberToString = (phoneNumber: string | undefined) => {
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber
    ?.replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const onDropFiles = async (providerId: number, files: File[]) => {
  try {
    return await uploadFilesToS3(
      files,
      UserUploadTypes.PROVIDER_QUESTIONNAIRE_UPLOADS,
      providerId
    );
  } catch (error) {
    notifyError('Failed to process file');
    logException(error);
  }
};

export const isLicenseType = (
  licenseType: string | undefined
): licenseType is LicenseTypes => {
  return licenseType !== undefined && licenseType in LicenseTypes;
};
