import { PrivacyTip } from '@mui/icons-material';
import Alert from '@mui/material/Alert';

import { UserRead } from '@headway/api/models/UserRead';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Modal } from '@headway/ui/Modal';
import {
  PatientAddressForm,
  PatientAddressFormImplProps,
} from '@headway/ui/PatientAddressForm';
import { theme } from '@headway/ui/theme';

export interface AddressModalProps {
  open: boolean;
  patientUser: UserRead;
  onClose: () => void;
  onSuccess: PatientAddressFormImplProps['onSuccess'];
  isActiveAddress?: boolean;
}

export const AddressModal = ({
  open,
  patientUser,
  onClose,
  onSuccess,
  isActiveAddress = true,
}: AddressModalProps) => {
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  const patientFirstName = formatPatientName(patientUser, {
    firstNameOnly: true,
  });

  const modalTitle = isMSCEnabled
    ? `Add location for ${patientFirstName}`
    : 'New Address';
  return (
    <Modal title={modalTitle} open={open} onClose={onClose}>
      <Alert
        color="info"
        icon={<PrivacyTip />}
        css={{ marginBottom: theme.space.base }}
      >
        {isMSCEnabled
          ? `We need to know where ${patientFirstName} is located to submit a claim. You must be licensed and credentialed on Headway in the state where your client is located during each session.`
          : `We ask for a home address because the insurance company requires residence verification for eligibility and claims. We will not use this information otherwise, nor will we share it with anyone besides the insurance and provider.`}
      </Alert>
      <PatientAddressForm
        user={patientUser}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_ID as string}
        onSuccess={onSuccess}
        isActiveAddress={isActiveAddress}
      />
    </Modal>
  );
};
