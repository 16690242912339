import {
  CalendarDate,
  DateValue,
  getLocalTimeZone,
  today,
} from '@internationalized/date';
import { useFormikContext } from 'formik';
import sortBy from 'lodash/sortBy';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { Button } from '@headway/helix/Button';
import { ComboBox, Item as ComboBoxItem } from '@headway/helix/ComboBox';
import { DatePickerField } from '@headway/helix/DatePickerField';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { Select, Item as SelectItem } from '@headway/helix/Select';
import {
  getProviderDisplayFirstAndLast,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';
import { Loader } from '@headway/ui/Loader';

import { useGroupPracticeProviders } from 'hooks/useGroupPracticeProviders';
import { usePatientsByProviderId } from 'hooks/usePatientsByProviderId';
import { useAuthStore } from 'stores/AuthStore';

export interface BillNewSessionsFormValues {
  sessionDate: CalendarDate;
  providerId: number | undefined;
  patientIds: number[];
}

interface BillNewSessionsSidesheetFormProps {
  onDismiss: () => void;
  isProviderPreselected: boolean;
}

export const BILL_NEW_SESSIONS_FORM_ID = 'bill-new-sessions-form';

export const BillNewSessionsSidesheetForm = ({
  onDismiss,
  isProviderPreselected,
}: BillNewSessionsSidesheetFormProps) => {
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<BillNewSessionsFormValues>();
  const selectedProviderId = values.providerId;
  const { data: patients } = usePatientsByProviderId({
    providerId: selectedProviderId,
  });

  patients?.sort(
    (a, b) =>
      a.lastName.localeCompare(b.lastName) ||
      a.firstName.localeCompare(b.firstName)
  );

  const AuthStore = useAuthStore();
  const { data: providers, isLoading: isProvidersLoading } =
    useGroupPracticeProviders(
      {
        groupPracticeId: AuthStore.user?.group_practice?.id,
        query: { is_active: true },
      },
      {
        select: (providers) =>
          sortBy(providers, (provider) =>
            getProviderDisplayFirstAndLast(provider)
          ),
        refetchOnWindowFocus: false,
      }
    );

  const filteredProviders = useMemo(
    () => (providers || []).filter((provider) => !provider.isArchived),
    [providers]
  );

  const numSessionStr =
    values.patientIds.length > 0 ? ` (${values.patientIds.length})` : '';

  return (
    <>
      <ModalContent>
        <Form id={BILL_NEW_SESSIONS_FORM_ID}>
          <div className="flex flex-col gap-5 pb-5">
            <div>Add sessions to bill that were not scheduled on Headway.</div>
            <FormControl
              component={DatePickerField}
              label="Date"
              name="sessionDate"
              isDateUnavailable={(date: DateValue) =>
                date.compare(today(getLocalTimeZone())) > 0
              }
            />
            {isProvidersLoading ? (
              <Loader />
            ) : (
              <FormControl
                component={Select}
                name="providerId"
                label="Provider"
                placeholder="Select a provider"
                selectionMode="single"
                menuWidth="stretch"
                onSelectionChange={() => {
                  setFieldValue('patientIds', []);
                }}
              >
                {filteredProviders.map((provider) => (
                  <SelectItem key={provider.id}>
                    {getProviderDisplayFirstAndLastWithPrenomial(provider)}
                  </SelectItem>
                ))}
              </FormControl>
            )}
            <FormControl
              component={ComboBox}
              name="patientIds"
              label="Clients"
              selectionMode="multiple"
              placeholder="Select clients to bill"
              disabled={!selectedProviderId}
            >
              {(patients || []).map((patient) => (
                <ComboBoxItem key={patient.id}>{patient.fullName}</ComboBoxItem>
              ))}
            </FormControl>
          </div>
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="secondary"
          size="large"
          disabled={isSubmitting}
          onPress={onDismiss}
        >
          Cancel
        </Button>
        <Button
          form={BILL_NEW_SESSIONS_FORM_ID}
          type="submit"
          variant="primary"
          size="large"
          disabled={isSubmitting}
        >
          {isProviderPreselected
            ? `Add sessions${numSessionStr}`
            : `Add and confirm sessions${numSessionStr}`}
        </Button>
      </ModalFooter>
    </>
  );
};

export const BillNewSessionValidationSchema = Yup.object().shape({
  sessionDate: Yup.string().required('Date is required.'),
  providerId: Yup.number().required('Provider is required.'),
  patientIds: Yup.array().of(Yup.number()).min(1, 'Clients are required.'),
});
