import { useContext } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { HeadwayLogo } from '@headway/icons/dist/HeadwayLogo';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { trackEvent } from '@headway/shared/utils/analytics';

import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

interface ComplianceGuideModalV2Props {
  open: boolean;
  onClose: () => void;
}

export const ComplianceGuideModalV2 = ({
  open,
  onClose,
}: ComplianceGuideModalV2Props) => {
  const { provider } = useContext(AppointmentConfirmationContextV2);
  const [, setProviderClosedComplianceModal] = useLocalStorage(
    'providerClosedComplianceModal'
  );

  const modalTitle = 'Templates designed to make compliance easy & stress-free';

  return (
    <Modal
      title={
        <>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <HeadwayLogo
              aria-label="Headway"
              css={{ width: 160, marginBottom: theme.spacing.x6 }}
              aria-hidden="true"
              focusable="false"
            />
          </div>
          <div css={{ marginTop: theme.spacing.x4 }}>{modalTitle} </div>
        </>
      }
      isOpen={open}
      onDismiss={onClose}
      isDismissable={false}
    >
      <ModalContent>
        <>
          {`Hi ${provider?.displayFirstName},`}
          <div css={{ marginTop: theme.spacing.x6 }}>
            <BodyText>
              I know first-hand how much work it takes to write compliant notes
              on top of seeing clients and running a practice. That's why our
              clinical team designed templates that simplify the note-taking
              process, while including information required to meet insurance
              compliance standards.
              <div css={{ marginTop: theme.spacing.x6 }}>
                Progress notes created with Headway templates{' '}
                <strong>
                  meet all insurance requirements 3 times more often
                </strong>{' '}
                than notes created using the free text option.
              </div>
              <div css={{ marginTop: theme.spacing.x6 }}>
                If you have any questions on using these templates, please feel
                free to{' '}
                <Link
                  href="https://headway-university.northpass.com/app/training_events"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    if (provider) {
                      trackEvent({
                        name: 'Schedule a Meeting Link Clicked',
                        properties: {
                          providerId: provider.id,
                        },
                      });
                    }
                  }}
                >
                  schedule a meeting
                </Link>{' '}
                with a member of our team.
              </div>
              <div css={{ marginTop: theme.spacing.x6 }}>
                The clinical team at Headway
              </div>
            </BodyText>
          </div>
        </>
      </ModalContent>
      <ModalFooter>
        <LinkButton
          variant="secondary"
          target="_blank"
          rel="noreferrer"
          onPress={() => {
            setProviderClosedComplianceModal('true');
          }}
          href="https://headway.co/resources/behavioral-health-compliance"
        >
          See Headway's compliance guide
        </LinkButton>
        <Button
          onPress={() => {
            setProviderClosedComplianceModal('true');
            onClose();
          }}
        >
          Create progress note
        </Button>
      </ModalFooter>
    </Modal>
  );
};
