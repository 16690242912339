import {
  Component as V1Component,
  ComponentTypes as V1ComponentTypes,
  Section as V1Section,
} from '../v1/types';

export interface DoAndDontData {
  do: string[];
  dont: string[];
}

export interface InfoListData {
  title: string;
  items: string[];
}

export enum InfoComponentTypes {
  doAndDont = 'doAndDont',
  infoList = 'infoList',
}

export type InfoComponentData = DoAndDontData | InfoListData;

export interface InfoComponent {
  type: InfoComponentTypes;
  data: InfoComponentData;
}

export type ComponentTypes = V1ComponentTypes;

export interface Component<T> extends V1Component<T> {
  subTitle?: string;
  htmlContent?: string;
}

export interface Section<T> extends V1Section<T> {
  subHeader?: string;
  infoComponent?: InfoComponent;
}

export type TemplateV2<T> =
  | Array<Section<T>>
  | Array<Component<T>>
  | Array<Component<T> | Section<T>>;
