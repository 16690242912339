import { useMedicareOrMedicaid } from '~/legacy/hooks/useMedicareOrMedicaid';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';

import { useActiveTreatmentPlan } from '../../hooks/utils/useActiveTreatmentPlan';
import { Rule } from '../Rule';
import { DefaultTemplateFilters, TemplateFilter } from '../TemplateFilter';
import { MedicareAndMedicaidProgressNoteBanner } from './MedicareAndMedicaidProgressNoteBanner';

export class MedicareAndMedicaid extends Rule<
  [boolean, ProviderTreatmentPlanRead | undefined]
> {
  enabled = [true];

  addDataHooks() {
    return [
      () => useMedicareOrMedicaid(this.patient.id),
      () => useActiveTreatmentPlan(),
    ];
  }

  isRuleActive(): boolean {
    const data = this.getHookData();

    if (!data) {
      return false;
    }

    return data[0];
  }

  get disabledProgressNoteTypes(): ProgressNoteType[] {
    return [ProgressNoteType.NONE, ProgressNoteType.UPLOAD];
  }

  get banners() {
    return {
      progressNote: {
        header: <MedicareAndMedicaidProgressNoteBanner />,
      },
    };
  }
  templateFilter(): TemplateFilter {
    return DefaultTemplateFilters.RemoveFreeText;
  }
  isDocumentationRequired(): boolean {
    return true;
  }
  isTreatmentPlanRequired(): boolean {
    const data = this.getHookData();

    if (!data) {
      return false;
    }

    const isIntakeSession =
      this.formValues.sessionDetails.cptCodes?.find(
        (cptCode) => cptCode.value === '90791'
      ) !== undefined;
    const hasActiveTreatmentPlan = data[1] !== undefined;

    return !(isIntakeSession || hasActiveTreatmentPlan);
  }
}
