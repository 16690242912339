import { useCallback, useContext } from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useProviderPatient } from '~/legacy/hooks';
import { useProviderEventCache } from '~/legacy/hooks/useProviderEvent';
import { useProviderTreatmentPlans } from '~/legacy/hooks/useProviderTreatmentPlans';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';

import { isPast } from '../../Calendar/events/util/events';
import { ProgressNoteFormV2Values } from '../components/forms/ProgressNote/ProgressNoteFormV2';
import { CodingError } from '../components/forms/SessionDetails/validation/miscoding';
import { useRuleSet } from '../ruleset/useRuleSet';
import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../stores/AppointmentConfirmationContextV2';
import { useConfirmSession } from './formActions/useConfirmSession';
import { useSaveProgressNoteAsDraft } from './formActions/useSaveProgressNoteAsDraft';
import { useSignAddendum } from './formActions/useSignAddendum';
import { useSignProgressNote } from './formActions/useSignProgressNote';
import { useUpdateProgressNoteToEmpty } from './formActions/useUpdateProgressNoteToEmpty';
import { convertMiscodingErrors, onValidationFail } from './formActions/utils';

export const useFormActions = () => {
  const {
    patient,
    provider,
    event,
    progressNoteState,
    onCloseAppointmentConfirmationModal,
    switchToNoteSigned,
    eventVirtualId,
    setIsAutoSaving,
    setIsSubmitAddendumWithoutChangesModalOpen,
    setIsRequiredNoteSubmissionModalOpen,
    openTreatmentPlanAdoptionModal,
  } = useContext(AppointmentConfirmationContextV2);
  const { handleSubmit, control } = useFormContext();
  const progressNoteValues: ProgressNoteFormV2Values = useWatch({
    name: 'progressNote',
  });

  const providerEventCache = useProviderEventCache();
  const { signProgressNote } = useSignProgressNote();
  const { saveProgressNoteAsDraft } = useSaveProgressNoteAsDraft();
  const { signAddendum } = useSignAddendum();
  const { updateProgressNoteToEmpty } = useUpdateProgressNoteToEmpty();
  const { confirmSession } = useConfirmSession();
  const { data: providerPatient } = useProviderPatient({
    providerId: event?.providerAppointment?.providerId,
    patientId: event?.patientUserId,
  });
  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });
  const rule = useRuleSet({ patient, provider, event });

  const selectedCptCodes = useWatch({
    control,
    name: 'sessionDetails.cptCodes',
  });

  // Reusable utility to chain or call actions with optional validation
  const createAction = useCallback(
    (
      action: (values?: FieldValues) => Promise<void>,
      options: { validate?: boolean; afterAction?: () => void } = {}
    ) => {
      const { validate = true, afterAction } = options;

      return async () => {
        try {
          setIsAutoSaving(true);
          let isValidationSuccessful = true;

          if (validate) {
            try {
              // Use handleSubmit for the validationSchema handling
              await handleSubmit(
                async (values: FieldValues) => {
                  await action(values);
                  setIsAutoSaving(false);
                },
                (errors) => {
                  isValidationSuccessful = false;
                  onValidationFail(errors);
                }
              )();
            } catch (error) {
              isValidationSuccessful = false;
              throw error;
            }
          } else {
            await action();
          }

          // Proceed with afterAction only if validation succeeded
          if (isValidationSuccessful && afterAction) {
            afterAction();
          }
        } catch (error) {
          console.error('An error occurred:', error);
        } finally {
          setIsAutoSaving(false);
        }
      };
    },
    [handleSubmit, setIsAutoSaving]
  );

  const handleConfirmSession = useCallback(
    async (values?: FieldValues) => {
      const { updatedEvent, errors } = await confirmSession({
        patient,
        provider,
        event,
        eventVirtualId,
        values: values?.sessionDetails,
      });
      if (errors.length > 0) {
        onValidationFail(convertMiscodingErrors(errors as CodingError[]));
        throw new Error('Validation errors occurred');
      }

      // update the event's cache before closing the modal (for the Calendar mostly)
      providerEventCache.set(
        { eventIdOrVirtualId: updatedEvent?.virtualId },
        updatedEvent
      );

      // TODO: Check if that is still relevant to track at this moment
      // if (isDetailsConfirmed(event)) {
      //   trackButtonClicked(`Modify Session Button Clicked`);
      // } else {
      //   trackButtonClicked(`Confirm Session Button Clicked`);
      // }
    },
    [
      patient,
      provider,
      event,
      eventVirtualId,
      confirmSession,
      providerEventCache,
    ]
  );

  const handleOpenTreatmentPlanPage = useCallback(() => {
    window.open(
      `/clients/${patient?.id}/clinical?treatmentPlan=true`,
      '_blank'
    );
  }, [patient?.id]);

  const onOpenTreatmentPlanAdoptionModal = async () => {
    if (!openTreatmentPlanAdoptionModal || (treatmentPlans?.length || 0) > 0) {
      return;
    }

    if (selectedCptCodes?.includes('90791')) {
      openTreatmentPlanAdoptionModal(true);
      return;
    }

    const { totalCount: totalConfirmedSessions } =
      await ProviderEventApi.getEvents({
        provider_id: provider?.id,
        patient_user_id: patient?.id,
        event_types: [ProviderEventType.APPOINTMENT],
        appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
        expand_estimated_prices: false,
        use_minimal_appointment_fields: true,
        source: 'appointment_confirmation_confirmed_sessions',
        limit: 1,
      });
    const isFirstConfirmedSession = totalConfirmedSessions === 1;

    if (isFirstConfirmedSession) {
      openTreatmentPlanAdoptionModal(false);
    }
  };

  const getActionsByTypeAndState = useCallback(() => {
    if (event && isPast(event)) {
      switch (progressNoteValues.progressNoteType) {
        case ProgressNoteType.TEMPLATE:
          switch (progressNoteState) {
            case ProgressNoteState.EDITING:
              return {
                primary: createAction(signProgressNote),
                secondary: createAction(saveProgressNoteAsDraft, {
                  validate: false,
                }),
              };
            case ProgressNoteState.ADDENDUM_EDITING:
              return {
                primary: createAction(signAddendum),
                secondary: rule?.isInRemediationFlow()
                  ? () => setIsSubmitAddendumWithoutChangesModalOpen(true)
                  : switchToNoteSigned,
              };

            case ProgressNoteState.SAVED_FOR_LATER:
              return {
                primary: rule?.isDocumentationRequired()
                  ? () => setIsRequiredNoteSubmissionModalOpen(true)
                  : createAction(handleConfirmSession, {
                      afterAction: () => {
                        onCloseAppointmentConfirmationModal();
                        onOpenTreatmentPlanAdoptionModal();
                      },
                    }),
                secondary: rule?.isTreatmentPlanRequired()
                  ? () => handleOpenTreatmentPlanPage()
                  : undefined,
              };
            case ProgressNoteState.SIGNED:
              return {
                primary: createAction(handleConfirmSession, {
                  afterAction: () => {
                    onCloseAppointmentConfirmationModal();
                    onOpenTreatmentPlanAdoptionModal();
                  },
                }),
                secondary: rule?.isTreatmentPlanRequired()
                  ? () => handleOpenTreatmentPlanPage()
                  : undefined,
              };
          }
          break;
        case ProgressNoteType.UPLOAD:
          switch (progressNoteState) {
            case ProgressNoteState.ADDENDUM_EDITING:
              return {
                primary: createAction(signAddendum),
                secondary: rule?.isInRemediationFlow()
                  ? () => setIsSubmitAddendumWithoutChangesModalOpen(true)
                  : switchToNoteSigned,
              };
            case ProgressNoteState.SIGNED:
              return {
                primary: createAction(handleConfirmSession, {
                  afterAction: async () => {
                    await updateProgressNoteToEmpty();
                    onCloseAppointmentConfirmationModal();
                  },
                }),
              };
            default:
              return {
                primary: createAction(handleConfirmSession, {
                  afterAction: async () => {
                    await updateProgressNoteToEmpty();
                    onCloseAppointmentConfirmationModal();
                    onOpenTreatmentPlanAdoptionModal();
                  },
                }),
                secondary: rule?.isTreatmentPlanRequired()
                  ? () => handleOpenTreatmentPlanPage()
                  : undefined,
              };
          }
        case ProgressNoteType.NONE:
          return {
            primary: rule?.isDocumentationRequired()
              ? () => setIsRequiredNoteSubmissionModalOpen(true)
              : createAction(handleConfirmSession, {
                  afterAction: async () => {
                    await updateProgressNoteToEmpty();
                    onCloseAppointmentConfirmationModal();
                    onOpenTreatmentPlanAdoptionModal();
                  },
                }),
            secondary: rule?.isTreatmentPlanRequired()
              ? () => handleOpenTreatmentPlanPage()
              : undefined,
          };
        default:
          // Do nothing
          break;
      }
    } else {
      // Not a past appointment => can't confirm, can only save as draft
      switch (progressNoteState) {
        case ProgressNoteState.EDITING:
          return {
            primary: createAction(saveProgressNoteAsDraft, {
              validate: false,
              afterAction: onCloseAppointmentConfirmationModal,
            }),
          };
        default:
          // Do nothing
          break;
      }
    }
  }, [
    event,
    progressNoteState,
    progressNoteValues.progressNoteType,
    signProgressNote,
    saveProgressNoteAsDraft,
    signAddendum,
    updateProgressNoteToEmpty,
    createAction,
    onCloseAppointmentConfirmationModal,
    switchToNoteSigned,
    handleConfirmSession,
    setIsSubmitAddendumWithoutChangesModalOpen,
    setIsRequiredNoteSubmissionModalOpen,
    rule,
    handleOpenTreatmentPlanPage,
  ]);

  const onPrimaryClick = useCallback(async () => {
    const actions = getActionsByTypeAndState();
    if (actions?.primary) {
      await actions.primary();
    }
  }, [getActionsByTypeAndState]);

  const onSecondaryClick = useCallback(async () => {
    const actions = getActionsByTypeAndState();
    if (actions?.secondary) {
      await actions.secondary();
    }
  }, [getActionsByTypeAndState]);

  return { onPrimaryClick, onSecondaryClick };
};
