import {
  CalendarDate,
  DateValue,
  getLocalTimeZone,
  today,
} from '@internationalized/date';
import { DatePickerProps } from '@react-types/datepicker';
import React, { useMemo } from 'react';
import { mergeProps, useDatePicker, useFocusRing } from 'react-aria';
import { useDatePickerState } from 'react-stately';

import { Button } from '@headway/helix/Button';
import {
  CalendarCellProps,
  DatePickerCalendarDialog,
} from '@headway/helix/DatePickerCalendarDialog';
import { CalendarView } from '@headway/helix/DatePickerField';
import { IconButton } from '@headway/helix/IconButton';
import { IconCalendarDots } from '@headway/helix/icons/CalendarDots';
import { IconCaretLeft } from '@headway/helix/icons/CaretLeft';
import { IconCaretRight } from '@headway/helix/icons/CaretRight';

type HelishDatePickerProps = {
  value: CalendarDate;
  onChange: (value: CalendarDate) => void;
  decoratorText?: string;
  'aria-label': string;
} & Pick<DatePickerProps<CalendarDate>, 'isDateUnavailable'> &
  Pick<CalendarCellProps, 'isDateSignificant'>;

/**
 * A hybrid SplitButton / button menu that opens a calendar datepicker.
 * Helix doesn't plan to support this pattern, so we're rolling our own.
 */
export function HelishDatePickerButton(props: HelishDatePickerProps) {
  let { focusProps: focusPropsButton } = useFocusRing({
    within: false,
    isTextInput: false,
  });

  const timeZone = getLocalTimeZone();
  const currentDay = useMemo(() => today(timeZone), [timeZone]);
  const timeFormatter = useMemo(
    () =>
      Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        timeZone: timeZone,
      }),
    [timeZone]
  );

  const options = {
    isDateUnavailable: props.isDateUnavailable,
    onChange: (value: DateValue) => {
      props.onChange?.(value as CalendarDate);
    },
    value: props.value,
    hideTimeZone: true,
    granularity: 'day',
    shouldCloseOnSelect: true,
    'aria-label': props['aria-label'],
  } as const;

  const state = useDatePickerState(options);

  let controlRef = React.useRef(null);

  const { buttonProps, dialogProps, calendarProps } = useDatePicker(
    options,
    state,
    controlRef
  );

  const nextDay = props.value.add({ days: 1 });
  const previousDay = props.value.subtract({ days: 1 });

  return (
    // CSS misdemeanors to emulate SplitButton appearance
    <div className="flex [&>button:first-of-type]:-mr-[1px] [&>button:first-of-type]:rounded-l [&>button:last-of-type]:-ml-[1px] [&>button:last-of-type]:rounded-r [&>button]:rounded-none">
      <IconButton
        aria-label="Previous day"
        disabled={
          props.isDateUnavailable ? props.isDateUnavailable(previousDay) : false
        }
        onPress={() => props.onChange(previousDay)}
      >
        <IconCaretLeft />
      </IconButton>
      <IconButton
        aria-label="Next day"
        disabled={
          props.isDateUnavailable ? props.isDateUnavailable(nextDay) : false
        }
        onPress={() => props.onChange(nextDay)}
      >
        <IconCaretRight />
      </IconButton>
      <Button
        variant="secondary"
        ref={controlRef}
        {...mergeProps(buttonProps, focusPropsButton)}
      >
        <div className="flex items-center gap-2 leading-[20px]">
          <IconCalendarDots />
          {currentDay.compare(props.value) === 0
            ? 'Today'
            : timeFormatter.format(props.value.toDate(timeZone))}
          {props.decoratorText && (
            <div className="rounded-[20px] bg-background-compliance px-2 py-[1px] text-foreground-inverted-primary">
              {props.decoratorText}
            </div>
          )}
        </div>
      </Button>
      {state.isOpen && (
        <DatePickerCalendarDialog
          state={state}
          triggerRef={controlRef}
          placement="bottom start"
          dialogProps={dialogProps}
        >
          <CalendarView
            {...calendarProps}
            isDateSignificant={props.isDateSignificant}
          />
          <div className="hlx-date-picker-field-preset">
            <Button
              variant="secondary"
              onPress={() => props.onChange(today(timeZone))}
            >
              Today
            </Button>
          </div>
        </DatePickerCalendarDialog>
      )}
    </div>
  );
}
