import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { ProviderAttestationStatus } from '@headway/api/models/ProviderAttestationStatus';
import { ProviderQuestionnaireType } from '@headway/api/models/ProviderQuestionnaireType';
import { ProviderPaymentPauseApi } from '@headway/api/resources/ProviderPaymentPauseApi';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import {
  FlagsBreakingVersionPrompt,
  withFlags,
} from '@headway/shared/FeatureFlags/react';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { usePayerQuestionnaires } from '@headway/shared/hooks/usePayerQuestionnaires';
import { trackEvent } from '@headway/shared/utils/analytics';
import { LogoLoader } from '@headway/ui';
import { ForethoughtButton } from '@headway/ui/Forethought';
import { ExtoleAccessTokenProvider } from '@headway/ui/hooks/extole';
import { FrontEndCarrierProvider } from '@headway/ui/providers/FrontEndCarrierProvider';
import { ProviderAddressProvider } from '@headway/ui/providers/ProviderAddressProvider';
import { ProviderFrontEndCarrierProvider } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';
import { ProviderTaskProvider } from '@headway/ui/providers/ProviderTaskProvider';
import { theme } from '@headway/ui/theme';

import { BankAccountBanner } from 'components/BankAccount/BankAccountBanner';
import { GroupPracticeBillingModal } from 'components/GroupPracticeBilling/UpdateGroupPracticeBillingModal';
import { Header } from 'components/Header/Header';
import { ProviderSelect } from 'components/Header/ProviderSelect';
import { IroncladAmendmentsBanner } from 'components/IroncladAmendmentsModal/components/IroncladAmendmentsBanner';
import { AMENDMENT_DATE_OF_NOTICE } from 'components/IroncladAmendmentsModal/helpers/constants';
import {
  isGPPendingInitialContractAcceptance,
  isGroupPracticeProviderBefore30Days,
} from 'components/IroncladAmendmentsModal/helpers/utils';
import { IroncladAmendmentsModal } from 'components/IroncladAmendmentsModal/IroncladAmendmentsModal';
import { IroncladAgreementStatus } from 'components/IroncladAmendmentsModal/types/IroncladAgreementStatus';
import { PausedPaymentsModal } from 'components/PausedPayments/PausedPaymentsModal';
import { PausedPaymentsReleasedModal } from 'components/PausedPayments/PausedPaymentsReleasedModal';
import ProviderDataManagementModal from 'components/ProviderDataManagement/Modal';
import { isPastRecredDeadlineAndGracePeriod } from 'components/Recredentialing/recred_utils';
import { RefreshPageAlert } from 'components/RefreshPageAlert/RefreshPageAlert';
import Sidebar from 'components/Sidebar/Sidebar';
import { StripeRequirementsBanner } from 'components/StripeRequirements/StripeRequirementsBanner';
import { W9Banner } from 'components/W9/W9Banner';
import { W9Modal } from 'components/W9/W9Modal';
import { useBillingAccountForBillingSettings } from 'hooks/useBillingAccount';
import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { updateProviderPaymentPause } from 'hooks/useProviderPaymentPause';
import { useAuthStore } from 'stores/AuthStore';
import { withUiStore } from 'stores/UiStore';
import {
  hasCustomBilling,
  hasDashboardAccess,
  hasIntakeAccess,
  hasRateAccess,
  isAdminImpersonatingProviderUser,
  isGroupAdmin,
  isOffshoreAgentImpersonatingProviderUser,
} from 'utils/access';
import { ErrorBoundary } from 'utils/errorBoundary';
import { withReactRouterV5Props } from 'utils/migration/withReactRouterV5Props';
import {
  getActiveProviderPaymentPause,
  getLatestProviderPaymentPause,
} from 'utils/providerPaymentPauses';
import { Clients } from 'views/Clients/Clients';
import { Client } from 'views/Clients/ClientView';
import { Home } from 'views/Home/Home';
import { InsuranceStatus } from 'views/InsuranceStatus/InsuranceStatus';
import Payments from 'views/Payments/Payments';
import { ReferAProvider } from 'views/Refer/ReferAProvider';

import { ForethoughtScriptProvider } from '../components/ForethoughtScript';
import { GroupPracticeBillingBanner } from '../components/GroupPracticeBilling/GroupPracticeBillingBanner';
import { PausedPaymentsBanner } from '../components/PausedPayments/PausedPaymentsBanner';
import { ProviderDataManagementBanner } from '../components/ProviderDataManagement/Banner';
import { RecredentialingModal } from '../components/Recredentialing/RecredentialingModal';
import { RecredPastDeadlineBanner } from '../components/Recredentialing/RecredPastDeadlineBanner';
import { SmsAgreementModal } from '../components/SmsAgreement/SmsAgreementModal';
import { UpdatedIdentityModal } from '../components/UpdatedIdentity/UpdatedIdentityModal';
import {
  hasUnmetStripeAccountRequirements,
  isPastW9Deadline,
  shouldShowBankAccountComponents,
  shouldShowW9Components,
} from '../utils/billing';
import { getLocalStorageKeyForProvider } from '../utils/localStorage';
import { is1DayFromLastAcknowledgedDate } from '../utils/modals';
import { CalendarView } from '../views/Calendar/CalendarView';
import Messages from '../views/Messages/HelixMessages';
import { PostIntakeInfoCollectionModal } from '../views/PostIntakeInfoCollection/PostIntakeInfoCollectionModal';
import { Settings } from '../views/SettingsHelix/Settings';
import { BannersContext, calculateBannerHeight } from './utils';

const RECRED_MODAL_ACKNOWLEDGED_DATE = 'recred_modal_acknowledged_date';
const SMS_AGREEMENT_ACKNOWLEDGED = 'sms_agreement_acknowledged';
const UPDATED_IDENTITY_ACKNOWLEDGED = 'updated_identity_acknowledged';

const Dashboard = inject('AuthStore')(
  observer(
    class Dashboard extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          mobileOpen: false,
          showRecredModal: false,
          recredSummary: null,
          isOptionalRecredInProgress: false,
          showDataManagementModal: false,
          showSmsAgreementModal: false,
          showPausedPaymentsModal: false,
          showPausedPaymentsReleasedModal: false,
          showPausedPaymentsBanner: false,
          showW9FormBanner: false,
          showW9Modal: false,
          showGroupPracticeBillingBanner: false,
          showStripeRequirementsBanner: false,
          showBankAccountBanner: false,
          providerLastAttestedDate: null,
          numDaysSinceLastAttestedDate: null,
          showIroncladAmendmentsBanner: false,
          showUpdatedIdentityModal: false,
          providerPaymentPause: null,
          isLoadingCredFormState: true,
        };
        this.resizeFunction = this.resizeFunction.bind(this);
      }

      async componentDidMount() {
        window.addEventListener('resize', this.resizeFunction);

        const providerLastAttestedDate = this.getProviderLastAttestedDate(
          this.props.AuthStore.provider.lastAttestedOn,
          this.props.AuthStore.provider.providerLicenseState
            .questionnaireCompletedOn
        );
        const numDaysSinceLastAttestedDate = moment()
          .startOf('day')
          .diff(moment(providerLastAttestedDate).startOf('day'), 'days');

        const providerPaymentPauses = await this.getProviderPaymentPauses(
          this.props.AuthStore.provider.id
        );

        const showDataManagementBanner = this.shouldShowAttestationBanner(
          providerLastAttestedDate
        );
        const showDataManagementModal = this.shouldShowDataManagementModal(
          showDataManagementBanner
        );

        const smsAgreementAcknowledgedKey = getLocalStorageKeyForProvider(
          SMS_AGREEMENT_ACKNOWLEDGED,
          this.props.AuthStore.provider.id
        );
        const showSmsAgreementModal = this.shouldShowSmsAgreementModal(
          smsAgreementAcknowledgedKey
        );
        const showPausedPaymentsModal = this.shouldShowPausedPaymentsModal(
          providerPaymentPauses
        );
        const showPausedPaymentsReleasedModal =
          this.shouldShowPausedPaymentsReleasedModal(providerPaymentPauses);
        const showPausedPaymentsBanner = this.shouldShowPausedPaymentsBanner(
          providerPaymentPauses
        );
        const showIroncladAmendmentsModal =
          this.shouldShowIroncladAmendmentsModal();

        const showIroncladAmendmentsBanner =
          this.shouldShowIroncladAmendmentsBanner();

        const showGroupPracticeBillingBanner =
          this.shouldShowGroupPracticeBillingBanner();

        const updatedIdentityAcknowledgedKey = getLocalStorageKeyForProvider(
          UPDATED_IDENTITY_ACKNOWLEDGED,
          this.props.AuthStore.provider.id
        );
        const showUpdatedIdentityModal = this.shouldShowUpdatedIdentityModal(
          updatedIdentityAcknowledgedKey
        );

        await this.setRecredSummary();
        this.setShowRecredModal();
        await this.setIsOptionalRecredInProgress();
        this.setState({
          providerLastAttestedDate,
          numDaysSinceLastAttestedDate,
          providerPaymentPauses,
          showDataManagementBanner,
          showDataManagementModal,
          showSmsAgreementModal,
          showPausedPaymentsModal,
          showPausedPaymentsReleasedModal,
          showPausedPaymentsBanner,
          showIroncladAmendmentsModal,
          showIroncladAmendmentsBanner,
          showUpdatedIdentityModal,
          showGroupPracticeBillingBanner,
          isLoadingCredFormState: false,
        });
        if (showDataManagementModal) {
          this.trackDataManagementModalOpen();
        }
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.resizeFunction);
      }

      componentDidUpdate(e) {
        if (this.props.location.pathname !== e.location.pathname) {
          if (this.state.mobileOpen) {
            this.setState({ mobileOpen: false });
          }
        }

        const showIroncladAmendmentsModal =
          this.shouldShowIroncladAmendmentsModal();

        if (showIroncladAmendmentsModal) {
          this.setState({
            showIroncladAmendmentsModal,
          });
        }

        const showW9Components = shouldShowW9Components(
          this.props.AuthStore.provider.groupPracticeId,
          this.props.flags.shouldCollectIndividualW9FromGroup,
          this.props.billingAccountInfo?.stripeAccount
        );
        const showStripeRequirementsComponents =
          hasUnmetStripeAccountRequirements(
            this.props.billingAccountInfo?.stripeAccount
          );
        const showBankAccountBanner = shouldShowBankAccountComponents(
          this.props.billingAccountInfo
        );

        if (
          e.billingAccountInfo?.isLoadingBillingAccount !==
          this.props.billingAccountInfo?.isLoadingBillingAccount
        ) {
          this.setState({
            showW9Modal: showW9Components,
            showStripeRequirementsBanner: showStripeRequirementsComponents,
            showBankAccountBanner: showBankAccountBanner,
          });
        }

        const showGroupPracticeBillingBanner =
          this.shouldShowGroupPracticeBillingBanner();
        if (
          showGroupPracticeBillingBanner !==
          this.state.showGroupPracticeBillingBanner
        ) {
          this.setState({ showGroupPracticeBillingBanner });
        }

        if (
          e.isLoadingIroncladAgreementStatus !==
            this.props.isLoadingIroncladAgreementStatus ||
          e.ironcladAgreementStatus !== this.props.ironcladAgreementStatus
        ) {
          const showIroncladAmendmentsBanner =
            this.shouldShowIroncladAmendmentsBanner();

          this.setState({
            showIroncladAmendmentsBanner,
          });
        }

        const currentShowSMSAgreementModal = this.shouldShowSmsAgreementModal();
        if (
          this.state.showSmsAgreementModal === true &&
          currentShowSMSAgreementModal === false
        ) {
          this.setState({
            showSmsAgreementModal: false,
          });
        }

        const currentShowUpdatedIdentityModal =
          this.shouldShowUpdatedIdentityModal();

        if (
          this.state.showUpdatedIdentityModal === true &&
          currentShowUpdatedIdentityModal === false
        ) {
          this.setState({
            showUpdatedIdentityModal: false,
          });
        }
      }

      setRecredSummary = async () => {
        const recredSummary =
          await ProviderQuestionnaireApi.getProviderQuestionnaireRecredSummary(
            this.props.AuthStore.provider.id
          );

        this.setState({ recredSummary });
      };

      setShowRecredModal = () => {
        let showRecredModal;
        if (
          !this.state.recredSummary?.isEligibleForRecred ||
          this.props.flags.preventAccountTermination
        ) {
          showRecredModal = false;
        } else {
          showRecredModal = is1DayFromLastAcknowledgedDate(
            RECRED_MODAL_ACKNOWLEDGED_DATE,
            this.props.AuthStore.provider.id
          );
        }

        this.setState({ showRecredModal });
      };

      setIsOptionalRecredInProgress = async () => {
        //Fetch the most recent provider questionnaire of type=RECREDENTIALING and
        //check if it is in progress
        const questionnaires =
          await ProviderQuestionnaireApi.findProviderQuestionnaires({
            provider_id: this.props.AuthStore.provider.id,
            type: ProviderQuestionnaireType.RECREDENTIALING,
          });
        const mostRecentPq = questionnaires.sort(
          (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
        )[0];
        const isMostRecentPqInProgress = mostRecentPq
          ? !mostRecentPq.completedOn
          : false;

        this.setState({
          isOptionalRecredInProgress: isMostRecentPqInProgress,
        });
      };

      handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
      };

      resizeFunction() {
        if (window.innerWidth >= 960) {
          this.setState({ mobileOpen: false });
        }
      }

      shouldShowSmsAgreementModal = (smsAgreementAcknowledgedKey) => {
        const {
          AuthStore: { impersonatingUser, provider, user },
        } = this.props;
        const isSpoofing = isAdminImpersonatingProviderUser(
          user,
          impersonatingUser
        );
        const isSmsAgreementAcknowledged =
          window.localStorage.getItem(smsAgreementAcknowledgedKey) === 'true';
        // show to existing providers who do not yet have an smsAgreementAcceptanceDate
        // (unlike all new providers) but are live
        return (
          !isSpoofing &&
          !provider.smsAgreementAcceptanceDate &&
          provider.earliestActiveLiveOn &&
          !isSmsAgreementAcknowledged
        );
      };

      shouldShowIroncladAmendmentsBanner = () => {
        const {
          AuthStore: { provider },
          flags: { ironcladProviderAmendments },
          ironcladAgreementStatus,
          isLoadingIroncladAgreementStatus,
          isLoadingIroncladAgreementInfo,
        } = this.props;

        if (
          isLoadingIroncladAgreementStatus ||
          isLoadingIroncladAgreementInfo ||
          isGPPendingInitialContractAcceptance(provider)
        ) {
          return false;
        }
        const today = moment();
        const isWithin30DaysOfNotice = !!(
          today >= AMENDMENT_DATE_OF_NOTICE &&
          today <= moment(AMENDMENT_DATE_OF_NOTICE).add(30, 'days')
        );

        if (
          ironcladProviderAmendments &&
          isWithin30DaysOfNotice &&
          ironcladAgreementStatus !== IroncladAgreementStatus.AGREED
        ) {
          return true;
        }
      };

      closeIroncladAmendmentsBanner = () =>
        this.setState({ showIroncladAmendmentsBanner: false });

      shouldShowGroupPracticeBillingBanner = () => {
        const { billingAccountInfo } = this.props;
        if (billingAccountInfo) {
          return (
            billingAccountInfo.isGroupPracticeBillingAccount &&
            !billingAccountInfo.isVerified
          );
        }
        return false;
      };

      shouldShowPausedPaymentsModal = (providerPaymentPauses) => {
        const {
          flags: { pausedPayments },
        } = this.props;

        const providerPausedPayment = getActiveProviderPaymentPause(
          providerPaymentPauses
        );
        const isPausedPaymentsAcknowledged =
          providerPausedPayment && providerPausedPayment.acknowledgedOn;

        return (
          pausedPayments &&
          providerPausedPayment &&
          !isPausedPaymentsAcknowledged
        );
      };

      shouldShowPausedPaymentsReleasedModal = (providerPaymentPauses) => {
        const {
          AuthStore: { impersonatingUser, user },
          flags: { pausedPayments },
        } = this.props;

        const isSpoofing = isAdminImpersonatingProviderUser(
          user,
          impersonatingUser
        );
        const latestProviderPaymentPause = getLatestProviderPaymentPause(
          providerPaymentPauses
        );
        const isActive =
          latestProviderPaymentPause &&
          (!latestProviderPaymentPause.endedOn ||
            moment(latestProviderPaymentPause.endedOn) > moment());
        const isPausedPaymentsReleaseAcknowledged =
          latestProviderPaymentPause &&
          latestProviderPaymentPause.acknowledgedReleaseOn;
        return (
          pausedPayments &&
          !isSpoofing &&
          latestProviderPaymentPause &&
          !isActive &&
          !isPausedPaymentsReleaseAcknowledged
        );
      };

      shouldShowPausedPaymentsBanner = (providerPaymentPauses) => {
        const {
          flags: { pausedPayments },
        } = this.props;

        const providerPausedPayment = getActiveProviderPaymentPause(
          providerPaymentPauses
        );
        return pausedPayments && providerPausedPayment;
      };

      shouldShowIroncladAmendmentsModal = () => {
        const {
          AuthStore: { provider, user },
          flags: { ironcladProviderAmendments, preventAccountTermination },
          isLoadingIroncladAgreementInfo,
          isLoadingIroncladAgreementStatus,
          ironcladAgreementStatus,
        } = this.props;

        if (
          isLoadingIroncladAgreementInfo ||
          isLoadingIroncladAgreementStatus ||
          ironcladAgreementStatus === IroncladAgreementStatus.AGREED ||
          isGroupPracticeProviderBefore30Days(provider, user) ||
          preventAccountTermination
        ) {
          return false;
        }

        const {
          ironcladAmendmentLastRenderedOn,
          setIroncladAmendmentLastRenderedOn,
        } = this.props.UiStore;
        const isInitialRender =
          ironcladProviderAmendments &&
          moment() >= AMENDMENT_DATE_OF_NOTICE &&
          ironcladAmendmentLastRenderedOn === null;

        const isTwentyFourHoursSinceLastRender =
          !!ironcladAmendmentLastRenderedOn &&
          moment().isAfter(
            moment(ironcladAmendmentLastRenderedOn).add(24, 'hours')
          );

        if (isInitialRender || isTwentyFourHoursSinceLastRender) {
          setIroncladAmendmentLastRenderedOn(moment());
          return true;
        }
      };

      closeIroncladAmendmentsModal = () =>
        this.setState({ showIroncladAmendmentsModal: false });

      shouldShowUpdatedIdentityModal = (updatedIdentityAcknowledgedKey) => {
        const {
          AuthStore: { impersonatingUser, user },
          flags: { newProviderGender },
        } = this.props;
        const isSpoofing = isAdminImpersonatingProviderUser(
          user,
          impersonatingUser
        );
        const isUpdatedIdentityAcknowledged =
          window.localStorage.getItem(updatedIdentityAcknowledgedKey) ===
          'true';
        return (
          newProviderGender &&
          !isSpoofing &&
          !isUpdatedIdentityAcknowledged &&
          !this.props.AuthStore.provider.genderStatus
        );
      };

      shouldShowDataManagementModal = (showDataManagementBanner) => {
        const {
          flags: { preventAccountTermination },
        } = this.props;
        return (
          this.props.AuthStore.provider &&
          !!this.props.AuthStore.provider.lastAttestedOn &&
          moment(this.props.AuthStore.provider.lastAttestedOn)
            .startOf('day')
            .isSameOrBefore(moment().subtract(83, 'days').startOf('day')) &&
          this.props.AuthStore.provider.attestationStatus !==
            ProviderAttestationStatus.ATTESTED &&
          !showDataManagementBanner &&
          !preventAccountTermination
        );
      };

      shouldShowAttestationBanner = (providerLastAttestedDate) => {
        const {
          AuthStore: { provider },
          flags: { preventAccountTermination },
        } = this.props;
        if (!providerLastAttestedDate || preventAccountTermination) {
          return false;
        }
        const isWithinValidDateRange =
          moment(providerLastAttestedDate)
            .startOf('day')
            .isSameOrBefore(moment().subtract(60, 'days').startOf('day')) &&
          moment(providerLastAttestedDate)
            .startOf('day')
            .isAfter(moment().subtract(83, 'days').startOf('day'));
        return (
          isWithinValidDateRange &&
          provider.attestationStatus !== ProviderAttestationStatus.ATTESTED
        );
      };

      getProviderPaymentPauses = async (providerId) => {
        const providerPausedPayments =
          await ProviderPaymentPauseApi.getProviderPaymentPauses({
            provider_ids: [providerId],
          });

        return providerPausedPayments;
      };

      getProviderLastAttestedDate = (
        lastAttestedOn,
        questionnaireCompletedOn
      ) => {
        if (lastAttestedOn) {
          return lastAttestedOn;
        } else if (questionnaireCompletedOn) {
          return questionnaireCompletedOn;
        } else {
          return null;
        }
      };

      trackDataManagementModalOpen = () => {
        trackEvent({
          name: 'Provider Attestation Started',
          properties: {
            lastAttestedDate: this.state.providerLastAttestedDate,
            numDaysSinceLastAttested: this.state.numDaysSinceLastAttestedDate,
          },
        });
      };

      render() {
        const { AuthStore, UiStore, classes, history, ...rest } = this.props;

        if (this.state.showPausedPaymentsModal) {
          trackEvent({
            name: 'Paused Payment Modal Viewed',
            properties: {
              providerId: AuthStore.provider.id,
              auditBy: 'Headway',
            },
          });
        }
        if (this.state.showUpdatedIdentityModal) {
          trackEvent({
            name: 'Provider Identity Modal Viewed',
            properties: {
              providerId: AuthStore.provider.id,
            },
          });
        }

        if (!hasIntakeAccess(AuthStore.provider, AuthStore.user)) {
          return <Navigate replace to="/auth/register" />;
        } else if (
          !hasDashboardAccess(AuthStore.provider) &&
          !this.props.flags?.skipIntake
        ) {
          return <Navigate replace to="/credentials" />;
        }

        const bannerComponents = [
          {
            shouldShow: this.state.showDataManagementBanner,
            component: () => (
              <ProviderDataManagementBanner
                onClick={() => {
                  this.setState({ showDataManagementModal: true });
                  this.trackDataManagementModalOpen();
                }}
                lastAttestedOn={this.state.providerLastAttestedDate}
              />
            ),
          },
          {
            shouldShow: this.state.showPausedPaymentsBanner,
            component: (bannerOffset) => (
              <PausedPaymentsBanner
                onClick={() => {
                  this.setState({ showPausedPaymentsModal: true });
                  trackEvent({
                    name: 'Learn More Payments on Hold Banner Clicked',
                    properties: {
                      providerId: AuthStore.provider.id,
                      auditBy: 'Headway',
                    },
                  });
                }}
                bannerOffset={bannerOffset}
              />
            ),
          },
          {
            shouldShow: this.state.showIroncladAmendmentsBanner,
            component: (bannerOffset) => (
              <IroncladAmendmentsBanner
                onPress={() =>
                  this.setState({ showIroncladAmendmentsModal: true })
                }
                bannerOffset={bannerOffset}
              />
            ),
          },
          {
            shouldShow: this.state.showW9FormBanner,
            component: (bannerOffset) => (
              <W9Banner bannerOffset={bannerOffset} />
            ),
          },
          {
            shouldShow:
              this.state.showStripeRequirementsBanner &&
              this.props.flags.showStripeAccountTasksInSigmund,
            component: (bannerOffset) => (
              <StripeRequirementsBanner bannerOffset={bannerOffset} />
            ),
          },
          {
            shouldShow:
              this.state.showBankAccountBanner &&
              this.props.flags.showStripeAccountTasksInSigmund,
            component: (bannerOffset) => (
              <BankAccountBanner bannerOffset={bannerOffset} />
            ),
          },
          {
            shouldShow: isPastRecredDeadlineAndGracePeriod(
              this.state.recredSummary
            ),
            component: (bannerOffset) => (
              <RecredPastDeadlineBanner
                recredSummary={this.state.recredSummary}
                bannerOffset={bannerOffset}
              />
            ),
          },
          {
            shouldShow: this.state.showGroupPracticeBillingBanner,
            component: (bannerOffset) => (
              <GroupPracticeBillingBanner
                bannerOffset={bannerOffset}
                isGroupAdmin={isGroupAdmin(this.props.AuthStore.user)}
              />
            ),
          },
        ];

        let numBannersAbove = 0;
        const visibleBanners = bannerComponents.filter(
          ({ shouldShow }) => shouldShow
        );

        const showCredForm =
          !this.state.isLoadingCredFormState &&
          ((this.state.recredSummary?.isEligibleForRecred &&
            !this.state.recredSummary?.isLatestRecredComplete &&
            !isPastRecredDeadlineAndGracePeriod(this.state.recredSummary)) ||
            this.state.isOptionalRecredInProgress);
        const showPayerQuestionnaire =
          !this.props.isLoadingIncompletePayerQuestionnaires &&
          !!this.props.incompletePayerQuestionnaires?.length;

        const showOffshoreBrokenLinks =
          !isOffshoreAgentImpersonatingProviderUser(
            AuthStore.impersonatingUserRoles
          );

        return (
          <BannersContext.Provider value={visibleBanners.length}>
            {visibleBanners.map(({ component }) => {
              const bannerOffset = calculateBannerHeight(numBannersAbove);
              const renderedComponent = component(bannerOffset);
              numBannersAbove += 1;
              return renderedComponent;
            })}
            <Header
              handleDrawerToggle={this.handleDrawerToggle}
              startComponent={<ProviderSelect />}
            />
            <div
              id="maincontent"
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: calculateBannerHeight(numBannersAbove),
              }}
            >
              <ForethoughtScriptProvider>
                <ForethoughtButton />
                <Sidebar
                  handleDrawerToggle={this.handleDrawerToggle}
                  showCredForm={showCredForm}
                  showPayerQuestionnaire={showPayerQuestionnaire}
                  open={this.state.mobileOpen}
                  {...rest}
                />

                {/* add a key here so that the page body re-renders when a group practice owner switches between providers */}
                <div
                  key={AuthStore.provider?.id}
                  css={{
                    width: '100%',
                    [theme.media.medium]: {
                      width: 'calc(100% - 238px)',
                    },
                  }}
                >
                  <ProviderAddressProvider
                    providerIds={[AuthStore.provider.id]}
                  >
                    <ProviderFrontEndCarrierProvider
                      providerId={AuthStore.provider.id}
                    >
                      <FrontEndCarrierProvider>
                        <ProviderTaskProvider
                          providerId={AuthStore.provider.id}
                        >
                          <ErrorBoundary providerId={AuthStore.provider?.id}>
                            {/* add a key here so that the page body re-renders when a group practice owner switches between providers */}
                            <Routes key={AuthStore.provider?.id}>
                              <Route
                                path="/home/*"
                                element={
                                  // Lots of places (including login) redirect to /home, which is broken for offshore impersonators
                                  // Lets send them to a known working page when this happens
                                  showOffshoreBrokenLinks ? (
                                    <Home />
                                  ) : (
                                    <Navigate replace to="/insurance-status" />
                                  )
                                }
                              />
                              <Route
                                path="/calendar"
                                element={
                                  <CalendarView provider={AuthStore.provider} />
                                }
                              />
                              {[
                                '',
                                'clinical',
                                'billing',
                                'communication',
                                'insurance',
                                'payment',
                              ].map((segment) => (
                                <Route
                                  key={segment}
                                  path={`/clients/:id/${segment}/*`}
                                  element={<Client />}
                                />
                              ))}
                              <Route path="/clients/*" element={<Clients />} />
                              {/** Legacy /patients routes are here for anyone with saved/bookmarked links. */}
                              <Route
                                path="/patients/:id/:subpath?/*"
                                element={<PatientsRedirect />}
                              />
                              <Route
                                path="/patients"
                                element={<Navigate replace to="/clients" />}
                              />
                              {hasRateAccess(
                                AuthStore.provider,
                                AuthStore.user
                              ) ? (
                                <Route
                                  path="/payments/*"
                                  element={<Payments />}
                                />
                              ) : null}
                              {!hasCustomBilling(AuthStore.provider) ? (
                                <Route
                                  path="/messages/*"
                                  element={<Messages />}
                                />
                              ) : null}
                              <Route
                                path="/insurance-status/*"
                                element={
                                  <InsuranceStatus
                                    recredSummary={this.state.recredSummary}
                                  />
                                }
                              />
                              <Route
                                path="/settings/*"
                                element={<Settings AuthStore={AuthStore} />}
                              />
                              <Route
                                path="/refer-a-provider/*"
                                element={<ReferAProvider />}
                              />
                              <Route
                                path="*"
                                element={<Navigate replace to="/home" />}
                              />
                            </Routes>
                            {this.state.showDataManagementModal && (
                              <ProviderDataManagementModal
                                UiStore={UiStore}
                                providerLastAttestedDate={
                                  this.state.providerLastAttestedDate
                                }
                                numDaysSinceLastAttestedDate={
                                  this.state.numDaysSinceLastAttestedDate
                                }
                                open={this.state.showDataManagementModal}
                                onClose={(event) => {
                                  this.setState({
                                    showDataManagementModal: false,
                                  });
                                  if (event.target.localName !== 'button') {
                                    trackEvent({
                                      name: 'Provider Attestation Step Viewed',
                                      properties: {
                                        lastAttestedDate:
                                          this.state.providerLastAttestedDate,
                                        numDaysSinceLastAttested:
                                          this.state
                                            .numDaysSinceLastAttestedDate,
                                        stepName: 'exited',
                                      },
                                    });
                                  }
                                }}
                                hideDataManagementBanner={() =>
                                  this.setState({
                                    showDataManagementBanner: false,
                                  })
                                }
                              />
                            )}
                          </ErrorBoundary>
                        </ProviderTaskProvider>
                      </FrontEndCarrierProvider>
                    </ProviderFrontEndCarrierProvider>
                  </ProviderAddressProvider>
                </div>

                {UiStore.isLoading ? (
                  <div className="absolute left-0 top-0 z-[9999] flex h-screen w-full items-center justify-center overflow-hidden">
                    <LogoLoader />
                  </div>
                ) : (
                  <React.Fragment />
                )}
                {showPayerQuestionnaire &&
                  this.props.incompletePayerQuestionnaires.map(
                    (incompletePayerQuestionnaire) => (
                      <PostIntakeInfoCollectionModal
                        incompletePayerQuestionnaire={
                          incompletePayerQuestionnaire
                        }
                        refetchIncompletePayerQuestionnaires={
                          this.props.refetchIncompletePayerQuestionnaires
                        }
                        providerFrontEndCarriers={
                          AuthStore.provider.providerFrontEndCarriers
                        }
                        key={incompletePayerQuestionnaire.response.id}
                      />
                    )
                  )}
                {isGroupAdmin(this.props.AuthStore.user) &&
                  !this.props.flags.shouldCollectIndividualW9FromGroup && (
                    <GroupPracticeBillingModal provider={AuthStore.provider} />
                  )}
                <RecredentialingModal
                  isOpen={this.state.showRecredModal}
                  recredSummary={this.state.recredSummary}
                  onClose={() => {
                    const key = getLocalStorageKeyForProvider(
                      RECRED_MODAL_ACKNOWLEDGED_DATE,
                      this.props.AuthStore.provider?.id
                    );
                    window.localStorage.setItem(
                      key,
                      moment().format('YYYY-MM-DD')
                    );
                    this.setState({ showRecredModal: false });
                  }}
                />
                {this.state.showSmsAgreementModal && (
                  <SmsAgreementModal
                    open={this.state.showSmsAgreementModal}
                    onClose={() => {
                      window.localStorage.setItem(
                        getLocalStorageKeyForProvider(
                          SMS_AGREEMENT_ACKNOWLEDGED,
                          AuthStore.provider.id
                        ),
                        'true'
                      );
                      this.setState({ showSmsAgreementModal: false });
                    }}
                    onError={UiStore.showWarningSnackbar}
                    AuthStore={AuthStore}
                  />
                )}
                {this.state.showUpdatedIdentityModal && (
                  <UpdatedIdentityModal
                    open={this.state.showUpdatedIdentityModal}
                    onClose={() => {
                      window.localStorage.setItem(
                        getLocalStorageKeyForProvider(
                          UPDATED_IDENTITY_ACKNOWLEDGED,
                          AuthStore.provider.id
                        ),
                        'true'
                      );
                      this.setState({ showUpdatedIdentityModal: false });
                    }}
                    providerId={AuthStore.provider.id}
                  />
                )}
                {this.state.showPausedPaymentsModal && (
                  <PausedPaymentsModal
                    open={true}
                    providerId={this.props.AuthStore.provider.id}
                    onClose={async () => {
                      const paymentPause = getActiveProviderPaymentPause(
                        this.state.providerPaymentPauses
                      );
                      const isSpoofing = isAdminImpersonatingProviderUser(
                        this.props.AuthStore.user,
                        this.props.AuthStore.impersonatingUser
                      );
                      if (!isSpoofing && !paymentPause.acknowledgedOn) {
                        await updateProviderPaymentPause({
                          providerPaymentPauseId: paymentPause.id,
                          acknowledgedOn: new Date(),
                        });
                      }
                      this.setState({ showPausedPaymentsModal: false });
                    }}
                  />
                )}
                {this.state.showPausedPaymentsReleasedModal && (
                  <PausedPaymentsReleasedModal
                    open={true}
                    onClose={async () => {
                      const paymentPause = getLatestProviderPaymentPause(
                        this.state.providerPaymentPauses
                      );
                      const isSpoofing = isAdminImpersonatingProviderUser(
                        this.props.AuthStore.user,
                        this.props.AuthStore.impersonatingUser
                      );
                      if (!isSpoofing) {
                        await updateProviderPaymentPause({
                          providerPaymentPauseId: paymentPause.id,
                          acknowledgedReleaseOn: new Date(),
                        });
                      }
                      this.setState({ showPausedPaymentsReleasedModal: false });
                    }}
                  />
                )}
                {this.state.showIroncladAmendmentsModal && (
                  <IroncladAmendmentsModal
                    isOpen={this.state.showIroncladAmendmentsModal}
                    closeIroncladAmendmentsModal={
                      this.closeIroncladAmendmentsModal
                    }
                    closeIroncladAmendmentsBanner={
                      this.closeIroncladAmendmentsBanner
                    }
                  />
                )}
                {this.state.showW9Modal && (
                  <W9Modal
                    providerState={
                      this.props.AuthStore.provider.providerLicenseState.state
                    }
                    stripeOnboardingLink={
                      this.props.billingAccountInfo.stripeOnboardingLink
                    }
                    closeW9Modal={() =>
                      this.setState({
                        showW9Modal: false,
                        showW9FormBanner: isPastW9Deadline(
                          this.props.AuthStore.provider.providerLicenseState
                            .state
                        ),
                      })
                    }
                  />
                )}
              </ForethoughtScriptProvider>
            </div>
            <FlagsBreakingVersionPrompt
              flag="sigmundBreakingVersion"
              prompt={RefreshPageAlert}
            />
          </BannersContext.Provider>
        );
      }
    }
  )
);

const PatientsRedirect = () => {
  const match = useMatch('/patients/:id/:subpath?');
  return (
    <Navigate
      replace
      to={`/clients/${match?.params.id}/${match?.params.subpath}`}
    />
  );
};

const DashboardWithQuery = (props) => {
  const { provider, user } = useAuthStore();
  const { carriersById } = useFrontEndCarriers();
  const {
    ironcladAgreementStatus,
    isLoading: isLoadingIroncladAgreementStatus,
  } = useIroncladAgreementStatus();
  const { ironcladAgreementInfo, isLoading: isLoadingIroncladAgreementInfo } =
    useIroncladAgreementInfo();
  const { billingAccountInfo } = useBillingAccountForBillingSettings();
  const {
    data: incompletePayerQuestionnaires,
    isLoading: isLoadingIncompletePayerQuestionnaires,
    refetch: refetchIncompletePayerQuestionnaires,
  } = usePayerQuestionnaires({
    provider,
    incompleteOnly: true,
    intakeCompleteStatesOnly: true,
  });

  return (
    <ExtoleAccessTokenProvider user={user}>
      <Dashboard
        key={provider.id}
        {...props}
        carriersById={carriersById}
        ironcladAgreementStatus={ironcladAgreementStatus}
        isLoadingIroncladAgreementStatus={isLoadingIroncladAgreementStatus}
        ironcladAgreementInfo={ironcladAgreementInfo}
        isLoadingIroncladAgreementInfo={isLoadingIroncladAgreementInfo}
        billingAccountInfo={billingAccountInfo}
        incompletePayerQuestionnaires={incompletePayerQuestionnaires}
        isLoadingIncompletePayerQuestionnaires={
          isLoadingIncompletePayerQuestionnaires
        }
        refetchIncompletePayerQuestionnaires={
          refetchIncompletePayerQuestionnaires
        }
      />
    </ExtoleAccessTokenProvider>
  );
};

export default withFlags(
  withUiStore(withReactRouterV5Props(DashboardWithQuery)),
  {
    shouldCollectIndividualW9FromGroup: undefined,
    preventAccountTermination: undefined,
    ironcladProviderAmendments: undefined,
    pausedPayments: undefined,
    newProviderGender: undefined,
    skipIntake: undefined,
    showStripeAccountTasksInSigmund: undefined,
  }
);
