export interface CPTCodeInfo {
  value: string;
  display: string;
  allowMultiple?: boolean;
}

const CIGNA_EAP_CODE = '99404';
const ANTHEM_EAP_CODE = '99404';
const OPTUM_EAP_CODES = ['90832', '90834', '90846', '90847', '90853'];
const PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES = ['90833', '90836', '90838'];

const PRESCRIBER_PPR_CPT_CODES: string[] = []; // note this will affect progress note requirements if changed

const NONPRESCRIBER_COMMON_CODES: CPTCodeInfo[] = [
  { value: '90791', display: `Initial Eval / First Session` },
  {
    value: '90834',
    display: `Psychotherapy, 45 minutes (38-52 minutes)`,
  },
  {
    value: '90837',
    display: `Psychotherapy, 60 minutes (53 minutes and over)`,
  },
];
const NONPRESCRIBER_LESS_COMMON_CODES: CPTCodeInfo[] = [
  {
    value: '90832',
    display: `Psychotherapy, 30 minutes (16-37 minutes)`,
  },
  {
    value: '90839',
    display: `Psychotherapy for crisis, 60 minutes (30-74 minutes)`,
  },
  {
    value: '90840',
    display: `Psychotherapy for crisis, each additional 30 minutes (16-37 minutes)`,
    allowMultiple: true,
  },
  {
    value: '90846',
    display: `Family or couples psychotherapy, without patient present`,
  },
  {
    value: '90847',
    display: `Family or couples psychotherapy, with patient present`,
  },
  { value: '90853', display: `Group Psychotherapy (not family)` },
  {
    value: '90785',
    display: `Interactive Complexity`,
    allowMultiple: true,
  },
  {
    value: '99404',
    display:
      'Preventive medicine counseling and/or risk factor reduction intervention(s)',
  },
];

const PRESCRIBER_COMMON_CODES: CPTCodeInfo[] = [
  { value: '90791', display: `Initial Eval / First Session` },
  {
    value: '90792',
    display: `Psychiatric Diagnostic Evaluation with medical services`,
  },
  {
    value: '90833',
    display: `Psychotherapy (at least 16 minutes) when done in tandem with an E&M code`,
  },
  {
    value: '90836',
    display: `Psychotherapy (at least 38 minutes) when done in tandem with an E&M code`,
  },
  {
    value: '90838',
    display: `Psychotherapy (at least 53 minutes) when done in tandem with an E&M code`,
  },
  {
    value: '99212',
    display: `E&M of an established patient of straightforward Medical Decision Making or 10-19 minutes of total time`,
  },
  {
    value: '99213',
    display: `E&M of an established patient of low Medical Decision Making or 20-29 minutes of total time`,
  },
  {
    value: '99214',
    display: `E&M of an established patient of moderate Medical Decision Making or 30-39 minutes of total time`,
  },
  {
    value: '99215',
    display: `E&M of an established patient of high Medical Decision Making or 40-54 minutes of total time`,
  },
];

const PRESCRIBER_LESS_COMMON_CODES: CPTCodeInfo[] = [
  {
    value: '90785',
    display: `Interactive Complexity`,
    allowMultiple: true,
  },
  {
    value: '90832',
    display: `Psychotherapy, 30 minutes (16-37 minutes)`,
  },
  {
    value: '90834',
    display: `Psychotherapy, 45 minutes (38-52 minutes)`,
  },
  {
    value: '90837',
    display: `Psychotherapy, 60 minutes (53 minutes and over)`,
  },
  {
    value: '90839',
    display: `Psychotherapy for crisis, 60 minutes (30-74 minutes)`,
  },
  {
    value: '90840',
    display: `Psychotherapy for crisis, each additional 30 minutes (16-37 minutes)`,
    allowMultiple: true,
  },
  {
    value: '90846',
    display: `Family or couples psychotherapy, without patient present`,
  },
  {
    value: '90847',
    display: `Family or couples psychotherapy, with patient present`,
  },
  { value: '90853', display: `Group Psychotherapy (not family)` },
  {
    value: '99202',
    display: `E&M of a new patient of straightforward Medical Decision Making or 15-29 minutes of total time`,
  },
  {
    value: '99203',
    display: `E&M of a new patient of low Medical Decision Making or 30-44 minutes of total time`,
  },
  {
    value: '99204',
    display: `E&M of a new patient of moderate Medical Decision Making or 45-59 minutes of total time`,
  },
  {
    value: '99205',
    display: `E&M of a new patient of high Medical Decision Making or 60-74 minutes of total time`,
  },
];

const NONPRESCRIBER_ADD_ON_CODES = [
  ...NONPRESCRIBER_LESS_COMMON_CODES.filter((c) => c.allowMultiple).map(
    (c) => c.value
  ),
];

const CODES_BY_VALUE = Object.fromEntries(
  [
    ...PRESCRIBER_COMMON_CODES,
    ...PRESCRIBER_LESS_COMMON_CODES,
    ...NONPRESCRIBER_COMMON_CODES,
    ...NONPRESCRIBER_LESS_COMMON_CODES,
  ].map((code) => [code.value, code])
);

export {
  NONPRESCRIBER_COMMON_CODES,
  NONPRESCRIBER_LESS_COMMON_CODES,
  PRESCRIBER_COMMON_CODES,
  PRESCRIBER_LESS_COMMON_CODES,
  ANTHEM_EAP_CODE,
  CIGNA_EAP_CODE,
  OPTUM_EAP_CODES,
  NONPRESCRIBER_ADD_ON_CODES,
  CODES_BY_VALUE,
  PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES,
  PRESCRIBER_PPR_CPT_CODES,
};
