import { useControlledState } from '@react-stately/utils';
import { CollectionElement } from '@react-types/shared';
import React from 'react';
import { useState } from 'react';
import { Key } from 'react-aria';
import {
  Dialog,
  DialogTrigger,
  OverlayTriggerStateContext,
  Popover, // @ts-expect-error
  useCollection,
} from 'react-aria-components';

import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { CheckboxGroup } from '../CheckboxGroup';
import { FilterButton, FilterButtonCount } from './Filter';

interface CheckboxFilterProps<T> {
  children: (item: T) => CollectionElement<T>;
  items: Iterable<T>;
  selection: Iterable<Key>;
  onSelectionChange: (selection: Set<Key>) => void;
  size?: 'medium' | 'large';
  label: string;
}

export function CheckboxFilter<T>({
  onSelectionChange,
  children: renderer,
  items,
  ...props
}: CheckboxFilterProps<T>) {
  let { portal, collection } = useCollection({
    items,
    children: renderer,
  });

  const [selection, setSelection] = useControlledState(
    props.selection ? new Set(props.selection) : undefined,
    new Set<Key>(),
    (selection) => {
      onSelectionChange?.(selection ?? new Set<Key>());
    }
  );

  const isSelected = !!selection && selection.size > 0;

  let content = props.label;
  if (isSelected && collection) {
    const firstSelectedItem = selection.values().next().value;

    const item = collection.getItem(firstSelectedItem);

    if (item) {
      content = item.rendered;
    }
  }

  return (
    <>
      {portal}
      <DialogTrigger>
        <FilterButton
          onClear={() => {
            setSelection(new Set());
          }}
          isSelected={isSelected}
          size={props.size}
          aria-label={
            isSelected
              ? `${props.label}, ${selection.size} selected`
              : props.label
          }
        >
          {content}
          <FilterButtonCount selection={selection} />
        </FilterButton>
        <Popover className="hlx-popover" offset={4} placement="bottom left">
          <Dialog className="hlx-filter-dialog">
            <CheckboxGroupFilterDialog
              selection={selection}
              setSelection={setSelection}
              collection={collection}
            />
          </Dialog>
        </Popover>
      </DialogTrigger>
    </>
  );
}
interface CheckboxGroupFilterDialogProps<T> {
  selection: Set<Key>;
  setSelection: (selection: Set<Key>) => void;
  collection: any;
}
function CheckboxGroupFilterDialog<T>({
  selection,
  setSelection,
  collection,
}: CheckboxGroupFilterDialogProps<T>) {
  const [state, setState] = useState(selection ? Array.from(selection) : []);
  const dialogState = React.useContext(OverlayTriggerStateContext);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSelection(new Set(state));
        dialogState.close();
      }}
    >
      <div className="hlx-filter-dialog-content">
        <CheckboxGroup
          name="filter"
          value={state.map((key) => key.toString())}
          onChange={(selected) => {
            setState(selected);
          }}
        >
          {[...collection].map((node, i) => (
            <Checkbox key={node.key} value={node.key}>
              {node.rendered}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </div>
      <div className="hlx-filter-dialog-footer">
        <Button
          variant="link"
          onPress={() => {
            const empty = new Set<string>();

            setSelection(empty);
            dialogState.close();
          }}
        >
          Clear
        </Button>
        <Button variant="primary" size="medium" type="submit">
          Apply
        </Button>
      </div>
    </form>
  );
}
