import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import { SessionDetailsFormV2Values } from '../../AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';

export interface ProviderNameData {
  id: number;
  displayFirstName?: string;
  displayLastName?: string;
  prenomial?: string;
}

export enum BulkConfirmSubmissionStatus {
  UNSUBMITTED = 'UNSUBMITTED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type SubmissionStatusMap = Record<string, BulkConfirmSubmissionStatus>;

export interface BulkConfirmFormValues {
  sessions: { [virtualId: string]: SessionDetailsFormV2Values };
  notesAttestation: boolean;
}

export type UnconfirmedEventsData = {
  [key: ProviderEventRead['virtualId']]: {
    event: ProviderEventRead;
    providerAddress?: ProviderAddressRead;
    patientAddress?: PatientAddressRead;
  };
};
