import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderEventQueryKeyArgs {
  eventIdOrVirtualId: number | string | undefined;
}
export type UseProviderEventQueryKey = [string, number | string | undefined];

export const getUseProviderEventQueryKey = ({
  eventIdOrVirtualId,
}: UseProviderEventQueryKeyArgs): UseProviderEventQueryKey => [
  'provider-event',
  eventIdOrVirtualId,
];

const {
  useSingleQuery: useProviderEvent,
  useListQuery: useProviderEventList,
  useCachedQuery: useProviderEventCache,
} = createBasicApiHooks(
  getUseProviderEventQueryKey,
  ({ eventIdOrVirtualId }) =>
    ProviderEventApi.getEventById(eventIdOrVirtualId!),
  ({ eventIdOrVirtualId }) => !!eventIdOrVirtualId,
  () => 'Failed to fetch event'
);

export { useProviderEvent, useProviderEventList, useProviderEventCache };
