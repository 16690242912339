import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';

import {
  FieldControl,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
} from '@headway/ui/form';
import { theme } from '@headway/ui/theme';

import { Button } from '../Button';
import { SafeFormikForm } from '../form/SafeFormikForm';
import {
  GoogleMapEmbed,
  GoogleMapsApiProvider,
  HeadwayMarker,
} from '../GoogleMap';

const LAYOUTS = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};

const horizontalStyles = {
  formContainer: { display: 'flex', flexDirection: 'row-reverse' },
  mapContainer: { height: '300px', width: '250px' },
};
const verticalStyles = {
  formContainer: {},
  mapContainer: { height: '200px' },
};

const styles = {
  [LAYOUTS.vertical]: verticalStyles,
  [LAYOUTS.horizontal]: horizontalStyles,
};

export const AddressFormWithMap = ({
  selectedAddress,
  saveAddress,
  cancelAddress,
  googleMapsApiKey,
  layout = LAYOUTS.vertical,
  AddressSchema,
}) => {
  const mapCenter = {
    lat: selectedAddress.lat ?? 42.65258,
    lng: (selectedAddress.lng || selectedAddress.lon) ?? -73.756233,
  };

  return (
    <>
      {!!selectedAddress.streetLine1 && (
        <div
          css={{
            borderRadius: 3,
            border: `1px solid ${theme.color.border}`,
            overflow: 'hidden',
            ...styles[layout].formContainer,
          }}
        >
          <GoogleMapsApiProvider
            apiKey={googleMapsApiKey}
            // For now we have to include the places library eveywhere we use the GoogleMapsApiProvider
            // to avoid breaking changes to the global Google namespace.
            libraries={['places']}
          >
            <GoogleMapEmbed
              defaultZoom={14}
              defaultCenter={mapCenter}
              scrollwheel={false}
              style={{ height: '200px', ...styles[layout].mapContainer }}
            >
              {!!mapCenter && <HeadwayMarker position={mapCenter} />}
            </GoogleMapEmbed>
          </GoogleMapsApiProvider>
          <div css={{ padding: theme.space.base }}>
            <>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h4
                  css={{
                    fontFamily: theme.fontFamily.postGrotesk,

                    marginBottom: 0,
                    color: '#000000',
                    fontSize: theme.fontSize.xl,
                  }}
                >
                  {selectedAddress.streetLine1}
                </h4>
              </div>
              <div css={{ marginTop: theme.space.xs4 }}>
                <div css={{ width: '100%' }}>
                  <span>
                    {selectedAddress.streetLine2 ? (
                      <>
                        {selectedAddress.streetLine2}
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    {selectedAddress.city}, {selectedAddress.state},{' '}
                    {selectedAddress.zipCode}
                  </span>
                </div>
              </div>
              <Formik
                initialValues={{
                  city: selectedAddress.city || '',
                  state: selectedAddress.state || '',
                  streetLine1: selectedAddress.streetLine1 || '',
                  streetLine2: selectedAddress.streetLine2 || '',
                  locationInstructions:
                    selectedAddress.locationInstructions || '',
                  isActive: selectedAddress.hasOwnProperty('isActive')
                    ? selectedAddress.isActive
                    : true,
                  zipCode: selectedAddress.zipCode || '',
                }}
                enableReinitialize={true}
                validationSchema={AddressSchema}
                onSubmit={saveAddress}
              >
                {({ isSubmitting }) => (
                  <SafeFormikForm
                    css={{
                      marginTop: theme.space.sm,
                    }}
                  >
                    <div>
                      <FieldControl name={`streetLine2`} fullWidth={true}>
                        <FieldInputLabel>
                          (Optional) Suite or Floor Number
                        </FieldInputLabel>
                        <FieldInput placeholder="e.g. Suite 505, Floor 5" />
                        <FieldErrorText />
                      </FieldControl>
                      <FieldControl
                        name={`locationInstructions`}
                        fullWidth={true}
                      >
                        <FieldInputLabel>
                          (Optional) Location Instructions
                        </FieldInputLabel>
                        <FieldInput placeholder="e.g. Take elevator to 3rd floor" />
                        <FieldErrorText />
                      </FieldControl>
                      <div
                        css={{
                          paddingTop: theme.space.base,
                          display: 'flex',

                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          color="primary"
                          variant="contained"
                        >
                          Save
                        </Button>
                        {cancelAddress && (
                          <Button
                            color="gray"
                            onClick={cancelAddress}
                            disabled={isSubmitting}
                            css={
                              layout === LAYOUTS.horizontal && {
                                marginLeft: theme.space.sm,
                              }
                            }
                          >
                            Cancel
                          </Button>
                        )}
                      </div>
                    </div>
                  </SafeFormikForm>
                )}
              </Formik>
            </>
          </div>
        </div>
      )}
    </>
  );
};
