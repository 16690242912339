import { useCallback, useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { Item, Select } from '@headway/helix/Select';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { FormRow } from '@headway/ui/form';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddresses } from 'hooks/usePatientAddresses';

import { AppointmentConfirmationContextV2 } from '../../../../stores/AppointmentConfirmationContextV2';
import { FormControlRHF } from '../../../FormControlRHF';
import { SessionDetailsFormV2Values } from '../SessionDetailsFormV2';

// gemini.link(web/apps/sigmund/app/legacy/views/Calendar/components/AppointmentConfirmation/Forms/FormComponents/PatientAddressDropdown.tsx)
export const PatientAddressDropdownHelixV2 = ({
  patient,
  canUpdateSessionDetails,
}: {
  patient: UserRead;
  canUpdateSessionDetails: boolean;
}) => {
  const {
    updateAppointmentAddressState,
    provider,
    event,
    setIsAddressModalOpen,
  } = useContext(AppointmentConfirmationContextV2);
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });
  const { data: patientAddresses } = usePatientAddresses({
    patientId: patient.id,
  });
  const { isMSCGuardrailWarning, restrictionDate } = useMSCGuardrail();
  const { setValue, trigger } = useFormContext();
  const sessionDetailsValues: SessionDetailsFormV2Values = useWatch({
    name: 'sessionDetails',
  });

  const patientAddressesOnFileText = `Your client must be physically located (during your session) in a state where you are credentialed with their insurance plan on Headway. ${
    patientAddresses?.length === 0 && isMSCGuardrailWarning
      ? `Starting after ${restrictionDate}, we'll block telehealth session confirmation if there is a mismatch.`
      : ''
  }`;

  const onSelectionChange = useCallback(
    (value: Set<string>) => {
      const selectedValue =
        value.size > 0 ? parseInt(Array.from(value)[0]) : undefined;
      setValue(
        'sessionDetails.appointmentLocationPatientAddressId',
        selectedValue
      );
      trigger('sessionDetails.appointmentLocationPatientAddressId');
      updateAppointmentAddressState(
        sessionDetailsValues?.providerAddressId,
        selectedValue
      );
    },
    [
      setValue,
      trigger,
      sessionDetailsValues?.providerAddressId,
      updateAppointmentAddressState,
    ]
  );

  const handleOnNewLocationClick = useCallback(() => {
    if (provider && patient) {
      trackEvent({
        name: 'Provider Add New Address Button Clicked',
        properties: {
          providerId: provider.id,
          providerPatientId: patient.id,
          providerAppointmentId: event?.providerAppointment?.id,
        },
      });
    }
    setIsAddressModalOpen(true);
  }, [
    event?.providerAppointment?.id,
    provider,
    patient,
    setIsAddressModalOpen,
  ]);

  return (
    <FormRow>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <FormControlRHF
            component={Select}
            name="sessionDetails.appointmentLocationPatientAddressId"
            disabled={!canUpdateSessionDetails}
            label={`Where did ${patientFirstName} join the telehealth session from?`}
            menuWidth="stretch"
            placeholder="Select a location"
            helpText={patientAddressesOnFileText}
            selectionMode="single"
            selectedKeys={
              sessionDetailsValues.appointmentLocationPatientAddressId
                ? new Set([
                    sessionDetailsValues.appointmentLocationPatientAddressId.toString(),
                  ])
                : new Set()
            }
            onSelectionChange={onSelectionChange}
          >
            {patientAddresses?.map((address: PatientAddressRead) => (
              <Item
                key={address.id}
                textValue={`${
                  address.addressType
                    ? `${addressTypeDisplayNames[address.addressType]}: `
                    : ''
                }${address.streetLine1}${
                  address.streetLine2 ? ` ${address.streetLine2}` : ''
                }, ${address.city}, ${statesToDisplayNames[address.state]} ${
                  address.zipCode
                }`}
              >
                {address.addressType &&
                  `${addressTypeDisplayNames[address.addressType]}: `}
                {address.streetLine1}
                {address.streetLine2 && ` ${address.streetLine2}`},{' '}
                {address.city}, {statesToDisplayNames[address.state]}{' '}
                {address.zipCode}
              </Item>
            ))}
          </FormControlRHF>
        </div>
        <div style={{ padding: 10 }} />
        <div
          className={'hlx-combobox-actions button'}
          style={{ paddingTop: 20 }}
        >
          <Button
            size="large"
            variant={'secondary'}
            onPress={handleOnNewLocationClick}
          >
            + New location
          </Button>
        </div>
      </div>
    </FormRow>
  );
};
