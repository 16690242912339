import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ContentText } from '@headway/helix/ContentText';

import { UnconfirmedEventsData } from '../../utils/types';
import { SessionDetailsTable } from '../components/SessionDetailsTable';

interface DataEntryStepProps {
  provider: ProviderRead;
  unconfirmedEventsData: UnconfirmedEventsData;
}

export const DataEntryStep = ({
  provider,
  unconfirmedEventsData,
}: DataEntryStepProps) => {
  return (
    <div>
      <div className="pb-5 pl-5 pt-10">
        <ContentText variant="page-title">Sessions</ContentText>
      </div>
      <SessionDetailsTable
        provider={provider}
        unconfirmedEventsData={unconfirmedEventsData}
      />
    </div>
  );
};
