import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { BillingType } from '@headway/api/models/BillingType';
import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderPatientUpdate } from '@headway/api/models/ProviderPatientUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useSearchableFrontEndCarriersQuery } from '@headway/shared/hooks/useSearchableFrontEndCarriersQuery';
import {
  isClientMedicare,
  isClientMedicareOrMedicaid,
} from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import {
  FieldCheckbox,
  FieldControl,
  FieldErrorText,
  Form,
} from '@headway/ui/form';
import { Loader } from '@headway/ui/Loader';
import { ProviderFrontEndCarrierContext } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

import { useInsuranceStatus } from 'hooks/useInsuranceStatus';

import {
  billingTypeFormIntialValues,
  PatientBillingTypeFormFields,
  patientBillingTypeFormValidationSchema,
  PatientBillingTypeFormValues,
} from './PatientBillingTypeForm';
import { PatientBillingUnconfirmedWarning } from './PatientBillingUnconfirmedWarning';
import {
  insuranceFormInitialValues,
  insuranceFormValidationSchema,
  InsuranceFormValues,
  PatientInsuranceFormFields,
  PatientInsuranceFormFieldsProps,
  patientInsuranceFormSubmit,
} from './PatientInsuranceForm';
import {
  SelfPayFormFieldsProps,
  selfPayFormInitialValues,
  selfPayFormValidationSchema,
  SelfPayFormValues,
  SelfPayRateFormFields,
} from './SelfPayRateForm';
import { determineSelfPayEligibility } from './utils/billingType';
import { useProviderEventsUnconfirmedQuery } from './utils/customQueries';
import { useIsSelfPayEligibleEvenIfInNetwork } from './utils/useIsSelfPayEligibleEvenIfInNetwork';

type PatientBillingFormProps = PatientInsuranceFormFieldsProps &
  SelfPayFormFieldsProps & {
    onUpdateSuccess: (update: PatientBillingFormUpdate) => Promise<void> | void;
    provider: ProviderRead;
    patient: UserRead;
    providerPatient: ProviderPatientRead;
    showPatientAddedAlert?: boolean;
    startFormOnSelfPay: boolean;
    openSelfPayProviderOptInModal: () => void;
  };

type PatientBillingFormValues = InsuranceFormValues &
  SelfPayFormValues &
  PatientBillingTypeFormValues & {
    confirmComms: boolean;
    confirmNotInNetwork: boolean;
  };

export type PatientBillingFormUpdate = {
  userRead: UserRead;
  providerPatient: ProviderPatientRead;
  userInsurance?: UserInsuranceRead;
  eligibilityLookup?: EligibilityLookupRead;
};

/**
 * Billing form for patient that allow to select either Insurance or Self-Pay based forms.
 *
 * Composes the following:
 *  PatientInsuranceForm
 *  PatientSelfPayForm
 *  PatientBillingTypeForm
 */

export const PatientBillingForm = ({
  patient,
  provider,
  providerPatient,
  onUpdateSuccess,
  onCancel,
  showAuthorizationInstructionsModal,
  showPatientAddedAlert,
  openSelfPayProviderOptInModal,
  startFormOnSelfPay,
}: PatientBillingFormProps) => {
  const {
    frontEndCarriers: searchableFrontEndCarriers,
    carriersById: frontEndCarriersById,
  } = useSearchableFrontEndCarriersQuery(true);
  const anthemPatientFacingCarrierConsolidationEnabled = useFlag(
    ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION,
    false
  );
  const providerFrontEndCarrierContext = React.useContext(
    ProviderFrontEndCarrierContext
  );

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const { insuranceStatus } = useInsuranceStatus(
    patient,
    patient.activeUserInsurance
  );

  const isSelfPayEligibleEvenIfInNetwork =
    useIsSelfPayEligibleEvenIfInNetwork(patient);

  const isMedicareOrMedicaid = isClientMedicareOrMedicaid(patient);
  const isMedicare = isClientMedicare(patient);

  let selfPayEligible = determineSelfPayEligibility(
    patient,
    insuranceStatus,
    isSelfPayEligibleEvenIfInNetwork,
    providerPatient
  );

  let selfPayEnabled = false;
  if (!selfPayEligible) {
    selfPayEnabled = false;
  } else {
    selfPayEnabled = !!provider.selfPayTermsAcceptanceDate;
  }

  let startingBillingType;
  if (!selfPayEligible) {
    startingBillingType = BillingType.INSURANCE;
  } else if (startFormOnSelfPay) {
    startingBillingType = BillingType.SELF_PAY;
  } else {
    startingBillingType = providerPatient.billingTypeDefault;
  }

  const { isLoading, providerEventsUnconfirmed, error } =
    useProviderEventsUnconfirmedQuery(providerPatient);

  const validationSchema = Yup.lazy(
    (value: Yup.Shape<object | undefined, PatientBillingFormValues>): any => {
      return Yup.object().shape({
        ...patientBillingTypeFormValidationSchema.fields,
        ...(value?.billingTypeDefault === BillingType.INSURANCE &&
          insuranceFormValidationSchema.fields),
        ...(value?.billingTypeDefault === BillingType.SELF_PAY && {
          ...selfPayFormValidationSchema.fields,
          confirmComms: Yup.boolean().oneOf(
            [true],
            'You must acknowledge that Headway will send communications to this client.'
          ),
          confirmNotInNetwork: Yup.boolean().oneOf(
            [true],
            'I attest to the best of my knowledge, this client is not covered by an insurance with which Headway is in-network and is not on a Medicare and/or Medicaid plan.'
          ),
        }),
      });
    }
  );

  if (isLoading) return <Loader flex={true} />;

  const unconfirmedCount = providerEventsUnconfirmed
    ? providerEventsUnconfirmed.totalCount
    : 0;
  const shouldShowAlert = error || unconfirmedCount > 0;

  return (
    <Formik<PatientBillingFormValues>
      initialValues={{
        ...billingTypeFormIntialValues(startingBillingType),
        ...insuranceFormInitialValues(
          patient,
          anthemPatientFacingCarrierConsolidationEnabled
        ),
        ...selfPayFormInitialValues(providerPatient.selfPayRateDefault),
        confirmComms: !!providerPatient.providerSelfPayAttestationDate,
        confirmNotInNetwork: !!providerPatient.providerSelfPayAttestationDate,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values: PatientBillingFormValues) => {
        if (values.billingTypeDefault === BillingType.SELF_PAY) {
          try {
            const payload: ProviderPatientUpdate = {
              billingTypeDefault: values.billingTypeDefault,
              selfPayRateDefault: values.selfPayRateDefault,
            };

            if (!providerPatient.providerSelfPayAttestationDate) {
              payload.providerSelfPayAttestationDate = new Date().toISOString();
            }

            const newProviderPatient =
              await ProviderPatientApi.updateProviderPatient(
                providerPatient.id,
                payload
              );
            onUpdateSuccess({
              userRead: patient,
              providerPatient: newProviderPatient,
            });
          } catch (err) {
            notifyError(
              `There was a problem saving ${formatPatientName(patient, {
                firstNameOnly: true,
              })}'s billing information`
            );
            logException(err);
          }
        } else {
          let newProviderPatient = providerPatient;
          if (
            providerPatient.billingTypeDefault !== values.billingTypeDefault
          ) {
            // Billing type changed
            newProviderPatient = await ProviderPatientApi.updateProviderPatient(
              providerPatient.id,
              {
                billingTypeDefault: values.billingTypeDefault,
              }
            );
          }
          await patientInsuranceFormSubmit(
            values,
            patient,
            frontEndCarriersById,
            ({ userRead, userInsurance, eligibilityLookup }) =>
              onUpdateSuccess({
                userRead,
                userInsurance,
                eligibilityLookup,
                providerPatient: newProviderPatient,
              })
          );
        }
      }}
    >
      {({ isSubmitting, values, ...props }) => {
        return (
          <>
            {shouldShowAlert && (
              <PatientBillingUnconfirmedWarning
                error={error}
                unconfirmedCount={unconfirmedCount}
              />
            )}
            {showPatientAddedAlert && (
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.space.base,
                  marginBottom: theme.space.base,
                }}
              >
                {values.billingTypeDefault === BillingType.INSURANCE ||
                !selfPayEligible ? (
                  !patient?.activeUserInsuranceId ? (
                    <GuidanceCard variant="info">
                      {patientName} has been added to your practice! You can add
                      their insurance details below, or your client can add them
                      on their end.
                    </GuidanceCard>
                  ) : null
                ) : (
                  <GuidanceCard variant="info">
                    {patientName} has been added to your practice! You can edit
                    the private pay rate to customize this client’s rate if
                    needed.
                  </GuidanceCard>
                )}
              </div>
            )}
            <Form>
              {selfPayEligible && <PatientBillingTypeFormFields descriptive />}
              {values.billingTypeDefault === BillingType.INSURANCE ||
              !selfPayEligible ? (
                <PatientInsuranceFormFields
                  patient={patient}
                  searchableFrontEndCarriers={searchableFrontEndCarriers}
                  providerFrontEndCarrierContext={
                    providerFrontEndCarrierContext
                  }
                  showAuthorizationInstructionsModal={
                    showAuthorizationInstructionsModal
                  }
                  values={values}
                  isSubmitting={isSubmitting}
                  onCancel={onCancel}
                  cancelBtnText={
                    showPatientAddedAlert ? 'Skip for now' : 'Cancel'
                  }
                  {...props}
                />
              ) : (
                <>
                  {!isMedicareOrMedicaid ? (
                    selfPayEnabled ? (
                      <>
                        <SelfPayRateFormFields
                          isSubmitting={isSubmitting}
                          onCancel={onCancel}
                          cancelBtnText={
                            showPatientAddedAlert ? 'Skip for now' : 'Cancel'
                          }
                          {...props}
                        >
                          <>
                            <FieldControl name="confirmComms">
                              <FieldCheckbox
                                fieldType="boolean"
                                value={true}
                                label="I acknowledge that this client will receive communications from Headway."
                              />
                              <FieldErrorText />
                            </FieldControl>
                            <FieldControl name="confirmNotInNetwork">
                              <FieldCheckbox
                                fieldType="boolean"
                                value={true}
                                label="I attest that to the best of my knowledge, this client is not covered by an insurance with which Headway is in-network and is not on a Medicare and/or Medicaid plan."
                              />
                              <FieldErrorText
                                sx={{ bottom: `-${theme.space.xl3}` }}
                              />
                            </FieldControl>
                          </>
                        </SelfPayRateFormFields>
                      </>
                    ) : (
                      <div>
                        <h6>Enable Private Pay</h6>
                        <p>
                          Bring your private pay clients to Headway and keep
                          your practice in one place. Simply set your rate and
                          schedule sessions, and we’ll include these payments
                          with your existing Headway payouts.
                          {/*<a href="TODO_INSERT_ZENDESK_LINK">Learn More.</a>*/}
                        </p>
                        <div css={{ textAlign: 'right' }}>
                          <Button
                            variant="primary"
                            onPress={() => {
                              openSelfPayProviderOptInModal();
                            }}
                            disabled={isMedicareOrMedicaid}
                          >
                            Enable Private Pay
                          </Button>
                        </div>
                      </div>
                    )
                  ) : (
                    <div css={{ paddingBottom: theme.space.base }}>
                      <h6>
                        Private Pay not supported for{' '}
                        {isMedicare ? `Medicare` : `Medicaid`} clients
                      </h6>
                      <GuidanceCard variant="error">
                        <div className="flex flex-col items-start gap-2">
                          <BodyText>
                            {isMedicare ? (
                              <>
                                We do not currently support private pay for
                                Medicare clients on our platform. If you would
                                like to see this client via private pay off
                                Headway, we advise that you review{' '}
                                <Link
                                  href="https://www.medicare.gov/basics/costs/medicare-costs/provider-accept-Medicare"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  CMS guidelines
                                </Link>{' '}
                                on private contracts with Medicare members
                                before doing so. If you have any questions,
                                please{' '}
                                <Link
                                  href="https://headway.co/contact"
                                  target="_blank"
                                >
                                  reach out to us
                                </Link>
                                .
                              </>
                            ) : (
                              <>
                                We do not currently support private pay for
                                Medicaid clients on our platform. If you would
                                like to see this client via private pay off
                                Headway, we advise that you review state
                                guidelines on private pay with Medicaid members
                                before doing so. If you have any questions,
                                please{' '}
                                <Link
                                  href="https://headway.co/contact"
                                  target="_blank"
                                >
                                  reach out to us
                                </Link>
                                .
                              </>
                            )}
                          </BodyText>
                        </div>
                      </GuidanceCard>
                    </div>
                  )}
                </>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
