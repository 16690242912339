import { motion, useReducedMotion } from 'framer-motion';
import React from 'react';
import { mergeProps } from 'react-aria';

import { SearchField, SearchFieldProps } from '../SearchField';

export function SearchFilter(props: SearchFieldProps) {
  const [width, setWidth] = React.useState('auto');
  const shouldReduceMotion = useReducedMotion();

  return (
    <motion.div
      animate={{ width }}
      transition={shouldReduceMotion ? { duration: 0 } : { duration: 0.18 }}
    >
      <SearchField
        {...mergeProps(props, {
          onFocus: () => {
            setWidth('300px');
          },
          onBlur(e: React.FocusEvent<HTMLInputElement>) {
            const value = e.target.value;

            if (value === '') {
              setWidth('auto');
            }
          },
        })}
      />
    </motion.div>
  );
}
