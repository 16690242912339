import { useCallback, useContext, useMemo } from 'react';
import { useMedicareOrMedicaid } from '~/legacy/hooks/useMedicareOrMedicaid';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';

import { useRuleSet } from '../../ruleset/useRuleSet';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

interface RequiredNoteSubmissionModalV2Props {
  open: boolean;
  onClose: () => void;
}

export const RequiredNoteSubmissionModalV2 = ({
  open,
  onClose,
}: RequiredNoteSubmissionModalV2Props) => {
  const {
    patient,
    provider,
    event,
    switchToEditProgressNote,
    progressNoteType,
  } = useContext(AppointmentConfirmationContextV2);
  const isPatientMedicareOrMedicaid = useMedicareOrMedicaid(patient?.id);
  const rule = useRuleSet({ patient, provider, event });

  const canSubmitTemplatedNote = useMemo(
    () => !rule?.disabledProgressNoteTypes.includes(ProgressNoteType.TEMPLATE),
    [rule?.disabledProgressNoteTypes]
  );
  const canSubmitUploadedNote = useMemo(
    () => !rule?.disabledProgressNoteTypes.includes(ProgressNoteType.UPLOAD),
    [rule?.disabledProgressNoteTypes]
  );

  const handleOnEdit = useCallback(() => {
    if (progressNoteType === ProgressNoteType.TEMPLATE) {
      switchToEditProgressNote();
    }
    onClose();
  }, [switchToEditProgressNote, onClose, progressNoteType]);

  return (
    <Modal
      title="You must complete this note to confirm the session"
      onDismiss={onClose}
      isOpen={open}
    >
      <ModalContent>
        {isPatientMedicareOrMedicaid ? (
          <GuidanceCard variant="compliance">
            <BodyText>
              To meet compliance standards for Medicare and Medicaid plans,
              you're required to complete this documentation on Headway.
            </BodyText>
            <LinkButton
              variant="link"
              href="https://help.headway.co/hc/en-us/articles/23697087897108-Working-with-Original-Medicare-and-Medicare-Advantage-on-Headway#h_01HPT125JNGBXT7BGR8XF43XGK"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about documentation requirements
            </LinkButton>
          </GuidanceCard>
        ) : (
          <GuidanceCard variant="compliance">
            <BodyText>
              To meet compliance standards for sessions impacted by the current
              audit, you're required to complete this documentation on Headway.
            </BodyText>
            <LinkButton
              variant="link"
              href="https://help.headway.co/hc/en-us/articles/24735863229844-Writing-compliant-documentation"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about documentation requirements
            </LinkButton>
          </GuidanceCard>
        )}
        <div css={{ marginTop: theme.spacing.x6 }}>
          <BodyText>
            {canSubmitTemplatedNote && canSubmitUploadedNote
              ? `Complete a template or upload a note to confirm the session.`
              : canSubmitTemplatedNote
              ? `Complete a template to confirm the session.`
              : canSubmitUploadedNote
              ? `Upload a note to confirm the session.`
              : null}
          </BodyText>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onPress={onClose} variant="secondary" size="large">
          Cancel
        </Button>
        <Button variant="primary" size="large" onPress={handleOnEdit}>
          Edit note
        </Button>
      </ModalFooter>
    </Modal>
  );
};
