import noop from 'lodash/noop';
import { useContext } from 'react';
import { useProvider } from '~/legacy/hooks/useProvider';
import { useProviderById } from '~/legacy/hooks/useProviderById';
import { useSelectedEvent } from '~/legacy/hooks/useSelectedEvent';
import { useSetSelectedProvider } from '~/legacy/utils/practice';
import { AppointmentConfirmation } from '~/legacy/views/AppointmentConfirmation/AppointmentConfirmation';
import { AppointmentConfirmationV2 } from '~/legacy/views/AppointmentConfirmation/AppointmentConfirmationV2';

import { SESSION_CONFIRMATION_FLOW_REVAMP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { checkExhaustive } from '@headway/shared/utils/types';
import {
  FrontEndCarrierContext,
  FrontEndCarrierProvider,
} from '@headway/ui/providers/FrontEndCarrierProvider';
import {
  ProviderAddressContext,
  ProviderAddressProvider,
} from '@headway/ui/providers/ProviderAddressProvider';
import {
  ProviderFrontEndCarrierContext,
  ProviderFrontEndCarrierProvider,
} from '@headway/ui/providers/ProviderFrontEndCarrierProvider';

import { AppointmentCancelModal } from 'views/Calendar/components/AppointmentCancelModal';
import { AppointmentUndoConfirmedSessionModal } from 'views/Calendar/components/AppointmentUndoConfirmedSessionModal';

import {
  useCancelRecurringEventSideEffectsForBilling,
  useCancelSingleEventSideEffectsForBilling,
} from '../utils/queries';

export enum SessionAction {
  CANCEL = 'CANCEL',
  UNDO_CONFIRMATION = 'UNDO_CONFIRMATION',
  CONFIRM = 'CONFIRM',
  MODIFY = 'MODIFY',
  VIEW = 'VIEW',
}

export interface SessionActionModalProps {
  action: SessionAction | undefined;
  isOpen: boolean;
  onDismiss: () => void;
}

/**
 * Manages modals which take actions on a single event. Since many of these modals are scoped to a
 * single provider, this component sets up React contexts that the modals rely on.
 */
export const SessionActionModal = ({
  action,
  isOpen,
  onDismiss,
}: SessionActionModalProps) => {
  const { event: selectedEvent } = useSelectedEvent();
  const { data: providerForSelectedEvent } = useProviderById({
    providerId: selectedEvent?.providerId,
  });
  const selectedProvider = useProvider();
  const setSelectedProvider = useSetSelectedProvider();

  // Child modals read the provider from AuthStore, so we need to make sure AuthStore has the
  // same provider as the event before opening anything.
  if (
    providerForSelectedEvent &&
    selectedProvider.id !== providerForSelectedEvent.id
  ) {
    setSelectedProvider(providerForSelectedEvent, { noRedirect: true });
  }

  if (
    !action ||
    !selectedEvent ||
    !providerForSelectedEvent ||
    selectedProvider.id !== providerForSelectedEvent.id
  ) {
    return null;
  }

  return (
    <FrontEndCarrierProvider>
      <ProviderFrontEndCarrierProvider providerId={selectedEvent.providerId}>
        <ProviderAddressProvider providerIds={[selectedEvent.providerId]}>
          <SessionActionModalWithProviders
            action={action}
            isOpen={isOpen}
            onDismiss={onDismiss}
          />
        </ProviderAddressProvider>
      </ProviderFrontEndCarrierProvider>
    </FrontEndCarrierProvider>
  );
};

const SessionActionModalWithProviders = ({
  action,
  isOpen,
  onDismiss,
}: {
  [P in keyof SessionActionModalProps]: NonNullable<SessionActionModalProps[P]>;
}) => {
  const cancelSingleEventSideEffectsForBilling =
    useCancelSingleEventSideEffectsForBilling();
  const cancelRecurringEventSideEffectsForBilling =
    useCancelRecurringEventSideEffectsForBilling();
  const isSessionConfirmationFlowRevampEnabled = useFlag(
    SESSION_CONFIRMATION_FLOW_REVAMP,
    false
  );
  const { isLoading: isFrontEndCarriersLoading } = useContext(
    FrontEndCarrierContext
  );
  const { isLoading: isProviderFrontEndCarriersLoading } = useContext(
    ProviderFrontEndCarrierContext
  );
  const { isLoading: isProviderAddressesLoading } = useContext(
    ProviderAddressContext
  );
  // We can assume this is non-null because the parent component checks for it.
  const { event: selectedEvent } = useSelectedEvent();

  if (
    isFrontEndCarriersLoading ||
    isProviderFrontEndCarriersLoading ||
    isProviderAddressesLoading
  ) {
    return null;
  }

  switch (action) {
    case SessionAction.CANCEL:
      return (
        <AppointmentCancelModal
          open={isOpen}
          onClose={onDismiss}
          cancelSingleEventSideEffects={cancelSingleEventSideEffectsForBilling}
          cancelRecurringEventSideEffects={
            cancelRecurringEventSideEffectsForBilling
          }
        />
      );
    case SessionAction.MODIFY:
    case SessionAction.CONFIRM:
    case SessionAction.VIEW:
      return isSessionConfirmationFlowRevampEnabled ? (
        <AppointmentConfirmationV2
          open={isOpen}
          onClose={onDismiss}
          eventVirtualId={selectedEvent!.virtualId}
          patientId={selectedEvent!.patientUserId!}
          onOpenContactFormInsuranceIssues={noop}
          // We deliberately don't include the adoption modal in this flow.
          openTreatmentPlanAdoptionModal={noop}
        />
      ) : (
        <AppointmentConfirmation
          open={isOpen}
          onClose={onDismiss}
          eventVirtualId={selectedEvent!.virtualId}
          patientId={selectedEvent!.patientUserId!}
          progressNoteOnly={action === SessionAction.VIEW}
          onOpenContactFormInsuranceIssues={noop}
          openTreatmentPlanAdoptionModal={noop}
        />
      );
    case SessionAction.UNDO_CONFIRMATION:
      return (
        <AppointmentUndoConfirmedSessionModal
          open={isOpen}
          onClose={onDismiss}
        />
      );
    default:
      checkExhaustive(action);
  }
};
