import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAuthStore } from '~/legacy/stores/AuthStore';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { LDFlagSet } from '@headway/shared/FeatureFlags/react';

import { AppointmentConfirmationModalFormV2Values } from '../components/modals/AppointmentConfirmationModalV2';
import { Rule } from './Rule';
import { RulesInPriorityOrder } from './RulePriority';

const getEnabledRules = (
  accessingUser: UserRead | undefined,
  provider: ProviderRead | undefined,
  patient: UserRead | undefined,
  event: ProviderEventRead | undefined,
  formValues: AppointmentConfirmationModalFormV2Values | undefined
) => {
  if (!provider || !patient || !event || !accessingUser || !formValues) {
    return [];
  }

  return RulesInPriorityOrder.filter((rule) => rule.enabled[0]).map((Rule) => {
    return new Rule(accessingUser, provider, patient, event, formValues);
  });
};

const filterRulesByFlags = (
  rules: Rule<unknown[]>[],
  flags: LDFlagSet | undefined
): Rule<unknown[]>[] => {
  if (!flags) {
    return rules;
  }

  return rules.filter((rule: any) => {
    if (rule.enabled.length === 2) {
      return flags[rule.enabled[1]];
    }

    return true;
  });
};

export const useRuleSet = ({
  patient,
  provider,
  event,
}: {
  patient?: UserRead;
  provider?: ProviderRead;
  event?: ProviderEventRead;
}): Rule<unknown[]> | undefined => {
  const flags = useLDFlags();
  const { user } = useAuthStore();
  const { control } = useFormContext();
  const formValues = useWatch({
    control,
  }) as AppointmentConfirmationModalFormV2Values;

  const [enabledRules, setEnabledRules] = useState<Rule<unknown[]>[]>(
    getEnabledRules(user, provider, patient, event, formValues) ?? []
  );
  const [activeRule, setActiveRule] = useState<Rule<unknown[]> | undefined>();

  /**
   * Gathers a list of the react hooks added by the rules that should be
   * added  and initializes  to this component.
   */
  const dataHooks = enabledRules.map((rule) => {
    return rule.addDataHooks().map((hook) => hook());
  });
  const stringifiedDataHooks = JSON.stringify(dataHooks);

  /**
   * In order to not cause a rerender whenever the template responses are updated,
   * we remove it from the form values.
   */
  const {
    template,
    progressNoteType,
    previousNoteId,
    lateEntryReason,
    lateEntryOtherReason,
    ...response
  } = formValues.progressNote;
  const minifiedFormValues = {
    ...formValues,
    progressNote: {
      template,
      progressNoteType,
      previousNoteId,
      lateEntryReason,
      lateEntryOtherReason,
    },
  };

  /**
   * Re-computes the enabled rules when the provider, patient, or event changes.
   */
  useEffect(() => {
    setEnabledRules(
      getEnabledRules(user, provider, patient, event, minifiedFormValues)
    );
  }, [
    user,
    provider,
    patient,
    event,
    flags,
    JSON.stringify(minifiedFormValues),
  ]);

  /**
   * When hook data or flags are updated, re-computes the active rule.
   * Filters the rules by the flags and then sets the updated hook data for each rule.
   *
   * @Returns The first rule that is active in the priority order.
   */
  useEffect(() => {
    if (!patient || !provider || !event) {
      return;
    }

    const rulesFilteredByFlagValue = filterRulesByFlags(enabledRules, flags);
    rulesFilteredByFlagValue.map((rule, idx) => {
      return rule.setHookData(dataHooks[idx] as [unknown]);
    });
    setActiveRule(rulesFilteredByFlagValue.find((rule) => rule.isRuleActive()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stringifiedDataHooks, // Stringified to make sure the effect reruns on data changes,
    enabledRules,
    patient,
    provider,
    event,
    flags,
  ]);

  return activeRule;
};
