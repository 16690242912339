import React from 'react';

import { TelehealthPlatform } from '@headway/api/models/TelehealthPlatform';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Item, Select } from '@headway/helix/Select';
import { telehealthPlatformDisplayNames } from '@headway/shared/constants/telehealthPlatformDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { SessionDetailsFormValues } from '../SessionDetailsForm';

export const TelehealthLocationsInputs = ({
  canUpdateSessionDetails,
  values,
  setFieldValue,
}: {
  canUpdateSessionDetails?: boolean;
  values: SessionDetailsFormValues;
  setFieldValue: (a: any, b: any) => void;
}) => {
  let telehealthPlatformOptions = [
    TelehealthPlatform.VIDEO,
    TelehealthPlatform.AUDIO_ONLY_NO_ACCESS,
    TelehealthPlatform.AUDIO_ONLY_REFUSAL,
  ];

  // Account for deprecated telehealthPlatform values
  if (
    !canUpdateSessionDetails &&
    values.telehealthPlatform &&
    Object.values(TelehealthPlatform).includes(values.telehealthPlatform)
  ) {
    telehealthPlatformOptions = [
      values.telehealthPlatform as TelehealthPlatform,
    ];
  }

  return (
    <>
      <FormControl
        component={RadioGroup}
        name="telehealthPlatform"
        label="Type of telehealth session"
        disabled={!canUpdateSessionDetails}
      >
        {telehealthPlatformOptions.map((option) => (
          <Radio key={option} value={option}>
            {telehealthPlatformDisplayNames[option as TelehealthPlatform]}
          </Radio>
        ))}
      </FormControl>
      <FormControl
        component={Select}
        name="telehealthProviderState"
        label="Where did you join the telehealth session from?"
        placeholder="Select a state"
        selectionMode="single"
        onSelectionChange={(items: Set<string>) => {
          const value = UnitedStates[Array.from(items)[0] as UnitedStates];
          setFieldValue('telehealthProviderState', value);
        }}
        disabled={!canUpdateSessionDetails}
      >
        {Object.keys(statesToDisplayNames).map((state) => (
          <Item key={state}>{statesToDisplayNames[state as UnitedStates]}</Item>
        ))}
      </FormControl>
    </>
  );
};
