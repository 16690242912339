import { useProvider } from 'hooks';
import React, { useCallback, useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { SkeletonBlock } from '~/legacy/components/SkeletonBlock';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import {
  AddOrUpdateTreatmentPlanButtonClickedEvent,
  CompleteAndSignDraftButtonClickedEvent,
  CreateTreatmentPlanOrUploadExistingButtonClickedEvent,
  ShowTreatmentPlanButtonClickedEvent,
} from '@headway/avo';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';
import { PROMS_ONLY_RATE_BOOST } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { trackEvent } from '@headway/shared/utils/analytics';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import { isDetailsConfirmed } from 'views/Calendar/events/util/events';
import RateBoostGuidanceCard from 'views/Incentives/RateBoostGuidanceCard';

import { useRuleSet } from '../../../ruleset/useRuleSet';
import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../../stores/AppointmentConfirmationContextV2';
import { FormControlRHF } from '../../FormControlRHF';
import { PrefillFromPreviousSession } from './components/PrefillFromPreviousSession';
import { ProgressNoteLateEntryInputV2 } from './components/ProgressNoteLateEntryInputV2';
import { TemplateSelect } from './components/TemplateSelect';
import Template from './Template/Template';
import { getTemplate, getTemplateIdAndVersionFromFormValue } from './utils';

interface ProgressNoteFormV2Props {
  providerPatient?: ProviderPatientRead;
}

export type ProgressNoteFormV2Values = {
  template?: string;
  progressNoteType?: ProgressNoteType;
  previousNoteId?: number;
  lateEntryReason?: ProviderProgressNoteLateEntryReason;
  lateEntryOtherReason?: string;
  [key: string]: any;
};

export type TemplateAnalyticsTypes =
  | AddOrUpdateTreatmentPlanButtonClickedEvent
  | CompleteAndSignDraftButtonClickedEvent
  | CreateTreatmentPlanOrUploadExistingButtonClickedEvent
  | ShowTreatmentPlanButtonClickedEvent;
export type TemplateAnalyticsNames =
  | AddOrUpdateTreatmentPlanButtonClickedEvent['name']
  | CompleteAndSignDraftButtonClickedEvent['name']
  | CreateTreatmentPlanOrUploadExistingButtonClickedEvent['name']
  | ShowTreatmentPlanButtonClickedEvent['name'];

export const ProgressNoteFormV2 = ({
  providerPatient,
}: ProgressNoteFormV2Props) => {
  const { user } = useAuthStore();
  const provider = useProvider();
  const isGroupPracticeAdmin = isGroupAdminImpersonatingProvider(
    provider,
    user
  );

  const {
    event,
    progressNote,
    isProgressNoteLoading,
    progressNoteState,
    patient,
    templates,
    setProgressNoteType,
  } = useContext(AppointmentConfirmationContextV2);

  const progressNoteValues: ProgressNoteFormV2Values = useWatch({
    name: 'progressNote',
  });

  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  const rule = useRuleSet({ patient, provider, event });

  const [
    providerClosedConsiderHeadwayTemplateCard,
    setProviderClosedConsiderHeadwayTemplateCard,
  ] = useLocalStorage('providerClosedConsiderHeadwayTemplateCard');

  const { data: providerEnrollmentSummary } =
    useProviderIncentiveProgramEnrollment({
      providerId: provider.id,
    });

  const sendTemplateAnalyticsEvent = useCallback(
    (trackingEventName: TemplateAnalyticsNames) => {
      trackEvent({
        name: trackingEventName,
        properties: {
          providerId: provider.id!,
          providerAppointmentId: event?.providerAppointment?.id!,
          progressNoteId: progressNote?.id!,
        },
      });
    },
    [event?.providerAppointment?.id, provider.id, progressNote?.id]
  );

  const sendHeadwayTemplateAnalyticsEvent = (
    progressNoteType: ProgressNoteType
  ) => {
    if (provider?.id && event?.providerAppointment?.id && progressNoteType) {
      trackEvent({
        name: 'Use Headway Template Modal Click',
        properties: {
          providerId: provider?.id,
          providerAppointmentId: event?.providerAppointment.id,
          progressNoteRadio: progressNoteType,
        },
      });
    }
  };

  const hoursSinceEndofAppointment = event?.endDate
    ? Math.floor(
        (new Date().valueOf() - new Date(event.endDate).valueOf()) /
          (1000 * 3600)
      )
    : 0;

  const isMedicareOrMedicaid = useMedicareOrMedicaid(patient?.id);
  const showLateEntrySection =
    (isMedicareOrMedicaid && hoursSinceEndofAppointment >= 48) ||
    (!isMedicareOrMedicaid && hoursSinceEndofAppointment >= 72);

  // Only show the show rate boost banner + badges if every other banners or
  // requirements are not shown and if the appointment has not been confirmed
  // yet (more priority to other banners and flows)
  const showRateBoostInfo =
    providerEnrollmentSummary?.isProviderEnrolled &&
    !showLateEntrySection &&
    !isDetailsConfirmed(event) &&
    !rule?.banners.progressNote;

  const isFormDisabled = useMemo(
    () => progressNoteState !== ProgressNoteState.EDITING,
    [progressNoteState]
  );

  const isNoteSavedElsewhereTemplate =
    progressNoteValues?.progressNoteType === ProgressNoteType.NONE;

  const isUploadTemplate =
    progressNoteValues?.progressNoteType === ProgressNoteType.UPLOAD;

  const isIncompleteUploadOrNoteTemplate =
    isUploadTemplate || isNoteSavedElsewhereTemplate;

  const currentTemplate = useMemo(() => {
    const templateIdAndVersion = getTemplateIdAndVersionFromFormValue(
      progressNoteValues?.template
    );
    return getTemplate(templates, ...templateIdAndVersion);
  }, [progressNoteValues?.template, templates]);

  const isIncompleteFreeTextTemplate = useMemo(() => {
    return (
      progressNoteValues?.template &&
      currentTemplate?.templateInfo.name.toLowerCase().includes('free') &&
      progressNoteState !== ProgressNoteState.SIGNED &&
      progressNoteState !== ProgressNoteState.SAVED_FOR_LATER
    );
  }, [
    progressNoteState,
    progressNoteValues?.template,
    currentTemplate?.templateInfo.name,
  ]);

  const sessionConfirmationDate =
    event?.providerAppointment?.sessionDetailsConfirmedOn &&
    new Date(
      event.providerAppointment.sessionDetailsConfirmedOn
    ).toLocaleDateString([], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

  const showNoteSavedElsewhereSessionConfirmation = useMemo(() => {
    return (
      isDetailsConfirmed(event) &&
      event?.providerAppointment?.progressNoteType === ProgressNoteType.NONE
    );
  }, [event]);

  const showProgressNoteBanners =
    !isProgressNoteLoading &&
    progressNoteState === ProgressNoteState.EDITING &&
    ((showRateBoostInfo && !promsOnlyRateBoostEnabled) ||
      rule?.banners.progressNote);

  // Memoize BigRadio options to avoid re-rendering
  const radioOptions = useMemo(() => {
    return [
      {
        key: 'template',
        value: ProgressNoteType.TEMPLATE,
        label: (
          <div>
            <strong>Fill out template</strong>
            <p>Create a structured, compliant note</p>
            {showRateBoostInfo && !promsOnlyRateBoostEnabled && (
              <Badge variant="info">Rate boost qualified</Badge>
            )}
          </div>
        ),
        disabled: rule?.disabledProgressNoteTypes.includes(
          ProgressNoteType.TEMPLATE
        ),
      },
      {
        key: 'upload',
        value: ProgressNoteType.UPLOAD,
        label: (
          <div>
            <strong>Upload existing note</strong>
            <p>Use your own format or a note signed elsewhere</p>
            {showRateBoostInfo && !promsOnlyRateBoostEnabled && (
              <Badge variant="info">Rate boost qualified</Badge>
            )}
          </div>
        ),
        disabled: rule?.disabledProgressNoteTypes.includes(
          ProgressNoteType.UPLOAD
        ),
      },
      {
        key: 'none',
        value: ProgressNoteType.NONE,
        label: (
          <div>
            <strong>My note is saved elsewhere</strong>
            <p>Confirm your note exists outside of Headway</p>
          </div>
        ),
        disabled: rule?.disabledProgressNoteTypes.includes(
          ProgressNoteType.NONE
        ),
      },
    ];
  }, [
    showRateBoostInfo,
    promsOnlyRateBoostEnabled,
    rule?.disabledProgressNoteTypes,
  ]);

  const MemoizedBigRadioGroup = React.memo(BigRadioGroup);
  const MemoizedBigRadio = React.memo(BigRadio);

  if (isProgressNoteLoading) {
    return <ProgressNoteFormV2Skeleton />;
  }

  return (
    <div>
      <div css={{ marginBottom: theme.spacing.x6 }}>
        {showProgressNoteBanners && (
          <div className="mb-5">
            {rule?.banners?.progressNote?.header ? (
              rule?.banners?.progressNote?.header
            ) : showRateBoostInfo && !promsOnlyRateBoostEnabled ? (
              <RateBoostGuidanceCard />
            ) : null}
          </div>
        )}

        <div className="flex flex-col gap-5">
          <FormControlRHF
            disabled={
              !(progressNoteState === ProgressNoteState.EDITING) &&
              event?.providerAppointment?.progressNoteType !==
                ProgressNoteType.NONE
            }
            onChange={(value: any) => {
              setProgressNoteType(value);
            }}
            component={MemoizedBigRadioGroup}
            name="progressNote.progressNoteType"
            aria-label="Fill out a template or upload an existing note"
          >
            {radioOptions.map((option) => {
              return (
                <MemoizedBigRadio
                  key={option.key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </MemoizedBigRadio>
              );
            })}
          </FormControlRHF>
          {isIncompleteUploadOrNoteTemplate &&
            !providerClosedConsiderHeadwayTemplateCard &&
            progressNoteValues?.progressNoteType && (
              <CreateHeadwayTemplateGuidanceCard
                setProviderClosedConsiderHeadwayTemplateCard={
                  setProviderClosedConsiderHeadwayTemplateCard
                }
                provider={provider}
                sendHeadwayTemplateAnalyticsEvent={
                  sendHeadwayTemplateAnalyticsEvent
                }
                progressNoteType={progressNoteValues?.progressNoteType}
              />
            )}
          {isUploadTemplate &&
            providerClosedConsiderHeadwayTemplateCard &&
            !(rule?.banners.progressNote && isGroupPracticeAdmin) &&
            !isFormDisabled && <SuggestionGuidanceCard isUploadOrNote={true} />}
          {providerClosedConsiderHeadwayTemplateCard &&
            !showNoteSavedElsewhereSessionConfirmation &&
            progressNoteState === ProgressNoteState.EDITING &&
            isNoteSavedElsewhereTemplate && (
              <NotesSavedElsewhereComplianceGuidanceCard />
            )}
          {showNoteSavedElsewhereSessionConfirmation && (
            <div>
              <BodyText>
                {sessionConfirmationDate ? (
                  <>
                    On <strong>{sessionConfirmationDate},</strong> you{' '}
                  </>
                ) : (
                  'You '
                )}
                confirmed this session and indicated your note was saved
                elsewhere. You may upload that document by selecting{' '}
                <strong>Upload existing note</strong> above.
              </BodyText>
            </div>
          )}
          {progressNoteValues?.progressNoteType ===
            ProgressNoteType.TEMPLATE && (
            <>
              <div
                css={{
                  ...theme.stack.horizontal,
                  gap: theme.spacing.x2,
                  [theme.__futureMedia.phone]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <TemplateSelect />
                <PrefillFromPreviousSession />
              </div>
              {showLateEntrySection && !!progressNoteValues?.template && (
                <ProgressNoteLateEntryInputV2
                  disabled={isFormDisabled}
                  isMedicareOrMedicaid={isMedicareOrMedicaid}
                  showBanner={progressNoteState === ProgressNoteState.EDITING}
                />
              )}
              {isIncompleteFreeTextTemplate &&
                !providerClosedConsiderHeadwayTemplateCard &&
                progressNoteValues?.progressNoteType && (
                  <CreateHeadwayTemplateGuidanceCard
                    setProviderClosedConsiderHeadwayTemplateCard={
                      setProviderClosedConsiderHeadwayTemplateCard
                    }
                    provider={provider}
                    sendHeadwayTemplateAnalyticsEvent={
                      sendHeadwayTemplateAnalyticsEvent
                    }
                    progressNoteType={progressNoteValues?.progressNoteType}
                  />
                )}
              {isIncompleteFreeTextTemplate &&
                providerClosedConsiderHeadwayTemplateCard &&
                !isFormDisabled && (
                  <SuggestionGuidanceCard isUploadOrNote={false} />
                )}
              {currentTemplate && providerPatient && (
                <Template
                  template={currentTemplate}
                  eventStartDate={event?.startDate}
                  patient={patient}
                  providerPatient={providerPatient}
                  sendTemplateAnalytics={sendTemplateAnalyticsEvent}
                  disabled={isFormDisabled}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const CreateHeadwayTemplateGuidanceCard = ({
  setProviderClosedConsiderHeadwayTemplateCard,
  provider,
  sendHeadwayTemplateAnalyticsEvent,
  progressNoteType,
}: {
  setProviderClosedConsiderHeadwayTemplateCard: (changeTo: string) => void;
  provider: ProviderRead;
  sendHeadwayTemplateAnalyticsEvent: (
    progressNoteType: ProgressNoteType
  ) => void;
  progressNoteType: ProgressNoteType;
}) => (
  <GuidanceCard
    title={'For your next session, I recommend a Headway template'}
    variant="neutral"
    layout="vertical"
  >
    <BodyText>
      <>
        <div css={{ marginTop: theme.spacing.x4 }}>
          Headway's structured templates help you save time and meet insurance
          compliance standards. In fact, we've seen that progress notes created
          with Headway templates{' '}
          <strong>meet all insurance requirements 3 times more often</strong>{' '}
          than notes created using free text.
        </div>

        <div css={{ marginTop: theme.spacing.x6 }}>
          Prefer to keep your notes organized on another platform? You can
          download your notes created with Headway templates and upload them to
          a HIPAA compliant record-keeping platform.
        </div>

        <div css={{ marginTop: theme.spacing.x6 }}>
          If you're interested in using Headway templates for your next session
          and have any questions, please feel free to{' '}
          <Link
            href="https://calendly.com/office-hours-h2k"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                name: 'Schedule a Meeting Link Clicked',
                properties: {
                  providerId: provider.id,
                },
              });
            }}
          >
            schedule a meeting
          </Link>{' '}
          with a member of our team.
        </div>
        <div css={{ marginTop: theme.spacing.x6 }}>
          The clinical team at Headway
        </div>
      </>
    </BodyText>
    <Button
      onPress={() => {
        sendHeadwayTemplateAnalyticsEvent(progressNoteType);
        setProviderClosedConsiderHeadwayTemplateCard('true');
      }}
    >
      OK
    </Button>
  </GuidanceCard>
);

const SuggestionGuidanceCard = ({
  isUploadOrNote,
}: {
  isUploadOrNote?: boolean;
}) => (
  <GuidanceCard
    title={'A few items we recommend you double check'}
    variant="neutral"
    layout="vertical"
  >
    <BodyText>
      <>
        We recommend double checking that your current note includes the
        information below. These are the items that we've seen insurance
        companies most commonly flag during audits:
      </>
      <div css={{ marginTop: theme.spacing.x6 }}>
        <ol css={{ paddingLeft: theme.spacing.x4 }}>
          <li>
            <strong>Reference the client's current diagnosis,</strong> including
            what information continues to support it or why it has changed
          </li>
          <li>
            <strong>Reference the treatment plan,</strong> with commentary on
            progress toward the overall goal
            <ol type="a">
              <li>
                "Scott's treatment plan lays out a goal to reduce anxiety at
                work and in his social life/environments. Progress today:
                regressing."
              </li>
            </ol>
          </li>
          <li>
            <strong>Indicate whether symptoms</strong> have improved, maintained
            or escalated
          </li>
          {isUploadOrNote && (
            <>
              <li>
                <strong>Include a risk assessment,</strong> and if a risk is
                identified, a safety plan
              </li>

              <li>
                <strong>Include a mental status exam,</strong> with at least 3
                categories present
              </li>
            </>
          )}
        </ol>
      </div>
      <>
        <div css={{ marginTop: theme.spacing.x6 }}>
          <Link
            href="https://findheadway.zendesk.com/hc/en-us/articles/14367706608916"
            target="_blank"
            rel="noreferrer"
          >
            Our team has put together other commonly missed items here.
          </Link>
        </div>
        <div css={{ marginTop: theme.spacing.x6 }}>
          The clinical team at Headway
        </div>
      </>
    </BodyText>
  </GuidanceCard>
);

const NotesSavedElsewhereComplianceGuidanceCard = () => {
  return (
    <GuidanceCard
      variant="compliance"
      title="Important compliance requirements when saving your notes elsewhere"
    >
      <BodyText>
        By choosing <strong>My note is saved elsewhere</strong>, you acknowledge
        two things:
      </BodyText>
      <ol className="m-0 pl-4">
        <li>
          <BodyText>
            You have a copy of the note you can provide at a later date if
            requested.
          </BodyText>
        </li>
        <li>
          <BodyText>
            You will no longer be able to fill out a note template later (this
            ensures only one signed copy of the note exists, which is important
            for compliance).
          </BodyText>
        </li>
      </ol>
    </GuidanceCard>
  );
};

const ProgressNoteFormV2Skeleton = () => {
  return (
    <div role="status">
      <span className="sr-only">Loading...</span>
      <div className="mt-4 flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
        </div>
        <div className="flex flex-col gap-2">
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
        </div>
        <div className="flex flex-col gap-2">
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
        </div>
        <div className="border-1 -mx-5 flex items-center gap-3 border-0 border-y border-solid border-y-system-borderGray p-5">
          <SkeletonBlock className="aspect-square h-[24px] w-[24px] rounded-full" />
          <SkeletonBlock className="hlx-typography-body w-[13ch]" />
        </div>
      </div>
    </div>
  );
};
