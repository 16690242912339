import { useContext } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';

import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../stores/AppointmentConfirmationContextV2';

export const MedicareAndMedicaidProgressNoteBanner = () => {
  const { progressNoteState } = useContext(AppointmentConfirmationContextV2);

  if (progressNoteState !== ProgressNoteState.EDITING) {
    return null;
  }

  return (
    <GuidanceCard variant="compliance">
      <BodyText>
        To meet compliance standards for Medicare and Medicaid plans, you're
        required to complete this documentation on Headway.
      </BodyText>
      <LinkButton
        variant="link"
        href="https://help.headway.co/hc/en-us/articles/23697087897108-Working-with-Original-Medicare-and-Medicare-Advantage-on-Headway#h_01HPT125JP8ZWMEHMH2EMZZ0R4"
        target="_blank"
        rel="noreferrer"
      >
        Learn more about documentation requirements
      </LinkButton>
    </GuidanceCard>
  );
};
