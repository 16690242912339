import {
  UsePrePaymentReview,
  usePrePaymentReview,
} from '~/legacy/hooks/usePrePaymentReview';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ContactFormInsuranceAlerts } from '@headway/ui/InsuranceEligibilityAlerts';

import { isBillingAddOnPsychotherapy } from '../../components/forms/SessionDetails/utils';
import { Rule } from '../Rule';
import { PPRAnthemCANVBannerTypes, PprBanner } from './PPRAnthemCANVBanner';

const PRESCRIBER_PPR_CPT_CODES: string[] = [];
const AnthemCANV_CARRIER_IDS = [24, 31];

export class PPRAnthemCANV extends Rule<[UsePrePaymentReview]> {
  enabled = [false];

  addDataHooks() {
    return [
      () =>
        usePrePaymentReview(
          this.patient,
          this.event.providerAppointment,
          AnthemCANV_CARRIER_IDS
        ),
    ];
  }

  isRuleActive(): boolean {
    const hasSelectedPrescriberPPRCptCodes =
      !!this.formValues.sessionDetails.cptCodes?.some((code) =>
        PRESCRIBER_PPR_CPT_CODES.includes(code.value)
      );

    if (!this.providerRoles.isPrescriber || !hasSelectedPrescriberPPRCptCodes) {
      return false;
    }

    const data = this.getHookData();
    if (!data) {
      return false;
    }

    return data[0].isPPR;
  }

  isDocumentationRequired(): boolean {
    return true;
  }

  isTreatmentPlanRequired(): boolean {
    return false;
  }

  isPsychotherapyDurationRequired(): boolean {
    if (
      isBillingAddOnPsychotherapy(
        this.formValues.sessionDetails.cptCodes?.map(
          (cptCode) => cptCode.value
        ) ?? []
      )
    ) {
      return true;
    }

    return false;
  }

  get disabledProgressNoteTypes(): ProgressNoteType[] {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    const data = this.getHookData();
    if (!data) return {};

    let variant = null;

    if (this.userRoles.isGroupAdmin) {
      variant = PPRAnthemCANVBannerTypes.PPR_GROUP_ADMIN_PRESCRIBER;
    } else {
      variant = PPRAnthemCANVBannerTypes.PPR_PRESCRIBER;
    }

    return {
      progressNote: {
        header: (
          <PprBanner version={variant} requiringCarrier={data[0].pprCarrier} />
        ),
      },
    };
  }
}
