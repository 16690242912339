import { useMemo } from 'react';

import { NpiApi } from '@headway/api/resources/NpiApi';
import { YUP_NPI_MATCH } from '@headway/shared/constants/format';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

import { NPI_TYPE_1, NpiError } from '../../utils/npi';
import { NpiLookupResult } from './helpers';

const getNpiLookupQueryKey = ({ npi }: { npi: string }) => ['npi-lookup', npi];

const { useListQuery: useNpiLookupList } = createBasicApiHooks<
  { npi: string },
  (string | number)[],
  NpiLookupResult
>(
  getNpiLookupQueryKey,
  async ({ npi }) => {
    let npiRes;
    try {
      npiRes = await NpiApi.getNpiLookup({ npi });
    } catch (error) {
      return { npi, error: NpiError.NpiLookUp };
    }

    // Make sure it's NPI Type 1
    if (npiRes?.results[0]?.enumerationType !== NPI_TYPE_1) {
      return { npi, error: NpiError.NpiLookUp };
    }

    const firstName = npiRes.results[0].basic.first_name;
    const lastName = npiRes.results[0].basic.last_name;
    const credential = npiRes.results[0].basic.credential;
    return { npi, firstName, lastName, credential };
  },
  ({ npi }) => YUP_NPI_MATCH.test(npi),
  () => 'Failed to fetch NPI lookup'
);

export const useNpiVerification = ({ npis }: { npis: string[] }) => {
  const uniqueNpis = useMemo(() => Array.from(new Set(npis)), [npis]);

  const npiQueries = useNpiLookupList(
    uniqueNpis.map((npi) => ({
      queryKeyArgs: { npi },
    }))
  );

  const npiLookupResults = npiQueries
    .map((query) => query.data)
    .filter(isNpiLookupResult);

  return {
    npiLookupResults,
  };
};

const isNpiLookupResult = (
  data: NpiLookupResult | undefined
): data is NpiLookupResult => data !== undefined;
