import styled from '@emotion/styled';
import React from 'react';

import { theme } from '../theme';

export const CopyCaption = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
) => (
  <h2
    className="CopyCaption"
    css={{
      margin: 0,
      fontFamily: theme.fontFamily.postGrotesk,
      fontWeight: theme.fontWeight.regular,
      fontSize: theme.fontSize.lg,
      color: theme.color.primary,
    }}
    {...props}
  />
);

export const CopyTitle = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
) => (
  <h2
    className="CopyTitle"
    css={{
      position: 'relative',
      color: theme.color.black,
      fontFamily: theme.fontFamily.brandText,
      fontSize: theme.fontSize.xl3,
      marginBottom: 0,
      marginTop: -10,
      [theme.media.small]: {
        fontSize: theme.fontSize.xl4,
      },
    }}
    {...props}
  />
);

export const CopyBody = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => (
  <div
    className="CopyBody"
    css={{
      color: theme.color.darkGray,
      fontFamily: theme.fontFamily.postGrotesk,
      fontSize: theme.fontSize.lg,
      maxWidth: '70ch',
      marginBottom: 0,
    }}
    {...props}
  />
);

export const CopyFooter: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => <div css={{ margin: '0', width: '100%' }} {...props} />;
