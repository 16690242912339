import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { WrapNetwork } from '@headway/api/models/WrapNetwork';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { ListHeader } from '@headway/helix/ListHeader';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { KAISER_PERMANENTE_NORCAL_IDS } from '@headway/shared/constants/carrierIds';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  BCBSMA_VIRTUAL_NETWORK_LINK,
  BCBSNJ_VIRTUAL_NETWORK_LINK,
  IBX_VIRTUAL_NETWORK_LINK,
} from '@headway/shared/constants/zendesk';
import {
  MULTI_STATE_CREDENTIALING_BETA,
  MULTI_STATE_CREDENTIALING_ONBOARDING,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  PayerTimelineData,
  PayerTimelineGroupSharedData,
} from '@headway/shared/hooks/useInsuranceStatus';
import { PayerStatus } from '@headway/shared/types/payerStatus';
import {
  formatDate,
  isDateInPast,
} from '@headway/shared/utils/insuranceStatus';
import { convertListToSentence } from '@headway/shared/utils/strings';

import { ApplyTimelineItem } from '../../../apps/sigmund/app/legacy/views/InsuranceStatus/ApplyTimelineItem';
import { GetCredentialedTimelineItem } from '../../../apps/sigmund/app/legacy/views/InsuranceStatus/GetCredentialedTimelineItem';
import { GetPaidTimelineItem } from '../../../apps/sigmund/app/legacy/views/InsuranceStatus/GetPaidTimelineItem';

export interface PayerTimelineProps extends PayerTimelineGroupSharedData {
  payerTimelines: PayerTimelineData[];
  onOptOutClicked?: () => void;
  isAuthorizationRequired: boolean;
  provider: ProviderRead;
  readOnly?: boolean;
}

const STATUS_BADGES = {
  [PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED]: (
    <Badge variant="negative">Action needed</Badge>
  ),
  [PayerStatus.APPLIED]: <Badge variant="neutral">In progress</Badge>,
  [PayerStatus.CREDENTIALED]: <Badge variant="neutral">In progress</Badge>,
  [PayerStatus.APPOINTMENT_READY]: <Badge variant="neutral">In progress</Badge>,
  [PayerStatus.CREDENTIALING_DELAYED]: <Badge variant="warning">Delayed</Badge>,
  [PayerStatus.CREDENTIALING_DELAYED_ACTION_NEEDED]: (
    <Badge variant="negative">Action needed</Badge>
  ),
  [PayerStatus.PAYMENT_READY]: <Badge variant="info">Live</Badge>,
  [PayerStatus.COMPLETE]: <Badge variant="info">Live</Badge>,
};

export function PayerTimeline({
  payerStatus,
  payerName,
  appliedDate,
  appointmentReadyDate,
  paymentReadyDate,
  estimatedAppointmentReadyDate,
  firstPaymentEligibleDate,
  wrapNetwork,
  payerQuestionnaire,
  payerTimelines,
  onOptOutClicked,
  isAuthorizationRequired,
  provider,
  readOnly = false,
}: PayerTimelineProps) {
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const isMSCOnboardingEnabled = useFlag(
    MULTI_STATE_CREDENTIALING_ONBOARDING,
    false
  );
  const isBCBSMAWrapNetwork = wrapNetwork === WrapNetwork.BCBS_MA;
  const isBCBSNJWrapNetwork = wrapNetwork === WrapNetwork.BCBS_NJ;
  const isIBXWrapNetwork = wrapNetwork === WrapNetwork.INDEPENDENCE_BC;
  const isVirtualOnlyNetwork =
    isBCBSMAWrapNetwork || isBCBSNJWrapNetwork || isIBXWrapNetwork;

  // Virtual only network has no in-progress state.
  const isCollapsed =
    appointmentReadyDate &&
    firstPaymentEligibleDate &&
    (isDateInPast(firstPaymentEligibleDate) || isVirtualOnlyNetwork);

  const stateNames = payerTimelines.map(
    ({ state }) => statesToDisplayNames[state]
  );

  let virtualNetworkHelpCenterLink = '';
  if (isBCBSMAWrapNetwork) {
    virtualNetworkHelpCenterLink = BCBSMA_VIRTUAL_NETWORK_LINK;
  } else if (isBCBSNJWrapNetwork) {
    virtualNetworkHelpCenterLink = BCBSNJ_VIRTUAL_NETWORK_LINK;
  } else if (isIBXWrapNetwork) {
    virtualNetworkHelpCenterLink = IBX_VIRTUAL_NETWORK_LINK;
  }

  let virtualNetworkReadyDate =
    estimatedAppointmentReadyDate || appointmentReadyDate;
  let virtualNetworkReadinessSentence;
  if (virtualNetworkReadyDate) {
    const today = new Date();
    const formattedVirtualNetworkReadyDate = formatDate(
      (estimatedAppointmentReadyDate || appointmentReadyDate)!
    );
    if (virtualNetworkReadyDate <= today) {
      virtualNetworkReadinessSentence = `Added to virtual network ${formattedVirtualNetworkReadyDate}`;
    } else {
      virtualNetworkReadinessSentence = `Added to virtual network, activation expected ${formattedVirtualNetworkReadyDate}`;
    }
  }

  return (
    <div
      css={{
        border:
          isMSCBetaEnabled || isMSCOnboardingEnabled
            ? undefined
            : `1px solid ${theme.color.system.borderGray}`,
        borderRadius: isMSCBetaEnabled || isMSCOnboardingEnabled ? 0 : 4,
        borderTop:
          isMSCBetaEnabled || isMSCOnboardingEnabled
            ? `1px solid ${theme.color.system.borderGray}`
            : undefined,
        padding: `${theme.spacing.x4} ${theme.spacing.x5}`,
        ...theme.stack.vertical,
        gap: isCollapsed ? 8 : 18,
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <h2>
          {isMSCBetaEnabled || isMSCOnboardingEnabled ? (
            <ListHeader>{convertListToSentence(stateNames)}</ListHeader>
          ) : (
            <SectionHeader>{payerName}</SectionHeader>
          )}
        </h2>
        <div>{STATUS_BADGES[payerStatus]}</div>
      </div>
      {isCollapsed ? (
        <>
          <span
            css={{
              ...theme.typography.body.regular,
              color: theme.color.system.gray,
            }}
          >
            {isVirtualOnlyNetwork
              ? virtualNetworkReadinessSentence
              : `Credentialed ${formatDate(
                  appointmentReadyDate
                )}, paid since ${formatDate(firstPaymentEligibleDate)}`}
          </span>

          {isVirtualOnlyNetwork && (
            <>
              <div
                css={{
                  gap: theme.spacing.x3,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {!readOnly && (
                  <Button
                    variant="secondary"
                    size="medium"
                    onPress={onOptOutClicked}
                  >
                    Opt out
                  </Button>
                )}
                <Button
                  variant="primary"
                  size="medium"
                  onPress={() =>
                    window.open(virtualNetworkHelpCenterLink, '_blank')
                  }
                >
                  Learn more
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <div css={{ display: 'flex' }}>
          <ApplyTimelineItem
            payerName={payerName}
            appliedDate={appliedDate}
            payerQuestionnaire={payerQuestionnaire}
            provider={provider}
          />
          <GetCredentialedTimelineItem
            payerStatus={payerStatus}
            appointmentReadyDate={appointmentReadyDate}
            estimatedAppointmentReadyDate={estimatedAppointmentReadyDate}
            payerQuestionnaire={payerQuestionnaire}
          />
          <GetPaidTimelineItem
            paymentReadyDate={paymentReadyDate}
            appointmentReadyDate={appointmentReadyDate}
          />
        </div>
      )}
      {isAuthorizationRequired && (
        <GuidanceCard variant="neutral">
          <BodyText>
            {payerName} members need to request authorization before booking
            sessions and you won’t be able to add them directly.
            {/* Hardcoded to KP Norcal (in prod) for now */}
            {KAISER_PERMANENTE_NORCAL_IDS.includes(
              payerTimelines[0].frontEndCarrierId
            ) && (
              <>
                {' '}
                See more{' '}
                <Link
                  href={
                    'https://help.headway.co/hc/en-us/articles/23508214770580-Working-with-Headway-and-Kaiser-Permanente-Northern-California#h_01HT395TMDFV4Y57HG8JEXA3E5'
                  }
                >
                  details
                </Link>{' '}
                about working with KP Norcal.
              </>
            )}
          </BodyText>
        </GuidanceCard>
      )}
    </div>
  );
}
