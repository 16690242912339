import { Skeleton } from '@mui/material';
import React, { useContext, useState } from 'react';
import { NoteJson } from '~/legacy/views/AppointmentConfirmation/components/forms/ProgressNote/Template/Renderer/types';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { IconDownloadSimple } from '@headway/helix/icons/DownloadSimple';
import { theme } from '@headway/helix/theme';
import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';
import { trackEvent } from '@headway/shared/utils/analytics';
import { AccordionDetailsHeader } from '@headway/ui/Accordion';

import { useProviderEvent } from 'hooks/useProviderEvent';
import { useProviderProgressNotes } from 'hooks/useProviderProgressNotes';
import { ProgressNoteDownloadAgreementModal } from 'views/Clients/ProgressNotesDownloads/ProgressNoteDownloadAgreementModal';

interface PastSessionProgressNoteProps {
  eventVirtualId: string;
  setIsConfirmDetailsOpen: (arg: boolean) => void;
  isSpoofing?: boolean;
}

const enum ProgressNoteDownloadState {
  SUBMITTED = 'SUBMITTED',
  DRAFT = 'DRAFT',
  NONE = 'NONE',
}

export const PastSessionsProgressNote = ({
  eventVirtualId,
  setIsConfirmDetailsOpen,
  isSpoofing,
}: PastSessionProgressNoteProps) => {
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const { isLoading, data: appointmentProgressNotes } =
    useProviderProgressNotes(
      {
        providerAppointmentId: event?.providerAppointment?.id,
      },
      {
        enabled: !isSpoofing,
      }
    );
  const { setSelectedEventVirtualId } = useContext(SelectedEventContext);

  if (!event || isLoading) {
    return <Skeleton variant="rectangular" height={100} width="100%" />;
  }

  const { providerId, providerAppointment, patientUserId } = event;

  const progressNoteType = providerAppointment?.progressNoteType;

  let progressNoteDownloadState: ProgressNoteDownloadState;
  if (progressNoteType === ProgressNoteType.TEMPLATE) {
    if (
      appointmentProgressNotes?.length &&
      appointmentProgressNotes?.[0]?.attestedOn
    ) {
      progressNoteDownloadState = ProgressNoteDownloadState.SUBMITTED;
    } else {
      progressNoteDownloadState = ProgressNoteDownloadState.DRAFT;
    }
  } else if (progressNoteType === ProgressNoteType.UPLOAD) {
    progressNoteDownloadState = ProgressNoteDownloadState.SUBMITTED;
  } else {
    progressNoteDownloadState = ProgressNoteDownloadState.NONE;
  }

  const getButtonText = () => {
    return progressNoteDownloadState === ProgressNoteDownloadState.SUBMITTED
      ? 'View note'
      : progressNoteDownloadState === ProgressNoteDownloadState.DRAFT
      ? 'Edit note'
      : 'Add note';
  };

  const getBadgeText = () => {
    return progressNoteDownloadState === ProgressNoteDownloadState.SUBMITTED
      ? 'Submitted'
      : progressNoteDownloadState === ProgressNoteDownloadState.DRAFT
      ? 'Draft'
      : undefined;
  };

  const bodyContent = () => {
    if (progressNoteType === ProgressNoteType.TEMPLATE) {
      if (appointmentProgressNotes && appointmentProgressNotes[0]?.noteJson) {
        return `Template: ${(appointmentProgressNotes[0].noteJson as NoteJson)
          ?.templateInfo?.name}`;
      } else {
        return 'Template';
      }
    } else if (progressNoteType === ProgressNoteType.UPLOAD) {
      return 'File upload';
    } else {
      return 'No progress note added yet.';
    }
  };

  const handleCloseModal = () => {
    setIsAgreementModalOpen(false);
  };

  return (
    <div>
      {!isSpoofing && (
        <>
          <div css={{ display: 'flex' }}>
            <AccordionDetailsHeader className="hw-accordion-details-header">
              Progress note
              {getBadgeText() && (
                <div
                  css={{ marginLeft: theme.spacing.x2, alignSelf: 'baseline' }}
                >
                  <Badge
                    variant={
                      progressNoteDownloadState ===
                      ProgressNoteDownloadState.SUBMITTED
                        ? 'positive'
                        : 'neutral'
                    }
                  >
                    {getBadgeText()}
                  </Badge>
                </div>
              )}
            </AccordionDetailsHeader>
          </div>
          {bodyContent()}
        </>
      )}
      <div
        css={{
          marginBottom: theme.spacing.x3,
          marginTop: theme.spacing.x3,
          display: 'flex',
        }}
      >
        {getBadgeText() === 'Submitted' && !isSpoofing && (
          <div css={{ flexDirection: 'row', marginRight: theme.spacing.x2 }}>
            <Button
              variant="secondary"
              onPress={() => {
                setIsAgreementModalOpen(true);
                trackEvent({
                  name: 'Download Progress Note Button Clicked',
                  properties: {
                    providerId: providerId,
                    providerAppointmentId: providerAppointment!.id,
                  },
                });
              }}
            >
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  gap: theme.spacing.x2,
                }}
              >
                <IconDownloadSimple />
                Download note
              </div>
            </Button>
          </div>
        )}
        <Button
          variant="secondary"
          onPress={() => {
            if (
              progressNoteDownloadState === ProgressNoteDownloadState.SUBMITTED
            ) {
              trackEvent({
                name: 'View Progress Note Button Clicked',
                properties: {
                  providerId: providerId,
                  patientUserId: patientUserId,
                  providerAppointmentId: providerAppointment?.id,
                  progressNoteId:
                    appointmentProgressNotes &&
                    appointmentProgressNotes?.length > 0
                      ? appointmentProgressNotes[0].id
                      : null,
                },
              });
            }
            setSelectedEventVirtualId(event.virtualId);
            setIsConfirmDetailsOpen(true);
          }}
        >
          {isSpoofing ? 'View note' : getButtonText()}
        </Button>
        <ProgressNoteDownloadAgreementModal
          providerId={providerId}
          clientId={patientUserId as number}
          providerAppointmentIds={[providerAppointment?.id as number]}
          open={isAgreementModalOpen}
          onClose={async () => handleCloseModal()}
        />
      </div>
    </div>
  );
};
